export function getFileSizeName(size) {
    var totalSizeKb = size / 1024;
    if (totalSizeKb < 1)
        return `${size.toFixed()} b`;

    var totalSizeMb = totalSizeKb / 1024;
    if (totalSizeMb < 1)
        return `${totalSizeKb.toFixed()} Kb`;

    var totalSizeGb = totalSizeMb / 1024;
    if (totalSizeGb < 1)
        return `${totalSizeMb.toFixed()} Mb`;

    return `${totalSizeGb.toFixed()} Gb`;
}