import React from "react";
import { useState, useEffect } from 'react';
import { fetchGet } from "../../../utils/requestsHelper.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import Chat from "../../chat/chat.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";

export default function cardChatControl(props) {
    const [loaded, setLoaded] = useState(false);
    const [chat, setChat] = useState(undefined);
    const [accessEdit, setAccessEdit] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(props.id, props.source), [props.id, props.source]);

    if (error) {
        return <ErrorMask error={error} />;
    }

    if (!loaded) {
        return <LoadingMask />;
    }

    const chatView = accessEdit
        ? <Chat canChooseChat={false} cardChat={chat} />
        : <ErrorMask error={window.captions.ViewAccessDeny} />;

    return <div className="dm-full-wh">
        {chatView}
    </div>;

    function fetchData(id, source) {
        fetchGet(`${window.constants.cardSourceCardChat}/${source}/${id}`,
            (data) => {
                setChat(data.cardChat);
                setAccessEdit(data.cardChatAccess);
                setError(null);
                setLoaded(true);
            },
            (ex) => setError(ex));
    }
}