import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { fieldTypes, entitySourcesNames } from "../../utils/systemConstants.jsx";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { DropDownButton, Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Popup } from "@progress/kendo-react-popup";
import GridLoadingPanel from "../../components/gridLoadingPanel.jsx";
import GridLoanCardCell from "../../components/cells/gridLoanCardCell.jsx";
import GridClientCardCell from "../../components/cells/gridClientCardCell.jsx";
import GridCollateralCardCell from "../../components/cells/gridCollateralCardCell.jsx";
import GridCallRecordsCardCell from "../../components/cells/gridCallRecordsCardCell.jsx";
import GridDocumentsCardCell from "../../components/cells/gridDocumentsCardCell.jsx";
import GridColorCell from "../../components/cells/gridColorCell.jsx";
import gridFunctions from "../../components/gridFunctions.jsx";
import GridButtonIconCell from "../../components/cells/gridButtonIconCell.jsx";
import GridQuestionnaireCell from "../../components/cells/GridQuestionnaireCell.jsx";
import GridEmptyCell from "../../components/cells/gridEmptyCell.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import DialogCollateralCalendar from "../dialogs/dialogCollateralCalendar.jsx";
import DialogSelectUser from "../dialogs/dialogSelectUser.jsx";
import DialogChooseProject from "../dialogs/dialogChooseProject.jsx";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { eventResize, isMobileSize, timeIntervalType } from "../../utils/systemConstants.jsx";

export default function SearchResults(props) {
    const autoRefreshTimeout = useRef(null);
    const resizeTimeout = useRef(null);

    const autoRefreshAnchor = useRef(null);
    const gridExport = useRef(null);
    const lastSelectedIndex = useRef(undefined);

    const params = useParams();
    const navigate = useNavigate();

    const [columns, setColumns] = useState([]);
    const [filterDictionaries, setFilterDictionaries] = useState([]);
    const [filters, setFilters] = useState([]);
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [menuAccess, setMenuAccess] = useState(null);
    const [questionnaireId, setQuestionnaireId] = useState(0);
    const [pending, setPending] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [name, setName] = useState(null);
    const [canOpenCard, setCanOpenCard] = useState(false);

    const [isMobile, setIsMobile] = useState(isMobileSize(window.innerWidth, window.innerHeight));
    const [showDialogInfo, setShowDialogInfo] = useState(false);
    const [showPanelInfo, setShowPanelInfo] = useState(false);
    const [selectEntityId, setSelectEntityId] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showDialogSelectUser, setShowDialogSelectUser] = useState(false);
    const [showDialogSelectProject, setShowDialogSelectProject] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [autoRefresh, setAutoRefresh] = useState(window.systemConfig.searchAutoRefreshInterval > 0
        ? {
            popup: false,
            isOn: true,
            intervalType: window.systemConfig.searchAutoRefreshIntervalType,
            interval: window.systemConfig.searchAutoRefreshInterval
        }
        : {
            popup: false,
            isOn: false,
            intervalType: timeIntervalType.hour,
            interval: 0
        });

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.SearchResults}`;
        window.addEventListener(eventResize, handleWindowSizeChange);

        return function cleanup() {
            window.removeEventListener(eventResize, handleWindowSizeChange);
            clearTimeout(autoRefreshTimeout.current);
            clearTimeout(resizeTimeout.current);
        };

    }, []);

    useEffect(() => fetchColumns(), [params.entity, params.id]);
    useEffect(() => {
        if (!pending) autoRefreshChange();
    }, [pending]);

    if (error) {
        return <ErrorMask error={error} />;
    }

    if (!isLoaded) {
        return <LoadingMask />;
    }

    let loadingPanel = "";
    if (pending || exporting) {
        loadingPanel = <GridLoadingPanel />;
    }

    let dialogContent = "";
    if (isMobile && showDialogInfo) {
        dialogContent =
            <DialogCollateralCalendar id={selectEntityId} isDialog={true} onClose={onDialogClose} />;
    }
    if (showDialogSelectUser) {
        dialogContent = <DialogSelectUser title={dialogTitle} onClose={onDialogSelectUserClose} />;
    }
    if (showDialogSelectProject) {
        dialogContent = <DialogChooseProject onClose={onDialogSelectProjectClose} />;
    }

    const getGridCardCell = (entity) => {
        switch (entity) {
            case entitySourcesNames.loan:
                return (p) => <GridLoanCardCell {...p} />;
            case entitySourcesNames.client:
                return (p) => <GridClientCardCell {...p} />;
            case entitySourcesNames.collateral:
                return (p) => <GridCollateralCardCell {...p} />;
            case entitySourcesNames.callRecords:
                return (p) => <GridCallRecordsCardCell {...p} />;
            case entitySourcesNames.documents:
                return (p) => <GridDocumentsCardCell {...p} />;
        }
    };

    const gridCardCell = getGridCardCell(params.entity);
    const gridCellColumn = getGridCellColumn(params.entity);

    let gridToolBar = "";
    if (params.entity === entitySourcesNames.collateral) {
        gridToolBar = <GridToolbar>
            {isMobile
                ? ""
                : <Button togglable={true} onClick={buttonPanelInfoOnClick}>
                    {window.captions.InfoPanel}
                </Button>}
        </GridToolbar>;
    }

    let gridCellColumnInfo = "";
    if (isMobile && params.entity === entitySourcesNames.collateral) {
        gridCellColumnInfo = <GridColumn
            field={gridCellColumn}
            locked={true}
            title=" "
            cells={{
                data: (p) => <GridButtonIconCell icon={"calendar"}
                    onClick={buttonIconOnClick} {...p} />
            }}
            width="55" />;
    }

    let gridCellQuestionnaire = "";
    if (questionnaireId > 0) {
        gridCellQuestionnaire = <GridColumn
            field={gridCellColumn}
            title=" " locked={true}
            cells={{
                data: (p) => <GridQuestionnaireCell {...p}
                    questionnaireId={questionnaireId} />
            }}
            width="35" />;
    }

    const columnsToShow = gridFunctions.renderGridColumns(columns, filterDictionaries, data);

    const getFileName = () => {
        const today = new Date();
        return `EXCEL${today.getHours()}${today.getMinutes()}${today.getSeconds()}.xlsx`;
    };
    const exportColumns =
        columns.filter((i) => { return i.type !== fieldTypes.image }).map((item, i) => {
            return <ExcelExportColumn field={item.column} title={item.name} width={item.width} key={i} />;
        });

    let pager = true;
    if (isMobile) {
        pager = {
            buttonCount: 9,
            info: false,
            type: "numeric",
            previousNext: false
        };
    }
    if (total <= dataState.take)
        pager = false;

    var gridResults = <Grid className="dm-full-wh"
        sortable={true}
        pageable={pager}
        resizable={true}
        filterable={false}
        total={total}
        data={data}
        skip={dataState.skip}
        pageSize={dataState.take}
        filter={dataState.filter}
        sort={dataState.sort}
        onDataStateChange={handleDataStateChange}
        onColumnResize={handleColumnResize}
        selectedField="selected"
        onSelectionChange={selectionChange}
        onHeaderSelectionChange={headerSelectionChange}
        onRowClick={rowClick}>
        {gridToolBar}
        <GridColumn
            field="selected"
            width="40"
            headerSelectionValue={headerSelectionValue()} />
        {columnsToShow}
        <GridColumn title=" " field="id" cells={{ data: GridEmptyCell }} />
        <GridColumn
            field="SColor"
            title=" "
            locked={true}
            cells={{ data: gridColorCell }}
            width="35" />
        {gridCellQuestionnaire}
        {gridCellColumnInfo}
        {canOpenCard
            ? <GridColumn
                field={gridCellColumn}
                title=" "
                locked={true}
                cells={{ data: gridCardCell }}
                width="35" />
            : null}
    </Grid>;

    var wrapperClass = props.filtersHidden ? "dm-flex-height" : "dm-flex-height";

    let gridHeight = "100%";
    let panelInfoContent = null;
    if (!isMobile && showPanelInfo) {
        gridHeight = "65%";
        panelInfoContent =
            <div className="dm-searchResults-panelInfo">
                <DialogCollateralCalendar id={selectEntityId} isDialog={false} />
            </div>;
    }

    return <div className={wrapperClass}>
        <h1 className="dm-title">
            {name ? name : window.captions.SearchResults}
            {renderMenu()}
            {renderFilters()}
            {renderAutoRefresh()}
        </h1>
        <div className="dm-under-title-content">
            <div style={{ width: "100%", height: gridHeight }}>
                {dialogContent}
                {gridResults}
                <ExcelExport ref={gridExport}
                    fileName={getFileName()}>
                    {exportColumns}
                </ExcelExport>
            </div>
            {panelInfoContent}
            {loadingPanel}
        </div>
    </div>;

    function fetchColumns() {
        fetchGet(`${window.constants.searchColumns}/${params.entity}/${params.id}`,
            (data) => {
                dataState.sort = data.sort;

                setColumns(data.columns);
                setFilterDictionaries(data.filterDictionaries);
                setFilters(data.filters);
                setDataState(dataState);
                setCanOpenCard(data.canOpenCard);
                setMenuAccess(data.menuAccess);
                setQuestionnaireId(data.questionnaireId);
                setName(data.name);
                setIsLoaded(true);
                setError(null);

                fetchData(dataState);
            },
            (ex) => setError(ex));
    }

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(changeEvent.dataState);
    }

    function handleColumnResize(e) {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = setTimeout(function () {
            fetchPost(`${window.constants.filterSaveWidth}/${params.entity}/${params.id}/${e.newWidth}`,
                e.columns[e.index].field,
                () => { },
                () => { });
        },
            300);
    }

    function fetchData(dataState) {
        setPending(true);
        const hasGroups = dataState.group && dataState.group.length;
        fetchGet(
            `${window.constants.searchResults}/${params.entity}/${params.id}?${toDataSourceRequestString(dataState)}`,
            result => {
                setData(hasGroups ? translateDataSourceResultGroups(result.data) : result.data);
                setError(null);
                setTotal(result.total >= 0 ? result.total : total);
                setDataState(dataState);
                setPending(false);
            },
            ex => {
                setError(ex);
                setPending(false);
            });
    }

    function handleWindowSizeChange() {
        setIsMobile(isMobileSize(window.innerWidth, window.innerHeight));
    }

    function gridColorCell(p) { return <GridColorCell {...p} /> }

    function buttonIconOnClick(id) {
        setShowDialogInfo(true);
        setSelectEntityId(id);;
    }

    function buttonPanelInfoOnClick() {
        setShowPanelInfo(!showPanelInfo);
    }

    function handleOnFilterSelect(e) {
        navigate(`/search/${params.entity}/${e.item.id}`);
    }

    function handleOnMenuSelect(e, item) {
        const getData = () => data.some(i => i.selected)
            ? data.filter((i) => i.selected)
            : item
                ? [item]
                : [];
        switch (e.item.id) {
            case "changeAuthor":
            case "changeExternalAuthor":
                setSelectedItem(item);
                setShowDialogSelectUser(true);
                setDialogTitle(e.item.name);
                return;
            case "changeProject":
                setSelectedItem(item);
                setShowDialogSelectProject(true);
                setDialogTitle(e.item.name);
                return;
            case "exportExcel":
                if (hasSelected(item))
                    gridExport.current.save(getData());
                else
                    exportItems();
                break;
            case "archive":
                processArchive(true, getData());
                break;
            case "restore":
                processArchive(false, getData());
                break;
        }
    }

    function processArchive(isArchive, items) {
        if (!items)
            return;
        const cell = getGridCellColumn(params.entity);
        const itemsIds = items.map(item => { return item[cell]; });
        fetchPost(`${window.constants.searchArchive}/${params.entity}/${isArchive ? 1 : 0}`,
            itemsIds,
            () => { },
            () => { });

        fetchData(dataState);
    }

    function getGridCellColumn(entity) {
        switch (entity) {
            case entitySourcesNames.loan:
                return "LID";
            case entitySourcesNames.client:
                return "CID";
            case entitySourcesNames.collateral:
                return "CLID";
            case entitySourcesNames.callRecords:
                return "CRID";
            case entitySourcesNames.documents:
                return "DOCID";
        }
    }

    function hasSelected(item = null) {
        const selected = item ? item : selectedItem;
        const selectedItems = data && data.some(i => i.selected)
            ? data.filter((i) => i.selected)
            : selected
                ? [selected]
                : [];
        return selectedItems ? selectedItems.length > 0 : false;
    }

    function exportItems() {
        const exportTotal = total < 1000 ? total : 1000;
        const dataStateExport = {
            skip: 0,
            take: exportTotal,
            sort: [],
            filter: dataState.filter
        };
        const query = `${window.constants.searchResults}/${params.entity}/${params.id}?${toDataSourceRequestString(dataStateExport)}`;
        setExporting(true);
        fetchGet(query,
            ({ data }) => {
                setExporting(false);
                if (!data)
                    return;
                gridExport.current.save(data);
            },
            () => setExporting(false));
    };

    function selectionChange(event) {
        if (!data)
            return;
        const cell = getGridCellColumn(params.entity);
        const selectdData = data.map(item => {
            if (item[cell] === event.dataItem[cell]) {
                item["selected"] = !event.dataItem["selected"];
            }
            return item;
        });
        const selectedItems = selectdData.filter((i) => i.selected);
        const selectedItemId = selectedItems && selectedItems.length === 1 ? selectedItems[0][cell] : 0;
        setSelectEntityId(selectedItemId);
        setData(selectdData);
    }

    function rowClick(event) {
        if (!data)
            return;

        let last = lastSelectedIndex.current;
        const cell = getGridCellColumn(params.entity);
        const current = data.findIndex(dataItem => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            lastSelectedIndex.current = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            data.forEach(item => (item.selected = false));
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            data[i].selected = select;
        }
        const selectedItems = data.filter((i) => i.selected);
        const selectedItemId = selectedItems && selectedItems.length === 1 ? selectedItems[0][cell] : 0;
        setSelectEntityId(selectedItemId);
        setData(data);
    };

    function headerSelectionChange(event) {
        if (!data)
            return;
        const checked = event.syntheticEvent.target.checked;
        const selectdData = data.map(item => {
            item.selected = checked;
            return item;
        });
        setData(selectdData);;
    }

    function headerSelectionValue() {
        if (!data)
            return false;
        return data.findIndex(dataItem => dataItem.selected === false) === -1;
    }

    function onDialogSelectUserClose(id) {
        if (id === 0 || !hasSelected()) {
            setShowDialogSelectUser(false);
            setDialogTitle("");
            return;
        }

        const selectedItems = data.some(i => i.selected)
            ? data.filter((i) => i.selected)
            : [selectedItem];
        const cell = getGridCellColumn(params.entity);
        const itemsIds = selectedItems.map(item => { return item[cell]; });
        let queryUrl;
        switch (dialogTitle) {
            case window.captions.ChangeExecutor:
                queryUrl = `${window.constants.searchChangeAuthor}/${params.entity}/${id}`;
                break;
            case window.captions.ChangeExternalExecutor:
                queryUrl = `${window.constants.searchChangeExternalAuthor}/${id}`;
                break;
            default:
                return;
        }

        fetchPost(queryUrl,
            itemsIds,
            () => { },
            () => { });
        setSelectedItem(null);
        setShowDialogSelectUser(false);
        setDialogTitle("");
        fetchData(dataState);
    }

    function onDialogSelectProjectClose(id) {
        if (id === 0 || !hasSelected()) {
            setShowDialogSelectProject(false);
            return;
        }

        const selectedItems = data.some(i => i.selected)
            ? data.filter((i) => i.selected)
            : [selectedItem];
        const cell = getGridCellColumn(params.entity);
        const itemsIds = selectedItems.map(item => { return item[cell]; });
        fetchPost(`${window.constants.searchChangeProject}/${params.entity}/${id}`,
            itemsIds,
            () => { },
            () => { });

        setSelectedItem(null);
        setShowDialogSelectProject(false);
        fetchData(dataState);
    }

    function onDialogClose() {
        setShowDialogInfo(false);
    }

    function renderFilters() {
        if (!filters || filters.length === 0 || props.filtersHidden)
            return null;

        return <DropDownButton
            onItemClick={(e) => handleOnFilterSelect(e)}
            themeColor="primary"
            className="dm-m-left"
            icon="filter"
            items={filters}
            textField="name" />;
    }

    function autoRefreshShow() {
        var model = autoRefresh;
        setAutoRefresh({ ...model, popup: !model.popup });
    };

    function autoRefreshChangeLimit(e) {
        autoRefreshChange({ ...autoRefresh, interval: e.value });
    };

    function autoRefreshChangeLimitType(e) {
        var intervalType = e.target.value.id;
        if (intervalType === timeIntervalType.second && autoRefresh.interval < 5)
            autoRefreshChange({ ...autoRefresh, intervalType, interval: 5 });
        else
            autoRefreshChange({ ...autoRefresh, intervalType });
    };

    function autoRefreshOnOff(e) {
        autoRefreshChange({ ...autoRefresh, isOn: e.value, popup: false });
    };

    function autoRefreshChange(passed) {
        var model = passed ? passed : autoRefresh;
        clearTimeout(autoRefreshTimeout.current);
        if (model.isOn && model.interval > 0) {
            let interval = model.interval * 1000;
            if (model.intervalType === timeIntervalType.minute)
                interval = interval * 60;
            else if (model.intervalType === timeIntervalType.hour)
                interval = interval * 60 * 60;

            autoRefreshTimeout.current = setTimeout(() => {
                fetchData(dataState);
            }, interval);
        }

        if (passed)
            setAutoRefresh(passed);
    }

    function renderAutoRefresh() {
        var model = autoRefresh;
        if (menuAccess == null)
            return null;

        if (menuAccess.denyChangeAutoUpdateDuration && !model.isOn)
            return null;

        const timeLimitTypes = [
            { id: timeIntervalType.second, name: window.captions.Second },
            { id: timeIntervalType.minute, name: window.captions.Minute },
            { id: timeIntervalType.hour, name: window.captions.Hour }
        ];
        const selectedLimitType = timeLimitTypes.find((i) => i.id === model.intervalType);
        var interval = model.interval;
        var minInterval = 1;
        if (model.intervalType === timeIntervalType.second)
            minInterval = 5;

        if (interval < minInterval)
            interval = minInterval;

        var controls;
        if (menuAccess.denyChangeAutoUpdateDuration) {
            controls = interval + " / " + selectedLimitType.name;
        } else {
            controls = [
                <Checkbox value={model.isOn} key="check" label={window.captions.AutoRefresh} onChange={autoRefreshOnOff} />,
                <div key="data">
                    <NumericTextBox onChange={autoRefreshChangeLimit}
                        min={minInterval}
                        max={360}
                        width={80}
                        value={interval} />
                    <DropDownList style={{ width: 85 }}
                        textField="name"
                        dataItemKey="id"
                        data={timeLimitTypes}
                        value={selectedLimitType}
                        onChange={autoRefreshChangeLimitType} />
                </div>
            ];
        }

        return [
            <button className={`k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-icon-button dm-m-left ${(model.isOn ? "k-button-solid-primary" : "")}`}
                key="autorefreshButton"
                title={window.captions.AutoRefresh}
                onClick={autoRefreshShow}
                ref={autoRefreshAnchor}>
                <span role="presentation" className="k-button-icon k-icon k-font-icon k-i-clock"></span>
            </button>,
            <Popup anchor={autoRefreshAnchor.current}
                key="autorefreshPopup"
                popupClass="dm-popup-content"
                show={autoRefresh.popup}>
                {controls}
            </Popup>
        ];
    }

    function renderMenu() {
        const entity = params.entity;

        if (entity === entitySourcesNames.callRecords || !menuAccess || !menuAccess.hasAccess)
            return null;

        const menuItems = [];
        const isHasSelected = hasSelected(null);
        if (entity !== entitySourcesNames.collateral && menuAccess.canChangeAuthor && isHasSelected)
            menuItems.push({ id: "changeAuthor", name: window.captions.ChangeExecutor });

        if (entity === entitySourcesNames.loan && menuAccess.canChangeAuthor && isHasSelected)
            menuItems.push({ id: "changeExternalAuthor", name: window.captions.ChangeExternalExecutor });

        if (entity === entitySourcesNames.loan && menuAccess.canChangeProject && isHasSelected)
            menuItems.push({ id: "changeProject", name: window.captions.ChangeProject });

        if (menuAccess.canExportExcel)
            menuItems.push({ id: "exportExcel", name: window.captions.SendToExcel });

        if (isHasSelected && (entity === entitySourcesNames.loan || entity === entitySourcesNames.collateral)) {
            if (menuAccess.canArchive)
                menuItems.push({ id: "archive", name: window.captions.SendToArchive });
            if (menuAccess.canRestore)
                menuItems.push({ id: "restore", name: window.captions.RestoreArchive });
        }

        if (menuItems.length === 0)
            return null;

        return <DropDownButton
            onItemClick={(e) => handleOnMenuSelect(e, null)}
            themeColor="primary"
            className="dm-m-left"
            icon="more-vertical"
            items={menuItems}
            textField="name" />;
    }
}