import React from "react";
import PhoneControl from "../../containers/cards/controls/phoneControl.jsx";
import AddressControl from "../../containers/cards/controls/addressControl.jsx";
import EmailControl from "../../containers/cards/controls/emailControl.jsx";
import UrlControl from "../../containers/cards/controls/urlControl.jsx";

export default function GridContactInfo(props) {
    const result = [];
    const sources = props.sources ? props.sources : props.dataItem.sources;

    for (let key in props.dataItem.phones) {
        const item = props.dataItem.phones[key];
        result.push(<PhoneControl key={`PhoneLinked${key}`} item={item} sources={sources} />);
    }

    for (let key in props.dataItem.addresses) {
        const item = props.dataItem.addresses[key];
        result.push(<AddressControl key={`Eddressesinked${key}`} item={item} sources={sources} />);
    }

    for (let key in props.dataItem.emails) {
        const item = props.dataItem.emails[key];
        result.push(<EmailControl key={`EmailsLinked${key}`} item={item} sources={sources} />);
    }

    for (let key in props.dataItem.urls) {
        const item = props.dataItem.urls[key];
        result.push(<UrlControl key={`UrlLinked${key}`} item={item} sources={sources} />);
    }

    return <td style={props.style} className={`dm-card-badge ${props.className}`} {...props.tdProps}>
        {result}
    </td>;
}