import React from "react";
import { useState } from 'react';
import { GridBaseComponent, createdSort } from "../../../components/gridApiSource.jsx";
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import GridCallRecordsCardCell from "../../../components/cells/gridCallRecordsCardCell.jsx";
import GridTextCell from "../../../components/cells/gridTextCell.jsx";
import GridPhoneCell from "../../../components/cells/gridPhoneCell.jsx";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";
import DialogRecordPlay from "../../dialogs/dialogRecordPlay.jsx";
import { callDirection } from "../../../utils/systemConstants.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

export default function CallRecordsControl(props) {
    const [link, setLink] = useState(null);
    const [showRecordDialog, setShowRecordDialog] = useState(false);

    const gridDateTimeCell = (p) => <GridDateTimeCell {...p} />
    const gridTextCell = (p) => <GridTextCell {...p} />
    const gridPhoneCell = (p) => <GridPhoneCell {...p} />

    const handleOnListen = (item) => {
        if (!item || !item["Link"])
            return;

        setLink(item["Link"]);
        setShowRecordDialog(true);
    }

    const actionsCell = (p) => props.canOpenCard
        ? <GridCallRecordsCardCell {...p} />
        : <td {...getLinkCellStyle(p)} />;

    const listenCell = (p) => {
        if (props.canListen)
            return <td {...getLinkCellStyle(p)}>
                <Button icon="play" fillMode="flat" onClick={() => handleOnListen(p.dataItem)} />
            </td>;
        else
            return <td {...getLinkCellStyle(p)} />;
    };

    const callDirect = (p) => {
        switch (p.dataItem[p.field]) {
            case callDirection.outgoingCallManual:
                return <td {...getLinkCellStyle(p)} title={window.captions
                    .OutgoingCallManual}>
                    <span className="k-icon k-font-icon k-i-arrow-60-up dm-active dm-symbol-size" />
                </td>;
            case callDirection.outgoingCallAutoDial:
                return <td {...getLinkCellStyle(p)} title={window.captions
                    .OutgoingCallAutoDial}>
                    <span className="k-icon k-font-icon k-i-arrow-60-up dm-warning dm-symbol-size" />
                </td>;
            default:
                return <td {...getLinkCellStyle(p)} />;
        }
    };

    const dialogContent = showRecordDialog
        ? <DialogRecordPlay link={link} onClose={() => setShowRecordDialog(false)} />
        : "";

    return <div style={props.style} className="dm-full-wh">
        <GridBaseComponent baseUrl={`${window.constants.cardSourceCallRecords}/${props.source}/${props.id}`}
            sort={createdSort}
            className="dm-full-wh">
            <GridColumn field="created" title={window.captions.DataAndTime}
                width="160" cells={{ data: gridDateTimeCell }} />
            <GridColumn field="contactName" title={window.captions.ContactPerson}
                width="220" />
            <GridColumn field="callDirect" title=" " width="35" cells={{ data: callDirect }} />
            <GridColumn field="phoneType" title={window.captions.PhoneType}
                width="220" cells={{ data: gridTextCell }} />
            <GridColumn field="phoneNumber" title={window.captions.PhoneNumber}
                width="220" cells={{ data: gridPhoneCell }} />
            <GridColumn field="duration" title={window.captions.Duration}
                width="140" />
            <GridColumn field="contactResult" title={window.captions.TypeAndResultContact}
                width="220" cells={{ data: gridTextCell }} />
            <GridColumn field="contactAuthor" title={window.captions.TelephonyMonitorUser}
                width="220" cells={{ data: gridTextCell }} />
            <GridColumn field="contactGroup" title={window.captions.Department}
                width="220" cells={{ data: gridTextCell }} />
            <GridColumn locked={true} field="callRecordsId" title=" " width="35" cells={{ data: actionsCell }} />
            <GridColumn locked={true} field="link" title=" " width="35" cells={{ data: listenCell }} />
        </GridBaseComponent>
        {dialogContent}
    </div>;
}