function isPushNotificationSupported() {
    /// <summary>checks if Push notification and service workers are supported by your browser</summary>
    /// <returns type="Boolean">allowed</returns>
    return "serviceWorker" in navigator && "PushManager" in window;
}

function askUserPermission(successAction, errorAction) {
    /// <summary>
    ///     asks user consent to receive push notifications and returns the response of the user, one of granted, default,
    ///     denied
    /// </summary>
    /// <returns type="NotificationPermission">"default" | "denied" | "granted"</returns>
    Notification.requestPermission((result) => successAction(result));
}

function createNotificationSubscription(key, successAction, errorAction) {
    /// <summary>using the registered service worker creates a push notification subscription and returns it</summary>
    /// <returns type="Promise">Promise of subscribe</returns>
    navigator.serviceWorker.ready.then(serviceWorker => {
        serviceWorker.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(key)
            })
            .then((data) => successAction(data))
            .catch((err) => errorAction(err));
    });
}

function destroyNotificationSubscription(successAction, errorAction) {
    /// <summary>using the registered service worker creates a push notification subscription and returns it</summary>
    /// <returns type="Promise">Promise of subscribe</returns>

    navigator.serviceWorker.ready.then(serviceWorker => {
        serviceWorker.pushManager.getSubscription().then(function(subscription) {
            subscription.unsubscribe()
                .then((data) => successAction(data))
                .catch((err) => errorAction(err));
        });
    });
}

function getUserSubscription(successAction, errorAction) {
    /// <summary>wait for service worker installation to be ready, and then return subscription</summary>
    /// <returns type="Object">returns the subscription if present or nothing</returns>

    navigator.serviceWorker.ready
        .then((serviceWorker) => serviceWorker.pushManager.getSubscription())
        .catch((err) => errorAction(err))
        .then((pushSubscription) => successAction(pushSubscription));
}

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

export {
    isPushNotificationSupported,
    askUserPermission,
    createNotificationSubscription,
    destroyNotificationSubscription,
    getUserSubscription
    };