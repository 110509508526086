import React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";

export default function DrawerMenu(props) {
    const isSmall = props.isSmall ? props.isSmall : false;
    const isMobile = props.isMobile ? props.isMobile : false;
    const isIcon = props.isIcon ? props.isIcon : false;
    const items = props.items ? props.items : [];
    const align = props.align ? props.align : "left";
    const select = props.onSelect ? props.onSelect : null;
    const separatorClass = "dm-drawer-item k-drawer-separator";

    const className = `dm-drawer ${align === "left" ? "dm-drawer-start" : "dm-drawer-end"}`;

    const style = {};
    style.width = isSmall ? "50px" : "220px";

    var template = isSmall
        ? (i) => <span title={i.text} className={(i.icon.startsWith("fa-")
            ? `fas ${i.icon} fa-fw`
            : `dm-i dm-i-${i.icon} dm-i-fw`)}></span>
        : (i) => isIcon
            ? [
                <span key={1} title={i.text} className={(i.icon.startsWith("fa-")
                    ? `fas ${i.icon} fa-fw`
                    : `dm-i dm-i-${i.icon} dm-i-fw`)}></span>,
                <span key={2} className="dm-item-text">{i.text}</span>
            ]
            : <span className="dm-item-text">{i.text}</span>;

    const content = items.map((item, key) => {
        if (item.separator)
            return <li key={key} className={separatorClass}></li>;

        var options = {
            className: "dm-drawer-item",
            tabIndex: 0
        };
        if (item.selected) {
            options.className = options.className + " k-state-selected";
            options["aria-selected"] = true;
        }

        if (item.className)
            options.className = options.className + " " + item.className;

        if (item.disabled) {
            options.className = options.className + " k-disabled";
            options["aria-disabled"] = true;
            options.tabIndex = -1;
        } else if (select) {
            options.onClick = () => select(item);
        }
        return <li key={key} {...options} role="option">
            {template(item)}
        </li>;
    });

    if (isSmall && !isMobile) {
        return <Tooltip position="left" anchorElement="target" openDelay={0}>
            <div className={className} style={style}>
                <ul>
                    {content}
                </ul>
            </div>
        </Tooltip>;
    }

    return <div className={className} style={style}>
        <ul>
            {content}
        </ul>
    </div>;
}