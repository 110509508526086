import React from "react";
import { useState, useRef } from 'react';
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { GridBaseComponent } from "../../../components/gridApiSource.jsx";
import { fetchDelete } from "../../../utils/requestsHelper.jsx";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";
import GridColorCell from "../../../components/cells/gridColorCell.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

export default function CollateralQueueControl(props) {
    let gridControl = useRef(gridControl);

    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const removeCell =
        (p) => {
            if (props.canDelete) {
                return <td {...getLinkCellStyle(p)}>
                    <Button icon="delete" fillMode="flat" onClick={() => handleOnDelete(p.dataItem)} />
                </td>;
            } else {
                return <td {...getLinkCellStyle(p)} />;
            }
        };

    return [
        <GridBaseComponent baseUrl={`${window.constants.cardSourceCollateralQueue}/${props.id}`}
            key="gridCollateralQueue"
            ref={gridControl}
            className="dm-full-wh">
            <GridColumn field="Index" title="#" width="100" />
            <GridColumn field="Color" title=" " width="55" cells={{ data: GridColorCell }} />
            <GridColumn field="StateName" title={window.captions.State} width="110" />
            <GridColumn field="Caption" title={window.captions.Caption} width="240" />
            <GridColumn field="Created" title={window.captions.DateCreated} cells={{ data: GridDateTimeCell }} width="170" />
            <GridColumn field="StartDate" title={window.captions.StartDate} cells={{ data: GridDateTimeCell }} width="170" />
            <GridColumn field="EndDate"
                title={window.captions.ExportSchedulerEndDate}
                cells={{ data: GridDateTimeCell }}
                width="170" />
            <GridColumn title=" " field="Index" width="35" cells={{ data: removeCell }} />
        </GridBaseComponent>,
        <ConfirmMessage key="confirmRemove"
            yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    ];

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem["Id"]);
        if (!isNaN(id)) {
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function refreshDataSource() {
        if (gridControl && gridControl.current)
            gridControl.current.refreshDataSource();
    }

    function deleteItem() {
        confirmClose();
        fetchDelete(`${window.constants.cardSourceCancelCollateralQueue}`,
            deleteId,
            () => { refreshDataSource(); },
            () => { });
    }

    function confirmClose() {
        setDeleteId(0);
        setConfirm(false);
    }
}