import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GridColumn } from "@progress/kendo-react-grid";
import { GridBaseComponent, createdSort } from "../../components/gridApiSource.jsx";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { entitySourcesNames, filterSources, getContainerEditorStyle } from "../../utils/systemConstants.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import GridDateCell from "../../components/cells/gridDateCell.jsx";
import GridLoanCardCell from "../../components/cells/gridLoanCardCell.jsx";
import GridClientCardCell from "../../components/cells/gridClientCardCell.jsx";
import SearchResults from "../search/searchResults.jsx";
import { createEntity } from "../header/headerReducer.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function Home() {
    const dispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);
    const [filters, setFilters] = useState(null);
    const [filterMyId, setFilterMyId] = useState(0);

    const user = useSelector(state => state.header.model);
    const access = useSelector(state => state.header.model.access);

    useEffect(() => {
        document.title = "Delta M. Crm";
        fetchData();
    }, []);

    if (!isLoaded)
        return <div />;

    if (!access.dashboard)
        return <ErrorMask error={window.captions.AccessDenied} />;

    let filterPanel = null;
    if (filters && filters.length > 0) {
        filterPanel = filters.map((item, i) => {
            switch (item.parent) {
                case filterSources.loan:
                    return <Link className="dm-not-underline" key={i} to={`/search/loan/${item.id}`} title={window.captionsDynamic.LoanCard}>
                        <Button themeColor="primary" iconClass="dm-i dm-i-money-check">
                            {item.name}
                        </Button>
                    </Link>;
                case filterSources.client:
                    return <Link className="dm-not-underline" key={i} to={`/search/client/${item.id}`} title={window.captions.ClientCard}>
                        <Button themeColor="primary" iconClass="dm-i dm-i-user-tie">
                            {item.name}
                        </Button>
                    </Link>;
                case filterSources.collateral:
                    return <Link className="dm-not-underline" key={i} to={`/search/collateral/${item.id}`} title={window.captionsDynamic.Collaterals}>
                        <Button themeColor="primary" iconClass="dm-i dm-i-hotel">
                            {item.name}
                        </Button>
                    </Link>;
                case filterSources.callRecords:
                    return <Link className="dm-not-underline" key={i} to={`/search/callRecords/${item.id}`} title={window.captions.CardCallRecords}>
                        <Button themeColor="primary" iconClass="dm-i dm-i-microphone2">
                            {item.name}
                        </Button>
                    </Link>;
                case filterSources.documents:
                    return <Link className="dm-not-underline" key={i} to={`/search/documents/${item.id}`} title={window.captions.DocumentCard}>
                        <Button themeColor="primary" iconClass="dm-i dm-i-file-alt">
                            {item.name}
                        </Button>
                    </Link>;
                default:
                    return null;
            }
        }
        );
    }

    if (user.isStartMy) {
        return <div className="dm-flex-height">
            <div className="dm-flex-height-header">{filterPanel}</div>
            <div className="dm-flex-height-content">
                <SearchResults id={filterMyId} filtersHidden={true} />
            </div>
        </div>;
    }

    const qItems = user.questionnaires;
    let questionnaires = null;
    if (qItems && qItems.length > 0) {
        questionnaires = qItems.map((item, i) => {
            return <Link className="dm-not-underline" key={i} to={`/${entitySourcesNames.questionnaire}/${item.id}`} title={window.captions.Questionnaire}>
                <Button iconClass="dm-i dm-i-money-check" className="dm-m-left">
                    {item.name}
                </Button>
            </Link>;
        }
        );
    }

    let newClient = null;
    let newLoan = null;
    if (access.canAddClient) {
        newClient = <Button icon="add" onClick={() => dispatch(createEntity({
            entity: entitySourcesNames.client,
            id: 0
        }))}>
            {window.captions.ClientCard}
        </Button >;
    }

    if (access.canAddLoan) {
        newLoan =
            <Button icon="add" onClick={() => dispatch(createEntity({
                entity: entitySourcesNames.loan,
                id: 0
            }))}>{
                    window.captionsDynamic.LoanCard}</Button>;
    }

    const containerStyle = getContainerEditorStyle(window.innerWidth > 1100) + " dm-no-padding";

    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.MainMenuLastOpened}</h1>
        <div className="dm-under-title-content">
            <div className="dm-flex-height-header">{filterPanel}</div>
            <div className={containerStyle}>
                <div className="dm-size-big-50">
                    <h1>{window.captionsDynamic.LoanManyNominative}</h1>
                    <GridBaseComponent baseUrl={constants.dashBoardLoanCards} sort={createdSort}>
                        <GridColumn field="caption" title={window.captions.FIOCaption} />
                        <GridColumn field="created" filter="date" width="120"
                            title={window.captions.Created} cells={{ data: GridDateCell }} />
                        <GridColumn field="id" locked={true} title=" " cells={{ data: cellLoans }} width="35" />
                        {access.viewLoan
                            ? <GridColumn locked={true} field="entityId" title=" "
                                cells={{ data: GridLoanCardCell }} width="35" />
                            : null}
                    </GridBaseComponent>
                    {newLoan}{questionnaires}
                </div>
                <div className="dm-size-big-50">
                    <h1>{window.captions.Clients}</h1>
                    <GridBaseComponent baseUrl={constants.dashBoardClientCards} sort={createdSort}>
                        <GridColumn field="caption" title={window.captions.FIOCaption} />
                        <GridColumn field="created" filter="date" width="120"
                            title={window.captions.Created} cells={{ data: GridDateCell }} />
                        <GridColumn field="id" locked={true} title=" " cells={{ data: cellClients }} width="35" />
                        {access.viewClient
                            ? <GridColumn locked={true} field="entityId" title=" "
                                cells={{ data: GridClientCardCell }} width="35" />
                            : null}
                    </GridBaseComponent>
                    {newClient}
                </div>
            </div>
        </div>
    </div>;

    function fetchData() {
        fetchGet(window.constants.dashBoardModel,
            (data) => {
                setFilters(data.filters);
                setFilterMyId(data.filterMyId);
                setIsLoaded(true);
            },
            () => {
                setFilters(null);
                setIsLoaded(true);
            });
    }

    function cellLoans(props) {
        return <td {...getLinkCellStyle(props)}>
            <i className="dm-i dm-i-money-check" />
        </td>
    };

    function cellClients(props) {
        return <td {...getLinkCellStyle(props)}>
            <i className="dm-i dm-i-user-tie" />
        </td>;
    }
}