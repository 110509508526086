export function getFilterTextComparer() {
    return [
        { id: 0, name: "=" },
        { id: 5, name: "≠" },
        { id: 1, name: window.captions.StartsWith },
        { id: 2, name: window.captions.EndsWith },
        { id: 3, name: window.captions.Contains },
        { id: 4, name: window.captions.ContainsIn },
        { id: 6, name: window.captions.Empty }
    ];
}

export function getBooleanSources() {
    return [
        { value: 0, label: window.captions.WordNo },
        { value: 1, label: window.captions.WordYes },
        { value: 2, label: window.captions.WordYesNo }
    ];
}