import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export default function ConfirmMessage(props) {
    const text = props.text;
    if (!text)
        return null;

    const title = props.title
        ? props.title
        : window.captions.Approve;
    return <Dialog title={title}
        minWidth={300}
        maxWidth={800}
        onClose={props.no}>
        {text}
        <DialogActionsBar>
            <Button className="dm-positive-filled" onClick={props.yes}>{window.captions.WordYes}</Button>
            <Button className="dm-negative-filled" onClick={props.no}>{window.captions.WordNo}</Button>
        </DialogActionsBar>
    </Dialog>;
}