import { entitySourcesNames } from "../../utils/systemConstants.jsx";

function renderMenuItems(user, unreadMessageCount) {
    const access = user.access;
    const items = [];

    items.push({ text: "Delta M. Crm", icon: "", route: "expand" });

    if (user.questionnaires) {
        for (let i = 0; i < user.questionnaires.length; i++) {
            const item = user.questionnaires[i];
            items.push({ entity: entitySourcesNames.questionnaire, text: item.name, id: item.id });
        }
    }

    if (access.canAddLoan)
        items.push({
            entity: entitySourcesNames.loan,
            text: window.captionsDynamic.MainMenuCreateLoan
        });

    if (access.canAddClient)
        items.push({
            entity: entitySourcesNames.client,
            text: window.captions.MainMenuCreateClient
        });

    if (access.dashboard) {
        items.push({ text: window.captions.Dashboard, icon: "dashboard", selected: true, route: "/home" });
        items.push({ separator: true });
    }

    if (user.isAdmin && user.access.isAdmin) {
        if (access.adminRulesAndUsers)
            items.push({ text: window.captions.RuleAndUsers, icon: "users-gear", route: "/admin/rules-and-users" });

        if (access.adminImport)
            items.push({ text: window.captions.Import, icon: "file-import", route: "/admin/import/loan" });

        if (access.adminClientCard)
            items.push({ text: window.captions.ClientCard, icon: "id-card-clip", route: "/admin/clientCard" });

        if (access.adminProject)
            items.push({ text: window.captions.Project, icon: "briefcase", route: "/admin/projects" });

        if (access.adminSearch) {
            items.push({
                text: window.captions.ConfigSearch,
                icon: "search",
                route: "/admin/searchSettings/loan"
            });
        }

        if (access.adminStructureSettings) {
            items.push({
                text: window.captions.MainTabAdministrationFields,
                icon: "sliders",
                route: "/admin/structureSettings/client"
            });
        }

        if (access.adminDocuments)
            items.push({ text: window.captions.Documents, icon: "file-word", route: "/admin/documents" });

        if (access.adminReports)
            items.push({ text: window.captions.MainTabStatisticReports, icon: "file-excel", route: "/admin/reports" });

    } else {
        var searchRoute = "";
        if (access.searchLoan)
            searchRoute = "loan";
        else if (access.searchClient)
            searchRoute = "client";
        else if (access.searchCollateral)
            searchRoute = "collateral";
        else if (access.searchCallRecords)
            searchRoute = "callRecords";
        else if (access.searchDocuments)
            searchRoute = "documents";

        if (searchRoute)
            items.push({
                text: window.captions.RuleDataSearch,
                icon: "search",
                route: `/search/${searchRoute}/form`,
                path: `/search`
            });

        if (access.calendar)
            items.push({ text: window.captions.MainTabCommonCalendar, icon: "calendar-day", route: "/calendar" });

        if (access.tasks)
            items.push({ text: window.captions.Tasks, icon: "tasks", route: "/task" });

        if (access.statistic)
            items.push({ text: window.captions.Statistic, icon: "stat", route: "/statistic" });

        if (access.chat) {
            items.push({
                text: window.captions.MainTabChat,
                icon: "chat",
                route: "/chat",
                count: unreadMessageCount
            });
        }

        if (access.collateralSchema) {
            items.push({
                text: window.captionsDynamic.ResourceSchemas,
                icon: "hotel",
                route: "/collateral-schema"
            });
        }

        if (access.organization) {
            items.push({ text: window.captions.CompanyStructure, icon: "company_structure", route: "/organization" });
        }
        if (access.wiki) {
            items.push({ text: window.captions.Wiki, icon: "info_circle", route: "/wiki" });
        }
        if (access.geolocation) {
            items.push({ text: window.captions.Geolocation, icon: "microphone1", route: "/geolocation" });
        }
    }

    return items;
}

export {
    renderMenuItems
}