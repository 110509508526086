import React, { useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FileIcon, defaultStyles } from "react-file-icon";
import { fetchPost } from "../../utils/requestsHelper.jsx";
import { fileTypeClassName } from "../statistic/statisticHelpers.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogExport({ exportItem, onClose }) {
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    if (pending || error) {
        return <Dialog title={exportItem.name} onClose={() => onClose(false)} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const image = fileTypeClassName(exportItem);

    let exportTitle;
    if (exportItem.type === 1 || exportItem.type === 2 || exportItem.type === 5)
        exportTitle = window.captions.MakeReport;
    else
        exportTitle = window.captions.MakeDocument;

    return <Dialog title={exportItem.name} onClose={() => onClose(false)} width={550}>
        <div className="dm-container dm-no-padding">
            <div key="icon" style={{ "textAlign": "center", "width": "100%" }}>
                <span className="dm-icon-64">
                    <FileIcon extension={image} {...defaultStyles[image]} />
                </span>
            </div>
        </div>
        <DialogActionsBar>
            <Button onClick={() => onClose(false)}>{window.captions.Close}</Button>
            <Button themeColor="primary" onClick={startExport}>{exportTitle}</Button>
        </DialogActionsBar>
    </Dialog>;

    function startExport() {
        var saveData = [];
        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.statisticRender}/${exportItem.type}/${exportItem.id}/0`,
            saveData,
            () => onClose(true),
            () => onClose(false));
    }
}