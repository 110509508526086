import React from "react";

export default function GridBoolCell({ dataItem, field, tdProps, style, className }) {
    let value = dataItem[field];
    if (typeof value === "object")
        value = null;

    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;

    if (value === undefined)
        return <td {...props} />;

    if (value === null) {
        return <td {...props}>
            <span className="dm-symbol-size" style={{ fontWeight: "bold" }}>&#63;</span>
        </td>;
    } else if (Boolean(value) === true) {
        return <td {...props}>
            <span className="k-icon k-font-icon k-i-check dm-positive dm-symbol-size" />
        </td>;
    } else if (Boolean(value) === false) {
        return <td {...props}>
            <span className="k-icon k-font-icon k-i-x dm-error dm-symbol-size" />
        </td >;
    } else {
        return <td {...props} />;
    }
}