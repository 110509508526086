import React from "react";

export default function GridPhoneCell({ dataItem, field, tdProps, style, className }) {
    let value = dataItem[field];
    if (typeof value === "object")
        value = null;

    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;

    const links = [];
    if (value) {
        const values = value.split(",");
        values.forEach(function (link, index) {
            if (!link)
                return;

            if (index > 0)
                links.push(<br key={index + 1000} />);

            links.push(<a href={`tel:+${link}`} target="_blank" key={index}>{link}</a>);
        });
    }
    return <td {...props}>
        {links}
    </td>;
}