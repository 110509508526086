import React, { forwardRef } from "react";
import { formatDate, formatNumber } from "@progress/kendo-intl";
import { dateFormat } from "../../../utils/systemConstants.jsx";
import { GridBaseComponent, createdSort } from "../../../components/gridApiSource.jsx";
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";
import GridColorCell from "../../../components/cells/gridColorCell.jsx";
import GridFullTextCell from "../../../components/cells/gridFullTextCell.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";
import { getUiCulture } from "../../../utils/authHelper.jsx";

const HistoryControl = forwardRef(function HistoryControl(props, ref) {
    const columns = [];
    const showTitle = window.systemConfig.historyTitle.indexOf(props.source) >= 0;

    columns.push(<GridColumn key="color" field="color" title=" " width="35" cells={{ data: GridColorCell }} />);
    if (showTitle)
        columns.push(<GridColumn key="title" field="title" title={window.captions.EntityName} width="240" />);

    columns.push(<GridColumn key="created" field="created" title={window.captions.Created} width="160" cells={{ data: GridDateTimeCell }} />);
    columns.push(<GridColumn key="contactType" field="contactType" title={window.captions.WordType} width="240" />);
    columns.push(<GridColumn key="contactResult" field="contactResult" title={window.captions.Result} width="240" />);
    columns.push(<GridColumn key="text" field="text" title={window.captions.Comment} width="300" cells={{ data: GridFullTextCell }} />);
    columns.push(<GridColumn key="contact" field="contact" title={window.captions.ContactPerson} width="240" />);
    columns.push(<GridColumn key="notification" field="notification" title={window.captions.Notification} width="160" cells={{ data: GridDateTimeCell }} />);
    columns.push(<GridColumn key="paymentDate" field="paymentDate" title={window.captions.PromissDeclared} width="160" cells={{ data: GridPromiseCell }} />);
    columns.push(<GridColumn key="author" field="author" title={window.captions.Author} width="240" />);
    columns.push(<GridColumn key="userGroup" field="userGroup" title={window.captions.Group} width="240" />);
    if (window.systemConfig.userHistoryGeolocation) {
        const mapCell = (p) => p.dataItem["location"]
            ? <td {...getLinkCellStyle(p)}>
                {p.dataItem["location"]}
                <Button icon="globe" fillMode="flat" onClick={() => handleOnMap(p.dataItem)} />
            </td>
            : <td {...getLinkCellStyle(p)} />;

        columns.push(<GridColumn key="geolocation" field="location" title={window.captions.Geolocation}
            cells={{ data: mapCell }}
            width="240" />);
    }

    return <GridBaseComponent baseUrl={`${window.constants.cardSourceHistory}/${props.source}/${props.id}`}
        sort={createdSort}
        ref={ref}
        className="dm-full-wh">
        {columns}
    </GridBaseComponent>;

    function handleOnMap(dataItem) {
        var latitude = parseFloat(dataItem["latitude"]);
        var longitude = parseFloat(dataItem["longitude"]);
        if (!isNaN(latitude) && !isNaN(longitude)) {
            props.dialogMap(true, { latitude, longitude });
        }
    }

    function GridPromiseCell({ dataItem, tdProps, style, className }) {
        const paymentDate = dataItem["paymentDate"];
        const promiseDate = dataItem["promiseDate"];
        const declaredDate = dataItem["declaredDate"];
        const props = { ...tdProps };
        if (style)
            props.style = style;
        if (className)
            props.className = className;

        var data = [];
        var culture = getUiCulture();
        if (paymentDate) {
            data.push(dateValueBlock("pay", culture, window.captions.Promiss, paymentDate, dataItem["paymentValue"]));
        }
        if (declaredDate) {
            data.push(dateValueBlock("dec", culture, window.captions.Declared, declaredDate, dataItem["declaredValue"]));
        }
        if (promiseDate) {
            data.push(dateValueBlock("pro", culture, window.captions.PromiseScheduler, promiseDate, dataItem["promiseValue"]));
        }

        return <td {...props}>
            {data}
        </td>;
    }

    function dateValueBlock(key, culture, caption, data, value) {
        return <div key={key}>{caption} {formatDate(new Date(data), dateFormat, culture)} / <span className="dm-positive">{formatNumber(new Number(value), "n2", culture)}</span></div>;
    }
})

export default HistoryControl;