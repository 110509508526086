import { headersGet } from "./requestsHelper.jsx";
import "isomorphic-fetch";

export default {
    getWiki(id, successAction) {
        this.getFile(`${window.constants.fileWiki}/${id}`, successAction);
    },

    getDocument(id, successAction) {
        this.getFile(`${window.constants.fileDocument}/${id}`, successAction);
    },

    getLetter(id, successAction) {
        this.getFile(`${window.constants.fileLetter}/${id}`, successAction);
    },
    
    getUserDocument(fileName, successAction) {
        this.getFile(`${window.constants.fileUserDocument}?fileName=${fileName}`, successAction);
    },

    getExport(id, successAction) {
        this.getFile(`${window.constants.fileExport}/${id}`, successAction);
    },

    getFileDocumentPackage(id, isPack, successAction) {
        this.getFile(`${window.constants.fileDocumentPackage}/${id}/${isPack}`, successAction);
    },

    getFileChat(id, successAction) {
        this.getFile(`${window.constants.fileChat}/${id}`, successAction);
    },

    getFileSpecificModule(name) {
        this.getFile(`${window.constants.fileSpecificModule}?name=${name}`);
    },

    getFile(url, successAction) {
        fetch(url, headersGet()).then(function(response) {
            if (response.ok) {
                var filename = "";
                const disposition = response.headers.get("content-disposition");
                if (disposition && disposition.indexOf("attachment") !== -1) {
                    console.log(disposition);
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches && matches[1]) {
                        filename = decodeURI(matches[1].replace(/['"]/g, ""));
                    }
                }

                if (filename === "") {
                    throw "No file name detected";
                }

                return response.blob().then(function(data) {
                    const url = URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    if (successAction)
                        successAction();
                });
            } else {
                return response.json().then(function(jsonError) {
                    console.log(jsonError);
                });
            }
        }).catch(function(error) {
            console.log("There has been a problem with your fetch operation: ", error.message);
        });
    }
};