import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { ListBox } from "@progress/kendo-react-listbox";

export default function RightGroupMembers({ dataRule, groupList, selectUserItem, selectGroupItem }) {
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => mountProperties(), [dataRule, groupList]);

    return <div className="container">
        <div className="row justify-content-center">
            <div className="col k-pr-2">
                <h4>{window.captions.Groups}</h4>
                <ListBox
                    draggable={false}
                    style={{
                        height: 350,
                        width: "100%",
                    }}
                    data={groups}
                    textField="name"
                    selectedField="selected"
                    item={groupItemRender}
                />
            </div>
            <div className="col k-pl-0">
                <h4>{window.captions.UsersTwoDots}</h4>
                <ListBox
                    style={{
                        height: 350,
                        width: "100%",
                    }}
                    draggable={false}
                    data={users}
                    textField="name"
                    selectedField="selected"
                    item={userItemRender}
                />
            </div>
        </div>
    </div>;

    function mountProperties() {
        if (!dataRule || !groupList) {
            setGroups([]);
            setUsers([]);
            return;
        }

        let groupsLocal = [];
        let usersLocal = [];

        groupList.forEach((group) => {
            if (dataRule.linkedGroups.includes(group.id))
                groupsLocal.push(group);

            group.items.forEach((user) => {
                if (user.role == dataRule.id)
                    usersLocal.push({
                        id: user.id,
                        name: user.name,
                    });
            })
        });

        setGroups(groupsLocal);
        setUsers(usersLocal);
    }

    function openUser(data) {
        if (selectUserItem)
            selectUserItem(data.id);
    }

    function openGroup(data) {
        if (selectGroupItem)
            selectGroupItem(data.id);
    }

    function groupItemRender(p) {
        let { dataItem, selected, ...others } = p;
        return <li {...others}>
            <div>
                <span>
                    {dataItem.name}
                </span>
                <span>
                    <Button style={{ padding: "0 0 0 5px", fontSize: "10px" }}
                        className="k-flat"
                        onClick={() => openGroup(dataItem)}>
                        <i className={`dm-i dm-i-link`} />
                    </Button>
                </span>
            </div>
        </li>;
    }

    function userItemRender(p) {
        let { dataItem, selected, ...others } = p;
        return <li {...others}>
            <div>
                <span>
                    {dataItem.name}
                </span>
                <span>
                    <Button style={{ padding: "0 0 0 5px", fontSize: "10px" }}
                        className="k-flat"
                        onClick={() => openUser(dataItem)}>
                        <i className={`dm-i dm-i-link`} />
                    </Button>
                </span>
            </div>
        </li>;
    }
}