import React from "react";
import {
    fieldTypes,
    getBooleanSources,
    getFormatDate,
    getFormatDateTime,
    getFormatTime,
    dateTimeWidth,
    dateWidth,
    timeWidth,
    styleNumber
} from "../../utils/systemConstants.jsx";
import { NumericTextBox, RadioGroup } from "@progress/kendo-react-inputs";
import { DateTimePicker, DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import DropDownAlternating from "../../components/controls/dropDownAlternating.jsx";
import PhoneControl from "./controls/phoneControl.jsx";
import AddressControl from "./controls/addressControl.jsx";
import EmailControl from "./controls/emailControl.jsx";
import UrlControl from "./controls/urlControl.jsx";
import PassportControl from "./controls/passportControl.jsx";
import EdrvpControl from "./controls/edrvpControl.jsx";
import ImageControl from "./controls/imageControl.jsx";
import ColorPickerControl from "./controls/colorPickerControl.jsx";
import AutocompleteControl from "./controls/autocompleteControl.jsx";

export function renderInput(field, value, sources, { changedValue, fileUpload }) {
    switch (field.type) {
        case fieldTypes.text:
            return field.isAutocompleted
                ? <AutocompleteControl field={field.modelName}
                    textLength={field.textLength}
                    textLengthMin={field.textLengthMin}
                    value={value}
                    required={field.isRequired}
                    autoFillType={field.autoFillType}
                    changeValue={changedValue} />
                : <MultilineTextArea changeText={changedValue}
                    textLength={field.textLength}
                    textLengthMin={field.textLengthMin}
                    required={field.isRequired}
                    field={field.modelName}
                    text={value} />;
        case fieldTypes.integer:
        case fieldTypes.double:
            var numberValue = parseFloat(value);
            if (isNaN(numberValue))
                numberValue = null;

            var numberProps = {};
            if (field.fieldWeight && field.fieldWeight >= 0)
                numberProps.max = field.fieldWeight;

            if (field.format)
                numberProps.format = field.format;
            else if (field.type === fieldTypes.integer)
                numberProps.format = "n0";

            var isValidNumeric = true;
            if (field.minDouble != null && numberValue && numberValue < field.minDouble)
                isValidNumeric = false;
            else if (field.maxDouble != null && numberValue && numberValue > field.maxDouble)
                isValidNumeric = false;
            else if (field.isRequired && numberValue == null)
                isValidNumeric = false;

            return <NumericTextBox
                onChange={(e) => changedValue({ fieldId: field.modelName, value: e.value })}
                defaultValue={numberValue}
                valid={isValidNumeric}
                {...numberProps}
                style={styleNumber} />;
        case fieldTypes.phone:
            return <PhoneControl
                changedValue={e => changedValue(e)}
                required={field.isRequired}
                isEdit={true}
                field={field.modelName}
                phoneIncorrect={field.incorrectValue}
                sources={sources}
                value={value} />;
        case fieldTypes.address:
            return <AddressControl
                changedValue={e => changedValue(e)}
                required={field.isRequired}
                isEdit={true}
                field={field.modelName}
                sources={sources}
                value={value} />;
        case fieldTypes.email:
            return <EmailControl
                changedValue={e => changedValue(e)}
                required={field.isRequired}
                isEdit={true}
                field={field.modelName}
                sources={sources}
                value={value} />;
        case fieldTypes.url:
            return <UrlControl
                changedValue={e => changedValue(e)}
                required={field.isRequired}
                isEdit={true}
                field={field.modelName}
                sources={sources}
                value={value} />;
        case fieldTypes.passport:
            return <PassportControl
                changedValue={changedValue}
                required={field.isRequired}
                field={field.modelName}
                value={value}
                passportTypes={sources.passportTypes} />;
        case fieldTypes.boolean:
            var boolValue = 2;
            if (value === true)
                boolValue = 1;
            if (value === false)
                boolValue = 0;

            var isValid = true;
            if (field.isRequired)
                isValid = boolValue !== 2;

            return <div className="dm-boolean-input">
                <RadioGroup data={getBooleanSources()}
                    onChange={(e) => changedValue({ fieldId: field.modelName, value: e.value })}
                    valid={isValid}
                    defaultValue={boolValue}
                    layout="horizontal" />
            </div>;
        case fieldTypes.valueArray:
            return <DropDownList style={{ width: "100%" }}
                data={field.valuesList}
                required={field.isRequired}
                value={value}
                onChange={(e) => changedValue({ fieldId: field.modelName, value: e.value })} />;
        case fieldTypes.time:
            var timeValue = value
                ? new Date(`2000-01-01T${value}`)
                : null;

            return <TimePicker
                onChange={(e) => changedValue({ fieldId: field.modelName, value: e.value })}
                defaultValue={timeValue}
                opened={true}
                required={field.isRequired}
                formatPlaceholder={getFormatTime()}
                width={timeWidth} />;
        case fieldTypes.date:
            var dateProps = {};
            if (field.minDateTime)
                dateProps.min = new Date(field.minDateTime);
            if (field.maxDateTime)
                dateProps.max = new Date(field.maxDateTime);

            return <DatePicker
                onChange={(e) => changedValue({ fieldId: field.modelName, value: e.value })}
                defaultValue={value ? new Date(value) : null}
                required={field.isRequired}
                formatPlaceholder={getFormatDate()}
                width={dateWidth}
                {...dateProps} />;
        case fieldTypes.dateTime:
            var dateTimeProps = {};
            if (field.minDateTime)
                dateTimeProps.min = new Date(field.minDateTime);
            if (field.maxDateTime)
                dateTimeProps.max = new Date(field.maxDateTime);

            return <DateTimePicker
                onChange={(e) => changedValue({ fieldId: field.modelName, value: e.value })}
                defaultValue={value ? new Date(value) : null}
                required={field.isRequired}
                formatPlaceholder={getFormatDateTime()}
                width={dateTimeWidth}
                {...dateTimeProps} />;
        case fieldTypes.edrvp:
            return <EdrvpControl
                changedValue={changedValue}
                required={field.isRequired}
                field={field.modelName}
                value={value} />;
        case fieldTypes.timeZone:
            if (!field.dictionarySource)
                return window.captions.ReportErrorUnknown;

            var timeUtcOffset = value ? parseInt(value) : 0;
            var timeZoneValue = field.dictionarySource.find((i) => i.id === timeUtcOffset);
            if (timeZoneValue == null || timeZoneValue === undefined)
                timeZoneValue = { id: 0, name: "" };

            var isTimeZoneValid = true;
            if (field.isRequired && timeZoneValue.id === 0)
                isTimeZoneValid = false;

            return <DropDownList style={{ width: "100%" }}
                data={field.dictionarySource}
                field={field.modelName}
                textField="name"
                dataItemKey="id"
                valid={isTimeZoneValid}
                value={timeZoneValue}
                onChange={(e) => changedValue({ fieldId: field.modelName, value: e.target.value.id })} />;
        case fieldTypes.simpleDictionary:
            if (!field.dictionarySource)
                return window.captions.ReportErrorUnknown;

            var selectedId = value ? value : 0;
            var simpleDictValue = field.dictionarySource.find((i) => i.id === selectedId);
            if (!simpleDictValue)
                simpleDictValue = { id: 0, name: "" };

            var isDictionaryValid = true;
            if (field.isRequired && simpleDictValue.id === 0)
                isDictionaryValid = false;

            return field.dictionarySource.length <= 7
                ? <DropDownList style={{ width: "100%" }}
                    data={field.dictionarySource}
                    field={field.modelName}
                    textField="name"
                    dataItemKey="id"
                    valid={isDictionaryValid}
                    value={simpleDictValue}
                    onChange={(e) => changedValue({ fieldId: field.modelName, value: e.target.value.id })} />
                : <DropDownTreeView
                    id={`DropSimpleTree${field.modelName}`}
                    field={field.modelName}
                    style={{ width: "100%" }}
                    required={field.isRequired}
                    selectedId={value}
                    treeData={field.dictionarySource}
                    selectionChanged={changedValue} />;
        case fieldTypes.extendedDictionary:
            if (!field.dictionarySource)
                return window.captions.ReportErrorUnknown;

            if (field.isDynamicLoad) {
                return <DropDownAlternating
                    id={`DropAlternating${field.modelName}`}
                    field={field.modelName}
                    style={{ width: "100%" }}
                    required={field.isRequired}
                    selectedId={value}
                    treeData={field.dictionarySource}
                    treeLabels={field.dictionaryLevels}
                    selectionChanged={changedValue} />;
            } else {
                return <DropDownTreeView
                    id={`DropTree${field.modelName}`}
                    field={field.modelName}
                    style={{ width: "100%" }}
                    required={field.isRequired}
                    selectedId={value}
                    treeData={field.dictionarySource}
                    selectionChanged={changedValue} />;
            }
        case fieldTypes.multiExtendedDictionary:
        case fieldTypes.multiSimpleDictionary:
            if (!field.dictionarySource)
                return window.captions.ReportErrorUnknown;

            return <DropDownCheckedTree id={`DropMulti${field.modelName}`}
                field={field.modelName}
                style={{ width: "100%" }}
                selectedIdList={value}
                required={field.isRequired}
                treeData={field.dictionarySource}
                selectionChanged={changedValue} />;
        case fieldTypes.image:
            return <ImageControl
                clearField={() => changedValue({ fieldId: field.modelName, value: null })}
                fileUpload={(e) => fileUpload(e)}
                field={field.modelName}
                value={value} />;
        case fieldTypes.color:
            return <ColorPickerControl view={"combo"}
                field={field.modelName}
                value={value}
                required={field.isRequired}
                changeValue={changedValue} />;
        default:
            return <div />;
    }
}