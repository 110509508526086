import React, { useState, useEffect, useRef } from 'react';
import { formatDate } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateTimePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { GridColumn } from "@progress/kendo-react-grid";
import { Switch, NumericTextBox, Input } from "@progress/kendo-react-inputs";
import DialogProjectStage from "./dialogProjectStage.jsx";
import gridFunctions from "../../components/gridFunctions.jsx";
import { GridBaseComponent } from "../../components/gridApiSource.jsx";
import {
    timeIntervalType,
    entitySourcesNames,
    entitySources,
    getFormatDateTime,
    dateTimeWidth,
    numericWidth,
    dateTimeFormat
} from
    "../../utils/systemConstants.jsx";
import { addInterval } from "../../components/extensions/dateTimeFunctions.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import MessageMask from "../../components/messageMask.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogCollateralStateReserve({ id, reserves, stateId, config, dateStart, dateEnd, multiReserve, onClose }) {
    const gridRef = useRef(null);

    const searchEntities = [
        { id: -1, name: window.captions.Unseted },
        { id: entitySources.client, name: window.captions.Client },
        { id: entitySources.loan, name: window.captionsDynamic.LoanSingleNominative }
    ];

    const [ranges, setRanges] = useState({ start: dateStart, end: dateEnd });
    const [daily, setDaily] = useState({ start: null, end: null });

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [confirmItem, setConfirmItem] = useState(null);

    const [columns, setColumns] = useState({ client: null, loan: null });
    const [states, setStates] = useState([]);
    const [state, setState] = useState(null);
    const [clientId, setClientId] = useState(0);
    const [groupId, setGroupId] = useState(0);
    const [canReserve, setCanReserve] = useState(false);
    const [canQueue, setCanQueue] = useState(false);
    const [isUseSchedule, setIsUseSchedule] = useState(false);
    const [validate, setValidate] = useState(false);

    const [timeLimit, setTimeLimit] = useState(0);
    const [timeLimitType, setTimeLimitType] = useState(1);

    const [searchEntity, setSearchEntity] = useState(-1);
    const [searchSource, setSearchSource] = useState(null);

    const [searchId, setSearchId] = useState(0);
    const [searchName, setSearchName] = useState("");
    const [search, setSearch] = useState("");

    const [duration, setDuration] = useState(0);
    const [count, setCount] = useState(0);
    const [countPerHour, setCountPerHour] = useState(0);

    const [scheduleTimeStart, setScheduleTimeStart] = useState(null);
    const [canCreateLoan, setCanCreateLoan] = useState(false);

    useEffect(() => {
        if (config && config.entityType >= 0) {
            changeSearchEntity({ target: { value: { id: config.entityType } } });
        }

        if (isNaN(stateId))
            fetchData(0);
        else
            fetchData(stateId);
    }, []);

    let title = window.captions.StatusReservation;
    if (pending || error) {
        return <Dialog title={title} onClose={() => onClose()} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const elements = [];
    let action = null;
    var rangesError = false;

    if (canCreateLoan && clientId > 0) {
        return <DialogProjectStage clientId={clientId} onClose={(saveId) => save(saveId)} />;
    }

    var culture = getUiCulture();
    renderPair(elements,
        window.captions.State,
        "state",
        () => <DropDownList style={{ width: "100%" }}
            textField="name"
            dataItemKey="id"
            data={states}
            value={state}
            onChange={changeState} />);

    if (config && config.entityType < 0) {
        var entity = searchEntities.find(f => f.id === searchEntity);
        renderPair(elements,
            window.captions.SearchEntity,
            "searchEntity",
            () => <DropDownList style={{ width: "100%" }}
                textField="name"
                dataItemKey="id"
                data={searchEntities}
                value={entity}
                onChange={changeSearchEntity} />);
    }

    if (multiReserve) {
        var rangesPanel = [];
        var now = new Date();
        reserves.forEach((i, key) => {
            var error = i.startDate < now;
            rangesError = rangesError || error;
            var style = error ? { color: "red" } : null;
            rangesPanel.push(
                <li key={key} style={style}>{formatDate(i.startDate, dateTimeFormat, culture)} - {formatDate(
                    i.endDate,
                    dateTimeFormat,
                    culture)}</li>);
        });
        renderPair(elements, window.captions.PeriodRanges, "ranges", null, <ol>{rangesPanel}</ol>);
    } else {
        renderPair(elements,
            window.captions.StartDate,
            "started",
            () => <DateTimePicker
                onChange={changeStart}
                min={new Date(Date.now())}
                value={ranges.start} width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />);

        renderPair(elements,
            window.captions.ExportSchedulerEndDate,
            "end",
            () => <DateTimePicker
                onChange={changeEnd}
                min={ranges.start ? ranges.start : new Date()}
                value={ranges.end}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />);
    }

    if (config && config.isLimitDisabled !== true && !rangesError) {
        renderPair(elements,
            window.captions.UseSchedule,
            "scheduleUseSwitch",
            () => <Switch checked={isUseSchedule} onChange={changeUseSchedule} />);

        if (isUseSchedule) {
            renderPair(elements,
                window.captions.DurationInSecond,
                "scheduleDuration",
                () => <NumericTextBox onChange={changeDuration}
                    min={0}
                    max={60}
                    width={numericWidth}
                    value={duration} />);
            const countMax = duration > 0 ? Math.trunc(3600 / duration) : 60;
            renderPair(elements,
                window.captions.NumberClips,
                "scheduleCount",
                () => <NumericTextBox onChange={changeCount}
                    min={1}
                    max={countMax}
                    width={numericWidth}
                    value={count} />);

            const countPerHourMax = duration > 0 && count > 0
                ? Math.trunc(3600 / (duration * count))
                : 60;
            renderPair(elements,
                window.captions.NumberExistPerHour,
                "scheduleCountPerHour",
                () => <NumericTextBox onChange={changeCountPerHour}
                    min={1}
                    max={countPerHourMax}
                    width={numericWidth}
                    value={countPerHour} />);

            renderPair(elements,
                window.captions.ExportSchedulerStartingAt,
                "dailyStart",
                () => <TimePicker value={daily.start} onChange={changeDailyStart} style={{
                    width: "100%"
                }} />);

            renderPair(elements,
                window.captions.ExportSchedulerEndingAt,
                "dailyEnd",
                () => <TimePicker value={daily.end} onChange={changeDailyEnd} style={{
                    width: "100%"
                }} />);

        }

        const timeLimitTypes = [
            { id: timeIntervalType.minute, name: window.captions.Minute },
            { id: timeIntervalType.hour, name: window.captions.Hour },
            { id: timeIntervalType.day, name: window.captions.Day },
            { id: timeIntervalType.month, name: window.captions.Month }
        ];
        const selectedLimitType = timeLimitTypes.find((i) => i.id === timeLimitType);

        renderPair(elements,
            window.captions.Limit,
            "limit",
            () => <div>
                <NumericTextBox onChange={changeTimeLimit}
                    min={1} max={360}
                    width={numericWidth}
                    value={timeLimit} />
                <DropDownList style={{ width: 90 }}
                    textField="name"
                    dataItemKey="id"
                    data={timeLimitTypes}
                    value={selectedLimitType}
                    onChange={changeTimeLimitType} />
            </div>);
    }

    if (searchSource && searchEntity >= 0 && !rangesError) {
        let fieldId = "";
        let columnsPanel = null;
        let fieldName = "FIO";
        let source = "";
        switch (searchEntity) {
            case entitySources.client:
                source = window.captions.Client;
                fieldId = "CID";
                columnsPanel = gridFunctions.renderGridColumns(columns.client);
                break;
            case entitySources.loan:
                source = window.captionsDynamic.LoanSingleNominative;
                fieldId = "LID";
                columnsPanel = gridFunctions.renderGridColumns(columns.loan);
                break;

        }

        const actionsCell =
            (p) => {
                return <td {...getLinkCellStyle(p)}>
                    <Button icon="check" fillMode="flat" onClick={() => selectEntity(p.dataItem,
                        fieldId,
                        fieldName)} />
                </td>;
            };

        elements.push(<div key="gridSearch" className="dm-container dm-no-padding">
            <div className="dm-wrapper">
                <div className="dm-wrapper-content">
                    <Input onChange={searchChanged}
                        onKeyDown={(e) => onSearchKey(e)}
                        placeholder={window.captions.Fio}
                        value={search}
                        style={{ width: "100%" }} />
                </div>
                <div>
                    <Button icon="find" title={window.captions.Search} onClick={() => findItem()} />
                </div>
            </div>

            <GridBaseComponent baseUrl={`${window.constants.getLinkedSearch}/${searchSource}/0/${searchSource}`}
                style={{ width: "100%", height: "400px", margin: "0 0 15px 0" }}
                ref={gridRef}>
                <GridColumn field="id" title=" " width="35" cells={{ data: actionsCell }} />
                {columnsPanel}
            </GridBaseComponent>
        </div>);

        if (searchId > 0) {
            renderPair(elements, source, "entity", null, <span className="text-success">{searchName}</span>);
        }
    }

    action = rangesError
        ? null
        : <Button onClick={handleStateReserveClick}>{window.captions.Reservation}</Button>;

    let modal = "";
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    return [
        <Dialog key="DialogCollateralStateReserve" title={title} onClose={() => onClose()} width={850}>
            <div className="dm-container dm-no-padding">
                {elements}
            </div>
            <DialogActionsBar>
                <Button onClick={() => onClose()}>{window.captions.Cancel}</Button>
                {action}
            </DialogActionsBar>
        </Dialog>,
        modal,
        <ConfirmMessage key="confirmDialog"
            yes={confirmOk}
            no={confirmClose}
            text={confirm} />
    ];

    function fetchData(stateId) {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getStateReserveModel}/${stateId}`,
            (response) => {
                setStates(response.types);
                setState(response.types.length > 0 ? response.types[0] : undefined);
                setColumns({ client: response.columnsClient, loan: response.columnsLoan });
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function onSearchKey(e) {
        if (e.keyCode !== 13)
            return;

        findItem();
    }

    function findItem() {
        gridRef.current.filterByField("fio", "contains", search);
    }

    function searchChanged(e) {
        const text = e.target.value && e.target.value.length > 0
            ? e.target.value
            : "";
        setSearch(text);
    }

    function selectEntity(dataItem, fieldId, fieldName) {
        setSearchId(parseInt(dataItem[fieldId]));
        setSearchName(dataItem[fieldName]);
    }

    function isValidDates() {
        return ranges.end && ranges.start && ranges.start < ranges.end;
    }

    function changeState(e) {
        setState(e.target.value);
    }

    function changeSearchEntity(e) {
        var id = e.target.value.id;
        let source = "";
        switch (id) {
            case entitySources.client:
                source = entitySourcesNames.client;
                break;
            case entitySources.loan:
                source = entitySourcesNames.loan;
                break;
        }
        if (source !== "") {
            setSearchSource(source);
            setSearchEntity(id);
        } else {
            setSearchSource(null);
            setSearchEntity(id);
        }
    }

    function changeStart(e) {
        setRanges({ ...ranges, start: e.value })
    }

    function changeEnd(e) {
        setRanges({ ...ranges, end: e.value })
    }

    function changeDuration(e) {
        setDuration(e.value);
    }

    function changeTimeLimit(e) {
        setTimeLimit(e.value);
    }

    function changeTimeLimitType(e) {
        setTimeLimitType(e.target.value.id);
    }

    function changeUseSchedule(e) {
        setIsUseSchedule(e.value);
    }

    function changeDuration(e) {
        setDuration(e.value);
    }

    function changeCount(e) {
        setCount(e.value);
    }

    function changeCountPerHour(e) {
        setCountPerHour(e.value);
    }

    function changeDailyStart(e) {
        setDaily({ ...daily, start: e.value })
    }

    function changeDailyEnd(e) {
        setDaily({ ...daily, end: e.value })
    }

    function setWarning(error) {
        setValidate(error);
    }

    function handleStateReserveClick() {
        if (!multiReserve) {
            save(0);
            return;
        }

        if (config.isLimitDisabled !== true && (!timeLimit || timeLimit === 0)) {
            setWarning(window.captions.ScheduleFillAllFields);
            return;
        }

        if (searchEntity < 0) {
            setWarning(window.captions.SearchEntityNotChosed);
            return;
        }

        if (!searchId) {
            setWarning(window.captions.SearchEntityNotChosed);
            return;
        }

        switch (searchEntity) {
            case entitySources.client:
                if (config.defaultProjectId > 0 && config.defaultStateId > 0) {
                    const loanData = {
                        clientId: searchId,
                        projectId: config.defaultProjectId,
                        stateId: config.defaultStateId
                    };
                    setPending(window.captions.SavingData);
                    setError(null);
                    fetchPost(window.constants.saveNewLoan,
                        loanData,
                        (loanId) => save(loanId),
                        (ex) => setError(ex));
                } else {
                    setClientId(searchId);
                    setCanCreateLoan(true);
                }
                break;
            case entitySources.loan:
                save(searchId);
                break;
        }
    }

    function save(saveId) {
        if (searchEntity < 0) {
            setWarning(window.captions.SearchEntityNotChosed);
            return;
        }

        if (!multiReserve && !isValidDates()) {
            setWarning(window.captions.StartAndEndDateRequired);
            return;
        }

        if (multiReserve && config.isLimitDisabled !== true) {
            const date = addInterval(new Date(), timeLimitType, timeLimit);
            const minReserveDate = daily.start
                ? new Date(ranges.start.getFullYear(),
                    ranges.start.getMonth(),
                    ranges.start.getDate(),
                    daily.start.getHours(),
                    daily.start.getMinutes(),
                    daily.start.getSeconds())
                : ranges.start;

            if (timeLimit === 0 || date >= minReserveDate) {
                setWarning(window.captions.CollateralReserveLimitDateError);
                return;
            }
        }

        if (isUseSchedule && (duration === 0 || count === 0 || countPerHour === 0)) {
            setWarning(window.captions.ScheduleFillAllFields);
            return;
        }

        const saveItems = [];
        if (multiReserve) {
            if (!reserves || reserves === 0)
                return;

            reserves.forEach(i => {
                const saveData = {
                    collateralId: i.collateralId,
                    loanId: saveId,
                    groupId: 0,
                    stateId: state.id,
                    canReserve: true,
                    canQueue: true,
                    startDate: i.startDate,
                    endDate: i.endDate,
                    isUseSchedule: isUseSchedule,
                    scheduleTimeStart: new Date(new Date().setHours(0, 0, 0, 0)),
                    scheduler: null,
                    timeLimit: timeLimit,
                    timeLimitType: timeLimitType
                };

                if (saveData.isUseSchedule) {
                    saveData.scheduler = {
                        duration: duration,
                        count: count,
                        countPerHour: countPerHour,
                        dailyStart: daily.start,
                        dailyEnd: daily.end
                    };
                }
                saveItems.push(saveData);
            });
        } else {
            const saveData = {
                collateralId: id,
                loanId: saveId,
                groupId: groupId,
                stateId: state.id,
                canReserve: canReserve,
                canQueue: canReserve,
                startDate: ranges.start,
                endDate: ranges.end,
                isUseSchedule: isUseSchedule,
                scheduleTimeStart: scheduleTimeStart
                    ? scheduleTimeStart
                    : new Date(new Date().setHours(0, 0, 0, 0)),
                scheduler: null,
                timeLimit: timeLimit,
                timeLimitType: timeLimitType
            };

            if (saveData.isUseSchedule) {
                saveData.scheduler = {
                    duration: duration,
                    count: count,
                    countPerHour: countPerHour,
                    dailyStart: daily.start,
                    dailyEnd: daily.end
                };
            }
            saveItems.push(saveData);
        }

        if (canQueue) {
            fetchPost(`${window.constants.collateralReserveQueue}`,
                saveItems[0],
                () => {
                    onClose();
                    return;
                },
                (ex) => setError(ex));
            return;
        }

        setPending(window.captions.StatusChecks);
        setError(null);
        fetchPost(`${window.constants.collateralStateReserve}/${multiReserve}`,
            saveItems,
            (data) => {
                if (data.stateReserved) {
                    onClose();
                    return;
                }

                if (!canReserve && data.canReserve) {
                    setCanReserve(data.canReserve);
                    setScheduleTimeStart(data.scheduleTimeStart);
                    setPending(null);
                } else if (!data.canReserve && !data.canQueue) {
                    setWarning(window.captions.PeriodTimeStatusReserved);
                    return;
                } else if (data.canQueue && data.groupId > 0) {
                    setConfirm(window.captions.PeriodTimeStatusReservedCanQueue);
                    setConfirmItem(data);
                } else {
                    setValidate(window.captions.PeriodTimeStatusReserved);
                    setPending(null);
                }
            },
            (ex) => setError(ex));
    }

    function confirmOk() {
        var data = confirmItem;
        confirmClose();
        setCanQueue(data.canQueue);
        setGroupId(data.groupId);
        setPending(null);
    }

    function confirmClose() {
        setConfirm(false);
        setConfirmItem(null);
    }
}