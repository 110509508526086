import React from "react";
import { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import MessageMask from "../../components/messageMask.jsx";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { renderInput } from "../cards/cardRenderInput.jsx";
import { renderViewer } from "../cards/cardRenderViewer.jsx";
import { getLabelClass } from "../cards/cardRenderFunctions.jsx";
import { renderContact } from "../cards/cardRenderContact.jsx";
import { entitySources } from "../../utils/systemConstants.jsx";
import CardReducerFunctions from "../cards/cardReducerFunctions.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";

export default function QuestionnaireDialogHistory({ loanId, projectId, clientId, source, entity, id, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [linkedClientType, setLinkedClientType] = useState(entitySources.client);
    const [fields, setFields] = useState([]);
    const [messageWarn, setMessageWarn] = useState("");
    const [messageWarnCount, setMessageWarnCount] = useState(0);
    const [customFields, setCustomFields] = useState([]);
    const [requiredFields, setRequiredFields] = useState(0);
    const [originalFields, setOriginalFields] = useState([]);
    const [updated, setUpdated] = useState([]);
    const [fieldsConfirm, setFieldsConfirm] = useState([]);
    const [fieldsRenouncement, setFieldsRenouncement] = useState([]);

    const [model, setModel] = useState({
        source: 0,
        projectId: 0,
        clientId: 0,
        sources: null,
        phones: null,
        addresses: null,
        emails: null,
        urls: null,
        accounts: null,
    });

    useEffect(() => fetchData(id), []);

    const title = window.captions.Questionnaire;
    const controls = [];
    let messageWarnPanel = [];
    const buttons = [];
    var errors = null;
    if (pending || error) {
        return <Dialog title={title} onClose={onCloseDialog} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    if (Object.keys(fields).length > 0) {
        messageWarnPanel = ShowWarningMessage(messageWarn);
        buttons.push(<Button key="ButtonSave"
            themeColor="primary"
            iconClass="dm-i dm-i-save"
            onClick={save}
            disabled={!isValid()}>
            {window.captions.Save}
        </Button>);

        buttons.push(<Button key="ButtonClose" onClick={onRefuse}>{window.captions.ButtonRefusedQuestioning}</Button>);

        for (let key in fields) {
            const fieldModel = fields[key];
            if (!fieldModel)
                continue;

            if (fieldModel.isContact) {
                controls.push(renderContact(`${"clients"}${key}`,
                    fieldModel,
                    model.sources,
                    model.phones,
                    model.addresses,
                    model.emails,
                    model.urls,
                    model.accounts,
                    fieldModel.isEditable,
                    { entity, id: id },
                    {
                        clickConfirmField,
                        clickFieldRenouncement,
                        changedValue
                    },
                    fieldsConfirm,
                    fieldsRenouncement));
                continue;
            }

            const value = fieldModel.value;
            const isEditedName = getLabelClass(fieldModel.isUpdated);

            var isConfirm = fieldsConfirm.includes(fieldModel.id);
            var isRenouncement = fieldsRenouncement.includes(fieldModel.id);
            var contentClass = fieldModel.isEditable ? "dm-wrapper-content" : "dm-wrapper-content dm-card-value";
            var control = fieldModel.isEditable
                ? renderInput(fieldModel, value, model.sources, { changedValue })
                : renderViewer(fieldModel, null);
            controls.push([
                <div key={`clients${key}Name`} className={isEditedName} title={fieldModel.name}>
                    <div>{fieldModel.name}</div>
                </div>,
                <div key={`clients${key}Editor`} className="dm-card-editor">
                    <div className="dm-wrapper">
                        <div className={contentClass}>
                            {control}
                        </div>
                        <Button className={`questionApprove${isConfirm ? " k-state-active" : ""}`}
                            onClick={() => clickConfirmField(fieldModel)}
                            icon="check"
                            title={window.captions.ButtonConfirm}>
                        </Button>
                        <Button className={`questionCancel${isRenouncement ? " k-state-active" : ""}`}
                            onClick={() => clickFieldRenouncement(fieldModel)}
                            icon="close"
                            title={window.captions.ButtonRenouncement}>
                        </Button>
                    </div>
                </div>
            ]);
        }
    } else {
        if (pending === null) {
            errors = <div className="dm-error">{window.captions.BlabkFieldsImported}</div>;
            buttons.push(<Button key="ButtonOk" themeColor="primary" onClick={onCloseDialog}>{window.captions.WordOk}</Button>);
        }
    }

    let modal = "";
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} />;
    }

    return [
        <Dialog title={title} key="questionnaireDialogHistory" onClose={onCloseDialog} width={800}>
            <div>
                <div className="dm-container dm-no-padding">
                    {controls}
                </div>
                {messageWarnPanel}
                {errors}
            </div>
            <DialogActionsBar>
                {buttons}
            </DialogActionsBar>
        </Dialog>,
        modal
    ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);

        const fetchProjectId = projectId ? projectId : 0;

        fetchGet(`${window.constants.getQuestionModel}/${source}/${fetchProjectId}/${loanId}/${clientId}`,
            (data) => {
                setFields(data.fields);
                setCustomFields(data.addonFields);
                setRequiredFields(data.requiredFields);
                setLinkedClientType(data.linkedClientType);
                setOriginalFields(data.originalsFields);
                setMessageWarnCount(data.maxQuestRenouncement);
                setMessageWarn(data.messageWarning);

                setModel({
                    source: data.source,
                    projectId: data.projectId,
                    clientId: data.id,
                    sources: data.sources,
                    phones: data.phones,
                    addresses: data.addresses,
                    emails: data.emails,
                    urls: data.urls,
                    accounts: data.accounts,
                });

                setPending(null);
                setError(null);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }

    function onRefuse() {
        save(true);
    }

    function onCloseDialog() {
        if (!isEdited)
            onClose(0);
    }

    function changeField(payload) {
        if (CardReducerFunctions.fieldEdited(fields, payload)) {
            setEntityFields(fields, id, entity);
        }
        setUpdated(true);
    }

    function changedValue(e) {
        if (e.item !== undefined) {
            changeContact(e);
        } else if (e.isPassport) {
            changePassportField(e);
        } else {
            changeField(e);
        }
    }

    function changePassportField(payload) {
        if (CardReducerFunctions.passportEdited(fields, payload)) {
            setEntityFields(fields, id, model.source);
        }
    }

    function changeContact(payload) {
        if (CardReducerFunctions.contactEdited(false,
            model.sources,
            fields,
            model.phones,
            model.addresses,
            model.emails,
            model.urls,
            model.accounts,
            payload)) {
            setEntityFields(fields, id, model.source);
        }
    }

    function clickFieldRenouncement(field) {
        if (fieldsRenouncement.includes(field.id))
            return;

        setFieldsRenouncement(fieldsRenouncement.concat(field.id));
        setFieldsConfirm(fieldsConfirm.filter(item => item !== field.id));
    }

    function clickConfirmField(field) {
        if (fieldsConfirm.includes(field.id))
            return;

        setFieldsRenouncement(fieldsRenouncement.filter(item => item !== field.id));
        setFieldsConfirm(fieldsConfirm.concat(field.id));
    }

    function setEntityFields(fields) {
        setFields(fields);
    }

    function ShowWarningMessage(props) {
        if (messageWarnCount < fieldsRenouncement.length) {
            return <div>
                {props}
            </div>;
        }

        return null;
    }

    function isValid() {
        const result = [];
        for (let key in requiredFields) {
            var fieldId = requiredFields[key];
            const fieldRecouncement = fieldsRenouncement.find((i) => i === fieldId);
            const fieldAccept = fieldsConfirm.find((i) => i === fieldId);
            if (fieldRecouncement)
                result.push(fieldRecouncement);
            if (fieldAccept)
                result.push(fieldAccept);
        }
        if (result.length < requiredFields.length)
            return false;

        return true;
    }

    function save(isRefused) {
        var saveData;
        if (isRefused) {
            saveData = CardSaveFunctions.renderValues(null);
        } else {
            const errorFields = CardSaveFunctions.validateRequired(fields, model, {});
            if (errorFields.length > 0) {
                setPending(null);
                setValidate(errorFields);
                return;
            }

            saveData = CardSaveFunctions.renderValues(fields);
        }

        saveData.clientId = model.clientId;
        saveData.loanId = loanId;
        saveData.projectId = model.projectId;
        saveData.linkedClientType = linkedClientType;
        saveData.source = model.source;
        saveData.updated = updated;
        saveData.fieldsRenouncement = fieldsRenouncement;
        saveData.confirmFields = fieldsConfirm;
        saveData.addonFields = customFields;
        saveData.originalFields = originalFields;
        saveData.addresses = model.addresses;
        saveData.phones = model.phones;
        saveData.emails = model.emails;
        saveData.urls = model.urls;
        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveQuestion}`,
            saveData,
            (saveId) => onClose(saveId),
            () => onClose(0));
    }
}