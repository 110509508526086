import React, { useState, useEffect } from 'react';
import FullAccessTreeView from "../../../../../components/controls/fullAccessTreeView.jsx";

export default function MainTabAdministrationSystemStrategy({ dataRule, strategiesList, ruleChanged }) {
    const [items, setItems] = useState([]);

    useEffect(() => mountProperties(), [dataRule, strategiesList]);

    return <div className="dm-container dm-no-padding">
        <FullAccessTreeView
            key="strategyAccessTreeView"
            dataTree={items}
            onCheckChange={onCheckChange}
        />
    </div>;

    function mountProperties() {
        if (!dataRule || !strategiesList) {
            setItems([]);
            return;
        }

        let itemsLocal = Array.isArray(strategiesList) && strategiesList.length ? strategiesList : [];

        itemsLocal.forEach(p => {
            let itemAccess = dataRule.accessStrategy.find(access => access.idList.some(id => id == p.idList[0]));
            setAccess(p, itemAccess);
            p.items?.forEach(strategy => {
                let strategyAccess = itemAccess?.items.find(item => item.id == strategy.id);
                setAccess(strategy, strategyAccess);
                strategy.items?.forEach(subStrategy => {
                    let subStrategyAccess = strategyAccess?.items.find(item => item.id == subStrategy.id);
                    setAccess(subStrategy, subStrategyAccess);
                });
            });
        });

        setItems(itemsLocal);
    }

    function setAccess(item, access) {
        if (!access) {
            item.viewing = false;
            item.editing = false;
            item.deleting = false;
            item.adding = false;
            return;
        }

        item.viewing = access.view;
        item.editing = access.edit;
        item.deleting = access.delete;
        item.adding = access.add;
    }

    function onCheckChange(selectedItems) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //stagesForAction: selected,
                isUpdated: true
            });
    }
}