import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { formatDate } from "@progress/kendo-intl";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { Upload } from "@progress/kendo-react-upload";
import { headersFile } from "../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import DialogTask from "./dialogTask.jsx";
import MessageMask from "../../components/messageMask.jsx";
import { FileIcon, defaultStyles } from "react-file-icon";
import FileHelper from "../../utils/fileHelper.jsx";
import { dateTimeFormat, parseDateTimeFormat } from "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";

export default function DialogChatPost({ postMessage, isEdit, isAdd, isComment, onClose, onSave }) {
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    const [showDialogMeeting, setShowDialogMeeting] = useState(false);
    const [addedMeeting, setAddedMeeting] = useState(false);
    const [meetingModel, setMeetingModel] = useState(null);

    const [title, setTitle] = useState("");
    const [titleImage, setTitleImage] = useState("");
    const [text, setText] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [users, setUsers] = useState([]);
    const [usersNotify, setUsersNotify] = useState([]);
    const [isImportant, setIsImportant] = useState(false);

    useEffect(() => {
        if (isEdit || isAdd || isComment) {
            setTitle(isAdd ? "" : postMessage.title);
            setText(isAdd ? "" : postMessage.text);
            setTitleImage(isAdd ? "" : postMessage.titleImage);
            if (isComment)
                fetchData(postMessage.id);

            fetchUsers();
        }
    }, []);

    let dialogTitle = window.captions.Message;
    if (pending || error) {
        return <Dialog title={dialogTitle} onClose={() => onClose()} width={500}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const elements = [];
    let actionBar = "";

    if (showDialogMeeting) {
        return <DialogTask taskId={0} projectId={0} isMeeting={true} onClose={(data) => { closeMeetingDialog(data) }} />;
    }

    if (isAdd || isComment || isEdit && postMessage.type !== 0) {
        renderPair(elements,
            window.captions.PostTitle,
            "chatTitleEditorKey",
            () => <MultilineTextArea id="chatTitleEditor" changeText={changeTitle} textLength={255} text={title} />,
            title,
            isAdd || isEdit);
    }

    if (isAdd || isEdit) {
        actionBar = <DialogActionsBar>
            <Button onClick={() => { onClose(); }}>{window.captions.Cancel}</Button>
            <Button themeColor="primary" onClick={save}>{(isAdd
                ? window.captions.Publish
                : window.captions.Save)
            }</Button>
        </DialogActionsBar>;

        if (isAdd) {
            elements.push(<div key="chatIsImportantEditor" className="dm-size-100">
                <Checkbox className="dm-card-value" value={isImportant} label={window.captions
                    .ImportantMessage} onChange={changeIsImportant} /></div>);

            renderPair(elements,
                window.captions.NotificationToUsers,
                "chatUsersEditorKey",
                () => <DropDownCheckedTree
                    id="chatUsersEditor"
                    treeData={users}
                    selectedIdList={usersNotify}
                    selectionChanged={changeUsersNotify}
                    style={{ width: "100%" }} />);

            renderPair(elements,
                window.captions.HeaderImage,
                "chatImageEditorKey",
                () => <Upload
                    id="chatImageEditor"
                    batch={false}
                    multiple={false}
                    restrictions={{ allowedExtensions: [".jpg", ".png"] }}
                    defaultFiles={[]}
                    onStatusChange={uploadImageStatusChange}
                    saveHeaders={headersFile()}
                    removeHeaders={headersFile()}
                    saveUrl={window.constants.chatFileUpload}
                    removeUrl={window.constants.fileRemove} />);

            renderPair(elements,
                window.captions.Attachments,
                "chatAttachmentEditorKey",
                () => <Upload
                    id="chatAttachmentEditor"
                    batch={false}
                    multiple={true}
                    defaultFiles={[]}
                    onStatusChange={uploadAttachStatusChange}
                    onRemove={removeAttachment}
                    saveHeaders={headersFile()}
                    removeHeaders={headersFile()}
                    saveUrl={window.constants.fileUpload}
                    removeUrl={window.constants.fileRemove} />);

            if (addedMeeting) {
                elements.push(<div key="meetingData" className="dm-size-100 alert alert-success">
                    <i className={`dm-i dm-i-calendar-day`} />
                    {window.captions.AddedMeeting}
                </div>);
            } else {
                elements.push(<div key="meetingData" className="dm-size-100">
                    <Button icon="add" onClick={() => setShowDialogMeeting(true)}>
                        {window.captions.Meeting}
                    </Button>
                </div>);
            }
        }

    }

    if (isAdd || isEdit || text) {
        renderPair(elements,
            window.captions.Comment,
            "commentEditorKey",
            () => <MultilineTextArea id="commentEditor" changeText={changeText} text={text} />,
            text,
            isAdd || isEdit);
    }

    if (isComment) {
        if (postMessage &&
            postMessage.attachmentsList &&
            postMessage.attachmentsList.length > 0) {
            var attachmentsPanel = [];
            postMessage.attachmentsList.forEach((item) => {
                const fileName = item.fileName.substring(item.fileName.lastIndexOf("/") + 1);
                const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
                attachmentsPanel.push(
                    <Button themeColor="primary" key={`attachment${item.id}`} title={item.name} onClick={() => FileHelper
                        .getFileChat(
                            item.id)}>
                        <span className="dm-icon">
                            <FileIcon extension={fileType} {...defaultStyles[fileType
                            ]} />
                        </span>
                    </Button>);
            });

            elements.push(<div key="attachmentData" className="dm-size-100">{attachmentsPanel}</div>);
        }
        elements.push(<div className="dm-size-100 dm-wrapper" key="commentData">
            <Input className="comment-input dm-wrapper-content"
                property="postCode"
                placeholder={window.captions.EnterMessage}
                onChange={e => setComment(e.value)}
                value={comment} />
            <Button onClick={addCommentClick} className="dm-m-left">
                <i className={`dm-i dm-i-paper-plane`} />
            </Button>
        </div>);

        var dialogMessages = [];
        if (postMessage) {
            for (let key in comments) {
                const item = comments[key];
                dialogMessages.push(<div key={key} className="k-message-group k-no-avatar">
                    <p className="k-author">{item.authorName}</p>
                    <div className="k-first k-message">
                        <time className="k-message-time" aria-hidden="false" style={{
                            opacity: 1
                        }}>{formatDate(new Date(item.created),
                            dateTimeFormat,
                            getUiCulture())}</time>
                        <div className="k-bubble comment">{item.text}</div>
                    </div>
                </div>);
            }
        }
        if (dialogMessages.length > 0) {
            elements.push(<div key="postMessages" className="dm-size-100 k-chat">{dialogMessages}</div>);
        }
    }

    return <Dialog title={dialogTitle} onClose={() => onClose()} width={550}>
        <div className="dm-container dm-no-padding">
            {elements}
        </div>
        {actionBar}
    </Dialog>;

    function fetchData(messageId) {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.chatComments}/${messageId}`,
            (items) => {
                setComments(items);
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function fetchUsers() {
        fetchGet(window.constants.chatUsers,
            (items) => setUsers(items),
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function uploadImageStatusChange(e) {
        if (!e.response)
            return;

        if (e.response.response.length === 1) {
            setTitleImage(e.response.response[0].name);
        }
    }

    function uploadAttachStatusChange(e) {
        if (!e.response)
            return;

        var items = [];
        var files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;
            items.push({
                fileName: file.fileName,
                name: file.name,
                size: file.size,
                created: new Date()
            });
        }
        setAttachments([...attachments, ...items]);
    }

    function removeAttachment(e) {
        const items = [...attachments];
        const removeFiles = e.affectedFiles;
        for (let key in removeFiles) {
            const file = removeFiles[key];
            const attach = items.find((i) => i.name === file.name.replace(file.extension, "") &&
                i.fileName === file.fileName &&
                i.size === file.size);

            if (!attach)
                continue;

            const index = items.indexOf(attach);
            items.splice(index, 1);
        }
        setAttachments(items);
    }

    function addCommentClick() {
        if (!comment)
            return;

        const comment = {
            id: 0,
            chatId: onSave.chatId,
            messageId: onSave.id,
            created: formatDate(new Date(), parseDateTimeFormat),
            text: comment,
            isDeleted: false
        };
        fetchPost(window.constants.addComment,
            comment,
            (data) => {
                if (data) {
                    setComments([...comments, data]);
                    setComment("");
                }
            },
            () => { });
    }

    function changeTitle(e) {
        setTitle(e.value);
    }

    function changeText(e) {
        setText(e.value);
    }

    function changeIsImportant(e) {
        setIsImportant(e.value);
    }

    function changeUsersNotify(e) {
        setUsersNotify(e.value);
    }

    function closeMeetingDialog(data) {
        setShowDialogMeeting(false);
        if (!data || !data.typeId) {
            setAddedMeeting(false);
            return;
        }

        setAddedMeeting(true);
        setMeetingModel(data);
    }

    function save() {
        let post = postMessage;
        if (!title && (!isEdit || !(post && post.type === 0))) {
            alert(window.captions.PostTitleRequired);
            return;
        }

        if (isAdd) {
            post = {
                id: 0,
                isUpdated: false,
                isDeleted: false,
                titleImage: "",
                titleImageValue: "",
                type: meetingModel ? 5 : 4,
                title: "",
                text: "",
                attachmentsList: attachments,
                usersNotify: usersNotify,
                meetingModel: meetingModel,
                isImportant: isImportant
            };
        } else {
            post.isUpdated = true;
        }

        post.text = text;
        post.titleImage = titleImage;
        post.title = title;

        onSave(post);
    }
}