import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { DialogActionsBar, Dialog } from "@progress/kendo-react-dialogs";
import { TextBox } from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarUtils, StackLayout } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import { getUiSources, getUiLanguages } from "../../../utils/systemConstants.jsx";
import LangItemEditor from "../../../components/controls/langItemEditor.jsx"
import InlineDialog from "../../../components/inlineDialog.jsx";
import FieldBlockComponent from "./controls/blockFieldControl.jsx";
import { EditListViewStateItem } from "./controls/editListViewItem.jsx";

export default function StageSettingsComponent({ item, currentUi, names, codes, isMobile, onSave, addState, onClose }) {
    const [id, setId] = useState(-1);
    const [name, setName] = useState("");
    const [langItem, setLangItem] = useState({});
    const [description, setDescription] = useState("");
    const [code, setCode] = useState("");
    const [stageBlockList, setStageBlockList] = useState(null);
    const [states, setStates] = useState([]);
    const [isCanDelete, setIsCanDelete] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => fetchData(), [item]);

    let content = [];
    const style = { width: "100%" };

    renderPair(content,
        window.captions.Identifier,
        `FieldIdValue_${id}`,
        () => <TextBox style={style} value={id} readOnly={true} />,
        true);

    renderPair(content,
        window.captions.Caption,
        `StageNameEditor_${name}`,
        () => <LangItemEditor key={`langEditorStage${id}`}
            defaultName={name}
            style={style}
            langItem={langItem}
            namedChanged={changeName} />);

    renderPair(content,
        window.captions.Description,
        "StageDescription",
        () => <TextBox style={style} value={description} onChange={changeDescription} />);

    renderPair(content,
        window.captions.Code,
        "StageCode",
        () => <TextBox style={style} value={code} onChange={changeCode} />);

    if (states) {
        renderPair(content,
            "",
            "StatesItems",
            () => <StackLayout orientation={"vertical"} key="StatesItemsLayout">
                <Button icon="add" onClick={stateAdded}>{window.captions.Add}</Button>
                <PanelBar style={style} children={PanelBarUtils.mapItemsToComponents(panelBarItem())} />
            </StackLayout>);
    }

    const dialogActions = <DialogActionsBar>
        <Button icon="undo" onClick={() => onClose(true)}>{window.captions.Cancel}</Button>
        <Button icon="save" disabled={!isEdited} className="dm-positive-filled" onClick={save}>{window.captions.Save}</Button>
        {id > 0 && isCanDelete
            ? <Button icon="delete" className="dm-negative-filled" onClick={deleteItem}>{
                window.captions.Delete}</Button>
            : null}
    </DialogActionsBar>;
    let dialog;

    if (isMobile)
        dialog = <Dialog title={window.captions.MainMenuConfig}
            key="dialogEditorStages"
            buttons={dialogActions}
            onClose={() => onClose(false)}>
            <div className="dm-container dm-container-slim dm-m-top">
                {content}
            </div>
        </Dialog>;
    else
        dialog = <InlineDialog title={window.captions.MainMenuConfig}
            key="dialogEditorStages"
            fullHeight={true}
            buttons={dialogActions}
            onClose={() => onClose(false)}>
            <div className="dm-container dm-container-slim dm-m-top">
                {content}
            </div>
        </InlineDialog>;
    if (id >= 0)
        return dialog;
    else
        return null;

    function fetchData() {
        if (!item)
            return;

        setId(item.id);
        setName(item.name);
        setLangItem(item.langItem);
        setDescription(item.description);
        setCode(item.code);
        setStates(item.states);
        setStageBlockList(item.blockDictionary);
        setIsCanDelete(item.blockDictionary ? item.blockDictionary.length <= 0 : false);
        setIsEdited(item.id !== id ? false : isEdited);
    }

    function getNames() {
        let namesArray = [];
        states.map(i => {
            if (i.langItem.items) {
                var langValue = i.langItem.items.find(f => f.id === currentUi.id);
                if (langValue) {
                    namesArray.push({
                        id: i.id,
                        name: langValue.name
                    });

                } else {
                    namesArray.push({
                        id: i.id,
                        name: i.name
                    });
                }
            } else {
                namesArray.push({
                    id: i.id,
                    name: i.name
                });
            }
        });

        return namesArray;
    }

    function getCodes() {
        let codesArray = [];
        states.map(i => {
            if (i.code)
                codesArray.push({
                    id: i.id,
                    value: i.code
                });
        });

        return codesArray;
    }

    function stateItem(changedItem) {
        return <EditListViewStateItem
            key={`StateItem_${changedItem.dataItem.id}`}
            item={changedItem}
            currentUi={currentUi}
            codes={getCodes()}
            names={getNames()}
            stateId={changedItem.dataItem.id}
            saveItem={onSaveItem} />;
    }

    function fieldBlockItem() {
        return {
            title: window.captions.ReferencesOnField,
            content: <FieldBlockComponent style={{ width: "100%" }}
                blockDict={stageBlockList}
                fieldId={id}
                onCheckCanDelete={onCheckCanDelete} />
        }
    }

    function panelBarItem() {
        let list = [];

        if (states && states.length > 0)
            list.push({
                title: window.captions.StatesOnStage,
                content: <ListView data={states} item={stateItem} />
            });

        if (stageBlockList && stageBlockList.length > 0)
            list.push(fieldBlockItem());

        return list;
    }

    function onSaveItem(model) {
        var newValue = [];
        states.map((i, index) => {
            if (index === model.index) {
                newValue.push(model.dataItem);
            } else {
                if (model.dataItem.isDefaultState)
                    i.isDefaultState = false;
                newValue.push(i);
            }
        });

        setStates(newValue);
        setIsEdited(true);
    }

    function getSaveModel(isDeleted = false) {
        langItem.items = langItem.items.sort(function (a, b) {
            return a.id - b.id || a.name.localeCompare(b.name);
        });
        let itemName = langItem.items.find((i) => i.name);

        return {
            id: id,
            name: itemName ? itemName.name : null,
            langItem: langItem,
            code: code,
            description: description,
            states: states,
            isDeleted: isDeleted
        }
    }

    function save() {
        var saveModel = getSaveModel();

        if (!saveModel.langItem.items) {
            alert(window.captions.MessageBoxEmptyNameDeny);
            return;
        }

        if (!saveModel.name || !saveModel.langItem.items) {
            alert(window.captions.MessageBoxEmptyNameDeny);
            return;
        }

        saveModel.langItem.items.map(i => {
            if (typeof i.name === "string" && i.name.length === 0) {
                var langKey = getUiSources().find(lang => lang.id === i.id);
                var language = getUiLanguages().find(lang => lang.link === langKey.name);
                alert(`${window.captions.MessageBoxEmptyNameDeny} (${language.name})`);
            }
        });

        let newName = saveModel.name;
        let selectedLang = saveModel.langItem.items.find(f => f.id === currentUi.id);
        if (selectedLang)
            newName = selectedLang.name;

        let findNameDuplicate = names.find(f => f.name === newName && f.id !== saveModel.id);
        if (findNameDuplicate) {
            alert(`${window.captions.MessageBoxDuplicateNameDeny} (${findNameDuplicate.name})\r\nId: ${findNameDuplicate.id}`);
            return;
        }

        let findCodeDuplicate = codes.find(f => f.value === saveModel.code && f.id !== saveModel.id);
        if (findCodeDuplicate) {
            alert(`${window.captions.MessageBoxDuplicateCodeDeny} (${findCodeDuplicate.value})\r\nId: ${findCodeDuplicate.id}`);
            return;
        }
        let hasError = false;
        saveModel.states.map(state => {
            if (!canSave) {
                hasError = true;
            }
        });

        if (hasError)
            alert(window.captions.SaveDataError);
        else
            onSave(saveModel);
    }

    function deleteItem() {
        if (!isCanDelete) {
            var items = [];

            fieldBlockList.map(i => {
                let fields = [];
                i.items.map(i => fields.push(`- ${i}\r\n`));
                items.push(`${i.module.name}:\r\n${fields.join('')}`);
            });

            alert(window.captions.MessageBoxRemoveFieldBlock.replaceAll("{0}", "") + items.join(''));
            return;
        }

        if (state && !confirm(window.captions.DeleteItemConfirm))
            return;
        var saveModel = getSaveModel(true);

        onSave(saveModel);
    }

    function stateAdded() {
        let stateNames = [];
        states.map(i => {
            stateNames.push({ id: stateNames.length, name: i.name });
        });

        addState({
            names: stateNames,
            stage: getSaveModel()
        });
    }

    function changeName(e) {
        setLangItem(e.selectLang);
        setIsEdited(true);
    }

    function changeCode(e) {
        setCode(e.value);
        setIsEdited(true);
    }

    function changeDescription(e) {
        setDescription(e.value);
        setIsEdited(true);
    }

    function onCheckCanDelete(changeItem) {
        setStageBlockList(changeItem);
        setIsCanDelete(changeItem.length > 0);
        setIsEdited(true);
    }
}