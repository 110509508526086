import React from 'react';
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function EgripFieldControl({ columns }) {
    return <>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>ЕГРИП</Label>
            <DropDownList id="dropdown-basic-buttonfielg567"
                data={columns}
                style={{ margin: 5, marginLeft: 70, width: 640 }} />
        </div>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>Идентификатор</Label>
            <DropDownList id="dropdown-basic-buttonfielg566"
                data={columns}
                style={{ margin: 5, width: 640 }} />
        </div>
    </>
}