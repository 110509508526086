import React from "react";
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";

import { useState, useEffect } from 'react';

export default function ClientsInRegionTab({ dataUser, regionsList, userChanged }) {
    const data = [
        { id: 1, name: "Steven White", selected: true, sourceType: 0 },
        { id: 2, name: "Nancy King", selected: false, sourceType: 1 },
        { id: 3, name: "Nancy Davolio", selected: false, isSelected: true, sourceType: 0 },
        { id: 14, name: "Robert Davolio", selected: false, sourceType: 0 },
        { id: 15, name: "Michael Leverling", selected: false, sourceType: 0 },
        { id: 16, name: "Andrew Callahan", selected: false, isSelected: true, sourceType: 1 },
        { id: 17, name: "Michael Suyama", selected: false, sourceType: 0 }
    ];

    const [regions, setRegions] = useState([]);

    useEffect(() => mountProperties(), [dataUser, regionsList]);

    //sourceTypes={getCardFieldsSourceTypes()}
    //availableItemRender={myCustomItem} 
    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={regions}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;

    function mountProperties() {
        if (!dataUser) {
            setRegions([]);
            return;
        }

        let regionsList = Array.isArray(regionsList) && regionsList.length ? regionsList : [];
        let items = regionsList.map((item) => {
            const elementIndex = dataUser.regions.findIndex((i) => i == item.id);
            const index = elementIndex == -1 ? null : elementIndex;
            const isSelected = elementIndex != -1;
            return {
                id: item.id,
                name: item.name,
                isSelected: isSelected,
                index: index,
            };
        });

        setRegions(regions);
    }

    function selectedItemsChanged(selectedItems) {
        if (userChanged)
            userChanged({
                ...dataUser,
                regions: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    //function myCustomItem(props) {
    //    let { dataItem, selected, ...others } = props;
    //    return (
    //        <li {...others}>
    //            <div>
    //                <span
    //                    style={{
    //                        fontWeight: "bold",
    //                    }}
    //                >
    //                    {props.dataItem.name}_2
    //                </span>
    //            </div>
    //        </li>
    //    );
    //};
}