import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";

export default function UserStates({ dataRule, userStates, ruleChanged }) {
    const stateTabs = {
        availableStatesTab: 0,
        userAvailableStates: 1,
    };

    const [selectedTab, setSelectedTab] = useState(stateTabs.availableStatesTab);
    const [userStatesList, setUserStatesList] = useState([]);
    const [userAvailableStates, setUserAvailableStates] = useState([]);

    useEffect(() => mountProperties(), [dataRule]);

    return <div className="dm-container dm-no-padding">
        <TabStrip style={{ height: "-webkit-fill-available" }}
            selected={selectedTab}
            onSelect={tabSelected}
            scrollable={true}>
            <TabStripTab title={window.captions.AvailableStates} key={stateTabs.availableStatesTab}>
                <ItemSelector
                    items={userStatesList}
                    selectedItemsChanged={userStatesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.UserAvailableStates} key={stateTabs.userAvailableStates}>
                <ItemSelector
                    items={userAvailableStates}
                    selectedItemsChanged={userAvailableStatesChanged} />
            </TabStripTab>
        </TabStrip>
    </div>;

    function mountProperties() {
        if (!dataRule || !userStates) {
            setUserStatesList([]);
            setUserAvailableStates([]);
            return;
        }

        const userStatesLocal = Array.isArray(userStates) && userStates.length ? userStates : [];
        let userStatesList = userStatesLocal.map(i => {
            return {
                ...i,
                isSelected: dataRule.userStates.some(id => id == i.id),
            };
        });
        let userAvailableStates = userStatesLocal.map(i => {
            return {
                ...i,
                isSelected: dataRule.userAvailableStates.some(id => id == i.id),
            };
        });

        setUserStatesList(userStatesList);
        setUserAvailableStates(userAvailableStates);
    }

    function userStatesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                userStatesList: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function userAvailableStatesChanged(selectedItems) {
        let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                userAvailableStates: selected,
                isUpdated: true
            });
    }

    function tabSelected(e) {
        setSelectedTab(e.selected);
    }
}