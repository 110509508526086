import React, { useState } from 'react';
import { fetchGet } from "../../../../utils/requestsHelper.jsx";
import AccessParametersTab from "./userTabs/accessParametersTab.jsx";
import AccountableUsersTab from "./userTabs/accountableUsersTab.jsx";
import ClientsInRegionTab from "./userTabs/clientsInRegionTab.jsx";
import DocumentsTab from "./userTabs/documentsTab.jsx";
import PersonalDataTab from "./userTabs/personalDataTab.jsx";
import UserFieldsTab from "./userTabs/userFieldsTab.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import MessageMask from "../../../../components/messageMask.jsx";
import LoadingMask from "../../../../components/loadingMask.jsx";

export default function UserControl({ dataUser, rules, groupList, selectRuleItem, userChanged, typesHidden }) {
    const userTabs = {
        accessParameters: 0,
        accountableUsers: 1,
        clientsInRegion: 2,
        documents: 3,
        personalData: 4,
        userFields: 5
    };

    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    const [regionsList, setRegionsList] = useState(null);
    const [documentTypes, setDocumentTypes] = useState(null);
    const [defaultTypeId, setDefaultTypeId] = useState(0);

    const [selectedTab, setSelectedTab] = useState(userTabs.accessParameters);

    if (!dataUser)
        return <div className="dm-container dm-no-padding">
            <MessageMask inline text={window.captions.LoadDataError} />
        </div>;

    const tabs = renderTabs();
    return <div style={{ height: "calc(100% - 106px)" }} >
        <TabStrip
            style={{ height: "-webkit-fill-available" }}
            selected={selectedTab}
            onSelect={tabSelected}
            scrollable={true}
        >
            {tabs.items}
        </TabStrip>
    </div>;

    function renderTabs() {
        var tabs = {
            items: [
                renderTabStripTabs(userTabs.accessParameters, window.captions.AccessParams),
                renderTabStripTabs(userTabs.accountableUsers, window.captions.RelativeUsers),
                renderTabStripTabs(userTabs.clientsInRegion, window.captions.UserForRegion),
                renderTabStripTabs(userTabs.documents, window.captions.Documents),
                renderTabStripTabs(userTabs.personalData, window.captions.PersonalData),
                renderTabStripTabs(userTabs.userFields, window.captions.UserDistributionParameters)
            ],
            selected: null
        };

        //if (selectedTab) {
        //    const content = renderTabControl( selectedTab);
        //    tabs.selected = <div className="dm-container-full-size">
        //        {content}
        //        </div>;
        //}

        return tabs;
    }

    function renderTabStripTabs(index, text) {
        const content = renderTabControl({ index, text, selected: index === selectedTab });
        return <TabStripTab title={text} key={index}>
            {content}
        </TabStripTab>;
    }

    function renderTabControl(tabData) {
        switch (tabData.index) {
            case userTabs.accessParameters:
                return <AccessParametersTab
                    dataUser={dataUser}
                    rules={rules}
                    groupList={groupList}
                    userChanged={userChanged}
                    selectRuleItem={selectRuleItem} />;
            case userTabs.accountableUsers:
                return <AccountableUsersTab dataUser={dataUser} groupsList={groupList} userChanged={userChanged} />;
            case userTabs.clientsInRegion:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(regionsList),
                    () => getRegionsList(),
                    () => <ClientsInRegionTab
                        dataUser={dataUser}
                        regionsList={regionsList}
                        userChanged={userChanged} />
                );
            case userTabs.documents:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(documentTypes),
                    () => getDocumentTypes(),
                    () => <DocumentsTab
                        dataUser={dataUser}
                        documentTypes={documentTypes}
                        defaultTypeId={defaultTypeId}
                        userChanged={userChanged} />
                );
            case userTabs.personalData:
                return <PersonalDataTab dataUser={dataUser} userChanged={userChanged} />;
            case userTabs.userFields:
                return <UserFieldsTab dataUser={dataUser} userChanged={userChanged} />;
            default:
                return null;
        }
    }

    function getTabOrStartLoading(tabData, isDataLoadedFuction, startLoadingData, getTab) {
        if (isDataLoadedFuction()) {
            return getTab();
        }

        if (pending || error) {
            return <div className="dm-container dm-no-padding">
                <MessageMask inline text={pending} error={error} />
            </div>;
        }

        if (tabData.selected)
            startLoadingData();

        return <LoadingMask />;
    }

    // #region DataLoadingMethods

    function getRegionsList() {
        fetchGet(`${window.constants.getRegionsList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setRegionsList(items);
                setError(null);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getDocumentTypes() {
        fetchGet(`${window.constants.getDocumentModel}/`,
            (data) => {
                if (haveAccessError(data))
                    return;

                var types = data.types;
                //if (typesHidden) {
                //    types = types.filter(t => typesHidden.indexOf(t.id) === -1);
                //    if (types.indexOf(data.defaultTypeId) === -1)
                //        data.defaultTypeId = types[0].id;
                //}
                setDocumentTypes(types);
                setDefaultTypeId(data.defaultTypeId);
                setError(null);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    // #endregion

    function haveAccessError(data) {
        if (!data?.accessError)
            return false;

        setPending(data.accessError);
        return true;
    }

    function tabSelected(e) {
        setSelectedTab(e.selected);
    }
}