import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import { useState } from 'react';
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { GridBaseComponent } from "../../../components/gridApiSource.jsx";
import GridContactInfo from "../../../components/cells/gridContactInfo.jsx";
import GridClientCardCell from "../../../components/cells/gridClientCardCell.jsx";
import { fetchDelete } from "../../../utils/requestsHelper.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const OwnerControl = forwardRef(function OwnerControl(props, ref) {
    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const access = useSelector(state => state.header.model.access);
    const viewClient = access.viewClient;
    const deleteOwners = access.deleteOwners;
    const viewCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="menu" fillMode="flat" onClick={() => props.edit(p.dataItem["id"])} />
        </td>;

    const removeCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="delete" fillMode="flat" onClick={() => handleOnDelete(p.dataItem)} />
        </td>;

    const columns = [];
    columns.push(<GridColumn key="name" field="name" title={window.captions.Caption} width="240" />);
    columns.push(<GridColumn key="contant" field="id" title={window.captions.MainTabCardContact}
        cells={{ data: (p) => <GridContactInfo {...p} sources={props.sources} /> }} />);
    if (deleteOwners && props.isEditMode)
        columns.push(<GridColumn locked={true} key="remove" field="id" title=" " width="35" cells={{ data: removeCell }} />);

    if (viewClient) {
        columns.push(<GridColumn locked={true} key="view" field="id" title=" " width="35" cells={{ data: viewCell }} />);
        columns.push(<GridColumn locked={true} key="action" field="id" title=" " width="35" cells={{ data: GridClientCardCell }} />);
    }

    return [
        <GridBaseComponent baseUrl={`${window.constants.cardOwner}/${props.id}`}
            key="gridOwners"
            ref={ref}
            className="dm-full-wh">
            {columns}
        </GridBaseComponent>,
        <ConfirmMessage key="confirmRemove"
            yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    ];

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem["id"]);
        if (!isNaN(id)) {
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function deleteItem() {
        confirmClose();
        fetchDelete(`${window.constants.cardOwner}/${props.id}/${id}`,
            deleteId,
            () => { gridControl.current?.refreshDataSource(); },
            () => { });
    }

    function confirmClose() {
        setDeleteId(0);
        setConfirm(false);
    }
})

export default OwnerControl;