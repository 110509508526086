import React, { useState, useRef } from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import BaseFieldsControl from "./baseFieldsControl.jsx";
import AddressBaseFieldControl from "./baseFieldAddressControl.jsx";

const AddressDetailRow = (props) => {
    const fieldLoan =
        [
            { id: 1, foo: "address", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 2, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 3, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 4, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 5, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 6, foo: "", expanded: undefined, bar: "BaseFieldLoan" }
        ];
    return <BaseFieldsControl data={fieldLoan} />;
}

export default function AddressControl(props) {
    const gridControl = useRef(null);
    const [dataAddresses, setDataAddresses] = useState();

    const buttonCellStyle = {
        margin: 5,
        width: 20,
        height: 20
    };
    const addressBaseFieldCell = () => <AddressBaseFieldControl props={props} />;

    const checkboxDropDownCell = () => <td >
        <Checkbox style={{ margin: 2 }} />
        <DropDownList id="dropdown-basic-buttonfielg567" style={{ width: 118, height: 30 }} />
    </td>;

    const buttonCell = (p) => <td>
        <div>
            <Button onClick={() => removeAddress(p)} className="dm-positive-filled" style={buttonCellStyle} />
        </div>
    </td>;

    return <div className="dm-full-height">
        <div>
            <Button className="dm-positive-filled" onClick={addAddress} style={{ margin: 5, width: 120 }
            }>Add addresses</Button>
        </div>
        <Grid ref={gridControl}
            expandField="expanded"
            onExpandChange={expandChangeAddresses}
            detail={AddressDetailRow}
            className="dm-full-height"
            data={dataAddresses}>
            <GridColumn field="bar" width="162" cells={{ data: checkboxDropDownCell }} />
            <GridColumn field="bar" width="162" cells={{ data: checkboxDropDownCell }} />
            <GridColumn field="bar" width="162" cells={{ data: checkboxDropDownCell }} />
            <GridColumn field="bar" width="790" cells={{ data: addressBaseFieldCell }} />
            <GridColumn field="bar" width="50" cells={{ data: buttonCell }} />
        </Grid>
    </div>;

    function expandChangeAddresses(event) {
        const newData = dataAddresses.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setDataAddresses(newData);
    }

    function addAddress() {
        const newAddresses = {
            id: dataAddresses.length + 1,
            foo: "",
            expanded: undefined,
            bar: "BaseFieldLoan"
        };

        setDataAddresses([newAddresses, ...dataAddresses]);
    }

    function removeAddress(event) {
        setDataAddresses(dataAddresses.filter(x => x.id !== event.dataItem.id))
    }
}