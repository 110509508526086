import React, { useState, useRef } from 'react';

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import BaseFieldsControl from "./baseFieldsControl.jsx";

const ProcessDetailRow = (props) => {
    const dataItem1 = props;

    const testClass =
        [
            { id: 1, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 2, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 3, foo: "", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 4, foo: "grip", expanded: undefined, bar: "BaseFieldLoan" },
            { id: 5, foo: null, expanded: undefined, bar: "BaseFieldLoan" },
            { id: 6, foo: "", expanded: undefined, bar: "BaseFieldLoan" }
        ];


    return <BaseFieldsControl data={testClass} />;
}

export default function ContactsControl({ entity }) {
    const gridControl = useRef(null);

    const [dataPhones, setDataPhones] = useState([]);

    const labelCell = (p) => <td >
        <div>
            <Checkbox
                style={{ margin: 5 }} />
            <DropDownList id="dropdown-basic-buttonfielg567"
                style={{ width: 70 }} />
        </div>
    </td>;

    const dropDownList = (p) => <td>
        <div>
            <DropDownList id="dropdown-basic-buttonfielg567"
                style={{ margin: 5, width: 820 }} />
        </div>
    </td>;

    const dropDownListSmall = (p) => <td>
        <div>
            <DropDownList id="dropdown-basic-buttonfielg567"
                style={{ margin: 5, width: 115 }} />
        </div>
    </td>;

    const buttonCell = (p) => <td>
        <div>
            <Button className="dm-positive-filled" onClick={() => removePhone(p)} style={{
                margin: 5,
                width: 30
            }} />
        </div>
    </td>;
    let nameButton = " ";
    if (entity === "phone") {
        const ff = "AddPhone";
        nameButton = ff;
    }
    if (entity === "email") {
        const ff = "AddEmail";
        nameButton = ff;
    }
    if (entity === "urls") {
        const ff = "AddLink";
        nameButton = ff;
    }

    return <div className="dm-full-wh">
        <div>
            <Button className="dm-positive-filled" onClick={addPhone} style={{ margin: 5, width: 100 }
            }>{nameButton}</Button>
        </div>
        <Grid ref={gridControl}
            expandField="expanded"
            onExpandChange={expandChangePhones}
            detail={ProcessDetailRow}
            className="dm-full-height"
            data={dataPhones}>
            <GridColumn title=" " field="bar" width="120" cells={{ data: labelCell }} />
            <GridColumn title=" " field="bar" width="120" cells={{ data: labelCell }} />
            <GridColumn title=" " field="bar" width="120" cells={{ data: labelCell }} />
            <GridColumn title=" " field="bar" width="820" cells={{ data: dropDownList }} />
            <GridColumn title=" " field="bar" width="115" cells={{ data: dropDownListSmall }} />
            <GridColumn title=" " field="bar" width="60" cells={{ data: buttonCell }} />
        </Grid>
    </div>;

    function expandChangePhones(event) {
        if (dataPhones === undefined)
            return;

        const newData = dataPhones.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });

        setDataPhones(newData);
    }

    function addPhone() {
        const newPhone = {
            id: dataPhones.length + 1,
            foo: "",
            expanded: undefined,
            bar: "BaseFieldLoan"
        };

        setDataPhones([newPhone, ...dataPhones]);
    }

    function removePhone(event) {
        setDataPhones(dataPhones.filter(x => x.id !== event.dataItem.id));
    }
}