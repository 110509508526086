import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";
import { Button } from "@progress/kendo-react-buttons";


export default function AccountableUsersTab({ dataUser, groupsList, userChanged }) {
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    useEffect(() => mountProperties(), [dataUser, groupsList]);

    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={users}
            selectedItemRender={userItemRender}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;

    function mountProperties() {
        if (!dataUser || !groupsList) {
            setUsers([]);
            return;
        }

        let groups = Array.isArray(groupsList) && groupsList.length ? groupsList : [];
        let usersList = [];
        groups.forEach(i => usersList = usersList.concat(i.items));
        const items = usersList.map((item) => {
            const elementIndex = dataUser.linkedUsers.findIndex((i) => i == item.id);
            const index = elementIndex == -1 ? null : elementIndex;
            const isSelected = elementIndex != -1;
            return {
                id: item.id,
                name: item.name,
                isSelected: isSelected,
                index: index,
            };
        });

        setUsers(items);
    }

    function selectedItemsChanged(selectedItems) {
        if (userChanged)
            userChanged({
                ...dataUser,
                linkedUsers: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function openUser(data) {
        navigate(`/admin/rules-and-users/user/${data.id}`);
    }

    function userItemRender(p) {
        let { dataItem, selected, ...others } = p;
        return (
            <li {...others}>
                <div>
                    <span>
                        {dataItem.name}
                    </span>
                    <span>
                        <Button style={{ padding: "0 0 0 5px", fontSize: "10px" }}
                            className="k-flat"
                            onClick={(e) => openUser(dataItem)}>
                            <i className={`dm-i dm-i-ellipsis-v`} />
                        </Button>
                    </span>
                </div>
            </li>
        );
    };

}