import React from "react";
import { formatNumber } from "@progress/kendo-intl";
import { getUiCulture } from "../../utils/authHelper.jsx";
import { getCellStyleAndValue } from "../../utils/pluginHelpers.jsx";

export default function GridDoubleCell({ dataItem, field, tdProps, style, className }) {
    let value = dataItem[field];
    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;

    if (typeof value === "object") {
        if (value != null && value.id >= 0) {
            const plugin = getCellStyleAndValue(value);
            props.style = plugin.style;
            value = plugin.value;
        } else {
            value = null;
        }
    }

    var valueNumber = value ? new Number(value) : null;
    if (valueNumber === NaN)
        valueNumber = null;

    return <td {...props}>
        {valueNumber !== null ? formatNumber(valueNumber, "n2", getUiCulture()) : null}
    </td>;
}