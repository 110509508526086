import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogChat({ chat, isAdd, onClose, onSave }) {
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState("");
    const [isLiveFeedMode, setIsLiveFeedMode] = useState(false);
    const [users, setUsers] = useState([]);
    const [allowedUsersView, setAllowedUsersView] = useState([]);
    const [allowedUsersEdit, setAllowedUsersEdit] = useState([]);

    useEffect(() => {
        if (!isAdd) {
            setName(chat.name);
            setIsLiveFeedMode(chat.isLiveFeedMode);
            setAllowedUsersView(chat.allowedUsers.filter((u) => u.isView).map((u) => u.id));
            setAllowedUsersEdit(chat.allowedUsers.filter((u) => u.isEdit).map((u) => u.id));
        }
        fetchData();
    }, []);

    if (pending || error) {
        return <Dialog onClose={() => { onClose(); }} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const elements = [];

    renderPair(elements,
        window.captions.Caption,
        "chatNameEditorKey",
        () => <MultilineTextArea id="chatNameEditor" changeText={changeName} text={name} />,
        name);

    renderPair(elements,
        window.captions.IsLiveFeedMode,
        "liveFeedModeKey",
        () => <Switch checked={isLiveFeedMode} onChange={changeLiveFeedMode} />);

    renderPair(elements,
        window.captions.ViewAccess,
        "chatUsersViewKey",
        () => <DropDownCheckedTree
            id="chatUsersView"
            treeData={users}
            selectedIdList={allowedUsersView}
            selectionChanged={changeUsersView}
            style={{ width: "100%" }} />);

    renderPair(elements,
        window.captions.EditAccess,
        "chatUsersEditorKey",
        () => <DropDownCheckedTree
            id="chatUsersEditor"
            treeData={users}
            selectedIdList={allowedUsersEdit}
            selectionChanged={changeUsersEdit}
            style={{ width: "100%" }} />);

    return <Dialog title={window.captions.Chats} onClose={() => { onClose(); }} width={500}>
        <div className="dm-container dm-no-padding">
            {elements}
        </div>
        <DialogActionsBar>
            <Button onClick={() => { onClose(); }}>{window.captions.Cancel}</Button>
            <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{(chat ? window.captions.Save : window.captions.Add)
            }</Button>
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(window.constants.chatUsers,
            (items) => {
                setUsers(items);
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function changeName(e) {
        setName(e.value);
    }

    function changeLiveFeedMode(e) {
        setIsLiveFeedMode(e.value);
    }

    function changeUsersView(e) {
        setAllowedUsersView(e.value)
    }

    function changeUsersEdit(e) {
        setAllowedUsersEdit(e.value)
    }

    function save() {
        if (!name) {
            alert(window.captions.TitleMustNotBeEmpty);
            return;
        }
        onSave({
            id: chat ? chat.id : 0,
            name: name,
            authorId: chat ? chat.authorId : 0,
            isLiveFeedMode: isLiveFeedMode,
            allowedUsersView: allowedUsersView,
            allowedUsersEdit: allowedUsersEdit
        });
    }
}