import React, { useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { fetchPost } from "../../utils/requestsHelper.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import GridDateCell from "../../components/cells/gridDateCell.jsx";
import GridDateTimeCell from "../../components/cells/gridDateTimeCell.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ValidationMessage from "../../components/validationMessage.jsx";
import { taskStates } from "../../utils/systemConstants.jsx";

export default function DialogChoseTaskToClose({ model, onClose }) {
    const [tasks, setTasks] = useState(model.ActiveTasks);
    const [validate, setValidate] = useState(false);

    const statusCell = ({ dataItem, tdProps, style, className }) => {
        var stateId = dataItem.TaskStateId;
        var ranges = dataItem.Ranges;
        var itemProps = {};

        const props = { style: style, ...tdProps };
        if (className)
            props.className = className;

        if (stateId === taskStates.relevant)
            itemProps.className = "badge bg-success";
        else if (stateId === taskStates.overdue || ranges && new Date(ranges) < new Date())
            itemProps.className = "badge bg-danger";
        else {
            itemProps.className = "badge bg-light";
            itemProps.style = { color: "#000000" };
        }

        return <td {...props}>
            <span {...itemProps}>{dataItem.State}</span>
        </td>;
    };

    return <>
        <Dialog title={window.captions.Tasks} onClose={onCloseDialog}>
            <Grid className="dm-full-wh"
                total={tasks ? tasks.length : 0}
                data={tasks}
                selectedField="IsChecked"
                onSelectionChange={selectionChange}
                onHeaderSelectionChange={headerSelectionChange}>
                <GridColumn field="IsChecked" width="40" headerSelectionValue={headerSelectionValue()} />
                <GridColumn field="Caption" title={window.captions.WordType} width="240" />
                <GridColumn field="State" title={window.captions.State} width="240" cells={{ data: statusCell }} />
                <GridColumn field="Created" title={window.captions.Created} width="120" cells={{ data: GridDateCell }} />
                <GridColumn field="Ranges" title={window.captions.TermOfTask} width="160" cells={{ data: GridDateTimeCell }} />
                <GridColumn field="Comment" title={window.captions.Comment} width="240" />
                <GridColumn field="Author" title={window.captions.Author} width="240" />
                <GridColumn field="Responsible" title={window.captions.TaskExecutor} width="240" />
            </Grid>
            <DialogActionsBar>
                <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={onCloseDialog}>{window.captions.Save}</Button>
            </DialogActionsBar>
        </Dialog>
        {validate && <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />}
    </>;

    function onCloseDialog() {
        if (!tasks || tasks.length === 0) {
            onClose(false);
            return;
        }
        const data = tasks.filter(item => item.IsChecked === true);
        if (data.length === 0)
            setValidate(window.captions.ChoseTaskToClose);
        else {
            fetchPost(window.constants.choseTaskToCloseSetSave,
                { ...model, ActiveTasks: tasks },
                () => onClose(true),
                () => onClose(false));
        }
    }

    function selectionChange(event) {
        if (tasks)
            setTasks(selectItems(tasks, event.dataItem["TaskId"], !event.dataItem["IsChecked"]));
    }

    function headerSelectionChange(event) {
        if (tasks)
            setTasks(selectItems(tasks, 0, event.syntheticEvent.target.checked));
    }

    function selectItems(items, id, isChecked) {
        return items.map(item => id === 0 || item.TaskId === id ? { ...item, IsChecked: isChecked } : item);
    }

    function headerSelectionValue() {
        return tasks && tasks.findIndex(item => item.IsChecked === false) === -1;
    }
}