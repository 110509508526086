import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import DialogProjectStage from "./dialogProjectStage.jsx";
import DialogCreateClient from "./dialogCreateClient.jsx";
import { ListView } from "@progress/kendo-react-listview";
import MessageMask from "../../components/messageMask.jsx";
import gridFunctions from "../../components/gridFunctions.jsx";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { renderViewer } from "../cards/cardRenderViewer.jsx";
import GridLoadingPanel from "../../components/gridLoadingPanel.jsx";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { callConditionDirection, entitySources, entitySourcesNames, openParameters } from
    "../../utils/systemConstants.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogIncomingCall({ callId, onClose }) {
    const [storage, setStorage] = useState({
        phone: null,
        formattedPhone: "",
        fioText: "",
        card: null,
        cards: [],
        loans: [],
        clients: [],
        parameters: null,
        cardTypeDisplayed: null,
        cardCreatedType: null,
        isDisplayClient: false,
        canAddLoans: false,
        canAddClient: false,
        isSingle: false,
        isMultiple: false,
        columns: []
    })

    const [searchDisable, setSearchDisable] = useState(false);
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [data, setData] = useState([]);
    const [gridPending, setGridPending] = useState(false);
    const [search, setSearch] = useState("");
    const [isShowCreateLoanDialog, setIsShowCreateLoanDialog] = useState(false);
    const [isShowCreateClientDialog, setIsShowCreateClientDialog] = useState(false);
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(), []);

    const title = getPopupTitle();
    let width = 400;
    if (pending || error) {
        return <Dialog title={title} onClose={onClose} width={width}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    var phoneNumber = storage.phone
        ? storage.phone.phoneNumber
        : null;
    if (isShowCreateLoanDialog) {
        return <DialogProjectStage clientId={0} phone={phoneNumber} onClose={createLoanClose} />;
    }

    if (isShowCreateClientDialog) {
        return <DialogCreateClient clientId={0} phone={phoneNumber} onClose={createClientClose} />;
    }

    let content = "";
    if (!storage.isDisplayClient && storage.loans && storage.loans.length > 0 ||
        storage.isDisplayClient && storage.clients && storage.clients.length > 0) {
        width = 600;
        content = renderList();
    } else {
        width = 800;
        content = renderGrid();
    }

    let clientId = 0;
    if (storage.isMultiple)
        clientId = storage.cards.clientId;
    else if (storage.isSingle)
        clientId = storage.card.clientId;

    let buttonOpenCardClient =
        <Link to={`/card/client/${clientId}`}
            className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-primary dm-white"
            onClick={() => closeAndShowHistory()}>
            {window.captions.Client}
        </Link>;
    if ((!storage.loans || storage.loans.length <= 0) && !storage.isDisplayClient || storage.isDisplayClient)
        buttonOpenCardClient = "";

    const titleHtml = <React.Fragment>
        <i className="dm-i dm-i-phone-alt" title={title} style={{
            fontSize: 20,
            marginTop: 5,
            marginRight: 10
        }} />
        {storage.formattedPhone
            ? storage.formattedPhone
            : phoneNumber}
        {" "}
        {storage.fioText}
    </React.Fragment>;

    return <Dialog title={titleHtml}
        onClose={onClose}
        width={width} height={700}
        className="dm-success-dialog">
        {content}
        <DialogActionsBar>
            {buttonOpenCardClient}
            <Button onClick={onClose}>{window.captions.CallIgnore}</Button>
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getIncomingCallModel}/${callId}`,
            (data) => {
                const cards = data.cards;
                setStorage({
                    phone: data.phone,
                    formattedPhone: data.formattedPhone,
                    fioText: data.fioText,
                    card: data.card,
                    cards: cards,
                    loans: cards && cards.loans ? cards.loans : [],
                    clients: cards && cards.clients ? cards.clients : [],
                    parameters: data.openParameters,
                    cardTypeDisplayed: data.cardTypeDisplayed,
                    cardCreatedType: data.cardCreatedType,
                    canAddLoans: data.canAddLoans,
                    canAddClient: data.canAddClient,
                    isSingle: data.isSingle,
                    isMultiple: data.isMultiple,
                    columns: data.columns,
                    isDisplayClient: data.cardTypeDisplayed === entitySources.client
                });

                setPending(null);
                setError(null);
            },
            (ex) => {
                setPending(pending);
                setError(ex);
            });
    }

    function fetchSearch(dataState) {
        setGridPending(true);
        setSearchDisable(true);
        const source = storage.isDisplayClient
            ? entitySourcesNames.client
            : entitySourcesNames.loan;
        fetchGet(
            `${window.constants.incomingSearch}/${source}/${encodeURIComponent(search)}?${toDataSourceRequestString(dataState)}`,
            (data) => {
                setData(data);
                setDataState(dataState);
                setSearchDisable(false);
                setGridPending(false);
                setError(null);
            },
            (ex) => {
                setError(ex);
                setGridPending(false);
            });
    }

    function onSearchKey(e) {
        if (e.keyCode !== 13)
            return;

        findItem();
    }

    function findItem() {
        fetchSearch(dataState);
    }

    function searchChanged(e) {
        const text = e.target.value && e.target.value.length > 0
            ? e.target.value
            : "";
        setSearch(text);
    }

    function getPopupTitle() {
        if (!storage.parameters)
            return "";
        switch (storage.parameters.callDirection) {
            case callConditionDirection.incoming:
                return window.captions.IncomingCall;
            case callConditionDirection.outgoing:
                return window.captions.OutgoingCall;
            default:
                return window.captionsDynamic.LoanCard;
        }
    }

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchSearch(changeEvent.dataState);
    }

    function createLoan() {
        setIsShowCreateLoanDialog(true);
    }

    function createLoanClose(id) {
        if (isNaN(id) || id <= 0) {
            onClose();
            return;
        }
        setIsShowCreateLoanDialog(false);
        closeAndShowHistory(true);
        window.open(`/card/loan/${id}`, "_self");
    }

    function createClient() {
        setIsShowCreateClientDialog(true);
    }

    function createClientClose(id) {
        if (isNaN(id) || id <= 0) {
            onClose();
            return;
        }
        setIsShowCreateClientDialog(false);
        closeAndShowHistory(true);
        window.open(`/card/client/${id}`, "_self");
    }

    function closeAndShowHistory(isNewCard = false) {
        sessionStorage.setItem(openParameters, JSON.stringify({ ...storage.parameters, phone: phone, isNewCard: isNewCard }));
        onClose();
    }

    function renderList() {
        const listItem = (p) => {
            const item = p.dataItem;
            const href = storage.isDisplayClient
                ? `/card/client/${item.id}`
                : `/card/loan/${item.loanId}`;

            const linkProps = {
                className: "k-button dm-positive-filled",
                style: {
                    display: "inline-block",
                    right: "0",
                    top: "2px",
                    width: "40px"
                }
            };

            if (!item.fields) {
                return <div key={item.id} className="dm-bordered-buttom dm-container">
                    <Link to={href} onClick={() => closeAndShowHistory()} {...linkProps}>
                        {window.captionsDynamic.LoanCard}
                        <i className={`dm-i dm-i-chevron_right`} />
                    </Link>
                </div>;
            }

            return <div key={item.id} className="dm-bordered-buttom dm-container">
                {Object.keys(item.fields).map(key => {
                    const field = item.fields[key];
                    var valueProps = {};
                    var link = null;
                    if (key == 0) {
                        link =
                            <Link key={`${key}Link`}
                                to={href}
                                onClick={() => closeAndShowHistory()} {...linkProps}>
                                <i className={`dm-i dm-i-chevron_right`} />
                            </Link>;
                        valueProps = {
                            style: {
                                width: "calc(100% - 200px)"
                            }
                        };
                    }

                    var items = [
                        <div key={`${key}Name`} className="dm-label">
                            <div>{field.name}</div>
                        </div>,
                        <div key={`${key}Value`} className="dm-card-value" {...valueProps}>
                            {renderViewer(field, null)}
                        </div>
                    ];
                    if (link)
                        items.push(link);

                    return items;
                })}
            </div>;
        };

        return <ListView data={storage.isDisplayClient ? storage.clients : storage.loans} item={listItem} className="dm-full-wh" />;
    }

    function renderGrid() {
        const actionsCell = (p) => {
            const value = p.dataItem[p.field];
            const href = storage.isDisplayClient ? `/card/client/${value}` : `/card/loan/${value}`;
            return <td {...getLinkCellStyle(p)}>
                <Link to={href} onClick={() => closeAndShowHistory()}>
                    <i className={`dm-i dm-i-chevron_right`} />
                </Link>
            </td>;
        };

        const buttonsCreate = [];
        if (storage.canAddLoans && (storage.cardCreatedType === entitySources.loan || storage.cardCreatedType === entitySources.clientToLoans))
            buttonsCreate.push(
                <Button key="createLoan"
                    themeColor="primary"
                    className="dm-m-left"
                    iconClass="dm-i dm-i-money-check"
                    onClick={createLoan}>
                    {window.captionsDynamic.CreateLoan}
                </Button>);

        if (storage.canAddClient && (storage.cardCreatedType === entitySources.client || storage.cardCreatedType === entitySources.clientToLoans))
            buttonsCreate.push(
                <Button key="createClient"
                    themeColor="primary"
                    className="dm-m-left"
                    iconClass="dm-i dm-i-user-tie"
                    onClick={createClient}>
                    {window.captions.CreateClient}
                </Button>);

        let loadingPanel = null;
        if (gridPending) {
            loadingPanel = <GridLoadingPanel />;
        }

        const fieldColumns = gridFunctions.renderGridColumns(storage.columns);
        return <div className="dm-full-wh">
            <Grid style={{ width: "100%", height: "100%" }}
                pageable={true}
                total={data.length}
                data={data}
                {...dataState}
                onDataStateChange={handleDataStateChange}>
                <GridToolbar>
                    <div className="dm-wrapper">
                        <div className="dm-wrapper-content">
                            <Input onChange={searchChanged}
                                onKeyDown={(e) => onSearchKey(e)}
                                placeholder={window.captions.Search}
                                disabled={searchDisable}
                                value={search}
                                style={{ width: "100%" }} />
                        </div>
                        <div>
                            <Button icon="find" title={window.captions.Search} onClick={() => findItem()} />
                            {buttonsCreate}
                        </div>
                    </div>
                </GridToolbar>
                {fieldColumns}
                <GridColumn field={storage.isDisplayClient ? "CID" : "LID"}
                    locked={true} title=" " width="35" cells={{ data: actionsCell }} />
            </Grid>
            {loadingPanel}
        </div>;
    }
}