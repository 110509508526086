import { filterFieldTypes, entitySourcesNames } from "../../utils/systemConstants.jsx";

export default {
    getFilterItem(state, fieldId) {
        fieldId = parseInt(fieldId);
        const selected = state.selected;
        let existed = selected.items.find((e) => e.id === fieldId);
        if (existed)
            return existed;

        const group = state.groups.find((g) => g.items.find((i) => i.id === fieldId));
        if (!group)
            return null;

        const field = group.items.find((i) => i.id === fieldId);
        existed = {
            id: fieldId,
            values: null,
            type: field.type,
            compareType: field.defaultComparer,
            children: [],
            periodFilter: null
        };
        if (state.entity === entitySourcesNames.collateral && field.fieldAlias === "State") {
            existed.periodFilter = {
                id: fieldId,
                values: null,
                type: field.periodFilter.type,
                compareType: field.periodFilter.defaultComparer,
                children: [],
                periodFilter: null
            };
        }

        selected.items.push(existed);
        return existed;
    },

    clear(state, fieldId) {
        fieldId = parseInt(fieldId);
        const selected = state.selected;
        if (fieldId === 0) {
            selected.searchAllFieldsValue = "";
            selected.items = [];
            state.selected = selected;
            return;
        }

        const existed = selected.items.find((e) => e.id === fieldId);
        if (!existed)
            return;

        const index = selected.items.indexOf(existed);
        if (index < 0) {
            return;
        }

        selected.items.splice(index, 1);
        state.selected = selected;
    },

    changeFieldChildren(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        existed.children = value;
        state.selected = state.selected;
    },

    changeFieldValue(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        existed.values = value;
        state.selected = state.selected;
    },

    changeFieldValueFrom(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        if (existed.values)
            existed.values[0] = value;
        else
            existed.values = [value, null];
        state.selected = state.selected;
    },

    changeFieldValueTo(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        if (existed.values)
            existed.values[1] = value;
        else
            existed.values = [null, value];
        state.selected = state.selected;
    },

    changeFieldWaterMarkValue(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        existed.values = value.split(" ").join().replace(/[^0-9\.,]/g, "");
        state.selected = state.selected;
    },

    changeFieldComparer(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        existed.compareType = value;
        state.selected = state.selected;
    },

    changePeriodFilterValueFrom(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        if (value != null && existed.type === filterFieldTypes.time)
            value = value.toLocaleTimeString("ru-RU");

        if (existed.periodFilter.values)
            existed.periodFilter.values[0] = value;
        else
            existed.periodFilter.values = [value, null];
        state.selected = state.selected;
    },

    changePeriodFilterValueTo(state, fieldId, value) {
        const existed = this.getFilterItem(state, fieldId);
        if (value != null && existed.type === filterFieldTypes.time)
            value = value.toLocaleTimeString("ru-RU");

        if (existed.periodFilter.values)
            existed.periodFilter.values[1] = value;
        else
            existed.periodFilter.values = [null, value];
        state.selected = state.selected;
    },

    rename(state, name) {
        const selected = state.selected;
        const filters = state.filters;
        const filter = filters.find((i) => i.id === selected.id);
        filter.name = name;
        state.selected = filter;
        state.filters = filters;
    },

    changeSearchAllValue(state, value) {
        const selected = state.selected;
        const filters = state.filters;
        const filter = filters.find((i) => i.id === selected.id);
        filter.searchAllFieldsValue = value;
        state.selected = filter;
        state.filters = filters;
    }
}