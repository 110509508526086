import React from "react";
import { useState, useRef } from 'react';
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { fetchPost } from "../../../utils/requestsHelper.jsx";

export default function AutocompleteControl({ textLength, textLengthMin, value, required, field, autoFillType, changeValue }) {
    let timeout = useRef(null);

    const delay = 300;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const restrictions = {};
    if (textLength && textLength > 0)
        restrictions.maxLength = textLength;

    let minLength = 0;
    if (textLengthMin)
        minLength = textLengthMin;

    let isValid = true;
    const text = value;
    if (text != null && text.length < minLength ||
        required && (text == null || text.length === 0 || text.length < minLength))
        isValid = false;

    return <AutoComplete data={items}
        suggest={true}
        valid={isValid}
        defaultValue={text}
        textField="name"
        style={{ width: "100%" }}
        {...restrictions}
        loading={loading}
        onChange={onChange} />;

    function setAutocomplete(items) {
        setItems(items);
        setLoading(false);
    }

    function refreshAutocomplete(newValue) {
        if (!field || !newValue || newValue.length <= 2 || isNaN(autoFillType)) {
            setAutocomplete([]);
            return;
        }

        fetchPost(`${window.constants.autocomplete}/${autoFillType}`,
            newValue,
            (data) => setAutocomplete(data),
            () => setAutocomplete([]));
    }

    function onChange(e) {
        const newValue = e.target.value;

        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            refreshAutocomplete(newValue);
            changeValue({ fieldId: field, value: newValue });
        },
            delay);

        setLoading(true);
    }
}