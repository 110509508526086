import { configureStore } from '@reduxjs/toolkit';

import header from "../containers/header/headerReducer.jsx";
import cardEntity from "../containers/cards/cardEntityReducer.jsx";
import searchForm from "../containers/search/searchFormReducer.jsx";
import chat from "../containers/chat/chatReducer.jsx";
import collateralSchema from "../containers/collateralSchema/collateralSchemaReducer.jsx";

export default configureStore({
    reducer: {
        header,
        cardEntity,
        searchForm,
        chat,
        collateralSchema
    }
});