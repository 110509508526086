import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { entitySourcesNames } from "../../../utils/systemConstants.jsx";
import DrawerMenu from "../../../components/drawerMenu.jsx";
import LoansImportRender from "./loansImportRender.jsx";
import { renderImportClients } from "./clientsImportRender.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import { fetchGet, fetchPost } from '../../../utils/requestsHelper.jsx';

export default function AdminImport() {
    const params = useParams();
    const navigate = useNavigate();

    const [baseFieldClients, setBaseFieldClients] = useState([]);
    const [baseFieldLoans, setBaseFieldLoans] = useState([]);
    const [startStatus, setStartStatus] = useState([]);
    const [autoImportLoans, setAutoImportLoans] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isL, setIsL] = useState(false);
    const [isC, setIsC] = useState(false);
    const [proj, setProj] = useState([]);

    const [columns, setColumns] = useState([]);

    const [errors, setErrors] = useState("");

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.Import}`;
    }, []);

    useEffect(() => {
        //TODO load with one request
        basedFieldLoan(params.entity);
        basedFieldClient(params.entity);
        getAutoImportLoans(params.entity);
    }, [params.entity]);

    //getTitle() {
    //    const entity = params.entity;
    //    if (entity === entitySourcesNames.loan)
    //        return window.captionsDynamic.ConfigSearchLoan;
    //    if (entity === entitySourcesNames.client)
    //        return window.captions.ConfigSearchClient;
    //    if (entity === entitySourcesNames.process)
    //        return window.captions.ConfigSearchProcess;
    //    if (entity === entitySourcesNames.collateral)
    //        return window.captionsDynamic.Collaterals;
    //    if (entity === entitySourcesNames.callRecords)
    //        return window.captions.ConfigSearchCallRecords;
    //    if (entity === entitySourcesNames.documents)
    //        return window.captions.ConfigSearchDocuments;
    //}

    if (errors)
        return <ErrorMask error={errors} />;

    let importLoans = null;
    if (!isL || !isC)
        return <LoadingMask />;

    if (!autoImportLoans)
        return <ErrorMask error="No autoImportLoans" />;

    if (params.entity === entitySourcesNames.loan) {
        importLoans = <LoansImportRender
            baseFieldLoans={baseFieldLoans}
            baseFieldClients={baseFieldClients}
            columns={columns}
            dataProjects={proj}
            startStatus={startStatus}
            autoImportLoans={autoImportLoans.autoImportLoan}
            entity={params.entity}
            getFileData={(data) => getFileData(data)}
            getProjects={() => getProject()}
            getStartStatus={(listIdProject) => getStatus(listIdProject)}
            startImportLoans={(data) => save(data)} />;
    }

    let importClients = null;
    if (params.entity === entitySourcesNames.client) {
        importClients = renderImportClients();
    }

    return <div className="dm-full-height">
        <DrawerMenu items={renderMenuItems()} align="left" onSelect={onSelect}>
        </DrawerMenu>
        {importLoans}
        {importClients}
    </div>;

    function renderMenuItems() {
        return [
            {
                text: window.captions.Loans,
                route: `loan`,
                selected: params.entity === entitySourcesNames.loan
            },
            {
                text: window.captions.Clients,
                route: `client`,
                selected: params.entity === entitySourcesNames.client
            },
            {
                text: window.captions.Processes,
                route: `process`,
                selected: params.entity === entitySourcesNames.process
            },
            { text: window.captions.MainTabCommonImportHistory },
            { text: window.captions.MainTabDictionary },
            { text: window.captions.Addresses },
            { text: window.captions.Accounts },
            { text: window.captions.PromiseScheduler }
        ];
    }

    function getFileData(fileName) {
        setIsLoaded(false);
        fetchPost(`${window.constants.importGetFileData}/${params.entity}`,
            fileName,
            (data) => {
                if (!fileName)
                    return;

                setColumns(data);
                setErrors(data.accessError);
                setIsLoaded(true);
            },
            ex => setErrors(ex)
        );
    }

    function save(model) {
        setIsLoaded(false);
        fetchPost(`${window.constants.importSaveImport}/${params.entity}`,
            model,
            (model) => {
                if (model)
                    setIsLoaded(true);
                else
                    setErrors(window.captions.SaveDataError);
            },
            ex => setErrors(ex)
        );
    }

    function getProject() {
        fetchGet(`${window.constants.importGetProjectsAdminImport}/${params.entity}`,
            (data) => {
                setProj(data);
            },
            ex => setErrors(ex)
        );
    }

    function getStatus(listIdProject) {
        fetchPost(`${window.constants.importGetStartStatusImport}/${params.entity}`,
            listIdProject,
            (data) => {
                if (!data)
                    return;

                setStartStatus(data);
                setErrors(data.accessError);
                setIsLoaded(true);
            },
            ex => setErrors(ex)
        );
    }

    function onSelect(e) {
        navigate(`/admin/import/${e.route}`);
    }

    function getAutoImportLoans(sourceId) {
        fetchGet(`${window.constants.importGetAutoImportLoans}/${sourceId}`,
            (data) => {
                setAutoImportLoans(data);
                setErrors(data.accessError);
                setIsC(true);
            },
            ex => setErrors(ex)
        );
    }

    function basedFieldLoan(sourceId) {
        fetchGet(`${window.constants.importBaseFieldLoans}/${sourceId}`,
            (data) => {
                setBaseFieldLoans(data);
                setErrors(data.accessError);
                setIsL(true);
            },
            ex => setErrors(ex)
        );
    }

    function basedFieldClient(sourceId) {
        fetchGet(`${window.constants.importBaseFieldClients}/${sourceId}`,
            (data) => {
                setBaseFieldClients(data);
                setErrors(data.accessError);
                setIsC(true);
            },
            ex => setErrors(ex)
        );
    }
}