import React from "react";
import { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import DropDownAlternating from "../../components/controls/dropDownAlternating.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogProjectStage({ id, clientId, phone, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [collapsed, setCollapsed] = useState([]);

    const [projectId, setProjectId] = useState(0);
    const [stateId, setStateId] = useState(0);

    useEffect(() => fetchData(), []);

    const title = id === 0 ? window.captionsDynamic.LoanSingleNominative : window.captionsDynamic.MainMenuCreateLoan;
    if (pending || error) {
        return <Dialog title={title} onClose={() => onClose(0)} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    let saveButton = "";
    if (projectId > 0 && stateId > 0) {
        saveButton = <Button themeColor="primary" onClick={save}>{window.captions.Create}</Button>;
    }

    var labels = [
        window.captions.ProjectsGroup,
        window.captions.Project,
        window.captions.Stage,
        window.captions.State
    ];

    if (collapsed.length > 0)
        labels = labels.slice(collapsed.length);

    return <Dialog title={title} onClose={() => onClose(0)} width={400}>
        <DropDownAlternating
            id={`DropAlternatingNewLoan`}
            style={{ width: "100%" }}
            treeData={items}
            treeLabels={labels}
            selectionChanged={changedState}
            selectionCanceled={selectedStateCanceled} />
        <DialogActionsBar>
            <Button onClick={() => onClose(0)}>{window.captions.Cancel}</Button>
            {saveButton}
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);

        fetchGet(`${window.constants.getProjectModel}`,
            (data) => {
                setItems(data.items);
                setCollapsed(data.collapsed);
                setPending(null);
                if (data.items.length === 0 && data.collapsed.length === 4) {
                    setStateId(data.collapsed[3]);
                    setProjectId(data.collapsed[1]);
                    save();
                }
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function changedState(e) {
        var localStateId = e.value;
        if (stateId === localStateId)
            return;

        var localProjectId = 0;
        if (collapsed.length >= 2) {
            localProjectId = collapsed[1];
        } else {
            localProjectId = e.selected[1 - collapsed.length].id;
        }

        setStateId(localStateId);
        setProjectId(localProjectId);
    }

    function selectedStateCanceled(e) {
        setStateId(0);
        setProjectId(0);
    }

    function save() {
        var saveData = {
            clientId: clientId,
            projectId: projectId,
            stateId: stateId,
            phone: phone
        };

        setPending(window.captions.SavingData);
        setError(null);

        fetchPost(window.constants.saveNewLoan,
            saveData,
            (saveId) => onClose(saveId),
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }
}