import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useInternationalization } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Scheduler, TimelineView, DayView, WeekView, MonthView, AgendaView, SchedulerItem } from "@progress/kendo-react-scheduler";
import { CalendarModelFields } from "./calendarConstants.jsx";
import { formatDate } from "@progress/kendo-intl";
import { parseDateTimeFormat, timeFormat } from "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import { fetchGet } from '../../utils/requestsHelper.jsx';

import DialogTask from "../dialogs/dialogTask.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";

export default function Calendar() {
    const accessCalendar = useSelector(state => state.header.model.access.calendar);

    const [items, setItems] = useState([]);
    const [resources, setResources] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isNewTask, setIsNewTask] = useState(false);
    const [isPreviewTask, setIsPreviewTask] = useState(false);
    const [canAddTask, setCanAddTask] = useState(false);
    const [previewTaskId, setPreviewTaskId] = useState(0);
    const [overflowAllDay, setOverflowAllDay] = useState(false);
    const [overflowMaxItems, setOverflowMaxItems] = useState(false);
    const [error, setError] = useState(null);

    const intlService = useInternationalization();

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.MainTabCommonCalendar}`;
        calendarLoad();
    }, []);

    if (!accessCalendar)
        return <ErrorMask error={window.captions.AccessDenied} />;

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    let dialog = "";
    if (isNewTask) {
        dialog =
            <DialogTask cardId={0}
                cardEntity="users"
                taskId={0}
                projectId={0}
                key="dialogTaskAdd"
                onClose={(success) => closeTasks(success)} />;
    }
    if (isPreviewTask) {
        dialog =
            <DialogTask cardId="0"
                cardEntity="users"
                taskId={previewTaskId}
                projectId={0}
                key="dialogTaskPreview"
                onClose={() => previewDialog(false, 0)} />;
    }

    let taskButton = null;
    if (canAddTask) {
        taskButton = <Button className="dm-positive-filled"
            iconClass="dm-i dm-i-plus"
            title={window.captions.NewTask}
            onClick={() => taskDialog(true)} />;
    }

    const schedulerItem = (p) => {
        if (!p || !p.dataItem)
            return <SchedulerItem {...p} />;

        return <SchedulerItem {...p}>
            <div key={p.dataItem.id} title={p.dataItem.textTitle}>
                {p.dataItem.subject}
            </div>
        </SchedulerItem>;
    };

    return [
        <div className="dm-full-height" key="schedulerView">
            <h1 className="dm-title">{window.captions.MainTabCommonCalendar} {taskButton}</h1>
            <div className="dm-under-title-content">
                <Scheduler resources={resources}
                    data={items}
                    item={schedulerItem}
                    height="100%"
                    modelFields={CalendarModelFields}>
                    <AgendaView />
                    <DayView />
                    <WeekView />
                    <MonthView />
                    <TimelineView />
                </Scheduler>
            </div>
        </div>,
        dialog
    ];

    function calendarLoad() {
        fetchGet(window.constants.calendarEvents,
            data => {
                const items = [];
                for (let key in data.items) {
                    const item = data.items[key];
                    const id = parseInt(item.id.substr(4));

                    const subject = [];
                    const textTitle = [
                        `(${formatDate(new Date(item.start), timeFormat, getUiCulture())} - ${formatDate(new Date(item.end), timeFormat, getUiCulture())})`
                    ];
                    const href = item.body;
                    if (!isNaN(id)) {
                        subject.push(
                            <Button className="dm-calendar-link" key={0} fillMode="flat" onClick={() => previewDialog(true, id)}>
                                <i className={`dm-i dm-i-link`} />
                            </Button>);
                    } else if (href) {
                        subject.push(<Link key={0} to={href}>
                            <i className={`dm-i dm-i-link`} />
                        </Link>);
                    }

                    if (item.title) {
                        subject.push(<span key={1}>{item.title}</span>);
                        textTitle.push(item.title);
                    }

                    if (item.executor !== item.author) {
                        subject.push(<div key={2}>
                            <i className={`dm-i dm-i-user`} color="secondary" /> {item.author}
                        </div>);
                        textTitle.push(item.author);
                    }

                    subject.push(<div key={3}>
                        <i className={`dm-i dm-i-user`} /> {item.executor}
                    </div>);
                    textTitle.push(item.executor);

                    subject.push(<div key={4}>
                        <i className={`dm-i dm-i-object-group`} /> {item.type}
                    </div>);
                    textTitle.push(item.type);

                    if (item.comment) {
                        subject.push(<div key={5}>
                            <i className={`dm-i dm-i-comment-alt`} /> {item.comment}
                        </div>);
                        textTitle.push(item.comment);
                    }

                    item.subject = subject;
                    item.start = intlService.parseDate(new Date(item.start), parseDateTimeFormat);
                    item.end = intlService.parseDate(new Date(item.end), parseDateTimeFormat);
                    item.textTitle = textTitle.join();
                    items.push(item);
                }

                setItems(items);
                setCanAddTask(data.canAddTask);
                setResources([
                    {
                        name: "type",
                        data: data.resources,
                        field: "markerType",
                        valueField: "id",
                        textField: "name",
                        colorField: "color"
                    }
                ]);
                setOverflowAllDay(data.overflowAllDay);
                setOverflowMaxItems(data.overflowMaxItems);
                setError(null);
                setIsLoaded(true);
            },
            ex => {
                setError(ex);
                setIsLoaded(true);
            }
        );
    }

    function previewDialog(isPreviewTask, id) {
        setIsPreviewTask(isPreviewTask);
        setPreviewTaskId(id);
    }

    function taskDialog(isNewTask) {
        setIsNewTask(isNewTask);
    }

    function closeTasks(success) {
        taskDialog(false)
        if (success !== false)
            calendarLoad();
    }
}