import React from "react";
import { useState, useEffect } from 'react';
import LangItemEditor from "../../../components/controls/langItemEditor.jsx";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { getAdminBooleanSources } from "../../../utils/systemConstants.jsx";
import { fetchPost } from "../../../utils/requestsHelper.jsx";

export default function SearchFieldEditorControl({ field, onClose, sources, compareTypes, formulas, isGroup, onSave, addField, remove }) {
    const [searchField, setSearchField] = useState({});
    const [selectEntity, setSelectEntity] = useState([]);
    const [selectField, setSelectField] = useState([]);
    const [selectFormula, setSelectFormula] = useState([]);
    const [selectCompare, setSelectCompare] = useState([]);
    const [selectDefault, setSelectDefault] = useState([]);
    const [selectLangItem, setSelectLangItem] = useState([]);
    const [isShowTotal, setIsShowTotal] = useState(false);
    const [isConvertCurrency, setIsConvertCurrency] = useState(false);

    useEffect(() => {
        setSearchField(field);
        setSelectEntity(field.sourceId);
        setSelectField(field.elementItem);
        setSelectFormula(field.formula);
        setSelectCompare(field.compareType);
        setSelectLangItem(field.langItem);
        setIsShowTotal(field.isShowTotal);
        setIsConvertCurrency(field.isConvertCurrency);
        setSelectDefault(field.defaultBool);
    }, [field.id]);

    const controls = [];

    if (searchField && searchField.langItem)
        renderPair(controls,
            window.captions.Caption,
            "LangEditor",
            () => <LangItemEditor key="langEditorControl"
                defaultName={searchField.name}
                langItem={searchField.langItem}
                namedChanged={changedName} />);

    if (isGroup) {
        renderPair(controls,
            "",
            "AddField",
            () => <Button onClick={addNewField}>{window.captions.AddFieldGroup}</Button>);
    } else {
        const entityList = [];
        var allowSources = sources.filter(x => x.isAvailable);
        for (let key in allowSources) {
            const entity = allowSources[key];
            entityList.push({ id: entity.id, name: entity.name });
        }
        var selectedEntity = entityList.find(i => i.id === selectEntity);
        renderPair(controls,
            window.captions.Source,
            "Entity",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={entityList}
                onChange={changedEntity}
                value={selectedEntity} />);

        renderPair(controls,
            window.captions.Field,
            "Field",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={searchField.elements}
                onChange={changedField}
                value={selectField} />);

        const formulaList = [];
        for (let key in formulas) {
            const formula = formulas[key];
            formulaList.push({ id: formula.id, name: formula.name });
        }
        var selectedFormula = formulaList.find(i => i.id === selectFormula);
        let formulaName = searchField.isMultipleSource ? window.captions.Formula : window.captions.FormulaSummary;
        renderPair(controls,
            formulaName,
            "Formula",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={formulaList}
                onChange={changedFormula}
                value={selectedFormula} />);

        renderPair(controls,
            " ",
            "Summary",
            () => <Checkbox key="searchFieldIsIsShowTotal" value={isShowTotal} label={window.captions
                .DisplaySummaryByField} onChange={changeIsShowTotal} />);

        if (searchField.isConvertCurrencyVisitility) {
            renderPair(controls,
                " ",
                "ConvertCurrency",
                () => <Checkbox key="searchFieldIsIsConvertCurrency" value={isConvertCurrency} label={window
                    .captions.TransferFinancialValueInDefaultCurrency} onChange={changeIsCurrency} />);
        }

        if (searchField.isString) {
            const compareList = [];
            for (let key in compareTypes) {
                const compare = compareTypes[key];
                compareList.push({ id: compare.id, name: compare.name });
            }
            var selectedCompare = compareList.find(i => i.id === selectCompare);
            renderPair(controls,
                window.captions.CompareBlock,
                "Compare",
                () => <DropDownList
                    textField="name"
                    dataItemKey="id"
                    data={compareList}
                    onChange={changedCompare}
                    value={selectedCompare} />);
        }

        if (searchField.isBool) {
            renderPair(controls,
                window.captions.ByDefault,
                "ByDefault",
                () => <RadioGroup

                    data={getAdminBooleanSources()}
                    value={selectDefault}
                    onChange={changedByDefault} />);
        }
    }

    const dialogActions = <DialogActionsBar>
        <Button onClick={() => onClose()}>{window.captions.Cancel}</Button>
        <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window
            .captions.Save}</Button>
        <Button iconClass="dm-i dm-i-remove" onClick={remove}>{window.captions.Delete
        }</Button>
    </DialogActionsBar>;

    // dm-container-slim
    const dialog = <div className="dm-container">
        {controls}
        <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                {dialogActions}
            </div>
        </div>
    </div>;
    return dialog;

    function changedEntity(e) {
        const sourceId = e.target.value.id;
        fetchPost(`${window.constants.changeEntityField}/${sourceId}`,
            getField(),
            (data) => {
                setSearchField(data);
                setSelectEntity(sourceId);
                setSelectField(data.elements.length > 0 ? data.elements[0] : null);
            });
    }

    function changedField(e) {
        setSelectField(e.target.value);
    }

    function changedFormula(e) {
        setSelectFormula(e.target.value.id);
    }

    function changedCompare(e) {
        setSelectCompare(e.target.value.id);
    }


    function changedByDefault(e) {
        setSelectDefault(e.value);
    }

    function changedName(e) {
        setSelectLangItem(e.selectLang);
    }

    function changeIsShowTotal(e) {
        setIsShowTotal(e.value);
    }

    function changeIsCurrency(e) {
        setIsConvertCurrency(e.value);
    }

    function getField() {
        return {
            ...searchField,
            compareType: selectCompare,
            defaultBool: selectDefault,
            element: selectField.id,
            elementItem: selectField,
            formula: selectFormula,
            isConvertCurrency: isConvertCurrency,
            isConvertCurrencyVisibility: isConvertCurrency,
            isShowTotal: isShowTotal,
            sourceId: selectEntity,
            langItem: selectLangItem,
        };
    }

    function save() {
        let saveField = null;
        let saveGroup = null;
        if (isGroup) {
            saveGroup = {
                id: searchField.id,
                isExpanded: searchField.isExpanded,
                orderId: searchField.orderId,
                langItem: selectLangItem,
                name: searchField.name
            };
        } else {
            saveField = getField();
        }

        if (saveGroup) {
            for (let key in saveGroup.langItem.items) {
                const groupLang = saveGroup.langItem.items[key];
                if (groupLang.name.length === 0) {
                    alert(window.captions.MessageBoxEmptyNameDeny);
                    return;
                }
            }
        }

        if (saveField) {
            for (let key in saveField.langItem.items) {
                const fieldLang = saveField.langItem.items[key];
                if (fieldLang.name.length === 0) {
                    alert(window.captions.MessageBoxEmptyNameDeny);
                    return;
                }
            }
        }

        onSave({
            deleteFields: [],
            deleteGroups: [],
            group: saveGroup,
            field: saveField
        });
    }

    function addNewField() {
        addField({
            id: searchField.id,
            isExpanded: searchField.isExpanded,
            orderId: searchField.orderId,
            langItem: selectLangItem,
            groupItems: searchField.items
        });
    }

    function remove() {
        let id = searchField.id;
        if (id > 0 && !confirm(captions.DeleteItemConfirm))
            return;

        let removeItem = {
            id: id,
            isGroup: isGroup
        };

        remove(removeItem);
    }
}