import React from "react";
import { useState, useEffect } from 'react';
import InlineDialog from "../../../components/inlineDialog.jsx";
import { DialogActionsBar, Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextBox, NumericTextBox, Checkbox, TextArea } from "@progress/kendo-react-inputs";
import LangItemEditor from "../../../components/controls/langItemEditor.jsx"
import structureSettingsFunc from "./structureSettingsFunctions.jsx";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import ValueArraySettings from "./controls/valueArraySettings.jsx";
import DictionaryControlComponent from "./controls/dictionaryControl.jsx";
import PictureLimitationComponent from "./controls/limitation/pictureControl.jsx";
import NumericLimitation from "./controls/limitation/numericLimit.jsx";
import DateTimeLimitation from "./controls/limitation/dateTimeLimit.jsx";
import SameNamesComponent from "./controls/savedSimilarNames.jsx";
import FieldBlockComponent from "./controls/blockFieldControl.jsx";
import VerifySettingsComponent from "./controls/verifySettings.jsx";
import FieldSizeLimit from "./controls/limitation/fieldSizeLimit.jsx";
import FieldMaskSettings from "./controls/fieldMaskSettings.jsx";
import CheckerListComponent from "./controls/booleanListControl.jsx";
import ColorPickerControl from "../../cards/controls/colorPickerControl.jsx";
import { ignoreSaveHistoryList, entitySources, fieldTypes, getUiSources, getUiLanguages } from "../../../utils/systemConstants.jsx";

export default function FieldItemComponent({ item, unusedSources, blockDict, onClose, isMobile, sources, allSimpleDict, allExtDict, autocompleteList, clientTypes, currentUi, names, codes, onSave }) {
    const [state, setState] = useState({
        id: null,
        isDisabled: true,
        selectedSource: {},
        source: 0,
        fieldAlias: null,
        dict: [],
        dictionarySource: [],
        allDicts: [],
        type: {},
        autoFillType: 0,
        autoCompletes: [],
        isEditable: false,
        isAuto: false,
        isSensitive: false,
        isOnce: false,
        isOnlyImport: false,
        saveFieldHistory: false,
        isPopupEditor: false,
        isViewableFromCard: false,
        format: null,
        fractionalPart: -1,
        description: "",
        valuesList: null,
        color: null,
        code: "",
        forClient: [],
        minLength: 0,
        maxLength: 0,
        maskFirstSymbolsCount: 0,
        maskLastSymbolsCount: 0,
        maskSymbol: "*",
        langItem: {},
        sameNames: null,
        restriction: null,
        ignoreVerification: null,
        validateScript: null,
        isRestrictionsOn: false,
        fieldBlockList: [],
        isCanDelete: false,
        isEdited: false,
        isDisableType: false
    });

    useEffect(() => fetchData(), [item]);

    if (state.id === null)
        return;

    const content = [];

    const panelItems = [];
    const style = { width: "100%" };
    const types = structureSettingsFunc.getTypes(state.source, unusedSources);
    var selectedType = types.find(c => c.id === 0);

    if (state.type)
        selectedType = types.find(c => c.id === state.type);

    renderPair(content,
        window.captions.Identifier,
        `FieldIdValue_${state.id}`,
        () => <TextBox style={style} value={item.id} readOnly={true} />,
        true);

    renderPair(content,
        window.captions.Caption,
        `FieldNameEditor_${item.id}`,
        () => <LangItemEditor key={`langEditorField_${item.id}`}
            defaultName={item.localizeName}
            langItem={state.langItem}
            namedChanged={changeName} />);

    renderPair(content,
        window.captions.Source,
        "FieldSources",
        () => <DropDownList style={style}
            textField="name"
            dataItemKey="fieldAlias"
            data={sources}
            onChange={onChangeSourceValue}
            value={state.selectedSource} />);

    renderPair(content,
        window.captions.DataType,
        "FieldTypes",
        () => <DropDownList style={style}
            textField="name"
            disabled={state.isDisableType}
            dataItemKey="id"
            onChange={changeType}
            data={types}
            value={selectedType} />);

    if (state.type === fieldTypes.valueArray)
        panelItems.push({
            title: window.captions.CTypeArray,
            content: <div className="dm-m-left dm-m-right">
                <ValueArraySettings list={state.valuesList} onSaveValueArray={onSaveValueArray} fieldId={item.id} />
            </div>
        });

    if (!state.fieldAlias && (state.type === fieldTypes.simpleDictionary ||
        state.type === fieldTypes.extendedDictionary ||
        state.type === fieldTypes.multiSimpleDictionary ||
        state.type === fieldTypes.multiExtendedDictionary)) {
        panelItems.push({
            title: window.captions.Dictionary,
            content: <div className="dm-m-left">{
                <DictionaryControlComponent allDict={state.allDicts} dict={state.dict} fieldId={item.id} onSaveDict={onSaveDict} />}
            </div>
        });
    }

    if ((state.type === fieldTypes.date ||
        state.type === fieldTypes.time ||
        state.type === fieldTypes.dateTime) &&
        state.isRestrictionsOn)
        panelItems.push({
            title: window.captions.WordRestriction,
            content: <div className="dm-m-left">{
                <DateTimeLimitation restriction={state.restriction} fieldId={item.id} type={state.type} onSaveLimit={onSaveRestriction} />}
            </div>
        });

    if ((state.type === fieldTypes.integer || state.type === fieldTypes.double) && state.isRestrictionsOn)
        panelItems.push({
            title: window.captions.WordRestriction,
            content: <div className="dm-m-left">{
                <NumericLimitation restriction={state.restriction} fieldId={item.id} type={item.type} onSaveLimit={onSaveRestriction} />}
            </div>
        });

    if (state.type === fieldTypes.image)
        panelItems.push({
            title: window.captions.Picture,
            content: <div className="dm-m-left">{
                <PictureLimitationComponent restriction={state.restriction} fieldId={item.id} onSavePicture={onSaveRestriction} />}
            </div>
        });

    if (state.type === fieldTypes.text ||
        state.type === fieldTypes.integer ||
        state.type === fieldTypes.address ||
        state.type === fieldTypes.dateTime ||
        state.type === fieldTypes.date ||
        state.type === fieldTypes.time)
        renderPair(content,
            window.captions.FieldAutoFillType,
            "AutoFillSource",
            () => <DropDownList style={style}
                data={structureSettingsFunc.getAutocomplete(state.autoCompletes, state.type)}
                textField="name"
                dataItemKey="id"
                onChange={changeAutoFillType}
                value={state.autoCompletes.find(ac => ac.id === state.autoFillType)} />
        );

    if (state.type === fieldTypes.text) {
        panelItems.push({
            title: window.captions.ValidationCode,
            content: <TextArea defaultValue={state.validateScript} onChange={changeValidateScript} rows={15} />
        });

        panelItems.push({
            title: window.captions.FieldLength,
            content: <div className="dm-m-left dm-m-right">
                <FieldSizeLimit onChange={changeFieldLimit} fieldId={item.id} item={fieldSizeModel()} />
            </div>
        });

        panelItems.push({
            title: window.captions.FieldMask,
            content: <div className="dm-m-left dm-m-right">
                <FieldMaskSettings onChange={changeMaskSettings} item={fieldMaskModel()} />
            </div>
        });

    }

    if (state.type === fieldTypes.integer ||
        state.type === fieldTypes.double ||
        state.type === fieldTypes.date ||
        state.type === fieldTypes.time ||
        state.type === fieldTypes.timeZone)
        renderPair(content,
            window.captions.StringFormat,
            "StringFormat",
            () => <TextBox style={style} value={state.format} onChange={changeFormat} />);

    if (state.type === fieldTypes.time ||
        state.type === fieldTypes.date ||
        state.type === fieldTypes.dateTime ||
        state.type === fieldTypes.double ||
        state.type === fieldTypes.integer)
        renderPair(content,
            "",
            "FieldRestrictionsOn",
            () => <Checkbox key="fieldRestrictionsOn" value={state.isRestrictionsOn}
                label={window.captions.RestrictionsOn}
                onChange={changeIsRestrictionsOn} />,
            true);

    if (state.fractionalPart >= 0 && state.type === fieldTypes.double)
        renderPair(content,
            window.captions.DecimalPlaces,
            "FieldFractionalPart",
            () => <NumericTextBox key={`FP${item.Id}`}
                field={item.Id}
                min={0}
                max={4}
                value={state.fractionalPart}
                onChange={changeFractionalPart} />);

    if (state) {
        var optionItems = [];

        renderPair(optionItems,
            "",
            "AdditionalField",
            () => <Checkbox key="fieldIsEditable" value={state.isEditable} label={window.captions.FieldAdditional}
                onChange={changeIsEditable} />,
            true);

        if (state.source === entitySources.phone ||
            state.source === entitySources.address ||
            state.source === entitySources.email ||
            state.source === entitySources.url)
            renderPair(optionItems,
                "",
                "FieldViewableFromCard",
                () => <Checkbox key="fieldViewableFromCard" value={state.isViewableFromCard}
                    label={window.captions.ViewableFromCard}
                    onChange={changeIsViewableFromCard} />,
                true);

        if (state.source !== entitySources.phone) {
            renderPair(optionItems,
                "",
                "FieldIsAuto",
                () => <Checkbox key="fieldIsAuto" value={state.isAuto}
                    disabled={true}
                    label={window.captions.FieldAutoEditable}
                    onChange={changeIsAuto} />,
                true);
        }

        renderPair(optionItems,
            "",
            "FieldIsSensitive",
            () => <Checkbox key="fieldisSensitive" value={state.isSensitive}
                label={window.captions.FieldSensitive}
                onChange={changeIsSensitive} />,
            true);

        renderPair(optionItems,
            "",
            "FieldIsOnce",
            () => <Checkbox key="fieldIsOnce" value={state.isOnce}
                disabled={state.type === fieldTypes.phone}
                label={window.captions.FieldOnceEditable}
                onChange={changeIsOnce} />);

        if (state.source !== entitySources.users &&
            state.source !== entitySources.documents) {
            renderPair(optionItems,
                "",
                "FieldIsOnlyImport",
                () => <Checkbox key="fieldIsOnlyImport" value={state.isOnlyImport}
                    label={window.captions.FieldUpdateOnlyFromImport}
                    onChange={changeIsOnlyImport} />,
                true);
        }

        if ((state.source === entitySources.client ||
            state.source === entitySources.loan ||
            state.source === entitySources.collateral ||
            state.source === entitySources.users) &&
            !ignoreSaveHistoryList.includes(state.fieldAlias)) {
            renderPair(optionItems,
                "",
                "FieldIsSaveFieldHistory",
                () => <Checkbox key="fieldIsSaveFieldHistory" value={state.saveFieldHistory}
                    label={window.captions.SaveFieldHistory}
                    onChange={changeIsSaveFieldHistory} />,
                true);
        }

        if (state.type === fieldTypes.simpleDictionary ||
            state.type === fieldTypes.extendedDictionary ||
            state.type === fieldTypes.multiSimpleDictionary ||
            state.type === fieldTypes.multiExtendedDictionary)
            renderPair(optionItems,
                "",
                "FieldIsPopupEditor",
                () => <Checkbox key="fieldIsPopupEditor" value={state.isPopupEditor}
                    label={window.captions.PopupEditor}
                    onChange={changeIsPopupEditor} />,
                true);

        if ((state.source === entitySources.address ||
            state.source === entitySources.phone ||
            state.source === entitySources.url ||
            state.source === entitySources.email) &&
            (state.type === fieldTypes.text ||
                state.type === fieldTypes.integer ||
                state.type === fieldTypes.double ||
                (state.type === fieldTypes.date && !state.fieldAlias) ||
                (state.type === fieldTypes.time && !state.fieldAlias) ||
                (state.type === fieldTypes.dateTime && !state.fieldAlias)
            ))
            renderPair(optionItems,
                "",
                "FieldViewFieldsInAction",
                () => <Checkbox key="fieldViewFieldsInAction" value={state.isViewableFromHistory}
                    label={window.captions.ViewFieldsInAction}
                    onChange={changeIsViewableFromHistory} />,
                true);

        panelItems.push({
            title: window.captions.Parameters,
            content: <div className="dm-m-left dm-container-slim">{optionItems}</div>
        });
    }

    if (state.source === 0 && clientTypes && clientTypes.length > 0) {
        panelItems.push({
            title: window.captions.ShowOnlyForPersons,
            content: <div className="dm-container-slim">
                <CheckerListComponent items={clientTypes}
                    onSaveItem={onChangeClientType}
                    selected={state.forClient}
                    fieldId={item.id} />
            </div>
        });
    }

    if (state.sameNames && item.sameNames.length > 0) {
        panelItems.push({
            title: window.captions.SavedSimilarNames,
            content: <SameNamesComponent style={style} names={state.sameNames} fieldId={item.id} onSaveNames={onSaveNames} />
        });
    }

    if (state.id !== 0 &&
        (state.type === fieldTypes.text ||
            state.type === fieldTypes.integer ||
            state.type === fieldTypes.double ||
            state.type === fieldTypes.date ||
            state.type === fieldTypes.passport ||
            state.type === fieldTypes.boolean ||
            state.type === fieldTypes.valueArray ||
            state.type === fieldTypes.simpleDictionary ||
            state.type === fieldTypes.extendedDictionary ||
            state.type === fieldTypes.time ||
            state.type === fieldTypes.dateTime ||
            state.type === fieldTypes.timeZone ||
            state.type === fieldTypes.color))
        panelItems.push({
            title: window.captions.IgnoreVerificationValues,
            content: <div className="dm-m-left">{
                <VerifySettingsComponent key="IgnoreVerification"
                    fieldId={item.id}
                    fractionalPart={state.fractionalPart}
                    dictionarySource={state.dictionarySource}
                    valueArray={state.valuesList}
                    type={state.type}
                    items={state.ignoreVerification}
                    onSaveVerify={onSaveVerify} />}
            </div>
        });

    if (blockDict && blockDict.length > 0) {
        panelItems.push({
            title: window.captions.ReferencesOnField,
            content: <FieldBlockComponent style={style} blockDict={state.fieldBlockList} fieldId={item.id} onCheckCanDelete={onCheckCanDelete} />
        });
    }

    if (panelItems.length > 0)
        content.push(<PanelBar key={`PanelBarParameters${item.id}`} className="k-panelbar dm-m-bottom">
            {PanelBarUtils.mapItemsToComponents(panelItems)}
        </PanelBar>);

    renderPair(content,
        window.captions.Description,
        "FieldDescription",
        () => <TextBox style={style} value={state.description} onChange={changeDescription} />);

    if (state.type === fieldTypes.text ||
        state.type === fieldTypes.integer ||
        state.type === fieldTypes.double ||
        state.type === fieldTypes.date ||
        state.type === fieldTypes.passport ||
        state.type === fieldTypes.boolean ||
        state.type === fieldTypes.valueArray ||
        state.type === fieldTypes.time ||
        state.type === fieldTypes.edrvp ||
        state.type === fieldTypes.dateTime ||
        state.type === fieldTypes.timeZone) {
        var valueColor = state.color ? state.color : null;
        if (state.color && state.color.length === 9)
            valueColor = `#${state.color.substr(state.color.length - 6)}`;

        renderPair(content,
            window.captions.Color,
            "FieldColor",
            () => <ColorPickerControl view={"combo"}
                field={item.modelName}
                value={valueColor}
                changeValue={changeColor} />);
    }

    if (state.fieldAlias && state.fieldAlias.length > 0) {
        renderPair(content,
            window.captions.Code,
            "FieldCode",
            () => <TextBox style={style} readOnly={true} value={state.fieldAlias} />);
    } else {
        renderPair(content,
            window.captions.Code,
            "FieldCode",
            () => <TextBox style={style} value={state.code} onChange={changeCode} />);
    }

    const dialogActions = <DialogActionsBar>
        <Button icon="undo" onClick={() => onClose(true)}>{window.captions.Cancel}</Button>
        <Button icon="save" disabled={!state.isEdited} className="dm-positive-filled" onClick={save}>{window.captions.Save}</Button>
        {item && item.id > 0
            ? <Button icon="delete" className="dm-negative-filled" onClick={deleteItem}>{window.captions.Delete}</Button>
            : null}
    </DialogActionsBar>;

    let dialog;

    if (isMobile)
        dialog = <Dialog title={window.captions.MainMenuConfig}
            key="dialogEditorFields"
            onClose={() => onClose(false)}>
            <div className="dm-container dm-container-slim dm-m-top">
                {content}
            </div>
            {dialogActions}
        </Dialog>;
    else
        dialog = <InlineDialog title={window.captions.MainMenuConfig}
            key="dialogEditorFields"
            fullHeight={true}
            buttons={dialogActions}
            onClose={() => onClose(false)}>
            <div className="dm-container dm-container-slim dm-m-top">
                {content}
            </div>
        </InlineDialog>;

    if (item)
        return dialog;
    else
        return null;

    function fetchData() {
        if (!item)
            return;

        var selectedSource = {};
        if (sources)
            selectedSource = sources.length > 1
                ? sources.find(s => s.fieldAlias === item.fieldAlias)
                : sources[0];

        var ignoreVerification = [];
        if (item.ignoreVerification) {
            item.ignoreVerification.map(i => {
                if (item.type === fieldTypes.date ||
                    item.type === fieldTypes.dateTime ||
                    item.type === fieldTypes.time) {
                    if (i === null)
                        ignoreVerification.push(null);
                    else
                        ignoreVerification.push(new Date(i));
                } else if (item.type === fieldTypes.boolean) {
                    if (i === null)
                        ignoreVerification.push(null);
                    else
                        ignoreVerification.push(i ? 1 : 0);
                } else {
                    ignoreVerification.push(i);
                }
            });
        }

        let isRestrictionOn = false;

        if (item.restriction)
            isRestrictionOn = item.type === fieldTypes.integer || item.type === fieldTypes.double
                ? item.restriction.minType !== 2
                : item.restriction.minType !== 2 || item.restriction.maxType !== 2;

        let allDict = [];
        if (item.type === fieldTypes.simpleDictionary)
            allDict = allSimpleDict;
        if (item.type === fieldTypes.multiSimpleDictionary)
            allDict = allSimpleDict;
        if (item.type === fieldTypes.extendedDictionary)
            allDict = allExtDict;
        if (item.type === fieldTypes.multiExtendedDictionary)
            allDict = allExtDict;

        var isDisabled = item.id === 0 ? false : true;
        setState({
            ...state,
            id: item.id,
            fieldAlias: item.fieldAlias,
            isDisabled: isDisabled,
            source: item.source,
            selectedSource: selectedSource,
            type: item.type,
            autoFillType: item.autoFillType,
            isEditable: item.isEditable,
            isAuto: item.isAuto,
            isSensitive: item.isSensitive,
            isOnce: item.isOnce,
            isOnlyImport: item.isOnlyImport,
            saveFieldHistory: item.saveFieldHistory,
            isPopupEditor: item.isPopupEditor,
            isViewableFromCard: item.isViewableFromCard,
            isViewableFromHistory: item.isViewableFromHistory,
            format: item.format ? item.format : "",
            fractionalPart: item.fractionalPart,
            description: item.description ? item.description : "",
            valuesList: item.valuesList,
            color: item.color,
            code: item.code ? item.code : "",
            forClient: item.forClient ? item.forClient : [],
            minLength: item.textLengthMin ? item.textLengthMin : 0,
            maxLength: item.textLength ? item.textLength : 0,
            maskFirstSymbolsCount: item.formatMask ? item.formatMask.firstSymbolsCount : 0,
            maskLastSymbolsCount: item.formatMask ? item.formatMask.lastSymbolsCount : 0,
            maskSymbol: item.formatMask ? item.formatMask.symbol : "*",
            langItem: item.langItem,
            dict: item.dict,
            dictionarySource: item.dictionarySource,
            allDicts: allDict,
            sameNames: item.sameNames,
            restriction: item.restriction,
            validateScript: item.validateScript,
            ignoreVerification: ignoreVerification,
            isRestrictionsOn: isRestrictionOn,
            fieldBlockList: blockDict,
            isCanDelete: blockDict ? blockDict.length <= 0 : false,
            isEdited: item.id !== state.id ? false : state.isEdited,
            isDisableType: item.id !== 0 || (!!item.fieldAlias && item.fieldAlias.length > 0)
        });
    }

    function getSaveModel(isDeleted = false) {
        const model = state;

        state.langItem.items = state.langItem.items.sort(function (a, b) {
            return a.id - b.id || a.name.localeCompare(b.name);
        });
        let itemName = state.langItem.items.find((item) => item.name);

        return {
            id: item.id,
            name: itemName ? itemName.name : null,
            modelName: item.modelName,
            fieldAlias: model.fieldAlias,
            type: model.type,
            sourceType: item.sourceType,
            isEditable: model.isEditable,
            isAuto: model.isAuto,
            isSensitive: model.isSensitive,
            isOnce: model.isOnce,
            isOnlyImport: model.isOnlyImport,
            isSaveFieldHistory: model.isSaveFieldHistory,
            isPopupEditor: model.isPopupEditor,
            isViewableFromCard: model.isViewableFromCard,
            isViewableFromHistory: model.isViewableFromHistory,
            source: model.source,
            autoFillType: model.autoFillType ? model.autoFillType : 0,
            saveFieldHistory: model.saveFieldHistory,
            format: model.format,
            fractionalPart: model.fractionalPart,
            description: model.description ? model.description : "",
            valuesList: model.valuesList,
            color: model.color,
            code: model.code,
            forClient: model.forClient ? model.forClient : [],
            textLengthMin: model.minLength > 0 ? model.minLength : null,
            textLength: model.maxLength > 0 ? model.maxLength : null,
            formatMask: { firstSymbolsCount: state.maskFirstSymbolsCount, symbol: state.maskSymbol, lastSymbolsCount: state.maskLastSymbolsCount },
            langItem: model.langItem,
            dict: model.dict,
            sameNames: model.sameNames,
            validateScript: model.validateScript,
            restriction: model.type === fieldTypes.text
                ? model.restriction
                : state.isRestrictionsOn
                    ? model.restriction
                    : null,
            ignoreVerification: model.ignoreVerification,
            fieldBlockList: blockDict,
            isDeleted: isDeleted
        };
    }


    function save() {
        var saveModel = getSaveModel();
        if (!saveModel.name || !saveModel.langItem.items) {
            alert(window.captions.MessageBoxEmptyNameDeny);
            return;
        }
        const dictTypes = [
            fieldTypes.simpleDictionary,
            fieldTypes.multiSimpleDictionary,
            fieldTypes.extendedDictionary,
            fieldTypes.multiExtendedDictionary
        ];

        if ((!saveModel.dict || saveModel.dict.length <= 0) &&
            dictTypes.includes(saveModel.type)) {
            alert(window.captions.SelectDictionary);
            return;
        }

        saveModel.langItem.items.map(item => {
            if (typeof item.name === "string" && item.name.length === 0) {
                var langKey = getUiSources().find(lang => lang.id === item.id);
                var language = getUiLanguages().find(lang => lang.link === langKey.name);
                alert(`${window.captions.MessageBoxEmptyNameDeny} (${language.name})`);
            }
        });

        let name = saveModel.name;
        let selectedLang = saveModel.langItem.items.find(item => item.id === currentUi.id);
        if (selectedLang)
            name = selectedLang.name;

        let findNameDuplicate = names.find(item => item.name === name && item.id !== saveModel.id);
        if (findNameDuplicate) {
            alert(`${window.captions.MessageBoxDuplicateNameDeny} (${findNameDuplicate.name})\r\nId: ${findNameDuplicate.id}`);
            return;
        }

        let findCodeDuplicate = codes.find(item => item.value === saveModel.code && item.id !== saveModel.id);
        if (findCodeDuplicate) {
            alert(`${window.captions.MessageBoxDuplicateCodeDeny} (${findCodeDuplicate.value})\r\nId: ${findCodeDuplicate.id}`);
            return;
        }

        onSave(saveModel);
    }

    function deleteItem() {
        if (!state.isCanDelete) {
            var items = [];

            state.fieldBlockList.map(item => {
                let fields = [];
                item.items.map(i => fields.push(`- ${i}\r\n`));
                items.push(`${item.module.name}:\r\n${fields.join('')}`);
            });

            alert(window.captions.MessageBoxRemoveFieldBlock.replaceAll("{0}", "") + items.join(''));
            return;
        }

        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        var saveModel = getSaveModel(true);
        onSave(saveModel);
    }

    function onCheckCanDelete(item) {
        setState({
            ...state,
            fieldBlockList: item,
            isCanDelete: item.length > 0
        });
    }

    function onSaveDict(item) {
        setState({
            ...state,
            dict: item,
            isEdited: true
        });
    }

    function onSaveNames(item) {
        setState({
            ...state,
            sameNames: item,
            isEdited: true
        });
    }

    function onSaveValueArray(item) {
        setState({
            ...state,
            valuesList: item,
            isEdited: true
        });
    }

    function onSaveVerify(isNull, items) {
        var newValue = [];

        if (isNull)
            newValue.push(null);

        items.map(item => newValue.push(item));

        setState({
            ...state,
            ignoreVerification: newValue,
            isEdited: true
        });
    }

    function changeIsRestrictionsOn(e) {
        setState({
            ...state,
            isRestrictionsOn: e.value,
            restriction: !e.value ? item.restriction : state.restriction,
            isEdited: true
        });
    }

    function changeIsEditable(e) {
        setState({
            ...state,
            isEditable: e.value,
            isEdited: true
        });
    }

    function changeType(e) {
        let allDict = [];
        if (e.value.id === fieldTypes.simpleDictionary)
            allDict = allSimpleDict;
        if (e.value.id === fieldTypes.multiSimpleDictionary)
            allDict = allSimpleDict;
        if (e.value.id === fieldTypes.extendedDictionary)
            allDict = allExtDict;
        if (e.value.id === fieldTypes.multiExtendedDictionary)
            allDict = allExtDict;

        setState({
            ...state,
            type: e.value.id,
            allDicts: allDict,
            isEdited: true
        });
    }

    function changeIsAuto(e) {
        setState({
            ...state,
            isAuto: e.value,
            isEdited: true
        });
    }

    function changeIsOnce(e) {
        setState({
            ...state,
            isOnce: e.value,
            isEdited: true
        });
    }

    function changeIsSensitive(e) {
        setState({
            ...state,
            isSensitive: e.value,
            isEdited: true
        });
    }

    function changeIsOnlyImport(e) {
        setState({
            ...state,
            isOnlyImport: e.value,
            isEdited: true
        });
    }

    function changeIsSaveFieldHistory(e) {
        setState({
            ...state,
            saveFieldHistory: e.value,
            isEdited: true
        });
    }

    function changeIsPopupEditor(e) {
        setState({
            ...state,
            isPopupEditor: e.value,
            isEdited: true
        });
    }

    function changeIsViewableFromHistory(e) {
        setState({
            ...state,
            isViewableFromHistory: e.value,
            isEdited: true
        });
    }

    function changeIsViewableFromCard(e) {
        setState({
            ...state,
            isViewableFromCard: e.value,
            isEdited: true
        });
    }

    function onChangeClientType(e) {
        setState({
            ...state,
            forClient: e,
            isEdited: true
        });
    }

    function onChangeSourceValue(e) {
        let alias = e.value ? e.value.fieldAlias : null;
        if (alias && alias.length <= 0)
            alias = null;

        setState({
            ...state,
            selectedSource: e.value,
            fieldAlias: alias,
            isDisableType: state.id !== 0 || alias,
            type: !alias ? state.type : e.value.type,
            isEdited: true
        });
    }

    function changeName(e) {
        setState({
            ...state,
            langItem: e.selectLang,
            isEdited: true
        });
    }

    function changeColor(e) {
        setState({
            ...state,
            color: e.value,
            isEdited: true
        });
    }

    function changeFormat(e) {
        setState({
            ...state,
            format: e.value,
            isEdited: true
        });
    }

    function changeCode(e) {
        setState({
            ...state,
            code: e.value,
            isEdited: true
        });
    }

    function changeDescription(e) {
        setState({
            ...state,
            description: e.value,
            isEdited: true
        });
    }

    function changeValidateScript(e) {
        setState({
            ...state,
            validateScript: e.value,
            isEdited: true
        });
    }

    function changeFractionalPart(e) {
        var value = e.value;
        if (value <= 0)
            value = 0;
        setState({
            ...state,
            fractionalPart: value,
            isEdited: true
        });
    }

    function changeFieldLimit(item) {
        setState({
            ...state,
            minLength: item.minValue,
            maxLength: item.maxValue,
            isEdited: true
        });
    }

    function fieldSizeModel() {
        return {
            fieldId: item.id,
            minValue: state.minLength,
            maxValue: state.maxLength
        }
    }

    function changeMaskSettings(item) {
        setState({
            ...state,
            maskSymbol: item.maskSymbol,
            maskFirstSymbolsCount: item.maskFirstSymbolsCount,
            maskLastSymbolsCount: item.maskLastSymbolsCount,
            isEdited: true
        });
    }

    function fieldMaskModel() {
        return {
            fieldId: item.id,
            maskSymbol: state.maskSymbol,
            maskFirstSymbolsCount: state.maskFirstSymbolsCount,
            maskLastSymbolsCount: state.maskLastSymbolsCount
        }
    }

    function changeAutoFillType(e) {
        setState({
            ...state,
            autoFillType: e.value.id,
            isEdited: true
        });
    }

    function onSaveRestriction(e) {
        setState({
            ...state,
            restriction: e,
            isEdited: true
        });
    }
}