import React from "react";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { getBooleanSources } from "../searchConstants.jsx";

export default function BoolControl({ value, field, disabled, changedValue }) {
    const fieldId = field.id;
    let intValue = parseInt(value);
    if (isNaN(intValue)) {
        intValue = parseInt(field.defaultValue);
        if (isNaN(intValue))
            intValue = 2;
    }

    return <div className="dm-wrapper-content dm-boolean-input">
        <RadioGroup data={getBooleanSources()}
            disabled={disabled}
            id={`Bool${fieldId}`}
            onChange={(e) => changedValue({ field: fieldId, value: e.value })}
            value={intValue}
            layout="horizontal" />
    </div>
}