// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersGeolocationPanel .dm-wrapper-content.k-input.k-input-solid {
    height: auto;
    max-height: 75px;
    overflow: auto
}

.dm-mobile .usersGeolocationPanel .dm-wrapper-content.k-input.k-input-solid {
    max-height: 355px;
}

.usersGeolocationPopover{
    max-width: 100%;
}

.usersGeolocationPopover .k-popover-header {
    padding-block: 5px;
}

.usersGeolocationPopover .k-popover-body {
    padding-block: 5px;
    max-height: 300px;
    overflow: auto
}

.usersGeolocationPopover .k-popover-actions {
    display: block;
    margin: 0 !important;
    max-height: 130px;
    overflow: auto;
}

.usersGeolocationPopover .k-popover-actions .k-button {
    display: block;
}

.usersGeolocationPop {
    padding-bottom: 5px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));
}

.usersGeolocationPop:last-child {
    border-width: 0;
}`, "",{"version":3,"sources":["webpack://./ClientApp/styles/css/usersGeolocation.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB;AACJ;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;IACnB,+DAA+D;AACnE;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".usersGeolocationPanel .dm-wrapper-content.k-input.k-input-solid {\r\n    height: auto;\r\n    max-height: 75px;\r\n    overflow: auto\r\n}\r\n\r\n.dm-mobile .usersGeolocationPanel .dm-wrapper-content.k-input.k-input-solid {\r\n    max-height: 355px;\r\n}\r\n\r\n.usersGeolocationPopover{\r\n    max-width: 100%;\r\n}\r\n\r\n.usersGeolocationPopover .k-popover-header {\r\n    padding-block: 5px;\r\n}\r\n\r\n.usersGeolocationPopover .k-popover-body {\r\n    padding-block: 5px;\r\n    max-height: 300px;\r\n    overflow: auto\r\n}\r\n\r\n.usersGeolocationPopover .k-popover-actions {\r\n    display: block;\r\n    margin: 0 !important;\r\n    max-height: 130px;\r\n    overflow: auto;\r\n}\r\n\r\n.usersGeolocationPopover .k-popover-actions .k-button {\r\n    display: block;\r\n}\r\n\r\n.usersGeolocationPop {\r\n    padding-bottom: 5px;\r\n    border-width: 0 0 1px;\r\n    border-style: solid;\r\n    border-color: var(--kendo-color-border, rgba(33, 37, 41, 0.13));\r\n}\r\n\r\n.usersGeolocationPop:last-child {\r\n    border-width: 0;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
