import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";

export default function DialogAnswerPicker({ loanId, source, clientId, scenarioId, stateId, stageId, projectId, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [scenario, setScenario] = useState([]);
    const [scenarios, setScenarios] = useState([]);
    const [validate, setValidate] = useState(false);

    useEffect(() => fetchData(), []);

    if (pending || error) {
        return <Dialog onClose={() => { onClose(0); }} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }
    const result = [];
    if (validate) {
        return <ValidationMessage key="validateDialog" close={onClose} text={validate} error={true} />;
    }
    let scenarioModel;
    if (scenario.length === 0) {
        scenarioModel = scenarios;
    } else {
        scenarioModel = scenario;
    }

    result.push(<div key="text" style={{ whiteSpace: "pre-line" }}>{scenarioModel.text}</div>);
    scenarioModel.items.forEach((answer, index) => {
        result.push(<Button key={`answer${index}`} style={{ width: "100%", margin: "5px", whiteSpace: "pre-line" }} themeColor="primary"
            onClick={() => clickRenderAnswer(answer)}>{answer.name}</Button>
        );
    });
    const withoutParent = scenarioModel.items.find((x) => x.parentId === 0);
    if (!withoutParent) {
        result.push(<Button key={`back`} style={{ width: "100%", margin: "5px", whiteSpace: "pre-line" }} themeColor="primary"
            onClick={() => clickToBackRenderAnswer()}>{window.captions.Back}</Button>
        );
    }
    return <Dialog title={window.captions.Scenario} onClose={() => onClose(scenario.selectionActionResultId)} width={400} maxWidth={400} height={800}>
        {result}
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);

        let project = 0;
        if (projectId !== undefined)
            project = projectId;

        fetchGet(
            `${window.constants.getScenarioModel}/${source}/${loanId}/${clientId}/${scenarioId}/${project}/${stateId}/${stageId}`,
            (data) => {
                setScenarios(data);
                setPending(null);
                setError(null);
                if (data.items.length <= 0) {
                    setValidate(window.captions.NoAnswersAvailable);
                }
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function getParentScenario(scenarioId, items) {
        for (let key in items) {
            const scenario = items[key];
            if (scenario.id === scenarioId)
                return scenario;

            const parent = scenario.items.find((x) => x.id === scenarioId);
            if (parent && parent.id > 0) {
                return scenario;
            }
            const getScenario = getParentScenario(scenarioId, scenario.items);
            if (getScenario && getScenario.id > 0) {
                return getScenario;
            }
        }
        return null;
    }

    function clickRenderAnswer(answer) {
        setScenario(answer);
    }

    function clickToBackRenderAnswer() {
        let parent;

        if (scenario.parentId === 0) {
            parent = scenarios;
        }
        else
            parent = getParentScenario(scenario.id, scenarios.items);

        if (parent === null)
            parent = scenarios;

        setScenario(parent);
    }
}