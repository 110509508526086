import { entitySourcesNames } from "../../utils/systemConstants.jsx";

export function renderAction(card, controls, isReadonly) {
    const model = card.model;
    if (!isReadonly) {
        controls.push({
            text: card.isEditMode ? window.captions.View : window.captions.EditMode,
            icon: card.isEditMode ? "eye" : "pen",
            action: card.isEditMode ? "View" : "Edit"
        });

        controls.push({
            text: window.captions.Save,
            icon: "save",
            disabled: !card.isUpdated,
            action: "Save"
        });

        controls.push({ separator: true });
    }

    if (!model.isCopy) {
        if (model.access.canAddHistory)
            controls.push({
                action: "History",
                text: window.captions.NewAction,
                icon: "plus-square",
                className: "dm-positive"
            });
        if (model.access.canAddTask)
            controls.push({
                action: "Task",
                text: window.captions.NewTask,
                icon: "tasks",
                className: "dm-positive"
            });
        if (model.access.canAddDocument)
            controls.push({
                action: "Document",
                text: window.captions.NewDocument,
                icon: "paperclip",
                className: "dm-positive"
            });
        if (model.access.canAddLoan)
            controls.push({
                action: "Loan",
                text: window.captionsDynamic.LoanSingleNominative,
                icon: "money-check",
                className: "dm-positive"
            });
        if (model.access.canAddLinkedClient)
            controls.push({
                action: "AddLinkedClient",
                text: window.captions.ThirdPerson,
                icon: "user-tie",
                className: "dm-positive"
            });
        if (model.access.canAddPromiseScheduler)
            controls.push({
                action: "AddPromiseScheduler",
                text: window.captions.PromiseScheduler,
                icon: "fa-chart-line",
                className: "dm-positive",
                disabled: !model.hasPromiseToAdd
            });
        if (model.access.canAddPayment)
            controls.push({
                action: "AddPayment",
                text: window.captions.NewPayment,
                icon: "fa-hand-holding-usd",
                className: "dm-positive",
                disabled: !model.hasPaymentFields
            });
        if (model.access.canAddCollateral)
            controls.push({
                action: "AddCollateral",
                text: window.captionsDynamic.Collaterals,
                icon: "hotel",
                className: "dm-positive"
            });
        if (model.access.canAddCollateralOwner) {
            controls.push({
                action: "AddCollateralOwner",
                text: window.captionsDynamic.CollateralOwner,
                icon: "user-tie",
                className: "dm-positive"
            });
        }
        if (model.access.canAddEditProcess)
            controls.push({
                action: "AddProcess",
                text: window.captions.Process,
                icon: "fa-cogs",
                className: "dm-positive"
            });

        if (model.access.canAddEditAccount)
            controls.push({
                action: "AddAccount",
                text: window.captions.Account,
                icon: "fa-credit-card",
                className: "dm-positive"
            });

        if (model.access.canAddQuestionnaire) {
            const questionnaires = model.questionnaires;
            if (questionnaires.length <= 2) {
                for (var i = 0; i < questionnaires.length; i++) {
                    controls.push({
                        action: "Questionnaire",
                        actionid: questionnaires[i].id,
                        text: questionnaires[i].name,
                        icon: "fa-clipboard-list",
                        className: "dm-positive"
                    });
                }
            } else {
                controls.push({
                    action: "Questionnaire",
                    text: window.captions.Questionnaire,
                    icon: "fa-clipboard-list",
                    className: "dm-positive"
                });
            }
        }

        if (model.access.canCopyClient)
            controls.push({
                action: "CopyClient",
                text: window.captions.Duplicate,
                icon: "fa-copy",
                className: "dm-accent-brush"
            });

        if (model.access.canCopyLoan)
            controls.push({
                action: "CopyLoan",
                text: window.captions.Duplicate,
                icon: "fa-copy",
                className: "dm-accent-brush"
            });

        const hasReports = model.exportReports && model.exportReports.length > 0;
        const hasDocuments = model.exportDocuments && model.exportDocuments.length > 0;
        if (hasReports || hasDocuments) {
            controls.push({ separator: true });
            if (hasReports) {
                var reports = model.exportReports;
                if (reports.length === 1) {
                    var report = reports[0];
                    if (report.items.length <= 2) {
                        for (var i = 0; i < report.items.length; i++) {
                            controls.push({
                                action: "ExportReportItem",
                                actionid: report.items[i].id,
                                text: report.items[i].name,
                                icon: "fa-file-excel",
                                className: "dm-positive"
                            });
                        }
                    } else {
                        controls.push({
                            action: "ExportReportFolder",
                            actionid: report.id,
                            text: report.name,
                            icon: "fa-file-excel",
                            className: "dm-positive"
                        });
                    }
                } else if (reports.length <= 2) {
                    for (var i = 0; i < reports.length; i++) {
                        controls.push({
                            action: "ExportReportFolder",
                            actionid: reports[i].id,
                            text: reports[i].name,
                            icon: "fa-file-excel",
                            className: "dm-positive"
                        });
                    }
                } else {
                    controls.push({
                        action: "ExportReport",
                        text: window.captions.Report,
                        icon: "fa-file-excel",
                        className: "dm-positive"
                    });
                }
            }

            if (hasDocuments) {
                var documents = model.exportDocuments;
                if (documents.length === 1) {
                    var document = documents[0];
                    if (document.items.length <= 2) {
                        for (var i = 0; i < document.items.length; i++) {
                            controls.push({
                                action: "ExportDocumentItem",
                                actionid: document.items[i].id,
                                text: document.items[i].name,
                                icon: "fa-file-word",
                                className: "dm-accent-brush"
                            });
                        }
                    } else {
                        controls.push({
                            action: "ExportDocumentFolder",
                            actionid: document.id,
                            text: document.name,
                            icon: "fa-file-word",
                            className: "dm-accent-brush"
                        });
                    }
                } else if (documents.length <= 2) {
                    for (var i = 0; i < documents.length; i++) {
                        controls.push({
                            action: "ExportDocumentFolder",
                            actionid: documents[i].id,
                            text: documents[i].name,
                            icon: "fa-file-word",
                            className: "dm-accent-brush"
                        });
                    }
                } else {
                    controls.push({
                        action: "ExportDocument",
                        text: window.captions.Document,
                        icon: "fa-file-word",
                        className: "dm-accent-brush"
                    });
                }
            }
        }

        if (model.access.canOpenStrategyRegister) {
            controls.push({ separator: true });
            controls.push({
                action: "StrategyRegister",
                text: window.captions.StrategyRegister,
                icon: "fa-chess-rook",
                className: "dm-accent-brush"
            });
        }
        if (model.access.canResetStrategy) {
            controls.push({
                action: "StrategyReset",
                text: window.captions.CardStreategyReset,
                icon: "fa-redo-alt",
                className: "dm-accent-brush"
            });
        }
        if (model.isArchived && model.access.canRestoreArchive) {
            controls.push({
                action: "ArchiveRestore",
                text: window.captions.RestoreArchive,
                icon: "fa-box-open",
                className: "dm-accent-brush"
            });
        } else if (!model.isArchived && model.access.canArchive) {
            controls.push({
                action: "Archive",
                text: window.captions.SendToArchive,
                icon: "fa-box",
                className: "dm-accent-brush"
            });
        }

        if (card.entity === entitySourcesNames.callRecords) {
            if (model.access.canListenCallRecords) {
                controls.push({
                    action: "RecordPlay",
                    text: window.captions.PlayRecording,
                    icon: "fa-play-circle",
                    className: "dm-positive"
                });
            }
            if (model.access.canRatingCallRecords) {
                controls.push({
                    action: "RecordRating",
                    text: window.captions.Rating,
                    icon: "fa-balance-scale",
                    className: "dm-positive"
                });
            }
        }
        if (card.entity === entitySourcesNames.documents) {
            controls.push({
                action: "DownloadDocument",
                text: window.captions.Load,
                icon: "fa-file-download",
                className: "dm-positive"
            });
            controls.push({
                action: "PrintBarcode",
                text: window.captions.PrintBarcode,
                icon: "barcode",
                className: "dm-positive"
            });
        }
    }

    if (controls.length === 0)
        return;

    if (controls.length > 0 && !card.isMobile) {
        controls.unshift({
            icon: card.expanded ? "chevron_right" : "chevron_left",
            action: "ExpandChange"
        });
    }
}