import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function GridButtonIconCell(props) {
    const icon = props.icon;
    return <td {...getLinkCellStyle(props)}>
        <Button iconClass={`dm-i dm-i-${icon}`} onClick={onClick} />
    </td>;

    function onClick() {
        props.onClick(props.dataItem[props.field]);
    }
}