import React, { useState, useEffect } from 'react';
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";

export default function RelativeRoles({ dataRule, ruleList }) {
    const [items, setItems] = useState([]);

    useEffect(() => mountProperties(), [dataRule, ruleList, ruleChanged]);

    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={items}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;

    function mountProperties() {
        if (!dataRule || !rules) {
            setItems([]);
            return;
        }

        let ruleList = Array.isArray(rules) && rules.length ? rules : [];
        ruleList.forEach(i => i.isSelected = dataRule.linkedRoles.some(id => id == i.id));

        setItems(ruleList);
    }

    function selectedItemsChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                linkedRoles: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }
}