import React from "react";
import { useState, useEffect } from 'react';
import { ListView } from "@progress/kendo-react-listview";
import { EditListViewItem } from "./editListViewItem.jsx";

export default function SameNamesComponent(props) {
    const [names, setNames] = useState([]);
    const [fieldId, setFieldId] = useState(0);

    useEffect(() => fetchData(), [props.fieldId]);

    return <ListView data={names}
        item={nameItem}
        style={{ width: "100%" }} />;

    function fetchData() {
        if (!props)
            return;

        setNames(props.names);
        setFieldId(props.fieldId);
    }

    function deleteItem(item) {
        var newValue = names.filter((e) => e !== item.dataItem);
        setNames(newValue);
        props.onSaveNames(newValue);
    };

    function nameItem(item) {
        return <EditListViewItem
            key={`ListEditItem_${item.itemId}`}
            item={item}
            fieldId={fieldId}
            deleteItem={deleteItem} />;
    }
}