import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export default function ValidationMessage(props) {
    const text = props.text;
    const isArrayText = Array.isArray(text);
    const buttonText = props.button ? props.button : window.captions.WordOk;
    const title = props.title
        ? props.title
        : isArrayText
            ? window.captions.Tracking
            : window.captions.WordError;
    let message;
    if (isArrayText) {
        message = text.map((text, key) => <div key={`valid${key}`}>{text}</div>);
    } else {
        message = text;
    }
    return <Dialog title={title}
        minWidth={300}
        maxWidth={800}
        onClose={props.close}
        className={props.error ? "dm-error-dialog" : ""}>
        {message}
        <DialogActionsBar>
            <Button onClick={props.close}>{buttonText}</Button>
        </DialogActionsBar>
    </Dialog>;
}