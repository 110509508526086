import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import LoadingMask from "../loadingMask.jsx";
import ErrorMask from "../errorMask.jsx";
import { getLocation } from "../../utils/locationHelper.jsx";
import { executeLogin } from '../../containers/header/headerReducer.jsx';
import "isomorphic-fetch";

export default function LoginForm() {
    const dispatch = useDispatch();
    const [isServerConnected, setIsServerConnected] = useState(false);
    const [isLoadingServerState, setIsLoadingServerState] = useState(true);
    const [location, setLocation] = useState(null);
    const [message, setMessage] = useState(null);
    const [login, setLogin] = useState(window.systemConfig && window.systemConfig.domainAuthPrefix.length > 0
        ? `${window.systemConfig.domainAuthPrefix}\\`
        : "");
    const [password, setPassword] = useState("");

    const isLoginProcess = useSelector(state => state.header.isLoginProcess);
    const errorMessage = useSelector(state => state.header.error);

    useEffect(() => {
        if (window.systemConfig.userLogonsGeolocation) {
            getLocation(coords => { setLocation(coords); }, () => { });
        }

        fetchConnected();
    }, []);

    if (isLoadingServerState)
        return <LoadingMask text="Check server state ..." />;

    if (!isServerConnected) {
        if (!message)
            message = "Server offline";

        return <ErrorMask error={message} iconClass="fas fa-plug" />;
    }

    const loginPanel = isLoginProcess
        ? <LoadingMask text="..." />
        : <div />;

    const error = errorMessage
        ? <div className={"k-messagebox k-messagebox-error"}>
            {errorMessage}
        </div>
        : null;

    return <div className="dm-login-back">
        <form className="k-form dm-login-form" onSubmit={handleSubmit}>
            <fieldset>
                <div>
                    <Input
                        value={login}
                        name="login"
                        onChange={(e) => setLogin(e.target.value)}
                        style={{ width: "100%" }}
                        label={window.captions.Login}
                        required={true}
                        validationMessage={window.captions.CorrectLogin} />
                </div>
                <div>
                    <Input
                        key="password"
                        name="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: "100%" }}
                        label={window.captions.Password}
                        required={true}
                        validationMessage={window.captions.CorrectLogin} />
                </div>
                {error}
                <div className="dm-logo" src="img/logo.png" alt="Crm Logo">
                    <Button type="submit" themeColor="primary">{window.captions.WordEnter}</Button>
                </div>
            </fieldset>
        </form>
        {loginPanel}
    </div>;

    function handleSubmit(event) {
        event.preventDefault();
        dispatch(executeLogin(login, password, location));
    }

    async function fetchConnected() {
        fetch(window.constants.isConnected, { method: "GET", accept: "application/json" })
            .then(response => {
                if (response.ok)
                    return response.json();

                throw new Error("Request error");
            })
            .then((data) => {
                setIsLoadingServerState(false);
                setIsServerConnected(data.isConnected);
                setMessage(data.error);
            })
            .catch((err) => {
                setIsLoadingServerState(false);
                setIsServerConnected(false);
                setMessage(err);
            });
    }
}