function itemFinder(items, condition) {
    const item = items.find((i) => condition(i));
    if (item)
        return item;

    for (const subItem of items) {
        if (subItem.items && subItem.items.length > 0) {
            const subFounded = itemFinder(subItem.items, condition);
            if (subFounded) {
                subItem.expanded = true;
                return subFounded;
            }
        }
    }
    return null;
}

function alternatingItemFinder(items, condition) {
    const item = items.find((i) => condition(i));
    if (item)
        return [item];

    for (const subItem of items) {
        if (subItem.items && subItem.items.length > 0) {
            const subFounded = alternatingItemFinder(subItem.items, condition);
            if (subFounded) {
                var result = [subItem];
                subFounded.forEach(function(i) { result.push(i) });
                return result;
            }
        }
    }
    return null;
}

export {
    alternatingItemFinder,
    itemFinder
    };