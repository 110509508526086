import { getToken, clearAuth } from "./authHelper.jsx";
import "isomorphic-fetch";

function headersGet() {
    return {
        method: "GET",
        accept: "application/json",
        headers: { 'Authorization': `Bearer ${getToken()}` }
    };
}

function headersPost(data) {
    return {
        method: "POST",
        accept: "application/json",
        cache: "no-cache",
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    };
}

function headersFile() {
    return {
        'Authorization': `Bearer ${getToken()}`
    };
}

function headersDelete(data) {
    return {
        method: "DELETE",
        accept: "application/json",
        cache: "no-cache",
        headers: {
            'Content-Type': "application/json",
            'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify(data)
    };
}

function fetchGet(url, success, error) {
    fetchJson(url, headersGet(), success, error);
}

function fetchPost(url, data, success, error) {
    fetchJson(url, headersPost(data), success, error);
}

function fetchDelete(url, data, success, error) {
    fetchJson(url, headersDelete(data), success, error);
}

function fetchJson(url, headers, success, error) {
    fetch(url, headers)
        .then(response => {
            if (response.ok)
                return response.json();

            if (response.status == 403 || response.status == 401) {
                clearAuth();
                throw "user not logged";
            } else {
                throw response.text();
            }
        })
        .then(success)
        .catch((ex) => {
            if (ex.then)
                ex.then(error);
            else
                error(ex);
        });
}

function fetchGetOneWay(url) {
    fetch(url, headersGet());
}

export {
    headersGet,
    headersFile,
    fetchGet,
    fetchGetOneWay,
    fetchPost,
    fetchDelete
    };