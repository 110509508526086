import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet, fetchDelete } from "../../../utils/requestsHelper.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const ProcessDetailRow = (props) => {
    const dataItem = props.dataItem;
    return <section>
        <p>
            <strong>{window.captions.ObjectDot}</strong> {dataItem.Object}
        </p>
        <p>
            <strong>{window.captions.ProcessDot}</strong> {dataItem.Type}
        </p>
        <p>
            <strong>{window.captions.StateDot}</strong> {dataItem.State}
        </p>
        <p>
            <strong>{window.captions.Executor}</strong> {dataItem.Author}
        </p>
    </section>;
}

const ProcessControl = forwardRef(function ProcessControl(props, ref) {
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    useEffect(() => refreshDataSource(), [props.id, props.source]);

    useImperativeHandle(ref, () => {
        return {
            refreshDataSource
        };
    })

    if (error) {
        return <ErrorMask error={error} />;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    const actionsCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="menu" onClick={() => props.processViewEdit(p.dataItem.Id)} />
        </td>;

    const gridColorCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <span className="dm-color-cell" style={{
                backgroundColor: p.dataItem.Color
            }} />
        </td>;

    const removeCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="delete" onClick={() => handleOnDelete(p.dataItem)} />
        </td>;

    let removeColumn = null;
    if (props.canDelete && props.isEditMode) {
        removeColumn = <GridColumn locked={true} field="id" title=" " width="35" cells={{ data: removeCell }} />;
    }

    return <div className="dm-full-wh">
        <Grid className="dm-full-wh"
            pageable={true}
            total={total}
            data={data}
            skip={dataState.skip}
            pageSize={dataState.take}
            filter={dataState.filter}
            sort={dataState.sort}
            onDataStateChange={handleDataStateChange}
            detail={ProcessDetailRow}
            expandField="expanded"
            onExpandChange={expandChange}>
            <GridColumn field="Color" title=" " width="35" cells={{ data: gridColorCell }} />
            <GridColumn field="Caption" title={window.captions.Caption} />
            {removeColumn}
            <GridColumn field="id" locked={true} title=" " width="35" cells={{ data: actionsCell }} />
        </Grid>
        {loadingPanel}
        <ConfirmMessage yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    </div>;

    function handleDataStateChange(changeEvent) {
        setDataState({ dataState: changeEvent.dataState });
        fetchData(props.id, props.source, changeEvent.dataState);
    }

    function fetchData(id, source, dataState) {
        const hasGroups = dataState.group && dataState.group.length;
        setPending(true);
        fetchGet(`${window.constants.cardSourceProcess}/${source}/${id}?${toDataSourceRequestString(dataState)}`,
            ({ data, total }) => {
                setData(hasGroups ? translateDataSourceResultGroups(data) : data);
                setError(null);
                setTotal(total);
                setDataState(dataState);
                setPending(false);
            },
            ex => setError(ex));
    }

    function expandChange(event) {
        let newData = data.map((item) => {
            if (item.Id === event.dataItem.Id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setData(newData);
    }

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem.Id);
        if (!isNaN(id)) {
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function refreshDataSource() {
        fetchData(props.id, props.source, dataState);
    }

    function deleteItem() {
        var id = deleteId;
        confirmClose();
        fetchDelete(`${window.constants.cardSourceProcess}/${props.source}/${props.id}`,
            id,
            () => refreshDataSource(),
            () => { });
    }

    function confirmClose() {
        setDeleteId(0);
        setConfirm(false);
    }
})

export default ProcessControl;