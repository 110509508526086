import React from "react";
import { useState, useEffect, useRef } from 'react';
import { formatDate, formatNumber } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import MessageMask from "../../components/messageMask.jsx";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { NumericTextBox, Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getUiCulture } from "../../utils/authHelper.jsx";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import { paymentPeriods, promiseState, parseDateTimeFormat, getFormatDate, dateWidth, numericWidth } from
    "../../utils/systemConstants.jsx";
import { addDays, isEqualDate } from "@progress/kendo-date-math";
import { dayOfYear } from "../../components/extensions/dateTimeFunctions.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogPromiseSchedulerAdder(props) {
    const focusedItemId = useRef(null);

    const paymentPeriodList = [
        { id: paymentPeriods.week, name: window.captions.Week },
        { id: paymentPeriods.month, name: window.captions.Month },
        { id: paymentPeriods.day, name: window.captions.Day }
    ];

    const validateResult = {
        generateAndContinue: 0,
        generateAndSave: 1,
        cancel: 2,
        success: 3
    };

    const [storage, setStorage] = useState({
        maximumPromiseDate: new Date(),
        delayReasons: [],
        lastPromiseDate: null,
        specialTypesData: [],
        maximumPeriodSchedulerPromise: null,
        maximumPeriodBetweenPromise: null,
    });

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);

    const [blackoutDays, setBlackoutDays] = useState([]);
    const [minimumPromiseDate, setMinimumPromiseDate] = useState(new Date());
    const [minimumAmountPromise, setMinimumAmountPromise] = useState(0);

    const [totalAmountDebt, setTotalAmountDebt] = useState(0);

    const [delayReason, setDelayReason] = useState(props.delayReason);
    const [periodAmount, setPeriodAmount] = useState(1);
    const [selectedTypePeriod, setSelectedTypePeriod] = useState(paymentPeriodList[0]);
    const [selectedCountPeriod, setSelectedCountPeriod] = useState(1);

    const [firstTimePromise, setFirstTimePromise] = useState(new Date());
    const [firstAmountElement, setFirstAmountElement] = useState(0);
    const [promiseSource, setPromiseSource] = useState([]);
    const [selectedPromiseSource, setSelectedPromiseSource] = useState(null);

    const [selectedSpecialTypeData, setSelectedSpecialTypeData] = useState(null);
    const [comment, setComment] = useState(null);
    const [isDiscountEnable, setIsDiscountEnable] = useState(false); //Why?
    const [oldFirstElement, setOldFirstElement] = useState(0);

    const [promisePaymentScheduler, setPromisePaymentScheduler] = useState(null);
    const [paymentSchedulerList, setPaymentSchedulerList] = useState([]);

    const [schedulerAdded, setSchedulerAdded] = useState(false);

    useEffect(() => fetchData(props.cardId, props.projectId), []);
    useEffect(() => {
        if (focusedItemId.current) {
            const item = document.getElementById(focusedItemId.current);
            if (item)
                item.focus();
        }
        focusedItemId.current = null;
    }, [props]);

    const title = window.captions.PromiseScheduler;
    if (pending || error) {
        return <Dialog title={title} onClose={props.onClose} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const elements = [];
    renderPair(elements,
        window.captions.PromiseAmount,
        "promiseAmount",
        () => <div className="dm-wrapper">
            <NumericTextBox onChange={changePromiseAmount}
                min={minimumAmountPromise}
                max={props.amountDebtInitial}
                width={numericWidth}
                value={totalAmountDebt} />
            <DropDownList textField="name"
                className="dm-wrapper-content"
                dataItemKey="id"
                data={storage.delayReasons}
                value={delayReason}
                onChange={changeDelayReason} />
        </div>);

    const maxPeriods = maximumPeriods();
    renderPair(elements,
        window.captions.NumbersPaymentPeriod,
        "numbersPaymentPeriod",
        () => <div>
            <div className="dm-wrapper">
                <NumericTextBox onChange={changePeriodAmount}
                    min={1}
                    max={maxPeriods > 0 ? maxPeriods : 1}
                    width={numericWidth}
                    value={periodAmount} />
                <DropDownList textField="name"
                    className="dm-wrapper-content"
                    dataItemKey="id"
                    data={paymentPeriodList}
                    value={selectedTypePeriod}
                    onChange={changePaymentPeriod} />
            </div>
            <NumericTextBox style={{ marginTop: 8 }}
                onChange={changeCountPeriod}
                min={1}
                max={30}
                width={numericWidth}
                value={selectedCountPeriod} />
        </div>);

    renderPair(elements,
        window.captions.PromissPaymentDate,
        "promiseDate",
        () => <DatePicker width={dateWidth}
            formatPlaceholder={getFormatDate()}
            onChange={changePromiseDate}
            min={minimumPromiseDate}
            max={storage.maximumPromiseDate}
            value={firstTimePromise} />);

    renderPair(elements,
        window.captions.MainTabAdministrationDictPromiseSource,
        "promiseSource",
        () => <DropDownList style={{ width: "100%" }}
            textField="name"
            dataItemKey="id"
            data={promiseSource}
            value={selectedPromiseSource}
            onChange={changePromiseSource} />);

    renderPair(elements,
        window.captions.PhoneNumber,
        "phoneNumber",
        () => <DropDownList style={{ width: "100%" }}
            textField="name"
            dataItemKey="id"
            data={storage.specialTypesData}
            value={selectedSpecialTypeData}
            onChange={changeSpecialTypeData} />);

    renderPair(elements,
        window.captions.Comment,
        "comment",
        () => <MultilineTextArea changeText={changeComment} text={comment} />);

    let schedulerGrid = "";
    let saveButton = "";
    let width = 700;
    if (schedulerAdded) {
        saveButton = <DialogActionsBar>
            <Button themeColor="primary" onClick={savePromiseScheduler}>{window.captions.Save}</Button>
        </DialogActionsBar>;
        width = 900;
        const promiseDate =
            (props) =>
                <td style={props.style} className={props.className} {...props.tdProps}>
                    <DatePicker onChange={(e) => changeSchedulerPromiseDate(e, props.dataItem)}
                        min={minimumPromiseDate}
                        max={storage.maximumPromiseDate}
                        width={dateWidth}
                        formatPlaceholder={getFormatDate()}
                        value={props.dataItem["promiseDateTime"]} />
                </td>;

        const promiseAmountCell =
            (p) => <td style={p.style} className={p.className} {...p.tdProps}>
                {(p.dataItem["index"] === 1 || !window.systemConfig.editFirstSumPromise
                    ? <NumericTextBox id={`promiseAmount${p.dataItem["index"]}`}
                        onChange={(e) => changeSchedulerPromiseAmount(e,
                            p.dataItem)}
                        min={0}
                        max={props.amountDebtInitial}
                        width={numericWidth}
                        value={p.dataItem["promiseAmount"]} />
                    : formatNumber(p.dataItem["promiseAmount"], "n2", getUiCulture()))}
            </td>;

        const commentCell =
            (p) => <td style={p.style} className={p.className} {...p.tdProps}>
                <Input id={`comment${p.dataItem["index"]}`}
                    property="comment"
                    className="dm-size-100"
                    onChange={(e) => changeSchedulerComment(e, p.dataItem)}
                    defaultValue={p.dataItem["comment"]} />
            </td>;

        const removeCell =
            (p) =>
                <td {...getLinkCellStyle(p)}>
                    <Button icon="delete" onClick={() => handleDeleteItemScheduler(p.dataItem)} />
                </td>;

        schedulerGrid =
            <Grid
                className="dm-full-wh"
                total={paymentSchedulerList.length}
                data={paymentSchedulerList}>
                <GridToolbar>
                    <Button onClick={handleAddSchedulerItem} disabled={!canAddPaymentListItem()}>
                        {window.captions.Add}
                    </Button>
                    <Button onClick={handleDeleteAllScheduler}>{window.captions.DeleteAll}</Button>
                </GridToolbar>
                <GridColumn field="index" title="#" width="50" editable={false} />
                <GridColumn field="promiseDateTime" title={window.captions.WordDate}
                    width="164" cells={{ data: promiseDate }} />
                <GridColumn field="promiseAmount" title={window.captions.PromiseAmount}
                    width="205" cells={{ data: promiseAmountCell }} />
                <GridColumn field="comment" title={window.captions.Comment}
                    cells={{ data: commentCell }} />
                <GridColumn field="id" title="" width="35" cells={{ data: removeCell }} />
            </Grid>;
    }

    return <Dialog title={title} onClose={props.onClose} width={width}>
        <div>
            <div className="dm-container dm-no-padding">
                {elements}
            </div>
            <Button iconClass="dm-i dm-i-plus" onClick={handleAddScheduler}>
                {window.captions.CreateScheduler}
            </Button>
            {schedulerGrid}
        </div>
        {saveButton}
    </Dialog>;

    function fetchData(cardId, projectId) {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getPromiseSchedulerModel}/${cardId}/${projectId}`,
            (data) => {
                const specialTypesData = [{ id: 0, name: window.captions.PhoneNotChosed }, ...data.specialTypesData];
                const maxPromiseDate = addDays(new Date(), data.maximumPeriodSchedulerPromise);

                setStorage({
                    maximumPromiseDate: maxPromiseDate,
                    delayReasons: data.delayReasons,
                    lastPromiseDate: new Date(data.lastPromiseDate),
                    specialTypesData: specialTypesData,
                    maximumPeriodSchedulerPromise: data.maximumPeriodSchedulerPromise,
                    maximumPeriodBetweenPromise: data.maximumPeriodBetweenPromise,
                });

                setTotalAmountDebt(calculateTotalAmountDebt(0, props.amountDebtInitial));
                setMinimumAmountPromise(data.minimumAmountPromise);
                setDelayReason(props.delayReason ?? data.delayReasonDefault);
                setPromiseSource(data.promiseSource);
                setSelectedPromiseSource(data.promiseSourceDefault);
                setSelectedSpecialTypeData(specialTypesData[0]);
                setPending(null);

                getBlackoutDays(maxPromiseDate);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }

    function getBlackoutDays(date) {
        const requestDate = formatDate(new Date(date), parseDateTimeFormat);
        fetchGet(`${window.constants.configWeekendsGetBlackoutDays}/${requestDate}`,
            (data) => {
                const blcDays = data.map(i => new Date(i));
                const minPromiseDate = window.systemConfig.limitationPromiseDate && storage.lastPromiseDate
                    ? shiftToClosestAvailableDate(new Date(storage.lastPromiseDate), blcDays)
                    : shiftToClosestAvailableDate(new Date(), blcDays);
                setBlackoutDays(blcDays);
                setMinimumPromiseDate(minPromiseDate);
                setFirstTimePromise(minPromiseDate > firstTimePromise
                    ? minPromiseDate
                    : firstTimePromise);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function canAddPaymentListItem() {
        return paymentSchedulerList && maximumPeriods() > paymentSchedulerList.length;
    }

    function calculateTotalAmountDebt(oldValue, newValue) {
        const condition = newValue < minimumAmountPromise || newValue > props.amountDebtInitial;
        let totalAmountDebt = oldValue;
        if (!window.systemConfig.editFirstSumPromise && condition) {
            return totalAmountDebt;
        }

        if (window.systemConfig.editFirstSumPromise && condition)
            newValue = props.amountDebtInitial;

        totalAmountDebt = newValue;
        if (promisePaymentScheduler) {
            promisePaymentScheduler.totalAmountPromise = newValue;
            setPromisePaymentScheduler(promisePaymentScheduler);
        }

        return totalAmountDebt;
    }

    function maximumPeriods() {
        if (isNaN(props.amountDebtInitial) || isNaN(minimumAmountPromise))
            return 0;
        const isFractional = props.amountDebtInitial % minimumAmountPromise > 0;
        let additionalPeriod = 0;
        if (isFractional)
            additionalPeriod = 1;
        return Math.min(3000,
            Math.floor(props.amountDebtInitial / minimumAmountPromise) + additionalPeriod);
    }

    function maximumPromiseAmount(paymentSchedulerList) {
        if (!paymentSchedulerList || paymentSchedulerList.length === 0)
            return props.amountDebtInitial;
        return props.amountDebtInitial - paymentSchedulerList.map(i => i.promiseAmount).reduce((a, c) => a + c);
    }

    function shiftToClosestAvailableDate(date, blackoutDays) {
        if (!date)
            return new Date();
        if (!blackoutDays || blackoutDays.length <= 0)
            return date;
        let dt = new Date(date);
        while (blackoutDays.some(i => isEqualDate(i, dt))) {
            dt = addDays(dt, 1);
        }
        return dt;
    }

    function discountDebt() {
        if (!isDiscountEnable)
            return 0;

        const discount = props.amountDebtInitial - totalAmountDebt;
        if (promisePaymentScheduler)
            promisePaymentScheduler.discountAmount = discount;
        return discount;
    }

    function phoneId() {
        let phoneId = 0;
        if (storage.specialTypesData &&
            storage.specialTypesData.length >
            storage.specialTypesData.findIndex(i => i.id === selectedSpecialTypeData.id)) {
            phoneId = selectedSpecialTypeData.id;
        }

        if (promisePaymentScheduler)
            promisePaymentScheduler.phoneId = phoneId;
        return phoneId;
    }

    function generateScheduler(promisePaymentScheduler, paymentSchedulerList, firstAmountElement, oldFirstElement,
        totalAmountDebt, periodAmount) {
        var hasOld = paymentSchedulerList && paymentSchedulerList.length === periodAmount;

        if (promisePaymentScheduler.periodAmount === 0)
            promisePaymentScheduler.periodAmount = 1;
        if (promisePaymentScheduler.countSourcePeriod === 0)
            promisePaymentScheduler.countSourcePeriod = 1;

        var editFirstSumPromise = window.systemConfig.editFirstSumPromise;
        let paymentPerPeriod;
        if (editFirstSumPromise && firstAmountElement !== 0 && periodAmount > 1 ||
            !editFirstSumPromise && periodAmount === 1 && firstAmountElement) {
            paymentPerPeriod = Math.round((totalAmountDebt - firstAmountElement) /
                (promisePaymentScheduler.periodAmount - 1) *
                100) /
                100;
        } else {
            paymentPerPeriod =
                Math.round(promisePaymentScheduler.totalAmountPromise / promisePaymentScheduler.periodAmount * 100) /
                100;
        }

        const resultSchedulerItem = {
            promiseAmount: editFirstSumPromise && firstAmountElement !== 0 ||
                !editFirstSumPromise && firstAmountElement !== 0 && periodAmount === 1
                ? firstAmountElement
                : paymentPerPeriod,
            promiseDateTime: hasOld ? paymentSchedulerList[0].promiseDateTime : new Date(firstTimePromise),
            minPeriodZome: 1,
            promiseState: promiseState.pending,
            amountOnePeriodZone: totalAmountDebt
        };

        const resultScheduler = [resultSchedulerItem];
        if (editFirstSumPromise)
            firstAmountElement = resultSchedulerItem.promiseAmount;

        oldFirstElement = firstAmountElement;
        if (periodAmount < 2) {
            var singleScheduler = setListItemIndex([resultSchedulerItem]);
            setPromisePaymentScheduler(promisePaymentScheduler);
            setPaymentSchedulerList(singleScheduler);
            setOldFirstElement(oldFirstElement);
            setFirstAmountElement(firstAmountElement);
            setSchedulerAdded(true);
            return;
        }

        let countSourcePeriod = promisePaymentScheduler.countSourcePeriod;
        let timePromiseShift = new Date(promisePaymentScheduler.firstTimePromise);
        let availableDate = timePromiseShift;
        let timeZoneShift = addDays(new Date(promisePaymentScheduler.FirstTimePromise), 31);
        let currentYear = resultSchedulerItem.promiseDateTime.getFullYear();
        let currentMonth = resultSchedulerItem.promiseDateTime.getMonth() + 1;
        let currentZone = 1;

        for (let i = 1; i <= periodAmount - 1; i++) {
            let currentDay = new Date(firstTimePromise).getDate();
            currentMonth = currentMonth + countSourcePeriod;
            if (currentMonth > 12) {
                currentMonth = 1;
                currentYear = currentYear + 1;
            }

            let daysInMonth = new Date(currentYear, currentMonth - 1, 0).getDate();
            if (daysInMonth < currentDay)
                currentDay = daysInMonth;

            switch (promisePaymentScheduler.paymentPeriod) {
                case paymentPeriods.month:
                    timePromiseShift = new Date(currentYear, currentMonth - 1, currentDay);
                    break;
                case paymentPeriods.week:
                    timePromiseShift = addDays(timePromiseShift, 7 * countSourcePeriod);
                    break;
                case paymentPeriods.day:
                    timePromiseShift = addDays(timePromiseShift, countSourcePeriod);
                    break;
            }

            availableDate = timePromiseShift <= availableDate
                ? addDays(availableDate, 1)
                : timePromiseShift;
            if (hasOld)
                availableDate = paymentSchedulerList[resultScheduler.length].promiseDateTime;

            if (timeZoneShift < timePromiseShift) {
                currentZone = currentZone + 1;
                timeZoneShift = addDays(timeZoneShift, 31);
            }

            availableDate = shiftToClosestAvailableDate(availableDate, blackoutDays);
            resultScheduler.push({
                promiseAmount: paymentPerPeriod,
                promiseDateTime: availableDate,
                minPeriodZome: currentZone,
                promiseState: promiseState.pending
            });
        }

        for (var zone = 1; zone <= currentZone; zone++) {
            const zoneCloser = zone;
            resultScheduler.filter(date => date.minPeriodZome === zoneCloser)
                .forEach(i => i.amountOnePeriodZone = resultScheduler.filter(date => date.minPeriodZome === zoneCloser)
                    .map(i => i.promiseAmount)
                    .reduce((a, c) => a + c));
        }

        resultScheduler[resultScheduler.length - 1].promiseAmount =
            resultScheduler[resultScheduler.length - 1].promiseAmount +
            totalAmountDebt -
            resultScheduler.map(i => i.promiseAmount)
                .reduce((a, c) => a + c);

        var multiScheduler = setListItemIndex(resultScheduler);
        setPromisePaymentScheduler(promisePaymentScheduler);
        setPaymentSchedulerList(multiScheduler);
        setOldFirstElement(oldFirstElement);
        setFirstAmountElement(firstAmountElement);
        setSchedulerAdded(true);
        setPeriodAmount(periodAmount);
    }

    function changeAmountPeriodsManual(paymentSchedulerList) {
        const promisePaymentScheduler = promisePaymentScheduler;
        const periodAmount = paymentSchedulerList.length;
        let promiseAmount = Math.round(totalAmountDebt / periodAmount * 100) / 100;
        if (isNaN(promiseAmount))
            promiseAmount = 0;

        for (let promiseDateModel of paymentSchedulerList) {
            if (promiseAmount >= 1000) {
                promiseDateModel.promiseAmount = promiseAmount;
            } else {
                const lastItem = paymentSchedulerList[paymentSchedulerList.length - 1];
                if (!lastItem)
                    continue;
                lastItem.promiseAmount = totalAmountDebt -
                    Math.round(paymentSchedulerList.map(i => i.promiseAmount)
                        .reduce((a, c) => a + c) *
                        100) /
                    100;
                if (lastItem.promiseAmount < 0)
                    lastItem.promiseAmount = 0.0000;
            }
        }

        const lastItemLeft = paymentSchedulerList[paymentSchedulerList.length - 1];
        if (lastItemLeft) {
            lastItemLeft.promiseAmount = lastItemLeft.promiseAmount + totalAmountDebt -
                paymentSchedulerList.map(i => i.promiseAmount)
                    .reduce((a, c) => a + c);
        }

        if (promisePaymentScheduler)
            promisePaymentScheduler.periodAmount = periodAmount;
        paymentSchedulerList = setListItemIndex(paymentSchedulerList);

        return {
            promisePaymentScheduler: promisePaymentScheduler,
            paymentSchedulerList: paymentSchedulerList,
            periodAmount: periodAmount,
            totalAmountDebt: isNaN(totalAmountDebt) ? 0 : totalAmountDebt
        };
    }

    function setChangeAmountPeriodsManualResult(item) {
        setPromisePaymentScheduler(item.promisePaymentScheduler);
        setPaymentSchedulerList(item.paymentSchedulerList);
        setPeriodAmount(item.periodAmount);
        setTotalAmountDebt(isNaN(item.totalAmountDebt) ? 0 : item.totalAmountDebt);
    }

    function recalculateSchedulerList(paymentSchedulerList, promisePaymentScheduler, periodAmount, totalAmountDebt, value =
        null) {
        if (!paymentSchedulerList || paymentSchedulerList.length <= 0)
            return;

        const editFirstSumPromise = window.systemConfig.editFirstSumPromise;
        let firstDate = Math.min(paymentSchedulerList.map(i => i.promiseDateTime));
        let currentMinZone = 1;

        for (let promiseDate of paymentSchedulerList) {
            const timeDifference = Math.abs(promiseDate.promiseDateTime - firstDate);
            const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            if (dayDifference > 31) {
                currentMinZone += 1;
                firstDate = addDays(firstDate, 31);
            }

            promiseDate.minPeriodZome = currentMinZone;
        }

        for (let zone = 1; zone <= currentMinZone; zone++) {
            const zoneCloser = zone;
            const filteredList = paymentSchedulerList.filter(i => i.minPeriodZome === zoneCloser);
            if (!filteredList || filteredList.length <= 0)
                continue;

            for (let resScheduler of filteredList) {
                resScheduler.amountOnePeriodZone = filteredList.map(i => i.promiseAmount)
                    .reduce((a, c) => a + c);
            }
        }

        const oldFirstElement = firstAmountElement;
        let newFirstAmountElement = firstAmountElement;
        if (editFirstSumPromise || periodAmount === 1) {
            newFirstAmountElement = paymentSchedulerList[0].promiseAmount;
        }

        if (newFirstAmountElement === 0 &&
            periodAmount >= 1 &&
            maximumPromiseAmount(paymentSchedulerList) >= 0 &&
            !editFirstSumPromise ||
            periodAmount === 1) {
            totalAmountDebt = paymentSchedulerList.map(i => i.promiseAmount)
                .reduce((a, c) => a + c);

            paymentSchedulerList = setListItemIndex(paymentSchedulerList);
            setPromisePaymentScheduler(promisePaymentScheduler);
            setPaymentSchedulerList(paymentSchedulerList);
            setOldFirstElement(oldFirstElement);
            setFirstAmountElement(newFirstAmountElement);
            setTotalAmountDebt(totalAmountDebt);
            setPeriodAmount(periodAmount);
        } else if (editFirstSumPromise && oldFirstElement !== newFirstAmountElement) {
            paymentSchedulerList = setListItemIndex(paymentSchedulerList);
            generateScheduler(promisePaymentScheduler,
                paymentSchedulerList,
                newFirstAmountElement,
                oldFirstElement,
                totalAmountDebt,
                periodAmount);
        } else {
            paymentSchedulerList = setListItemIndex(paymentSchedulerList);
            generateScheduler(promisePaymentScheduler,
                paymentSchedulerList,
                newFirstAmountElement,
                oldFirstElement,
                totalAmountDebt,
                periodAmount);
        }
    }

    function setListItemIndex(list) {
        for (let index in list) {
            const item = list[index];
            item.index = new Number(index) + 1;
        }
        return list;
    }

    function changePromiseAmount(e) {
        const value = isNaN(e.value) ? 0 : e.value;
        setTotalAmountDebt(calculateTotalAmountDebt(totalAmountDebt, value));
    }

    function changeDelayReason(e) {
        setDelayReason(e.target.value);
    }

    function changePeriodAmount(e) {
        setPeriodAmount(e.value);
    }

    function changePaymentPeriod(e) {
        setSelectedTypePeriod(e.target.value);
    }

    function changeCountPeriod(e) {
        setSelectedCountPeriod(e.value);
    }

    function changePromiseDate(e) {
        let value = e.value;
        if (blackoutDays.some(i => isEqualDate(i, value))) {
            value = firstTimePromise ? firstTimePromise : new Date();
            alert(window.captions.UnableBuildSchedule);
        }
        if (promisePaymentScheduler) {
            promisePaymentScheduler.firstTimePromise = value;
        }
        setFirstTimePromise(value);
        setPromisePaymentScheduler(promisePaymentScheduler);
    }

    function changePromiseSource(e) {
        setSelectedPromiseSource(e.target.value);
    }

    function changeSpecialTypeData(e) {
        setSelectedSpecialTypeData(e.target.value);
    }

    function changeComment(e) {
        setComment(e.value);
    }

    function handleAddScheduler() {
        const firstAmountElement = 0;
        const promisePaymentScheduler = {
            id: 0,
            pid: props.projectId,
            loanId: props.cardId,
            created: new Date(),
            isDiscount: false,
            firstTimePromise: firstTimePromise,
            totalAmountPromise: totalAmountDebt,
            periodAmount: periodAmount,
            countSourcePeriod: selectedCountPeriod,
            phoneId: phoneId(),
            paymentPeriod: selectedTypePeriod.id,
            promiseState: promiseState.pending,
            paymentScheduler: [],
            discountAmount: discountDebt(),
            promiseSource: selectedPromiseSource.id,
            isImport: false,
            comment: comment,
            delayReason: delayReason.name
        };
        generateScheduler(promisePaymentScheduler,
            paymentSchedulerList,
            firstAmountElement,
            oldFirstElement,
            totalAmountDebt,
            periodAmount);
    }

    function handleAddSchedulerItem(e) {
        const last = paymentSchedulerList.length > 0 ? paymentSchedulerList[paymentSchedulerList.length - 1] : null;
        var dateToAdd = new Date();
        if (last) {
            dateToAdd = addDays(last.promiseDateTime,
                selectedTypePeriod.id === paymentPeriods.week
                    ? 7
                    : 31);
        }

        dateToAdd = shiftToClosestAvailableDate(dateToAdd, blackoutDays);
        paymentSchedulerList.push({
            promiseAmount: 0,
            promiseDateTime: dateToAdd,
            minPeriodZome: 0,
            promiseState: promiseState.pending
        });
        setChangeAmountPeriodsManualResult(changeAmountPeriodsManual(paymentSchedulerList));
    }

    function handleDeleteAllScheduler(e) {
        const paymentSchedulerList = [];
        setChangeAmountPeriodsManualResult(changeAmountPeriodsManual(paymentSchedulerList));
    }

    function handleDeleteItemScheduler(props) {
        const fitered = paymentSchedulerList.filter(i => i.index !== props.index);
        var result = changeAmountPeriodsManual(fitered);
        recalculateSchedulerList(result.paymentSchedulerList,
            result.promisePaymentScheduler,
            result.periodAmount,
            result.totalAmountDebt);
    }

    function changeSchedulerPromiseDate(e, props) {
        let value = e.value;
        if (blackoutDays.some(i => isEqualDate(i, value))) {
            value = firstTimePromise ? firstTimePromise : new Date();
            alert(window.captions.UnableBuildSchedule);
            return;
        }
        if (paymentSchedulerList.filter(i => dayOfYear(i.promiseDateTime) === dayOfYear(value)).length > 1) {
            alert(window.captions.ThisDateAlreadyInUse);
            return;
        }
        paymentSchedulerList[props.index - 1].promiseDateTime = !value ? new Date() : value;
        recalculateSchedulerList(paymentSchedulerList,
            promisePaymentScheduler,
            periodAmount,
            totalAmountDebt);
    }

    function changeSchedulerPromiseAmount(e, props) {
        let value = e.value;
        paymentSchedulerList[props.index - 1].promiseAmount = isNaN(value) ? 0 : value;
        const maxPromiseAmount = maximumPromiseAmount(paymentSchedulerList);
        if (maxPromiseAmount < 0 && !window.systemConfig.editFirstSumPromise) {
            value += maxPromiseAmount;
        }
        paymentSchedulerList[props.index - 1].promiseAmount = isNaN(value) ? 0 : value;
        recalculateSchedulerList(paymentSchedulerList,
            promisePaymentScheduler,
            periodAmount,
            totalAmountDebt,
            paymentSchedulerList[props.index - 1].promiseAmount);
        focusedItemId.current = `promiseAmount${props.index}`;
    }

    function changeSchedulerComment(e, props) {
        paymentSchedulerList[props.index - 1].comment = e.target.value;
        setPaymentSchedulerList(paymentSchedulerList);
        focusedItemId.current = `comment${props.index}`;
    }

    function isValidRules() {
        if (phoneId() < 1) {
            alert(window.captions.PhoneNotSelected);
            return validateResult.cancel;
        }

        if (!paymentSchedulerList || paymentSchedulerList.length === 0) {
            alert(window.captions.EmptyPromise);
            return validateResult.cancel;
        }

        const gropedSelect = [];
        paymentSchedulerList.map(i => {
            if (gropedSelect.every(g => !isEqualDate(g, i.promiseDateTime))) {
                gropedSelect.push(new Date(i.promiseDateTime.getFullYear(),
                    i.promiseDateTime.getMonth(),
                    i.promiseDateTime.getDate()));
            }
        });

        if (gropedSelect.length !== paymentSchedulerList.length) {
            alert(window.captions.DuplicateInDate);
            return validateResult.cancel;
        }

        if (totalAmountDebt < minimumAmountPromise &&
            props.amountDebtInitial > minimumAmountPromise) {
            alert(window.captions.AmountLessAllowed);
            return validateResult.cancel;
        }

        let errorInAmount;
        let errorInPeriod = false;
        let errorMaxPeriod = false;

        let lastPromise = paymentSchedulerList.length > 0
            ? paymentSchedulerList[paymentSchedulerList.length - 1]
            : null;
        if (Math.abs(Math.round(totalAmountDebt * 100) / 100 -
            Math.round(props.amountDebtInitial * 100) / 100) >
            0.00) {
            lastPromise = null;
        }

        if (paymentSchedulerList.length === 1) {
            errorInAmount = paymentSchedulerList[0].promiseAmount < minimumAmountPromise &&
                props.amountDebtInitial > minimumAmountPromise;
        } else {
            errorInAmount = paymentSchedulerList.some(
                promiseDate => promiseDate.promiseAmount < minimumAmountPromise &&
                    promiseDate !== lastPromise) ||
                paymentSchedulerList.some(m => Math.abs(m.promiseAmount) < 0.00 ||
                    m === lastPromise && lastPromise.promiseAmount <= 0);
        }

        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);
        if (paymentSchedulerList.some(i => i.promiseDateTime > new Date(storage.maximumPromiseDate) ||
            i.promiseDateTime < dateNow)) {
            alert(window.captions.DateOutOfRange);
            return validateResult.cancel;
        }

        let previousDate = paymentSchedulerList[0].promiseDateTime;
        if (paymentSchedulerList.length > 1) {
            for (var promiseDate of paymentSchedulerList) {
                const dtProm = new Date(promiseDate.promiseDateTime.getFullYear(),
                    promiseDate.promiseDateTime.getMonth(),
                    promiseDate.promiseDateTime.getDate());
                let timeDifference = Math.abs(dtProm -
                    new Date(previousDate.getFullYear(), previousDate.getMonth(), previousDate.getDate()));
                let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                if (dayDifference > storage.maximumPeriodBetweenPromise) {
                    errorInPeriod = true;
                    break;
                }

                timeDifference =
                    Math.abs(dtProm - new Date());
                dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                if (dayDifference > storage.maximumPeriodSchedulerPromise) {
                    errorMaxPeriod = true;
                    break;
                }

                previousDate = promiseDate.promiseDateTime;
            }
        } else {
            var promiseSingleDate = new Date(paymentSchedulerList[0].promiseDateTime);
            let timeDifference = Math.abs(promiseSingleDate - new Date());
            let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            if (dayDifference > storage.maximumPeriodSchedulerPromise)
                errorMaxPeriod = true;
        }

        if (errorInAmount) {
            alert(`${window.captions.PaymentMinAmountError}${minimumAmountPromise}`);
            return validateResult.cancel;
        }

        if (errorInPeriod) {
            alert(`${window.captions.PaymentMaxPeriodBetweenError} ${storage.maximumPeriodBetweenPromise} ${window
                .captions.Day}`);
            return validateResult.cancel;
        }

        if (errorMaxPeriod) {
            alert(`${window.captions.PaymentMaxPeriodError} ${storage.maximumPeriodSchedulerPromise} ${window.captions.Day
                }`);
            return validateResult.cancel;
        }

        return validateResult.success;
    }

    function savePromiseScheduler() {
        switch (isValidRules()) {
            case validateResult.success:
                save();
                break;
            case validateResult.generateAndSave:
                generateScheduler(promisePaymentScheduler,
                    paymentSchedulerList,
                    firstAmountElement,
                    oldFirstElement,
                    totalAmountDebt,
                    periodAmount);
                savePromiseScheduler();
                break;
            case validateResult.generateAndContinue:
                generateScheduler(promisePaymentScheduler,
                    paymentSchedulerList,
                    firstAmountElement,
                    oldFirstElement,
                    totalAmountDebt,
                    periodAmount);
                break;
            case validateResult.cancel:
                return;
        }
    }

    function save() {
        const promiseDateList = paymentSchedulerList.map(i => {
            return {
                id: 0,
                promiseAmount: i.promiseAmount,
                promiseDateTime: new Date(i.promiseDateTime),
                promisePaymentId: promisePaymentScheduler.id,
                promiseState: i.promiseState,
                totalAccepted: 0,
                comment: i.comment ? i.comment : null
            };
        });
        promisePaymentScheduler.paymentScheduler.push(...promiseDateList);

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.savePromiseScheduler}`,
            promisePaymentScheduler,
            (id) => { props.onClose(id); },
            () => { props.onClose(0); });
    }
}