import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { formatDate } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom";
import ErrorMask from "../../components/errorMask.jsx";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { fetchGet, fetchDelete, fetchPost } from "../../utils/requestsHelper.jsx";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import LoadingMask from "../../components/loadingMask.jsx";
import gridFunctions from "../../components/gridFunctions.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import GridLoadingPanel from "../../components/gridLoadingPanel.jsx";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import GridCollateralCardCell from "../../components/cells/gridCollateralCardCell.jsx";
import GridEmptyCell from "../../components/cells/gridEmptyCell.jsx";
import DialogCollateralStateReserve from "../dialogs/dialogCollateralStateReserve.jsx";
import { addDays, addMonths, addYears, firstDayOfMonth, lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
import { entitySourcesNames, timeIntervalTypes } from "../../utils/systemConstants.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";

const DATA_ITEM_KEY = "CLID";
const DATA_ITEM_STATE = "SID";
const SELECTED_FIELD = "selected";
const maxDisplayColumns = 2;
const idGetter = getter(DATA_ITEM_KEY);

export default function SearchCollateralReserves() {
    const params = useParams();
    const [state, setState] = useState();

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.SearchResults}`;
    }, []);
    useEffect(() => fetchColumns(), [params.id, params.entity]);

    if (state.entity !== entitySourcesNames.collateral) {
        return <ErrorMask error="Select only collateral entity" />;
    }

    if (state.error) {
        return <ErrorMask error={state.error} />;
    }

    if (!state.isLoaded) {
        return <LoadingMask />;
    }

    let loadingPanel = null;
    if (state.pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    const dialogContent = state.showReserveDialog
        ? <DialogCollateralStateReserve reserves={getReservesModel()}
            stateId={state.stateId}
            config={state.config}
            multiReserve={true}
            onClose={dialogReserveOnClose} />
        : null;

    var culture = getUiCulture();
    const reserveCell = (p) => {
        var td = {
            style: p.style,
            colSpan: p.colSpan,
            role: "gridcell",
            tabIndex: -1,
            "aria-colindex": p.ariaColumnIndex,
            "aria-selected": p.isSelected,
            "data-grid-col-index": p.columnIndex,
            "data-keyboardnavlevel": p.level,
            "data-keyboardnavid": p.id,
            ...p.tdProps
        };

        if (p.className)
            td.className = p.className;

        if (p.isSelected)
            td.className = td.className ? td.className + " k-state-selected" : "k-state-selected";

        if (!state.reserves || state.reserves.length === 0)
            return <td {...td} />;

        const id = p.dataItem[DATA_ITEM_KEY];
        const reserve = state.reserves.find(i => i.collateralId === id);
        if (!reserve || !reserve.items)
            return <td {...td} />;

        const item = reserve.items.find(i => i.column === p.field);
        if (!item)
            return <td {...td} />;

        if (item.value === -1)
            return <td {...td} />;

        if (item.value !== 0)
            return <td {...td}>
                {item.isSchedule ? `${item.value}%` : item.value}
            </td>;

        var fieldId = parseInt(p.field.substring(4));
        const itemNext = reserve.items.find(i => i.column === "cell" + (fieldId + 1));
        const itemPrev = reserve.items.find(i => i.column === "cell" + (fieldId - 1));
        var hasNext = itemNext && itemNext.value === 0;
        var hasPrev = itemPrev && itemPrev.value === 0;
        if (hasNext && hasPrev) {
            td.className = td.className ? td.className + " dm-cell-center" : "dm-cell-center";
        } else if (hasNext) {
            td.className = td.className ? td.className + " dm-cell-left" : "dm-cell-left";
        } else if (hasPrev) {
            td.className = td.className ? td.className + " dm-cell-right" : "dm-cell-right";
        } else {
            td.className = td.className ? td.className + " dm-cell-full" : "dm-cell-full";
        }

        var state = state.stateColors.find((i) => i.id === item.stateId);
        var colorProps = {
            style: { backgroundColor: state ? state.name : "#ffffff" },
            className: "dm-color-cell"
        };

        if (item.name)
            colorProps.title = item.name;

        return <td {...td}>
            <span {...colorProps} />
        </td>;
    };

    const views = [
        { value: timeIntervalTypes.Day, label: window.captions.Day },
        { value: timeIntervalTypes.Month, label: window.captions.Month },
        { value: timeIntervalTypes.Year, label: window.captions.Year }
    ];

    const columns = gridFunctions.renderGridColumns(state.columns);
    var dateFormat = "";
    var prevDate = null;
    var nextDate = null;
    if (state.currentView === timeIntervalTypes.Month) {
        const defaultDate = new Date(state.defaultDate);
        let startDate = firstDayOfMonth(defaultDate);
        const endDate = lastDayOfMonth(defaultDate);
        var formatter = new window.Intl.DateTimeFormat(culture, { weekday: "short" });
        let i = 0;
        while (startDate <= endDate) {
            const fDate = formatDate(startDate, "dd") + " " + formatter.format(startDate);
            const day = startDate.getDay();
            const isWeekend = day === 0 | day === 6;
            const field = `cell${startDate.getDate()}`;
            const cellStyle = {};
            if (isWeekend)
                cellStyle.background = "#ff00001";

            columns.push(<GridColumn field={field} cells={{ data: reserveCell }} style={cellStyle}
                title={fDate} width={60} key={i} />);
            startDate = addDays(startDate, 1);
            ++i;
        }

        dateFormat = "MMMM";
        prevDate = addMonths(state.defaultDate, -1);
        nextDate = addMonths(state.defaultDate, 1);
    } else if (state.currentView === timeIntervalTypes.Year) {
        yearItems(culture).map((item, i) => {
            columns.push(<GridColumn field={item.column} cells={{ data: reserveCell }}
                title={item.name} width={110} key={i} />);
        });

        dateFormat = "yyyy";
        prevDate = addYears(state.defaultDate, -1);
        nextDate = addYears(state.defaultDate, 1);
    } else if (state.currentView === timeIntervalTypes.Day) {
        dayItems(culture).map((item, i) => {
            columns.push(<GridColumn field={item.column} cells={{ data: reserveCell }}
                title={item.name} width={70} key={i} />);
        });

        dateFormat = "dd MMMM";
        prevDate = addDays(state.defaultDate, -1);
        nextDate = addDays(state.defaultDate, 1);
    }

    const currentItem = formatDate(state.defaultDate, dateFormat, culture);
    const prevItem = formatDate(prevDate, dateFormat, culture);
    const nextItem = formatDate(nextDate, dateFormat, culture);

    const gridToolBar = [
        <RadioGroup style={{ margin: "4px 8px" }} data={views}
            key="viewSelector"
            onChange={handleChangeView}
            value={state.currentView}
            layout="horizontal" />,
        <Button key="perv" icon="arrow-chevron-left" onClick={handlePrevItem}>{prevItem}</Button>,
        <span key="current" style={{ margin: "6px 8px" }}>{currentItem}</span>,
        <Button key="next" icon="arrow-chevron-right" onClick={handleNextItem}>{nextItem}</Button>
    ];

    var filteredData = state.data
        ? state.data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: state.selectedState[idGetter(item)]
        }))
        : state.data;

    if (filteredData && state.hasSelection && state.selectedReserve) {
        const selectedReserves = state.selectedReserve.items.filter(i => i.selected && i.value !== -1);
        if (!selectedReserves || selectedReserves.length === 0) {
            if (!isNaN(state.selectedItem[DATA_ITEM_STATE]))
                gridToolBar.push(<Button className="dm-positive-filled"
                    key="reserve"
                    style={{ margin: "0 8px" }}
                    onClick={handleReserve}>
                    {window.captions.Reservation}
                </Button>);
        } else {
            var reserveIdList = [];
            selectedReserves.forEach(i => {
                if (!reserveIdList.includes(i.id)) reserveIdList.push(i.id);
            });
            if (reserveIdList.length === 1) {
                var reserveId = reserveIdList[0];
                gridToolBar.push(<Button className="dm-negative-filled"
                    key="delete"
                    style={{ margin: "0 8px" }}
                    onClick={() => handleOnDelete(reserveId)}>
                    {window.captions.Delete}
                </Button>);

                gridToolBar.push(<DropDownButton key="changeState"
                    style={{ margin: "0 8px" }}
                    onItemClick={(e) => handleOnChangeState(e, reserveId)}
                    icon="stop"
                    items={state.states}
                    text={window.captions.ChangeState}
                    textField="name" />);
            }

            if (selectedReserves.length === 1 && selectedReserves[0].loanId > 0) {
                const href = `/card/loan/${selectedReserves[0].loanId}`;
                gridToolBar.push(<Link to={href} key="loanLink" title={window.captionsDynamic.LoanCard}>
                    <Button icon="arrow-chevron-right" style={{ margin: "0 8px" }}>
                        {selectedReserves[0].name}
                    </Button>
                </Link>);
            }
        }
    }

    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.DisplayResultByReserve}</h1>
        <div className="dm-under-title-content dm-under-full-height">
            <Tooltip position="top" anchorElement="target" openDelay={0}>
                <Grid className="dm-full-height dm-bordered-grid"
                    pageable={true}
                    total={state.total}
                    data={filteredData}
                    skip={state.dataState.skip}
                    pageSize={state.dataState.take}
                    filter={state.dataState.filter}
                    sort={state.dataState.sort}
                    navigatable={true}
                    onDataStateChange={handleDataStateChange}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: true,
                        mode: "multiple"
                    }}
                    onSelectionChange={selectionChange}>
                    <GridToolbar>
                        <div className="dm-flex-between">
                            <div className="dm-flex-between">
                                {gridToolBar}
                            </div>
                        </div>
                    </GridToolbar>
                    {columns}
                    <GridColumn field="id" title=" " field={DATA_ITEM_KEY} cells={{ data: GridEmptyCell }} />
                    <GridColumn
                        field={DATA_ITEM_KEY}
                        title=" "
                        locked={true}
                        cells={{ data: GridCollateralCardCell }}
                        width="35" />
                </Grid>
            </Tooltip>
            {loadingPanel}
            {dialogContent}
            <ConfirmMessage yes={confirmOk}
                no={confirmClose}
                text={state.confirm} />
        </div>
    </div>;

    function fetchColumns() {
        fetchGet(`${window.constants.searchReservesColumns}/${state.entity}/${state.id}`,
            (data) => {
                var dataState = state.dataState;
                dataState.sort = data.sort;
                var columns = data.columns && data.columns.length > 1
                    ? data.columns.slice(0, maxDisplayColumns)
                    : [];

                setState({
                    ...state,
                    columns: columns,
                    dataState,
                    hasSelection: false,
                    menuAccess: data.menuAccess,
                    stateColors: data.stateColors,
                    config: data.config,
                    interval: data.interval,
                    schedule: data.schedule,
                    typeConfig: data.typeConfig,
                    workTimeFrom: data.workTimeFrom,
                    workTimeTo: data.workTimeTo,
                    currentView: data.config.defaultView,
                    states: data.states,
                    isLoaded: true,
                    error: null
                });
                fetchData(state.dataState);
            },
            error => {
                setState({ ...state, error });
            });
    }

    function fetchData(dataState) {
        setState({ ...state, pending: true, reserves: [] });
        fetchGet(
            `${window.constants.searchResults}/${state.entity}/${state.id}?${toDataSourceRequestString(dataState)}`,
            ({ data, total }) => {
                var items = data.map((dataItem) =>
                    Object.assign({ selected: false }, dataItem)
                );

                setState({
                    ...state,
                    data: items,
                    error: null,
                    total,
                    dataState,
                    hasSelection: false
                });
                fetchReserves();
            },
            error => {
                setState({ ...state, error });
            });
    }

    function fetchReserves(date) {
        if (!date) {
            date = state.defaultDate;
            if (!date)
                return;
        }

        const requestDate = formatDate(date, "yyyy-MM-dd");
        const ids = state.data.map((dataItem) => `id=${dataItem[DATA_ITEM_KEY]}`).join("&");
        fetchGet(`${window.constants.searchReservesItems}/${state.currentView}/${requestDate}/${state.interval}?${ids}`,
            (data) => {
                setState({
                    ...state,
                    reserves: data ? data : [],
                    defaultDate: date,
                    isLoaded: true,
                    pending: false,
                    error: null
                });
            },
            error => {
                setState({ ...state, error });
            });
    }

    function yearItems(culture) {
        const items = [];
        for (let m = 0; m < 12; m++) {
            items.push({
                column: `cell${m + 1}`,
                name: formatDate(new Date(1, m, 1), "MMMM", culture)
            });
        }
        return items;
    }

    function dayItems(culture) {
        const items = [];
        const interval = state.interval;
        const from = state.workTimeFrom;
        let to = state.workTimeTo;
        if (to < 1)
            to = 24;

        let m = from * 60;
        while (m < to * 60) {
            items.push({
                column: `cell${m}`,
                name: formatDate(new Date(1, 1, 1, 0, m, 0), "HH:mm", culture)
            });
            m += interval;
        }
        return items;
    }

    function getReservesModel() {
        const reserve = state.selectedReserve;
        if (!reserve)
            return [];

        const selectedReserves = reserve.items.filter(i => i.selected);
        if (!selectedReserves)
            return [];

        const items = [];
        const setItem = (res) => {
            res.endDate.setSeconds(res.endDate.getSeconds() - 1);
            items.push(res);
        };

        const getItem = (res) => {
            return {
                collateralId: reserve.collateralId,
                startDate: new Date(res.startDate),
                endDate: new Date(res.endDate)
            };
        };

        let reserveData = undefined;
        for (let i in selectedReserves) {
            const res = selectedReserves[i];
            if (!reserveData) {
                reserveData = getItem(res);
                continue;
            }

            if (isEqual(reserveData.endDate, new Date(res.startDate))) {
                reserveData.endDate = new Date(res.endDate);
            } else {
                setItem(reserveData);
                reserveData = getItem(res);
            }
        }
        if (reserveData)
            setItem(reserveData);

        return items;
    }

    function isCorrectSelectedDate() {
        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);
        const reserves =
            state.reserves.filter(i => state.selectedItems.some(k => k[DATA_ITEM_KEY] === i.collateralId));
        for (let key in reserves) {
            const item = reserves[key];
            if (item.items && item.items.some(i => i.selected && new Date(i.startDate) < dateNow))
                return true;
        }
        return false;
    }

    function handleDataStateChange(changeEvent) {
        setState({ ...state, dataState: changeEvent.dataState });
        fetchData(changeEvent.dataState);
    }

    function handleReserve() {
        if (!state.selectedItem)
            return;

        setState({
            ...state,
            showReserveDialog: true,
            stateId: state.selectedItem[DATA_ITEM_STATE]
        });
    }

    function handleChangeView(e) {
        setState({ ...state, currentView: e.value });
        fetchData(state.dataState);
    }

    function handleChangeItem(index) {
        const date = state.defaultDate;
        var newDate;
        if (state.currentView === timeIntervalTypes.Month) {
            newDate = addMonths(date, index);
        } else if (state.currentView === timeIntervalTypes.Year) {
            newDate = addYears(date, index);
        } else {
            newDate = addDays(date, index);
        }

        fetchReserves(newDate);
    }

    function handlePrevItem() {
        handleChangeItem(-1);
    }

    function handleNextItem() {
        handleChangeItem(1);
    }

    function dialogReserveOnClose() {
        setState({
            ...state,
            showReserveDialog: false,
            selectedState: {},
            selectedItem: null,
            selectedReserve: null,
            hasSelection: false,
            pending: true
        });
        fetchReserves();
    }

    function selectionChange(event) {
        var selectedState = getSelectedState({
            event,
            selectedState: state.selectedState,
            dataItemKey: DATA_ITEM_KEY
        });

        var keys = Object.keys(selectedState);
        if (keys.length === 0) {
            setState({ ...state, selectedState: {}, hasSelection: false, selectedItem: null, selectedReserve: null });
            return;
        }

        var idSelected = parseInt(keys[0]);
        const selectedItem = state.data.find(i => idSelected === i[DATA_ITEM_KEY]);
        const selectedReserve = state.reserves.find(i => i.collateralId === idSelected);
        if (!selectedItem || !selectedReserve) {
            setState({ ...state, selectedState: {}, hasSelection: false, selectedItem: null, selectedReserve: null });
            return;
        }

        var index = 0;
        var selected = selectedState[keys[0]];
        var shift = maxDisplayColumns;
        if (state.currentView === timeIntervalTypes.Day)
            shift -= state.workTimeFrom * 60 / state.interval;

        for (let key in selectedReserve.items) {
            selectedReserve.items[key].selected = selected.includes(index + shift);
            index++;
        }

        if (keys.length > 1) {
            const trimmedState = {};
            trimmedState[keys[0]] = selected;
            selectedState = trimmedState;
        }

        setState({ ...state, selectedState, hasSelection: true, selectedItem, selectedReserve });
    }

    function handleOnDelete(reserveId) {
        if (!isNaN(reserveId) && reserveId > 0) {
            setState({
                ...state,
                confirm: window.captions.CancelReserveConfirm,
                confirmReserveId: 0,
                confirmCancelId: reserveId,
            });
        }
    }

    function handleOnChangeState(e, reserveId) {
        if (!isNaN(reserveId) && reserveId > 0) {
            setState({
                ...state,
                confirm: e.item.name,
                confirmReserveId: reserveId,
                confirmStateId: e.item.id,
                confirmCancelId: 0,
            });
        }
    }

    function confirmOk() {
        var confirmReserveId = state.confirmReserveId;
        var confirmCancelId = state.confirmCancelId;
        confirmClose();
        if (confirmReserveId > 0) {
            fetchPost(`${window.constants.reserveUpdateState}/${confirmReserveId}`,
                state.confirmStateId,
                () => {
                    setState({
                        ...state,
                        selectedState: {},
                        selectedItem: null,
                        selectedReserve: null,
                        hasSelection: false,
                        pending: true
                    });
                    fetchReserves();
                },
                error => {
                    setState({ ...state, error });
                });
        } else if (confirmCancelId > 0) {
            fetchDelete(`${window.constants.reserveRemove}`,
                confirmCancelId,
                () => {
                    setState({
                        ...state,
                        selectedState: {},
                        selectedItem: null,
                        selectedReserve: null,
                        hasSelection: false,
                        pending: true
                    });
                    fetchReserves();
                },
                error => {
                    setState({ ...state, error });
                });
        }
    }

    function confirmClose() {
        setState({
            ...state,
            confirm: false,
            confirmReserveId: 0,
            confirmCancelId: 0
        });
    }
}