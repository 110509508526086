import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";

export default function MultilineTextArea({ field, text, textLength, textLengthMin, required, changeText }) {
    const textString = text ?? "";

    if (textString == null)
        return <span />;

    let minLength = 0;
    if (textLengthMin)
        minLength = textLengthMin;

    const valid = !(textString.length > 0 && textString.length < minLength || required && textString.length === 0);
    const restrictions = {};
    if (textLength && textLength > 0)
        restrictions.maxLength = textLength;

    return <TextArea onChange={changeTextArea}
        {...restrictions}
        valid={valid}
        rows={1}
        autoSize={true}
        field={field}
        defaultValue={textString} />;

    function changeTextArea(e) {
        if (changeText)
            changeText({ fieldId: field, value: e.value });
    }
}