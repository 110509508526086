function getLocation(success, error) {
    if (navigator.geolocation) {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        navigator.permissions
            .query({ name: "geolocation" })
            .then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(pos => success(pos.coords), err => {
                        error();
                        console.warn(`ERROR(${err.code}): ${err.message}`);
                    }, options);
                } else {
                    error();
                    console.log(result);
                }
            });
    } else {
        error();
        console.log("Geolocation is not supported by this browser.");
    }
}

export {
    getLocation
    }