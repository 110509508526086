import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { formatDate } from "@progress/kendo-intl";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { NumericTextBox, Input } from "@progress/kendo-react-inputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import MessageMask from "../../components/messageMask.jsx";
import {
    dateTimeFormat,
    entitySourcesNames,
    getFormatDateTime,
    numericWidth,
    dateTimeWidth
} from
    "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";

export default function DialogAccount({ accountId, access, cardId, cardEntity, onClose }) {
    const isAdd = accountId === 0;

    const delteteButtonStyle = {
        position: "absolute",
        right: "25px",
        width: "36px",
        height: "40px",
        top: "10px",
    };

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [deleteLimit, setDeleteLimit] = useState(null);
    const [deleteTransaction, setDeleteTransaction] = useState(null);

    const [selectedTab, setSelectedTab] = useState(0);
    const [isEdit, setIsEdit] = useState(accountId === 0);
    const [isEdited, setIsEdited] = useState(false);

    const [account, setAccount] = useState(null);
    const [type, setType] = useState(0);
    const [number, setNumber] = useState("");
    const [numberMask, setNumberMask] = useState("");
    const [numberValid, setNumberValid] = useState(true);
    const [state, setState] = useState(0);
    const [stateName, setStateName] = useState("");
    const [currency, setCurrency] = useState(0);
    const [currencyName, setCurrencyName] = useState("");
    const [executor, setExecutor] = useState(0);
    const [executorName, setExecutorName] = useState("");
    const [sources, setSources] = useState(null);
    const [limits, setLimits] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const [deletedLimits, setDeletedLimits] = useState([]);
    const [deletedTransactions, setDeletedTransactions] = useState([]);

    useEffect(() => fetchData(), []);

    let title = isAdd ? window.captions.AddAccount : window.captions.Account;
    if (pending || error) {
        return <Dialog title={title} onClose={() => onCloseDialog(false)} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    let editViewButton = null;
    if (access.canAddEditAccount)
        editViewButton =
            <Button iconClass={`dm-i dm-i-${(isEdit ? "eye" : "pen")}`} onClick={changeEdit}>{isEdit
                ? window.captions.View
                : window.captions.EditMode
            }</Button>;

    let saveButton = null;
    if (isEdited) {
        saveButton = <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Save}</Button>;
    }

    let modal = null;
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    var confirmDialog = <ConfirmMessage key="confirmDialog"
        yes={confirmOk}
        no={confirmClose}
        text={confirm} />;

    const layout = [];

    renderPair(layout,
        window.captions.AccountNumber,
        "number",
        () => <Input valid={numberValid} onChange={changeNumber} defaultValue={number} />,
        numberMask,
        isAdd || (isEdit && access.canChangeAccountNumber));

    const selectedType = sources.accountTypes.find((e) => e.id === type);
    renderPair(layout,
        window.captions.AccountType,
        "type",
        () => <DropDownList
            textField="name"
            dataItemKey="id"
            data={sources.accountTypes}
            value={selectedType}
            onChange={changeType}
            style={{ width: "100%" }} />,
        selectedType ? selectedType.name : null,
        isAdd || (isEdit && access.canChangeAccountType));

    var selectedState = sources.accountStates.find((i) => i.id === state);
    renderPair(layout,
        window.captions.AccountState,
        "state",
        () => <DropDownList
            textField="name"
            dataItemKey="id"
            data={sources.accountStates}
            value={selectedState}
            onChange={changeState}
            style={{ width: "100%" }} />,
        stateName,
        isAdd || (isEdit && access.canChangeAccountState));

    var selectedCurrency = sources.currencyTypes.find((i) => i.id === currency);
    renderPair(layout,
        window.captions.Currency,
        "currency",
        () => <DropDownList
            textField="name"
            dataItemKey="id"
            data={sources.currencyTypes}
            value={selectedCurrency}
            onChange={changeCurrency}
            style={{ width: "100%" }} />,
        currencyName,
        isAdd || isEdit);

    renderPair(layout,
        window.captions.Executor,
        "executor",
        () => <DropDownTreeView
            treeData={sources.executors}
            selectedId={executor}
            selectionChanged={changeExecutor}
            style={{ width: "100%" }} />,
        executorName,
        isAdd || (isEdit && access.canChangeAccountAuthor));

    const limitSystemFields = (p) => {
        const systemFields = [];

        var selectedType = sources.accountLimitTypes.find((i) => i.id === p.typeId);
        renderPair(systemFields,
            window.captions.AccountLimitsType,
            "typeId",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={sources.accountLimitTypes}
                value={selectedType}
                onChange={(e) => changeLimit(e.value, p, "typeId")}
                style={{ width: "calc(100% - 45px)" }} />,
            p.typeName,
            p.id === 0 || (isEdit && access.canChangeAccountEndDate));

        if (isEdit && access.canDeleteAccountLimit) {
            systemFields.push(<Button icon="delete" key="limitDelete" style={delteteButtonStyle} onClick={(e) => handleOnDeleteLimit(e, p)} />);
        }

        renderPair(systemFields, window.captions.Author, "author", null, p.authorName, false);

        renderPair(systemFields,
            window.captions.FormulaSum,
            "maxSum",
            () => <NumericTextBox
                onChange={(e) => changeLimit(e.value, p, "maxSum")}
                defaultValue={parseFloat(p.maxSum)}
                format="n0"
                width={numericWidth} />,
            p.maxSum,
            isEdit);

        renderPair(systemFields,
            window.captions.StartDateAccountLimit,
            "expectedEndDate",
            () => <DateTimePicker
                onChange={(e) => changeLimit(new Date(e.value), p, "expectedEndDate")}
                value={p.expectedEndDate ? new Date(p.expectedEndDate) : null}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />,
            p.expectedEndDate
                ? formatDate(new Date(p.expectedEndDate), dateTimeFormat, getUiCulture())
                : null,
            isEdit);

        renderPair(systemFields,
            window.captions.EndDateAccountLimit,
            "actualEndDate",
            () => <DateTimePicker
                onChange={(e) => changeLimit(new Date(e.value), p, "actualEndDate")}
                value={p.actualEndDate ? new Date(p.actualEndDate) : null}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />,
            p.actualEndDate
                ? formatDate(new Date(p.actualEndDate), dateTimeFormat, getUiCulture())
                : null,
            isEdit);

        return systemFields;
    };

    const transactionSystemFields = (p) => {
        const systemFields = [];

        var selectedType = sources.accountTransactionTypes.find((i) => i.id === p.typeId);
        renderPair(systemFields,
            window.captions.WordType,
            "typeId",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={sources.accountTransactionTypes}
                value={selectedType}
                onChange={(e) => changeTransactions(e.value, p, "typeId")}
                style={{ width: "calc(100% - 45px)" }} />,
            p.typeName,
            p.id === 0 || (isEdit && access.canChangeAccountTransaction));

        var selectedCurrency = sources.currencyTypes.find((i) => i.id === p.currencyId);
        renderPair(systemFields,
            window.captions.Currency,
            "currencyId",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={sources.currencyTypes}
                value={selectedCurrency}
                onChange={(e) => changeTransactions(e.value, p, "currencyId")}
                style={{ width: "calc(100% - 45px)" }} />,
            p.currencyName,
            p.id === 0 || (isEdit && access.canChangeAccountTransaction));

        if (isEdit && access.canDeleteAccountTransaction) {
            systemFields.push(<Button icon="delete" key="transactionDelete" style={delteteButtonStyle} onClick={(e) => handleOnDeleteTransaction(e, p)} />);
        }

        renderPair(systemFields, window.captions.Author, "author", null, p.authorName, false);

        renderPair(systemFields,
            window.captions.FormulaSum,
            "amount",
            () => <NumericTextBox
                onChange={(e) => changeTransactions(e.value, p, "amount")}
                defaultValue={parseFloat(p.amount)}
                format="n0"
                width={numericWidth} />,
            p.amount,
            isEdit);

        renderPair(systemFields,
            window.captions.Created,
            "created",
            () => <DateTimePicker
                onChange={(e) => changeTransactions(new Date(e.value), p, "created")}
                value={new Date(p.created)}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />,
            formatDate(new Date(p.created), dateTimeFormat, getUiCulture()),
            isEdit);

        return systemFields;
    };

    const limitsPanel =
        limits.map((item, g) => <PanelBarItem title={item.typeName} key={g} expanded={g === 0}>
            <div className="dm-filter-group dm-container">
                {limitSystemFields(item)}
                <FieldsControl id={item.id}
                    entity={entitySourcesNames.accountLimit}
                    fields={item.fields}
                    sources={sources}
                    isEditMode={isEdit}
                    setFields={setEntityFields} />
            </div>
        </PanelBarItem>
        );

    const transactionsPanel =
        transactions.map((item, g) => <PanelBarItem title={item.typeName} key={g} expanded={g === 0}>
            <div className="dm-filter-group dm-container">
                {transactionSystemFields(item)}
                <FieldsControl id={item.id}
                    entity={entitySourcesNames.accountTransaction}
                    fields={item.fields}
                    sources={sources}
                    isEditMode={isEdit}
                    setFields={setEntityFields} />
            </div>
        </PanelBarItem>
        );

    const fieldsPanel = account.fields && Object.keys(account.fields).length > 0
        ? <FieldsControl id={account.id}
            entity={entitySourcesNames.account}
            fields={account.fields}
            sources={sources}
            isEditMode={isEdit}
            setFields={setEntityFields} />
        : null;

    const closeTitle = isEdited ? window.captions.Cancel : window.captions.Close;

    var limitsTab = null;
    var transactionsTab = null;
    var renderButton = (canAdd, handler, name) => {
        return canAdd
            ? <Button className="dm-positive-filled" onClick={handler}>{name}</Button>
            : null;
    };
    var canAddLimit = access.canAddEditAccountLimit && sources.accountLimitTypes.length > 0;
    var hasLimitBar = canAddLimit || limitsPanel.length > 0;
    if (hasLimitBar) {
        limitsTab = <TabStripTab title={window.captions.AccountLimits}>
            <div>
                <PanelBar expandMode="single" style={{ width: "100%" }}>
                    {limitsPanel}
                </PanelBar>
            </div>
        </TabStripTab>;
    }

    var hasTransactionBar = transactionsPanel.length > 0;
    if (hasTransactionBar) {
        transactionsTab = <TabStripTab title={window.captions.AccountTransactions}>
            <div>
                <PanelBar expandMode="single" style={{ width: "100%" }}>
                    {transactionsPanel}
                </PanelBar>
            </div>
        </TabStripTab>;
    }

    var actionsButton;
    var tab = selectedTab;
    if (tab === 1 && limitsTab) {
        actionsButton = renderButton(canAddLimit, handleNewLimit, window.captions.AddAccountLimit);
    }

    return [
        <Dialog title={title}
            key="dialogAccounts"
            onClose={onCloseDialog} width={950}
            className="k-dialog-no-padding">
            <TabStrip selected={tab} onSelect={handleSelectTab}>
                <TabStripTab title={window.captions.Account}>
                    <div className="dm-container dm-no-padding">
                        {layout}
                    </div>
                </TabStripTab>
                {limitsTab}
                {transactionsTab}
                {fieldsPanel
                    ? <TabStripTab title={window.captions.Fields}>
                        <div className="dm-container dm-no-padding">
                            {fieldsPanel}
                        </div>
                    </TabStripTab>
                    : null}
            </TabStrip>
            <DialogActionsBar>
                <Button onClick={onCloseDialog}>{closeTitle}</Button>
                {editViewButton}
                {saveButton}
                {actionsButton}
            </DialogActionsBar>
        </Dialog>,
        modal,
        confirmDialog
    ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getAccountModel}/${cardEntity}/${cardId}/${accountId}`,
            (data) => {
                if (!data) {
                    setPending(null);
                    setError(window.captions.WordError);
                    return;
                }

                setAccount(data);
                setType(data.typeId);
                setNumber(data.eNumber);
                setNumberMask(data.eNumberMask);
                setNumberValid(true);
                setCurrency(data.currencyId);
                setCurrencyName(data.currencyName);
                setState(data.stateId);
                setStateName(data.stateName);
                setExecutor(data.authorId);
                setExecutorName(data.authorName);
                setSources(data.sources);

                setLimits(data.accountLimits);
                setTransactions(data.transactions);

                setPending(null);
                setError(null);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }

    function setEntityFields(fields, id, entity) {
        if (entity === entitySourcesNames.account) {
            setAccount({ ...account, fields });
            setIsEdited(true);
        } else if (entity === entitySourcesNames.accountLimit) {
            const limit = limits.find((i) => { return i.id === id });
            if (!limit)
                return;
            limit.fields = fields;
            limit.updated = true;

            setLimits([...limits]);
            setIsEdited(true);
        } else if (entity === entitySourcesNames.accountTransaction) {
            const transaction = transactions.find((i) => { return i.id === id });
            if (!transaction)
                return;
            transaction.fields = fields;
            transaction.updated = true;


            setTransactions([...transactions]);
            setIsEdited(true);
        }
    }

    function handleSelectTab(e) {
        setSelectedTab(e.selected);
    }

    function changeNumber(e) {
        var newNumber = e.value;
        if (window.systemConfig.accountNumberValidateFieldId > 0) {
            fetchPost(`${window.constants.validateField}/${window.systemConfig.accountNumberValidateFieldId}`,
                {
                    value: newNumber,
                    optionalValues: {
                        contactType: type,
                        contactState: state
                    }
                },
                (success) => {
                    setNumber(newNumber);
                    setIsEdited(true);
                    setNumberValid(success);;
                },
                () => {
                    setNumber(newNumber);
                    setIsEdited(true);
                    setNumberValid(false);
                });
        }
        else {
            setNumber(newNumber);
            setIsEdited(true);
            setNumberValid(true);
        }
    }

    function changeType(e) {
        setType(e.target.value.id);
        setIsEdited(true);
    }

    function changeExecutor(e) {
        setExecutor(e.value);
        setExecutorName(e.valueText);
        setIsEdited(true);
    }

    function changeState(e) {
        setState(e.target.value.id);
        setStateName(e.target.value.name);
        setIsEdited(true);
    }

    function changeCurrency(e) {
        setCurrency(e.target.value.id);
        setCurrencyName(e.target.value.name);
        setIsEdited(true);
    }

    function changeLimit(value, limit, prop) {
        if (prop === "typeId") {
            limit[prop] = value.id;
            limit["typeName"] = value.name;
        } else
            limit[prop] = value;

        limit.updated = true;

        setLimits([...limits]);
        setIsEdited(true);
    }

    function changeTransactions(value, transaction, prop) {
        if (prop === "typeId") {
            transaction[prop] = value.id;
            transaction["typeName"] = value.name;
        } else if (prop === "currencyId") {
            transaction[prop] = value.id;
            transaction["currencyName"] = value.name;
        } else
            transaction[prop] = value;

        transaction.updated = true;

        setTransactions([...transactions]);
        setIsEdited(true);
    }

    function handleNewLimit() {
        const cloneDeep = require('clone-deep');

        setLimits([
            {
                id: 0,
                typeId: sources.accountLimitTypes[0].id,
                typeName: sources.accountLimitTypes[0].name,
                fields: cloneDeep(account.limitFields),
                maxSum: 0,
                actualEndDate: null,
                expectedEndDate: null
            },
            ...limits
        ]);
        setIsEdited(true);
        setIsEdit(true);
        setPending(null);
        setError(null);
    }

    function handleOnDeleteLimit(e, limit) {
        var id = parseInt(limit["id"]);
        if (!isNaN(id)) {
            setConfirm(window.captions.DeleteItemConfirm);
            setDeleteLimit(limit);
        }
    }

    function handleOnDeleteTransaction(e, transaction) {
        var id = parseInt(transaction["id"]);
        if (!isNaN(id)) {
            setConfirm(window.captions.DeleteItemConfirm);
            setDeleteTransaction(transaction);
        }
    }

    function onCloseDialog() {
        if (!isEdited)
            onClose(false);
        else {
            setConfirm(window.captions.CancelChangesConfirm);
        }
    }

    function isValid(errorFields) {
        if (!numberValid || !number || number.trim() === "")
            errorFields.push(window.captions.AccountNumber);

        if (!type && isAdd)
            errorFields.push(window.captions.AccountType);

        if (!executor)
            errorFields.push(window.captions.Executor);

        if (!currency)
            errorFields.push(window.captions.Currency);

        if (!state)
            errorFields.push(window.captions.AccountState);
    }

    function save() {
        const errorFields = CardSaveFunctions.validateRequired(account.fields, null, {});
        isValid(errorFields);
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        var model = CardSaveFunctions.renderValues(account.fields);
        model.id = account.id;
        model.eNumber = number;
        model.typeId = type;
        model.authorId = executor;
        model.stateId = state;
        model.currencyId = currency;
        if (model.id > 0) {
            model.limits = [];
            model.transactions = [];

            for (let key in limits) {
                const item = limits[key];
                if (item.id !== 0 && !item.updated)
                    continue;

                const limit = CardSaveFunctions.renderValues(item.fields);
                limit.id = item.id;
                limit.authorId = item.authorId;
                limit.typeId = item.typeId;
                limit.maxSum = item.maxSum;
                limit.actualEndDate = item.actualEndDate ? new Date(item.actualEndDate) : null;
                limit.expectedEndDate = item.expectedEndDate ? new Date(item.expectedEndDate) : null;
                model.limits.push(limit);
            }


            for (let key in transactions) {
                const item = transactions[key];
                if (item.id !== 0 && !item.updated)
                    continue;

                const transaction = CardSaveFunctions.renderValues(item.fields);
                transaction.id = item.id;
                transaction.authorId = item.authorId;
                transaction.typeId = item.typeId;
                transaction.currencyId = item.currencyId;
                transaction.amount = item.amount;
                transaction.eNumber = item.eNumber;
                transaction.created = new Date(item.created);
                model.transactions.push(transaction);
            }

            if (errorFields.length > 0) {
                setPending(null);
                setValidate(errorFields);
                return;
            }

            model.deletedLimits = deletedLimits;
            model.deletedTransactions = deletedTransactions;
        }

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveAccount}/${cardEntity}/${cardId}`,
            model,
            () => onClose(true),
            () => onClose(false));
    }

    function changeEdit() {
        CardSaveFunctions.changeEditFields(isEdit,
            isEdited,
            account,
            (edit, m) => {
                setIsEdit(edit);
                setAccount(m);
            });
    }

    function confirmOk() {
        confirmClose();
        if (deleteLimit) {
            const id = parseInt(deleteLimit["id"]);
            const acts = limits;
            const index = acts.indexOf(deleteLimit);
            acts.splice(index, 1);
            const actsDeleted = deletedLimits;
            if (id > 0)
                actsDeleted.push(id);

            setLimits(acts);
            setDeletedLimits(actsDeleted);
            setIsEdited(true);
            return;
        }

        if (deleteTransaction) {
            const id = parseInt(deleteTransaction["id"]);
            const acts = transactions;
            const index = acts.indexOf(deleteTransaction);
            acts.splice(index, 1);
            const actsDeleted = deletedTransactions;
            if (id > 0)
                actsDeleted.push(id);

            setTransactions(acts);
            setDeletedTransactions(actsDeleted);
            setIsEdited(true);
            return;
        }

        onClose(false);
    }

    function confirmClose() {
        setConfirm(false);
        setDeleteLimit(null);
        setDeleteTransaction(null);
    }
}