import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogSelectUser({ title, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(0);

    useEffect(() => fetchData(), []);

    if (pending || error) {
        return <Dialog title={title} onClose={() => onClose(0)} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }
    let selectButton = "";
    if (userId > 0) {
        selectButton =
            <Button themeColor="primary" onClick={() => onClose(userId)}>{window.captions.WordSelect}</Button>;
    }

    return <Dialog title={title} onClose={() => onClose(0)} width={400}>
        <DropDownTreeView
            treeData={users}
            selectedId={userId}
            selectionChanged={changeUser}
            style={{ width: "100%" }} />
        <DialogActionsBar>
            <Button onClick={() => onClose(0)}>{window.captions.Cancel}</Button>
            {selectButton}
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.chatUsers}`,
            (data) => {
                setUsers(data);
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function changeUser(e) {
        setUserId(e.value);
    }
}