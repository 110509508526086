import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import DropDownAlternating from "../../components/controls/dropDownAlternating.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogCreateClient({ id, clientId, phone, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);

    const [typeId, setTypeId] = useState(0);

    useEffect(() => fetchData(), []);

    const title = id === 0 ? window.captions.Client : window.captions.MainMenuCreateClient;
    if (pending || error) {
        return <Dialog title={title} onClose={() => onClose(0)} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    let saveButton = "";
    if (typeId > 0) {
        saveButton = <Button themeColor="primary" onClick={save}>{window.captions.Create}</Button>;
    }

    const labels = [
        window.captions.WordType
    ];

    return <Dialog title={title} onClose={() => onClose(0)} width={400}>
        <DropDownAlternating
            id={`DropAlternatingNewClient`}
            style={{ width: "100%" }}
            selectedId={typeId}
            treeData={items}
            treeLabels={labels}
            selectionChanged={changedState} />
        <DialogActionsBar>
            <Button onClick={() => onClose(0)}>{window.captions.Cancel}</Button>
            {saveButton}
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getClientTypeModel}`,
            (data) => {
                setItems(data);
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function changedState(e) {
        setTypeId(e.selected.length === 4 ? e.selected[1].id : e.selected[0].id);
    }

    function save() {
        var saveData = {
            clientId: clientId,
            typeId: typeId,
            phone: phone
        };

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(window.constants.saveNewClient,
            saveData,
            (saveId) => onClose(saveId),
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }
}