import { itemFinder } from "../../utils/arrayHelper.jsx";
import { createSlice } from '@reduxjs/toolkit'
import collateralSchemaFunctions from "./collateralSchemaFunctions.jsx";
import { fetchGet, fetchPost } from '../../utils/requestsHelper.jsx';

export const collateralSchemaSlice = createSlice({
    name: 'collateralSchema',
    initialState: {
        collateralSchemas: [],
        blocks: [],
        error: null,
        isLoaded: false,
        isLoadingBlocks: false,
        showBlockDialog: false,
        date: null, //TODO was new Date()

        selectedSchema: null,
        selectedBlock: null,
        blockDataModel: null,

        minTop: 0,
        minLeft: 0,

        accessEdit: false
    },
    reducers: {
        schemaLoaded: (state, action) => {
            var minTop = 0;
            var minLeft = 0;
            var items = action.payload.schemas;
            var currentSchema = action.payload.schemaId
                ? items.find((i) => i.id === action.payload.schemaId)
                : items[0];

            for (let item of action.payload.blocks) {
                if (minLeft > item.positionLeft)
                    minLeft = item.positionLeft;

                if (minTop > item.positionTop)
                    minTop = item.positionTop;
            }
            state.error = null;
            state.isLoaded = true;
            state.selectedSchema = currentSchema;
            state.collateralSchemas = items;
            state.blocks = action.payload.blocks;
            state.minTop = minTop;
            state.minLeft = minLeft;
            state.accessEdit = action.payload.accessEdit;
        },
        itemLoaded: (state, action) => {
            var minTopBlocks = 0;
            var minLeftBlocks = 0;

            var changedSchema = itemFinder(state.collateralSchemas, (i) => i.id === action.payload.id);
            for (let item of action.payload.items) {
                if (minLeftBlocks > item.positionLeft)
                    minLeftBlocks = item.positionLeft;

                if (minTopBlocks > item.positionTop)
                    minTopBlocks = item.positionTop;
            }
            state.isLoadingBlocks = false;
            state.selectedSchema = changedSchema;
            state.selectedBlock = null;
            state.blocks = - action.payload.items;
            state.minTop = minTopBlocks;
            state.minLeft = minLeftBlocks;
            state.date = action.payload.date;
        },
        loading: (state) => {
            state.isLoadingBlocks = true;
        },
        error: (state, action) => {
            state.error = action.payload;
            state.isLoaded = true;
            state.isProcessing = false;
            state.needReload = false;
        },
        blockChanged: (state, action) => {
            state.selectedBlock = action.payload;
        },
        blockModelLoaded: (state, action) => {
            state.showBlockDialog = true;
            state.blockDataModel = action.payload;
        },
        dialogCanceled: (state) => {
            state.showBlockDialog = false;
        }
    }
})

export const {
    schemaLoaded,
    itemLoaded,
    loading,
    error,
    blockChanged,
    blockModelLoaded,
    dialogCanceled
} = collateralSchemaSlice.actions

export const schemaLoad = (schemaId) => (dispatch) => {
    const schemaIdRequest = schemaId ? new Number(schemaId) : 0;
    const url = `${window.constants.collateralSchemaElements}/${schemaIdRequest}`;

    fetchGet(url,
        data => {
            const blocks = collateralSchemaFunctions.convertBlocks(data.blocks);
            const schemas = collateralSchemaFunctions.convertSchemas(data.schemas);
            dispatch(schemaLoaded({
                schemas,
                blocks,
                accessEdit: data.accessEdit,
                schemaId: schemaId ? parseInt(schemaId) : null
            }));
        },
        ex => {
            dispatch(error(ex));
        }
    );
}

export const selectedDateChanged = (e, store) => (dispatch) => {
    return schemaSelectedChanged({ value: store.selectedSchema.id }, e.target.value);
}

export const schemaSelectedChanged = (e, date) => (dispatch) => {
    const id = e.value;
    const newDate = date ? new Date(date) : new Date();
    dispatch(loading());
    fetchPost(`${window.constants.collateralSchemaBlocks}/${id}`,
        newDate,
        data => {
            const items = collateralSchemaFunctions.convertBlocks(data);
            dispatch(itemLoaded({ items, id, date: newDate }));
        },
        ex => dispatch(error(ex))
    );
}

export const selectedBlockChanged = (e, newItem, selectedSchema) => (dispatch) => {
    if (e.ctrlKey) {
        if (newItem.cid)
            document.location.href = `/card/collateral/${newItem.cid}`;
        else if (newItem.sid) {
            const newSchema = selectedSchema.items.find(x => x.id === newItem.sid);
            if (newSchema) {
                schemaSelectedChanged({ value: newSchema.id });
            }
        }
    }

    dispatch(blockChanged(newItem));
}

export const viewBlockInfo = (selectedBlock) => (dispatch) => {
    //TODO add enum
    switch (selectedBlock.tid) {
        case 0:
            dispatch(blockModelLoaded({
                id: selectedBlock.id,
                tid: selectedBlock.tid,
                displayName: selectedBlock.blockName,
                schemaName: selectedBlock.schemaName
            }));
        case 2:
            if (!selectedBlock.collateralModel)
                return;

            fetchPost(`${window.constants.collateralSchemaBlockModel}`,
                selectedBlock.collateralModel,
                data => {
                    const collateralModel = collateralSchemaFunctions.convertCollateralModels(data);
                    dispatch(blockModelLoaded({
                        ...collateralModel,
                        id: selectedBlock.id,
                        tid: selectedBlock.tid,
                        displayName: selectedBlock.blockName
                    }));
                },
                ex => dispatch(error(ex))
            );
    }
}

export default collateralSchemaSlice.reducer