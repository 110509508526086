import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { questionnaireGroupType } from "../../utils/systemConstants.jsx";
import DialogLinkedClient from "../dialogs/dialogLinkedClient.jsx";
import DialogDocument from "../dialogs/dialogDocument.jsx";
import DialogAddLinkedClient from "../dialogs/dialogAddLinkedClient.jsx";
import DialogConfirmVerification from "../dialogs/dialogConfirmVerification.jsx";
import DialogAccount from "../dialogs/dialogAccount.jsx";

function renderButtons(selectedGroup, isBlocked, access, { add }) {
    if (isBlocked)
        return null;

    if (selectedGroup.type === questionnaireGroupType.documents && access.canAddDocument ||
        selectedGroup.type === questionnaireGroupType.linkedPersons && access.canAddLinkedClient ||
        selectedGroup.type === questionnaireGroupType.accounts && access.canAddEditAccount) {
        return <Button key="buttonAdd" style={{ marginRight: "10px" }} icon="plus" onClick={() => add(true)}>{
            window.captions.Add}</Button>;
    }

    return null;
}

function renderDialogs(selectedGroup,
    { cardId, source, adding, editing, link, access, verification },
    { add, edit, dialogConfirmVerification }) {
    const id = cardId;
    const entity = source;
    if (selectedGroup.type === questionnaireGroupType.documents && adding) {
        return <DialogDocument key="addingDialog"
            cardId={id}
            cardEntity={entity}
            typesHidden={selectedGroup.typesHidden}
            onClose={() => add(false)} />;
    } else if (selectedGroup.type === questionnaireGroupType.accounts && (editing || adding)) {
        return <DialogAccount key="addingDialog"
            accountId={link.id}
            cardId={id}
            cardEntity={entity}
            access={access}
            stepId={selectedGroup.id}
            onClose={() => add(false)} />;
    } else if (selectedGroup.type === questionnaireGroupType.linkedPersons) {
        if (adding)
            return <DialogAddLinkedClient key="addingDialog"
                cardId={id}
                cardEntity={entity}
                canAdd={access.canAddClient}
                canSelectType={true}
                onClose={(data) => data && data.typeId > 0
                    ? edit(true, data.id)
                    : add(false)} />;

        if (editing)
            return <DialogLinkedClient key="addingDialog"
                cardId={id}
                cardEntity={entity}
                stepId={selectedGroup.id}
                linkEntity={link.source}
                id={link.cardId}
                linkId={link.id}
                onClose={() => edit(false)} />;
    } else if (verification.isShow) {
        return <DialogConfirmVerification key="dialogConfirmVerification"
            phoneId={verification.phoneId}
            code={verification.code}
            altCode={verification.altCode}
            onClose={(verified, stateId) => dialogConfirmVerification(false,
                verification.phoneId,
                "",
                "",
                verified,
                stateId)} />;
    }

    return null;
}

export {
    renderButtons,
    renderDialogs
};