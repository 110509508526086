import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import BarcodeHelper from "../../../utils/barcodeHelper.jsx";
import { GridBaseComponent, createdSort } from "../../../components/gridApiSource.jsx";
import { fetchDelete } from "../../../utils/requestsHelper.jsx";
import GridFileCell from "../../../components/cells/gridFileCell.jsx";
import GridFileTypeCell from "../../../components/cells/gridFileTypeCell.jsx";
import GridDateCell from "../../../components/cells/gridDateCell.jsx";
import GridDocumentsCardCell from "../../../components/cells/gridDocumentsCardCell.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const DocumentsControl = forwardRef(function DocumentsControl(props, ref) {
    const sizedDiv = useRef(null);

    const [confirm, setConfirm] = useState(false);
    const [objectType, setObjectType] = useState(null);
    const [deleteId, setDeleteId] = useState(0);
    const [width, setWidth] = useState(2000);

    useEffect(() => {
        if (sizedDiv.current)
            setWidth(sizedDiv.current.offsetWidth);
    }, [sizedDiv.current]);

    const canOpenCard = props.canOpenCard;
    const actionsCell = (p) =>
        p.dataItem["id"] > 0 && !p.dataItem["isReadOnly"]
            ? <GridDocumentsCardCell {...p} />
            : <td {...getLinkCellStyle(p)} />;

    const barcodeCell =
        (p) => {
            if (p.dataItem[p.field] > 0)
                return <td {...getLinkCellStyle(p)} title={window.captions
                    .PrintBarcode}>
                    <Button iconClass="dm-i dm-i-barcode"
                        fillMode="flat"
                        onClick={() => BarcodeHelper.printBarcode(p.dataItem[p.field],
                            p.dataItem["barcodeFields"])} />
                </td>;
            else
                return <td {...getLinkCellStyle(p)} />;
        };

    const removeCell = (p) => p.dataItem["canDelete"]
        ? <td {...getLinkCellStyle(p)}>
            <Button icon="delete" fillMode="flat" onClick={() => handleOnDelete(p.dataItem)} />
        </td>
        : <td {...getLinkCellStyle(p)} />;

    const columns = [];

    columns.push(<GridColumn width="47" key="fileType" field="fileType" title=" " cells={{ data: GridFileTypeCell }} />);
    columns.push(<GridColumn key="name" field="name" title={window.captions.Caption} />);
    if (width >= 800) {
        columns.push(<GridColumn width="180" key="type" field="type" title={window.captions.WordType} />);
        columns.push(
            <GridColumn width="120" key="sizeText" field="sizeText" title={window.captions.SizeInKiloBites} />);
    }

    if (width >= 1100) {
        columns.push(<GridColumn width="120"
            key="created"
            field="created"
            title={window.captions.Created}
            cells={{ data: GridDateCell }} />);
        columns.push(<GridColumn width="180"
            key="objectTypeName"
            field="objectTypeName"
            title={window.captions.ProcessObjectName} />);
    }

    if (props.isEditMode)
        columns.push(<GridColumn locked={true} width="35" key="load" field="id" title=" " cells={{ data: removeCell }} />);

    columns.push(<GridColumn locked={true} width="35" key="file" field="id" title=" " cells={{ data: GridFileCell }} />);
    if (canOpenCard)
        columns.push(<GridColumn locked={true} key="id" title=" " field="id" width="35" cells={{ data: actionsCell }} />);

    if (width >= 1100 && !window.systemConfig.isDocumentBarcodeHidden) {
        columns.push(<GridColumn locked={true} width="35" key="barCode" title=" " field="id" cells={{ data: barcodeCell }} />);
    }

    return <div ref={sizedDiv} style={props.style} className="dm-full-wh">
        <GridBaseComponent baseUrl={`${window.constants.cardSourceDocuments}/${props.source}/${props.id}/${(props.stepId ? props.stepId : 0)}`}
            sort={createdSort}
            ref={ref}
            className="dm-full-wh">
            {columns}
        </GridBaseComponent>
        <ConfirmMessage yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    </div>;

    function handleOnDelete(dataItem) {
        var objectType = dataItem["objectType"];
        var id = parseInt(dataItem["id"]);
        if (!isNaN(id)) {
            setObjectType(objectType);
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function deleteItem() {
        confirmClose();
        fetchDelete(`${window.constants.cardSourceDocuments}/${objectType}`,
            deleteId,
            () => { ref.current?.refreshDataSource() },
            () => { });
    }

    function confirmClose() {
        setObjectType(null);
        setDeleteId(0);
        setConfirm(false);
    }
})

export default DocumentsControl;