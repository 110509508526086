import React from "react";
import { useState, useEffect, useRef } from 'react';
import CardReducerFunctions from "../cardReducerFunctions.jsx";
import { renderField } from "../cardRenderFunctions.jsx";

export default function FieldsControl({ fields, isEditMode, entity, id, sources,
    phones, addresses, emails, urls, accounts,
    verificationNewPhone, dialogConfirmVerification, setFields }) {
    const prevCountRef = useRef();

    const [renderFields, setRenderFields] = useState([]);

    useEffect(() => {
        prevCountRef.fields = fields;
        setRenderFields(fields);
    }, []);

    useEffect(() => {
        const f = fields;
        const p = prevCountRef.fields;
        if (!f || !p)
            return;

        const fk = Object.keys(f);
        const fp = Object.keys(p);
        if (fk.length !== fp.length || (fk.length > 0 && fp.length > 0 && fk[0] !== fp[0])) {
            var fieldsList = f;
            for (let key in fieldsList) {
                const oldField = p[key];
                if (oldField && oldField.isEditable && !oldField.isContact)
                    fieldsList[key].oValue = oldField.value;
            }

            setRenderFields(fieldsList);
        }

        prevCountRef.fields = fields;
    }, [fields]);


    let localEntity = entity;
    if (!localEntity)
        localEntity = "Entity";

    if (!renderFields || Object.keys(renderFields).length <= 0)
        return <div />;

    const items = [];
    for (let key in renderFields) {
        if (!Object.prototype.hasOwnProperty.call(renderFields, key))
            continue;

        const fieldModel = renderFields[key];
        if (!fieldModel)
            continue;

        items.push(renderField(fieldModel,
            `${localEntity}${key}`,
            sources,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            isEditMode,
            { entity: entity, entityId: id },
            {
                verificationNewPhone,
                dialogConfirmVerification,
                addContact,
                changedValue: (e) => {
                    if (e.item !== undefined) {
                        changeContact(e);
                    } else if (e.isPassport) {
                        changePassportField(e);
                        return;
                    } else {
                        changeField(e);
                    }
                },
                fileUpload
            }));
    }

    return items;

    function shallowFields() {
        var updatedFields = [];
        for (let key in renderFields) {
            updatedFields[key] = renderFields[key];
        }
        return updatedFields;
    }

    function changeField(payload) {
        if (CardReducerFunctions.fieldEdited(renderFields, payload) && setFields) {
            setFields(shallowFields(), id, entity);
        }
    }

    function fileUpload(e) {
        if (!e.response)
            return;

        const files = e.response.response;
        if (!files || files.length === 0)
            return;

        const file = files[0];
        e.affectedFiles[0].fileName = file.fileName;
        changeField({ fieldId: e.fieldId, value: file.fileName });
    }

    function changeContact(payload) {
        if (CardReducerFunctions.contactEdited(false,
            sources,
            renderFields,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            payload) &&
            setFields) {
            setFields(renderFields, id, entity);
        }
    }

    function addContact(fieldId, typeItem) {
        if (CardReducerFunctions.contactAdded(renderFields,
            sources,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            fieldId,
            typeItem) &&
            setFields) {
            setFields(renderFields, id, entity);
        }
    }

    function changePassportField(payload) {
        if (CardReducerFunctions.passportEdited(renderFields, payload) &&
            setFields) {
            setFields(renderFields, id, entity); // TODO clone fields
        }
    }
}