import React from "react";
import { useState, useEffect, useRef } from 'react';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { TreeView } from "@progress/kendo-react-treeview";

export default function DropDownCheckedTree({ field, treeData, selectedIdList, disabled, required, style, className, onKeyPress, selectionChanged }) {
    const [displayValues, setDisplayValues] = useState([]);
    const [values, setValues] = useState([]);
    const [open, setOpen] = useState(false);
    const [allSource, setAllSource] = useState([]);
    const [source, setSource] = useState([]);
    const [loading, setLoading] = useState(true);

    let timeout = useRef(null);

    useEffect(() => {
        if (treeData)
            mountProperties();
    }, [treeData, selectedIdList]);

    const props = {}
    if (style)
        props.style = style;
    if (className)
        props.className = className;

    const withChild = allSource.find((i) => i.items && i.items.length > 0) != null;
    return withChild
        ? <MultiSelect
            data={["single"]}
            filterable
            disabled={disabled ?? false}
            required={required ?? false}
            onFilterChange={filterChange}
            value={displayValues}
            {...props}
            opened={open}
            onBlur={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onChange={onClearSelection}
            onKeyPress={onKeyPress}
            loading={loading}
            itemRender={itemRender} />
        : <MultiSelect
            data={source}
            filterable
            disabled={disabled ?? false}
            required={required ?? false}
            textField="name"
            dataItemKey="id"
            onFilterChange={filterChange}
            value={values}
            {...props}
            opened={open}
            onBlur={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            onChange={onChangeSelection}
            onKeyPress={onKeyPress}
            loading={loading} />;


    function mountProperties() {
        let idList = selectedIdList;
        if (!Array.isArray(idList) && idList) {
            idList = idList.toString().split(",").map(Number);
        }

        const data = JSON.parse(JSON.stringify(treeData));
        const newValues = [];
        if (idList && idList.length > 0 && data) {
            for (const id of idList) {
                const findElement = itemFinderHierarch(data, id);
                if (findElement) {
                    findElement.selected = true;
                    newValues.push(findElement);
                }
            }
        }

        setSource(data);
        setAllSource(data);
        setLoading(false);
        setValues(newValues);
        setDisplayValues(getDisplayValues(newValues));
        setOpen(false);
    }

    function onItemClick(event) {
        const item = event.item;
        if (item.items && item.items.length > 0) {
            item.expanded = !item.expanded;
            return;
        }

        const findElement = itemFinderHierarch(allSource, item.id);
        if (!findElement) {
            return;
        }

        const newValues = values;
        if (item.selected) {
            item.selected = false;
            const valuesIndex = newValues.indexOf(item);
            if (valuesIndex !== -1)
                newValues.splice(valuesIndex, 1);
        } else {
            item.selected = true;
            newValues.push(item);
        }

        setValues(newValues);
        setDisplayValues(getDisplayValues(newValues));

        fireChangeEvent();
    }

    function fireChangeEvent(newValues) {
        if (!selectionChanged)
            return;

        if (!newValues)
            newValues = values;

        var idList = [];
        for (const value of newValues) {
            if (value.idList) {
                for (const id of value.idList) {
                    idList.push(id);
                }
            } else {
                idList.push(parseInt(value.id));
            }
        }

        selectionChanged({
            fieldId: field,
            value: idList
        });
    }

    function getDisplayValues(stateValues) {
        var newValues = [];
        for (const value of stateValues) {
            newValues.push(value.name);
        }
        return newValues;
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function onClearSelection(event) {
        if (event.target.value.length >= displayValues.length)
            return;

        const newValues = values;
        var clearValue = event.target.value;

        for (const value of newValues) {
            if (clearValue.indexOf(value.name) >= 0)
                continue;

            value.selected = false;
            newValues.splice(newValues.indexOf(value), 1);
        }

        setValues(newValues);
        setDisplayValues(getDisplayValues(newValues));

        fireChangeEvent();
    }

    function onChangeSelection(event) {
        setValues(event.target.value);
        fireChangeEvent(event.target.value);
    }

    function itemRender(li) {
        const itemChildren = <TreeView
            data={source}
            onItemClick={onItemClick}
            onExpandChange={onExpandChange}
            expandIcons={true}
            animate={false}
            item={p => p.item.oName ?? p.item.name}
            className="dm-drop-tree" />;
        const props = li.props;
        return React.cloneElement(li, { ...props, className: "dm-drop-tree-container" }, itemChildren);
    }

    function setItemNestedName(items) {
        if (items.length <= 1)
            return;

        const item = items[0];
        const names = [];
        for (var i = 1; i < items.length; i++) {
            names.push(items[i].name);
        }

        const parentNames = names.join(' - ');
        if (!item.oName)
            item.oName = item.name;

        item.name = `${item.oName} (${parentNames})`;
    }

    function itemFinderHierarch(items, id) {
        const findResult = itemFinder(items, id);
        if (!findResult) 
            return null;

        setItemNestedName(findResult);
        return findResult[0];        
    }

    function itemFinder(items, id) {
        const item = items.find((i) => parseInt(i.id) === parseInt(id) && !(i.items && i.items.length > 0));
        if (item)
            return [item];

        for (const subItem of items) {
            if (subItem.items && subItem.items.length > 0) {
                const subFounded = itemFinder(subItem.items, id);
                if (subFounded) {
                    subItem.expanded = true;
                    subFounded.push(subItem);
                    return subFounded;
                }
            }
        }
        return null;
    }

    function filterChange(event) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setSource(event.filter.value
                ? filterData(event.filter.value, JSON.parse(JSON.stringify(allSource)))
                : allSource);
            setLoading(false);
        },
            300);

        setLoading(true);
    }

    function filterData(filter, items) {
        return items.filter(i => {
            if (i.name.toLowerCase().includes(filter.toLowerCase())) {
                i.expanded = true;
                return true;
            }

            var subItems = i.items;
            if (!(subItems && subItems.length > 0))
                return false;

            i.expanded = false;
            subItems = filterData(filter, i.items);
            if (subItems && subItems.length > 0) {
                i.items = subItems;
                i.expanded = true;
                return true;
            }
            return false;
        });
    }

}