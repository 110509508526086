import React, { useState } from "react";
import { useEffect } from 'react';
import { TreeView } from "@progress/kendo-react-treeview";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { FileIcon, defaultStyles } from "react-file-icon";
import { fetchGet, fetchPost } from '../../utils/requestsHelper.jsx';
import { fileTypeClassName, iconClassName } from "./statisticHelpers.jsx";
import ExportQueues from "../exportQueue/exportQueues.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";

export default function StatisticDialog({ exportType, cardId, cardEntity, onClose }) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [config, setConfig] = useState(null);

    const [reports, setReports] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [exportStarted, setExportStarted] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => fetchData(), [exportType, cardId, cardEntity]);

    let title = window.captions.ReportsWizard;
    if (error)
        return <Dialog title={title} onClose={close} width={550}>
            <ErrorMask inline error={error} />
        </Dialog>;

    if (!isLoaded)
        return <Dialog title={title} onClose={close} width={550}>
            <LoadingMask inline />
        </Dialog>;

    const exportId = config ? config.id : 0;
    const isDocument = exportType === "ExportDocument" ||
        exportType === "ExportDocumentItem" ||
        exportType === "ExportDocumentFolder";
    title = isDocument
        ? window.captions.DocumentsWizard
        : window.captions.ReportsWizard;

    let content = "";
    let items = isDocument ? documents : reports;
    switch (exportType) {
        case "ExportDocumentItem":
        case "ExportReportItem":
            if (items.length === 1) {
                items = items[0].items;
                if (!selected)
                    setSelected(items.find(d => d.id === exportId));
            } else {
                var itemFolder = items.find(f => f.items.find(d => d.id === exportId));
                if (itemFolder) {
                    itemFolder.expanded = true;
                    setSelected(itemFolder.items.find(d => d.id === exportId));
                }
            }
            break;
        case "ExportDocumentFolder":
        case "ExportReportFolder":
            var folder = items.find(d => d.id === exportId);
            if (folder) {
                items = folder.items;
            }
            break;
        default:
            if (items.length === 1)
                items = items[0].items;
    }

    let itemContent = null;
    if (selected) {
        const item = selected;
        const image = fileTypeClassName(item);
        const exportTitle = isDocument ? window.captions.MakeDocument : window.captions.MakeReport;
        itemContent = <div className="dm-export-start">
            <span className="dm-icon-64">
                <FileIcon extension={image} {...defaultStyles[image]} />
            </span><br />{selected.name}<br />
            <Button onClick={() => itemExport(item)}>{exportTitle}</Button>
        </div>;
    }

    if (items && items.length > 0) {
        content = <TreeView
            data={items}
            textField="name"
            animate={false}
            style={{ maxHeight: "400px" }}
            item={i => [<span className={iconClassName(i.item)} key="0" />, i.item.name]}
            expandIcons={true}
            onItemClick={onItemClick}
            onExpandChange={onExpandChange} />;
    }

    if (exportStarted) {
        content = <ExportQueues onFinish={onClose}
            inDialog
            isStarted={true} />;
        itemContent = null;
    }

    return <Dialog title={title} onClose={close} width={550} maxHeight={400}>
        {itemContent}
        {content}
    </Dialog>;

    function fetchData() {
        fetchGet(`${window.constants.statisticElements}/${cardEntity}`,
            data => {
                setReports(data.reports);
                setDocuments(data.documents);
                setConfig(config);
                setError(null);
                setIsLoaded(true);
                setExportStarted(false);
                setSelected(null);
            },
            ex => {
                setError(ex);
                setIsLoaded(true);
            }
        );
    }

    function onItemClick(event) {
        const item = event.item;
        if (item.items && item.items.length > 0) {
            item.expanded = !item.expanded;
            return;
        }

        if (selected)
            selected.selected = false;

        item.selected = true;
        setSelected(item);
    }

    function itemExport(item) {
        fetchPost(`${window.constants.statisticRender}/${item.type}/${item.id}/${cardId}`,
            [],
            () => setExportStarted(true),
            ex => {
                setError(ex);
                setIsLoaded(true);
            }
        );
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function close() {
        if (selected && exportStarted) {
            fetchPost(`${window.constants.statisticCancel}/${selected.type}/${selected.id}`,
                [],
                () => { },
                () => { });
        }

        onClose();
    }
}