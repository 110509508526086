import React, { useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Switch } from "@progress/kendo-react-inputs";
import { fetchPost } from "../../utils/requestsHelper.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogClientCopy({ clientId, onClose }) {
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [copyPhones, setCopyPhones] = useState(false);
    const [copyAddresses, setCopyAddresses] = useState(false);
    const [copyEmails, setCopyEmails] = useState(false);
    const [copyUrls, setCopyUrls] = useState(false);

    if (pending || error) {
        return <Dialog title={window.captions.DuplicateClient} onClose={() => onClose(0)} width={400
        }>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const elements = [];
    renderPair(elements,
        window.captions.Phones,
        "phone",
        () => <Switch onChange={changedCopyPhones} checked={copyPhones} />);
    renderPair(elements,
        window.captions.Addresses,
        "addresses",
        () => <Switch onChange={changedCopyAddresses} checked={copyAddresses} />);
    renderPair(elements,
        window.captions.Email,
        "emails",
        () => <Switch onChange={changedCopyEmails} checked={copyEmails} />);
    renderPair(elements,
        window.captions.Urls,
        "urls",
        () => <Switch onChange={changedCopyUrls} checked={copyUrls} />);

    return <Dialog title={window.captions.DuplicateClient} onClose={() => onClose(0)} width={400}>
        <div className="dm-container dm-no-padding">
            {elements}
        </div>
        <DialogActionsBar>
            <Button onClick={() => onClose(0)}>{window.captions.Cancel}</Button>
            <Button themeColor="primary" onClick={save}>{window.captions.Create}</Button>
        </DialogActionsBar>
    </Dialog>;


    function changedCopyPhones(e) {
        setCopyPhones(e.value);
    }

    function changedCopyAddresses(e) {
        setCopyAddresses(e.value);
    }

    function changedCopyEmails(e) {
        setCopyEmails(e.value);
    }

    function changedCopyUrls(e) {
        setCopyUrls(e.value);
    }

    function save() {
        var saveData = {
            clientId: clientId,
            copyPhones: copyPhones,
            copyAddresses: copyAddresses,
            copyEmails: copyEmails,
            copyUrls: copyUrls
        };

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(window.constants.saveCopyClient,
            saveData,
            (id) => onClose(id),
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }
}