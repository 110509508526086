import React from "react";
import { Link } from "react-router-dom";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function GridQuestionnaireCell(props) {
    const isEditable = props.dataItem["editable"] === true && props.dataItem["questionnaire"] !== true;
    if (!isEditable)
        return <td {...getLinkCellStyle(props)}></td>;

    const value = props.dataItem[props.field];
    const href = `/questionnaire/${props.questionnaireId}/${value}`;
    return <td {...getLinkCellStyle(props)}>
        <Link to={href} title={window.captions.Questionnaire}>
            <i title={window.captions.Questionnaire} className={`dm-i dm-i-edit`} />
        </Link>
    </td>;
}