import React from "react";
import { useState, useEffect } from 'react';
import { fetchGet } from "../../../utils/requestsHelper.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";

export default function StageMovementsControl(props) {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(props.source, props.id), [props.source, props.id]);

    if (error)
        return <ErrorMask error={error} />;

    if (loading)
        return <LoadingMask />;

    if (!data)
        return <div />;

    const length = (days) => `${days} ${window.captions.DayShort}`;

    const generateState = (state, i) => {
        let users = [];
        if (state.items) {
            users = state.items.length === 1
                ? <span>{state.items[0].name}</span>
                : <ol>{state.items.map((user, i) => <li key={i}>{user.name}</li>)}</ol>;
        }

        return <li key={i}>
            <div style={{ borderLeft: `4px solid #${state.color}` }}>
                <span className="spanLength">{length(state.length)}</span>
                <span>{state.dateText}</span>
                <span className="boldSpanName">{state.name}</span>
                {users}
            </div>
        </li>;
    };

    const generateStage = (stage, i) => {
        let states = [];
        if (stage.isShowStates && stage.items && stage.items.length > 0) {
            states = stage.items.map((state, s) => generateState(state, s));
        }

        return <li key={i}>
            <div>
                <h4 style={{ color: `#${stage.colorGradient}` }}>{stage.name}</h4>
                <div className="arrowStage" style={{
                    backgroundColor: `#${stage.colorGradient}`,
                    color: `#${stage.colorGradient}`
                }}
                    onClick={() => handleShowStates(stage, i)}>
                    <span className="arrowStageText">{length(stage.length)}</span>
                </div>
            </div>
            <ol className="stageMovementsStates" key="stateItems">
                {states}
            </ol>
        </li>;
    };

    const stages = data.map((item, i) => generateStage(item, i));

    return <div className="movementsContainer dm-full-wh">
        <ol className="stageMovementsStages">
            {stages}
        </ol>
    </div>;

    function fetchData(source, id) {
        setLoading(true);
        fetchGet(`${window.constants.cardSourceStageMovements}/${source}/${id}`,
            (data) => {
                setData(data);
                setLoading(false);
                setError(null);
            },
            data => {
                setLoading(false);
                setError(data);
            });
    }

    function handleShowStates(stage, index) {
        if (!stage)
            return;
        data[index].isShowStates = !stage.isShowStates;
        setData([...data]);
    }
}