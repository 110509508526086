import React from "react";
import { useState, useEffect } from 'react';
import { ListView } from "@progress/kendo-react-listview";
import { EditListViewItem } from "./editListViewItem.jsx";

export default function FieldBlockComponent({ fieldId, blockDict }) {
    const [items, setItems] = useState([]);
    const [id, setId] = useState(undefined);

    useEffect(() => {
        if (fieldId)
            fetchData();
    }, [fieldId]);

    return <ListView data={items} item={getFieldBlockItem} />;

    function fetchData() {
        setId(fieldId);
        setItems(blockDict);
    }

    function getFieldBlockItem(item, index) {
        return <EditListViewItem
            key={`ListItem_${item.itemId}_${index}`}
            item={item}
            isFieldBlock={true}
            fieldId={id} />;
    }
}