import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        newMessage: null,
        unreadMessageCount: 0
    },
    reducers: {
        pushMessage: (state, action) => {
            var message = action.payload.message;
            if (!message)
                return;

            if (!action.payload.isPageActive) {
                state.unreadMessageCount = state.unreadMessageCount + 1;
                return;
            }

            state.newMessage = message;
        },
        clearMessage: (state) => {
            state.newMessage = null;
        },
        updateUnreadMessageCount: (state, action) => {
            state.unreadMessageCount = action.payload;
        }
    }
})

export const {
    pushMessage,
    clearMessage,
    updateUnreadMessageCount
} = chatSlice.actions

export default chatSlice.reducer