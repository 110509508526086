import React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { selectedBlockChanged } from "./collateralSchemaReducer.jsx";

function TooltipBlock(props) {
    const itemState = props.itemState;
    if (itemState === null)
        return null;

    if (itemState.stateType === -1) {
        return <div>
            <h6 style={{ textAlign: "center" }}>{props.title}</h6>
            <div className="dm-container">
                <div style={{ height: 10, width: 10, background: itemState.stateColor }} />
                <strong>{`${window.captions.Stage}:`}</strong>
                <div>{itemState.stageName}</div>
                <strong>{`${window.captions.State}:`}</strong>
                <div>{itemState.stateName}</div>
            </div>
        </div>;
    } else if (itemState.stateType >= 0) {
        const incomingBlocks = [];
        const incomingSchemas = [];
        let index = 0;

        for (let key in itemState.collateralBlocks) {
            if (!Object.prototype.hasOwnProperty.call(itemState.collateralBlocks, key))
                continue;
            const item = itemState.collateralBlocks[key];
            const block = <tr key={index}>
                <td>
                    <div style={{ height: 10, width: 10, background: item.stateColor }} />
                </td>
                <td>
                    <div>{item.stageName}</div>
                </td>
                <td>
                    <div>{item.stateName}</div>
                </td>
                <td>
                    <div>{item.count}</div>
                </td>
            </tr>;
            incomingBlocks.push(block);
            index++;
        }

        for (let key in itemState.schemas) {
            if (!Object.prototype.hasOwnProperty.call(itemState.schemas, key))
                continue;
            const item = itemState.schemas[key];
            const schema =
                <tr key={index}>
                    <td>
                        <div>{item.stateName}</div>
                    </td>
                    <td>
                        <div>{item.count}</div>
                    </td>
                </tr>;
            incomingSchemas.push(schema);
            index++;
        }

        return <div>
            <h6 style={{ textAlign: "center" }}>{props.title}</h6>
            <div>
                <strong>{`${window.captions.State}: `}</strong>{itemState.stateTypeName}
            </div>{itemState.stateType === 1 || itemState.stateType === 3
                ? <div className="dm-container">
                    <div style={{
                        height: 10,
                        width: 10,
                        background: itemState
                            .stateColor
                    }} />
                    <strong>{`${window.captions.Stage}:`}</strong>
                    <div>{itemState.stageName}</div>
                    <strong>{`${window.captions.State}:`}</strong>
                    <div>{itemState.stateName}</div>
                </div>
                : ""}
            {incomingBlocks.length > 0 || incomingSchemas.length > 0 ? <br /> : ""}
            {incomingBlocks.length > 0
                ? <div>
                    <h6 style={{ textIndent: "20px" }}>{window.captionsDynamic.ResourceBlocks}</h6>
                    <table className="table table-sm" style={{ color: "#fff" }}>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{window.captions.Stage}</th>
                                <th scope="col">{window.captions.State}</th>
                                <th scope="col">{window.captions.FormulaCount}</th>
                            </tr>
                        </thead>
                        <tbody>{incomingBlocks}</tbody>
                    </table>
                </div>
                : ""}

            {incomingSchemas.length > 0
                ? <div>
                    <h6 style={{ textIndent: "20px" }}>{window.captionsDynamic.ResourceSchemas}</h6>
                    <table className="table table-sm" style={{ color: "#fff" }}>
                        <thead>
                            <tr>
                                <th scope="col">{window.captions.State}</th>
                                <th scope="col">{window.captions.FormulaCount}</th>
                            </tr>
                        </thead>
                        <tbody>{incomingSchemas}</tbody>
                    </table>
                </div>
                : ""}
        </div>;
    } else
        return null;
}

export default {
    convertSchemas(data) {
        const items = [];
        for (let key in data) {
            if (!Object.prototype.hasOwnProperty.call(data, key))
                continue;

            const item = data[key];
            const chat = {
                id: item.id,
                parentId: item.parentId,
                name: item.name,
                items: item.items
            };
            items.push(chat);
        }
        return items;
    },

    getSchemaStateName(state) {
        switch (state) {
            case 0:
                return window.captions.FullyReservedState;
            case 1:
                return window.captions.FullyReservedByOneState;
            case 2:
                return window.captions.PartiallyReservedState;
            case 3:
                return window.captions.PartiallyReservedByOneState;
            case 4:
                return window.captions.FreeState;
            case 5:
                return window.captions.SchemaIsClear;
            default:
                return window.captions.Unknown;
        }
    },

    convertBlocks(data) {
        const items = [];
        for (let key in data) {
            if (!Object.prototype.hasOwnProperty.call(data, key))
                continue;

            const item = data[key];
            let state = null;

            if (item.tid !== 1) {
                state = {
                    schemas: [],
                    collateralBlocks: [],
                    stageId: item.state.stageId,
                    stageName: item.state.stageName,
                    stateId: item.state.stateId,
                    stateName: item.state.stateName,
                    stateColor: item.state.stateColor,
                    isDefaultState: item.state.isDefaultState,
                    stateType: item.state.stateType,
                    stateTypeName: this.getSchemaStateName(item.state.stateType)
                };

                for (let itemKey in item.state.schemaBlocksStates) {
                    if (!Object.prototype.hasOwnProperty.call(item.state.schemaBlocksStates, itemKey))
                        continue;

                    const itemState = item.state.schemaBlocksStates[itemKey];

                    if (itemState.stateType === -1) {
                        const currentBlock = state.collateralBlocks.find((i) => i.stateId === itemState.stateId);
                        if (currentBlock === undefined || currentBlock === null) {
                            state.collateralBlocks.push({
                                stageId: itemState.stageId,
                                stageName: itemState.stageName,
                                stateId: itemState.stateId,
                                stateName: itemState.stateName,
                                stateColor: itemState.stateColor,
                                isDefaultState: itemState.isDefaultState,
                                stateType: itemState.stateType,
                                count: 1
                            });
                        } else {
                            currentBlock.count++;
                        }
                    } else {
                        const currentSchema = state.schemas.find((i) => i.stateType === itemState.stateType);

                        if (currentSchema === undefined || currentSchema === null) {
                            state.schemas.push({
                                stateType: itemState.stateType,
                                stateName: this.getSchemaStateName(itemState.stateType),
                                stateColor: itemState.stateColor,
                                count: 1
                            });
                        } else {
                            currentSchema.count++;
                        }
                    }
                }
            }

            const block = {
                id: item.id,
                pSid: item.pSid,
                cid: item.cid,
                sid: item.sid,
                tid: item.tid,
                state: state,
                schemaName: item.schemaName,
                blockName: item.blockName,
                collateralModel: item.collateralModel,
                backgroundImageValue: item.backgroundImageValue,
                backgroundImage: item.backgroundImage,
                positionLeft: item.positionLeft,
                positionTop: item.positionTop,
                width: item.width,
                height: item.height,
                description: item.description,
                isSelected: false
            };
            items.push(block);
        }
        return items;
    },

    convertCollateralModels(data) {
        const blockDataModel = {
            collateralId: data.collateralId,
            eNumber: data.eNumber,
            author: data.authorName,
            type: data.type,
            stageName: data.stageName,
            stateName: data.stateName
        };

        return blockDataModel;
    },

    getBackground(item) {
        if (item.tid === 2)
            return item.state.stateColor;

        switch (item.state.stateType) {
            case 0:
                return "linear-gradient(to left top, red 33%, yellow 35% 65%, blue 67%)";
            case 1:
                return item.state.stateColor;
            case 2:
                {
                    let stripedGradient = "";
                    const stripedLimit = (item.width / 20) - 1;
                    const stripedWidth = 50 / stripedLimit;
                    for (let i = 0; i < stripedLimit; i += 2) {
                        if ((i + 2) * stripedWidth < 51)
                            stripedGradient += `, yellow ${i * stripedWidth}% ${(i + 1) * stripedWidth}%, red ${(i + 1) * stripedWidth}% ${(i + 2) * stripedWidth}%`;
                    }

                    const backgroundColor = `linear-gradient(to left top ${stripedGradient}, white 51%)`;
                    return backgroundColor;
                }
            case 3:
                return `linear-gradient(to left top, ${item.state.stateColor} 49%, white 51%)`;
            case 4:
                return "white";
            case 5:
                return "linear-gradient(to right top, white 47%, red 49% 51%, white 53%)";
            default:
                return null;
        }
    },

    drawBlock(store, dispatch, item) {
        if (item.tid === 1)
            return <img src={item.backgroundImageValue} style={{ height: item.height, width: item.width }} alt={item
                .backgroundImage} />;

        const className = "sch-block";
        const blockContentClass = "block-content";
        let selectedBlockContentClass = "";
        let selectedBlockClass = "";
        const selectedBlock = store.selectedBlock;
        const selectedSchema = store.selectedSchema;
        const backgroundColor = this.getBackground(item);
        if (selectedBlock !== null && item.id === selectedBlock.id) {
            selectedBlockClass += " selected-block";
            selectedBlockContentClass = "selected-block-content";
        }

        return <div className={`sch-border-block ${item.tid === 0 ? "schema-block" : "collateral-block"} ${selectedBlockContentClass}`} style={{ height: item.height, width: item.width }}>
            <div className={selectedBlockClass}>
                <div className="sch-block-container-item">
                    <div className={className} style={{ background: `${backgroundColor}` }} />
                    <Tooltip content={() => <TooltipBlock title={item.blockName} itemState={item.state} />}>
                        <div className={blockContentClass} title="!" onClick={(e) => dispatch(selectedBlockChanged(e, item, selectedSchema))}>
                            <strong title={item.blockName}>{item.blockName}</strong>
                        </div>
                    </Tooltip >
                </div>
            </div>
        </div>;
    }
}