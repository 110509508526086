import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { fetchGet } from "../../../utils/requestsHelper.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import GridLoanCardCell from "../../../components/cells/gridLoanCardCell.jsx";
import gridFunctions from "../../../components/gridFunctions.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import GridEmptyCell from "../../../components/cells/gridEmptyCell.jsx";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

export default function LinkedDataControl(props) {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [], group: [{ field: "Type" }] });
    const [pending, setPending] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState(null);

    const viewLoan = useSelector(state => state.header.model.access.viewLoan);

    useEffect(() => {
        fetchColumns();
        fetchData(props.id, props.source, dataState);
    }, [props.id, props.source]);

    if (error) {
        return <ErrorMask error={error} />;
    }

    if (!isLoaded) {
        return <LoadingMask />;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    const actionsCell = (p) => {
        if (p.rowType === "groupHeader")
            return null;

        return props.canOpenCard && p.rowType === "data"
            ? <GridLoanCardCell {...p} />
            : <td {...getLinkCellStyle(p)} />;
    };

    const archiveCell = (p) => {
        if (p.rowType === "groupHeader")
            return null;

        return p.dataItem[p.field]
            ? <td {...getLinkCellStyle(p)} title={window.captionsDynamic
                .StatLoanArchived}>
                <i className={`dm-i dm-i-archive`} />
            </td>
            : <td {...getLinkCellStyle(p)} />;
    };

    const isSelfCell = (p) => {
        if (p.rowType === "groupHeader")
            return null;

        return p.dataItem[p.field]
            ? <td {...getLinkCellStyle(p)} title={window.captionsDynamic.ThisLoan}>
                <i className={`dm-i dm-i-exclamation-triangle dm-error-im`} />
            </td>
            : <td {...getLinkCellStyle(p)} />;
    };

    const fieldColumns = gridFunctions.renderGridColumns(columns);

    return <div className="dm-full-wh">
        <Grid className="dm-full-wh"
            pageable={true}
            resizable={true}
            total={total}
            data={data}
            {...dataState}
            onExpandChange={expandChange}
            onDataStateChange={handleDataStateChange}
            expandField="expanded">
            {fieldColumns}
            <GridColumn title=" " field="id" cells={{ data: GridEmptyCell }} />
            <GridColumn field="IsSelf" title=" " width="35" cells={{ data: isSelfCell }}
                groupable={false} locked={true} />
            <GridColumn field="IsArchived" title=" " width="35" cells={{ data: archiveCell }}
                groupable={false} locked={true} />
            {viewLoan
                ? <GridColumn field="LoanId" title=" " width="35" cells={{ data: actionsCell }}
                    groupable={false} locked={true} />
                : null}
        </Grid>
        {loadingPanel}
    </div>;

    function fetchColumns() {
        fetchGet(`${window.constants.cardSourceLinkedDataColumns}`,
            (data) => {
                setColumns(data);
                setIsLoaded(true);
                setError(null);
            },
            (ex) => setError(ex));
    }

    function fetchData(id, source, dataState) {
        const hasGroups = dataState.group && dataState.group.length;
        setPending(true);
        fetchGet(
            `${window.constants.cardSourceLinkedData}/${source}/${id}?${toDataSourceRequestString(dataState)
            }`,
            ({ data, total }) => {
                setData(hasGroups ? translateDataSourceResultGroups(data) : data);
                setError(null);
                setPending(false);
                setTotal(total);
                setDataState(dataState);
            },
            (ex) => setError(ex));
    }

    function expandChange(event) {
        event.dataItem[event.target.props.expandField] = event.value;
        setDataState(dataState);;
    }

    function handleDataStateChange(changeEvent) {
        if (changeEvent.dataState.group && gridFunctions.hasDuplicatesGroups(changeEvent.dataState.group))
            return;
        fetchData(props.id, props.source, changeEvent.dataState);
    }

}