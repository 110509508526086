import { fetchGet, fetchPost } from "../utils/requestsHelper.jsx";
import "isomorphic-fetch";

import {
    isPushNotificationSupported,
    askUserPermission,
    createNotificationSubscription,
    destroyNotificationSubscription,
    getUserSubscription

} from "./push-notifications.jsx";

export function usePushNotifications() {
    if (!isPushNotificationSupported())
        return;

    getUserSubscription((existingSubscription) => {
            if (existingSubscription) {
                fetchPost(window.constants.pushSubscription, existingSubscription, () => {}, () => {});
                return;
            }

            askUserPermission((consent) => {
                    if (consent !== "granted") {
                        console.log("You denied the consent to receive notifications");
                        return;
                    }

                    fetchGet(window.constants.pushGetKey,
                        (key) => {
                            createNotificationSubscription(key,
                                (subscription) => {
                                    fetchPost(window.constants.pushSubscription,
                                        subscription,
                                        (response) => {
                                            if (response === true)
                                                console.log("subscribed");
                                            else {
                                                console.log("subscribe failed", response);
                                                destroyNotificationSubscription(null, null);
                                            }
                                        },
                                        console.log);
                                },
                                (err) => {
                                    console.log("Couldn't create the notification subscription",
                                        err,
                                        "name:",
                                        err.name,
                                        "message:",
                                        err.message,
                                        "code:",
                                        err.code);
                                });
                        },
                        (err) => {
                            err.then(console.log);
                        });


                },
                console.log);
        },
        console.log);
}