import React from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";

export default function AddressBaseFieldControl({ columns }) {
    return <>
        <div>
            <Checkbox label="Страна"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 136, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Индекс"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 133, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Область"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 127, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Район"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 142, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Тип населенного пункта"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 8, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Населенный пункт"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 50, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Тип улицы"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 108, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Улица"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 141, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Тип дома"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 117, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Дом"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 154, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Корпус дома"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns} data={columns}
                style={{ margin: 2, marginLeft: 92, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Квартира"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 117, height: 30, width: 550 }} />
        </div>
        <div>
            <Checkbox label="Коментарий"
                style={{ margin: 2 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 2, marginLeft: 97, height: 30, width: 550 }} />
        </div>
    </>;
}