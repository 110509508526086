import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPost, fetchGet } from "../../utils/requestsHelper.jsx";
import { getToken, clearAuth } from "../../utils/authHelper.jsx";
import { pingInterval, eventMessage, notifyTypes, entitySources, openParameters, tokenRefreshInterval } from
    "../../utils/systemConstants.jsx";

import {
    userBlockedChanged,
    showIncomingCallDialog,
    showTaskToCloseDialog,
    loginUpdate,
    logout
} from './headerReducer.jsx';

import { pushMessage } from "../chat/chatReducer.jsx";
import { cardAccessChanged, cardLoad } from "../cards/cardEntityReducer.jsx";

export default function MessageWorker() {
    const dispatch = useDispatch()
    const pingTimer = useRef(null);

    const cardModelRef = useRef(null);
    const userRef = useRef(null);

    const cardModel = useSelector(state => state.cardEntity.model);
    const user = useSelector(state => state.header.model);

    useEffect(() => {
        pingTimer.current = setInterval(pingTimeour, pingInterval);

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener(eventMessage, processMessage);
        }

        return function cleanup() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener(eventMessage, processMessage);
            }

            clearTimeout(pingTimer.current);
        };
    }, []);

    useEffect(() => { cardModelRef.current = cardModel; }, [cardModel]);
    useEffect(() => { userRef.current = user; }, [user]);

    return null;

    function processMessage(event) {
        switch (event.data.type) {
            case notifyTypes.chat:
                console.log('Event Process: chat');
                if (userRef.current && userRef.current.access.chat)
                    dispatch(pushMessage({ message: JSON.parse(event.data.data), isPageActive: window.document.location.pathname.startsWith("/chat") }));
                else
                    console.log(`Have not access to chat`);
                break;
            case notifyTypes.card:
                console.log('Event Process: card');
                pushCardOpen(event.data.data);
                break;
            case notifyTypes.incomingCall:
                console.log('Event Process: vincomingCall');
                dispatch(showIncomingCallDialog({ isShow: true, callId: event.data.data }));
                break;
            case notifyTypes.taskToClose:
                console.log('Event Process: taskToClose');
                dispatch(showTaskToCloseDialog(JSON.parse(event.data.data)));
                break;
            case notifyTypes.cardRestriction:
                console.log('Event Process: cardRestriction');
                pushCardRestriction(JSON.parse(event.data.data));
                break;
            case notifyTypes.userBlockChanged:
                console.log('Event Process: userBlockChanged');
                pushUserBlockChanged(parseInt(event.data.data) === 1);
                break;
            case notifyTypes.logout:
                console.log('Event Process: logout');
                clearAuth();
                dispatch(logout(false));
                break;
            case notifyTypes.removeCard:
                console.log('Event Process: removeCard');
                pushCardRemoved(JSON.parse(event.data.data));
                break;
            default:
                console.log(`Event Process ignore: ${event.data.type}`);
                break;
        }
    }

    function pushUserBlockChanged(block) {
        dispatch(userBlockedChanged(block));
    }

    function pushCardRemoved(data) {
        var model = cardModelRef.current;
        if (model && (data.Source === model.source && data.IdList.find((id) => id === model.id)
            || data.Source === entitySources.client && model.source === entitySources.loan && data.IdList.find((id) => id === model.clientId)))
            dispatch(cardLoad(model.id, model.source));
    }

    function pushCardRestriction(access) {
        var model = cardModelRef.current;
        if (model && access.Source === model.source && access.Id === model.id)
            dispatch(cardAccessChanged(access));
    }

    function pushCardOpen(data) {
        const item = JSON.parse(data);
        if (!item)
            return;

        const openCall = {
            workItemId: item.Options.WorkItemId,
            dialId: item.Options.DialId,
            isContactOpened: item.Options.IsContactOpened,
            contactResultId: item.Options.ContactResultId,
            phone: item.Options.PhoneModel,
            isNewCard: false
        };
        sessionStorage.setItem(openParameters, JSON.stringify(openCall));
        const loanId = new Number(item.Id);
        const clientId = new Number(item.Cid);
        if (!isNaN(loanId) && loanId > 0)
            window.open(`/card/loan/${loanId}`);
        else if (!isNaN(clientId) && clientId > 0)
            window.open(`/card/client/${clientId}`);
    }

    function pingTimeour() {
        if (!getToken())
            return;

        const refreshThreshold = (new Date).getTime() + tokenRefreshInterval;
        if (userRef.current && userRef.current.token && refreshThreshold > userRef.current.tokenExpires) {
            fetchGet(window.constants.tokenRefresh,
                (tokens) => {
                    dispatch(loginUpdate(tokens));
                },
                () => {
                    console.log("token refresh error");
                    dispatch(logout(false));
                });

            return;
        }

        fetchPost(window.constants.pushPing,
            null,
            (success) => {
                if (!success) dispatch(logout(false));
            },
            () => dispatch(logout(false)));
    }
}