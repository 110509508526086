import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { GridBaseComponent } from "../../../components/gridApiSource.jsx";
import { entitySources } from "../../../utils/systemConstants.jsx";
import GridClientCardCell from "../../../components/cells/gridClientCardCell.jsx";
import GridContactInfo from "../../../components/cells/gridContactInfo.jsx";
import { fetchDelete } from "../../../utils/requestsHelper.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const LinkedPersonsControl = forwardRef(function LinkedPersonsControl(props, ref) {
    const startFilter = window.systemConfig.isShowLinkedClientsWithPositive
        ? getFilterByField("ClientTypePositive", "eq", true)
        : null;

    const sizedDiv = useRef(null);
    const prevIsEditMode = useRef(false);

    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [linkSource, setLinkSource] = useState(0);
    const [width, setWidth] = useState(2000);

    const access = useSelector(state => state.header.model.access);

    useEffect(() => {
        if (prevIsEditMode.current == props.isEditMode)
            return;

        prevIsEditMode.current = props.isEditMode;
        filter();
    }, [props.isEditMode]);

    useEffect(() => {
        if (sizedDiv.current)
            setWidth(sizedDiv.current.offsetWidth);
    }, [sizedDiv.current]);

    const actionsCell = (p) => <GridClientCardCell {...p} />;

    const viewCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="menu" fillMode="flat" onClick={() => props.edit(p.dataItem["id"],
                p.dataItem["linkEntity"],
                p.dataItem["linkCardId"])} />
        </td>;

    const typeCell = (p) => {
        switch (p.dataItem["linkEntity"]) {
            case entitySources.clientToLoans:
                return <td {...getLinkCellStyle(p)} title={window.captionsDynamic
                    .ClientToLoan}>
                    <i className="dm-i dm-i-user-friends" />
                </td>;
            case entitySources.clientToClients:
                return <td {...getLinkCellStyle(p)} title={window.captions
                    .ClientToClient}>
                    <i className="dm-i dm-i-user-tie" />
                </td>;
            case entitySources.collateralOwner:
                return <td {...getLinkCellStyle(p)} title={window
                    .captionsDynamic.CollateralsOwners}>
                    <i className="dm-i dm-i-hotel" />
                </td>;
            default:
                return <td {...getLinkCellStyle(p)} />;
        }
    };

    const removeCell =
        (p) => {
            if (p.dataItem["canDeleteLinked"])
                return <td {...getLinkCellStyle(p)}>
                    <Button icon="delete" fillMode="flat" onClick={() => handleOnDelete(p.dataItem)
                    } />
                </td>;
            else
                return <td {...getLinkCellStyle(p)} />;
        };

    const viewClient = access.viewClient;
    const deleteLinkedClient = access.deleteLinkedClient;
    const columns = [];
    columns.push(<GridColumn key="type" field="id" title=" " width="35" cells={{ data: typeCell }} />);
    columns.push(<GridColumn key="name" field="name" title={window.captions.Caption} />);

    if (width >= 1100) {
        columns.push(<GridColumn key="contact" field="id" title={window.captions.MainTabCardContact} width="450"
            cells={{ data: (p) => <GridContactInfo {...p} sources={props.sources} /> }} />);
    }

    columns.push(
        <GridColumn key="clientTypeName" field="clientTypeName" title={window.captions.WordType} width={width >=
            800
            ? 240
            : 140} />);

    if (deleteLinkedClient && props.isEditMode)
        columns.push(<GridColumn locked={true} key="remove" field="id" title=" " width="35" cells={{ data: removeCell }} />);

    if (width >= 800 && viewClient)
        columns.push(<GridColumn locked={true} key="view" field="id" title=" " width="35" cells={{ data: viewCell }} />);

    if (props.isRedirectAllowed !== false && viewClient)
        columns.push(<GridColumn locked={true} key="action" field="id" title=" " width="35" cells={{ data: actionsCell }} />);

    return <div ref={sizedDiv} style={props.style} className="dm-full-wh">
        <GridBaseComponent baseUrl={`${window.constants.cardSourceLinkedPersons}/${props.source}/${props.id}`}
            filter={startFilter}
            ref={ref} className="dm-full-wh">
            {columns}
        </GridBaseComponent>
        <ConfirmMessage yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    </div>;

    function filter() {
        var filterPositive = window.systemConfig.isShowLinkedClientsWithPositive && !props.isEditMode;
        if (ref.current) {
            if (filterPositive)
                ref.current.filterByField("ClientTypePositive", "eq", true);
            else
                ref.current.filterByField(null, null, null);
        }
    }

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem["id"]);
        var linkSource = dataItem["linkEntity"];
        if (!isNaN(id)) {
            setLinkSource(linkSource);
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function deleteItem() {
        confirmClose();
        fetchDelete(
            `${window.constants.cardSourceLinkedPersons}/${props.source}/${props.id}/${linkSource}`,
            deleteId,
            () => filter(),
            () => { });
    }

    function confirmClose() {
        setLinkSource(0);
        setDeleteId(0);
        setConfirm(false);
    }
})

export default LinkedPersonsControl;