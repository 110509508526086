import React from "react";
import { useState, useEffect } from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { alternatingItemFinder as itemFinder } from "../../utils/arrayHelper.jsx";

export default function DropDownAlternating({ treeData, treeLabels, selectedId, required, placeholder, style, className, field, selectionChanged, selectionCanceled }) {
    const [id, setId] = useState(0);
    const [selected, setSelected] = useState([]);
    const [source, setSource] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => mountProperties(), [treeData, selectedId]);

    let valid = true;
    if (required && (!id || id === 0)) {
        valid = false;
    }

    const firstValue = selected.length > 0 ? selected[0] : null;
    var controls = [];
    var level = 0;
    selected.forEach(function (item) {
        if (!item.items || item.items.length === 0)
            return;

        level++;
        var localLevel = level;
        var value = null;
        if (selected.length > localLevel)
            value = selected[localLevel];

        var controlClass;
        if (labels.length > localLevel) {
            controls.push(<div key={`label${localLevel}`} className="dm-label">
                <div>{labels[localLevel]}</div>
            </div>);
            controlClass = "dm-card-editor";
        } else {
            controlClass = "dm-card-editor-full";
        }

        controls.push(<DropDownList className={controlClass}
            textField="name"
            key={localLevel}
            defaultItem={{ name: placeholder ? placeholder : "..." }}
            dataItemKey="id"
            valid={valid}
            data={item.items}
            value={value}
            onChange={e => handleChange(e.target.value, localLevel, selected)} />);
    });

    const rootLabel = labels.length > 0 ? <div className="dm-label">
        <div>{labels[0]}</div>
    </div> : null;

    let rootControlClass;
    if (rootLabel) {
        className = className ? className + " dm-container" : "dm-container";
        rootControlClass = "dm-card-editor";
    } else {
        rootControlClass = "dm-card-editor-full";
    }

    const props = {};
    if (style)
        props.style = style;
    if (className)
        props.className = className;

    return <div {...props}>
        {rootLabel}
        <DropDownList className={rootControlClass}
            textField="name"
            dataItemKey="id"
            level={0}
            valid={valid}
            data={source}
            value={firstValue}
            onChange={e => handleChange(e.target.value, 0, selected)} />
        {controls}
    </div>;

    function mountProperties() {
        const id = selectedId ? selectedId : 0;
        const data = JSON.parse(JSON.stringify(treeData));
        const labels = treeLabels
            ? JSON.parse(JSON.stringify(treeLabels))
            : [];
        let firstSelected = [];
        if (data) {
            const findElement = itemFinder(data, (i) => i.id === id && !(i.items && i.items.length > 0));
            if (findElement) {
                firstSelected = findElement;
            }
        }

        setId(id);
        setSource(data);
        setLabels(labels);
        setSelected(firstSelected);
    }

    function handleChange(item, level, itemsSelected) {
        var newSelected = [...itemsSelected];
        var selectedLength = newSelected.length;

        for (let index = level; index < selectedLength; index++) {
            newSelected.splice(level, 1);
        }

        newSelected.push(item);
        setSelected(newSelected);

        if (!item.items || item.items.length === 0) {
            if (selectionChanged)
                selectionChanged({
                    fieldId: field,
                    value: item.id,
                    selected: newSelected
                });

            setId(item.id);
            return;
        }

        setId(0);

        if (item.items.length !== 1) {
            if (id > 0 && selectionCanceled) {
                selectionCanceled({
                    fieldId: field,
                    value: 0,
                    selected: newSelected
                });
            }
            return;
        }

        handleChange(item.items[0], level + 1, newSelected);
    }
}