import React, { useState } from "react";
import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import DrawerMenu from "../../../components/drawerMenu.jsx";
import { entitySourcesNames, getUiSources } from "../../../utils/systemConstants.jsx";
import SearchFieldEditorControl from "./searchFieldEditorControl.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { getUiLang } from "../../../utils/authHelper.jsx";
import { fetchGet, fetchPost } from '../../../utils/requestsHelper.jsx';
import {
    TreeView,
    moveTreeViewItem,
    TreeViewDragAnalyzer,
    TreeViewDragClue,
} from "@progress/kendo-react-treeview";

export default function AdminSearchSettings() {
    const navigate = useNavigate();
    const params = useParams();

    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [isShowEditor, setIsShowEditor] = useState(false);
    const [needReload, setNeedReload] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const [items, setItems] = useState([]);
    const [fields, setFields] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);

    const [error, setError] = useState("");
    const [entity, setEntity] = useState("");

    let dragClue = useRef(null);

    const isMobile = useSelector(state => state.header.isMobile);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.ConfigSearch}`;
    }, []);

    useEffect(() => fetchData(params.entity), [params.entity]);
    useEffect(() => fetchElement(params.id), [params.id]);

    useEffect(() => {
        if (!needReload)
            return;

        setNeedReload(false);
        fetchData(params.entity);
    }, [needReload]);

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    const leftMenu = <DrawerMenu items={renderMenuItems()}
        align="left"
        onSelect={onSelect}>
    </DrawerMenu>;
    let editorControl = [];
    let filterGroups = [];
    let sortFields = [];
    const tab = selectedTab;
    if (tab === 0) {
        filterGroups = renderEditFieldsTreeView(items, false);
        if (isShowEditor) {
            editorControl = <SearchFieldEditorControl
                field={selectedItem}
                formulas={data.formulas}
                sources={data.fieldSources}
                compareTypes={data.compareTypes}
                isGroup={isGroup}
                addField={(i) => addField(i)}
                remove={(i) => remove(i.id, i.isGroup, params.entity)}
                onSave={(i) => onSave(i)}
                onClose={() => onCloseEditor()} />;
        }
    }
    else if (tab === 1) {
        sortFields = renderEditFieldsTreeView(fields, true);
    }
    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.ConfigSearch}</h1>
        <div className="dm-under-title-content">
            {leftMenu}
            <TabStrip selected={tab} onSelect={(e) => handleSelectTab(e.selected)}>
                <TabStripTab title={window.captions.General}>
                    <div className="dm-full-height dm-container">
                        <div className="dm-size-33 dm-full-height" style={{ paddingRight: "16px" }}>
                            {filterGroups}
                        </div>
                        <div className="dm-size-66 dm-full-height">
                            {editorControl}
                        </div>
                    </div>
                </TabStripTab>
                <TabStripTab title={getTitle()}>
                    {sortFields}
                </TabStripTab>
            </TabStrip>
        </div>
    </div>;

    function getTitle() {
        const entity = params.entity;
        if (entity === entitySourcesNames.loan)
            return window.captionsDynamic.ConfigSearchLoan;
        if (entity === entitySourcesNames.client)
            return window.captions.ConfigSearchClient;
        if (entity === entitySourcesNames.process)
            return window.captions.ConfigSearchProcess;
        if (entity === entitySourcesNames.collateral)
            return window.captionsDynamic.Collaterals;
        if (entity === entitySourcesNames.callRecords)
            return window.captions.ConfigSearchCallRecords;
        if (entity === entitySourcesNames.documents)
            return window.captions.ConfigSearchDocuments;
    }

    function renderMenuItems() {
        return [
            {
                text: window.captionsDynamic.ConfigSearchLoan,
                route: `/admin/searchSettings/loan`,
                selected: entity === entitySourcesNames.loan
            },
            {
                text: window.captions.ConfigSearchClient,
                route: `/admin/searchSettings/client`,
                selected: entity === entitySourcesNames.client
            },
            {
                text: window.captions.ConfigSearchProcess,
                route: `/admin/searchSettings/process`,
                selected: entity === entitySourcesNames.process
            },
            {
                text: window.captionsDynamic.Collaterals,
                route: `/admin/searchSettings/collateral`,
                selected: entity === entitySourcesNames.collateral
            },
            {
                text: window.captions.ConfigSearchCallRecords,
                route: `/admin/searchSettings/callRecords`,
                selected: entity === entitySourcesNames.callRecords
            },
            {
                text: window.captions.ConfigSearchDocuments,
                route: `/admin/searchSettings/documents`,
                selected: entity === entitySourcesNames.documents
            },
            { separator: true },
            {
                id: 0,
                text: window.captions.AddGroup,
                icon: "add"
            },
            {
                id: -1,
                text: window.captions.Save,
                icon: "save"
            }
        ];
    }

    function onItemClick(event) {
        if (selectedItem)
            selectedItem.isSelected = false;
        //setSelectedItem({ ...selectedItem, isSelected: false });

        event.item.isSelected = true;
        fetchElement(event.item.id);
    };

    function onItemClickSort(event) {
        if (selectedItem)
            selectedItem.isSelected = false;
        //setSelectedItem({ ...selectedItem, isSelected: false });

        event.item.isSelected = true;
        searchLoadSortElement(event.item.id);
    };

    function getSiblings(itemIndex, result) {
        const separator = "_";
        const indices = itemIndex.split(separator).map((index) => Number(index));
        for (let i = 0; i < indices.length - 1; i++) {
            result = result[indices[i]].items || [];
        }
        return result;
    };

    function getClueClassName(event) {
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
        const { itemHierarchicalIndex: itemIndex } = eventAnalyzer.destinationMeta;
        if (eventAnalyzer.isDropAllowed) {
            const separator = "_";
            switch (eventAnalyzer.getDropOperation()) {
                case "child":
                    if (event.item.isGroup)
                        return "k-i-cancel";
                    return "k-i-plus";
                case "before":
                    return itemIndex === "0" || itemIndex.endsWith(`${separator}0`)
                        ? "k-i-insert-up"
                        : "k-i-insert-middle";
                case "after": {
                    const siblings = getSiblings(itemIndex, data.items);
                    const lastIndex = Number(itemIndex.split(separator).pop());
                    return lastIndex < siblings.length - 1
                        ? "k-i-insert-middle"
                        : "k-i-insert-down";
                }
                default:
                    break;
            }
        }
        return "k-i-cancel";
    }

    function renderEditFieldsTreeView(items, sortTree) {
        return <div>
            <TreeView
                data={items}
                onItemClick={sortTree ? onItemClickSort : onItemClick}
                onExpandChange={onExpandChange}
                animate={false}
                selectField="isSelected"
                textField="name"
                expandIcons={true}
                draggable={true}
                onItemDragOver={onItemDragOver}
                onItemDragEnd={onItemDragEnd}
                itemRender={p => <span>{p.item.name}</span>}
                style={{ width: "100%" }} />
            <TreeViewDragClue ref={dragClue} /></div>;
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };

    function onItemDragEnd(event) {
        if (dragClue.current)
            dragClue.current.hide();

        event.item.isUpdated = true;
        var updateFields = [];

        if (selectedTab === 0) {
            updateFields = items;
        }

        if (selectedTab === 1) {
            updateFields = fields;
        }
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
        if (eventAnalyzer.isDropAllowed) {
            const updatedTree = moveTreeViewItem(
                event.itemHierarchicalIndex,
                updateFields,
                eventAnalyzer.getDropOperation() || "child",
                eventAnalyzer.destinationMeta.itemHierarchicalIndex
            );
            if (selectedTab === 0) {
                setItems(updatedTree);
                setIsLoaded(true);
                setNeedReload(false);
            }
            else if (selectedTab === 1) {
                setFields(updatedTree);
                setIsLoaded(isLoaded);
                setNeedReload(false);
            }
        }
    };

    function onItemDragOver(event) {
        if (!dragClue.current)
            return;

        dragClue.current.show(
            event.pageY + 10,
            event.pageX,
            event.item.text,
            getClueClassName(event)
        );
    };

    function onSelect(e) {
        var selected = e;
        if (selected.id === 0) {
            addGroup(items, params.entity);
            return;
        }

        if (selected.id === -1) {
            const tab = selectedTab;
            let saveFields;
            if (tab === 0)
                saveFields = items;
            else
                saveFields = fields;

            saveSort(saveFields, params.entity, tab);
            return;
        }

        if (selected.route === "expand") {
            expandChanged();
            return;
        }

        if (isMobile)
            expandChanged();

        navigate(selected.route);
    }

    function onSave(saveModel) {
        const userUi = getUiLang();
        const language = getUiSources().find(i => i.name === userUi);

        if (saveModel.group) {
            const groupName = saveModel.group.langItem.items.find(x => x.id === language.id);
            const allGroups = items.filter(x => x.id !== saveModel.group.id);
            if (allGroups.find(x => x.name === groupName.name)) {
                alert(window.captions.MessageBoxDuplicateNameDeny);
                return;
            }
        } else {
            const groupName = saveModel.field.langItem.items.find(x => x.id === language.id);
            const allFields = items.map(x => {
                if (x.items.length > 0) {
                    let test = x.items.filter(s => s.id !== saveModel.field.id);
                    return test.map(q => q.name);
                }
            });
            if (allFields.find(x => x && x.find(s => s === groupName.name))) {
                alert(window.captions.MessageBoxDuplicateNameDeny);
                return;
            }
        }

        //do logic with one save button
        save(saveModel, params.entity);
    }

    function addField(item) {
        item.groupItems = item.groupItems;
        item.allItems = [];
        for (var key in items) {
            let group = items[key];
            if (group.id > 0) {
                group.items.map((i) => item.allItems.push(i));
            }
        }

        fetchPost(`${window.constants.addFieldSearchField}/${params.entity}`,
            item,
            group => {
                if (group) {
                    setNeedReload(true);
                } else
                    setError(window.captions.SaveDataError);
            },
            ex => setError(ex)
        );
    }

    function fetchElement(payload) {
        if (!data.items)
            return;

        var group = data.items.find((i) => i.id === payload);
        if (group) {
            setSelectedItem(group);
            setIsGroup(true);
            setIsShowEditor(true);
            return;
        }

        var parent = data.items.find((i) => i.items.find((x) => x.id === payload));
        var item = parent.items.find((i) => i.id === payload);
        setSelectedItem(item);
        setIsGroup(false);
        setIsShowEditor(true);
    }
    function searchLoadSortElement(payload) {
        setSelectedItem(fields.find(x => x.id === payload));
    }
    function handleSelectTab(payload) {
        setSelectedTab(payload);
        setIsShowEditor(false);
    }
    function onCloseEditor() {
        setNeedReload(false);
        setIsShowEditor(false);
    }

    function fetchData(entity) {
        fetchGet(`${window.constants.getSettings}/${entity}`,
            model => {
                setData(model);
                setItems(model.items);
                setFields(model.sortFields);
                setEntity(entity);
                setError(model.accessError);
                setIsLoaded(true);
                setNeedReload(false);

                if (params.id)
                    fetchElement(params.id);
            },
            ex => setError(ex)
        );
    }

    function saveSort(tree, source, tab) {
        let constant = "";
        if (tab === 0) {
            constant = `${window.constants.saveSort}/${source}`;
        } else if (tab === 1) {
            constant = `${window.constants.saveFieldsSort}/${source}`;
        }

        fetchPost(constant,
            tree,
            mode => {
                if (mode) {
                    setNeedReload(true);
                }
                else
                    setError(window.captions.SaveDataError);
            },
            ex => setError(ex)
        );
    }

    function addGroup(groups, entity) {
        fetchPost(`${window.constants.addGroupSearchField}/${entity}`,
            groups,
            mode => {
                if (mode) {
                    setNeedReload(true);
                } else
                    setError(window.captions.SaveDataError);
            },
            ex => setError(ex)
        );
    }

    function remove(id, isGroup, entity) {
        fetchPost(`${window.constants.removeSearchField}/${entity}/${id}/${isGroup}`,
            mode => {
                if (mode) {
                    setNeedReload(true);
                    setIsShowEditor(false);
                }
                else
                    setError(window.captions.SaveDataError);
            },
            ex => setError(ex)
        );
    }

    function save(model, entity) {
        if (!model) {
            setIsShowEditor(false);
            setNeedReload(false);
            return;
        }

        fetchPost(`${window.constants.saveSearchField}/${entity}`,
            model,
            model => {
                if (model) {
                    setIsShowEditor(false);
                    setNeedReload(true);
                }
                else
                    setError(window.captions.SaveDataError);
            },
            ex => setError(ex)
        );
    }
}