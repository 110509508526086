import React, { useState, useEffect } from 'react';
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";

export default function StagesForAction({ dataRule, stagesList, ruleChanged }) {
    const [items, setItems] = useState([]);

    useEffect(() => mountProperties(), [dataRule, stagesList]);

    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={items}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;

    function mountProperties() {
        if (!dataRule || !stagesList) {
            setItems([]);
            return;
        }

        let items = Array.isArray(stagesList) && stagesList.length ? stagesList : [];
        items.forEach(i => i.isSelected = dataRule.stagesForAction.some(stageId => stageId == i.id));
        setItems(items);
    }

    function selectedItemsChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                stagesForAction: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }
}