import { formatDate } from "@progress/kendo-intl";
import { getUiCulture } from "../../utils/authHelper.jsx";

export default {
    getTaskStatesItems() {
        return [
            { id: 0, name: window.captions.Actual },
            { id: 1, name: window.captions.NotActual },
            { id: 2, name: window.captions.Implemented },
            { id: 3, name: window.captions.Overdued },
            { id: 5, name: window.captions.OverdueNotRelevant }
        ];
    },

    getTaskMonthItems() {
        const monthItems = [{ id: -1, name: window.captions.Month }];
        const culture = getUiCulture();
        for (let m = 0; m <= 11; m++) {
            monthItems.push({ id: m + 1, name: formatDate(new Date(1, m, 1), "MMMM", culture) });
        }
        return monthItems;
    },

    getTaskYearItems() {
        const yearItems = [{ id: 0, name: window.captions.Year }];
        const year = new Date().getFullYear();
        for (let y = year + 1; y >= 2010; y--) {
            yearItems.push({ id: y, name: y });
        }
        return yearItems;
    },

    getLeftTime(date) {
        const dateNow = new Date();
        let delta = Math.abs(date - dateNow) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = delta % 60;
        let leftTime = "";
        if (days > 0)
            leftTime = `${leftTime} ${days} ${window.captions.DayShort}`;
        if (hours > 0)
            leftTime = `${leftTime} ${hours} ${window.captions.Hour}`;
        if (minutes > 0)
            leftTime = `${leftTime} ${minutes} ${window.captions.Minute}`;
        if (leftTime.length === 0 && seconds > 0)
            leftTime = `${leftTime} ${seconds} ${window.captions.Second}`;
        return leftTime.trim();
    },

    getChangeAuthorUserGroups(user, selectedItems, menuDataSource) {
        const subUsers = [];
        const menuUserGroups = [];
        let exceptionUser = 0;
        if (selectedItems) {
            const existingUsers = selectedItems.map(item => item.ExecutorId)
                .filter((value, index, self) => self.indexOf(value) === index);
            if (existingUsers.length === 1)
                exceptionUser = existingUsers[0];
        }

        if (!menuDataSource.accessGroup && !menuDataSource.accessFull && !menuDataSource.accessSubordinates || !menuDataSource.userGroups)
            return null;

        const hasLinkedUser = user.linkedUsers && user.linkedUsers.length > 0;
        for (let key in menuDataSource.userGroups) {
            const g = menuDataSource.userGroups[key];
            if ((g.id !== user.groupId || !menuDataSource.accessGroup) && !menuDataSource.accessFull && !hasLinkedUser)
                continue;

            const group = { id: g.id, name: g.name, items: [] };
            const gItems = g.items.filter((i) => g.id === user.groupId && menuDataSource.accessGroup ||
                menuDataSource.accessFull ||
                (hasLinkedUser && user.linkedUsers.includes(i.id)));
            for (let key in gItems) {
                const u = gItems[key];
                subUsers.push(u.id);
                if (u.id !== exceptionUser)
                    group.items.push(u);
            }

            if (group.items.length > 0)
                menuUserGroups.push(group);
        }
        return { userGroups: menuUserGroups, subordinateUsers: subUsers };
    },

    taskMenuItems(user, data, menuDataSource, selected) {
        const selectedItems = data.some((i) => i.Selected)
            ? data.filter((i) => i.Selected)
            : selected ? [selected] : [];
        if (!selectedItems || selectedItems.length === 0 || menuDataSource.isReadOnly)
            return [];

        let canChangeState = true;
        const authorData = this.getChangeAuthorUserGroups(user, selectedItems, menuDataSource);
        if (!authorData)
            return [];

        let canChangeExecutor = authorData.userGroups.length > 0;
        let canSetFieldValue = menuDataSource.canChangeFields;
        let canChangeStarted = selectedItems.every((i) => i.IsEditableStarted);

        if (selectedItems.some((i) => !i.ContextMenuEnabled)) {
            canChangeExecutor = false;
            canChangeState = false;
            canSetFieldValue = false;
            canChangeStarted = false;
        } else if (selectedItems.some((obj) => obj.ExecutorId === user.id && obj.AuthorId !== user.id))
            canChangeState = false;
        else if (selectedItems.some((obj) => obj.ExecutorId !== user.id &&
            obj.AuthorId !== user.id &&
            !authorData.subordinateUsers.includes(obj.ExecutorId))) {
            canChangeExecutor = false;
            canChangeState = false;
            canSetFieldValue = false;
            canChangeStarted = false;
        }

        if (!canChangeExecutor && !canChangeState && !canSetFieldValue)
            return [];

        const menuItems = [];
        if (canChangeState)
            menuItems.push({ id: "changeState", name: window.captions.ChangeState });

        menuItems.push({ id: "changeComment", name: window.captions.ChangeComment });
        if (canChangeExecutor)
            menuItems.push({ id: "changeExecutor", name: window.captions.ChangeExecutor });

        menuItems.push({ id: "changeRanges", name: window.captions.ChangeRanges });
        if (canChangeStarted)
            menuItems.push({ id: "changeStarted", name: window.captions.ChangeStarted });
        if (canSetFieldValue)
            menuItems.push({ id: "changeField", name: window.captions.SetFieldValue });
        return menuItems;
    }
}