import React from "react";

export default function LoadingMask(props) {
    const text = props.text ? props.text : window.captions.LoadingData;
    return props.inline
        ? <div className="k-loading-image dm-loader-inline">{text}</div>
        : <div className="k-loading-mask">
            <span className="dm-loading-text">{text}</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>;
}