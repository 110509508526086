import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getFilterTextComparer } from "../searchConstants.jsx";

export default function TextControl({ value, field, disabled, comparer, onKeyPress, changedValue, comparerSelected }) {
    const fieldId = field.id;
    const filterTextComparer = getFilterTextComparer();
    const selectedComparer = filterTextComparer.find((e) => e.id === comparer);
    return <div className="dm-wrapper dm-wrapper-content">
        <DropDownList
            data={filterTextComparer}
            disabled={disabled}
            textField="name"
            dataItemKey="id"
            id={`TextComparer${fieldId}`}
            field={fieldId}
            value={selectedComparer}
            onChange={(e) => comparerSelected({ field: fieldId, value: e.value })}
            style={{ width: "160px" }} />
        <Input
            className="dm-wrapper-content"
            disabled={disabled}
            id={`Text${fieldId}`}
            value={value}
            onKeyPress={onKeyPress}
            onChange={(e) => changedValue({ field: fieldId, value: e.value })} />
    </div>;
}