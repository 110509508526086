import React, { useState } from 'react';
import { fetchGet } from "../../../../utils/requestsHelper.jsx";
import RuleGeneralData from "./ruleTabs/ruleGeneralData.jsx";
import RuleAdmin from "./ruleTabs/ruleAdmin.jsx";
import ToolsTab from "./ruleTabs/toolsTab.jsx";
import RuleProjectStageAccess from "./ruleTabs/ruleProjectStageAccess.jsx";
import RuleAccessClients from "./ruleTabs/ruleAccessClients.jsx";
import MainTabAdministrationSystemStrategy from "./ruleTabs/mainTabAdministrationSystemStrategy.jsx";
import Dictionary from "./ruleTabs/dictionary.jsx";
import RuleFieldsAccess from "./ruleTabs/ruleFieldsAccess.jsx";
import StagesForAction from "./ruleTabs/stagesForAction.jsx";
import RightGroupMembers from "./ruleTabs/rightGroupMembers.jsx";
import FieldsFilterForm from "./ruleTabs/fieldsFilterForm.jsx";
import FieldsFilterResults from "./ruleTabs/fieldsFilterResults.jsx";
import CalculatedUserFields from "./ruleTabs/calculatedUserFields.jsx";
import RelativeRoles from "./ruleTabs/relativeRoles.jsx";
import UserStates from "./ruleTabs/userStates.jsx";
import RulesEntityTypes from "./ruleTabs/rulesEntityTypes.jsx";
import RulesEntityStatuses from "./ruleTabs/rulesEntityStatuses.jsx";
import WindowsGroup from "./ruleTabs/windowsGroup.jsx";
import FullHistoryFilters from "./ruleTabs/fullHistoryFilters.jsx";
import FullWorkHistory from "./ruleTabs/fullWorkHistory.jsx";
import TasksNeeded from "./ruleTabs/tasksNeeded.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import LoadingMask from "../../../../components/loadingMask.jsx";
import MessageMask from "../../../../components/messageMask.jsx";
import LangItemEditor from "../../../../components/controls/langItemEditor.jsx";

export default function RuleControl({ dataRule, groupList, rules, selectUserItem, selectGroupItem, ruleChanged }) {
    const ruleTabs = {
        ruleGeneralData: 0,
        ruleAdmin: 1,
        toolsTab: 2,
        ruleProjectStageAccess: 3,
        ruleAccessClients: 4,
        mainTabAdministrationSystemStrategy: 5,
        dictionary: 6,
        ruleFieldsAccess: 7,
        stagesForAction: 8,
        rightGroupMembers: 9,
        fieldsFilterForm: 10,
        fieldsFilterResults: 11,
        calculatedUserFields: 12,
        relativeRoles: 13,
        userStates: 14,
        rulesEntityTypes: 15,
        rulesEntityStatuses: 16,
        windowsGroup: 17,
        fullHistoryFilters: 18,
        fullWorkHistory: 19,
        tasksNeeded: 20
    };

    const [projectGroups, setProjectGroups] = useState(null);
    const [programStages, setProgramStages] = useState(null);
    const [clientCardTypes, setClientCardTypes] = useState(null);
    const [strategiesList, setStrategiesList] = useState(null);
    const [dictionariesList, setDictionariesList] = useState(null);
    const [stages, setStages] = useState(null);
    const [filterFieldsFormList, setFilterFieldsFormList] = useState(null);
    const [filterFieldsResultList, setFilterFieldsResultList] = useState(null);
    const [calculatedUserFieldsList, setCalculatedUserFieldsList] = useState(null);
    const [userStates, setUserStates] = useState(null);
    const [phonesTypes, setPhonesTypes] = useState(null);
    const [addressTypes, setAddressTypes] = useState(null);
    const [emailTypes, setEmailTypes] = useState(null);
    const [urlTypes, setUrlTypes] = useState(null);
    const [documentTypes, setDocumentTypes] = useState(null);
    const [phoneStates, setPhoneStates] = useState(null);
    const [addressStates, setAddressStates] = useState(null);
    const [emailStates, setEmailStates] = useState(null);
    const [urlStates, setUrlStates] = useState(null);
    const [fullHistoryFilters, setFullHistoryFilters] = useState(null);
    const [privilegesCard, setPrivilegesCard] = useState(null);
    const [privilegesGeneral, setPrivilegesGeneral] = useState(null);
    const [privilegesAdminGeneral, setPrivilegesAdminGeneral] = useState(null);
    const [privilegesAdminCustom, setPrivilegesAdminCustom] = useState(null);
    const [privilegesMacros, setPrivilegesMacros] = useState(null);
    const [fieldsPermissions, setFieldsPermissions] = useState(null);
    const [historyActionView, setHistoryActionView] = useState(null);
    const [historyActionAdd, setHistoryActionAdd] = useState(null);
    const [taskTree, setTaskTree] = useState(null);

    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    const [selectedTab, setSelectedTab] = useState(ruleTabs.ruleGeneralData);

    const tabs = renderTabs();
    const main = [];

    if (!dataRule)
        return <div className="dm-container dm-no-padding">
            <MessageMask inline text={window.captions.LoadDataError} />
        </div>;

    if (dataRule.id) {
        renderPair(main,
            "Id",
            "Id",
            null,
            dataRule.id,
            false
        );
    }

    if (dataRule && dataRule.langItem)
        renderPair(main,
            window.captions.Caption,
            "LangEditor",
            () => <LangItemEditor key={`langEditorRule_${dataRule.id}`}
                defaultName={dataRule.name}
                langItem={dataRule.langItem}
                namedChanged={changedName} />);

    return <div style={{ height: "calc(100% - 106px)" }}>
        <div className="dm-container dm-no-padding">
            {main}
        </div>
        <div style={{ height: "calc(100% - 94px)" }} >
            <TabStrip style={{ height: "-webkit-fill-available" }}
                selected={selectedTab}
                onSelect={tabSelected}
                scrollable={true}>
                {tabs.items}
            </TabStrip>
        </div>
    </div>;

    function renderTabs() {
        return {
            items: [
                renderTabStripTabs(ruleTabs.ruleGeneralData, window.captions.RuleGeneralData),
                renderTabStripTabs(ruleTabs.ruleAdmin, window.captions.RuleAdmin),
                renderTabStripTabs(ruleTabs.toolsTab, window.captions.ToolsTab),
                renderTabStripTabs(ruleTabs.ruleProjectStageAccess, window.captions.RuleProjectStageAccess),
                renderTabStripTabs(ruleTabs.ruleAccessClients, window.captions.RuleAccessClients),
                renderTabStripTabs(ruleTabs.mainTabAdministrationSystemStrategy, window.captions.MainTabAdministrationSystemStrategy),
                renderTabStripTabs(ruleTabs.dictionary, window.captions.Dictionary),
                renderTabStripTabs(ruleTabs.ruleFieldsAccess, window.captions.RuleFieldsAccess),
                renderTabStripTabs(ruleTabs.stagesForAction, window.captions.StagesForAction),
                renderTabStripTabs(ruleTabs.rightGroupMembers, window.captions.RightGroupMembers),
                renderTabStripTabs(ruleTabs.fieldsFilterForm, window.captions.FieldsFilterForm),
                renderTabStripTabs(ruleTabs.fieldsFilterResults, window.captions.FieldsFilterResults),
                renderTabStripTabs(ruleTabs.calculatedUserFields, window.captions.CalculatedUserFields),
                renderTabStripTabs(ruleTabs.relativeRoles, window.captions.RelativeRoles),
                renderTabStripTabs(ruleTabs.userStates, window.captions.UserStates),
                renderTabStripTabs(ruleTabs.rulesEntityTypes, window.captions.RulesEntityTypes),
                renderTabStripTabs(ruleTabs.rulesEntityStatuses, window.captions.RulesEntityStatuses),
                renderTabStripTabs(ruleTabs.windowsGroup, window.captions.WindowsGroup),
                renderTabStripTabs(ruleTabs.fullHistoryFilters, window.captions.FullHistoryFilters),
                renderTabStripTabs(ruleTabs.fullWorkHistory, window.captions.FullWorkHistory),
                renderTabStripTabs(ruleTabs.tasksNeeded, window.captions.TasksNeeded)
            ],
            selected: null
        };
    }

    function renderTabStripTabs(index, text) {
        return <TabStripTab title={text} key={index}>
            {renderTabControl({ index, text, selected: index === selectedTab })}
        </TabStripTab>;
    }

    function renderTabControl(tabData) {
        switch (tabData.index) {
            case ruleTabs.ruleGeneralData:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(privilegesCard) && Array.isArray(privilegesGeneral),
                    () => getRuleGeneralData(),
                    () => <RuleGeneralData
                        dataRule={dataRule}
                        privilegesCard={privilegesCard}
                        privilegesGeneral={privilegesGeneral}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.ruleAdmin:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(privilegesAdminGeneral) && Array.isArray(privilegesAdminCustom),
                    () => getRuleAdminData(),
                    () => <RuleAdmin
                        dataRule={dataRule}
                        privilegesAdminGeneral={privilegesAdminGeneral}
                        privilegesAdminCustom={privilegesAdminCustom}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.toolsTab:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(privilegesMacros),
                    () => getToolsTabData(),
                    () => <ToolsTab
                        dataRule={dataRule}
                        privilegesMacros={privilegesMacros}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.ruleProjectStageAccess:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(projectGroups) && Array.isArray(programStages),
                    () => getRuleProjectStage(),
                    () => <RuleProjectStageAccess
                        dataRule={dataRule}
                        projectGroups={projectGroups}
                        programStages={programStages}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.ruleAccessClients:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(clientCardTypes),
                    () => getRuleAccessClients(),
                    () => <RuleAccessClients
                        dataRule={dataRule}
                        clientCardTypes={clientCardTypes}
                        ruleChanged={ruleChanged}
                    />
                );;
            case ruleTabs.mainTabAdministrationSystemStrategy:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(strategiesList),
                    () => getStrategies(),
                    () => <MainTabAdministrationSystemStrategy
                        dataRule={dataRule}
                        strategiesList={strategiesList}
                        ruleChanged={ruleChanged} />
                );
                return;
            case ruleTabs.dictionary:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(dictionariesList),
                    () => getDictionaries(),
                    () => <Dictionary
                        dataRule={dataRule}
                        dictionariesList={dictionariesList}
                        ruleChanged={ruleChanged} />
                );
                return;
            case ruleTabs.ruleFieldsAccess:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(fieldsPermissions),
                    () => getFieldsPermissions(),
                    () => <RuleFieldsAccess
                        dataRule={dataRule}
                        fieldsPermissions={fieldsPermissions}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.stagesForAction:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(stages),
                    () => getStagesList(),
                    () => <StagesForAction
                        dataRule={dataRule}
                        stagesList={stages}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.rightGroupMembers:
                return <RightGroupMembers
                    dataRule={dataRule}
                    groupList={groupList}
                    ruleChanged={ruleChanged}
                    selectUserItem={selectUserItem}
                    selectGroupItem={selectGroupItem} />;
            case ruleTabs.fieldsFilterForm:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(filterFieldsFormList),
                    () => getFilterFieldsFormList(),
                    () => <FieldsFilterForm dataRule={dataRule} filterFieldsFormList={filterFieldsFormList} ruleChanged={ruleChanged} />
                );
            case ruleTabs.fieldsFilterResults:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(filterFieldsResultList),
                    () => getFilterFieldsResultList(),
                    () => <FieldsFilterResults dataRule={dataRule} filterFieldsResultList={filterFieldsResultList} ruleChanged={ruleChanged} />
                );
            case ruleTabs.calculatedUserFields:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(calculatedUserFieldsList),
                    () => getCalculatedUserFieldsList(),
                    () => <CalculatedUserFields dataRule={dataRule} calculatedUserFieldsList={calculatedUserFieldsList} ruleChanged={ruleChanged} />
                );
            case ruleTabs.relativeRoles:
                return <RelativeRoles
                    dataRule={dataRule}
                    ruleList={rules}
                    ruleChanged={ruleChanged}
                />;
            case ruleTabs.userStates:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(userStates),
                    () => getUserStatesList(),
                    () => <UserStates
                        dataRule={dataRule}
                        userStates={userStates}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.rulesEntityTypes:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(phonesTypes),
                    () => getContactTypesList(),
                    () => <RulesEntityTypes
                        dataRule={dataRule}
                        phonesTypes={phonesTypes}
                        addressTypes={addressTypes}
                        emailTypes={emailTypes}
                        urlTypes={urlTypes}
                        documentTypes={documentTypes}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.rulesEntityStatuses:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(phoneStates),
                    () => getContactStatesList(),
                    () => <RulesEntityStatuses
                        dataRule={dataRule}
                        phoneStates={phoneStates}
                        addressStates={addressStates}
                        emailStates={emailStates}
                        urlStates={urlStates}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.windowsGroup:
                return <WindowsGroup dataRule={dataRule} ruleChanged={ruleChanged} />;
            case ruleTabs.fullHistoryFilters:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(fullHistoryFilters),
                    () => getFullHistoryFiltersList(),
                    () => <FullHistoryFilters
                        dataRule={dataRule}
                        fullHistoryFilters={fullHistoryFilters}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.fullWorkHistory:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(historyActionView),
                    () => getHistoryActionTree(),
                    () => <FullWorkHistory
                        dataRule={dataRule}
                        historyActionView={historyActionView}
                        historyActionAdd={historyActionAdd}
                        ruleChanged={ruleChanged}
                    />
                );
            case ruleTabs.tasksNeeded:
                return getTabOrStartLoading(
                    tabData,
                    () => Array.isArray(taskTree),
                    () => getTaskTree(),
                    () => <TasksNeeded
                        dataRule={dataRule}
                        taskTree={taskTree}
                        ruleChanged={ruleChanged}
                    />
                );
            default:
                return null;
        }
    }

    function getTabOrStartLoading(tabData, isDataLoadedFuction, startLoadingData, getTab) {
        if (isDataLoadedFuction()) {
            return getTab();
        }

        if (pending || error) {
            return <div className="dm-container dm-no-padding">
                <MessageMask inline text={pending} error={error} />
            </div>;
        }

        if (tabData.selected)
            startLoadingData();

        return <LoadingMask />;
    }

    // #region DataLoadingMethods

    function getRuleGeneralData() {
        fetchGet(`${window.constants.getRuleGeneralDataDictionary}/`,
            (data) => {
                if (haveAccessError(data))
                    return;

                setPrivilegesCard(data.privilegesCard ?? []);
                setPrivilegesGeneral(data.privilegesGeneral ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getRuleAdminData() {
        fetchGet(`${window.constants.getRuleAdminDictionary}/`,
            (data) => {
                if (haveAccessError(data))
                    return;

                setPrivilegesAdminGeneral(data.privilegesAdminGeneral ?? []);
                setPrivilegesAdminCustom(data.privilegesAdminCustom ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getToolsTabData() {
        fetchGet(`${window.constants.getToolsTabDictionary}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setPrivilegesMacros(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getStrategies() {
        fetchGet(`${window.constants.getStrategies}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setStrategiesList(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getDictionaries() {
        fetchGet(`${window.constants.getDictionaries}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setDictionariesList(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getStagesList() {
        fetchGet(`${window.constants.getStagesList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                var stages = items ?? [];
                stages.unshift({ id: 0, name: window.captions.ByDefault });
                setStages(stages);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getRuleProjectStage() {
        fetchGet(`${window.constants.getRuleProjectStage}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setProjectGroups(items.projectGroups);
                setProgramStages(items.programStages);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getRuleAccessClients() {
        fetchGet(`${window.constants.getRuleAccessClients}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setClientCardTypes(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getFilterFieldsFormList() {
        fetchGet(`${window.constants.getFilterFieldsFormList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setFilterFieldsFormList(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getFilterFieldsResultList() {
        fetchGet(`${window.constants.getFilterFieldsResultList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setFilterFieldsResultList(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getCalculatedUserFieldsList() {
        fetchGet(`${window.constants.getCalculatedUserFieldsList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setCalculatedUserFieldsList(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getUserStatesList() {
        fetchGet(`${window.constants.getUserStatesList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setUserStates(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getContactTypesList() {
        fetchGet(`${window.constants.getContactTypesList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setPhonesTypes(items ? items.phonesTypes : []);
                setAddressTypes(items ? items.addressTypes : []);
                setEmailTypes(items ? items.emailTypes : []);
                setUrlTypes(items ? items.urlTypes : []);
                setDocumentTypes(items ? items.documentTypes : []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getContactStatesList() {
        fetchGet(`${window.constants.getContactStatesList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setPhoneStates(items ? items.phoneStates : null);
                setAddressStates(items ? items.addressStates : null);
                setEmailStates(items ? items.emailStates : null);
                setUrlStates(items ? items.urlStates : null);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getFullHistoryFiltersList() {
        fetchGet(`${window.constants.getFullHistoryFiltersList}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setFullHistoryFilters(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getFieldsPermissions() {
        fetchGet(`${window.constants.getFieldsPermissions}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setFieldsPermissions(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getHistoryActionTree() {
        fetchGet(`${window.constants.getHistoryActionTree}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                var historyActionTree = items ?? [];
                setHistoryActionView(cloneHierarchicalList(historyActionTree));
                setHistoryActionAdd(cloneHierarchicalList(historyActionTree));
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    function getTaskTree() {
        fetchGet(`${window.constants.getTaskTree}/`,
            (items) => {
                if (haveAccessError(items))
                    return;

                setTaskTree(items ?? []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            }
        );
    }

    // #endregion

    function haveAccessError(data) {
        if (!data?.accessError)
            return false;

        setPending(data.accessError);
        return true;
    }

    function cloneHierarchicalList(items) {
        if (!items)
            return [];
        var result = items.map(i => {
            return {
                id: i.id,
                name: i.name,
                items: cloneHierarchicalList(i.items),
            }
        });
        return result;
    }

    function tabSelected(e) {
        setSelectedTab(e.selected);
    }

    function onChangeRuleName(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                name: e.value,
            });
    }

    function changedName(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                langItem: e.selectLang
            });
    }
}