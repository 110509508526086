import React from "react";
import { Avatar } from "@progress/kendo-react-layout";

export default {
    expandToLevelRecursive(items, toLevel, level) {
        items.forEach((item) => {
            item.showItems = level < toLevel || toLevel === 0;
            if (!item.showItems)
                item.showUsers = false;
            else if (toLevel === 0)
                item.showUsers = true;

            if (item.children && item.children.length > 0)
                this.expandToLevelRecursive(item.children, toLevel, level + 1);
        });
    },

    find(item, search) {
        var foundedItems = false;
        var foundedUsers = false;
        const hasItems = item.children && item.children.length > 0;
        const hasUsers = item.users && item.users.length > 0;

        if (hasItems) {
            item.children.forEach((subItem) => {
                if (this.find(subItem, search))
                    foundedItems = true;
            });
        }

        if (hasUsers) {
            item.users.forEach(user => {
                if (user.name.toLowerCase().includes(search))
                    foundedUsers = true;
            });
        }

        if (foundedItems)
            item.showItems = true;

        if (foundedUsers)
            item.showUsers = true;

        return foundedItems || foundedUsers;
    },

    userAvatar(user) {
        let avatar;
        if (user.photo)
            return <Avatar rounded="full" type="image">
                       <img src={user.photo} alt={user.name}/>
                   </Avatar>;

        const initials = user.name.split(" ");
        if (initials.length > 1 && initials[1][0] !== "(") {
            avatar = initials[0][0] + initials[1][0];
        } else {
            avatar = user.name[0];
        }
        return <Avatar rounded="full" type="text">{avatar}</Avatar>;
    }
}