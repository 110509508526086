import React, { useState, useEffect } from 'react';
import { Checkbox } from "@progress/kendo-react-inputs";
import { TreeView } from "@progress/kendo-react-treeview";

export default function FullAccessTreeView(props) {
    const [state, setState] = useState({
        textField: "name",
        viewField: "viewing",
        editField: "editing",
        deleteField: "deleting",
        addField: "adding",

        dataTree: null
    });

    useEffect(() => mountProperties(), [props.dataTree, props.viewField, props.textField, props.editField]);

    return <div className="container">
        <TreeView
            expandIcons={true}
            data={state.dataTree}
            item={renderItem}
            textField={state.textField}
            onExpandChange={onExpandChange}
        />
    </div>;

    function mountProperties() {
        const textField = props.textField ?? "name";
        const viewField = props.viewField ?? "viewing";
        const editField = props.editField ?? "editing";
        const deleteField = props.deleteField ?? "deleting";
        const addField = props.addField ?? "adding";

        const dataTree = Array.isArray(props.dataTree) && props.dataTree.length ? props.dataTree : [];

        setState({
            textField: textField,
            viewField: viewField,
            editField: editField,
            deleteField: deleteField,
            addField: addField,

            dataTree: dataTree,
        });
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };

    function onCheckChange(e, item, field) {
        setValue(item, field, e.value);
        updateParents(item, field);
        if (props.onCheckChange)
            props.onCheckChange(item);
    }


    function updateParents(item, field) {
        if (item.getParent)
            var parent = item.getParent();
        if (!parent || parent[field] === true) {
            return;
        }

        var newValue = undefined;
        parent.items.forEach(i => {
            newValue = getNewCheckboxValue(newValue, i[field]);
        });

        updateParents(parent, needUpdateField);
    }

    function getNewCheckboxValue(oldValue, newValue) {
        if (oldValue) {
            return newValue ? oldValue : null;
        } else if (oldValue === false) {
            return newValue || newValue === null ? null : false;
        } else if (oldValue === null) {
            return null;
        } else {
            return newValue;
        }
    }

    function setValue(item, field, newValue) {
        item[field] = newValue;
        if (!item.items)
            return;
        item.items.forEach(i => setValue(i, field, newValue));
    }

    function renderItem(props) {
        return <>
            <Checkbox key={`CheckboxView_${props.itemHierarchicalIndex}`}
                value={props.item[state.viewField]}
                onChange={(e) => onCheckChange(e, props.item, state.viewField)} >
                <i className={`dm-i dm-i-eye`} />
            </Checkbox>
            <Checkbox key={`CheckboxEdit_${props.itemHierarchicalIndex}`}
                value={props.item[state.editField]}
                disabled={!props.item[state.viewField]}
                onChange={(e) => onCheckChange(e, props.item, state.editField)} >
                <i className={`dm-i dm-i-pen`} />
            </Checkbox>
            <Checkbox key={`CheckboxDelete_${props.itemHierarchicalIndex}`}
                value={props.item[state.deleteField]}
                disabled={!props.item[state.viewField]}
                onChange={(e) => onCheckChange(e, props.item, state.deleteField)} >
                <i className={`dm-i dm-i-close_big`} />
            </Checkbox>
            <Checkbox key={`CheckboxAdd_${props.itemHierarchicalIndex}`}
                value={props.item[state.addField]}
                onChange={(e) => onCheckChange(e, props.item, state.addField)} >
                <i className={`dm-i dm-i-plus`} />
                <label className={"k-checkbox-label"} style={{ display: "inline-block", }} >
                    {props.item[state.textField]}
                </label>
            </Checkbox>
        </>;
    };
}