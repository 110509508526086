import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Map, MapLayers, MapTileLayer, MapMarkerLayer } from '@progress/kendo-react-map';

export default function DialogMap({ position, onClose }) {
    const tileSubdomains = ["a", "b", "c"];
    const tileUrl = (e) => `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
    const attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap contributors</a>';
    const markers = [];
    markers.push({
        latlng: [position.latitude, position.longitude],
        name: ''
    })
    let center = markers.length > 0 ? markers[0].latlng : null;
    var style = {
        height: "600px"
    };
    return <Dialog title={window.captions.Geolocation} onClose={() => onClose()} width={600}>
        <Map style={style} center={center} zoom={13}>
            <MapLayers>
                <MapTileLayer
                    urlTemplate={tileUrl}
                    subdomains={tileSubdomains}
                    attribution={attribution} />
                <MapMarkerLayer
                    data={markers}
                    locationField="latlng"
                    titleField="name" />
            </MapLayers>
        </Map>
    </Dialog>;
}