import React from "react";
import { Link } from "react-router-dom";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function GridCollateralCardCell(props) {
    const value = props.dataItem[props.field];
    const href = `/card/collateral/${value}`;
    return <td {...getLinkCellStyle(props)}>
        <Link to={href} title={window.captionsDynamic.Collaterals}>
            <i className={`dm-i dm-i-chevron_right`} />
        </Link>
    </td>;
}