import { load, loadMessages } from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import amNumbers from "cldr-numbers-full/main/am/numbers.json";
import beNumbers from "cldr-numbers-full/main/be/numbers.json";
import enNumbers from "cldr-numbers-full/main/en/numbers.json";
import kaNumbers from "cldr-numbers-full/main/ka/numbers.json";
import plNumbers from "cldr-numbers-full/main/pl/numbers.json";
import ruNumbers from "cldr-numbers-full/main/ru/numbers.json";
import ukNumbers from "cldr-numbers-full/main/uk/numbers.json";
import viNumbers from "cldr-numbers-full/main/vi/numbers.json";
import esNumbers from "cldr-numbers-full/main/es/numbers.json";
import arNumbers from "cldr-numbers-full/main/ar/numbers.json";

import amLocalCurrency from "cldr-numbers-full/main/am/currencies.json";
import beLocalCurrency from "cldr-numbers-full/main/be/currencies.json";
import enLocalCurrency from "cldr-numbers-full/main/en/currencies.json";
import kaLocalCurrency from "cldr-numbers-full/main/ka/currencies.json";
import plLocalCurrency from "cldr-numbers-full/main/pl/currencies.json";
import ruLocalCurrency from "cldr-numbers-full/main/ru/currencies.json";
import ukLocalCurrency from "cldr-numbers-full/main/uk/currencies.json";
import viLocalCurrency from "cldr-numbers-full/main/vi/currencies.json";
import esLocalCurrency from "cldr-numbers-full/main/es/currencies.json";
import arLocalCurrency from "cldr-numbers-full/main/ar/currencies.json";

import amCaGregorian from "cldr-dates-full/main/am/ca-gregorian.json";
import beCaGregorian from "cldr-dates-full/main/be/ca-gregorian.json";
import enCaGregorian from "cldr-dates-full/main/en/ca-gregorian.json";
import kaCaGregorian from "cldr-dates-full/main/ka/ca-gregorian.json";
import plCaGregorian from "cldr-dates-full/main/pl/ca-gregorian.json";
import ruCaGregorian from "cldr-dates-full/main/ru/ca-gregorian.json";
import ukCaGregorian from "cldr-dates-full/main/uk/ca-gregorian.json";
import viCaGregorian from "cldr-dates-full/main/vi/ca-gregorian.json";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import arCaGregorian from "cldr-dates-full/main/ar/ca-gregorian.json";

import amDateFields from "cldr-dates-full/main/am/dateFields.json";
import beDateFields from "cldr-dates-full/main/be/dateFields.json";
import enDateFields from "cldr-dates-full/main/en/dateFields.json";
import kaDateFields from "cldr-dates-full/main/ka/dateFields.json";
import plDateFields from "cldr-dates-full/main/pl/dateFields.json";
import ruDateFields from "cldr-dates-full/main/ru/dateFields.json";
import ukDateFields from "cldr-dates-full/main/uk/dateFields.json";
import viDateFields from "cldr-dates-full/main/vi/dateFields.json";
import esDateFields from "cldr-dates-full/main/es/dateFields.json";
import arDateFields from "cldr-dates-full/main/ar/dateFields.json";

import amMessages from "../../wwwroot/json/hy-AM.json";
import beMessages from "../../wwwroot/json/be-BY.json";
import enMessages from "../../wwwroot/json/en-US.json";
import kaMessages from "../../wwwroot/json/ka-GE.json";
import plMessages from "../../wwwroot/json/pl-PL.json";
import ruMessages from "../../wwwroot/json/ru-RU.json";
import ukMessages from "../../wwwroot/json/uk-UA.json";
import viMessages from "../../wwwroot/json/vi-VN.json";
import esMessages from "../../wwwroot/json/es-ES.json";
import arMessages from "../../wwwroot/json/ar-SA.json";

load(
    likelySubtags,
    currencyData,
    weekData,
    amNumbers,
    amLocalCurrency,
    amCaGregorian,
    amDateFields,
    beNumbers,
    beLocalCurrency,
    beCaGregorian,
    beDateFields,
    enNumbers,
    enLocalCurrency,
    enCaGregorian,
    enDateFields,
    kaNumbers,
    kaLocalCurrency,
    kaCaGregorian,
    kaDateFields,
    plNumbers,
    plLocalCurrency,
    plCaGregorian,
    plDateFields,
    ruNumbers,
    ruLocalCurrency,
    ruCaGregorian,
    ruDateFields,
    ukNumbers,
    ukLocalCurrency,
    ukCaGregorian,
    ukDateFields,
    viNumbers,
    viLocalCurrency,
    viCaGregorian,
    viDateFields,
    esNumbers,
    esLocalCurrency,
    esCaGregorian,
    esDateFields,
    arNumbers,
    arLocalCurrency,
    arCaGregorian,
    arDateFields
);

loadMessages(amMessages, "am");
loadMessages(beMessages, "be");
loadMessages(enMessages, "en");
loadMessages(kaMessages, "ka");
loadMessages(plMessages, "pl");
loadMessages(ruMessages, "ru");
loadMessages(ukMessages, "uk");
loadMessages(viMessages, "vi");
loadMessages(esMessages, "es");
loadMessages(arMessages, "ar");