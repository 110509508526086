import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import GridDateCell from "../../components/cells/gridDateCell.jsx";
import { fetchPost } from "../../utils/requestsHelper.jsx";
import { GridBaseComponent } from "../../components/gridApiSource.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogAddCollateral({ cardEntity, cardId, onClose }) {
    const gridRef = useRef(null);

    const canAddNew = useSelector(state => state.header.model.access.addingCollateral);
    
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [id, setId] = useState(0);
    const [enumber, setEnumber] = useState("");
    const [search, setSearch] = useState("");

    if (pending || error) {
        return <Dialog title={window.captionsDynamic.AddCollateral} onClose={() => onClose(-1)} width={850}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const actionsCell =
        (p) => {
            return <td {...getLinkCellStyle(p)}>
                <Button icon="check" fillMode="flat" onClick={() => selectCollateral(p.dataItem)} />
            </td>;
        };

    const elements = [];
    let action = "";
    if (id > 0) {
        action =
            <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Add
            }</Button>;

        renderPair(elements,
            window.captionsDynamic.Collaterals,
            "collateral",
            null,
            <span className="text-success">{enumber}</span>);
    }

    let createNew = "";
    if (canAddNew)
        createNew = <Button iconClass="dm-i dm-i-plus" onClick={create}>{window.captions.Create}</Button>;

    let modal = "";
    if (validate) {
        modal =
            <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    return [
        <Dialog title={window.captionsDynamic.AddCollateral}
            key="dialogCollateral"
            onClose={() => onClose(-1)}
            width={850}>
            <div className="dm-container dm-no-padding">
                <div className="dm-wrapper">
                    <div className="dm-wrapper-content">
                        <Input onChange={searchChanged}
                            onKeyDown={(e) => onSearchKey(e)}
                            placeholder={window.captions.ContractNum}
                            value={search}
                            style={{ width: "100%" }} />
                    </div>
                    <div>
                        <Button icon="find" title={window.captions.Search} onClick={() => filter()} />
                    </div>
                </div>

                <GridBaseComponent baseUrl={`${window.constants.getCollateralSearch}/${cardEntity}/${cardId}`}
                    style={{ width: "100%", height: "400px", margin: "0 0 15px 0" }}
                    ref={gridRef}>
                    <GridColumn field="id" title=" " width="35" cells={{ data: actionsCell }} />
                    <GridColumn field="ENumber" title={window.captions.ContractNum} />
                    <GridColumn field="Created" title={window.captions.DateCreated} width="140"
                        cells={{ data: GridDateCell }} />
                </GridBaseComponent>
                {elements}
            </div>
            <DialogActionsBar>
                <Button onClick={() => onClose(-1)}>{window.captions.Cancel}</Button>
                {createNew}
                {action}
            </DialogActionsBar>
        </Dialog>,
        modal
    ];

    function selectCollateral(dataItem) {
        setId(parseInt(dataItem["Id"]));
        setEnumber(dataItem["ENumber"]);
    }

    function create() {
        onClose(0);
    }

    function save() {
        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.addCollateralModel}/${cardEntity}/${cardId}/${id}`,
            null,
            (success) => {
                if (success)
                    onClose(id);
                else
                    setPending(null);
                setValidate(window.captionsDynamic.AddLinkedCollateralError);
            },
            () => { onClose(-1); });
    }

    function onSearchKey(e) {
        if (e.keyCode !== 13)
            return;

        filter();
    }

    function filter() {
        gridRef.current.filterByField("ENumber", "contains", search);
    }

    function searchChanged(e) {
        const text = e.target.value && e.target.value.length > 0
            ? e.target.value
            : "";
        setSearch(text);
    }
}