import { entitySourcesNames, fieldTypes, entitySources } from "../../../utils/systemConstants.jsx";

export default {
    getTypes(source, unusedSources) {
        var accountSource = unusedSources.find(s => s.fieldAlias === "ACCOUNTS");

        const items = [];
        items.push({ id: fieldTypes.text, name: window.captions.ValueText });
        items.push({ id: fieldTypes.integer, name: window.captions.CTypeInteger });
        items.push({ id: fieldTypes.double, name: window.captions.CTypeDouble });
        items.push({ id: fieldTypes.date, name: window.captions.WordDate });
        items.push({ id: fieldTypes.phone, name: window.captions.Phone });
        items.push({ id: fieldTypes.address, name: window.captions.Address });
        items.push({ id: fieldTypes.passport, name: window.captions.Passport });
        items.push({ id: fieldTypes.boolean, name: window.captions.CTypeBoolean });
        items.push({ id: fieldTypes.valueArray, name: window.captions.CTypeArray });
        items.push({ id: fieldTypes.simpleDictionary, name: window.captions.Dictionary });
        items.push({ id: fieldTypes.extendedDictionary, name: window.captions.CTypeExtendedDictionary });
        items.push({ id: fieldTypes.time, name: window.captions.NowTime });
        items.push({ id: fieldTypes.edrvp, name: window.captions.CTypeExplorerEDRVP });
        items.push({ id: fieldTypes.dateTime, name: window.captions.DataAndTime });
        items.push({ id: fieldTypes.timeZone, name: window.captions.CTypeTimeZone });
        items.push({ id: fieldTypes.email, name: window.captions.Email });
        items.push({ id: fieldTypes.url, name: window.captions.Url });
        items.push({ id: fieldTypes.multiSimpleDictionary, name: window.captions.CTypeMultiSimpleDictionary });
        items.push({ id: fieldTypes.multiExtendedDictionary, name: window.captions.CTypeMultiExtendedDictionary });
        items.push({ id: fieldTypes.image, name: window.captions.Picture });
        items.push({ id: fieldTypes.color, name: window.captions.Color });
        if (accountSource && (source === entitySources.client || source === entitySources.loan))
            items.push({ id: fieldTypes.account, name: window.captions.Account });

        items.sort(function (a, b) {
            if (a.name < b.name)
                return -1;
            if (a.name > b.name)
                return 1;
            return 0;
        });

        return items;
    },
    renderMenuItems(entity) {
        return [
            {
                text: window.captions.ConfigFieldsClient,
                route: `/admin/structureSettings/client`,
                selected: entity === entitySourcesNames.client
            },
            {
                text: window.captionsDynamic.ConfigFieldsLoan,
                route: `/admin/structureSettings/loan`,
                selected: entity === entitySourcesNames.loan
            },
            {
                text: window.captions.ConfigFieldsPayments,
                route: `/admin/structureSettings/payments`,
                selected: entity === entitySourcesNames.payments
            },
            {
                text: window.captions.ConfigPaymentPlanFields,
                route: `/admin/structureSettings/paymentPlan`,
                selected: entity === entitySourcesNames.paymentPlan
            },
            {
                text: window.captions.ConfigFieldsUsers,
                route: `/admin/structureSettings/users`,
                selected: entity === entitySourcesNames.users
            },
            {
                text: window.captions.ConfigFieldsProjects,
                route: `/admin/structureSettings/project`,
                selected: entity === entitySourcesNames.project
            },
            {
                text: window.captions.ConfigFieldsProjectGroups,
                route: `/admin/structureSettings/projectGroup`,
                selected: entity === entitySourcesNames.projectGroup
            },
            {
                text: window.captions.PhoneFields,
                route: `/admin/structureSettings/phone`,
                selected: entity === entitySourcesNames.phone
            },
            {
                text: window.captions.AddressFields,
                route: `/admin/structureSettings/address`,
                selected: entity === entitySourcesNames.address
            },
            {
                text: window.captions.EmailFields,
                route: `/admin/structureSettings/email`,
                selected: entity === entitySourcesNames.email
            },
            {
                text: window.captions.UrlFields,
                route: `/admin/structureSettings/url`,
                selected: entity === entitySourcesNames.url
            },
            {
                text: window.captions.PromisePaymentFields,
                route: `/admin/structureSettings/promisePayment`,
                selected: entity === entitySourcesNames.promisePayment
            },
            {
                text: window.captions.PromiseSchedulerFields,
                route: `/admin/structureSettings/promiseScheduler`,
                selected: entity === entitySourcesNames.promiseScheduler
            },
            {
                text: window.captions.MainTabAdministrationStages,
                route: `/admin/structureSettings/specials`,
                selected: entity === entitySourcesNames.specials
            },
            { separator: true },
            {
                id: 0,
                text: window.captions.Add,
                icon: "add"
            }
        ];
    },
    getCultureName(key) {
        if (key === "ua")
            return "uk";

        if (key === "ge")
            return "ka";

        if (key === "vn")
            return "vi";

        return key;
    },
    getAutocomplete(items, type) {
        let newItems = [];
        items.map(item => {
            if (type === fieldTypes.integer) {
                if (item.id === 0 || item.id === 2)
                    newItems.push(item);
            } else if (type === fieldTypes.dateTime ||
                type === fieldTypes.time ||
                type === fieldTypes.date) {
                if (item.id === 0 || item.id === 1)
                    newItems.push(item);
            } else if (type === fieldTypes.text)
                newItems.push(item);
            else if (type === fieldTypes.address &&
                (item.id === 0 || item.moduleId !== 0)) {
                newItems.push(item);
            }
        });
        return newItems;
    }
};