import React from "react";
import { useState, useEffect } from 'react';
import { fetchGet } from "../../../utils/requestsHelper.jsx";
import { formatDate } from "@progress/kendo-intl";
import ErrorMask from "../../../components/errorMask.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import { dateTimeFormat } from "../../../utils/systemConstants.jsx";
import { getUiCulture } from "../../../utils/authHelper.jsx";

export default function ProcessMovementsControl(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(props.id), [props.id]);

    if (error)
        return <ErrorMask error={error} />;

    if (loading)
        return <LoadingMask />;

    if (!data)
        return <div />;

    const duration = (durText) => durText ? durText.replace("{0}", ` ${window.captions.DayShort} `) : "";
    const generateProcessItem = (item, i) => {
        return <li key={i}>
            <div style={{ borderLeft: `4px solid #${item.color}` }}>
                <span className="spanLength">{duration(item.durationText)}</span>
                <span className="boldSpanName">{item.typeName}</span>
                <span>{item.actionName}</span>
            </div>
        </li>;
    };

    const generateProcessState = (state, i, pIndex) => {
        let processItems = [];
        if (state.isShowState && state.processItems && state.processItems.length > 0) {
            processItems = state.processItems.map((item, s) => generateProcessItem(item, s));
        }

        return <li key={i}>
            <div>
                <h4>{state.stateName}</h4>
                <div className="arrowStage" style={{ backgroundColor: state.color, color: state.color }}
                    onClick={() => handleShowItems(state, i, pIndex)}>
                    <span className="arrowStageText">{duration(state.durationText)}</span>
                </div>
            </div>
            <ol className="processItemMovements" key="stateItems">
                {processItems}
            </ol>
        </li>;
    };

    const generateProcess = (process, i) => {
        let processStates = [];
        if (process.states && process.states.length > 0) {
            processStates = process.states.map((pState, s) => generateProcessState(pState, s, i));
        }

        return <li key={i}>
            <div>
                <span className="processNameSpan">{process.name}</span>
                <span>{formatDate(new Date(process.created), dateTimeFormat, getUiCulture())}</span>
                <ol className="processStateMovements" key="processStates">
                    {processStates}
                </ol>
            </div>
        </li>;
    };

    const process = data.map((item, i) => generateProcess(item, i));

    return <div className="movementsContainer dm-full-wh">
        <ol className="processMovements">
            {process}
        </ol>
    </div>;

    function fetchData(id) {
        setLoading(true);
        fetchGet(`${window.constants.cardSourceProcessMovements}/${id}`,
            (data) => {
                setData(data);
                setLoading(false);
                setError(null);
            },
            data => {
                setLoading(false);
                setError(data);
            });
    }

    function handleShowItems(item, sIndex, pIndex) {
        if (!item)
            return;

        const states = data[pIndex].states;
        states[sIndex].isShowState = !states[sIndex].isShowState;
        setData([...data]);
    }
}