import React from "react";
import { Button } from "@progress/kendo-react-buttons";

export default function InlineDialog(props) {
    let className = props.fullHeight
        ? "dm-card-inline-dialog dm-full-height"
        : "dm-card-inline-dialog";

    return <div className={className}>
        <div className="k-window-titlebar k-dialog-titlebar k-header">
            <div className="k-window-title k-dialog-title">{props.title}</div>
            <div className="k-window-actions k-dialog-actions">
                <Button icon="x" onClick={props.onClose}></Button>
            </div>
        </div>
        <div className="db-overflow-y">
            {props.children}
        </div>
        {props.buttons}
    </div>;
} 