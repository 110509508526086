import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getUser } from "../utils/authHelper.jsx";

import Home from "../containers/home/home.jsx";
import SearchForm from "../containers/search/searchForm.jsx";
import SearchResults from "../containers/search/searchResults.jsx";
import SearchCollateralReserves from "../containers/search/searchCollateralReserves.jsx";
import CardEntity from "../containers/cards/CardEntity.jsx";
import Calendar from "../containers/calendar/calendar.jsx";
import Task from "../containers/task/task.jsx";
import Statistic from "../containers/statistic/statistic.jsx";
import Organization from "../containers/organization/organization.jsx";
import Wiki from "../containers/wiki/wiki.jsx";
import UsersGeolocation from "../containers/dashboards/usersGeolocation.jsx";
import Chat from "../containers/chat/chat.jsx";
import AdminClientCard from "../containers/admin/clientCard/clientCard.jsx";
import AdminDocuments from "../containers/admin/documents/documents.jsx";
import AdminImport from "../containers/admin/import/import.jsx";
import AdminProjects from "../containers/admin/projects/projects.jsx";
import AdminReports from "../containers/admin/reports/reports.jsx";
import AdminRolesAndUsers from "../containers/admin/rulesAndUsers/rulesAndUsers.jsx";
import AdminSearchSettings from "../containers/admin/searchSettings/searchSettings.jsx";
import AdminStructureSettings from "../containers/admin/structureSettings/structureSettings.jsx";
import QuestionnaireCard from "../containers/questionnaire/questionnaireCard.jsx";
import CollateralSchema from "../containers/collateralSchema/collateralSchema.jsx";

export default function Routing() {
    var user = getUser();
    var mainPage = user ? user.access.page : "home";

    return <Routes>
        <Route path="/" element={<Navigate to={mainPage} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/search/:entity/form/:id" element={<SearchForm />} />
        <Route path="/search/:entity/form" element={<SearchForm />} />
        <Route path="/search/:entity/:id" element={<SearchResults />} />
        <Route path="/reserve/:entity/:id" element={<SearchCollateralReserves />} />
        <Route path="/card/:entity/:id" element={<CardEntity />} />
        <Route path="/statistic" element={<Statistic />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/task" element={<Task />} />
        <Route path="/organization" element={<Organization />} />
        <Route path="/wiki/:id" element={<Wiki />} />
        <Route path="/wiki" element={<Wiki />} />
        <Route path="/geolocation" element={<UsersGeolocation />} />
        <Route path="/chat/:id" element={<Chat />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/questionnaire/:id/:entityId" element={<QuestionnaireCard />} />
        <Route path="/questionnaire/:id" element={<QuestionnaireCard />} />
        <Route path="/collateral-schema" element={<CollateralSchema />} />
        <Route path="/collateral-schema/:id" element={<CollateralSchema />} />
        <Route path="/admin/clientCard" element={<AdminClientCard />} />
        <Route path="/admin/documents" element={<AdminDocuments />} />
        <Route path="/admin/import/:entity" element={<AdminImport />} />
        <Route path="/admin/projects" element={<AdminProjects />} />
        <Route path="/admin/reports" element={<AdminReports />} />
        <Route path="/admin/rules-and-users/:source/:id" element={<AdminRolesAndUsers />} />
        <Route path="/admin/rules-and-users" element={<AdminRolesAndUsers />} />
        <Route path="/admin/searchSettings/:entity" element={<AdminSearchSettings />} />
        <Route path="/admin/structureSettings/:entity" element={<AdminStructureSettings />} />
    </Routes>;
}