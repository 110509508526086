import React from "react";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function GridColorCell(props) {
    let value = props.dataItem[props.field];
    if (typeof value === "object")
        value = null;

    if (!value)
        return <td {...getLinkCellStyle(props)}></td>;

    let hexValue;
    if (value.length === 7)
        hexValue = value;
    else if (value.length === 9)
        hexValue = `#${value.substring(3)}`;
    else
        hexValue = `#${value}`;

    return <td {...getLinkCellStyle(props)}>
        <span className="dm-color-cell" style={{ backgroundColor: hexValue }} />
    </td>;
}