import React from "react";
import { useState, useEffect } from 'react';
import { ListView } from "@progress/kendo-react-listview";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import LoadingMask from "../../../../components/loadingMask.jsx";
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import {
    EditListViewTextBoxItem,
    EditListViewPassportItem,
    EditListViewNumericItem,
    EditListViewColorItem,
    EditListViewDropdownItem,
    EditListViewBooleanItem,
    EditListViewDateTimeItem
} from "./editListViewItem.jsx";

export default function VerifySettingsComponent(props) {
    const [type, setType] = useState(0);
    const [values, setValues] = useState([]);
    const [isNull, setIsNull] = useState(false);
    const [fieldId, setFieldId] = useState(0);

    useEffect(() => fetchData(), [props.fieldId]);

    if (!values)
        return <LoadingMask />;

    const style = { width: "100%" };
    let content = [];

    let [key, item] = ["",];

    switch (type) {
        case 0:
            key = "textVerificationItem";
            item = textVerificationItem;
            break;
        case 1:
        case 2:
            key = "numericVerificationItem";
            item = numericVerificationItem;
            break;
        case 6:
            key = "passportVerificationItem";
            item = passportVerificationItem;
            break;
        case 7:
            key = "booleanVerificationItem";
            item = booleanVerificationItem;
            break;
        case 8:
            key = "dropdownVerificationItem";
            item = dropdownVerificationItem;
            break;
        case 9:
        case 10:
            key = "dropdownVerificationItem";
            item = dropdownVerificationItem;
            break;
        case 3:
        case 11:
        case 13:
            key = "dateTimeVerificationItem";
            item = dateTimeVerificationItem;
            break;
        case 14:
            key = "dropdownVerificationItem";
            item = dropdownVerificationItem;
            break;
        case 20:
            key = "colorVerificationItem";
            item = colorVerificationItem;
            break;
    }

    renderPair(content,
        "",
        "IsIgnoreEmpty",
        () => <Checkbox key="fieldIsNull" value={isNull}
            label={window.captions.FilterNull}
            onChange={changeIsNull} />,
        true);

    content.push(<Button key="addItem" icon="add" className="dm-positive-filled dm-m-bottom" onClick={addItem}>{window.captions.Add}</Button>);

    content.push(<ListView data={values}
        key={key}
        item={item}
        style={style} />);

    return content;

    function fetchData() {
        if (!props)
            return;

        setFieldId(props.fieldId);
        setType(props.type);

        getIgnoreVerification(props.items);
    }


    function changeIsNull(e) {
        setIsNull(e.value);

        props.onSaveVerify(e.value, values);
    }

    function getIgnoreVerification(item) {
        var values = item ? item : [];
        var isNull = false;

        if (values.length <= 0)
            return;

        if (values[0] === null)
            isNull = true;

        let isNotNull = value => value != null;
        var newList = values.filter(isNotNull);

        setValues(newList);
        setIsNull(isNull);
    }

    function addItem() {
        var newValue = [];

        values.map(i => newValue.push(i));

        if (type === 6)
            newValue.push("1&PP;&PP;&PP;&PP;&PP;");
        else if (type === 1 || type === 2)
            newValue.push(0);
        else if (type === 3)
            newValue.push(new Date());
        else if (type === 3 || type === 11 || type === 13)
            newValue.push(new Date());
        else if (type === 7)
            newValue.push(2);
        else if (type === 8)
            newValue.push(props.valueArray[0]);
        else if (type === 9 || type === 10)
            newValue.push(0);
        else if (type === 20)
            newValue.push(undefined);
        else
            newValue.push("");

        setValues(newValue);
        props.onSaveVerify(isNull, newValue);
    }

    function deleteItem(item) {
        var newValue = values.filter((e) => e !== item.dataItem);
        setValues(newValue);
        props.onSaveVerify(isNull, newValue);
    };

    function saveItem(e) {
        var newValue = [];
        values.map((item, index) => {
            if (index === e.index)
                newValue.push(e.dataItem);
            else
                newValue.push(item);
        });

        setValues(newValue);
        props.onSaveVerify(isNull, newValue);
    }

    function textVerificationItem(item) {
        return <EditListViewTextBoxItem key={`EditListViewTextBoxItem_${item.index}`}
            item={item}
            fieldId={fieldId}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }

    function numericVerificationItem(item) {
        return <EditListViewNumericItem key={`EditListViewNumericItem_${item.index}`}
            item={item}
            fieldId={fieldId}
            isInteger={type === 1}
            fractionalPart={props.fractionalPart}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }

    function passportVerificationItem(item) {
        return <EditListViewPassportItem key={`EditListViewPassportItem_${item.index}`}
            item={item}
            fieldId={fieldId}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }

    function colorVerificationItem(item) {
        return <EditListViewColorItem key={`EditListViewColorItem_${item.index}`}
            item={item}
            fieldId={fieldId}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }

    function dropdownVerificationItem(item) {
        var sources = type === 8 ? props.valueArray : props.dictionarySource;
        return <EditListViewDropdownItem key={`EditListViewDropdownItem_${item.index}`}
            item={item}
            sources={sources}
            type={type}
            fieldId={fieldId}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }

    function booleanVerificationItem(item) {
        return <EditListViewBooleanItem key={`EditListViewBooleanItem_${item.index}`}
            item={item}
            type={type}
            fieldId={fieldId}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }

    function dateTimeVerificationItem(item) {
        return <EditListViewDateTimeItem key={`EditListViewDateTimeItem_${item.index}`}
            item={item}
            type={type}
            fieldId={fieldId}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }
}