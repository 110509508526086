import React from "react";
import { useState, useEffect } from 'react';
import { StackLayout } from "@progress/kendo-react-layout";
import { Checkbox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import {
    fieldTypes,
    getFormatDate,
    getFormatDateTime,
    getFormatTime,
    dateTimeWidth,
    dateWidth,
    timeWidth
} from "../../../../../utils/systemConstants.jsx";
import { DateTimePicker, DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";

export default function DateTimeLimitation(props) {
    const [restriction, setRestriction] = useState({});
    const [isEnableMin, setIsEnableMin] = useState(false);
    const [isEnableMax, setIsEnableMax] = useState(false);
    const [valueMin, setValueMin] = useState(null);
    const [valueMax, setValueMax] = useState(null);
    const [fieldId, setFieldId] = useState(0);
    const [type, setType] = useState(0);

    useEffect(() => fetchData(), [props.fieldId]);

    let pickerFrom;
    let pickerTo;

    let enableMin = <Checkbox key="enableMinCheckBox" className="center" value={isEnableMin}
        onChange={changeEnableMin} />;

    let enableMax = <Checkbox key="enableMaxCheckBox" className="center" value={isEnableMax}
        onChange={changeEnableMax} />;

    if (type === fieldTypes.date) {
        pickerFrom = <DatePicker value={valueMin}
            width={dateWidth}
            disabled={!isEnableMin}
            formatPlaceholder={getFormatDate()}
            onChange={saveMinValue} />;

        pickerTo = <DatePicker value={valueMax}
            width={dateWidth}
            disabled={!isEnableMax}
            formatPlaceholder={getFormatDate()}
            onChange={saveMaxValue} />;
    }

    if (type === fieldTypes.time) {
        pickerFrom = <TimePicker value={statevalueMin}
            width={timeWidth}
            disabled={!stateisEnableMin}
            formatPlaceholder={getFormatTime()}
            onChange={saveMinValue} />;

        pickerTo = <TimePicker value={statevalueMax}
            width={timeWidth}
            disabled={!stateisEnableMax}
            formatPlaceholder={getFormatTime()}
            onChange={saveMaxValue} />;
    }

    if (statetype === fieldTypes.dateTime) {
        pickerFrom = <DateTimePicker value={statevalueMin}
            width={dateTimeWidth}
            disabled={!stateisEnableMin}
            formatPlaceholder={getFormatDateTime()}
            onChange={saveMinValue} />;

        pickerTo = <DateTimePicker value={statevalueMax}
            width={dateTimeWidth}
            disabled={!stateisEnableMax}
            formatPlaceholder={getFormatDateTime()}
            onChange={saveMaxValue} />;
    }

    let content = [];
    renderPair(content,
        window.captions.WordFrom,
        "RestrictionMin",
        () => <StackLayout orientation="horizontal" gap={5}>
            {enableMin}
            {pickerFrom}
        </StackLayout>,
        true);

    renderPair(content,
        window.captions.WordTo,
        "RestrictionMax",
        () => <StackLayout orientation="horizontal" gap={5}>
            {enableMax}
            {pickerTo}
        </StackLayout>,
        true);

    return (
        <StackLayout orientation="vertical" gap={5}>
            {content}
        </StackLayout>
    );

    function fetchData() {
        if (!props)
            return;

        const model = props.restriction;
        let hasMaxValue = Object.keys(model).includes("maxValue");
        if (hasMaxValue && !model.maxValue)
            hasMaxValue = false;
        if (hasMaxValue && Object.keys(model.maxValue).length <= 0)
            hasMaxValue = false;

        let hasMinValue = Object.keys(model).includes("minValue");
        if (hasMinValue && !model.minValue)
            hasMinValue = false;
        if (hasMinValue && Object.keys(model.minValue).length <= 0)
            hasMinValue = false;

        let minValue = new Date();
        let maxValue = new Date();

        if (props.type === fieldTypes.time) {
            minValue = hasMinValue
                ? getTime(model.minValue)
                : null;
            maxValue = hasMaxValue
                ? getTime(model.maxValue)
                : null;
        }
        if (props.type === fieldTypes.date || props.type === fieldTypes.dateTime) {
            minValue = hasMinValue
                ? new Date(model.minValue)
                : null;
            maxValue = hasMaxValue
                ? new Date(model.maxValue)
                : null;
        }

        setRestriction(model);
        setValueMin(minValue);
        setValueMax(maxValue);
        setFieldId(props.fieldId);
        setIsEnableMin(minValue ? true : false);
        setIsEnableMax(maxValue ? true : false);
        setType(props.type);
    }

    function getTime(value) {
        let date = new Date();
        if (typeof value === 'string') {
            var timeValues = value.split(":");

            date.setHours(timeValues[0]);
            date.setMinutes(timeValues[1]);
            date.setSeconds(timeValues[2]);
        } else
            date = value;

        return date;
    }

    function saveMinValue(item) {
        if (parseInt(item)) {
            setValueMin(item);
        } else {
            if (item.value)
                saveRestriction(item.value, false);
            else
                saveRestriction(item, false);
        }
    }

    function saveMaxValue(item) {
        if (parseInt(item)) {
            setValueMax(item);
        } else {
            if (item.value)
                saveRestriction(item.value, true);
            else
                saveRestriction(item, true);
        }
    }

    function changeEnableMin(item) {
        setIsEnableMin(item.value);
        setValueMin(!item.value ? statevalueMin : null);

        if (!item.value)
            saveRestriction(null, false);
    }

    function changeEnableMax(item) {
        setIsEnableMax(item.value);
        setValueMax(!item.value ? statevalueMax : null);

        if (!item.value)
            saveRestriction(null, true);
    }

    function saveRestriction(value, isMax) {
        if (isMax)
            setValueMax(value);
        else
            setValueMin(value);

        var newValue = {
            minType: staterestriction.minType,
            minValue: !isMax ? value : statevalueMin,
            maxType: staterestriction.maxType,
            maxValue: isMax ? value : statevalueMax
        };

        if (isMax)
            newValue.maxValue = value;
        else
            newValue.minValue = value;

        newValue.minType = newValue.minValue !== null ? 1 : 2;
        newValue.maxType = newValue.maxValue !== null ? 1 : 2;

        if (!stateisEnableMin)
            newValue.minValue = null;

        if (!stateisEnableMax)
            newValue.maxValue = null;

        if (isMax && stateisEnableMin && stateisEnableMax && newValue.maxValue <= newValue.minValue) {
            let newChangedValue = null;
            switch (statetype) {
                case fieldTypes.time:
                case fieldTypes.dateTime:
                    newChangedValue = new Date(newValue.minValue.getTime() - 60000);
                    break;
                case fieldTypes.date:
                    newChangedValue = new Date(newValue.minValue.getTime() - 1440 * 60000);
                    break;
            }

            if (statetype === fieldTypes.time && newValue.maxValue) {
                let time = new Date(newValue.maxValue);
                var newTime = time.setHours(0);
                newTime = time.setMinutes(0);
                newTime = time.setSeconds(0);
                newValue.minValue = newValue.maxValue.getTime() <= newTime
                    ? new Date(newTime)
                    : newChangedValue;
            } else
                newValue.minValue = newChangedValue;

            if (stateisEnableMin)
                saveMinValue(newValue.minValue);
        }

        if (!isMax && stateisEnableMin && stateisEnableMax && newValue.maxValue <= newValue.minValue) {
            let newChangedValue = null;
            switch (statetype) {
                case fieldTypes.time:
                case fieldTypes.dateTime:
                    newChangedValue = new Date(newValue.minValue.getTime() + 60000);
                    break;
                case fieldTypes.date:
                    newChangedValue = new Date(newValue.minValue.getTime() + 1440 * 60000);
                    break;
            }

            if (statetype === fieldTypes.time && newValue.minValue) {
                let time = new Date(newValue.minValue);
                var newTime = time.setHours(0);
                newTime = time.setMinutes(0);
                newTime = time.setSeconds(0);
                newValue.maxValue = newValue.minValue.getTime() <= newTime
                    ? new Date(newTime)
                    : newChangedValue;
            } else
                newValue.maxValue = newChangedValue;

            if (stateisEnableMax)
                saveMaxValue(newValue.maxValue);
        }

        var oldValueJson = JSON.stringify(staterestriction);
        var newValueJson = JSON.stringify(newValue);

        if (oldValueJson === newValueJson)
            return;

        props.onSaveLimit(newValue);
    }
}