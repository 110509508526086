import React from "react";
import { useState, useEffect, useRef } from 'react';
import FieldsControl from "./fieldsControl.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet, fetchPost } from "../../../utils/requestsHelper.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { Map, MapLayers, MapTileLayer, MapMarkerLayer } from '@progress/kendo-react-map';
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import GridDateCell from "../../../components/cells/gridDateCell.jsx";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";
import GridIntegerCell from "../../../components/cells/gridIntegerCell.jsx";
import GridTextCell from "../../../components/cells/gridTextCell.jsx";
import GridDoubleCell from "../../../components/cells/gridDoubleCell.jsx";
import LoadingMask from "../../../components/loadingMask.jsx";
import FileHelper from "../../../utils/fileHelper.jsx";
import { DateTimePicker, DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
    controlType,
    userFieldTypes,
    buttonType,
    entityType,
    entitySourcesNames,
    pluginRequestType,
    pickerInputMode,
    getFormatDate,
    getFormatDateTime,
    getFormatTime
} from
    "../../../utils/systemConstants.jsx";

export default function SpecificModuleControl(props) {
    let tableIndex = useRef(1);

    const [uiElement, setUiElement] = useState(null);
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [value, setValue] = useState(null);
    const [tab, setTab] = useState({});

    useEffect(() => fetchData(props.id, props.module, dataState, pluginRequestType.getElements), [
        props.id,
        props.source,
        props.module
    ]);

    if (loading)
        return <LoadingMask />;

    const uiItem = uiElement;
    if (!uiItem)
        return <div />;

    const getStyle = (item) => {
        if (!item)
            return "";

        var style = {};
        if (item.margin.top === 0 && item.margin.right === 0 && item.margin.bottom === 0 && item.margin.left === 0)
            style.margin = "0";
        else
            style.margin =
                `${item.margin.top}px ${item.margin.right}px ${item.margin.bottom}px ${item.margin.left}px`;

        if (item.width > 0)
            style.width = item.width;

        if (item.height > 0)
            style.height = item.height;

        if (item.minWidth > 0)
            style.minWidth = item.minWidth;

        if (item.maxWidth > 0)
            style.maxWidth = item.maxWidth;

        if (item.minHeight > 0)
            style.minHeight = item.minHeight;

        if (item.maxHeight > 0)
            style.maxHeight = item.maxHeight;

        return style;
    };

    const getDateTimePicker = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        const props = {
            style: { width: "100%" }
        };
        if (item.control.inputMode === pickerInputMode.timePicker && item.control.selectedTime) {
            props.value = new Date(item.control.selectedTime);
        } else if (item.control.inputMode !== pickerInputMode.timePicker && item.control.selectedValue) {
            props.value = new Date(item.control.selectedValue);
        }

        if (item.control.startTime) {
            props.min = new Date(item.control.startTime);
        }
        if (item.control.endTime) {
            props.max = new Date(item.control.endTime);
        }

        let picker = "";
        switch (item.control.inputMode) {
            case pickerInputMode.datePicker:
                picker = <DatePicker formatPlaceholder={getFormatDate()} {...props} />;
                break;
            case pickerInputMode.dateTimePicker:
                picker = <DateTimePicker formatPlaceholder={getFormatDateTime()} {...props} />;
                break;
            case pickerInputMode.timePicker:
                picker = <TimePicker formatPlaceholder={getFormatTime()} {...props} />;
                break;

        }
        return <div key={key} style={getStyle(item)}>{picker}</div>;
    };

    const getComboBox = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        const selected = selectedItem ? selectedItem : item.control.selectedItem;
        return <div key={key} style={getStyle(item)}>
            <DropDownList style={{ width: "100%" }}
                textField="name"
                dataItemKey="id"
                data={item.control.items}
                value={selected}
                onChange={(e) => changeComboBox(e, item)} />
        </div>;
    };

    const getScrollViewer = (item, key, childControls) => {
        if (!item.control)
            return <div key={key} />;
        return <div key={key} style={getStyle(item)}
            className="dm-sm-container-scroll">
            {childControls}
        </div>;
    };

    const getTextBlock = (item, key) => {
        if (!item.control)
            return <span key={key} />;

        return <div key={key} style={getStyle(item)}>
            <span>
                {item.control.text}
            </span>
        </div>;
    };

    const getBrowser = (item, key) => {
        var control = item.control;
        if (!control)
            return <span key={key} />;

        const style = getStyle(item);
        style.width = "100%";
        style.height = "100%";
        if (control.url) {
            return <iframe key={key} src={control.url} style={style} />;
        } else {
            return <div key={key} style={style}>{parse(control.htmlContent)}</div>;
        }
    };

    const getGridView = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        const columns = getGridColumns(item.control.columns);
        const style = getStyle(item);
        const showPager = item.control.isShowPaging === true &&
            item.control.result.total > item.control.itemsPerPage;
        const pageSize = showPager ? item.control.itemsPerPage : item.control.result.total;
        style.width = "100%";
        style.height = "100%";
        return <Grid key={key}
            pageable={showPager}
            pageSize={pageSize}
            filterable={false}
            style={style}
            total={item.control.result.total}
            data={item.control.result.data}
            {...dataState}
            onDataStateChange={handleDataStateChange}>
            {columns}
        </Grid>;
    };

    const getButton = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        const icon = () => {
            switch (item.control.type) {
                case buttonType.add:
                    return "plus";
                case buttonType.remove:
                    return "delete";
                case buttonType.save:
                    return "save";
                case buttonType.refresh:
                    return "refresh";
                case buttonType.exportWord:
                    return "word";
                case buttonType.exportExcel:
                    return "excel";
                case buttonType.openCard:
                    return "paste-plain-text";
                case buttonType.openSearch:
                    return "search";
            }
            return "plus";
        };

        var link = null;
        if (item.control.type === buttonType.openCard) {
            const cardId = item.control.cardId;
            switch (item.control.cardEntity) {
                case entityType.loan:
                    link = `/card/${entitySourcesNames.loan}/${cardId}`;
                    break;
                case entityType.client:
                    link = `/card/${entitySourcesNames.client}/${cardId}`;
                    break;
                case entityType.collateral:
                    link = `/card/${entitySourcesNames.collateral}/${cardId}`;
                    break;
                case entityType.documents:
                    link = `/card/${entitySourcesNames.documents}/${cardId}`;
                    break;
                case entityType.process:
                    link = `/card/${entitySourcesNames.process}/${cardId}`;
                    break;
            }
        }

        if (link) {
            return <Link className="dm-not-underline" key={key} to={link} title={item.control.text} style={
                getStyle(item)}>
                <Button icon={icon()}>
                    {item.control.text}
                </Button>
            </Link>;
        }

        return <div key={key} style={getStyle(item)}>
            <Button icon={icon()} onClick={() => buttonOnClick(item)}>
                {item.control.text}
            </Button>
        </div>;
    };

    const getFields = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        var fields = <FieldsControl id={0}
            entity="specificModule"
            fields={item.control}
            isEditMode={true}
            setFields={setEntityFields} />;
        return <div key={key} style={getStyle(item)}>
            {fields}
        </div>;
    };

    const getGrid = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        var gridIndex = tableIndex;
        tableIndex += 1;

        var config = item.control;
        const gridColumnStyle = (col) => {
            if (!col)
                return null;

            const style = {};
            if (col.isAutoLength)
                style.width = "auto";
            else if (col.Length > 0)
                style.width = col.length;

            if (col.maxLength > 0)
                style.maxWidth = col.maxLength;

            return style;
        };

        const gridRowStyle = (row) => {
            if (!row)
                return null;
            const style = {};
            if (row.isAutoLength)
                style.height = "auto";
            else if (row.Length > 0)
                style.height = row.length;

            if (row.maxLength > 0)
                style.maxHeight = row.maxLength;

            return style;
        };

        const fillColumns = (index) => {
            const columns = [];
            if (config.columnDefinitions.length > 0) {
                for (let i in config.columnDefinitions) {
                    const colDefinition = config.columnDefinitions[i];
                    const column = React.createElement("td",
                        {
                            id: `t${gridIndex}row${index}col${i}`,
                            key: createKey(),
                            style: gridColumnStyle(colDefinition)
                        },
                        []);
                    columns.push(column);
                }
            } else {
                const column = React.createElement("td",
                    {
                        id: `t${gridIndex}row${index}col${0}`,
                        key: createKey()
                    },
                    []);
                columns.push(column);
            }
            return columns;
        };

        const rows = [];
        for (let index in config.rowDefinitions) {
            const rowDefinition = config.rowDefinitions[index];
            const columns = fillColumns(index);
            const row = React.createElement("tr",
                {
                    id: `t${gridIndex}row${index}`,
                    key: createKey(),
                    style: gridRowStyle(rowDefinition)
                },
                [...columns]);
            rows.push(row);
        }

        if (config.rowDefinitions.length === 0) {
            const columns = fillColumns(0);
            const row = React.createElement("tr",
                {
                    id: `t${gridIndex}row${0}`,
                    key: createKey()
                },
                [...columns]);
            rows.push(row);
        }

        const tableStyle = getStyle(item);
        tableStyle.tableLayout = "fixed";
        tableStyle.width = "100%";

        const tBody = React.createElement("tbody", { key: createKey() }, [...rows]);

        for (let el of Object.keys(config.elements)) {
            const rowIndex = `t${gridIndex}row${config.elementRows[el]}`;
            const id = `${rowIndex}col${config.elementColumns[el]}`;
            const control = buildControl(config.elements[el]);
            const column = tBody.props.children.find(i => i.props.id === rowIndex);
            if (!column)
                continue;

            const row = column.props.children.find(i => i.props.id === id);
            if (row)
                row.props.children.push(control);
        }

        const table = React.createElement("table", { key: createKey(), style: tableStyle }, tBody);
        return <div key={key} style={getStyle(item)}>{table}</div>;
    };

    const getStackPanel = (item, key, childControls) => {
        if (!item.control)
            return <div key={key} />;
        var style = getStyle(item);
        if (item.control.orientation === 0) {
            style.display = "flex";
            style.flexDirection = "row";
        }
        return <div key={key} style={style}>{childControls}</div>;
    };

    const getTabControl = (item, key, childControls) => {
        if (!item.control)
            return <div key={key} />;
        var name = item.control.uniqueName;
        var style = getStyle(item);
        var selected = 0;
        if (tab[name])
            selected = tab[name];

        return <TabStrip key={key}
            selected={selected}
            onSelect={(e) => tabChanged(name, e)}
            style={style}>
            {childControls}
        </TabStrip>;
    };

    const getTabItem = (item, key, childControls) => {
        if (!item.control)
            return <div key={key} />;
        var style = getStyle(item);
        return <TabStripTab title={item.control.header} key={key} style={style}>{childControls}</TabStripTab>;
    };

    const getMap = (item, key) => {
        if (!item.control)
            return <div key={key} />;

        const tileSubdomains = ["a", "b", "c"];
        const tileUrl = (e) => `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
        const attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap contributors</a>';
        const markers = [];
        if (item.control.points) {
            for (let point of item.control.points) {
                markers.push({
                    latlng: [point.x, point.y],
                    name: point.name
                })
            }
        }

        if (item.control.routes) {
            for (let route of item.control.routes) {
                for (let point of route.points) {
                    markers.push({
                        latlng: [point.x, point.y],
                        name: point.name
                    })
                }
            }
        }

        let center = markers.length > 0 ? markers[0].latlng : null;

        var style = getStyle(item);
        if (!style.height)
            style.height = "100%";
        return <Map key={key} style={style} center={center} zoom={13}>
            <MapLayers>
                <MapTileLayer
                    urlTemplate={tileUrl}
                    subdomains={tileSubdomains}
                    attribution={attribution} />
                <MapMarkerLayer
                    data={markers}
                    locationField="latlng"
                    titleField="name" />
            </MapLayers>
        </Map>;
    };

    const getControl = (item, childControls) => {
        var key = createKey();
        if (!item.isVisible)
            return <div key={key} />;

        switch (item.controlType) {
            case controlType.stackPanel:
                return getStackPanel(item, key, childControls);
            case controlType.tabControl:
                return getTabControl(item, key, childControls);
            case controlType.tabItem:
                return getTabItem(item, key, childControls);
            case controlType.grid:
                return getGrid(item, key);
            case controlType.field:
                return getFields(item, key);
            case controlType.fieldsList:
                return getFields(item, key);
            case controlType.button:
                return getButton(item, key);
            case controlType.gridView:
                return getGridView(item, key);
            case controlType.textBlock:
                return getTextBlock(item, key);
            case controlType.scrollViewer:
                return getScrollViewer(item, key, childControls);
            case controlType.comboBox:
                return getComboBox(item, key);
            case controlType.browser:
                return getBrowser(item, key);
            case controlType.dateTimePicker:
                return getDateTimePicker(item, key);
            case controlType.map:
                return getMap(item, key);
            default:
                return <div key={key}>Unknown control {item.controlType}</div>;
        }
    };

    const buildControl = (item) => {
        if (!item)
            return <div />;

        const builtControls = [];
        if (item.controlType !== controlType.grid) {
            if (item.childItems && item.childItems.length > 0) {
                for (let child of item.childItems) {
                    builtControls.push(buildControl(child));
                }
            }
        }

        return getControl(item, builtControls);
    };

    const controls = buildControl(uiItem);
    return <div className="dm-plugin">
        {controls}
    </div>;


    function fetchData(id, module, dataState, requestType) {
        setLoading(true);
        const moduleId = module ? new Number(module.replace("special", "").replace("module", "")) : "";
        if (isNaN(moduleId))
            return;

        fetchGet(
            `${window.constants.cardSourceSpecificModule}/${id}/${moduleId}/${requestType}?${toDataSourceRequestString(dataState)}`,
            (data) => {
                setUiElement(data);
                setLoading(false);
            },
            data => {
                setError(data);
                setLoading(false);
            });
    }

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(props.id,
            props.module,
            changeEvent.dataState,
            pluginRequestType.getElements);
    }

    function createKey() {
        return uuidv4();
    }

    function getGridColumns(columns) {
        if (!columns)
            return <GridColumn filterable={false} />;

        const gridDateCell = (p) => <GridDateCell {...p} />;
        const gridDateTimeCell = (p) => <GridDateTimeCell {...p} />;
        const gridTextCell = (p) => <GridTextCell {...p} />;
        const gridIntegerCell = (p) => <GridIntegerCell {...p} />;
        const gridDoubleCell = (p) => <GridDoubleCell {...p} />;

        const columnsToShow =
            columns.map((item, i) => {
                var cell = gridTextCell;
                if (item.type === userFieldTypes.integer)
                    cell = gridIntegerCell;
                else if (item.type === userFieldTypes.date)
                    cell = gridDateCell;
                else if (item.type === userFieldTypes.dateTime)
                    cell = gridDateTimeCell;
                else if (item.type === userFieldTypes.double)
                    cell = gridDoubleCell;

                return <GridColumn field={item.uniqueName}
                    cells={{ data: cell }}
                    filterable={item.isFilterable}
                    title={item.header}
                    width={item.width}
                    key={i} />;
            });

        return columnsToShow;
    }

    function setEntityFields(fields) {
        const field = fields[selectedField.modelName];
        return setValue(field.value); //TODO check
    }

    function tabChanged(key, e) {
        tab[key] = e.selected;
        setTab({ ...tab });
    }

    function buttonOnClick(item) {
        const module = props.module;
        const moduleId = module ? new Number(module.replace("special", "").replace("module", "")) : "";
        if (isNaN(moduleId) || !item.control)
            return;

        if (item.control.type === buttonType.openSearch) {
            var entity = entitySourcesNames.loan;
            switch (item.control.cardEntity) {
                case entityType.loan:
                    entity = entitySourcesNames.loan;
                    break;
                case entityType.client:
                    entity = entitySourcesNames.client;
                    break;
                case entityType.collateral:
                    entity = entitySourcesNames.collateral;
                    break;
                case entityType.documents:
                    entity = entitySourcesNames.documents;
                    break;
                case entityType.process:
                    entity = entitySourcesNames.process;
                    break;
            }

            fetchPost(`${window.constants.cardSourceSpecificModuleSearch}/${entity}`,
                item.control.idList,
                (searchId) => {
                    document.location.href = `/search/${entity}/${searchId}`;
                },
                data => {
                    setError(data);
                    setLoading(false);
                });
            return;
        }

        const model = {
            root: uiElement,
            button: item.control
        };
        fetchPost(
            `${window.constants.cardSourceSpecificModule}/${props.id}/${moduleId}/${pluginRequestType.buttonClick
            }?${toDataSourceRequestString(dataState)}`,
            model,
            (data) => {
                if (data.documentName) {
                    FileHelper.getFileSpecificModule(data.documentName);
                } else {
                    setUiElement(data);
                    setLoading(false);
                }
            },
            data => {
                setError(data);
                setLoading(false);
            });
    }

    function changeComboBox(e, item) {
        setSelectedItem(e.target.value);
    }

    function changeDateTimePicker(item) {

    }
}