import React, { useState } from "react";
import { useEffect } from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { Toolbar, ToolbarItem, ToolbarSeparator, Button } from "@progress/kendo-react-buttons";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import DialogUser from "../dialogs/dialogUser.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import organizationFunctions from "./organizationFunctions.jsx";

import "../../styles/css/organizationalChart.css";

export default function Organization() {
    const [items, setItems] = useState([]);
    const [levels, setLevels] = useState(0);
    const [search, setSearch] = useState("");
    const [needSearch, setNeedSearch] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPreviewUser, setIsPreviewUser] = useState(false);
    const [previewUserId, setPreviewUserId] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = `Delta M. Crm. ${captions.CompanyStructure}`;
        fetchData();
    }, []);

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    let dialog = "";
    if (isPreviewUser) {
        dialog = <DialogUser userId={previewUserId}
            key="dialogUserPreview"
            onClose={() => previewUser(false, 0)} />;
    }

    var searchText = search ? search.toLowerCase() : "";

    function generateChart(item, g) {
        var subControls = null;
        var usersControls = null;
        const hasItems = item.children && item.children.length > 0;
        const hasUsers = item.users && item.users.length > 0;
        const isRoot = item === items[0];
        if (isRoot)
            item.showItems = true;

        if (item.showItems && hasItems) {
            subControls = <ol>
                {item.children.map((subItem, subIndex) => generateChart(subItem, subIndex))}
            </ol>;
        }

        var color = item.color ? item.color : "73AA30";
        if (color.length > 6)
            color = color.substring(2);

        color = `#${color}`;
        if (item.showUsers && hasUsers) {
            usersControls = item.users.map((user, subIndex) => {
                var avatar = organizationFunctions.userAvatar(user);
                var className = (needSearch && user.name.toLowerCase().includes(searchText)) ? "founded" : "";
                return <div style={{ "background": color }} className={className} key={subIndex}>
                    {avatar}
                    <a href="#" onClick={() => previewUser(true, user.id)}>{user.name
                    }</a><br />
                    <span>{user.role}</span>
                </div>;
            });

            usersControls = <div className="usersList">
                {usersControls}
            </div>;
        }

        const groupStyle = item.showUsers
            ? { "background": "none", "border": `1px solid ${color}` }
            : { "background": color };
        const expanderIcon = item.showItems ? "minus" : "plus";

        var expander = null;
        if (hasItems && !isRoot) {
            expander = <div className="expander d-print-none">
                <Button icon={expanderIcon} onClick={() => expandItems(item)} />
            </div>;
        }

        var groupName;
        if (hasUsers) {
            if (usersControls) {
                groupName =
                    <a href="#" style={{ "color": "#333333" }} onClick={() => expandUsers(item)}>{item.name
                    }</a>;
            } else {
                groupName = <a href="#" onClick={() => expandUsers(item)}>{item.name}</a>;
            }
        } else {
            groupName = <span>{item.name}</span>;
        }

        return <li key={g}>
            <div style={groupStyle}>
                {groupName}
                {usersControls}
                {expander}
            </div>
            {subControls}
        </li>;
    }

    const levelsPanel = [];
    for (let i = 2; i <= levels; i++) {
        levelsPanel.push(<ToolbarItem key={i}>
            <Button title={i} onClick={(e) => expandToLevel(e)}>{captions.Level} {i}</Button>
        </ToolbarItem>);
    }
    levelsPanel.push(<ToolbarItem key="expandAll">
        <Button title={captions.WordAll} onClick={() => expandAll()}>{captions.WordAll}</Button>
    </ToolbarItem>);

    const chart = items.map((item, g) => generateChart(item, g));

    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.CompanyStructure}</h1>
        <div className="dm-under-title-content">
            <div className="db-overflow-xy">
                <Toolbar className="d-print-none">
                    <ToolbarItem>
                        <Input style={{ "marginLeft": "5px" }}
                            onChange={(e) => searchItems(e)}
                            placeholder={captions.Search}
                            value={search} />
                    </ToolbarItem>
                    <ToolbarSeparator />
                    {levelsPanel}
                    <ToolbarSeparator />
                    <ToolbarItem>
                        <Button icon="print" title={captions.Print} onClick={() => window.print()}>{captions
                            .Print
                        }</Button>
                    </ToolbarItem>
                </Toolbar>
                <div id="organizationalChartWrapper">
                    <div id="organizationalChartContainer">
                        <ol className="organizationalChart">
                            {chart}
                        </ol>
                    </div>
                </div>
                {dialog}
            </div>
        </div>
    </div>;

    function fetchData() {
        fetchGet(window.constants.organizationItems,
            data => {
                setItems(data.items);
                setLevels(data.levels);
                setError(null);
                setIsLoaded(true);
            },
            ex => {
                setError(ex);
                setIsLoaded(true);
            }
        );
    }

    function previewUser(isPreview, id) {
        setIsPreviewUser(isPreview);
        setPreviewUserId(id);
    }

    function searchItems(e) {
        var search = e.target.value;
        if (search && search.length >= 3) {
            var lowerSearch = search.toLowerCase();
            items.forEach((item) => organizationFunctions.find(item, lowerSearch));
            setSearch(search);
            setNeedSearch(true);
        } else {
            setSearch(search);
            setNeedSearch(false);
        }
    }

    function expandToLevel(e) {
        expandRecursive(parseInt(e.currentTarget.title));
    }

    function expandAll() {
        expandRecursive(parseInt(0));
    }

    function expandRecursive(levelId) {
        organizationFunctions.expandToLevelRecursive(items, levelId, 1);
        setItems([...items]);
    }

    function expandItems(item) {
        item.showItems = item.showItems !== true;
        setItems([...items]);
    }

    function expandUsers(item) {
        item.showUsers = item.showUsers !== true;
        setItems([...items]);
        if (!item.showUsers || item.photoLoaded) {
            return;
        }

        var userIdList = [];
        item.users.forEach(user => userIdList.push(user.id));
        fetchPost(window.constants.organizationPhotos,
            userIdList,
            items => {
                item.photoLoaded = true;
                item.users.forEach(user => {
                    var item = items.filter(i => user.id === i.id)[0];
                    if (item && item.name)
                        user.photo = item.name;
                });
            },
            ex => {
                setError(ex);
                setIsLoaded(true);
            }
        );
    }
}