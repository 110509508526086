import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";

export default function DialogLinkedClient({ cardEntity, cardId, id, linkEntity, linkId, stepId, onClose }) {
    const [selectedId, setSelectedId] = useState(id);

    const [linkTypeId, setLinkTypeId] = useState(0);
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [validate, setValidate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    const [model, setModel] = useState({
        sources: {},
        fields: {},
        phone: null,
        accounts: null,
        addresses: null,
        emails: null,
        url: null,
        clientType: 0,
        created: null,
        author: 1,
        stateId: 0,
        stageId: 0,
        linkCardId: 0,
        linkEntity: null,
        canEdit: false,
        canEditLink: false
    });

    useEffect(() => fetchData(), []);

    const title = window.captions.ThirdPerson;
    if (error || pending) {
        return <Dialog title={title} onClose={onCloseDialog} width={850}>
            <MessageMask inline error={error} text={pending} />
        </Dialog>;
    }

    const elements = [];

    let selectedType = { id: 0, name: "" };
    if (linkTypeId > 0 && (model.canEditLink || selectedId > 0))
        selectedType = model.sources.clientTypes.find((i) => i.id === linkTypeId);

    renderPair(elements,
        window.captions.Linking,
        "types",
        () => <DropDownList style={{ width: "100%" }}
            data={model.sources.clientTypes}
            textField="name"
            dataItemKey="id"
            value={selectedType}
            onChange={selectType} />,
        selectedType.name,
        isEdit && model.canEditLink
    );

    if (model.fields && Object.keys(model.fields).length > 0) {
        elements.push(<FieldsControl id={selectedId}
            key="fields"
            fields={model.fields}
            phones={model.phones}
            addresses={model.addresses}
            emails={model.emails}
            urls={model.urls}
            accounts={model.accounts}
            sources={model.sources}
            isEditMode={isEdit}
            setFields={setEntityFields} />);
    }

    let editViewButton = null;
    if (model.canEdit)
        editViewButton =
            <Button iconClass={`dm-i dm-i-${(isEdit ? "eye" : "pen")}`} onClick={changeEdit}>{isEdit
                ? window.captions.View
                : window.captions.EditMode
            }</Button>;

    const closeTitle = isEdited ? window.captions.Cancel : window.captions.Close;
    let saveButton = "";
    if (isEdited) {
        saveButton =
            <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Save
            }</Button>;
    }

    let modal = "";
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} />;
    }

    return [
        <Dialog title={title} key="dialogLinkedClient" onClose={onCloseDialog} width={850}>
            <div className="dm-container dm-no-padding">
                {elements}
            </div>
            <DialogActionsBar>
                <Button onClick={onCloseDialog}>{closeTitle}</Button>
                {editViewButton}
                {saveButton}
            </DialogActionsBar>
        </Dialog>,
        modal,
        <ConfirmMessage key="confirmDialog"
            yes={confirmOk}
            no={() => setConfirm(false)}
            text={confirm} />
    ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        const fetchStepId = stepId ? stepId : 0;
        const fetchLinkId = linkId ? linkId : 0;
        const uri =
            `${window.constants.getLinkedEdit}/${cardEntity}/${cardId}/${id}/${fetchLinkId}/${fetchStepId}`;
        fetchGet(uri,
            (data) => {
                if (!data) {
                    setPending(null);
                    setError(window.captions.StatErrorProgram);
                    return;
                }

                if (data.hasAccessError) {
                    setPending(null);
                    setError(data.accessError);
                    return;
                }

                setSelectedId(data.id);
                setModel({
                    fields: data.fields,
                    sources: data.sources,
                    accounts: data.accounts,
                    phones: data.phones,
                    addresses: data.addresses,
                    emails: data.emails,
                    urls: data.urls,
                    clientType: data.clientType,
                    created: data.created,
                    author: data.author,
                    stateId: data.stateId,
                    stageId: data.stageId,
                    linkCardId: data.linkCardId,
                    linkEntity: data.linkEntity,
                    canEdit: !data.IsReadOnly,
                    canEditLink: data.canEditLink
                });
                setLinkTypeId(data.linkTypeId);

                setIsEdit(data.id === 0);
                setPending(null);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }

    function setEntityFields(fields) {
        setModel({ ...model, fields });
        setIsEdited(true);
        setIsEdit(true);
    }

    function selectType(e) {
        setLinkTypeId(e.target.value.id);
        setIsEdited(true);
    }

    function save() {
        setPending(window.captions.SavingData);
        setError(null);

        const errorFields = CardSaveFunctions.validateRequired(model.fields, model, {});
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        const converted = CardSaveFunctions.renderValues(model.fields);
        const request = {
            values: converted.values,
            nullValues: converted.nullValues,
            id: selectedId,
            clientType: model.clientType,
            created: model.created,
            author: model.author,
            stateId: model.stateId,
            stageId: model.stageId,

            linkTypeId: linkTypeId,
            linkCardId: model.linkCardId,
            linkId: linkId,
            linkEntity: model.linkEntity,
            linkSource: linkEntity,

            phones: model.phones.filter(i => i.isUpdated),
            addresses: model.addresses.filter(i => i.isUpdated),
            emails: model.emails.filter(i => i.isUpdated),
            urls: model.urls.filter(i => i.isUpdated)
        };

        fetchPost(`${window.constants.cardsSave}/client`,
            request,
            () => onClose(true),
            () => onClose(false));
    }

    function onCloseDialog() {
        if (!isEdited)
            onClose(false);
        else {
            setConfirm(window.captions.CancelChangesConfirm);
        }
    }

    function changeEdit() {
        CardSaveFunctions.changeEditFields(isEdit,
            isEdited,
            model,
            (edit, m) => {
                setIsEdit(edit);
                setModel({ ...model, fields: m.fields });
            });
    }

    function confirmOk() {
        setConfirm(false);
        onClose(false);
    }
}