import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { setExpandedState } from "@progress/kendo-react-data-tools";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { fetchGet, fetchDelete } from "../../../utils/requestsHelper.jsx";
import gridFunctions from "../../../components/gridFunctions.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import EmptyMask from "../../../components/emptyMask.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const PaymentsControl = forwardRef(function PaymentsControl(props, ref) {
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [columns, setColumns] = useState([]);
    const [group, setGroup] = useState([]);
    const [collapsedState, setCollapsedState] = useState([]);
    const [data, setData] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    useEffect(() => refreshDataSource(), [props.id, props.projectId]);

    useImperativeHandle(ref, () => {
        return {
            refreshDataSource
        };
    })

    if (error) {
        return <ErrorMask error={error} />;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    } else if (columns.length == 0) {
        return <EmptyMask />;
    }

    const columnsModels = gridFunctions.renderGridColumns(columns);
    let removeColumn = null;
    if (props.canDelete) {
        const removeCell =
            (p) => !p.dataItem.Id
                ? <td {...getLinkCellStyle(p)}></td>
                : <td {...getLinkCellStyle(p)}>
                    <Button icon="delete" onClick={() => handleOnDelete(p.dataItem)} />
                </td>;
        removeColumn = <GridColumn title=" " width="35" field="id" locked={true} groupable={false} cells={{ data: removeCell }} />;
    }

    const gridData = groupData
        ? setExpandedState({
            data: groupData,
            collapsedIds: collapsedState,
        })
        : data;

    return <div className="dm-full-wh">
        <Grid className="dm-full-wh"
            sortable={true}
            pageable={true}
            resizable={true}
            groupable={false}
            total={total}
            data={gridData}
            group={group}
            expandField="expanded"
            {...dataState}
            onExpandChange={expandChange}
            onGroupChange={handleGroupChange}
            onDataStateChange={handleDataStateChange}>
            {columnsModels}
            {removeColumn}
        </Grid>
        {loadingPanel}
        <ConfirmMessage yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    </div>;

    function refreshDataSource() {
        fetchData(props.id, props.projectId, dataState);
    }

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(props.id, props.projectId, changeEvent.dataState);
    }

    function fetchData(id, projectId, dataState) {
        setPending(true);
        fetchGet(`${window.constants.cardSourcePayments}/${id}/${projectId}?${toDataSourceRequestString(dataState)}`,
            (dataSource) => {
                setColumns(dataSource.columns);
                setData(dataSource.data);
                setGroup(dataSource.groups);
                setGroupData(gridFunctions.processWithGroups(dataSource.data, dataSource.groups));
                setError(null);
                setTotal(dataSource.total);
                setDataState(dataState);
                setPending(false);
            },
            ex => setError(ex));
    }

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem["Id"]);
        if (!isNaN(id)) {
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function deleteItem() {
        confirmClose();
        fetchDelete(`${window.constants.cardSourcePayments}/${props.id}`,
            deleteId,
            () => { refreshDataSource(); },
            () => { });
    }

    function confirmClose() {
        setDeleteId(0);
        setConfirm(false);
    }

    function handleGroupChange(event) {
        const newGroupData = gridFunctions.processWithGroups(data, event.group);

        setGroupData(newGroupData);
        setGroup(event.group);
    }

    function expandChange(event) {
        const item = event.dataItem;
        if (item.groupId) {
            const newCollapsedIds = !event.value
                ? [...collapsedState, item.groupId]
                : collapsedState.filter(
                    (groupId) => groupId !== item.groupId
                );

            setCollapsedState(newCollapsedIds);
        }
    }
})

export default PaymentsControl;