import React from "react";
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { fetchGet, fetchDelete } from "../../../utils/requestsHelper.jsx";
import { formatDate } from "@progress/kendo-intl";
import { CalendarModelFields } from "../../calendar/calendarConstants.jsx";
import DialogCollateralStateReserve from "../../dialogs/dialogCollateralStateReserve.jsx";
import { getUiCulture } from "../../../utils/authHelper.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import {
    Scheduler,
    DayView,
    WeekView,
    MonthView,
    SchedulerEditItem,
    SchedulerEditSlot,
    SchedulerViewSlot
} from
    "@progress/kendo-react-scheduler";

export default function CollateralSchedulerControl(props) {
    const schedulerRef = useRef(null);

    const [state, setState] = useState({
        items: [],
        resources: [],
        holidays: [],
        holidaysColor: null,
        dateStart: new Date(),
        dateEnd: new Date(),
        showDialogStateReserve: false,
        currentView: "month",
        error: null,
        confirm: false,
        cancelId: 0,
        cancelStart: null
    });

    useEffect(() => fetchData(props.id), []);

    const viewSlot = (p) => {
        const color = p && p.start && isHoliday(p.start) && state.holidaysColor
            ? state.holidaysColor
            : "#ffffff";
        let dayPercent = "100%";
        const start = formatDate(new Date(p.start), "yyyy-MM-dd");
        if (state.daysPercent && start) {
            const item = state.daysPercent.find((i) => formatDate(new Date(i.startDate), "yyyy-MM-dd") === start);
            if (item) {
                dayPercent = `${item.percentFreeTime}%`;
            }
        }
        if (state.currentView === "month") {
            return <SchedulerViewSlot {...p} style={{ backgroundColor: color }}>
                <span>{formatDate(new Date(p.start), "dd", getUiCulture())}</span>
                <span className="dm-daysPercent-slot">{dayPercent}</span>
            </SchedulerViewSlot>;
        }
        return <SchedulerViewSlot {...p} style={{ backgroundColor: color }} />;
    };

    const editSlot = (p) => <SchedulerEditSlot
        {...p}
        onDoubleClick={handleStateReserve} />;

    const editItem = (p) => <SchedulerEditItem
        {...p}
        showRemoveDialog={false}
        onRemoveClick={handleCancelReserve} />;


    const dialogContent = state.showDialogStateReserve
        ? <DialogCollateralStateReserve id={props.id}
            multiReserve={false}
            stateId={props.stateId}
            dateStart={state.dateStart}
            dateEnd={state.dateEnd}
            onClose={() => {
                setState({ ...state, showDialogStateReserve: false });
                fetchData(props.id);
            }} />
        : "";

    if (state.error) {
        return <ErrorMask error={state.error} />;
    }

    return <div className="dm-full-wh">
        <Scheduler key="schedulerView"
            ref={schedulerRef}
            resources={state.resources}
            data={state.items}
            modelFields={CalendarModelFields}
            onDateChange={handleDateChange}
            onViewChange={handleViewChange}
            defaultView="month"
            editable={{ remove: props.canDeleteReserve }}
            editItem={editItem}
            editSlot={editSlot}
            viewSlot={viewSlot}
            footer={() => ""}>
            <DayView showWorkHours={false} />
            <WeekView showWorkHours={false} />
            <MonthView />
        </Scheduler>
        {dialogContent}
        <ConfirmMessage yes={cancelReserve}
            no={confirmClose}
            text={state.confirm} />
    </div>;

    function fetchData(id, date) {
        var datef;
        if (!date) {
            if (schedulerRef.current && schedulerRef.current.props["defaultDate"]) {
                datef = formatDate(schedulerRef.current.props["defaultDate"], "yyyy-MM-dd");
            } else {
                datef = formatDate(new Date(), "yyyy-MM-dd");
            }
        } else {
            datef = formatDate(new Date(date), "yyyy-MM-dd");
        }
        const count = 1;
        fetchGet(`${window.constants.cardSourceCollateralScheduler}/${id}/${datef}/${count}`,
            (data) => {
                const items = [];
                for (let key in data.items) {
                    const item = data.items[key];

                    const subject = [];
                    if (item.body !== null) {
                        subject.push(<Link key={0} to={item.body} title={window.captionsDynamic.Collaterals}>
                            <i className={`dm-i dm-i-link`} />
                        </Link>);
                    }

                    subject.push(<span key={1}>{item.title + " " + item.executor}</span>);

                    item.subject = subject;
                    item.start = new Date(item.start);
                    item.end = new Date(item.end);
                    items.push(item);
                }
                const holidays = data.holidays.map((item, i) => {
                    return formatDate(new Date(item), "yyyy-MM-dd");
                });
                setState({
                    ...state,
                    items: items,
                    resources: [
                        {
                            name: "type",
                            data: data.resources,
                            field: "stateId",
                            valueField: "numId",
                            textField: "name",
                            colorField: "color"
                        }
                    ],
                    holidays: holidays,
                    holidaysColor: data.holidaysColor,
                    daysPercent: data.daysPercent,
                    error: null
                });
            },
            (ex) => {
                setState({
                    ...state,
                    error: ex
                });
            });
    }

    function isHoliday(value) {
        const date = formatDate(new Date(value), "yyyy-MM-dd");
        if (!state.holidays || !date)
            return false;
        return state.holidays.includes(date);
    }

    function handleDateChange(dateChange) {
        fetchData(props.id, dateChange.value);
    }

    function handleViewChange(e) {
        setState({ ...state, currentView: e.value });
    }

    function handleStateReserve(item) {
        if (!props.canReserve)
            return;

        const dStart = new Date(item.target.props["start"]);
        const dEnd = new Date(item.target.props["end"]);
        const currentDate = new Date(Date.now());
        if (dStart >= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) &&
            !isHoliday(dStart)) {
            setState({
                ...state,
                dateStart: dStart,
                dateEnd: dEnd,
                showDialogStateReserve: true
            });
        }
    }

    function handleCancelReserve(item) {
        const id = parseInt(item.target.props["uid"]);
        if (!isNaN(id))
            setState({
                ...state,
                confirm: window.captions.CancelReserveConfirm,
                cancelId: id,
                cancelStart: item.target.props["start"]
            });
    }

    function cancelReserve() {
        var id = state.cancelId;
        var cancelStart = state.cancelStart;
        confirmClose();
        fetchDelete(window.constants.cardSourceCollateralCancelReserve,
            id,
            () => { fetchData(props.id, cancelStart); },
            () => { });
    }

    function confirmClose() {
        setState({
            ...state,
            confirm: false,
            cancelStart: null,
            cancelId: 0
        });
    }
}