import React from "react";
import { getCellStyleAndValue } from "../../utils/pluginHelpers.jsx";

export default function GridFullTextCell({ dataItem, field, tdProps, style, className, color }) {
    let value = dataItem[field];
    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;

    if (typeof value === "object") {
        if (value != null && value.id >= 0) {
            const plugin = getCellStyleAndValue(value);
            props.style = plugin.style;
            value = typeof plugin.value === "object" ? null : plugin.value;
        } else {
            value = null;
        }
    }

    if (color) {
        return <td {...props}>
            <div className="dm-text-full-cell" style={{ color: color }}>{
                value ? value : null}</div>
        </td>;
    }

    if (!value)
        return <td {...props} />;

    return <td {...props}>
        <div className="dm-text-full-cell">{value}</div>
    </td>;
}