import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";

import "../../styles/css/collateralSchema.css";

export default function DialogSchemaBlock({ block, onClose }) {
    if (!block)
        return <div />;

    const dataRows = [];
    if (block.tid === 0) {
        addRow(dataRows, window.captions.SchemaName, block.schemaName);
    } else {
        addRow(dataRows, window.captions.CollateralContractNumberEntity, block.eNumber);
        addRow(dataRows, window.captions.Executor, block.author);
        addRow(dataRows, window.captions.WordType, block.type);
        addRow(dataRows, window.captions.Stage, block.stageName);
        addRow(dataRows, window.captions.State, block.stateName);
    }

    return <Dialog title={window.captions.SchemaBlockDescription} onClose={onClose} width={550}>
        <div className="dm-container dm-no-padding">
            <div className="dm-label" key={`key_${window.captions.DisplayName}`} title={window.captions
                .DisplayName}>
                <div>{window.captions.DisplayName}</div>
            </div>
            <div className="dm-card-value" key={`value_${window.captions.DisplayName}`} title={block
                .displayName
            }>
                <div>{block.displayName}</div>
            </div>
            {dataRows}
        </div>
    </Dialog>;

    function addRow(rows, fieldName, value) {
        rows.push(<div className="dm-label" key={`key_${fieldName}`} title={fieldName}>
            <div>{fieldName}</div>
        </div>);
        rows.push(<div className="dm-card-value" key={`value_${fieldName}`} title={value}>
            <div>{value}</div>
        </div>);
    }
}