import React from "react";
import { useState, useEffect } from 'react';
import { TreeView } from "@progress/kendo-react-treeview";

export default function DictionaryControlComponent(props) {
    const [allDict, setAllDict] = useState([]);
    const [dict, setDict] = useState([]);
    const [fieldId, setFieldId] = useState(0);

    useEffect(() => fetchData(), [props.fieldId]);

    return <div>
        {getDictionaryItems(allDict, dict)}
    </div>;

    function fetchData() {
        setAllDict(props.allDict);
        setDict(props.dict ? props.dict : []);
        setFieldId(props.fieldId);
    }

    function onCheckChange(event) {
        var item = event.item;
        var isChecked = dict.includes(item.id);

        if (!isChecked) {
            const newList = [];
            newList.push(item.id);
            dict.map((element) => newList.push(element));
        } else {
            const newList = [];

            dict.map((element) => {
                if (element !== item.id)
                    newList.push(element);
            });
        }

        setDict(newList);
        props.onSaveDict(newList);
    }

    function getDictionaryItems(items, selectedDict) {
        if (!items)
            return null;

        let isEmpty = false;
        if (!selectedDict) {
            isEmpty = true;
        }
        var itemList = [];
        items.map((item) => {
            if (isEmpty)
                itemList.push({ id: item.id, text: item.name, checked: false });
            else {
                var isChecked = selectedDict.includes(item.id);
                itemList.push({ id: item.id, text: item.name, checked: isChecked });
            }
        }
        );
        return <TreeView
            key={`dict${fieldId}`}
            textField="text"
            data={itemList}
            checkboxes={true}
            onCheckChange={onCheckChange} />;
    }
}