import React from "react";
import { formatDate } from "@progress/kendo-intl";
import { Card, CardHeader, CardTitle, CardSubtitle, CardBody, CardActions } from
    "@progress/kendo-react-layout";

import { Button } from "@progress/kendo-react-buttons";
import { parseDateTimeFormat, dateTimeFormat, chatTemplateTypes } from "../../utils/systemConstants.jsx";
import { getFileSizeName } from "../../components/extensions/intFunctions.jsx";

export default {
    parsePushMessage(message, messages) {
        if (!message)
            return null;

        const existMess = messages.find((m) => m.id === message.Id);
        if (existMess)
            return null;

        const meet = message.MeetingModel;
        const quote = message.QuoteModel;
        const newMessage = {
            id: message.Id,
            chatId: message.ChatId,
            author: { id: message.AuthorId, name: message.AuthorName },
            timestamp: new Date(message.Created),
            isUpdated: message.IsUpdated,
            isDeleted: message.IsDeleted,
            isImportant: message.IsImportant,
            titleImage: message.TitleImage,
            titleImageValue: message.TitleImageValue,
            type: message.Type,
            title: message.Title,
            text: message.Body,
            attachmentsList: message.Attachments.map((item) => {
                return {
                    id: item.Id,
                    name: item.Name,
                    authorId: item.AuthorId,
                    created: item.Created,
                    fileName: item.FileName,
                    type: item.Type,
                    size: item.Size,
                    sizeName: getFileSizeName(item.Size)
                };
            }),
            comments: message.Comments,
            meetingModel: null,
            newMessage: null
        };
        if (quote) {
            newMessage.quote = {
                authorId: quote.AuthorId,
                authorName: quote.AuthorName,
                created: quote.Created,
                quoteId: quote.QuoteId,
                text: quote.Text
            };
        }
        if (meet) {
            newMessage.meetingModel = {
                id: meet.Id,
                typeId: meet.TypeId,
                typeCaption: meet.TypeCaption,
                authorId: meet.AuthorId,
                executorId: meet.ExecutorId,
                executorName: meet.ExecutorName,
                startDate: meet.StartDate,
                rangeDate: meet.RangeDate,
                comments: meet.Comments,
                venue: meet.Venue,
                participants: meet.Participants
            };
        }
        return newMessage;
    },

    templateBubble(message, handleContextMenuOpen, canEdit) {
        return <div className="k-bubble">
            {message.text}
            {(canEdit
                ? <Button style={{ padding: "0 0 0 5px", fontSize: "10px" }}
                    className="k-flat"
                    onClick={(e) => handleContextMenuOpen(e, message)}>
                    <i className={`dm-i dm-i-ellipsis-v`} />
                </Button>
                : null)}
        </div>;
    },

    messageMark(message) {
        return message.isImportant
            ? <div className="dm-chat-messageMark" title={window.captions.ImportantMessage}>
                <i className={`k-icon k-font-icon k-i-star dm-positive`} />
            </div>
            : null;
    },

    templateMeeting(message, handleContextMenuOpen, addMeetingUser, userId, canEdit) {
        const meeting = message.meetingModel;
        if (!meeting)
            return null;

        const cannotMeet = meeting.authorId === userId ||
            meeting.executorId === userId ||
            (meeting.participants && meeting.participants.includes(userId));

        let cardAction = null;
        if (!cannotMeet || canEdit) {
            const actions = [];

            if (!cannotMeet)
                actions.push(<Button key="go" style={{ padding: "0" }} onClick={() => addMeetingUser(message, userId)}>
                    {window.captions.WillGo}
                </Button>);

            if (canEdit)
                actions.push(<Button key="edit"
                    style={{ padding: "0" }}
                    className="k-flat"
                    onClick={(e) => handleContextMenuOpen(e, message)}>
                    <i className={`dm-i dm-i-ellipsis-v`} />
                </Button>);

            cardAction = <CardActions>{actions}</CardActions>;
        }

        const dataFormatted = formatDate(new Date(meeting.startDate), dateTimeFormat);
        let blockWho = null;
        let blockWhere = null;
        const blockWhen =
            <span title={window.captions.DataAndTime}>
                <i className={`dm-i dm-i-bell dm-i-fw`} /> {dataFormatted} &nbsp;
            </span>;
        if (meeting.executorName && meeting.executorName !== "") {
            blockWho =
                <span title={window.captions.Executor}>
                    <i className={`dm-i dm-i-user dm-i-fw`} /> {meeting.executorName} &nbsp;
                </span>;
        }
        if (meeting.venue && meeting.venue !== "") {
            blockWhere =
                <span title={window.captions.Venue}>
                    <i className={`dm-i dm-i-map-marker-alt dm-i-fw`} /> {meeting.venue} &nbsp;
                </span>;
        }

        return <Card>
            <CardHeader>
                <CardTitle >{message.title}</CardTitle>
                <CardSubtitle>{meeting.typeCaption}</CardSubtitle>
            </CardHeader>
            <CardBody>
                {this.messageMark(message)}
                {blockWhen}
                {blockWho}
                {blockWhere}
                <p>{meeting.comments}</p>
            </CardBody>
            {cardAction}
        </Card>;
    },

    templateQuote(message, handleContextMenuOpen, canEdit) {
        return <div className="k-bubble">
            <div>
                <div style={{ display: "inline-flex" }}>
                    <div>
                        <span className="fas fa-quote-right k-chat-quote-icon" />
                    </div>
                    <div>
                        <p className="k-chat-quoted-text"> {message.quote.text} </p>
                        <p className="k-chat-quoted-info">
                            {`${message.quote.authorName}, ${formatDate(new Date(message.quote.created),
                                parseDateTimeFormat)}`}
                        </p>
                        <div className="k-drawer-separator k-chat-separator" />
                    </div>
                </div>
                <p style={{ marginBottom: "5px" }}> {message.text} </p>
            </div>

            {(canEdit
                ? <div style={{ float: "right" }}>
                    <Button className="k-flat"
                        style={{ padding: "0" }}
                        iconClass="dm-i dm-i-ellipsis-v dm-i-fw"
                        onClick={(e) => handleContextMenuOpen(e, message)} />
                </div>
                : null)}
        </div>;
    },

    templateImage(message, handleContextMenuOpen, canEdit) {
        return <div className="k-bubble">
            <img src={message.titleImageValue}
                style={{ maxWidth: 400 }}
                alt={message.titleImage} />
            {(canEdit
                ? <div style={{ float: "right" }}>
                    <Button className="k-flat"
                        style={{ padding: "0" }}
                        iconClass="dm-i dm-i-ellipsis-v dm-i-fw"
                        onClick={(e) => handleContextMenuOpen(e, message)} />
                </div>
                : null)}
        </div>;
    },

    templateDocument(message, handleContextMenuOpen, canEdit) {
        const document = message.attachmentsList[0];
        return <div className="k-bubble">
            <div>
                <h6 style={{ fontWeight: "bold" }}>
                    {document.name}
                </h6>
                <p className="k-label">{document.sizeName}</p>
            </div>
            {(canEdit
                ? <div style={{ float: "right" }}>
                    <Button className="k-flat"
                        style={{ padding: "0" }}
                        iconClass="dm-i dm-i-ellipsis-v dm-i-fw"
                        onClick={(e) => handleContextMenuOpen(e, message)} />
                </div>
                : null)}
        </div>;
    },

    templatePost(message, searchPhrase, handleContextMenuOpen, addComment, canEdit) {
        let search = searchPhrase;
        if (search)
            search = search.toLowerCase();

        let title = <h4 className="title">
            {message.title}
        </h4>;
        if (search && search.length > 2) {
            const searchText = message.title;
            const startIndex = searchText.toLowerCase().indexOf(search);
            const endIndex = startIndex + search.length;
            if (startIndex !== -1) {
                title =
                    <h6 class="title">
                        {searchText.slice(0, startIndex)}
                        <span className="dm-highlight">
                            {searchText.slice(startIndex, endIndex)}
                        </span>
                        {searchText.slice(endIndex)}
                    </h6>;
            }
        }

        let text = null;
        if (message.text) {
            text =
                <p className="k-chat-post-body-text">
                    {message.text}
                </p>;
            if (search && search.length > 2) {
                const searchText = message.text;
                const startIndex = searchText.toLowerCase().indexOf(search);
                const endIndex = startIndex + search.length;
                if (startIndex !== -1) {
                    text =
                        <p>
                            {searchText.slice(0, startIndex)}
                            <span className="dm-highlight">
                                {searchText.slice(startIndex, endIndex)}
                            </span>
                            {searchText.slice(endIndex)}
                        </p>;
                }
            }
        }

        let clip = null;
        if (message.attachmentsList && message.attachmentsList.length > 0) {
            clip = <div className="k-chat-paperclip">
                <span className="dm-i dm-i-paperclip" />
            </div>;
        }

        return <div className="k-bubble post">
            {this.messageMark(message)}
            {message.titleImageValue && message.titleImageValue.length > 0
                ? <Button className="k-flat" onClick={() => addComment(message)}>
                    <img src={message.titleImageValue}
                        style={{ height: 100 }}
                        alt={message.titleImage} />
                </Button>
                : null
            }
            <div className="content">
                <div>
                    <div className="title-container">
                        {title}
                        {clip}
                    </div>
                    {text}
                </div>
                <div style={{ float: "right" }}>
                    <Button className="k-flat"
                        iconClass="dm-i k-icon k-font-icon dm-i-comment-alt dm-i-fw"
                        style={{ padding: "0" }}
                        onClick={() => addComment(message)} />
                    {(canEdit
                        ? <><br /><Button className="k-flat edit-post-button"
                            iconClass="dm-i k-icon k-font-icon dm-i-ellipsis-v dm-i-fw"
                            style={{ padding: "0" }}
                            onClick={(e) => handleContextMenuOpen(e, message)} /></>
                        : null)}
                </div>
            </div>
        </div>;
    },

    template(props, search, accessEdit, handleContextMenuOpen, addMeetingUser, addComment, userId) {
        if (!props)
            return props;

        const message = props.item;
        const t = message.type;
        const canEdit = userId === message.author.id || accessEdit;

        if (t === 5 && message.meetingModel)
            return this.templateMeeting(message, handleContextMenuOpen, addMeetingUser, userId, canEdit);

        switch (t) {
            case chatTemplateTypes.image:
                return this.templateImage(message, handleContextMenuOpen, canEdit);
            case chatTemplateTypes.document:
                return this.templateDocument(message, handleContextMenuOpen, canEdit);
            case chatTemplateTypes.post:
                return this.templatePost(message, search, handleContextMenuOpen, addComment, canEdit);
            case chatTemplateTypes.quote:
                if (message.quote)
                    return this.templateQuote(message, handleContextMenuOpen, canEdit);
                else
                    return this.templateBubble(message, handleContextMenuOpen, canEdit);
            default:
                return this.templateBubble(message, handleContextMenuOpen, canEdit);
        }
    },

    convertChats(data) {
        const items = [];
        for (let key in data) {
            const item = data[key];
            const chat = {
                id: item.id,
                name: item.name,
                authorId: item.authorId,
                isLiveFeedMode: item.isLiveFeedMode,
                isUpdated: item.isUpdated,
                isDeleted: item.isDeleted,
                allowedUsers: item.allowedUsers,
                badgeCount: 0
            };
            items.push(chat);
        }
        return items;
    },

    convertMessages(data) {
        const items = [];
        for (let key in data) {
            const item = data[key];
            const message = {
                id: item.id,
                chatId: item.chatId,
                author: { id: item.authorId, name: item.authorName },
                timestamp: new Date(item.created),
                isUpdated: item.isUpdated,
                isDeleted: item.isDeleted,
                isImportant: item.isImportant,
                titleImage: item.titleImage,
                titleImageValue: item.titleImageValue,
                type: item.type,
                title: item.title,
                text: item.body,
                quote: item.quoteModel,
                attachmentsList: item.attachments.map((x) => {
                    return {
                        id: x.id,
                        name: x.name,
                        authorId: x.authorId,
                        created: x.created,
                        fileName: x.fileName,
                        type: x.type,
                        size: x.size,
                        sizeName: getFileSizeName(x.size)
                    };
                }),
                comments: item.comments,
                meetingModel: item.meetingModel
            };
            items.push(message);
        }

        return items;
    }
}