import React from "react";
import { formatDate } from "@progress/kendo-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getFormatDate, dateWidth } from "../../../utils/systemConstants.jsx";

export default function DateControl({ value, field, disabled, changedValueFrom, changedValueTo }) {
    let valueArray = value;
    if (!Array.isArray(valueArray))
        valueArray = [null, null];

    let valueFrom = valueArray[0];
    if (valueFrom !== null) {
        valueFrom = new Date(valueFrom);
    }

    let valueTo = valueArray[1];
    if (valueTo !== null) {
        valueTo = new Date(valueTo);
    }

    const fieldId = field.id;
    return [
        <div key={`DateFrom${fieldId}`} className="dm-filter-range">
            <div>
                {window.captions.WordFrom}
            </div>
            <DatePicker id={`DateFromValue${fieldId}`}
                disabled={disabled}
                width={dateWidth}
                formatPlaceholder={getFormatDate()}
                value={valueFrom}
                onChange={(e) => changedValueFrom({ field: fieldId, value: formatDate(e.value, "yyyy-MM-ddTHH:mm:ss") })} />
        </div>,
        <div key={`DateTo${fieldId}`} className="dm-filter-range">
            <div>
                {window.captions.WordTo}
            </div>
            <DatePicker id={`DateToValue${fieldId}`}
                disabled={disabled}
                width={dateWidth}
                formatPlaceholder={getFormatDate()}
                value={valueTo}
                onChange={(e) => changedValueTo({ field: fieldId, value: formatDate(e.value, "yyyy-MM-ddTHH:mm:ss") })} />
        </div>
    ];
}