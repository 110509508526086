import React, { useState, useEffect } from 'react';

import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import BaseFieldsControl from "./baseFieldsControl.jsx";
import ContactsControl from "./contactsControl.jsx";
import AddressControl from "./addressControl.jsx";
import { Upload } from "@progress/kendo-react-upload";
import { headersFile } from "../../../utils/requestsHelper.jsx";
import ProjectDialog from "./projectDialog.jsx";

export default function LoansImportRender({ autoImportLoans, dataProjects, startStatus, columns, baseFieldLoans, baseFieldClients, getFileData, getProjects, getStartStatus, startImportLoans }) {
    const fieldClient = [
        { id: 1, foo: "", expanded: true, bar: "BaseFieldClient" },
        { id: 2, foo: "", expanded: false, bar: "BaseFieldClient" },
        { id: 3, foo: "grip", expanded: true, bar: "BaseFieldClient" },
        { id: 4, foo: null, expanded: false, bar: "BaseFieldClient" },
        { id: 5, foo: "", expanded: true, bar: "BaseFieldClient" }
    ];

    const defaultNameImport = "Не является елементом авто импорта";

    const [selectedTab, setSelectedTab] = useState(0);
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");

    const [attachments, setAttachments] = useState([]);
    const [visible, setVisible] = useState(false);

    const [headerRow, setHeaderRow] = useState(1);
    const [dataRow, setDataRow] = useState(2);

    const [selectedStageId, setSelectedStageId] = useState(0);
    const [selectedStateId, setSelectedStateId] = useState(0);
    const [states, setStates] = useState([]);
    const [statesDefault, setStatesDefault] = useState([]);
    const [isLoadStartProjState, setIsLoadStartProjState] = useState(false);

    const [updateDate, setUpdateDate] = useState("");

    const [nameImport, setNameImport] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectIdList, setProjectIdList] = useState([]);
    const [isLoadProjects, setIsLoadProjects] = useState(false);
    const [projectList, setProjectList] = useState([]);

    const [isOnlyUpdate, setIsOnlyUpdate] = useState(false);
    const [isOnlyInsert, setIsOnlyInsert] = useState(false);
    const [isOnlyThisProject, setIsOnlyThisProject] = useState(false);
    const [isMultiUpdating, setIsMultiUpdating] = useState(false);
    const [isIgnoreArchived, setIsIgnoreArchived] = useState(false);
    const [isUpdateLoanLink, setIsUpdateLoanLink] = useState(false);
    const [isNotUpdateContactInfo, setIsNotUpdateContactInfo] = useState(false);
    const [isImportAsSystem, setIsImportAsSystem] = useState(false);
    const [ignoreDefaultValueUpdate, setIgnoreDefaultValueUpdate] = useState(false);
    const [isImportOnlyActive, setIsImportOnlyActive] = useState(false);
    const [ignoreEmptyKeys, setIgnoreEmptyKeys] = useState(false);
    const [isRemoveNotPresentsPayments, setIsRemoveNotPresentsPayments] = useState(false);

    useEffect(() => {
        setStatus();
        getProjects();
    }, []);

    let field = getField();
    if (!isLoadStartProjState)
        setStatus();

    if (!isLoadProjects)
        setProjects();

    return <div className="dm-full-height" style={{ overflowY: "auto" }}>
        <div className="dm-container">
            <DropDownList id="autoImportLoan"
                textField="name"
                dataItemKey="id"
                textField="name"
                dataItemKey="id"
                data={dataAutoImport(autoImportLoans)}
                onChange={changeAutoImport}
                defaultValue={{ id: 0, name: defaultNameImport }}
                style={{ margin: 5, width: 540 }}
            />
        </div>

        <div className="dm-container">
            <TabStrip selected={selectedTab} onSelect={handleSelect}>
                <TabStripTab title={window.captions.General}>

                    <div className="dm-container">

                        <div>
                            <Label style={{ margin: 5, padding: 10, textAlign: "center" }}>
                                {window.captions.ChoseProjects}</Label>
                        </div>

                        <div>
                            <Button className="dm-positive-filled"
                                style={{ margin: 5 }}
                                iconClass="dm-i dm-i-search dm-i-fw"
                                onClick={toggleDialog}
                                title={window.captions.ChoseProjects} />
                        </div>

                        <div>
                            {visible && (
                                <ProjectDialog onClose={toggleDialog}
                                    getProgects={getProj}
                                    dataProjects={projectList}
                                    getStartPrStat={getStartProjStat} />
                            )}
                        </div>

                        <div>
                            <Label style={{ margin: 5, padding: 10 }}>{projectName}</Label>
                        </div>

                    </div>

                    <div className="dm-container">

                        <div>
                            <Label style={{ margin: 5, padding: 10 }}>{window.captions
                                .ChoseSource
                            }</Label>
                        </div>

                        <div>
                            <Input key="nameLabel1"
                                maxLength={255}
                                style={{ margin: 0, padding: 1, width: 250 }}
                                value={fileName} />
                        </div>
                        <div>
                            <Upload
                                id="UploadfileImport"
                                batch={false}
                                multiple={true}
                                defaultFiles={[]}
                                onStatusChange={uploadAttachStatusChange}
                                onRemove={removeAttachment}
                                saveHeaders={headersFile()}
                                removeHeaders={headersFile()}
                                saveUrl={window.constants.fileUpload}
                                removeUrl={window.constants.fileRemove} />
                        </div>

                        <div>
                            <Label style={{ margin: 5 }}>{window.captions.RowHeaderNumber
                            }</Label>
                        </div>

                        <div>
                            <Input key="RowHeaderNumber"
                                onChange={changeHeaderRow}
                                defaultValue={1}
                                pattern="[0-9]*"
                                maxLength={255}
                                value={headerRow}
                                style={{ width: 70 }} />
                        </div>

                        <div>
                            <Label style={{ margin: 5, padding: 10, width: 10 }}>{window
                                .captions
                                .FirstRowNumber}</Label>
                        </div>

                        <div>
                            <Input key="FirstRowNumber"
                                onChange={changeDataRow}
                                pattern="[0-9]*"
                                defaultValue={2}
                                value={dataRow}
                                maxLength={255}
                                style={{ width: 70 }} />
                        </div>
                    </div>
                    <div className="dm-container">
                        <div>
                            <Label style={{ margin: 5, padding: 10 }}>{window.captions
                                .SelectStartState}</Label>
                        </div>
                        <div>
                            <DropDownList id="dropdown1"
                                textField="fullName"
                                dataItemKey="id"
                                data={startStatus}
                                onChange={changeStartProj}
                                style={{ margin: 5, width: 540 }}

                                defaultValue={defaultStartProj()} />
                        </div>
                        <div>
                            <DropDownList id="dropdown11"
                                textField="fullName"
                                dataItemKey="id"
                                data={states}
                                onChange={changeStartStatus}

                                defaultValue={statesDefault}
                                style={{ margin: 5, width: 540 }}
                            />
                        </div>
                    </div>
                    <div className="dm-container">
                        <div>
                            <Label style={{ margin: 5, padding: 10 }}>{window.captions
                                .SetDataUpdateDate}</Label>
                        </div>
                        <div>
                            <DropDownList id="dropdownSetDataUpdateDate"
                                data={columns}
                                style={{ margin: 5, width: 1040 }}
                                defaultValue="пусто" />
                        </div>
                    </div>

                    <div className="dm-full-height">
                        <div>
                            <Checkbox key="isOnlyUpdate"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.OnlyUpdate}
                                value={isOnlyUpdate}
                                onChange={(e) => setIsOnlyUpdate(e.value)} />

                            <Checkbox key="OnlyInsert"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.OnlyInsert}
                                value={isOnlyInsert}
                                onChange={(e) => setIsOnlyInsert(e.value)} />

                            <Checkbox key="67"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.IgnoreOtherProjects}
                                value={isOnlyThisProject}
                                onChange={(e) => setIsOnlyThisProject(e.value)} />

                            <Checkbox key="340963"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.AllowMultiImport}
                                value={isMultiUpdating}
                                onChange={(e) => setIsMultiUpdating(e.value)} />

                            <Checkbox key="82323"
                                style={{ margin: 5 }}
                                label={window.captions.IgnoreArchived}
                                value={isIgnoreArchived}
                                onChange={(e) => setIsIgnoreArchived(e.value)} />

                        </div>
                        <div >
                            <Checkbox key="3543"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.UpdateLoanLink}
                                value={isUpdateLoanLink}
                                onChange={(e) => setIsUpdateLoanLink(e.value)} />

                            <Checkbox key="232853"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.NotUpdateContactInfo}
                                value={isNotUpdateContactInfo}
                                onChange={(e) => setIsNotUpdateContactInfo(e.value)} />

                            <Checkbox key="34863"
                                style={{ margin: 5 }}
                                label="Новые сделки закреплять за System"
                                value={isImportAsSystem}
                                onChange={(e) => setIsImportAsSystem(e.value)} />

                            <Checkbox key="24323"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.IgnoreDefaultValueUpdate}
                                value={ignoreDefaultValueUpdate}
                                onChange={(e) => setIgnoreDefaultValueUpdate(e.value)} />
                        </div>
                        <div >
                            <Checkbox key="3843"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.ImportOnlyActive}
                                value={isImportOnlyActive}
                                onChange={(e) => setIsImportOnlyActive(e.value)} />

                            <Checkbox key="23253"
                                style={{ margin: 5 }}
                                label={window.captions.IgnoreEmptyKeys}
                                value={ignoreEmptyKeys}
                                onChange={(e) => setIgnoreEmptyKeys(e.value)} />

                            <Checkbox key="3463"
                                style={{ margin: 5 }}
                                label={window
                                    .captions.RemoveNotPresentsPayments}
                                value={isRemoveNotPresentsPayments}
                                onChange={(e) => setIsRemoveNotPresentsPayments(e.value)} />
                        </div>
                    </div>

                </TabStripTab>
                <TabStripTab title="Поля сделки">
                    <BaseFieldsControl data={baseFieldLoans} columns={columns} />
                </TabStripTab>
                <TabStripTab title={window.captions.WorkFlowAnswerActionFieldClient}>
                    <BaseFieldsControl data={baseFieldClients} columns={columns} />
                </TabStripTab>

                <TabStripTab title={window.captions.Phones}>
                    <ContactsControl entity="phone" />
                </TabStripTab>
                <TabStripTab title={window.captions.Address}>
                    <AddressControl />
                </TabStripTab>
                <TabStripTab title={window.captions.Email}>
                    <ContactsControl entity="email" />
                </TabStripTab>
                <TabStripTab title={window.captions.Urls}>
                    <ContactsControl entity="urls" />
                </TabStripTab>
                <TabStripTab title={window.captions.Payment}>
                    <BaseFieldsControl data={fieldClient} />
                </TabStripTab>
                <TabStripTab title={window.captions.PaymentPlan}>
                    <BaseFieldsControl data={fieldClient} />
                </TabStripTab>
                <TabStripTab title={window.captions.PaymentScheduler}>
                    <BaseFieldsControl data={fieldClient} />
                </TabStripTab>
            </TabStrip>
            <Button icon="save"
                className="dm-button"
                onClick={() => startImportLoans(field)}

                style={{ margin: "16px 0 16px 0" }}>
                {window.captions.Import}
            </Button>
        </div>
    </div>;

    function getField() {
        return {
            ProjectIdList: projectIdList,
            Name: nameImport,
            FileName: fileName,
            FileType: fileType,
            FileFields: columns,

            HeaderRow: headerRow,
            DataRow: dataRow,

            SelectedStageId: selectedStageId,
            SelectedStateId: selectedStateId,
            UpdateDate: updateDate,

            IsOnlyUpdate: isOnlyUpdate,
            IsOnlyInsert: isOnlyInsert,
            IsOnlyThisProject: isOnlyThisProject,
            isMultiUpdating: isMultiUpdating,
            IsIgnoreArchived: isIgnoreArchived,
            IsUpdateLoanLink: isUpdateLoanLink,
            IsNotUpdateContactInfo: isNotUpdateContactInfo,
            IsImportAsSystem: isImportAsSystem,
            IgnoreDefaultValueUpdate: ignoreDefaultValueUpdate,
            IsImportOnlyActive: isImportOnlyActive,
            IgnoreEmptyKeys: ignoreEmptyKeys,
            IsRemoveNotPresentsPayments: isRemoveNotPresentsPayments
        };
    }

    function handleSelect(e) {
        setSelectedTab(e.selected);
    }

    function uploadAttachStatusChange(e) {
        if (!e.response)
            return;

        var newAttachments = attachments;
        var files = e.response.response;
        if (files.length === 0)
            return;

        const file = files["0"];
        const name = file.fileName;
        const type = file.fileType;

        getFileData(name);
        setFileType(type);
        setFileName(name);
        setAttachments(newAttachments);
    }

    function removeAttachment(e) {
        const newAttachments = [...attachments];
        const removeFiles = e.affectedFiles;
        for (let key in removeFiles) {
            const file = removeFiles[key];
            const attach = newAttachments.find((i) => i.name === file.name.replace(file.extension, "") &&
                i.fileName === file.fileName &&
                i.size === file.size);

            if (!attach)
                continue;

            const index = newAttachments.indexOf(attach);
            newAttachments.splice(index, 1);
        }
        setAttachments(newAttachments);
    }

    function toggleDialog() {
        setVisible(!visible);
    }

    function getProj() {
        getProjects();
    }

    function getStartProjStat(listIdProject) {
        getStartStatus(listIdProject);
        getIdListProjectAndName(listIdProject);
    }

    //isMultiUpdating  - если найдено несколько
    //IsIgnoreArchived - игнор архивних
    //IsUpdateLoanLink - обновлять связь с клиентом
    //IsNotUpdateContactInfo - не обновлять контактние данніе
    //IsImportAsSystem - нові угоди на систем
    //IgnoreDefaultValueUpdate - не обновлять значение по умолчанию
    //IsImportOnlyActive - считать незагружение елементи активними
    //IgnoreEmptyKeys - игнорировать записи з пустими ключами
    //IsRemoveNotPresentsPayments - удалять погашения которих нет в загрузке

    function setProjects() {
        if (dataProjects !== undefined) {
            setProjectList(dataProjects);
            setIsLoadProjects(true);
        }
    }

    function getIdListProjectAndName(idList) {
        let projectNames = [];
        let projIdList = [];
        for (let index in idList) {
            let id = idList[index];
            for (let projectIndex in projectList) {
                const projectGroup = projectList[projectIndex];
                for (let itemsIndex in projectGroup.items) {
                    const project = projectGroup.items[itemsIndex];
                    if (project.id === id) {
                        projIdList.push(project.id);
                        projectNames.push(project.text);
                    }
                }
            }
        }
        setProjectName(projectNames.join());
        setProjectIdList(projIdList);
    }

    function setStatus() {
        if (startStatus.length !== 0) {
            if (startStatus[0].items.length !== 0) {
                setStates(startStatus[0].items);
                setIsLoadStartProjState(true);
                setStatesDefault(startStatus[0].items[0]);
            }
        }
    }

    function changeStartProj(e) {
        setIsLoadStartProjState(true);
        setSelectedStageId(e.value.id);
        setStates(e.value.items);
        setStatesDefault(e.value.items[0]);
    }

    function changeStartStatus(e) {
        setSelectedStateId(e.value.id);
    }

    function changeFileData(path) {
        getFileData(path);
        setFileName(path);
    }

    function changeAutoImport(e) {
        getStartStatus([e.value.newLoansProject]);

        getIdListProjectAndName(e.value.projectIdList);
        changeFileData(e.value.folder + '\\' + e.value.fileName);
        setNameImport(e.value.name);

        setIsOnlyUpdate(e.value.isOnlyUpdate);
        setIsOnlyInsert(e.value.isOnlyInsert);
        setIsOnlyThisProject(e.value.isOnlyThisProject);
        setIsMultiUpdating(e.value.isMultiUpdating);
        setIsIgnoreArchived(e.value.isIgnoreArchived);
        setIsUpdateLoanLink(e.value.isUpdateLoanLink);
        setIsNotUpdateContactInfo(e.value.isNotUpdateContactInfo);
        setIsImportAsSystem(e.value.isImportAsSystem);
        setIgnoreDefaultValueUpdate(e.value.ignoreDefaultValueUpdate);
        setIsImportOnlyActive(e.value.isImportOnlyActive);
        setIgnoreEmptyKeys(e.value.ignoreEmptyKeys);
        setIsRemoveNotPresentsPayments(e.value.isRemoveNotPresentsPayments);
    }

    function defaultStartProj() {
        if (startStatus.length > 0)
            return startStatus[0];
    }

    function changeHeaderRow(e) {
        var dr = dataRow;
        var hr = headerRow;
        setHeaderRow(e.value);
        if (dr < e.value) {
            testSr();
        }
    }

    function testSr() {
        var sd = headerRow;
        setDataRow(parseInt("34"));
    }

    function changeDataRow(e) {
        setDataRow(e.value);
    }

    function dataAutoImport(autoImportLoans) {
        if (autoImportLoans !== undefined) {
            let dd = projectList;
            if (dd !== undefined && dd.length > 0) {
                let newLoansProject = dd[0].items[0].id;
                if (autoImportLoans[0].newLoansProject === 0) {
                    autoImportLoans[0].newLoansProject = newLoansProject;
                    autoImportLoans[0].projectIdList.push(newLoansProject);
                    getIdListProjectAndName(autoImportLoans[0].projectIdList);
                    setNameImport(autoImportLoans[0].name);
                }

            }
        }

        return autoImportLoans;
    }
}