import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import GroupControl from "./controls/groupControl.jsx";
import UserControl from "./controls/userControl.jsx";
import RuleControl from "./controls/ruleControl.jsx";
import { ButtonGroup, Button, Toolbar, ToolbarItem, ToolbarSeparator } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TreeView, moveTreeViewItem, TreeViewDragAnalyzer, TreeViewDragClue } from "@progress/kendo-react-treeview";

import RulesAndUsersFunctions from "./rulesAndUsersFunctions.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { fetchGet, fetchPost } from "../../../utils/requestsHelper.jsx";

export default function AdminRuleAndUsers() {
    const dragClue = useRef(null);
    const dragOverCnt = useRef(0);
    const isDragDrop = useRef(false);

    const navigate = useNavigate();
    const params = useParams();

    const [isRuleTab, setIsRuleTab] = useState(false);
    const [isUserTab, setIsUserTab] = useState(false);
    const [isDataListLoaded, setIsDataListLoaded] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(null);
    const [confirmModel, setConfirmModel] = useState({
        confirmText: false,
        isDeleteConfirm: false,
        isSaveConfirm: false,
        goToUrl: null,
    });
    const [accessError, setAccessError] = useState(null);
    const [saveError, setSaveError] = useState(null);

    const [groupsList, setGroupsList] = useState([]);
    const [rules, setRules] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRule, setSelectedRule] = useState(null);

    const [groupData, setGroupData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [ruleData, setRuleData] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [needReload, setNeedReload] = useState(false);
    const [needPush, setNeedPush] = useState(false);
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.RuleAndUsers}`;
        usersAndRulesLoad();
    }, []);

    useEffect(() => {
        if (params.source && params.id)
            dataLoad();
    }, [params.source, params.id]);

    useEffect(() => {
        if (!needReload)
            return;

        setNeedReload(false);
        usersAndRulesLoad();
        dataLoad();
    }, [needReload]);

    useEffect(() => {
        if (!needPush)
            return;

        setNeedPush(false);
        if (groupData) {
            selectGroupItem(groupData.id);
        } else if (userData) {
            selectUserItem(userData.id);
        } else if (ruleData) {
            selectRuleItem(ruleData.id);
        }
    }, [needPush]);

    if (error)
        return <ErrorMask error={error} />;

    if (accessError)
        return <div className="dm-container dm-no-padding">
            <MessageMask inline text={accessError} />
        </div>;

    if (!isDataListLoaded || isDataLoading)
        return <LoadingMask text={message} />;

    const interactionButtons = [];

    if (isRuleTab && ruleData?.id !== 0) {
        interactionButtons.push(<ToolbarItem key="addRule">
            <Button icon="add"
                onClick={() => createNewRule()}
                title={window.captions.AddRule} />
        </ToolbarItem>);
    } else if (groupData?.id !== 0) {
        interactionButtons.push(<ToolbarItem key="addGroup">
            <Button icon="add"
                onClick={() => addGroup()}
                title={window.captions.AddGroup} />
        </ToolbarItem>);
    }

    if (groupData && groupData?.id !== 0 || userData
        && userData?.id !== 0 || ruleData && ruleData?.id !== 0) {
        interactionButtons.push(<ToolbarItem key="deleteElement">
            <Button icon="delete"
                onClick={() => deleteElement()}
                title={window.captions.Delete} />
        </ToolbarItem>);
    }

    if (isRuleTab && ruleData && ruleData?.id !== 0) {
        interactionButtons.push(<ToolbarItem key="duplicateRule">
            <Button iconClass={`fas fa-copy fa-fw`}
                onClick={() => createNewRule(ruleData)}
                title={window.captions.DuplicateItem} />
        </ToolbarItem>);
    }

    if (canSave) {
        interactionButtons.push(<ToolbarItem key="saveElement">
            <Button icon="save"
                onClick={() => saveElement()}
                title={window.captions.Save} />
        </ToolbarItem>);
    }

    const toolbar = (
        <Toolbar>
            <ToolbarItem>
                <ButtonGroup>
                    <Button
                        togglable={true}
                        selected={!isRuleTab}
                        onClick={showGroupsAndUsers}
                    >
                        {window.captions.GroupsAndUsers}
                    </Button>
                    <Button
                        togglable={true}
                        selected={isRuleTab}
                        onClick={showRules}
                    >
                        {window.captions.Rights}
                    </Button>
                </ButtonGroup>
            </ToolbarItem>
            <ToolbarSeparator />
            {interactionButtons}
        </Toolbar>
    );

    const elementsTreeView =
        <div>
            {isRuleTab
                ? <TreeView
                    data={rules}
                    onItemClick={onRuleClick}
                    animate={false}
                    textField="name"
                    expandIcons={false}
                    draggable={false}
                    itemRender={p => <span>{p.item.name}</span>}
                    style={{ width: "100%" }} />
                : <TreeView
                    data={groupsList}
                    onItemClick={onGroupUserClick}
                    onExpandChange={onExpandChange}
                    animate={false}
                    textField="name"
                    expandIcons={true}
                    draggable={true}
                    onItemDragOver={onItemDragOver}
                    onItemDragEnd={onItemDragEnd}
                    itemRender={p => <span>{p.item.name}</span>}
                    style={{ width: "100%" }} />
            }
            <TreeViewDragClue ref={dragClue} />
        </div>;

    let dialogMessage = null;
    if (saveError) {
        dialogMessage = <Dialog
            key="dialogEditorFields"
            className="k-dialog-no-padding"
            onClose={() => setSaveingError(null)}
            title={window.captions.Warning}
        >
            {saveError}
            {/*<div className="dm-container dm-container-slim dm-m-top">*/}
            {/*    {saveError}*/}
            {/*</div>*/}
            {/*<DialogActionsBar>*/}
            {/*    <Button className="dm-positive-filled" onClick={yes}>{window.captions.WordYes}</Button>*/}
            {/*    <Button className="dm-negative-filled" onClick={no}>{window.captions.WordNo}</Button>*/}
            {/*</DialogActionsBar>*/}
        </Dialog>;
    }

    const pageContent = tabContent();
    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.RuleAndUsers}</h1>
        {toolbar}
        <div className="dm-container dm-full-height">
            <div className="dm-size-33 dm-full-height">
                {elementsTreeView}
            </div>
            <div className="dm-size-66 dm-full-height">
                {pageContent}
            </div>
        </div>
        {dialogMessage}
        {renderConfirmMessage()}
    </div>;

    function dataLoad() {
        if (!params.source || !params.id)
            return;

        switch (params.source) {
            case 'group':
                groupDataLoad(params.id);
                break;
            case 'user':
                userDataLoad(params.id);
                break;
            case 'rule':
                ruleDataLoad(params.id);
                break;
        }
    }

    function selectGroupItem(id) {
        goToElement(`group/${id}`);
    }

    function selectUserItem(id) {
        goToElement(`user/${id}`);
    }

    function selectRuleItem(id) {
        goToElement(`rule/${id}`);
    }

    function goToElement(goToUrl) {
        const navigateTo = `/admin/rules-and-users/${goToUrl}`;
        if (canSave) {
            setConfirm(false, true, navigateTo);
        } else {
            navigate(navigateTo);
        }
    }

    function addGroup() {
        if (!rules || rules.length == 0) {
            alert(window.captions.YouDontHaveRuleGroup);
            return;
        }

        var newItem = RulesAndUsersFunctions.getNewGroup(groupsList, rules);
        var group = { id: newItem.id, name: newItem.name, selected: true };
        removePreviousSelection();

        setGroupData(newItem);
        setUserData(null);
        setRuleData(null);
        setGroupsList([...groupsList, group]);
        setIsUserTab(false);
        setIsRuleTab(false);
        setSelectedGroup(group);
        setSelectedUser(null);
        setSelectedRule(null);
        cleanConfirm();
        setCanSave(true);
    }

    function createNewRule(originalRule) {
        var newItem = RulesAndUsersFunctions.getNewRule(rules, originalRule);
        var rule = { id: newItem.id, name: newItem.name, selected: true };
        removePreviousSelection();
        setRuleData(newItem);
        setGroupData(null);
        setUserData(null);
        setRules([...rules, rule]);
        setIsUserTab(false);
        setIsRuleTab(true);
        setSelectedGroup(null);
        setSelectedUser(null);
        setSelectedRule(rule);
        cleanConfirm();
        setCanSave(true);
    }

    function deleteElement() {
        if (groupData) {
            var rule = rules.find((x) => x.id == groupData.rule);
            if (rule.isSup) {
                alert(window.captions.UnableRemoveSuperUser);
            } else {
                groupDataDelete(groupData.id);
            }
        } else if (userData) {
            var rule = rules.find((x) => x.id == userData.rule);
            if (rule.isSup) {
                alert(window.captions.UnableRemoveSuperUser);
            } else {
                userDataDelete(userData.id);
            }
        } else if (ruleData) {
            var rule = rules.find((x) => x.id == ruleData.id);
            if (rule.isSup) {
                alert(window.captions.UnableRemoveSuperUser);
            } else if (ruleData.linkedGroups && ruleData.linkedGroups.length > 0
                || groupsList.some((x) => x.items.some((i) => i.role == ruleData.id))) {
                alert(window.captions.RuleContainsUsers);
            } else {
                ruleDataDelete(ruleData.id);
            }
        }
    }

    function confirmClose() {
        setConfirm(false, false);
    }

    function goToPage(goToUrl) {
        //TODO move to useEffect
        if (!goToUrl)
            return;
        navigate(goToUrl);
    }

    function saveElement() {
        if (groupData)
            groupDataSave();
        else if (userData)
            userDataSave();
        else if (ruleData)
            ruleDataSave();
    }

    function requestContent(requestString) {
        return <div className="dm-rules-users">
            <div className="select-request">
                {requestString}
            </div>
        </div>;
    }

    function tabContent() {
        if (isRuleTab) {
            if (!selectedRule)
                return requestContent(window.captions.ChoseOrAddRule);

            return <RuleControl
                dataRule={ruleData}
                groupList={groupsList}
                rules={rules}
                ruleChanged={ruleChanged}
                selectGroupItem={(id) => selectGroupItem(id)}
                selectUserItem={(id) => selectUserItem(id)}
                selectRuleItem={(id) => selectRuleItem(id)}
            />;
        } else if (isUserTab) {
            if (!selectedUser)
                return requestContent(window.captions.ChoseOrAddGroupOrUser);

            return <UserControl
                dataUser={userData}
                rules={rules}
                groupList={groupsList}
                userChanged={userChanged}
                selectRuleItem={(id) => selectRuleItem(id)}
            />;
        } else if (!selectedGroup) {
            return requestContent(window.captions.ChoseOrAddGroupOrUser);
        } else {
            return <GroupControl
                dataGroup={groupData}
                rules={rules}
                groupList={groupsList}
                addNewUser={(users) => createNewUser(users, groupData)}
                groupChanged={groupChanged}
                selectRuleItem={(id) => selectRuleItem(id)}
            />;
        }
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function onGroupUserClick(event) {
        const selected = isUserTab
            ? selectedUser
                ? selectedUser
                : null
            : selectedGroup
                ? selectedGroup
                : null;

        if (selected)
            selected.selected = false;

        event.item.selected = true;
        if (event.item.isGroup)
            selectGroupItem(event.item.id);
        else
            selectUserItem(event.item.id);
    }

    function onRuleClick(event) {
        if (selectedRule)
            selectedRule.selected = false;

        event.item.selected = true;
        selectRuleItem(event.item.id);
    }

    function onItemDragEnd(event) {
        isDragDrop.current = dragOverCnt.current > 0;
        dragOverCnt.current = 0;
        dragClue.current.hide();
        event.item.isUpdated = true;
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();

        if (eventAnalyzer.isDropAllowed) {
            const updatedTree = moveTreeViewItem(
                event.itemHierarchicalIndex,
                groupsList,
                eventAnalyzer.getDropOperation() || "child",
                eventAnalyzer.destinationMeta.itemHierarchicalIndex
            );
            moveUser(updatedTree);
        }
    }

    function onItemDragOver(event) {
        dragOverCnt.current++;
        dragClue.current.show(
            event.pageY + 10,
            event.pageX,
            event.item.text,
            getClueClassName(event)
        );
    }

    function getClueClassName(event) {
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
        const { itemHierarchicalIndex: itemIndex } = eventAnalyzer.destinationMeta;
        if (eventAnalyzer.isDropAllowed) {
            const separator = "_";
            switch (eventAnalyzer.getDropOperation()) {
                case "child":
                    if (event.item.isGroup)
                        return "k-i-cancel";
                    return "k-i-plus";
                case "before":
                    return itemIndex === "0" || itemIndex.endsWith(`${separator}0`)
                        ? "k-i-insert-up"
                        : "k-i-insert-middle";
                case "after": {
                    const siblings = getSiblings(itemIndex, groupsList);
                    const lastIndex = Number(itemIndex.split(separator).pop());
                    return lastIndex < siblings.length - 1
                        ? "k-i-insert-middle"
                        : "k-i-insert-down";
                }
                default:
                    break;
            }
        }

        return "k-i-cancel";
    }

    function getSiblings(itemIndex, data) {
        const separator = "_";
        let result = data;
        const indices = itemIndex.split(separator).map((index) => Number(index));
        for (let i = 0; i < indices.length - 1; i++) {
            result = result[indices[i]].items || [];
        }
        return result;
    }

    function renderConfirmMessage() {
        switch (true) {
            case confirmModel.isSaveConfirm:
                return <ConfirmMessage
                    yes={() => saveElement()}
                    no={() => goToPage(confirmModel.goToUrl)}
                    text={confirmModel.confirmText}
                />;
            case confirmModel.isDeleteConfirm:
                return <ConfirmMessage
                    yes={() => deleteElement()}
                    no={confirmClose}
                    text={confirmModel.confirmText}
                />;
            default:
                return null;
        }
    }


    /* Actons */

    function usersAndRulesLoad() {
        //TODO after load - loadData
        fetchGet(`${window.constants.usersAndRules}`,
            (data) => {
                if (haveAccessError(data))
                    return;

                setGroupsList(data.groups);
                setRules(data.roles);
                setSelectedGroup(null);
                setSelectedUser(null);
                setSelectedRule(null);
                setIsDataListLoaded(true);
                setIsDataLoading(false);
                setNeedReload(false);
                cleanConfirm();
            },
            (ex) => loadedError(ex));
    }

    function groupDataLoad(groupId) {
        const groupIdRequest = groupId ? parseInt(groupId) : 0;
        loadingProcess();

        fetchGet(`${window.constants.getGroup}/${groupIdRequest}`,
            (data) => {
                if (haveAccessError(data))
                    return;

                var findId = groupId ? parseInt(groupId) : null;
                var item = groupsList.find((i) => i.id === findId);
                if (item) {
                    item.selected = true;
                }

                removePreviousSelection();
                setGroupData(data);
                setUserData(null);
                setRuleData(null);
                setSelectedGroup(item);
                setSelectedUser(null);
                setSelectedRule(null);
                setIsUserTab(false);
                setIsRuleTab(false);
                setNeedReload(false);
                setIsDataLoading(false);
                cleanConfirm();
                setCanSave(false);
            },
            (ex) => loadedError(ex));
    }

    function userDataLoad(userId) {
        const userIdRequest = userId ? parseInt(userId) : 0;
        loadingProcess();

        fetchGet(`${window.constants.getUser}/${userIdRequest}`,
            (data) => {
                if (haveAccessError(data))
                    return;


                var findId = userId ? parseInt(userId) : null;
                var group = groupsList.find((i) => i.id === data.groupId);
                if (group) {
                    group.expanded = true;
                }
                var item = group.items.find((i) => i.id === findId);
                if (item) {
                    item.selected = true;
                }

                removePreviousSelection();
                setUserData(data);
                setGroupData(null);
                setRuleData(null);
                setSelectedUser(item);
                setSelectedGroup(null);
                setSelectedRule(null);
                setIsUserTab(true);
                setIsRuleTab(false);
                setNeedReload(false);
                setIsDataLoading(false);
                cleanConfirm();
                setCanSave(false);
            },
            (ex) => loadedError(ex));
    }

    function ruleDataLoad(ruleId) {
        loadingProcess();

        const ruleIdRequest = ruleId ? parseInt(ruleId) : 0;
        fetchGet(`${window.constants.getRole}/${ruleIdRequest}`,
            (data) => {
                if (haveAccessError(data))
                    return;

                const findId = ruleId ? parseInt(ruleId) : null;
                var item = rules.find((i) => i.id === findId);
                if (item) {
                    item.selected = true;
                }

                removePreviousSelection();
                setRuleData(data);
                setGroupData(null);
                setUserData(null);
                setSelectedRule(item);
                setSelectedGroup(null);
                setSelectedUser(null);
                setIsUserTab(false);
                setIsRuleTab(true);
                setNeedReload(false);
                setIsDataLoading(false);
                cleanConfirm();
                setCanSave(false);
            },
            (ex) => loadedError(ex));
    }

    function groupChanged(group) {
        setGroupData({ ...group, isUpdated: true });
        setCanSave(true);
    }

    function userChanged(user) {
        setUserData({ ...user, isUpdated: true });
        setCanSave(true);
    }

    function ruleChanged(rule) {
        setRuleData({ ...rule, isUpdated: true });
        setCanSave(true);
    }

    function showGroupsAndUsers() {
        setIsRuleTab(false);
        setSelectedRule(null);
    }

    function showRules() {
        setIsUserTab(false);
        setIsRuleTab(true);
        setSelectedGroup(null);
        setSelectedUser(null);
    }

    function moveUser(updatedTree) {
        setGroupsList([...updatedTree]);
    }

    function removePreviousSelection() {
        if (selectedRule) {
            if (selectedRule.id === 0) {
                let index = rules.indexOf(selectedRule);
                if (index >= 0)
                    rules.splice(index, 1);
            }

            selectedRule.selected = false;
        } else if (selectedGroup) {
            if (selectedGroup.id === 0) {
                let index = groupsList.indexOf(selectedGroup);
                if (index >= 0)
                    groupsList.splice(index, 1);
            }
            selectedGroup.selected = false;
        } else if (selectedUser) {
            if (selectedUser.id === 0) {
                groupsList.some((i) => {
                    if (i.items && i.items.length > 0) {
                        let index = i.items.indexOf(selectedUser);
                        if (index >= 0)
                            i.items.splice(index, 1);
                        return index >= 0;
                    }
                    return false;
                });
            }
            selectedUser.selected = false;
        }
    }

    function createNewUser(users, parentGroup) {
        var newItem = RulesAndUsersFunctions.getNewUser(users, parentGroup);
        var user = { id: newItem.id, name: newItem.name, role: newItem.rule, selected: true };
        var group = groupsList.find((i) => i.id === newItem.groupId);
        group.items = [...group.items, user];
        group.expanded = true;
        removePreviousSelection();

        setUserData(newItem);
        setGroupData(null);
        setRuleData(null);
        setGroupsList([...groupsList]);
        setIsUserTab(true);
        setIsRuleTab(false);
        setSelectedGroup(null);
        setSelectedUser(user);
        setSelectedRule(null);
        cleanConfirm();
        setCanSave(true);
    }

    function groupDataDelete(groupId) {
        const groupIdRequest = groupId ? parseInt(groupId) : 0;
        fetchGet(`${window.constants.deleteGroup}/${groupIdRequest}`,
            (data) => {
                if (haveAccessError(data))
                    return;

                const existedGroup = groupsList.find((m) => m.id === groupIdRequest);
                var index = groupsList.indexOf(existedGroup);
                groupsList.splice(index, 1);

                setGroupsList(groupsList);
                setSelectedGroup(null);
                setSelectedUser(null);
                setSelectedRule(null);
                setGroupData(null);
                cleanConfirm();
            },
            (ex) => loadedError(ex));
    }

    function userDataDelete(userId) {
        const userIdRequest = userId ? parseInt(userId) : 0;
        fetchGet(`${window.constants.deleteUser}/${userIdRequest}`,
            (data) => {
                if (haveAccessError(data))
                    return;

                let existedUser = null;
                var group = groupsList.find(i => {
                    if (i.items && i.items.length > 0) {
                        let user = i.items.find((u) => u.id === userIdRequest)
                        if (user == -1) {
                            return false;
                        }
                        existedUser = user;
                        return true;
                    }
                    return false;
                });
                if (existedUser != null) {
                    var index = group.items.indexOf(existedUser);
                    group.items.splice(index, 1);
                }

                setGroupsList([...groupsList]);
                setSelectedGroup(null);
                setSelectedUser(null);
                setSelectedRule(null);
                setUserData(null);
                cleanConfirm();
            },
            (ex) => loadedError(ex));
    }

    function ruleDataDelete(ruleId) {
        const ruleIdRequest = ruleId ? parseInt(ruleId) : 0;
        savingProcess();
        fetchGet(`${window.constants.deleteRole}/${ruleIdRequest}`,
            (data) => {
                if (haveAccessError(data))
                    return;

                const existedRule = rules.find((m) => m.id === ruleIdRequest);
                var index = rules.indexOf(existedRule);
                rules.splice(index, 1);

                setRules(rules);
                setSelectedGroup(null);
                setSelectedUser(null);
                setSelectedRule(null);
                setRuleData(null);
                cleanConfirm();
            },
            (ex) => loadedError(ex));
    }

    function groupDataSave() {
        var isNew = groupData.id == 0;
        savingProcess();
        fetchPost(`${window.constants.saveGroup}`,
            groupData,
            (data) => {
                if (haveAccessError(data) || haveSaveError(data))
                    return;

                const findId = groupData?.id ? parseInt(groupData.id) : null;
                var item = groupsList.find((i) => i.id === findId);
                item.id = data.id;
                setGroupData(data);
                setSelectedGroup(item);
                setSelectedUser(null);
                setSelectedRule(null);
                setIsUserTab(false);
                setIsRuleTab(false);
                setNeedReload(false);
                setIsDataLoading(false);
                cleanConfirm();
                setCanSave(false);
                setNeedPush(isNew);
            },
            (ex) => loadedError(ex));
    }

    function userDataSave() {
        var isNew = userData.id == 0;
        var userModel = {
            ...userData,
            ...CardSaveFunctions.renderValues(userData.Fields)
        };
        savingProcess();
        fetchPost(`${window.constants.saveUser}`,
            userModel,
            (data) => {
                if (haveAccessError(data) || haveSaveError(data))
                    return;

                const findId = userData?.id ? parseInt(userData.id) : null;
                var group = groupsList.find((i) => i.id === data.groupId);
                var item = group.items.find((i) => i.id === findId);
                item.id = data.id;
                setUserData(data);
                setSelectedUser(item);
                setSelectedGroup(null);
                setSelectedRule(null);
                setIsUserTab(true);
                setIsRuleTab(false);
                setNeedReload(false);
                setIsDataLoading(false);
                cleanConfirm();
                setCanSave(false);
                setNeedPush(isNew);
            },
            (ex) => loadedError(ex));
    }

    function ruleDataSave() {
        var isNew = ruleData.id == 0;
        savingProcess();
        fetchPost(`${window.constants.saveRole}`,
            ruleData,
            (data) => {
                if (haveAccessError(data) || haveSaveError(data)) {
                    return;
                }

                //else if (isNew) {
                //    //navigate(`/admin/rules-and-users/rule/${id}`);
                //}

                const findId = ruleData?.id ? parseInt(ruleData.id) : null;
                var item = rules.find((i) => i.id === findId);
                item.id = data.id;
                setRuleData(data);
                setSelectedRule(item);
                setSelectedGroup(null);
                setSelectedUser(null);
                setIsUserTab(false);
                setIsRuleTab(true);
                setNeedReload(false);
                setIsDataLoading(false);
                cleanConfirm();
                setCanSave(false);
                setNeedPush(isNew);
            },
            (ex) => loadedError(ex));
    }

    function setConfirm(isDeleteConfirm, isSaveConfirm, goToUrl = null) {
        var confirm = false;
        if (isDeleteConfirm) {
            confirm = window.captions.DeleteItemConfirm;
        } else if (isSaveConfirm) {
            confirm = window.captions.CloseConfirmSaving;
        }

        setConfirmModel({
            confirmText: confirm,
            isDeleteConfirm: isDeleteConfirm,
            isSaveConfirm: isSaveConfirm,
            goToUrl: goToUrl
        });
    }

    function haveAccessError(data) {
        if (!data?.accessError)
            return false;

        setAccessError(data.accessError);
        setIsDataListLoaded(true);
        setIsDataLoading(false);
        setNeedReload(false);
        return true;
    }

    function haveSaveError(data) {
        if (data?.isOverFlowUsers) {
            setSaveingError(`${window.captions.DatabaseIdOverflow} - ${window.captions.Users}: ${data.name}`);
            return true;
        }

        if (data?.isOverFlowGroups) {
            setSaveingError(`${window.captions.DatabaseIdOverflow} - ${window.captions.Groups} ${data.name}`);
            return true;
        }

        if (data?.dublicatedNames && data.dublicatedNames.length != 0) {
            setSaveingError(RulesAndUsersFunctions.getDuplicateNameInfo(data.dublicatedNames));
            return true;
        }

        if (data?.isIncorrectPassword) {
            setSaveingError(window.captions.IncorrectPassword);
            return true;
        }

        return false;
    }

    /* Redux */

    function setSaveingError(text) {
        setSaveError(text);
        setNeedReload(false);
        setIsDataLoading(false);
    }

    function cleanConfirm() {
        setConfirmModel({
            confirmText: false,
            isDeleteConfirm: false,
            isSaveConfirm: false,
            goToUrl: null
        });
    }

    function loadingProcess() {
        setIsDataLoading(true);
        setMessage(window.captions.LoadingData);
    }

    function savingProcess() {
        setIsDataLoading(true);
        setMessage(window.captions.SavingData);
    }

    function loadedError(ex) {
        setError(ex);
        setIsDataListLoaded(true);
        setIsDataLoading(false);
        setNeedReload(false);
        cleanConfirm();
    }
}