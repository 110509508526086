import React from "react";
import { useState, useEffect } from 'react';
import "isomorphic-fetch";
import { Dialog } from "@progress/kendo-react-dialogs";
import { headersGet } from "../../utils/requestsHelper.jsx";
import AudioPlayer from "../../components/audioPlayer.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";

export default function DialogRecordPlay({ link, onClose }) {
    const [recordPath, setRecordPath] = useState("");
    const [pending, setPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(), []);

    let content;
    if (pending) {
        content = <LoadingMask inline text={pending} />;
    } else if (error) {
        content = <ErrorMask inline error={error} />;
    } else {
        content = <AudioPlayer url={recordPath} />;
    }

    return <Dialog title={window.captions.RuleRecordsListen} onClose={onClose} width={800}>
        {content}
    </Dialog>;

    function fetchData() {
        const setRecordResult = (message) => {
            setRecordPath(message);
            setPending(null);
        };

        const setErrorResult = (message) => {
            setPending(null);
            setError(message);
        };

        setPending(window.captions.LoadingData);
        fetch(`${window.constants.fileRecord}?record=${link}`, headersGet()).then(function (response) {
            if (response.ok) {
                let filename = "";
                const disposition = response.headers.get("content-disposition");
                if (disposition && disposition.indexOf("attachment") !== -1) {
                    console.log(disposition);
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches && matches[1]) {
                        filename = decodeURI(matches[1].replace(/['"]/g, ""));
                    }
                }

                if (filename === "") {
                    throw "No file name detected";
                }

                if (filename.endsWith(".txt")) {
                    throw window.captions.ErrorFileUpload;
                }

                return response.blob().then(function (data) {
                    setRecordResult(URL.createObjectURL(data));
                });
            } else {
                return response.json().then(function (jsonError) {
                    setErrorResult(jsonError);
                });
            }
        }).catch(function (error) {
            setErrorResult(error.message ? error.message : error);
        });
    }
}