import React from "react";
import { useState, useEffect } from 'react';
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import { NumericTextBox, TextBox } from "@progress/kendo-react-inputs";

export default function FieldMaskSettings(props) {
    const [fieldId, setFieldId] = useState(0);
    const [maskSymbol, setMaskSymbol] = useState('');
    const [maskFirstSymbolsCount, setMaskFirstSymbolsCount] = useState(0);
    const [maskLastSymbolsCount, setMaskLastSymbolsCount] = useState(0);

    useEffect(() => fetchData(), [props.item.fieldId]);

    let content = [];
    const style = { width: "100%" };

    renderPair(content,
        window.captions.MaskSymbol,
        "FieldMask",
        () => <TextBox style={style} maxLength={1} value={maskSymbol} onChange={changeMaskSymbol} />,
        true,
        true,
        true);

    renderPair(content,
        window.captions.NumberFirstCharacters,
        "FieldMaskSymbolsCount",
        () => <NumericTextBox field={fieldId}
            min={0}
            value={maskFirstSymbolsCount}
            onChange={changeFirstCount} />,
        true,
        true,
        true);

    renderPair(content,
        window.captions.NumberLastCharacters,
        "FieldMaskLastSymbolsCount",
        () => <NumericTextBox field={fieldId}
            min={0}
            value={maskLastSymbolsCount}
            onChange={changeLastCount} />,
        true,
        true,
        true);

    if (maskSymbol) {
        var exampleValue = "ExampleValue12345";
        var exampleValueFirst = "";
        if (exampleValue.length - maskFirstSymbolsCount - maskLastSymbolsCount >= 0)
            exampleValueFirst = `${exampleValue.substr(0, maskFirstSymbolsCount)}`;

        var exampleValueLast = "";
        if (exampleValue.length - maskFirstSymbolsCount - maskLastSymbolsCount >= 0)
            exampleValueLast = `${exampleValue.substr(exampleValue.length - maskLastSymbolsCount)}`;

        var exampleMaskCount = exampleValue.length - exampleValueFirst.length - exampleValueLast.length;
        var exampleMask = "";

        exampleMask = exampleMaskCount <= 0 ? "" : maskSymbol.repeat(exampleMaskCount);
        if (maskFirstSymbolsCount > 0 || maskLastSymbolsCount)
            exampleValue = `${exampleValueFirst}${exampleMask}${exampleValueLast}`;

        var text = `${window.captions.Example}: ${exampleValue}`;
        content.push(<p key={`Example_${fieldId}`}>{text}</p>);
    }

    return content;


    function fetchData() {
        if (!props || !props.item)
            return;

        var data = props.item;
        setFieldId(data.fieldId);
        setMaskSymbol(data.maskSymbol);
        setMaskFirstSymbolsCount(data.maskFirstSymbolsCount ? data.maskFirstSymbolsCount : 0);
        setMaskLastSymbolsCount(data.maskLastSymbolsCount ? data.maskLastSymbolsCount : 0);
    }

    function changeMaskSymbol(e) {
        var value = isNaN(e.value) ? 0 : e.value;
        if (value === null)
            value = 0;

        setMaskSymbol(value);
        sendChanged();
    }

    function changeFirstCount(e) {
        var value = isNaN(e.value) ? 0 : e.value;
        if (value === null)
            value = 0;

        setMaskFirstSymbolsCount(value);
        sendChanged();
    }

    function changeLastCount(e) {
        var value = isNaN(e.value) ? 0 : e.value;
        if (value === null)
            value = 0;

        setMaskLastSymbolsCount(value);
        sendChanged();
    }

    function sendChanged() {
        props.onChange({
            fieldId: fieldId,
            maskSymbol: maskSymbol,
            maskFirstSymbolsCount: maskFirstSymbolsCount,
            maskLastSymbolsCount: maskFirstSymbolsCount
        });
    }
}