import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import gridFunctions from "../../components/gridFunctions.jsx";
import GridColorCell from "../../components/cells/gridColorCell.jsx";
import GridDateTimeCell from "../../components/cells/gridDateTimeCell.jsx";
import GridTextCell from "../../components/cells/gridTextCell.jsx";
import DialogTask from "../dialogs/dialogTask.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import GridLoadingPanel from "../../components/gridLoadingPanel.jsx";
import taskFunctions from "./taskFunctions.jsx";
import DialogTaskMenuAction from "../dialogs/dialogTaskMenuAction.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";
import { fetchGet } from '../../utils/requestsHelper.jsx';

export default function Task() {
    const lastSelectedIndex = useRef(0);

    const [columns, setColumns] = useState([]);
    const [menuDataSource, setMenuDataSource] = useState(null);
    const [taskStates, setTaskStates] = useState([]);
    const [yearItems, setYearItems] = useState([]);
    const [monthItems, setMonthItems] = useState([]);
    const [taskFilter, setTaskFilter] = useState({ showAll: false, states: [], year: null, month: null });
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [canAddTask, setCanAddTask] = useState(false);
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [], group: [] });
    const [previewTaskId, setPreviewTaskId] = useState(0);
    const [pending, setPending] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isNewTask, setIsNewTask] = useState(false);
    const [isPreviewTask, setIsPreviewTask] = useState(false);
    const [isContextMenuAction, setIsContextMenuAction] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [menuActionType, setMenuActionType] = useState(null);
    const [error, setError] = useState(null);

    const user = useSelector(state => state.header.model);
    const isMobile = useSelector(state => state.header.isMobile);
    const accessTasks = user.access.tasks;

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.Tasks}`;
        taskColumnsLoad();
    }, []);

    useEffect(() => {
        if (isLoaded)
            taskLoad(taskFilter);
    }, [isLoaded]);

    if (!accessTasks)
        return <ErrorMask error={window.captions.AccessDenied} />;

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    let dialog = "";
    if (isNewTask) {
        dialog =
            <DialogTask cardId={0}
                cardEntity="users"
                taskId={0}
                projectId={0}
                key="dialogTaskAdd"
                onClose={(needRefresh) => needRefresh === true
                    ? refreshSource()
                    : setIsNewTask(false)} />;
    }
    if (isContextMenuAction) {
        const selectedItems = data.some(i => i.Selected)
            ? data.filter((i) => i.Selected)
            : selectedItem
                ? [selectedItem]
                : [];
        let users = null;
        if (menuActionType === "changeExecutor") {
            const authorData = taskFunctions.getChangeAuthorUserGroups(user, selectedItems, menuDataSource);
            users = authorData.userGroups;
        }
        dialog = <DialogTaskMenuAction actionType={menuActionType}
            data={selectedItems}
            users={users}
            onClose={menuActionOnClose} />;

    }
    if (isPreviewTask) {
        dialog =
            <DialogTask cardId="0"
                projectId={0}
                cardEntity="users"
                taskId={previewTaskId}
                key="dialogTaskPreview"
                onClose={() => previewDialog(false, 0)} />;
    }

    const isOverdueCell = (p) => p.dataItem[p.field]
        ? <td {...getLinkCellStyle(p)}>
            <i className={`dm-i dm-i-exclamation-triangle dm-error`} />
        </td>
        : <td {...getLinkCellStyle(p)} />;

    const taskLinkCell = (p) => p.rowType === "data"
        ? <td {...getLinkCellStyle(p)}>
            <Button icon="menu"
                onClick={() => previewDialog(true, p.dataItem[p.field])} />
        </td>
        : <td {...getLinkCellStyle(p)} />;;


    const leftTimeCell = (p) => {
        const td = { style: p.style, ...p.tdProps };
        if (p.className)
            td.className = p.className;

        if (!p.dataItem)
            return <td {...td} />;

        const range = p.dataItem[p.field];
        if (!range || p.dataItem["IsFinished"])
            return <td {...td} />;

        const leftTime = taskFunctions.getLeftTime(new Date(range));
        return <td {...td}>
            <div className="dm-text-cell"
                style={{ color: p.dataItem["LeftDaysColor"] }} title={leftTime}>
                {leftTime}
            </div>
        </td>;
    };

    const typeStateColor = (p) => {
        const color = p.dataItem["TaskStateColor"];
        return color ? <GridTextCell color={color} {...p} /> : <GridTextCell {...p} />;
    };

    const gridMenuCell = (p) => {
        var menuItems = taskFunctions.taskMenuItems(user, data, menuDataSource, p.dataItem);
        if (menuItems.length === 0)
            return <td {...getLinkCellStyle(p)} />;

        return <td {...getLinkCellStyle(p)}>
            <DropDownButton
                onItemClick={(e) => handleOnSelect(e, p.dataItem)}
                icon="more-vertical"
                items={menuItems}
                textField="name" />
        </td>;
    };

    let pager = true;
    if (isMobile) {
        pager = {
            buttonCount: 9,
            info: false,
            type: "numeric",
            previousNext: false
        };
    }
    if (total <= 20)
        pager = false;

    const columnsToShow = gridFunctions.renderGridColumns(columns);
    return <div className="dm-full-height" key="schedulerView">
        <h1 className="dm-title">{window.captions.Tasks}</h1>
        <div className="dm-under-title-content">
            <Grid className="dm-full-wh"
                sortable={true}
                groupable={!isMobile}
                pageable={pager}
                resizable={true}
                total={total}
                data={data}
                {...dataState}
                onDataStateChange={handleDataStateChange}
                selectedField="Selected"
                onSelectionChange={selectionChange}
                onHeaderSelectionChange={headerSelectionChange}
                onRowClick={rowClick}
                onExpandChange={expandChange}
                expandField="expanded">
                <GridToolbar>
                    <DropDownCheckedTree
                        style={{ width: isMobile ? "100%" : "calc(100% - 405px)" }}
                        treeData={taskStates}
                        selectedIdList={taskFilter.states}
                        selectionChanged={changeTaskStates} />
                    {canAddTask && <Button icon="add"
                        style={{ height: "40px" }}
                        className="dm-positive-filled"
                        onClick={() => setIsNewTask(true)}>
                        {isMobile
                            ? ""
                            : window.captions.Task}
                    </Button>}
                    <DropDownList
                        style={{ width: 115 }}
                        dataItemKey="id"
                        data={monthItems}
                        textField="name"
                        value={taskFilter.month}
                        onChange={changeSelectedMonth} />
                    <DropDownList
                        style={{ width: 90 }}
                        dataItemKey="id"
                        data={yearItems}
                        textField="name"
                        value={taskFilter.year}
                        onChange={changeSelectedYear} />
                    <Button togglable={true}
                        style={{ height: "40px" }}
                        onClick={changeShowAll}
                        title={window.captions.ShowNotifyFromAllClients}>
                        <i className={`dm-i dm-i-users`} />
                    </Button>
                </GridToolbar>
                <GridColumn field="Selected" width="40" headerSelectionValue={headerSelectionValue()} />
                <GridColumn field="SourceTask" title={window.captions.Source}
                    cells={{ data: GridTextCell }} width="100" />
                <GridColumn field="TaskGroup" title={window.captions.Group}
                    cells={{ data: GridTextCell }} width="200" />
                <GridColumn field="TaskType" title={window.captions.Caption}
                    cells={{ data: GridTextCell }} width="200" />
                <GridColumn field="Range" title={window.captions.LeftTime} cells={{ data: leftTimeCell }}
                    filterable={false} width="160" />
                <GridColumn field="Finished" title={window.captions.FinishDate}
                    cells={{ data: GridDateTimeCell }} filterable={false} groupable={false} width="130" />
                <GridColumn field="StateName" title={window.captions.State}
                    cells={{ data: typeStateColor }}
                    filterable={false} groupable={true} width="110" />
                <GridColumn field="ClientName" title={window.captions.Fio}
                    cells={{ data: GridTextCell }}
                    filterable={false} groupable={false} width="200" />
                <GridColumn field="LoanNumber" title={window.captionsDynamic.LoanNumber}
                    cells={{ data: GridTextCell }} filterable={false} groupable={false} width="100" />
                <GridColumn field="LoanState" title={window.captions.Stage} width="80" />
                <GridColumn field="Started" title={window.captions.StartDate}
                    cells={{ data: GridDateTimeCell }} filterable={false} groupable={false} width="130" />
                <GridColumn field="Range" title={window.captions.PerformTill}
                    cells={{ data: GridDateTimeCell }} filterable={false} groupable={false} width="130" />
                <GridColumn field="Comment" title={window.captions.Comment}
                    cells={{ data: GridTextCell }}
                    filterable={false} groupable={false} width="100" />
                <GridColumn field="Creator" title={window.captions.Author}
                    cells={{ data: GridTextCell }}
                    filterable={false} width="120" />
                <GridColumn field="Participants" title={window.captions.Participants}
                    cells={{ data: GridTextCell }} filterable={false} groupable={false} width="120" />
                <GridColumn field="Executor" title={window.captions.Executor}
                    cells={{ data: GridTextCell }} width="120" />
                {columnsToShow}
                <GridColumn locked={true} field="menu" title=" " width="35" cells={{ data: gridMenuCell }} />
                {isMobile
                    ? null
                    : <GridColumn locked={true} field="IsOverdueBool" title=" " cells={{ data: isOverdueCell }}
                        filterable={false} groupable={false} width="35" />}
                <GridColumn locked={true} field="TaskTypeColor" title=" " cells={{ data: GridColorCell }}
                    filterable={false} groupable={false} width="35" />
                <GridColumn locked={true} field="Id" title=" " cells={{ data: taskLinkCell }}
                    filterable={false} groupable={false} width="35" />
            </Grid>
            {loadingPanel}
            {dialog}
        </div>
    </div>;

    function handleDataStateChange(changeEvent) {
        const newState = {
            skip: changeEvent.dataState.skip ? changeEvent.dataState.skip : 0,
            take: changeEvent.dataState.take ? changeEvent.dataState.take : 20,
            sort: changeEvent.dataState.sort,
            group: changeEvent.dataState.group
        };

        if (newState.group && gridFunctions.hasDuplicatesGroups(newState.group))
            return;

        taskLoad(taskFilter, newState);
    }

    function changeTaskStates(e) {
        taskLoad({ ...taskFilter, states: e.value });
    }

    function changeShowAll() {
        taskLoad({ ...taskFilter, showAll: !taskFilter.showAll });
    }

    function changeSelectedYear(e) {
        taskLoad({ ...taskFilter, year: e.target.value });
    }

    function changeSelectedMonth(e) {
        taskLoad({ ...taskFilter, month: e.target.value });
    }

    function menuActionOnClose(success) {
        taskContextMenuAction(false);
        if (success)
            taskLoad(taskFilter);
    }

    function handleOnSelect(e, item) {
        taskContextMenuAction(true, e.item.id, item);
    }

    function selectItems(items, id, selected) {
        return items.map(item => {
            if (item.items) {
                item.items = selectItems(item.items, id, selected);
                return item;
            }

            if (id === 0 || item.Id === id) {
                item.Selected = selected;
            }
            return item;
        });
    }

    function allSelected(items) {
        return items.findIndex(item => item.items
            ? allSelected(item.items) === false
            : item.Selected === false) ===
            -1;
    }

    function headerSelectionValue() {
        return data && allSelected(data);
    }

    function selectionChange(event) {
        if (data)
            taskRefreshItems(selectItems(data, event.dataItem["Id"], !event.dataItem["Selected"]));
    }

    function headerSelectionChange(event) {
        if (!data)
            return;

        const checked = event.syntheticEvent.target.checked;
        taskRefreshItems(selectItems(data, 0, checked));
    }

    function expandChange(event) {
        event.dataItem[event.target.props.expandField] = event.value;
        taskRefreshItems([...data]);
    }

    function rowClick(event) {
        if (!data)
            return;

        let last = lastSelectedIndex.current;
        const current = data.findIndex(dataItem => dataItem === event.dataItem);
        if (!current || current < 0)
            return;

        if (!event.nativeEvent.shiftKey) {
            lastSelectedIndex.current = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            data.forEach(item => (item.Selected = false));
        }
        const select = !event.dataItem.Selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            data[i].Selected = select;
        }
        taskRefreshItems([...data]);
    }

    function refreshSource() {
        taskLoad(taskFilter);
    }

    /* Functions */

    function taskColumnsLoad() {
        fetchGet(window.constants.tasksColumns,
            data => {
                if (data) {
                    const years = taskFunctions.getTaskYearItems();
                    const monthes = taskFunctions.getTaskMonthItems();
                    setTaskStates(taskFunctions.getTaskStatesItems());
                    setYearItems(years);
                    setMonthItems(monthes);
                    setTaskFilter({
                        showAll: false,
                        states: [0],
                        year: years.find(i => i.id === new Date().getFullYear()),
                        month: monthes.find(i => i.id === new Date().getMonth() + 1)
                    });
                    setColumns(data.columns);
                    setCanAddTask(data.canAddTask);
                    setMenuDataSource(data.menuDataSource);
                    setError(null);
                    setIsLoaded(true);
                    setPending(true);

                } else
                    loadedError(window.captions.LoadDataError);
            },
            ex => loadedError(ex)
        );
    }

    function taskLoad(taskFilter, newState) {
        var fetchDataState = dataState;
        if (newState) {
            setDataState(newState);
            fetchDataState = newState;
        }

        const hasGroups = fetchDataState.group && fetchDataState.group.length;
        const states = taskFilter.states && taskFilter.states.length > 0 ? taskFilter.states.join('LN') : '0';
        const year = taskFilter.year ? taskFilter.year.id : 0;
        const month = taskFilter.month ? taskFilter.month.id : 0;
        const requestUrl =
            `${window.constants.tasksElements}/${taskFilter.showAll}/${states}/${year}/${month}?${toDataSourceRequestString(fetchDataState)}`;

        setPending(true);
        fetchGet(requestUrl,
            model => {
                if (model) {
                    setData(hasGroups ? translateDataSourceResultGroups(model.data) : model.data);
                    setTotal(model.total);
                    setTaskFilter(taskFilter);
                    setError(null);
                    setIsNewTask(false);
                    setPending(false);
                } else
                    loadedError(window.captions.LoadDataError);
            },
            ex => loadedError(ex)
        );
    }

    function previewDialog(isPreviewTask, id) {
        setIsPreviewTask(isPreviewTask);
        setPreviewTaskId(id);
    }

    function taskContextMenuAction(isContextMenuAction, menuActionType = null, selected = null) {
        setIsContextMenuAction(isContextMenuAction);
        setMenuActionType(menuActionType);
        setSelectedItem(selected);
    }

    function loadedError(ex) {
        setError(ex);
        setIsLoaded(true);
    }

    function taskRefreshItems(newData) {
        const hasGroups = dataState.group && dataState.group.length;

        if (newData) {
            setData(hasGroups ? translateDataSourceResultGroups(newData) : newData);
        }
        setPending(false);
    }
}