import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import FileHelper from "../../utils/fileHelper.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";
import { documentsParents } from "../../utils/systemConstants.jsx";

export default function GridFileCell(props) {
    const value = props.dataItem[props.field];
    const size = props.dataItem["size"];
    const objectType = props.dataItem["objectType"];
    if (size === 0)
        return <td {...getLinkCellStyle(props)}></td>;

    var clickAction = objectType == documentsParents.letter
        ? () => FileHelper.getLetter(value)
        : () => FileHelper.getDocument(value);
    return <td {...getLinkCellStyle(props)} title={window.captions.Download}>
        <Button iconClass="dm-i dm-i-paperclip" onClick={clickAction} />
    </td>;
}