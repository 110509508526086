import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { fieldTypes } from "../../utils/systemConstants.jsx";

export function renderViewer(fieldModel, verify) {
    if (fieldModel.type === fieldTypes.image) {
        return <img src={fieldModel.value} alt={fieldModel.name} />;
    }

    if (fieldModel.type === fieldTypes.color) {
        return <span className="dm-color-cell" style={{ backgroundColor: fieldModel.value, margin: "8px 0" }} />;
    }

    let textFull = fieldModel.text ?? fieldModel.value;
    if (fieldModel.verify && verify) {
        if (!textFull)
            textFull = window.captions.Unseted;

        return <div>
            <div style={{ color: "#cccccc" }}>{textFull}</div>
            <div>{fieldModel.verify.value}</div>
            {(fieldModel.verify.canChange
                ? <div>
                    <Button className="dm-positive-filled" iconClass="dm-i dm-i-check" onClick={() => verify(fieldModel.verify, true)}>{window.captions.Approve}</Button>
                    <Button className="dm-negative-filled dm-m-left" iconClass="dm-i dm-i-close_small" onClick={() => verify(fieldModel.verify, false)}>{window.captions.Cancel}</Button>
                </div>
                : null
            )}
        </div>;
    }

    const text = textFull.toString().split("\n");
    if (text.length === 1)
        return fieldModel.color
            ? <div style={{ color: fieldModel.color }}>{text[0]}</div>
            : <div>{text[0]}</div>;

    if (fieldModel.color) {
        return <div style={{ color: fieldModel.color }}>{text.map((i, key) => {
            return <div key={key}>{i}</div>;
        })}</div>;
    }

    return <div>{text.map((i, key) => {
        return <div key={key}>{i}</div>;
    })}</div>;
}