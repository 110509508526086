import React from "react";
import { useState, useEffect } from 'react';
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { NumericTextBox } from "@progress/kendo-react-inputs";

export default function FieldSizeLimit(props) {
    const [fieldId, setFieldId] = useState(0);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => fetchData(), [props.item.id]);

    let content = [];
    const style = { width: "100%" };
    let isNewField = fieldId === 0;

    let minControl = isNewField
        ? <NumericTextBox style={style} min={0} value={minValue} onChange={changeMinLength} />
        : <NumericTextBox style={style} min={0} max={maxValue - 1} value={minValue} onChange={changeMinLength} />;

    renderPair(content,
        window.captions.MinLength,
        "FieldMinLength",
        () => minControl,
        true);

    renderPair(content,
        window.captions.MaxLength,
        "FieldMaxLength",
        () => <NumericTextBox style={style} min={0} disabled={fieldId > 0} value={maxValue} onChange={changeMaxLength} />,
        true);

    return content;

    function fetchData() {
        if (!props || !props.item)
            return;

        var data = props.item;
        setFieldId(data.fieldId);
        setMinValue(data.minValue);
        setMaxValue(data.maxValue);
    }

    function changeMinLength(e) {
        setMinValue(e.value);

        if (newValue.maxValue <= newValue.minValue)
            setMaxValue(newValue.minValue <= 0 ? 0 : newValue.minValue + 1);

        fireChanges();
    }

    function changeMaxLength(e) {
        setMaxValue(e.value);

        if (newValue.maxValue <= newValue.minValue)
            setMinValue(newValue.maxValue <= 0 ? 0 : newValue.maxValue - 1);

        fireChanges();
    }

    function fireChanges() {
        let newValue = {
            fieldId: fieldId,
            minValue: minValue,
            maxValue: maxValue
        };
        props.onChange(newValue);
    }
}