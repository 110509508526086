import React, { useState, useEffect } from 'react';
import {
    getBooleanSources,
    getFormatDate,
    getFormatDateTime,
    getFormatTime,
    dateTimeWidth,
    dateWidth,
    timeWidth,
    numericWidth,
    getUiSources,
    getUiLanguages
} from "../../../../utils/systemConstants.jsx";
import { TextBox, NumericTextBox, RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import PassportControl from "../../../cards/controls/passportControl.jsx";
import CardReducerFunctions from "../../../cards/cardReducerFunctions.jsx";
import ColorPickerControl from "../../../cards/controls/colorPickerControl.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DropDownTreeView from "../../../../components/controls/dropDownTreeView.jsx";
import { DateTimePicker, DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import LoadingMask from "../../../../components/loadingMask.jsx";
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import LangItemEditor from "../../../../components/controls/langItemEditor.jsx"
import {
    Card,
    CardTitle,
    CardBody,
    CardActions,
} from "@progress/kendo-react-layout";

export function EditListViewTextBoxItem({ item, readonly, onSaveItem, onDeleteItem }) {
    return <div className="k-d-flex-row dm-m-left dm-m-top dm-m-bottom">
        <TextBox className="dm-m-right" readOnly={readonly} value={item.dataItem} onChange={onChangeValue} />
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function onChangeValue(e) {
        var newValue = { dataItem: e.value, index: item.index };
        setItem(newData);
        onSaveItem(newValue);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        onDeleteItem(item);
    };
}

export function EditListViewItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    if (!item.dataItem)
        return <LoadingMask />;

    if (props.isFieldBlock) {
        var items = [];

        item.dataItem.items.map(i => items.push(<li>{i}</li>));

        return <div className="dm-container" key={`fieldBlock_${item.index}`}>
            <div className="k-flex-row dm-m-left ">
                <span className="k-flex-column k-icon k-font-icon k-i-warning k-color-error"></span>
                <span className="k-flex-column dm-m-left">{item.dataItem.module.name}:</span>
            </div>
            <ul className="dm-m-left">{items}</ul>
        </div>;
    } else {
        return <div className="k-d-flex-row dm-m-top dm-m-bottom" key={`fieldBlock_${item.index}`}>
            <TextBox className="dm-m-left dm-m-right" disabled={true} value={item.dataItem} />
            <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
        </div>;
    }

    function fetchData() {
        if (props)
            setItem(props.item);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.deleteItem(item);
    };
}

export function EditListViewPassportItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId]);

    const passportTypes = [
        { id: 1, name: window.captions.PassportTypeBook },
        { id: 2, name: window.captions.PassportTypeIdCard }
    ];

    return <div key={`Id${item.index}`} className="k-d-flex-row dm-m-top dm-m-bottom">
        <PassportControl
            changedPassportValue={changedPassportValue}
            changedPassportDropValue={changedPassportDropValue}
            value={item.dataItem}
            passportTypes={passportTypes} />
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function fetchData() {
        if (props)
            setItem(props.item);
    }

    function changePassportField(payload) {
        var newPasswordValue = CardReducerFunctions.passportFieldEdited(item.dataItem, payload, true);
        var newValue = { dataItem: newPasswordValue, index: item.index };
        setItem(newValue);
        props.onSaveItem(newValue);
    }

    function changedPassportValue(e) {
        changePassportField({
            fieldId: props.fieldId,
            item: item.dataItem,
            property: e.target.props === undefined ? "" : e.target.props.property,
            value: e.value === undefined ? e.target.value : e.value
        });
    }

    function changedPassportDropValue(e) {
        changePassportField({
            fieldId: props.fieldId,
            item: item.dataItem,
            property: e.target.props.property,
            value: e.item === undefined ? e.target.value.id : e.item.id,
            valueName: e.item === undefined ? e.target.value.name : e.item.name
        });
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.onDeleteItem(item);
    };
}

export function EditListViewNumericItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    let isIntFormat = props.isInteger ? props.isInteger : false;
    let format = isIntFormat ? "#" : props.fractionalPart ? `n${props.fractionalPart}` : "n2";

    return <div key={`Id${item.index}`} className="k-d-flex-row dm-m-left dm-m-top dm-m-bottom">
        <NumericTextBox className="dm-m-right" width={numericWidth} format={format} min={0} value={item.dataItem} onChange={onChangeValue} />
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function fetchData() {
        if (props)
            setItem(props.item);
    }

    function onChangeValue(e) {
        var newValue = { dataItem: e.value, index: item.index };
        setItem(newValue);
        props.onSaveItem(newValue);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.onDeleteItem(item);
    };
}

export function EditListViewColorItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    var valueColor = item ? item.dataItem : undefined;
    if (item.dataItem && item.dataItem.length === 9)
        valueColor = `#${item.dataItem.substr(item.dataItem.length - 6)}`;

    return <div key={`Id${item.index}`} className="k-d-flex-row dm-m-left dm-m-top dm-m-bottom">
        <ColorPickerControl view={"combo"}
            value={valueColor}
            changeValue={onChangeValue} />
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function fetchData() {
        if (props)
            setItem(props.item);
    }

    function onChangeValue(e) {
        var newValue = { dataItem: e.value, index: item.index };
        setItem(newValue);
        props.onSaveItem(newValue);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.onDeleteItem(item);
    };
}

export function EditListViewDropdownItem(props) {
    const [item, setItem] = useState({});
    const [sources, setSources] = useState([]);

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    const style = { width: "100%" };
    let selectedItem = props.type === 9
        ? sources.find(i => i.id === item.dataItem)
        : sources.find(i => i === item.dataItem);

    let dropdown;
    switch (props.type) {
        case 8:
            dropdown = <DropDownList style={style}
                data={sources}
                value={selectedItem}
                onChange={onChangeValue} />;
            break;
        case 9:
            dropdown = <DropDownList style={style}
                textField="name"
                dataItemKey="id"
                data={sources}
                onChange={onChangeValue}
                value={selectedItem} />;
            break;
        default:
            dropdown = <DropDownTreeView style={style}
                field={`fieldIndex_${item.dataItem}`}
                selectedId={item.dataItem}
                treeData={sources}
                selectionChanged={onChangeValue} />;
            break;
    }

    return <div key={`Id${item.index}`} className="k-d-flex-row dm-m-left dm-m-top dm-m-bottom">
        {dropdown}
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function fetchData() {
        if (!props)
            return;

        setItem(props.item);
        setSources(props.sources);
    }

    function onChangeValue(e) {
        var value = props.type === 9 ? e.value.id : e.value;
        var newValue = { dataItem: value, index: item.index };
        setItem(newValue);
        props.onSaveItem(newValue);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.onDeleteItem(item);
    };
}

export function EditListViewBooleanItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    return <div key={`Id${item.index}`} className="k-d-flex-row dm-m-left dm-m-top dm-m-bottom">
        <div className="dm-boolean-input">
            <RadioGroup data={getBooleanSources()}
                key={`Bolean_${item.index}`}
                className="dm-m-right"
                onChange={onChangeValue}
                value={item.dataItem}
                layout="horizontal" />
        </div>
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function fetchData() {
        if (!props)
            return;

        setItem(props.item);
    }

    function onChangeValue(e) {
        var newValue = { dataItem: e.value, index: item.index };
        setItem(newValue);
        props.onSaveItem(newValue);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.onDeleteItem(item);
    };
}

export function EditListViewDateTimeItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    let picker;

    if (props.type === 3)
        picker = <DatePicker value={item.dataItem}
            width={dateWidth}
            formatPlaceholder={getFormatDate()}
            onChange={onChangeValue} />;

    if (props.type === 11)
        picker = <TimePicker value={item.dataItem}
            width={timeWidth}
            formatPlaceholder={getFormatTime()}
            onChange={onChangeValue} />;


    if (props.type === 13)
        picker = <DateTimePicker value={item.dataItem}
            width={dateTimeWidth}
            formatPlaceholder={getFormatDateTime()}
            onChange={onChangeValue} />;

    return <div key={`Id${item.index}`} className="k-d-flex-row dm-m-left dm-m-top dm-m-bottom">
        {picker}
        <Button icon="delete" className="dm-negative-filled dm-m-left" onClick={removeItem} />
    </div>;

    function fetchData() {
        if (props)
            setItem(props.item);
    }

    function onChangeValue(e) {
        var newValue = { dataItem: e.value, index: item.index };
        setItem(newValue);
        props.onSaveItem(newValue);
    }

    function removeItem() {
        if (item && !confirm(window.captions.DeleteItemConfirm))
            return;

        props.onDeleteItem(item);
    };
}

export function EditListViewStateItem(props) {
    const [state, setState] = useState({
        id: 0,
        name: null,
        langItem: {},
        selectLangItem: [],
        rowColor: "000000",
        code: "",
        isDefaultState: undefined,
        isDeleted: false,
        stateId: 0,
        canSave: true
    });

    useEffect(() => fetchData(props.item.dataItem.isDefaultState), [props.item.dataItem.isDefaultState]);

    if (!state || !state.name)
        return <LoadingMask />;

    const style = { width: "100%" };
    const cardStyle = {
        boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
        marginTop: "5px"
    };
    let content = [];

    if (state.isDeleted)
        return null;

    renderPair(content,
        window.captions.Identifier,
        `Id_${state.id}`,
        () => <TextBox style={style} value={state.id} readOnly={true} />,
        true,
        true,
        true);

    renderPair(content,
        window.captions.Caption,
        `StateNameEditor_${state.id}`,
        () => <LangItemEditor key={`langEditorState_${state.id}`}
            defaultName={state.name}
            style={style}
            langItem={state.langItem}
            namedChanged={changeName} />,
        true,
        true,
        true);

    renderPair(content,
        "",
        `StateIsDefault_${state.id}`,
        () => <Checkbox value={state.isDefaultState}
            label={window.captions.ByDefault}
            onChange={changeIsDefault} />,
        true,
        true,
        true);

    renderPair(content,
        window.captions.Code,
        `Code_${state.id}`,
        () => <TextBox style={style} value={state.code} onChange={changeCode} />,
        true,
        true,
        true);

    var valueColor = state.rowColor ? `#${state.rowColor}` : undefined;
    if (state.rowColor && state.rowColor.length === 8)
        valueColor = `#${state.rowColor.substr(state.rowColor.length - 6)}`;

    renderPair(content,
        window.captions.Color,
        `StateColor_${state.id}`,
        () => <ColorPickerControl view={"combo"}
            field={state.id}
            value={valueColor}
            changeValue={changeColor} />,
        true,
        true,
        true);

    return <Card style={cardStyle}>
        <CardBody>
            <CardTitle>{state.name}</CardTitle>
            {content}
        </CardBody>
        <CardActions>
            <Button icon="delete" className="dm-negative-filled" onClick={removeItem}>{window.captions.Delete}</Button>
        </CardActions>
    </Card>;

    function fetchData(overrideDefaultState = undefined) {
        if (!props)
            return;

        let model = props.item.dataItem;

        let stateModel = {
            id: model.id,
            name: model.name,
            code: model.code,
            rowColor: model.rowColor,
            isDefaultState: model.isDefaultState,
            langItem: model.langItem,
            stateId: props.stateId,
            isDeleted: model.isDeleted,
            canSave: true
        }

        if (overrideDefaultState)
            stateModel.isDefaultState = overrideDefaultState;

        setState(stateModel);
    }

    function onChangeValue(e) {
        var newValue = { dataItem: e.value, index: props.item.index };
        setState({
            ...state,
            item: newValue
        });

        props.onSaveItem(newValue);
    }

    function removeItem() {
        if (name && !confirm(window.captions.DeleteItemConfirm))
            return;

        setState({
            ...state,
            isDeleted: true
        });

        update(true, 4);
    };

    function getSaveStateModel(value, type) {
        if (!name)
            return null;

        let itemName = name;

        var newData = {
            id: id,
            name: itemName,
            langItem: langItem,
            code: code,
            rowColor: rowColor,
            isDefaultState: isDefaultState,
            isDeleted: isDeleted,
            canSave: true
        }

        if (type === 0) {
            if (langItem.items) {
                langItem.items = langItem.items.sort(function (a, b) {
                    return a.id - b.id || a.name.localeCompare(b.name);
                });
                let newItem = langItem.items.find((item) => item.name);
                if (newItem) {
                    setState({
                        ...state,
                        name: newItem.name
                    });
                    itemName = newItem.name;
                }
            }

            newData.name = itemName;
        }

        if (type === 1)
            newData.isDefaultState = value;

        if (type === 2)
            newData.code = value;

        if (type === 3)
            newData.rowColor = value;

        if (type === 4)
            newData.isDeleted = value;

        newData.langItem.items.map(item => {
            if (typeof item.name === "string" && item.name.length === 0) {
                var langKey = getUiSources().find(lang => lang.id === item.id);
                var language = getUiLanguages().find(lang => lang.link === langKey.name);
                newData.canSave = false;
                alert(`${window.captions.MessageBoxEmptyNameDeny} (${language.name})`);
            }
        });

        let name = newData.name;
        let selectedLang = newData.langItem.items.find(item => item.id === props.currentUi.id);
        if (selectedLang)
            name = selectedLang.name;

        let findNameDuplicate = props.names.find(item => item.name === name && item.id !== newData.id);
        if (findNameDuplicate) {
            alert(`${window.captions.MessageBoxDuplicateNameDeny} (${findNameDuplicate.name})\r\nId: ${findNameDuplicate.id}`);
            newData.name = `${newData.name} (${window.captions.Copy})`;
            newData.canSave = false;
            return newData;
        }

        let findCodeDuplicate = props.codes.find(item => item.value === newData.code && item.id !== newData.id);
        if (findCodeDuplicate) {
            alert(`${window.captions.MessageBoxDuplicateCodeDeny} (${findCodeDuplicate.value})\r\nId: ${findCodeDuplicate.id}`);
            newData.code = null;
            newData.canSave = false;
            return newData;
        }

        return newData;
    }

    function update(value, type) {
        let saveModel = getSaveStateModel(value, type);
        if (!saveModel)
            return;

        var newValue = { dataItem: saveModel, index: props.item.index };
        props.saveItem(newValue);
    }

    function changeName(e) {
        setState({ ...state, langItem: e.selectLang });
        update(e.selectLang, 0);
    }

    function changeCode(e) {
        setState({ ...state, code: e.value });
        update(e.value, 2);
    }

    function changeColor(e) {
        let value = e.value;
        if (value.startsWith("#", 0))
            value = value.replace("#", "");

        setState({ ...state, rowColor: value });
        update(value, 3);
    }

    function changeIsDefault(e) {
        setState({ ...state, isDefaultState: e.value });
        update(e.value, 1);
    }
}

export function EditListViewBooleanCheckItem(props) {
    const [item, setItem] = useState({});

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.fieldId, props.item]);

    var boolItem = [];
    renderPair(boolItem,
        "",
        `StateBool_${item.id}`,
        () => <Checkbox key="fieldViewableFromCard"
            className="dm-m-left"
            value={item.value}
            label={item.name}
            onChange={onChangeValue} />,
        true);
    return boolItem;

    function fetchData() {
        if (props)
            setItem(props.item.dataItem);
    }

    function onChangeValue(e) {
        var newData = {
            id: item.id,
            name: item.name,
            value: e.value
        };

        var newValue = { dataItem: newData, index: props.item.index };
        setItem(newData);
        props.onSaveItem(newValue);
    }
}
