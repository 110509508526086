import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Map, MapLayers, MapTileLayer, MapMarkerLayer } from '@progress/kendo-react-map';
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { Window, WindowActionsBar, Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, FloatingActionButton } from "@progress/kendo-react-buttons";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from "@progress/kendo-intl";

import { dateTimeFormat } from "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import { fetchPost } from "../../utils/requestsHelper.jsx";

import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";

import "../../styles/css/usersGeolocation.css";

const tileSubdomains = ["a", "b", "c"];
const tileUrl = (e) => `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
const attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap contributors</a>';

export default function UsersGeolocation() {
    const defaultCenter = [50.48675558924585, 30.608816851024];
    const navigate = useNavigate();

    const [marker, setMarker] = useState(null);

    const [types, setTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [users, setUsers] = useState([]);
    const [history, setHistory] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [dateRanges, setDateRanges] = useState({
        start: new Date(),
        end: new Date(),
    });
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedHistory, setSelectedHistory] = useState([]);

    const [hasStates, setHasStates] = useState(false);
    const [hasHistory, setHasHistory] = useState(false);

    const [message, setMessage] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [newMarkers, setNewMarkers] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const mapObject = useRef(null);
    const mapControl = useRef(null);

    const isMobile = useSelector(state => state.header.isMobile);

    useEffect(() => {
        document.title = `Delta M. Crm. ${captions.Geolocation}`;
        window.addEventListener("click", handleBodyClick);

        fetchData();

        return function cleanup() {
            window.removeEventListener("click", handleBodyClick);
        };
    }, []);

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    let overContent = null;
    if (isProcessing)
        overContent = <LoadingMask text={message} />;

    if (!mapObject.current) {
        let center = defaultCenter;
        if (markers && markers.length > 0) {
            center = markers[0].position;
            markers[0].onClick = clickMarker;
        }

        var styleFullHeigth = {
            height: "100%"
        };

        mapObject.current = <Map ref={mapControl} style={styleFullHeigth} center={center} zoom={13} onMarkerClick={clickMarker}>
            <MapLayers>
                <MapTileLayer
                    urlTemplate={tileUrl}
                    subdomains={tileSubdomains}
                    attribution={attribution} />
                <MapMarkerLayer
                    data={markers}
                    locationField="position"
                    titleField="title" />
            </MapLayers>
        </Map>;
    } else if (newMarkers && mapControl.current) {
        mapControl.current.loadMarkers(markers);
        setNewMarkers(false);
    }

    const filter = <div className="row usersGeolocationPanel">
        <DateRangePicker defaultValue={dateRanges} adaptive={true} onChange={(e) => changedRange(e)} />
        <div className="col-12">
            <label htmlFor="selectorTypes" className="k-label">{window.captions.WordType}</label>
            <DropDownCheckedTree
                id="selectorTypes"
                treeData={types}
                className="dm-wrapper-content"
                selectionChanged={changeSelectedTypes}
                selectedIdList={selectedTypes} />
        </div>
        <div className="col-12">
            <label htmlFor="selectorUsers" className="k-label">{window.captions.Users}</label>
            <DropDownCheckedTree
                id="selectorUsers"
                treeData={users}
                className="dm-wrapper-content"
                selectionChanged={(e) => setSelectedUsers(e.value)}
                selectedIdList={selectedUsers} />
        </div>
        {hasStates &&
            (<div className="col-12">
                <label htmlFor="selectorStates" className="k-label">{window.captions.States}</label>
                <DropDownCheckedTree
                    id="selectorStates"
                    treeData={states}
                    className="dm-wrapper-content"
                    selectionChanged={(e) => setSelectedStates(e.value)}
                    selectedIdList={selectedStates} />
            </div>)}
        {hasHistory && history &&
            (<div className="col-12">
                <label htmlFor="selectorHistory" className="k-label">{window.captions.Action}</label>
                <DropDownCheckedTree
                    id="selectorHistory"
                    treeData={history}
                    className="dm-wrapper-content"
                    selectionChanged={(e) => setSelectedHistory(e.value)}
                    selectedIdList={selectedHistory} />
            </div>)}
    </div>;

    var popover = marker && (
        <Popover offset={marker.offset} title={marker.type} show={true} onClose={() => setMarker(null)} className="usersGeolocationPopover">
            {renderMarkerContent()}
            {renderMarkerActions()}
        </Popover>);

    return isMobile ? renderMobile() : renderDesktop();

    function renderMarkerContent() {
        const uiCulture = getUiCulture();
        var panel = [];
        if (marker.items.length == 1) {
            return renderMarkerGroup(marker.items[0], uiCulture, 0);
        } else {
            for (let key in marker.items) {
                const itemGroup = marker.items[key];
                panel.push(<strong key={`type${key}`}>{itemGroup.typeName}</strong>);
                panel.push(renderMarkerGroup(itemGroup, uiCulture, key));
            }
        }

        return panel;
    }

    function renderMarkerGroup(itemGroup, uiCulture, parentKey) {
        var panel = [];
        for (let key in itemGroup.items) {
            const item = itemGroup.items[key];
            panel.push(renderMarkerItem(item, key, uiCulture, parentKey));
        }
        return panel;
    }

    function renderMarkerItem(item, key, uiCulture, parentKey) {
        return <div className="usersGeolocationPop" key={`item${key}_${parentKey}`}>
            {formatDate(new Date(item.created), dateTimeFormat, uiCulture)}<br />
            {item.userName}<br />
            {item.itemName}
        </div>;
    }

    function renderMarkerActions() {
        if (!marker.cards)
            return null;

        var panel = [];
        for (var key in marker.cards) {
            const item = marker.cards[key];
            const link = item.loanId
                ? `/card/loan/${item.loanId}`
                : item.clientId > 0
                    ? `/card/client/${item.clientId}`
                    : null;
            if (link) {
                panel.push(<Button key={key} onClick={() => navigate(link)} fillMode={"flat"}>
                    {item.cardTitle}
                </Button>);
            }
        }
        return <PopoverActionsBar>
            {panel}
        </PopoverActionsBar>;
    }

    function renderMobile() {
        return <div className="dm-full-height">
            {mapObject.current}
            {showFilter && (<Dialog title={window.captions.DataFlter} className="dm-mobile" onClose={() => setShowFilter(false)}>
                {filter}
                <DialogActionsBar layout="end">
                    <Button themeColor="primary" type="button"
                        icon="search"
                        onClick={() => fetchData()}>
                        {window.captions.RefreshData}
                    </Button>
                </DialogActionsBar>
            </Dialog>)}
            <FloatingActionButton icon="search"
                align={{ vertical: "bottom", horizontal: "end" }}
                onClick={() => setShowFilter(true)} />
            {overContent}
            {popover}
        </div>
    }

    function renderDesktop() {
        const left = window.innerWidth - 450 - 15;
        const menu = <Window title={window.captions.DataFlter}
            initialHeight={350} initialWidth={450}
            initialTop={55} initialLeft={left}
            style={{ zIndex: 1 }}
            closeButton={() => null} maximizeButton={() => null}>
            {filter}
            <WindowActionsBar layout="end">
                <Button themeColor="primary" type="button"
                    icon="search"
                    onClick={() => fetchData()}>
                    {window.captions.RefreshData}
                </Button>
            </WindowActionsBar>
        </Window>;

        return <div className="dm-full-height">
            <h1 className="dm-title">{window.captions.Geolocation}</h1>
            <div className="dm-under-title-content">
                <div className="dm-full-height">
                    {mapObject.current}
                    {menu}
                    {overContent}
                    {popover}
                </div>
            </div>
        </div>;
    }

    function changeSelectedTypes(e) {
        var items = e.value;
        const newStates = items.find(i => i == 1);
        const newHistory = items.find(i => i == 5);
        setSelectedTypes(items);
        setHasStates(newStates);
        setHasHistory(newHistory);

        if (!newStates && hasStates) {
            setSelectedStates([]);
        }

        if (!newHistory && hasHistory) {
            setSelectedHistory([]);
        }
    }

    function fetchData() {
        var request = {
            dateFrom: dateRanges.start,
            dateTo: dateRanges.end,
            logonTypes: selectedTypes,
            states: mapObject.current ? selectedStates : null,
            users: selectedUsers,
            historyResults: selectedHistory
        }

        setIsProcessing(true);
        setShowFilter(false);
        setMarker(null);
        setMessage(window.captions.LoadingData);
        fetchPost(window.constants.userGeolocationElements,
            request,
            data => {
                if (data.states) {
                    setTypes(data.types);
                    setStates(data.states);
                    setUsers(data.users);
                    setHistory(data.history);
                }

                setMarkers(data.items);
                setError(null);
                setIsLoaded(true);
                setIsProcessing(false);
                setNewMarkers(true);
            },
            ex => {
                setError(ex);
                setIsLoaded(true);
                setIsProcessing(false);
                setNewMarkers(false);
            }
        );
    }

    function changedRange(e) {
        setDateRanges(e.value);
    }

    function handleBodyClick(e) {
        if (e.currentTarget && (!e.currentTarget.className || e.currentTarget.className.indexOf('k-marker') < 0))
            setMarker(null);
    }

    function clickMarker(e) {
        const rect = e.marker.element.getBoundingClientRect();
        var offset = {
            left: rect.left + rect.width / 2,
            top: rect.top
        };
        if (e.marker.dataItem)
            setMarker({ ...e.marker.dataItem, offset });
        else
            setMarker(null);
    }
}