import { createSlice } from '@reduxjs/toolkit'
import CardReducerFunctions from "./cardReducerFunctions.jsx";
import CardSaveFunctions from "./cardSaveFunctions.jsx";
import { isMobileSize, getLayoutSize, entitySourcesNames, layoutSize, openParameters } from "../../utils/systemConstants.jsx";
import { fetchGet, fetchGetOneWay, fetchPost } from "../../utils/requestsHelper.jsx";

export const cardEntitySlice = createSlice({
    name: 'cardEntity',
    initialState: {
        selectedTab: 0,
        isUpdated: false,
        isProcessing: false,
        isMobile: false,
        sizeMode: { size: layoutSize.one, editor: layoutSize.one, inlineDialog: false },
        isLoaded: false,
        isError: false,
        isEditMode: false,
        needReload: false,
        hasClientDuplicateByInn: false,
        expanded: false,
        dialogs: {
            tasks: false,
            taskId: 0,
            history: false,
            document: false,
            export: false,
            loan: false,
            clientCopy: false,
            loanCopy: false,
            addLinked: false,
            editLinked: false,
            questionnaire: false,
            linkedId: 0,
            linkedCardId: 0,
            linkedSource: 0,
            addCollateral: false,
            addCollateralOwner: false,
            addPromiseScheduler: false,
            addPayment: false,
            process: false,
            processId: 0,
            account: false,
            accountId: 0,
            record: false,
            recordLink: "",
            validate: false,
            verification: false,
            verificationPhoneId: 0,
            verificationCode: "",
            altVerificationCode: "",
            map: false,
            mapPosition: null
        },
        error: null,
        entity: "",
        id: 0,
        model: null,
        openParameters: {
            phone: null,
            contactResultId: 0,
            contactTypeId: 0
        }
    },
    reducers: {
        windowSizeChanged: (state, action) => {
            var isMobile = isMobileSize(window.innerWidth, window.innerHeight);
            state.sizeMode = getLayoutSize(window.innerWidth, state.expanded);
            state.isMobile = isMobile;
            state.expanded = isMobile ? false : action.payload;
        },
        cardAccessChanged: (state, action) => {
            var access = action.payload;
            if (access.Source === state.model.source && access.Id === state.model.id) {
                state.model.editAllowed = access.EditAllowed;
                state.model.editUserName = access.EditUserName;
            }
        },
        tabSelected: (state, action) => {
            const e = action.payload;
            state.selectedTab = e.id !== undefined ? e.id : e.value.index;
        },
        dialogCommon: (state, action) => {
            state.dialogs[action.payload.name] = action.payload.isShow;
            if (action.payload.name === 'editCollateral')
                state.dialogs.addCollateral = false;

            if (action.payload.nameId)
                state.dialogs[action.payload.nameId] = action.payload.id;
        },
        dialogComplex: (state, action) => {
            state.dialogs[action.payload.name] = action.payload.item.isShow ? action.payload.item : false;
        },
        dialogExportOpen: (state, action) => {
            state.dialogs.export = action.payload.exportType === false ? false : action.payload;
        },
        dialogEditLinkedClientOpen: (state, action) => {
            state.dialogs.editLinked = action.payload.isShow;
            state.dialogs.addLinked = false;
            state.dialogs.linkedCardId = action.payload.id;
            state.dialogs.linkedId = action.payload.linkId;
            state.dialogs.linkedSource = action.payload.source;
        },
        dialogHistory: (state, action) => {
            if (action.payload === true || action.payload === false) {
                state.dialogs.history = action.payload;
                state.openParameters.phone = null;
                state.openParameters.phoneId = 0;
                state.openParameters.contactResultId = 0;
                state.openParameters.contactTypeId = 0;
                if (state.openParameters.isContactOpened && !action.payload)
                    state.openParameters.isContactOpened = false;
            } else if (state.model.outgoingCall != null) {
                state.dialogs.history = true;
                state.openParameters.phone = action.payload;
                state.openParameters.contactResultId = state.model.outgoingCall.resultId;
                state.openParameters.contactTypeId = state.model.outgoingCall.typeId;
            }
        },
        dialogStrategyHistory: (state, action) => {
            state.dialogs.strategy = action.payload;
        },
        dialogLoanOpen: (state, action) => {
            state.dialogs.loan = action.payload.isShow;
            if (state.dialogs.loan || action.payload.loanId === 0)
                return;

            state.needReload = true;
            state.id = action.payload.loanId;
            state.entity = entitySourcesNames.loan;
            state.isEditMode = false;
            state.isProcessing = false;
            state.isUpdated = false;
        },
        dialogCopyClientOpen: (state, action) => {
            state.dialogs.clientCopy = action.payload.isShow;
            if (state.dialogs.clientCopy || action.payload.clientId === 0)
                return;

            state.needReload = true;
            state.id = action.payload.clientId;
            state.entity = entitySourcesNames.client;
            state.isEditMode = false;
            state.isProcessing = false;
            state.isUpdated = false;
        },
        dialogCopyLoanOpen: (state, action) => {
            state.dialogs.loanCopy = action.payload.isShow;
            if (state.dialogs.loanCopy || action.payload.loanModel === undefined || action.payload.loanModel === null)
                return;

            state.model = action.payload.loanModel;
            state.selectedTab = 0;
            state.id = action.payload.loanModel.id;
            state.entity = entitySourcesNames.loan;
            state.isLoaded = true;
            state.isError = false;
            state.isEditMode = true;
            state.isProcessing = false;
            state.isUpdated = false;
            state.error = null;
        },
        dialogConfirmVerificationOpen: (state, action) => {
            state.dialogs.verification = action.payload.isShow;
            state.dialogs.verificationPhoneId = action.payload.phoneId;
            state.dialogs.verificationCode = action.payload.code;
            state.dialogs.altVerificationCode = action.payload.altCode;
            if (action.payload.verified) {
                const indexPhone = state.model.phones.findIndex(i => i.id === action.payload.phoneId);
                if (indexPhone != null && indexPhone >= 0) {
                    state.model.phones[indexPhone].verified = true;
                    if (action.payload.stateId)
                        state.model.phones[indexPhone].state = action.payload.stateId;
                }
            }
        },
        cardLoadedError: (state, action) => {
            state.error = action.payload;
            state.isLoaded = false;
            state.isError = true;
            state.isProcessing = false;
        },
        cardValidateError: (state, action) => {
            state.dialogs.validate = action.payload;
        },
        cardClosed: (state, action) => {
            const closeUrl = `${window.constants.cardClosed}/${action.payload.entity}/${action.payload.id}`;
            fetchGetOneWay(state.model && state.model.tabGuid ? `${closeUrl}/${state.model.tabGuid}` : closeUrl);

            state.model = null;
            state.isLoaded = false;
        },
        cardLoaded: (state, action) => {
            var cardIsMobile = isMobileSize(window.innerWidth, window.innerHeight);
            var updatedModel = action.payload.model;
            var isEditMode = action.payload.model.id === 0;
            var isUpdated = state.isUpdated;
            if (updatedModel) {
                if (state.openParameters && state.openParameters.isNewCard) {
                    updatedModel.phones = CardReducerFunctions.addPhoneIfIncomingNewCard(state.openParameters, updatedModel);
                    updatedModel.isEditMode = updatedModel.phones.some(i => i.isUpdated);
                    updatedModel.isUpdated = true;
                }

                var fieldsList = updatedModel.fields;
                for (let key in fieldsList) {
                    const field = fieldsList[key];
                    if (field.isEditable && !field.isContact)
                        fieldsList[key].oValue = field.value;
                }
            }

            state.isMobile = cardIsMobile;
            state.expanded = !cardIsMobile;
            state.model = updatedModel;
            state.sizeMode = getLayoutSize(window.innerWidth, !cardIsMobile);
            state.selectedTab = state.selectedTab >= updatedModel.tabs.length ? 0 : state.selectedTab;
            state.id = action.payload.model.id;
            state.entity = action.payload.entity;
            state.isLoaded = true;
            state.isError = false;
            state.isEditMode = isEditMode;
            state.isUpdated = isUpdated;
            state.isProcessing = false;
            state.error = null;

            if (action.payload.openParameters)
                state.openParameters = action.payload.openParameters;
        },
        cardEditMode: (state, action) => {
            var isEdit = action.payload.isEdit;
            var fields = action.payload.fields;
            if (!fields) {
                state.isEditMode = isEdit;
                return;
            }

            for (let fieldId in fields) {
                const field = state.model.fields[fieldId];
                if (field)
                    field.text = fields[fieldId];
            }
            state.isEditMode = isEdit;
        },
        cardBeforeSave: (state) => {
            state.isProcessing = true;
            state.hasClientDuplicateByInn = false;
        },
        cardBeforeLoad: (state) => {
            state.isLoaded = false;
            state.needReload = false;
        },
        cardAfterVerify: (state) => {
            state.needReload = true;
            state.isEditMode = false;
            state.isProcessing = false;
            state.isUpdated = false;
            state.error = null;
            state.isError = false;
        },
        cardAfterSave: (state, action) => {
            state.needReload = true;
            state.id = action.payload ? action.payload.id : 0;
            state.hasClientDuplicateByInn = action.payload ? action.payload.hasClientDuplicateByInn : false;
            state.isEditMode = false;
            state.isProcessing = false;
            state.isUpdated = false;
            state.error = null;
            state.isError = false;
        },
        cardEditField: (state, action) => {
            if (CardReducerFunctions.fieldEdited(state.model.fields, action.payload))
                state.isUpdated = true;
        },
        cardEditSystemField: (state, action) => {
            CardReducerFunctions.fieldSystemEdited(state, action.payload);
        },
        cardEditPassport: (state, action) => {
            if (CardReducerFunctions.passportEdited(state.model.fields, action.payload))
                state.isUpdated = true;
        },
        cardEditContact: (state, action) => {
            if (CardReducerFunctions.contactEdited(state.isUpdated,
                state.model.sources,
                state.model.fields,
                state.model.phones,
                state.model.addresses,
                state.model.emails,
                state.model.urls,
                state.model.accounts,
                action.payload))
                state.isUpdated = true;
        },
        cardAddContact: (state, action) => {
            if (CardReducerFunctions.contactAdded(state.model.fields,
                state.model.sources,
                state.model.phones,
                state.model.addresses,
                state.model.emails,
                state.model.urls,
                state.model.accounts,
                action.payload.fieldId,
                action.payload.typeItem)) {
                state.isUpdated = true;
                state.isEditMode = true;
            }
        },
        expandChange: (state, action) => {
            state.expanded = !action.payload;
            state.sizeMode = getLayoutSize(window.innerWidth, !action.payload);
        },
        cardArchiveSet: (state, action) => {
            state.model.isArchived = action.payload;
        }
    }
})

export const {
    windowSizeChanged,
    cardAccessChanged,
    tabSelected,
    dialogCommon,
    dialogComplex,
    dialogExportOpen,
    dialogEditLinkedClientOpen,
    dialogHistory,
    dialogStrategyHistory,
    dialogLoanOpen,
    dialogCopyClientOpen,
    dialogCopyLoanOpen,
    dialogConfirmVerificationOpen,
    cardLoadedError,
    cardValidateError,
    cardLoaded,
    cardClosed,
    cardEditMode,
    cardBeforeSave,
    cardBeforeLoad,
    cardAfterVerify,
    cardAfterSave,
    cardEditField,
    cardEditSystemField,
    cardEditPassport,
    cardEditContact,
    cardAddContact,
    expandChange,
    cardArchiveSet
} = cardEntitySlice.actions

const dialogCommonOpen = (name, isShow, nameId, id) => (dispatch) => {
    dispatch(dialogCommon({
        name,
        isShow,
        nameId,
        id
    }));
}

const dialogComplexOpen = (name, item) => (dispatch) => dispatch(dialogComplex({ name, item }));

export const dialogTask = (isShow, id) => dialogCommonOpen('tasks', isShow, 'taskId', id);
export const dialogAddLinkedClient = (isShow) => dialogCommonOpen('addLinked', isShow, '', 0);
export const dialogAddCollateralOwner = (isShow) => dialogCommonOpen('addOwner', isShow, '', 0);
export const dialogAddCollateral = (isShow) => dialogCommonOpen('addCollateral', isShow, 'collateralId', 0);
export const dialogEditCollateral = (isShow, id) => dialogCommonOpen('editCollateral', isShow, 'collateralId', id);
export const dialogRecordRating = (isShow) => dialogCommonOpen('recordRating', isShow, '', 0);
export const dialogPromiseSchedulerAdder = (isShow) => dialogCommonOpen('addPromiseScheduler', isShow, '', 0);
export const dialogPaymentAdder = (isShow) => dialogCommonOpen('addPayment', isShow, '', 0);
export const dialogDocument = (isShow) => dialogCommonOpen('document', isShow, '', 0);
export const dialogProcess = (isShow, id) => dialogCommonOpen('process', isShow, 'processId', id);
export const dialogAccount = (isShow, id) => dialogCommonOpen('account', isShow, 'accountId', id);
export const dialogMap = (isShow, position) => dialogCommonOpen('map', isShow, 'mapPosition', position);
export const dialogRecordPlay = (isShow, link) => dialogCommonOpen('record', isShow, 'recordLink', link);
export const dialogQuestionnaire = (isShow, id) => dialogComplexOpen('questionnaire', { isShow, id });

export const dialogExport = (exportType, id) => dialogExportOpen({ exportType, id });
export const dialogLoan = (isShow, loanId) => dialogLoanOpen({ isShow, loanId });
export const dialogCopyClient = (isShow, clientId) => dialogCopyClientOpen({ isShow, clientId });
export const dialogCopyLoan = (isShow, loanModel) => dialogCopyLoanOpen({ isShow, loanModel });

export const dialogConfirmVerification = (isShow, phoneId, code, altCode, verified, stateId) => dialogConfirmVerificationOpen({
    isShow,
    phoneId,
    code,
    altCode,
    verified,
    stateId
});

export const dialogEditLinkedClient = (isShow, id, source, linkId) => dialogEditLinkedClientOpen({
    isShow,
    id,
    source,
    linkId
});

export const resetStrategy = (source, id) => fetchGetOneWay(`${window.constants.resetStrategy}/${source}/${id}`);

export const archiveOrRestore = (source, id, isArchive) => (dispatch) => {
    const isArchived = isArchive ? 1 : 0;
    fetchGet(`${window.constants.archive}/${source}/${id}/${isArchived}`,
        () => dispatch(cardArchiveSet(isArchive)),
        ex => dispatch(cardLoadedError(ex))
    );
}

export const editMode = (isEdit, model) => (dispatch) => {
    if (isEdit) {
        if (!model.editAllowed) {
            dispatch(cardValidateError({
                message: window.captions.BlockMultiUserCardEditTooltip.replace("{0}", model.editUserName),
                isError: true
            }));
            return;
        }

        fetchGetOneWay(`${window.constants.cardEdited}/${model.source}/${model.id}/true`);
        dispatch(cardEditMode({ isEdit: isEdit, fields: null }));
        return;
    }

    fetchGetOneWay(`${window.constants.cardEdited}/${model.source}/${model.id}/false`);
    const converted = CardSaveFunctions.renderValues(model.fields);
    if (converted.isEmpty())
        return dispatch(cardEditMode({ isEdit: false, fields: null }));

    fetchPost(window.constants.parseText,
        converted,
        data => dispatch(cardEditMode({ isEdit: isEdit, fields: data })),
        () => dispatch(cardEditMode({ isEdit: isEdit, fields: [] }))
    );
}

export const fileUpload = (e) => (dispatch) => {
    if (!e.response)
        return [];

    const files = e.response.response;
    if (!files || files.length === 0)
        return [];

    const file = files[0];
    e.affectedFiles[0].fileName = file.fileName;

    dispatch(cardEditField({
        fieldId: e.fieldId,
        value: file.fileName
    }));
}

export const callPhone = (phone) => (dispatch) => dispatch(dialogHistory(phone));
export const changedSystemValue = (e) => (dispatch) => dispatch(cardEditSystemField(e));

export const changedValue = (e) => (dispatch) => {
    if (e.item !== undefined) {
        dispatch(cardEditContact(e));
    } else if (e.isPassport) {
        dispatch(cardEditPassport(e));
    } else {
        dispatch(cardEditField(e));
    }
}

export const addContact = (fieldId, typeItem) => (dispatch) => dispatch(cardAddContact({ fieldId, typeItem }));
export const validateClose = () => (dispatch) => dispatch(cardValidateError(false));

export const verify = (model, success) => (dispatch) => {
    dispatch(cardBeforeSave());
    fetchPost(`${window.constants.cardsVerify}/${success}`,
        model,
        () => dispatch(cardAfterVerify()),
        (ex) => dispatch(cardLoadedError(ex)));
}

export const save = (model, entity, openParams, canUniteByInn = false) => (dispatch) => {
    const errorFields = CardSaveFunctions.validateRequired(model.fields, model, {});
    if (errorFields.length > 0) {
        dispatch(cardValidateError({ message: errorFields }));
        return;
    }

    dispatch(cardBeforeSave());
    const converted = CardSaveFunctions.renderValues(model.fields, null, model.isCopy);
    const request = {
        values: converted.values,
        nullValues: converted.nullValues,
        id: model.isCopy ? 0 : model.id,
        clientType: model.clientType,
        created: model.created,
        author: model.author,
        stateId: model.stateId,
        stageId: model.stageId,

        phones: model.phones ? model.phones.filter(i => i.isUpdated) : [],
        addresses: model.addresses ? model.addresses.filter(i => i.isUpdated) : [],
        emails: model.emails ? model.emails.filter(i => i.isUpdated) : [],
        urls: model.urls ? model.urls.filter(i => i.isUpdated) : [],
        accounts: model.accounts ? model.accounts.filter(i => i.isUpdated) : [],
        canUniteByInn: canUniteByInn
    };

    if (openParams.isContactOpened) {
        openParams.isNewCard = false;
        sessionStorage.setItem(openParameters, JSON.stringify(openParams));
    }

    if (entity === entitySourcesNames.loan) {
        request.clientId = model.clientId;
        request.projectId = model.projectId;
        request.clientExternalId = model.clientExternalId;
        request.loanExternalId = model.loanExternalId;
        request.externalUser = model.externalUser;
    } else if (entity === entitySourcesNames.client) {
        request.externalId = model.externalId;
        request.fizJur = model.fizJur;
    } else if (entity === entitySourcesNames.collateral) {
        request.typeCode = model.typeCode;
    } else if (entity === entitySourcesNames.documents) {
        request.typeId = model.typeId;
        request.fileName = model.fileName;
        request.size = model.size;
    }

    fetchPost(
        `${window.constants.cardsSave}/${entity}`,
        request,
        item => dispatch(cardAfterSave(item)),
        ex => dispatch(cardLoadedError(ex))
    );
}

export const cardLoad = (id, entity, needReload) => (dispatch) => {
    if (!(id && entity)) {
        dispatch(cardLoadedError("Id is unsetted"));
        return;
    }

    dispatch(cardBeforeLoad());
    fetchGet(`${window.constants.cardEntity}/${entity}/${id}`,
        data => {
            var model = { model: data, entity: entity };

            if (!needReload) {
                const data = sessionStorage.getItem(openParameters);
                sessionStorage.removeItem(openParameters);
                let openParams;
                if (data) {
                    openParams = JSON.parse(data);
                } else {
                    openParams = {
                        isContactOpened: false,
                        isNewCard: false
                    };
                }

                model.openParams = openParams;
            }

            dispatch(cardLoaded(model));

        },
        ex => dispatch(cardLoadedError(ex))
    );
}

export default cardEntitySlice.reducer