import React, { useState, useEffect } from 'react';
import { formatDate } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateRangePicker, DateInput } from "@progress/kendo-react-dateinputs";
import GridLoadingPanel from "../../components/gridLoadingPanel.jsx";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import GridColorCell from "../../components/cells/gridColorCell.jsx";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import GridDateTimeCell from "../../components/cells/gridDateTimeCell.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import { strategyEntity, getFormatDate, dateWidth } from "../../utils/systemConstants.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogStrategyHistory({ entity, entityId, onClose }) {
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const [strategyNames, setStrategyNames] = useState([
        {
            id: 0,
            name: window.captions.WordAll,
            isActive: false,
            isChild: false,
            isParallel: false
        }
    ]);
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(dataState), []);

    if (error) {
        return <Dialog title={window.captions.StrategyRegister} onClose={onClose} width={1000} height={750}>
            <ErrorMask inline error={error} />
        </Dialog>;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    const passedCell = (p) => {
        const passed = p.dataItem[p.field];
        if (passed === true) {
            return <td {...getLinkCellStyle(p)}>
                <i className="dm-i dm-i-check dm-positive dm-symbol-size"
                    style={{ verticalAlign: "middle" }} />
            </td>;
        } else if (passed === false) {
            return <td {...getLinkCellStyle(p)}>
                <i className="dm-i dm-i-times dm-error dm-symbol-size"
                    style={{ verticalAlign: "middle" }} />
            </td>;
        } else
            return <td {...getLinkCellStyle(p)} />;
    };

    const entityNameCell = (p) => {
        const textCell = (text) => <td>
            <div className="dm-text-cell">{text}</div>
        </td>;
        switch (p.dataItem[p.field]) {
            case strategyEntity.loan:
                return textCell(window.captionsDynamic.LoanSingleNominative);
            case strategyEntity.client:
                return textCell(window.captions.Client);
            case strategyEntity.collateral:
                return textCell(window.captionsDynamic.Collaterals);
            case strategyEntity.phone:
                return textCell(window.captions.Phone);
            case strategyEntity.process:
                return textCell(window.captions.Process);
            case strategyEntity.processAction:
                return textCell(window.captions.ProcessAction);
            case strategyEntity.callRecords:
                return textCell(window.captions.CallRecords);
            case strategyEntity.account:
                return textCell(window.captions.Accounts);
            case strategyEntity.accountLimit:
                return textCell(window.captions.AccountLimits);
            default:
                return "";
        }
    };

    const rangeDateInput = (p) => {
        return <DateInput {...p}
            label={undefined}
            width={dateWidth}
            formatPlaceholder={getFormatDate()} />;
    };

    return <Dialog title={window.captions.StrategyRegister} onClose={onClose} width={1000} height={750}>
        <div className="dm-full-wh">
            <Grid className="dm-full-wh"
                pageable={true}
                total={total}
                data={data}
                {...dataState}
                onDataStateChange={handleDataStateChange}>
                <GridToolbar>
                    <DropDownList
                        style={{ width: 240, marginRight: 8 }}
                        dataItemKey="id"
                        data={strategyNames}
                        textField="name"
                        value={selectedStrategy}
                        onChange={changeSelectedStrategy} />
                    <DateRangePicker
                        style={{ marginRight: 8, width: "auto" }}
                        startDateInput={rangeDateInput}
                        endDateInput={rangeDateInput}
                        value={dateRange}
                        onChange={changeDateRange} />
                    <Button onClick={() => fetchData(dataState)}>
                        {window.captions.RefreshData}
                    </Button>
                </GridToolbar>
                <GridColumn field="blockColor" title=" " width="30" cells={{ data: GridColorCell }} />
                <GridColumn field="passed" title=" " width="35" cells={{ data: passedCell }} />
                <GridColumn field="created" title={window.captions.Created}
                    width="160" cells={{ data: GridDateTimeCell }} />
                <GridColumn field="number" title={window.captions.BlockNumber} width="130" />
                <GridColumn field="caption" title={window.captions.StrategyCaption} width="220" />
                <GridColumn field="strategyEntity" title={window.captions.EntityName}
                    width="200" cells={{ data: entityNameCell }} />
                <GridColumn field="entityId" title={window.captions.Identifier} width="150" />
            </Grid>
            {loadingPanel}
        </div>
    </Dialog>;

    function handleDataStateChange(changeEvent) {
        fetchData(changeEvent.dataState);
    }

    function fetchData(dataState) {
        var currentStrategy = selectedStrategy;

        setPending(true);
        setError(null);
        if (!currentStrategy) {
            currentStrategy = strategyNames[0];
            setSelectedStrategy(currentStrategy);
        }

        const dateFrom = dateRange.start ? formatDate(dateRange.start, "yyyy-MM-dd") : "1900-01-01";
        const dateTo = dateRange.end ? formatDate(dateRange.end, "yyyy-MM-dd") : "1900-01-01";
        let url =
            `${window.constants.getStrategyHistory}/${entityId}/${entity}/${currentStrategy.id}`;
        if (!dateFrom && !dateTo) {
            url = `${url}?${toDataSourceRequestString(dataState)}`;
        } else {
            url = `${url}/${dateFrom}/${dateTo}?${toDataSourceRequestString(dataState)}`;
        }
        fetchGet(url,
            (data) => {
                const nameItems = strategyNames;
                for (let item of data.strategyNames) {
                    if (nameItems.every(i => i.id !== item.id))
                        nameItems.push(item);
                }
                setData(data.items);
                setTotal(data.total);
                setStrategyNames(nameItems);
                setDataState(dataState);
                setPending(false);
            },
            (ex) => {
                setPending(false);
                setError(ex.stack ? ex.stack : ex);
            });
    }

    function changeSelectedStrategy(e) {
        setSelectedStrategy(e.target.value);
    }

    function changeDateRange(e) {
        setDateRange(e.target.value);
    }
}