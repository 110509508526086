import { tokenKey, tokenRequest, tokenSharing, tokenFlush } from "./systemConstants.jsx";
import { fetchGet } from "./requestsHelper.jsx";
import { v1 as uuidv1 } from "uuid";
import "isomorphic-fetch";

function saveAuth(model) {
    sessionStorage.setItem(tokenKey, JSON.stringify(model));
}

function changeUiLanguage(model, next) {
    if (!model)
        return next();

    return fetchGet(`${window.constants.localizationUser}/${model.ui}`,
        dictionary => {
            window.captions = dictionary.captions;
            window.captionsDynamic = dictionary.captionsDynamic;
            window.storeUiCaptions();
            model.roleName = dictionary.userLocales.roleName;
            model.groupName = dictionary.userLocales.groupName;
            model.states = dictionary.userLocales.states;
            next();
        },
        () => next());
}

function getUiCulture() {
    const item = sessionStorage.getItem(tokenKey);
    if (!item)
        return window.defaultCulture;

    const culture = JSON.parse(item).ui;
    switch (culture) {
    case "by":
        return "be";
    case "ge":
        return "ka";
    case "ua":
        return "uk";
    case "vn":
        return "vi";
    default:
        return culture;
    }
}

function getUiLang() {
    const item = sessionStorage.getItem(tokenKey);
    return item ? JSON.parse(item).ui : window.defaultCulture;
}

function getUiTheme() {
    const item = sessionStorage.getItem(tokenKey);
    return item ? JSON.parse(item).isDark : false;
}

function getUserMode() {
	const item = sessionStorage.getItem(tokenKey);
    return item ? JSON.parse(item).isAdmin : false;
}

function clearAuth() {
    sessionStorage.removeItem(tokenKey);
    localStorage.setItem(tokenFlush, uuidv1());
    localStorage.removeItem(tokenFlush);
}

function getUser() {
    const item = sessionStorage.getItem(tokenKey);
    if (item) {
        return JSON.parse(item);
    } else {
        return null;
    }
}

function getUserId() {
    const item = sessionStorage.getItem(tokenKey);
    if (item) {
        return JSON.parse(item).id;
    } else {
        return 0;
    }
}

function isLogged() {
    const item = sessionStorage.getItem(tokenKey);
    if (item) {
        return true;
    } else {
        return false;
    }
}

function getToken() {
    const item = sessionStorage.getItem(tokenKey);
    let token = null;
    if (item) {
        token = JSON.parse(item).token;
    }
    return token;
}

function requestSession() {
    if (sessionStorage.getItem(tokenKey))
        return;

    localStorage.setItem(tokenRequest, uuidv1());
    localStorage.removeItem(tokenRequest);
}

function storageChange(event, credentials) {
    var isChanged = false;
    const hasCredentials = credentials && Object.keys(credentials).length > 0;
    switch (event.key) {
    case tokenRequest:
        if (hasCredentials) {
            localStorage.setItem(tokenSharing, JSON.stringify(credentials));
            localStorage.removeItem(tokenSharing);
        }
        break;
    case tokenSharing:
        if (!hasCredentials && event.newValue) {
            sessionStorage.setItem(tokenKey, event.newValue);
            isChanged = true;
        }
        break;
    case tokenFlush:
        if (hasCredentials) {
            sessionStorage.removeItem(tokenKey);
            isChanged = true;
        }
        break;
    }
    return isChanged;
}

export {
    saveAuth,
    clearAuth,
    getUiCulture,
    getUiLang,
    getUiTheme,
    getUserMode,
    getUser,
    getUserId,
    isLogged,
    getToken,
    requestSession,
    storageChange,
    changeUiLanguage
    }