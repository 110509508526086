import React, { useState, useEffect } from 'react';
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";

export default function FieldsFilterForm({ dataRule, filterFieldsFormList }) {
    const [items, setItems] = useState([]);

    useEffect(() => mountProperties(), [dataRule]);

    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={items}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;

    function mountProperties() {
        if (!dataRule || !filterFieldsFormList) {
            setItems([]);
            return;
        }

        let itemsLocal = Array.isArray(filterFieldsFormList) && filterFieldsFormList.length ? filterFieldsFormList : [];
        itemsLocal.forEach(i => i.isSelected = dataRule.filterForm.some(id => id == i.id));

        setItems(itemsLocal);
    }

    function selectedItemsChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                filterForm: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }
}