
function getCellStyleAndValue(cell) {
    const style = {};
    if (cell.foreground !== "")
        style.color = cell.foreground;
    if (cell.background !== "")
        style.backgroundColor = cell.background;

    return {
        style,
        value: cell.value
    };
}

function getLinkCellStyle({ style, className, tdProps }) {
    const item = { style: style, ...tdProps };
    if (className)
        item.className = className + " dm-link-cell";
    else
        item.className = "dm-link-cell";

    return item;
}

export {
    getCellStyleAndValue,
    getLinkCellStyle
};