import React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { dateWidth } from "../../../utils/systemConstants.jsx";

export default function FinancialControl({ value, field, disabled, changedValueFrom, changedValueTo }) {
    let valueArray = value;
    if (!Array.isArray(valueArray))
        valueArray = [null, null];

    let valueFrom = valueArray[0];
    if (valueFrom !== null) {
        valueFrom = parseFloat(valueFrom);
        if (isNaN(valueFrom))
            valueFrom = 0;
    }

    let valueTo = valueArray[1];
    if (valueTo !== null) {
        valueTo = parseFloat(valueTo);
        if (isNaN(valueTo))
            valueTo = 0;
    }

    const fieldId = field.id;
    return [
        <div key={`FinancialFrom${fieldId}`} className="dm-filter-range">
            <div>{window.captions.WordFrom}</div>
            <NumericTextBox id={`FinancialFrom${fieldId}`}
                disabled={disabled}
                width={dateWidth}
                value={valueFrom}
                onChange={(e) => changedValueFrom({ field: fieldId, value: e.value })} />
        </div>,
        <div key={`FinancialTo${fieldId}`} className="dm-filter-range">
            <div>{window.captions.WordTo}</div>
            <NumericTextBox id={`FinancialTo${fieldId}`}
                disabled={disabled}
                width={dateWidth}
                value={valueTo}
                onChange={(e) => changedValueTo({ field: fieldId, value: e.value })} />
        </div>
    ];
}