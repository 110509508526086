import React from 'react';
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";

export default function PasswordBaseFieldControl({ columns }) {
    return <>
        <div>
            <Checkbox label="Тип паспорта"
                style={{ margin: 5 }} />
            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 16, width: 650 }} />
        </div>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>Серия</Label>

            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 70, width: 649 }} />
        </div>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>Номер</Label>

            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 65, width: 650 }} />
        </div>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>Дата выдачи</Label>

            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 22, width: 649 }} />
        </div>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>Дествителен до</Label>

            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 0, width: 650 }} />
        </div>
        <div>
            <Label style={{ margin: 5, padding: 10 }}>Кем выдано</Label>

            <DropDownList id="dropdown-basic-buttonfielg"
                data={columns}
                style={{ margin: 5, marginLeft: 26, width: 650 }} />
        </div>
    </>;
}