import React from "react";
import { formatDate } from "@progress/kendo-intl";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { getFormatDateTime, dateTimeWidth } from "../../../utils/systemConstants.jsx";

export default function DateTimeControl({ value, field, disabled, changedValueFrom, changedValueTo }) {
    let valueArray = value;
    if (!Array.isArray(valueArray))
        valueArray = [null, null];

    let valueFrom = valueArray[0];
    if (valueFrom !== null) {
        valueFrom = new Date(valueFrom);
    }

    let valueTo = valueArray[1];
    if (valueTo !== null) {
        valueTo = new Date(valueTo);
    }

    const fieldId = field.id;
    return [
        <div key={`DateTimeFrom${fieldId}`} className="dm-filter-range">
            <div>{window.captions.WordFrom}</div>
            <DateTimePicker id={`DateTimeFromValue${fieldId}`}
                disabled={disabled}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()}
                value={valueFrom}
                onChange={(e) => changedValueFrom({ field: fieldId, value: formatDate(e.value, "yyyy-MM-ddTHH:mm:ss") })} />
        </div>,
        <div key={`DateTimeTo${fieldId}`} className="dm-filter-range">
            <div>{window.captions.WordTo}</div>
            <DateTimePicker id={`DateTimeToValue${fieldId}`}
                disabled={disabled}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()}
                value={valueTo}
                onChange={(e) => changedValueTo({ field: fieldId, value: formatDate(e.value, "yyyy-MM-ddTHH:mm:ss") })} />
        </div>
    ];
}