import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { questionnaireGroupType } from "../../utils/systemConstants.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";

function validateStep(contactModel, groups, steppers, index, isBlocked, validateAsync, asyncErrorsList, questId, cardId, { setPending, setError, next }, isAuto) {
    const selected = groups[index];
    var response = {
        isAsync: validateAsync,
        errors: []
    };
    if (isBlocked)
        return response;

    if (selected.type === questionnaireGroupType.fields) {
        response.errors = CardSaveFunctions.validateRequired(selected.fields, contactModel, selected, isAuto);
        if (response.errors.length > 0)
            response.errors.splice(0, 0, window.captions.FillAllRequiredFields);

        steppers[index].isValid = response.errors.length === 0;
        return response;
    }

    if (!(selected.type === questionnaireGroupType.documents ||
        selected.type === questionnaireGroupType.accounts ||
        selected.type === questionnaireGroupType.linkedPersons)) {
        steppers[index].isValid = true;
        return response;
    }

    if (selected.types.length === 0) {
        steppers[index].isValid = true;
        return response;
    }

    if (!validateAsync) {
        response.isAsync = true;
        setPending(window.captions.Tracking);
        fetchGet(
            `${window.constants.validateQuestionnaireStep}/${questId}/${cardId}/${selected.id}`,
            (data) => {
                setPending(null);
                next(data, isAuto, true);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
        return response;
    }

    response.isAsync = false;
    if (asyncErrorsList)
        response.errors = asyncErrorsList;
    else
        response.errors.push("Model error");

    steppers[index].isValid = response.errors.length === 0;
    return response;
}

function phoneVerification(phones, isShow, phoneId, code, altCode, verified, stateId, setVerification, setPhones) {
    setVerification({
        isShow,
        phoneId,
        code,
        altCode
    });
    if (!verified)
        return;

    const indexPhone = phones.findIndex(i => i.id === phoneId);
    if (indexPhone == null || indexPhone < 0)
        return;

    phones[indexPhone].verified = true;
    if (stateId > 0)
        phones[indexPhone].state = stateId;

    setPhones(phones);
}

function reloadData(questId, index, steppers, loanId, contactModel, successPhoneNumber, error, success,
    { setPending, setError, setIndex, setSteppers, setMessage, setMessageError, setCardId, setAccess, setGroups, setActualizationId, setCanSave, setModel, clearIndex }) {
    fetchGet(`${window.constants.getQuestionnaireStep}/${questId}/${loanId}`,
        (dataModel) => {
            if (successPhoneNumber) {
                const phoneIndex = dataModel.phones.findIndex(i => i.phoneNumber === successPhoneNumber);
                if (phoneIndex != null && phoneIndex >= 0) {
                    dataModel.phones[phoneIndex].awaitVerification = !dataModel.phones[phoneIndex].verified;
                }
                clearIndex();
            }

            setIndex(successPhoneNumber || error ? index : index + 1);
            setSteppers(steppers);
            setCardId(dataModel.id);
            setAccess(dataModel.access);
            setGroups(dataModel.groups);
            setModel({
                ...contactModel,
                phones: dataModel.phones,
                addresses: dataModel.addresses,
                urls: dataModel.urls,
                emails: dataModel.emails,
                accounts: dataModel.accounts
            });
            setActualizationId(dataModel.actualizationId);
            setPending(null);

            if (error) {
                setMessage(error);
                setMessageError(true);
            } else {
                setMessage(success && success.length > 0 ? success : null);
                setCanSave(false);
            }
        },
        (ex) => {
            setError(ex);
            setPending(null);
        }
    );
}

function saveStep(contactModel, groups, index, isBlocked, canSave, steppers, cardId, actualizationId, questId, isReturnHome,
    successPhoneIndex, isDialog, { setPending, setIsFinal, setIsReturnHome, setError, setIndex, setSteppers, setMessage, setMessageError, setCardId, setAccess, setGroups, setActualizationId, setCanSave, setModel, clearIndex }, props) {
    const selected = groups[index];
    var count = groups.length;
    var isLastStep = index >= count - 1;
    if (isBlocked || !canSave && !selected.hasPlugin) {
        if (isLastStep) {
            if (isDialog) {
                props.onClose();
            } else {
                setIsFinal(true);
                setPending(null);
            }
        } else {
            setIndex(index + 1);
            setSteppers(steppers);
            setPending(null);
        }
        return;
    }

    setPending(window.captions.SavingData);
    setError(null);
    const fields = [];
    if (selected.type === questionnaireGroupType.fields) {
        for (let key in selected.fields) {
            fields[key] = selected.fields[key];
        }
    }

    const hasValidate = successPhoneIndex != null && successPhoneIndex >= 0;
    var successPhoneNumber = null;
    if (hasValidate) {
        successPhoneNumber = contactModel.phones[successPhoneIndex].phoneNumber;
    }

    const converted = CardSaveFunctions.renderValues(fields);
    const request = {
        values: converted.values,
        nullValues: converted.nullValues,
        id: cardId,

        phones: contactModel.phones.filter(i => i.isUpdated),
        addresses: contactModel.addresses.filter(i => i.isUpdated),
        emails: contactModel.emails.filter(i => i.isUpdated),
        urls: contactModel.urls.filter(i => i.isUpdated),
        accounts: contactModel.accounts.filter(i => i.isUpdated),

        actualizationId: actualizationId,
        stepId: selected.id,
        isLastStep: isLastStep,
        IsIgnoreStep: hasValidate
    };

    fetchPost(`${window.constants.saveQuestionnaire}/${questId}`,
        request,
        (data) => {
            if (data.hasError) {
                steppers[index].isValid = false;
                const error = data.errors.join("\r\n");
                const isFinal = selected.isFinalOnError || data.isFinal;
                if (isFinal || !(data.loanId > 0)) {
                    setSteppers(steppers);
                    setPending(null);
                    setMessage(error);
                    setMessageError(true);
                    setIsFinal(isFinal);
                    setIsReturnHome(isReturnHome && !selected.isOpenCardOnError);
                    if (data.loanId > 0) {
                        setCardId(data.loanId);
                    }
                } else {
                    reloadData(questId, index, steppers, data.loanId, contactModel, null, error, null,
                        { setPending, setError, setIndex, setSteppers, setMessage, setMessageError, setCardId, setAccess, setGroups, setActualizationId, setCanSave, setModel, clearIndex });
                }
                return;
            }

            if (isLastStep) {
                var message = data.messageSuccess && data.messageSuccess.length > 0 ? data.messageSuccess : null;
                if (isDialog) {
                    if (message) {
                        setMessage(message);
                        setIsFinal(true);
                        setPending(null);
                    } else {
                        props.onClose();
                    }
                } else {
                    setCardId(data.loanId);
                    setMessage(message);
                    setIsFinal(true);
                    setPending(null);
                }
            } else {
                reloadData(questId, index, steppers, data.loanId, contactModel, successPhoneNumber, null, data.messageSuccess,
                    { setPending, setError, setIndex, setSteppers, setMessage, setMessageError, setCardId, setAccess, setGroups, setActualizationId, setCanSave, setModel, clearIndex });
            }
        },
        (ex) => {
            setError(ex);
            setPending(null);
        });
}

export {
    validateStep,
    saveStep,
    phoneVerification
};