import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { TreeView } from "@progress/kendo-react-treeview";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchDelete, fetchGet, fetchPost } from '../../utils/requestsHelper.jsx';

import AudioPlayer from "../../components/audioPlayer.jsx";
import VideoPlayer from "../../components/videoPlayer.jsx";

import wikiFunctions from "./wikiFunctions.jsx";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import FileHelper from "../../utils/fileHelper.jsx";

export default function Wiki() {
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        ForeColor,
        BackColor,
        CleanFormatting,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable
    } = EditorTools;

    const [preview, setPreview] = useState(null);
    const [items, setItems] = useState([]);
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState(null);
    const [item, setItem] = useState(null);
    const [search, setSearch] = useState("");
    const [message, setMessage] = useState("");
    const [needReload, setNeedReload] = useState(false);
    const [needPush, setNeedPush] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [canRemove, setCanRemove] = useState(false);
    const [canAddAttachment, setCanAddAttachment] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    const user = useSelector(state => state.header.model);

    const editor = useRef(null);

    useEffect(() => {
        document.title = `Delta M. Crm. ${captions.Wiki}`;
        fetchData();
    }, []);

    useEffect(() => {
        if (params.id)
            wikiLoadElement(params.id);
    }, [params.id]);

    useEffect(() => {
        if (needReload)
            fetchData();

    }, [needReload]);

    useEffect(() => {
        if (!needPush)
            return;

        setNeedPush(false);
        if (item)
            navigate(`/wiki/${item.id}`);

    }, [needPush]);

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    let editView = null;
    let saveButton = null;
    let addButton = null;
    let removeButton = null;
    let overContent = null;
    let itemContent = null;
    let elements = [];
    let treeProps = { className: "dm-size-100 dm-full-height" };
    if (item) {
        if (item.canEdit && !isProcessing) {
            editView = isEditMode
                ? <Button icon="eye" type="second" title={window.captions.View}
                    onClick={() => setIsEditMode(false)} />
                : <Button icon="edit" type="second" title={window.captions.EditMode}
                    onClick={() => setIsEditMode(true)} />;

            saveButton = <Button type="primary"
                icon="save"
                disabled={!item.isUpdated}
                onClick={() => onSave()}
                title={window.captions.Save} />;

            if (canRemove)
                removeButton = <Button icon="delete" title={window.captions.Delete} onClick={() => onRemove(item.id)} />;
        }

        elements = isEditMode ? renderEdit() : renderView();

        treeProps.className = "dm-size-33 dm-full-height";
        treeProps.style = { paddingRight: "16px" };
        itemContent = <div className="dm-size-66 dm-full-height">
            <div className="dm-flex-height">
                {elements}
            </div>
        </div>;
    }

    if (canEdit && (!item || item.id > 0 && item.canEdit))
        addButton = <Button icon="add" className="dm-positive-filled" title={window.captions.NewElement} onClick={() => add()} />;

    if (isProcessing)
        overContent = <LoadingMask text={message} />;

    if (preview) {
        var filePath = `${window.constants.filePreviewWiki}/${user.id}/${preview.id}`;
        var previewControl = "";
        if (preview.contentType === 1) { //Image
            previewControl = <img src={filePath} alt={preview.name} width="100%" />;
        } else if (preview.contentType === 2) { //Audio
            previewControl = <AudioPlayer url={filePath} />;
        } else if (preview.contentType === 3) { //Video
            previewControl = <VideoPlayer url={filePath} />;
        }

        overContent = <Dialog title={window.captions.Preview} onClose={() => previewItem(null)} width={1000}>
            <div className="dm-container">
                {previewControl}
            </div>
            <DialogActionsBar>
                <Button className="k-success-colored"
                    onClick={() => FileHelper.getWiki(preview.id)}>
                    {window.captions.Download}
                </Button>
                <Button themeColor="primary"
                    onClick={() => previewItem(null)}>
                    {window.captions.Close}
                </Button>
            </DialogActionsBar>
        </Dialog>;
    }

    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.Wiki}</h1>
        <div className="dm-under-title-content">
            <div id="wikiWrapper" className="dm-container wikiRoot dm-full-height">
                <div {...treeProps}>
                    <div className="dm-flex-height">
                        <div className="dm-flex-height-header dm-wrapper">
                            <div className="dm-wrapper-content">
                                <Input onChange={(e) => searchChanged(e)}
                                    onKeyDown={(e) => onSearch(e)}
                                    placeholder={window.captions.Search}
                                    value={search}
                                    style={{ width: "100%" }} />
                            </div>
                            <div>
                                <Button icon="find" title={window.captions.Search} onClick={() => fetchData(search)} />
                                {removeButton}
                                {saveButton}
                                {editView}
                                {addButton}
                            </div>
                        </div>
                        <div className="dm-flex-height-content">
                            <TreeView
                                data={items}
                                expandIcons={true}
                                onItemClick={onItemClick}
                                onExpandChange={onExpandChange}
                                animate={false}
                                textField="name"
                                itemRender={props => <span>{props.item.name}</span>}
                                style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
                {itemContent}
                {overContent}
            </div>
        </div>
    </div>;

    function renderView() {
        const headers = [];

        headers.push(<div key="viewCount" className="k-float-right">
            <i className={`dm-i dm-i-eye`} />
            {item.viewCount}
        </div>);

        headers.push(<h1 key="title">{item.name}</h1>);

        if (item.attachments && item.attachments.length > 0) {
            const attachments = wikiFunctions.renderAttachmentsView(item, previewItem);
            if (attachments.length > 0)
                headers.push(<div key="attachmentData" className="dm-wiki-attachment-list">{attachments}</div>);
        }

        const url = `${window.constants.wikiPreview}/${user.networkId}/${item.id}`;
        return [
            <div key="contentHeader" className="dm-flex-height-header" style={{ width: "100%" }}>
                {headers}
            </div>,
            <div key="contentView" className="dm-flex-height-content" style={{ paddingBottom: "16px", overflowY: "hidden" }}>
                <iframe className="k-content dm-full-wh"
                    src={url} />
            </div>
        ];
    }

    function renderEdit() {
        const headers = [];

        const editorTools = [
            [Undo, Redo],
            [Bold, Italic, Underline, Strikethrough],
            [ForeColor, BackColor, CleanFormatting],
            [Subscript, Superscript],
            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [Indent, Outdent],
            [OrderedList, UnorderedList],
            FontSize, FontName, FormatBlock,
            [Link, Unlink, InsertImage],
            [ViewHtml],
            [InsertTable],
            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
            [DeleteRow, DeleteColumn, DeleteTable]
        ];

        headers.push(
            <MultilineTextArea key="itemName" changeText={(e) => changedName(e)} text={item.name} />);

        const attachments = canAddAttachment
            ? wikiFunctions.renderAttachmentsEdit(item, edited)
            : wikiFunctions.renderAttachmentsView(item, previewItem);
        headers.push(<div key="attachmentData" className="dm-container">{attachments}</div>);

        return [
            <div key="contentHeader" className="dm-flex-height-header" style={{ width: "100%" }}>
                {headers}
            </div>,
            <div key="contentEditor" className="dm-flex-height-content" style={{ paddingBottom: "16px" }}>
                <Editor tools={editorTools}
                    onChange={() => edited()}
                    contentStyle={{ height: "100%" }}
                    style={{ height: "100%" }}
                    defaultContent={item.content}
                    ref={editor} />
            </div>
        ];
    }

    function onItemClick(event) {
        if (selected === event.item)
            return;

        if (item && item.id === 0) {
            alert(window.captions.SaveNewItemOrRefreshPage);
            return;
        }

        if (item && item.isUpdated && !confirm(window.captions.CancelChangesConfirm))
            return;

        if (selected)
            selected.selected = false;

        navigate(`/wiki/${event.item.id}`);
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function onSave() {
        const view = editor.current.view;
        item.content = EditorUtils.getHtml(view.state);
        save();
    }

    function onRemove(id) {
        if (id > 0 && !confirm(captions.DeleteItemConfirm))
            return;

        remove(id);
    }

    function onSearch(e) {
        if (e.keyCode === 13)
            fetchData(search);
    }

    function add() {
        var newItem = {
            parentId: null,
            authorId: 0,
            content: "",
            tags: [],
            attachments: [],
            allowedUsers: [],
            items: [],
            id: 0,
            name: window.captions.NewElement,
            canEdit: true,
            isUpdated: true,
            selected: true
        };
        if (selected) {
            newItem.parentId = selected.id;
            selected.expanded = true;
            selected.selected = false;
            selected.items.push(newItem); //TODO maybe update?
        } else {
            setItems([...items, newItem]);
        }
        setItem(newItem);
        setSelected(newItem)
        setIsProcessing(false);
        setIsEditMode(true);
    }

    function previewItem(item) {
        setPreview(item);
    }

    function searchChanged(e) {
        const value = e.target.value;
        const text = value && value.length > 0
            ? value
            : "";
        setSearch(text);
    }

    function edited() {
        setItem({ ...item, isUpdated: true });
    }

    function changedName(e) {
        setItem({ ...item, isUpdated: true, name: e.value });

        if (selected)
            setSelected({ ...selected, name: e.value }); //TODO need it?
    }

    function loadedError(ex) {
        setError(ex);
        setIsLoaded(true);
        setIsProcessing(false);
        setNeedReload(false);
    }

    function fetchData(text) {
        setIsProcessing(true);
        setNeedReload(false);
        setMessage(window.captions.LoadingData);
        fetchGet(text ? `${window.constants.wikiElements}/${text}` : window.constants.wikiElements,
            data => {
                setItems(data.items);
                setTags(data.tags);
                setCanEdit(data.canEdit);
                setCanRemove(data.canRemove);
                setCanAddAttachment(data.canAddAttachment);
                setError(null);
                setIsLoaded(true);
                setNeedReload(false);
                setIsProcessing(false);
            },
            ex => loadedError(ex)
        );
    }

    function wikiLoadElement(id) {
        if (id) {
            setIsProcessing(true);
            setMessage(window.captions.LoadingData);
            fetchGet(`${window.constants.wikiElement}/${id}`,
                data => {
                    data.isUpdated = false;
                    wikiFunctions.attachmentsReindex(data);

                    setSelected(wikiFunctions.findTreeItem(data.id, items));
                    setItem(data);
                    setIsProcessing(false);
                    setIsEditMode(false);
                },
                ex => loadedError(ex)
            );
        } else {
            setSelected(null);
            setItem(null);
            setIsProcessing(false);
            setIsEditMode(false);
        }
    }

    function remove(id) {
        setIsProcessing(true);
        setMessage(window.captions.SavingData);
        fetchDelete(`${window.constants.wikiRemove}/${id}`,
            () => {
                setSelected(null);
                setItem(null);
                setNeedReload(true);
            },
            ex => loadedError(ex)
        );
    }

    function save() {
        setIsProcessing(true);
        setMessage(window.captions.SavingData);
        fetchPost(window.constants.wikiSave,
            item,
            savedItem => {
                if (item) {
                    var needPush = item.id === 0;

                    var itemModel = { ...item, id: savedItem.id, attachments: savedItem.attachments, removedAttachments: [], isUpdated: false };
                    wikiFunctions.attachmentsReindex(itemModel);
                    setItem(itemModel);

                    if (selected)
                        setSelected({ ...selected, id: savedItem.id });//TODO

                    setIsProcessing(false);
                    setIsEditMode(false);
                    setNeedPush(needPush);
                }
                else
                    loadedError(window.captions.SaveDataError);
            },
            ex => loadedError(ex)
        );
    }
}