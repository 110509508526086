import React from "react";
import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { headersFile } from "../../../utils/requestsHelper.jsx";

export default function ImageControl({ field, value, clearField, fileUpload }) {
    let clear = null;
    if (value) {
        clear =
            <Button style={{ float: "right", zIndex: "1" }} icon="delete" onClick={() => clearField()} />;
    }
    return <div style={{ width: "100%" }}>
        {clear}
        <Upload batch={false}
            multiple={false}
            showFileList={false}
            accept="image/*"
            defaultFiles={[]}
            onStatusChange={e => {
                e.fieldId = field;
                fileUpload(e);
            }}
            saveHeaders={headersFile()}
            saveUrl={window.constants.fileUpload} />
    </div>;
}