import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogChooseProject({ onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [collapsed, setCollapsed] = useState([]);
    const [projectId, setProjectId] = useState(0);

    useEffect(() => fetchData(), []);

    if (pending || error) {
        return <Dialog title={window.captions.ChangeProject} onClose={() => onClose(0)} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    let saveButton = "";
    if (projectId > 0) {
        saveButton = <Button themeColor="primary" onClick={() => onClose(projectId)}>{window.captions.WordSelect}</Button>;
    }
    return <Dialog title={window.captions.project} onClose={() => onClose(0)} width={400}>
        <DropDownTreeView
            style={{ width: "100%" }}
            selectedId={projectId}
            treeData={items}
            selectionChanged={changedProject}
            selectionCanceled={selectedProjectCanceled} />
        <DialogActionsBar>
            <Button onClick={() => onClose(0)}>{window.captions.Cancel}</Button>
            {saveButton}
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getProjects}`,
            (data) => {
                setItems(data.items);
                setCollapsed(data.collapsed);
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function changedProject(e) {
        setProjectId(e.value);
    }

    function selectedProjectCanceled(e) {
        setProjectId(0);
    }
}