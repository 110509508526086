import React from "react";
import { fieldTypes } from "../../utils/systemConstants.jsx";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getLabelClass } from "./cardRenderFunctions.jsx";
import PhoneControl from "./controls/phoneControl.jsx";
import AddressControl from "./controls/addressControl.jsx";
import EmailControl from "./controls/emailControl.jsx";
import UrlControl from "./controls/urlControl.jsx";
import AccountControl from "./controls/accountControl.jsx";

export function renderContact(i, fieldModel, sources, phones, addresses, emails, urls, accounts, isEditMode,
    { entity, entityId },
    {
        dialogConfirmVerification,
        verificationNewPhone,
        callPhone,
        clickConfirmField,
        clickFieldRenouncement,
        addContact,
        changedValue
    },
    fieldsConfirm, fieldsRenouncement) {
    const canEdit = isEditMode && fieldModel.isEditable;
    var canAdd = false;
    const result = [];
    var items;
    var types;
    var accessibleTypes;
    var states;
    var accessibleStates;
    var addText;
    var addName;

    switch (fieldModel.type) {
        case fieldTypes.phone:
            items = phones ? phones : [];
            types = sources.phoneTypes;
            canAdd = sources.contactAccess.phone.add;
            accessibleTypes = sources.accessiblePhonesTypes && sources.accessiblePhonesTypes.length > 0
                ? types.filter((t) => sources.accessiblePhonesTypes.includes(t.id) || t.id == 0)
                : types;
            states = sources.phoneStates;
            accessibleStates = sources.accessiblePhonesStatuses && sources.accessiblePhonesStatuses.length > 0
                ? states.filter((t) => sources.accessiblePhonesStatuses.includes(t.id) || t.id == 0)
                : states;
            addText = window.captions.Phone;
            addName = "Phone";
            break;
        case fieldTypes.address:
            items = addresses ? addresses : [];
            types = sources.addressTypes;
            canAdd = sources.contactAccess.address.add;
            accessibleTypes = sources.accessibleAddressesTypes && sources.accessibleAddressesTypes.length > 0
                ? types.filter((t) => sources.accessibleAddressesTypes.includes(t.id) || t.id == 0)
                : types;
            states = sources.addressStates;
            accessibleStates = sources.accessibleAddressesStatuses && sources.accessibleAddressesStatuses.length > 0
                ? states.filter((t) => sources.accessibleAddressesStatuses.includes(t.id) || t.id == 0)
                : states;
            addText = window.captions.Address;
            addName = "Address";
            break;
        case fieldTypes.email:
            items = emails ? emails : [];
            types = sources.emailTypes;
            canAdd = sources.contactAccess.email.add;
            accessibleTypes = sources.accessibleEmailsTypes && sources.accessibleEmailsTypes.length > 0
                ? types.filter((t) => sources.accessibleEmailsTypes.includes(t.id) || t.id == 0)
                : types;
            states = sources.emailStates;
            accessibleStates = sources.accessibleEmailsStatuses && sources.accessibleEmailsStatuses.length > 0
                ? states.filter((t) => sources.accessibleEmailsStatuses.includes(t.id) || t.id == 0)
                : states;
            addText = window.captions.Email;
            addName = "Email";
            break;
        case fieldTypes.url:
            items = urls ? urls : [];
            types = sources.urlTypes;
            canAdd = sources.contactAccess.url.add;
            accessibleTypes = sources.accessibleUrlsTypes && sources.accessibleUrlsTypes.length > 0
                ? types.filter((t) => sources.accessibleUrlsTypes.includes(t.id) || t.id == 0)
                : types;
            states = sources.urlStates;
            accessibleStates = sources.accessibleUrlsStatuses && sources.accessibleUrlsStatuses.length > 0
                ? states.filter((t) => sources.accessibleUrlsStatuses.includes(t.id) || t.id == 0)
                : states;
            addText = window.captions.Url;
            addName = "Url";
            break;
        case fieldTypes.account:
            items = accounts ? accounts : [];
            types = sources.accountTypes;
            canAdd = sources.contactAccess.account.add;
            accessibleTypes = types;
            states = sources.accountStates;
            accessibleStates = states;
            addText = window.captions.Account;
            addName = "Account";
            break;
    }

    if (!items)
        return result;

    let entityItem = {};
    let isPhone = fieldModel.type === fieldTypes.phone;
    if (isPhone) {
        if (entity && entityId)
            entityItem = {
                id: entityId,
                entity: entity
            };
    }

    var contactTypesUnique = window.systemConfig.contactTypesUnique === true && fieldModel.type !== fieldTypes.account;
    var existTypes = [];
    for (let key in items) {
        const item = items[key];
        if (item.isDeleted)
            continue;

        var canItemEdit = canEdit;
        if (isPhone && canItemEdit)
            canItemEdit = item.verified !== true;

        var selectedState = states.find((i) => i.id === item.state);
        if (!canItemEdit && item.state > 0 && selectedState && !selectedState.isPositive)
            continue;

        var itemStates = accessibleStates;
        if (itemStates && selectedState && !itemStates.includes(selectedState))
            itemStates = itemStates.concat(selectedState);

        if (!item.isDeleted && !existTypes.includes(item.type))
            existTypes.push(item.type);

        var itemTypes = accessibleTypes;
        var selectedType = types.find((i) => i.id === item.type);
        if (accessibleTypes && !accessibleTypes.includes(selectedType))
            itemTypes = itemTypes.concat(selectedType);

        var typeEdit = canItemEdit && !contactTypesUnique
            ? <DropDownList data={itemTypes}
                textField="name"
                dataItemKey="id"
                onChange={(e) => changedValue({
                    fieldId: fieldModel.modelName,
                    item: item.id,
                    property: "type",
                    value: e.target.value.id,
                    valueName: e.target.value.name
                })}
                popupSettings={{ animate: false }}
                style={{ width: "100%" }}
                value={selectedType} />
            : <div>{item.typeText}</div>;

        const isEditedName = getLabelClass(item.isUpdated, item.isMain);
        result.push(
            <div key={`${i}${addName}Name${key}`} title={item.typeText} className={isEditedName}>{typeEdit}</div>);

        var editor;
        switch (fieldModel.type) {
            case fieldTypes.phone:
                editor = <PhoneControl
                    changedValue={e => changedValue(e)}
                    call={(e) => callPhone(e)}
                    dialogConfirmVerification={dialogConfirmVerification}
                    verificationNewPhone={verificationNewPhone}
                    item={item}
                    entityItem={entityItem}
                    canVerify={sources.canVerify}
                    access={sources.contactAccess.phone}
                    isEdit={canItemEdit}
                    isContact={fieldModel.isContact}
                    field={fieldModel.modelName}
                    stateList={itemStates}
                    sources={sources} />;
                break;
            case fieldTypes.address:
                editor = <AddressControl
                    changedValue={e => changedValue(e)}
                    item={item}
                    access={sources.contactAccess.address}
                    isEdit={canItemEdit}
                    isContact={fieldModel.isContact}
                    field={fieldModel.modelName}
                    autoFillType={fieldModel.autoFillType}
                    isAutocompleted={fieldModel.isAutocompleted}
                    stateList={itemStates}
                    sources={sources} />;
                break;
            case fieldTypes.email:
                editor = <EmailControl
                    changedValue={e => changedValue(e)}
                    item={item}
                    access={sources.contactAccess.email}
                    isEdit={canItemEdit}
                    isContact={fieldModel.isContact}
                    field={fieldModel.modelName}
                    stateList={itemStates}
                    sources={sources} />;
                break;
            case fieldTypes.url:
                editor = <UrlControl
                    changedValue={e => changedValue(e)}
                    item={item}
                    access={sources.contactAccess.url}
                    isEdit={canItemEdit}
                    isContact={fieldModel.isContact}
                    field={fieldModel.modelName}
                    stateList={itemStates}
                    sources={sources} />;
                break;
            case fieldTypes.account:
                editor = <AccountControl
                    changedValue={e => changedValue(e)}
                    item={item}
                    entityItem={entityItem}
                    access={sources.contactAccess.account}
                    isEdit={canItemEdit}
                    isContact={fieldModel.isContact}
                    field={fieldModel.modelName}
                    currencyList={sources.currencyTypes}
                    stateList={itemStates} />;
                break;
        }

        var editorKey = i + addName + "Editor" + key;
        var isQuestion = fieldsConfirm && fieldsRenouncement;
        if (!isQuestion) {
            result.push(
                <div key={editorKey} className={canItemEdit ? "dm-card-editor" : "dm-card-value"}>{
                    editor}</div>);
        } else {
            var isConfirm = fieldsConfirm.includes(item.id);
            var isRenouncement = fieldsRenouncement.includes(item.id);
            result.push(<div key={editorKey} className="dm-card-editor">
                <div className="dm-wrapper">
                    <div className="dm-wrapper-content">
                        {editor}
                    </div>
                    <Button className={`questionApprove${isConfirm ? " k-state-active" : ""}`}
                        onClick={() => clickConfirmField(item)}
                        icon="check"
                        title={window.captions.ButtonConfirm}>
                    </Button>
                    <Button className={`questionCancel${isRenouncement ? " k-state-active" : ""}`}
                        onClick={() => clickFieldRenouncement(item)}
                        icon="close"
                        title={window.captions.ButtonRenouncement}>
                    </Button>
                </div>
            </div>);
        }
    }

    if (fieldModel.isEditable && addContact && canAdd) {
        var addTypes = accessibleTypes;
        if (contactTypesUnique)
            addTypes = accessibleTypes.filter((t) => !existTypes.includes(t.id));

        if (addTypes.length > 0) {
            result.push(
                <div key={i + addName + "Add"} className="dm-button-contact">
                    <DropDownButton
                        onItemClick={(e) => addContact(fieldModel.modelName, e.item)}
                        popupSettings={{ animate: false }}
                        icon="add"
                        text={addText}
                        items={addTypes}
                        textField="name" />
                </div>);
        }
    }
    return result;
}