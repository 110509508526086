import React from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

function renderImportClients() {
    return <TabStrip>
        <TabStripTab title={window.captions.General}>
            <div className="dm-container dm-no-padding">
                <h1>"Loans"</h1>
            </div>
        </TabStripTab>
        <TabStripTab title={window.captions.Clients}>
            <div className="dm-container dm-no-padding">
                <h1>"Loans"</h1>
            </div>
        </TabStripTab>
    </TabStrip>;
}

export {
    renderImportClients
};