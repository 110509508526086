import React from "react";
import { useSelector } from "react-redux";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MaskedTextBox, Input } from "@progress/kendo-react-inputs";
import { renderMasker } from "../cardRenderFunctions.jsx";
import formatStringByPattern from "format-string-by-pattern";
import ContactContextMenuControl from "./contactContextMenuControl.jsx";

export default function PhoneControl({
    value,
    item,
    call,
    field,
    isEdit,
    isContact,
    required,
    canVerify,
    sources,
    access,
    stateList,
    isAutocompleted,
    changedValue,
    entityItem,
    verificationNewPhone,
    dialogConfirmVerification }) {
    const user = useSelector(state => state.header.model);

    var state = 0;
    var owner = 0;
    let id = 0;
    let phoneNumber = "";
    let comment = "";
    let canCall = true;
    let blockChangeComment = user ? user.access.phoneBlockChangeComment : false;
    if (value) {
        var values = value.split("&PP;");
        [phoneNumber, comment, state, owner] = values;
        state = parseInt(state);
        owner = parseInt(owner);

        if (isNaN(state)) state = 0;
        if (isNaN(owner)) owner = 0;
    } else if (item) {
        id = item.id ? item.id : 0;
        state = item.state ? item.state : 0;
        owner = item.owner ? item.owner : 0;
        phoneNumber = item.phoneNumber;
        comment = item.comment;
        canCall = item.canCall;
    }

    let phoneCodeLocal = sources.localCode;
    const accessLocal = access ? access : { add: true, change: true, owner: true, state: true, stateMain: true, delete: true, main: true, mainAuto: true };
    const phoneCodeListLocal = sources.phoneCodes ? sources.phoneCodes : [];
    const ownerListLocal = sources.clientTypes ? sources.clientTypes : [];

    const stateListLocal = stateList
        ? stateList
        : sources.accessiblePhonesStatuses && sources.accessiblePhonesStatuses.length > 0
            ? sources.phoneStates.filter((t) => sources.accessiblePhonesStatuses.includes(t.id) || t.id == 0)
            : sources.phoneStates;

    const selectedState = stateListLocal.find((e) => e.id === state);
    const selectedOwner = ownerListLocal.find((e) => e.id === owner);

    const selectedStateName = selectedState && state > 0 ? selectedState.name : "";
    const selectedOwnerName = selectedOwner && owner > 0 ? selectedOwner.name : "";

    if (!accessLocal.main && accessLocal.mainAuto && !isAutocompleted) {
        accessLocal.main = true;
        accessLocal.mainAuto = false;
    }

    var text = [];
    var notEditVaue = null;

    var changeValue = false;
    var callButton = null;
    if (phoneNumber) {
        var originalNumber = phoneNumber;
        var code = phoneCodeListLocal.filter(i => phoneNumber.startsWith(i.codeString))[0];
        if (!code) {
            code = phoneCodeLocal ? phoneCodeLocal : phoneCodeListLocal[0];
        }

        var isCoded = phoneNumber.startsWith(code.codeString);

        if (isCoded && code.phoneMask) {
            phoneNumber = formatStringByPattern(code.phoneMask.replace(new RegExp("#", "g"), "0"),
                phoneNumber.substring(code.codeString.length));
            changeValue = phoneNumber.length === 0;

            if (code.uriSource) {
                text.push(
                    <img key="phoneImage" className="dm-phone-code" src={`/img/flag/${code.uriSource}.png`}
                        alt={code.country} title={code.country} />);
            }

            notEditVaue = <span key="phoneNumber">
                <a onClick={() => localCall()}
                    href={`tel:+${originalNumber}`}
                    target="_blank">
                    {getPhoneNumber(`${code.codeString} ${phoneNumber}`, originalNumber, user.phoneMask)}
                </a>
            </span>;
            text.push(notEditVaue);

            phoneNumber = `${code.codeString} ${phoneNumber}`;

            if (!changeValue && canCall)
                callButton =
                    <a onClick={() => localCall()}
                        href={`tel:+${originalNumber}`}
                        target="_blank"
                        className="k-button k-button-icon dm-positive-filled k-rounded-md dm-m-right dm-square">
                        <span className="dm-i dm-i-phone-alt" />
                    </a>;
        } else {
            changeValue = true;

            if (isCoded && code.uriSource) {
                text.push(
                    <img key="phoneImage" className="dm-phone-code" src={`/img/flag/${code.uriSource}.png`}
                        alt={code.country} title={code.country} />);
            }

            if (canCall) {
                notEditVaue = <span key="phoneNumber">
                    <a onClick={() => localCall()}
                        href={`tel:+${originalNumber}`}
                        target="_blank">
                        {getPhoneNumber(phoneNumber, originalNumber, user.phoneMask)}
                    </a>
                </span>;
            } else {
                notEditVaue = <span key="phoneNumber">{getPhoneNumber(phoneNumber, originalNumber, user.phoneMask)}</span>;
            }
            text.push(notEditVaue);
        }

        if (comment)
            text.push(<span key="phoneComment">{`, ${comment}`}</span>);
        if (selectedStateName)
            text.push(<span key="phoneState" className="badge bg-success dm-m-left">{selectedStateName}</span>);
        if (selectedOwnerName)
            text.push(<span key="phoneOwner" className="badge bg-secondary dm-m-left">{selectedOwnerName}</span>);

        phoneCodeLocal = code;
    } else {
        if (!phoneCodeLocal)
            phoneCodeLocal = phoneCodeListLocal[0];

        phoneNumber = `${phoneCodeLocal.codeString} `;
    }

    if (item && item.verified)
        text.push(<span key="verification" className="badge bg-info">{window.captions.Verified}</span>);

    if (!isEdit)
        return <div>
            {text}
        </div>;

    var verifyControl = null;
    if (item && canVerify && accessLocal.change) {
        verifyControl = <ContactContextMenuControl item={item}
            entityItem={entityItem}
            field={field}
            verificationNewPhone={verificationNewPhone}
            dialogConfirmVerification={dialogConfirmVerification} />;
    }

    var stateStyle = "dm-m-right";
    if (selectedStateName)
        stateStyle = stateStyle + (selectedState.isPositive ? " dm-positive" : " dm-negative");
    if (item && item.isMain)
        stateStyle = stateStyle + " dm-field-main";

    const ownerStyle = selectedOwnerName
        ? "dm-m-right dm-positive"
        : "dm-m-right";

    let valid = true;
    if (required) {
        valid = phoneNumber.length > phoneCodeLocal.codeString.length + 1;
    }

    if (item && item.phoneIncorrect)
        valid = false;

    let clear = null;
    if (isContact &&
        (accessLocal.delete || id <= 0) &&
        (selectedOwner || selectedState || phoneNumber || comment)) {
        clear = <Button className="dm-m-left" icon="delete" onClick={clearField} />;
    }

    let stateControl = null;
    if (accessLocal.state) {
        stateControl = <DropDownButton
            onItemClick={e => stateChanged(e)}
            title={selectedStateName}
            icon="stop"
            items={stateListLocal.map((st) => ({ ...st, selected: st.id === state }))}
            textField="name"
            className={stateStyle} />;
    }

    let commentControl = null;
    if (!blockChangeComment) {
        commentControl = <Input
            style={{ width: "100%" }}
            className="dm-m-top"
            placeholder={window.captions.Comment}
            onChange={commentChanged}
            defaultValue={comment} />;
    }

    let ownerControl = null;
    if (accessLocal.owner) {
        ownerControl = <DropDownButton
            onItemClick={e => ownerChanged(e)}
            title={selectedOwnerName}
            icon="user"
            items={ownerListLocal.map((ow) => ({ ...ow, selected: ow.id === owner }))}
            textField="name"
            className={ownerStyle} />;
    }

    var editor;
    let mainDisabled = !accessLocal.main && (id > 0 || accessLocal.mainAuto);
    if (phoneCodeLocal.phoneMask) {
        var mask = `${phoneCodeLocal.codeString} ${phoneCodeLocal.phoneMask}`;
        editor = changeValue
            ? <MaskedTextBox
                mask={mask}
                item={id}
                field={field}
                valid={valid}
                required={required}
                disabled={mainDisabled}
                property="phoneNumber"
                onChange={phoneChanged}
                value={phoneNumber} />
            : <MaskedTextBox
                mask={mask}
                item={id}
                field={field}
                valid={valid}
                required={required}
                disabled={mainDisabled}
                property="phoneNumber"
                onChange={phoneChanged}
                defaultValue={phoneNumber} />;
    } else {
        editor = changeValue
            ? <Input
                item={id}
                field={field}
                property="phoneNumber"
                valid={valid}
                className="dm-phone-number"
                required={required}
                disabled={mainDisabled}
                placeholder={window.captions.PhoneNumber}
                onChange={phoneChanged}
                maxLength={phoneCodeLocal.digitsCountMax}
                value={phoneNumber} />
            : <Input
                item={id}
                field={field}
                property="phoneNumber"
                valid={valid}
                className="dm-phone-number"
                required={required}
                disabled={mainDisabled}
                placeholder={window.captions.PhoneNumber}
                onChange={phoneChanged}
                defaultValue={phoneNumber} />
    }

    return <div className="dm-size-100">
        <div className="dm-wrapper">
            {callButton}
            {stateControl}
            {ownerControl}
            {accessLocal.change
                ? <div className="dm-wrapper-content dm-phone">
                    {editor}
                    <DropDownList
                        data={phoneCodeListLocal}
                        disabled={mainDisabled}
                        itemRender={itemRender}
                        valueRender={valueRender}
                        onChange={codeChanged}
                        value={phoneCodeLocal} />
                </div>
                : <div className="dm-wrapper-content dm-phone">
                    {notEditVaue}
                </div>}
            {clear}
            {verifyControl}
        </div>
        {commentControl}
    </div>;

    function itemRender(li, itemProps) {
        const itemRender = itemProps.dataItem;
        const image = `/img/flag/${itemRender.uriSource}.png`;
        const itemChildren = <span>
            <img src={image} alt={itemRender.country} /> {itemRender.country}
        </span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    function valueRender(element, renderValue) {
        if (!renderValue) {
            return element;
        }

        const image = `/img/flag/${renderValue.uriSource}.png`;
        const itemChildren = <span>
            <img src={image} alt={renderValue.country} />
        </span>;

        return React.cloneElement(element, { ...element.props }, itemChildren);
    }

    function commentChanged(e) {
        changedValue({
            fieldId: field,
            item: id,
            property: "comment",
            value: e.value
        });
    }

    function stateChanged(e) {
        if (!accessLocal.stateMain && ((e.item.id == sources.phoneState || state == sources.phoneState))) {
            alert(window.captions.ChangeMainStateInPhoneAddres);
            return;
        }

        changedValue({
            fieldId: field,
            item: id,
            property: "state",
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function ownerChanged(e) {
        changedValue({
            fieldId: field,
            item: id,
            property: "owner",
            value: e.item.id,
            valueName: e.item.name
        })
    }

    function codeChanged(e) {
        var code = e.target.value;
        var phoneNumber = code.codeString;
        changedValue({
            fieldId: field,
            item: id,
            property: "phoneNumber",
            value: phoneNumber
        });
    }

    function phoneChanged(e) {
        var phoneNumber = e.target.value;
        changedValue({
            fieldId: field,
            item: id,
            property: "phoneNumber",
            value: phoneNumber
                ? phoneNumber.replace(new RegExp("[^0-9]", "g"), "")
                : phoneCodeLocal.codeString
        });
    }

    function clearField() {
        changedValue({
            fieldId: field,
            item: id,
            isClear: true,
            value: null
        });
    }

    function localCall() {
        if (call && item)
            call(item);
    }

    function getPhoneNumber(phoneNumber, oridinalPhone, mask) {
        return mask
            ? renderMasker(oridinalPhone, mask)
            : phoneNumber;
    }
}