import React, { useState, useEffect } from 'react';
import { Checkbox } from "@progress/kendo-react-inputs";
import { TreeView } from "@progress/kendo-react-treeview";

export default function TreeViewCheck(props) {
    const [state, setState] = useState({
        textField: "name",
        isCheckedField: "checked",
        disabledField: "disabled",

        dataTree: null
    });

    useEffect(() => mountProperties(), [props.dataTree, props.isCheckedField, props.textField, props.isSelectedField]);

    return <div className="container">
        <TreeView
            expandIcons={true}
            data={state.dataTree}
            item={renderItem}
            textField={state.textField}
            onExpandChange={onExpandChange}
        />
    </div>;

    function mountProperties() {
        const textField = props.textField ?? "name";
        const isCheckedField = props.isCheckedField ?? "checked";
        const disabledField = props.disabledField ?? "disabled";

        const dataTree = Array.isArray(props.dataTree) && props.dataTree.length ? props.dataTree : [];

        setState({
            textField: textField,
            isCheckedField: isCheckedField,
            disabledField: disabledField,

            dataTree: dataTree,
        });
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };

    function onCheckChange(e, item) {
        item[state.isCheckedField] = e.value;
        updateParents(state, item);
        updateChildrens(item);

        if (props.onCheckChange)
            props.onCheckChange(item);
    }

    function updateParents(item) {
        if (item.getParent)
            var parent = item.getParent();
        if (!parent) {
            return;
        }
        if (parent.items.some((item) => item[state.isCheckedField] || item[state.isCheckedField] == null)) {
            if (parent.items.every((item) => item[state.isCheckedField])) {
                setParentValue(parent, true);
            } else {
                setParentValue(parent, null);
            }
        }
        else {
            setParentValue(parent, false);
        }
    }

    function setParentValue(parent, newValue) {
        if (parent[state.isCheckedField] == newValue)
            return;
        parent[state.isCheckedField] = newValue;
        updateParents(parent);
    }

    function updateChildrens(item) {
        if (!item.items)
            return;
        item.items.forEach(i => {
            setChildrenValue(i, item[state.isCheckedField]);
        });
    }

    function setChildrenValue(child, newValue) {
        if (child[state.isCheckedField] == newValue)
            return;
        child[state.isCheckedField] = newValue;
        updateChildrens(child);
    }

    function renderItem(props) {
        return <Checkbox key={`Checkbox_${props.itemHierarchicalIndex}`}
            value={props.item[state.isCheckedField]}
            label={props.item[state.textField]}
            disabled={props.item[state.disabledField]}
            onChange={(e) => onCheckChange(e, props.item)}
        />;
    };
}