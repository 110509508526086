import React from "react";
import { renderContact } from "./cardRenderContact.jsx";
import { renderInput } from "./cardRenderInput.jsx";
import { renderViewer } from "./cardRenderViewer.jsx";
import formatStringByPattern from "format-string-by-pattern";

function renderMasker(value, mask) {
    if (!mask || value.length < mask.starts)
        return value;

    var firstDigits = value.substring(0, mask.starts);
    var requiredMask = '';
    var lastDigits = '';
    if (mask.starts + mask.ends < value.length) {
        var length = value.length - mask.starts - mask.ends;
        lastDigits = value.substring(value.length - mask.ends);
        requiredMask = mask.symbol.repeat(length);
    } else {
        var length = value.length / 3;
        requiredMask = mask.symbol.repeat(length);
        firstDigits = value.substring(0, length);
        lastDigits = value.substring(firstDigits.length + requiredMask.length);
    }

    return firstDigits + requiredMask + lastDigits;
}

function getPhoneText(phoneNumber, phoneCodeList, phoneCodeLocal) {
    if (!phoneCodeList)
        return phoneNumber;

    let code = phoneCodeList.filter(i => phoneNumber.startsWith(i.codeString))[0];
    if (!code) {
        code = phoneCodeLocal ? phoneCodeLocal : phoneCodeList[0];
    }

    const isCoded = phoneNumber.startsWith(code.codeString);
    if (isCoded && code.phoneMask) {
        phoneNumber = formatStringByPattern(code.phoneMask.replace(new RegExp("#", "g"), "0"),
            phoneNumber.substr(code.codeString.length));

        return `${code.codeString} ${phoneNumber}`;
    } else {
        return phoneNumber;
    }
}

function getAddressText(country,
    postCode,
    region,
    district,
    city,
    typeCity,
    street,
    typeStreet,
    house,
    typeHouse,
    corpus,
    flat) {
    let address = "";
    if (country)
        address = address + ", " + country;
    if (postCode)
        address = address + ", " + postCode;
    if (region)
        address = address + ", " + region;
    if (district)
        address = address + ", " + district;

    if (city)
        address = address + ", " + typeCity + " " + city;
    if (street)
        address = address + ", " + typeStreet + " " + street;
    if (house)
        address = address + ", " + typeHouse + " " + house;

    if (corpus)
        address = address + ", " + corpus;

    if (flat)
        address = address + ", " + flat;

    if (address)
        return address.substr(2);

    return "";
}

function getLabelClass(isUpdated, isMain) {
    return `dm-label${isUpdated ? " dm-field-updated" : ""}${isMain ? " dm-field-main" : ""}`;
}

function renderPair(elements, label, key, editorFunc, value = null, canEdit = true) {
    if ((!editorFunc || !canEdit) && !value)
        return;

    if (label.length > 0)
        elements.push(
            <div key={`${key}Label`} className="dm-label">
                <div>{label}</div>
            </div>);

    elements.push(editorFunc && canEdit
        ? <div key={`${key}Data`} className="dm-card-editor">
            {editorFunc()}
        </div>
        : <div key={`${key}Data`} className="dm-card-value">
            <div>{value}</div>
        </div>);
}

function renderField(fieldModel, key, sources, phones, addresses, emails, urls, accounts, isEditMode,
    { entity, entityId },
    {
        dialogConfirmVerification,
        verificationNewPhone,
        callPhone,
        clickConfirmField,
        clickFieldRenouncement,
        addContact,
        changedValue,
        fileUpload,
        verify
    }) {
    if (fieldModel.isContact)
        return renderContact(key,
            fieldModel,
            sources,
            phones,
            addresses,
            emails,
            urls,
            accounts,
            isEditMode,
            { entity, entityId },
            {
                dialogConfirmVerification,
                verificationNewPhone,
                callPhone,
                clickConfirmField,
                clickFieldRenouncement,
                addContact,
                changedValue
            });

    const value = fieldModel.value;
    const isEditedName = getLabelClass(fieldModel.isUpdated);
    if (isEditMode && fieldModel.isEditable) {
        return [
            <div key={`${key}Name`} className={isEditedName} title={fieldModel.name}>
                <div>{fieldModel.name}{(fieldModel.verify ? <span className="badge bg-info">{window.captions.AwaitingVerificationField}</span> : null)}</div>
            </div>,
            <div key={`${key}Editor`} className="dm-card-editor">{renderInput(
                fieldModel,
                value,
                sources,
                { changedValue, fileUpload })
            }</div>
        ];
    }

    if (!value && value !== 0 && value !== false || fieldModel.text === "") {
        if (!window.systemConfig.emptyFieldVisible)
            return [];

        return [
            <div key={`${key}Name`} className={isEditedName} title={fieldModel.name}>
                <div>{fieldModel.name}</div>
            </div>,
            <div key={`${key}Editor`} className="dm-card-value"></div>
        ];
    }

    return [
        <div key={`${key}Name`} className={isEditedName} title={fieldModel.name}>
            <div>{fieldModel.name}</div>
        </div>,
        <div key={`${key}Editor`} className="dm-card-value">
            {renderViewer(fieldModel, verify)}
        </div>
    ];
}

export {
    getPhoneText,
    getAddressText,
    getLabelClass,
    renderPair,
    renderField,
    renderMasker
};