import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from "@progress/kendo-intl";
import { GridBaseComponent, getFilterByField } from "../../../components/gridApiSource.jsx";
import { GridColumn } from "@progress/kendo-react-grid";
import { getUiCulture } from "../../../utils/authHelper.jsx";
import { Button } from "@progress/kendo-react-buttons";
import GridCollateralCardCell from "../../../components/cells/gridCollateralCardCell.jsx";
import GridEmptyCell from "../../../components/cells/gridEmptyCell.jsx";
import { dateTimeFormat } from "../../../utils/systemConstants.jsx";
import { fetchDelete } from "../../../utils/requestsHelper.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const CollateralsControl = forwardRef(function CollateralsControl(props, ref) {
    const startFilter = window.systemConfig.isShowCollateralWithPositive
        ? getFilterByField("StatePositive", "eq", true)
        : null;

    const [confirm, setConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const prevIsEditMode = useRef(false);
    useEffect(() => {
        if (prevIsEditMode.current == props.isEditMode)
            return;

        prevIsEditMode.current = props.isEditMode;
        filter();
    }, [props.isEditMode]);

    const deleteCollateral = useSelector(state => state.header.model.access.deleteCollateral);

    const removeCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="delete" fillMode="flat" onClick={() => handleOnDelete(p.dataItem)} />
        </td>;

    var culture = getUiCulture();
    const reserveCell = (p) => {
        var td = { style: p.style };

        if (p.className)
            td.className = p.className;

        const value = p.dataItem[p.field];
        if (!value)
            return <td {...td} />;

        var ranges = [];
        value.forEach((i, key) => {
            ranges.push(
                <li key={key}>
                    {formatDate(new Date(i.startDate), dateTimeFormat, culture)}
                    - {formatDate(new Date(i.endDate), dateTimeFormat, culture)}
                </li>);
        });

        return <td {...td}>
            {ranges}
        </td>;
    };

    const actionsCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="menu" fillMode="flat" onClick={() => props.collateralViewEdit(p.dataItem.collateralId)} />
        </td>;

    return [<GridBaseComponent baseUrl={`${window.constants.cardSourceCollaterals}/${props.source}/${props.id}`}
        filter={startFilter}
        key="gridCollateral"
        className="dm-full-wh"
        ref={ref}>
        <GridColumn field="codeText" title={window.captions.WordType} width="240" />
        <GridColumn field="stateText" title={window.captions.State} width="240" />
        <GridColumn field="reserves" title={window.captions.ReservationStates}
            cells={{ data: reserveCell }}
            width="310" />
        <GridColumn field="sumEq" title={window.captions.NormalPrice} width="240" />
        <GridColumn field="marketSumEq" title={window.captions.MarketPrice} width="240" />
        <GridColumn field="owners" title={window.captions.Owners} width="240" />
        <GridColumn field="authorName" title={window.captions.Executor} width="240" />
        <GridColumn title=" " field="id" cells={{ data: GridEmptyCell }} />
        {deleteCollateral && props.isEditMode
            ? <GridColumn locked={true} field="id" title=" " width="35" cells={{ data: removeCell }} />
            : null}
        <GridColumn locked={true} field="id" title=" " width="35" cells={{ data: actionsCell }} />
        {props.canOpenCard
            ? <GridColumn locked={true} field="id" field="collateralId" title=" " width="35"
                cells={{ data: GridCollateralCardCell }} />
            : null}
    </GridBaseComponent>,
    <ConfirmMessage key="confirmRemove"
        yes={deleteItem}
        no={confirmClose}
        text={confirm} />];

    function filter() {
        var filterPositive = window.systemConfig.isShowCollateralWithPositive && !props.isEditMode;
        if (ref.current) {
            if (filterPositive)
                ref.current.filterByField("StatePositive", "eq", true);
            else
                ref.current.filterByField(null, null, null);
        }
    }

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem["collateralId"]);
        if (!isNaN(id)) {
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function deleteItem() {
        var id = deleteId;
        confirmClose();
        fetchDelete(`${window.constants.cardSourceCollaterals}/${props.source}/${props.id}/${id}`,
            id,
            () => filter(),
            () => { });
    }

    function confirmClose() {
        setDeleteId(0);
        setConfirm(false);
    }
})

export default CollateralsControl;