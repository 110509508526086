import { timeIntervalType } from "../../utils/systemConstants.jsx";

export function addInterval(date, type, value) {
    if (!date)
        return undefined;

    switch (type) {
    case timeIntervalType.second:
        date.setSeconds(date.getSeconds() + value);
        break;
    case timeIntervalType.minute:
        date.setMinutes(date.getMinutes() + value);
        break;
    case timeIntervalType.hour:
        date.setHours(date.getHours() + value);
        break;
    case timeIntervalType.day:
        date.setDate(date.getDate() + value);
        break;
    case timeIntervalType.month:
        date.setMonth(date.getMonth() + value);
        break;
    case timeIntervalType.year:
        date.setFullYear(date.getFullYear() + value);
        break;
    default:
        return undefined;

    }
    return date;
}

export function dayOfYear(date) {
    if (!date || !(date instanceof Date))
        return 0;

    const start = new Date(date.getFullYear(), 0, 0);
    const diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}