import React from "react";
import { useState, useEffect } from 'react';
import { StackLayout } from "@progress/kendo-react-layout";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";

export default function PictureLimitationComponent(props) {
    const [restriction, setRestriction] = useState({});
    const [pictureMin, setPictureMin] = useState({});
    const [pictureMax, setPictureMax] = useState({});
    const [needSave, setNeedSave] = useState(false);

    useEffect(() => fetchData(), [props.fieldId]);

    if (needSave) {
        saveRestriction();
        setNeedSave(false);
    }

    let content = [];
    renderPair(content,
        window.captions.CardPreviewImageSizes,
        "RestrictionMin",
        () => <div className="k-d-flex-row dm-m-top">
            <NumericTextBox placeholder={window.captions.Width}
                className="dm-m-right"
                min={0}
                max={240}
                defaultValue={16}
                value={pictureMin.width}
                onChange={saveWidthMin} />
            <NumericTextBox placeholder={window.captions.Height}
                className="dm-m-left dm-m-right"
                min={0}
                max={240}
                defaultValue={16}
                value={pictureMin.height}
                onChange={saveHeightMin} />
        </div>,
        true);

    renderPair(content,
        window.captions.SearchPreviewImageSizes,
        "RestrictionMax",
        () => <div className="k-d-flex-row dm-m-top">
            <NumericTextBox placeholder={window.captions.Width}
                className="dm-m-right"
                min={0}
                max={240}
                defaultValue={16}
                value={pictureMax.width}
                onChange={saveWidthMax} />
            <NumericTextBox placeholder={window.captions.Height}
                className="dm-m-left dm-m-right"
                min={0}
                defaultValue={16}
                value={pictureMax.height}
                onChange={saveHeightMax} />
        </div>,
        true);

    return <StackLayout orientation="vertical" gap={5}>
        {content}
    </StackLayout>;

    function fetchData() {
        if (!props)
            return;

        const model = props.restriction;
        setRestriction(model);
        setPictureMin(model.minValue);
        setPictureMax(model.maxValue);
    }

    function saveHeightMin(item) {
        setPictureMin({ height: item.value, width: pictureMin.width });
        setNeedSave(true);
    }

    function saveHeightMax(item) {
        setPictureMax({ height: item.value, width: pictureMax.width });
        setNeedSave(true);
    }

    function saveWidthMin(item) {
        setPictureMin({ height: pictureMin.height, width: item.value });
        setNeedSave(true);
    }

    function saveWidthMax(item) {
        setPictureMax({ height: pictureMax.height, width: item.value });
        setNeedSave(true);
    }

    function saveRestriction() {
        var newValue = {
            minType: restriction.minType,
            minValue: pictureMin ? pictureMin : null,
            maxType: restriction.maxType,
            maxValue: pictureMax ? pictureMax : null
        };

        var oldValueJson = JSON.stringify(restriction);
        var newValueJson = JSON.stringify(newValue);

        if (oldValueJson === newValueJson)
            return;

        props.onSavePicture(newValue);
    }

}