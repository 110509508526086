import React, { useState, useEffect } from 'react';
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-react-grid";
import { fetchGet } from "../../../utils/requestsHelper.jsx";
import gridFunctions from "../../../components/gridFunctions.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import EmptyMask from "../../../components/emptyMask.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";

export default function PaymentPlanControl(props) {
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(props.id, props.projectId, dataState), [props.id]);

    if (error) {
        return <ErrorMask error={error} />;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    } else if (columns.length === 0) {
        return <EmptyMask />;
    }
    const columnsModels = gridFunctions.renderGridColumns(columns);

    return <div className="dm-full-wh">
        <Grid className="dm-full-wh"
            sortable={true}
            pageable={true}
            resizable={true}
            total={total}
            data={data}
            {...dataState}
            onDataStateChange={handleDataStateChange}>
            {columnsModels}
        </Grid>
        {loadingPanel}
    </div>;

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(props.id, props.projectId, changeEvent.dataState);
    }

    function fetchData(id, projectId, dataState) {
        setPending(true);
        fetchGet(`${window.constants.cardSourcePaymentPlan}/${id}/${projectId}?${toDataSourceRequestString(dataState)}`,
            (dataSource) => {
                setColumns(dataSource.columns);
                setData(dataSource.data);
                setError(null);
                setTotal(dataSource.total);
                setDataState(dataState);
                setPending(false);
            },
            ex => setError(ex));
    }
}