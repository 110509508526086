import { createSlice } from '@reduxjs/toolkit'
import { isMobileSize, authMode } from "../../utils/systemConstants.jsx";
import { isLogged, getUser, getToken, saveAuth, clearAuth, getUiCulture, getUiLang, getUiTheme, getUserMode, changeUiLanguage } from "../../utils/authHelper.jsx";
import { formatDate } from "@progress/kendo-intl";
import { usePushNotifications } from "../../pushs/usePushNotifications.jsx";
import { fetchPost } from '../../utils/requestsHelper.jsx';

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        isLogged: isLogged(),
        model: getUser(),
        ui: getUiCulture(),
        lang: getUiLang(),
        isDark: getUiTheme(),
        isAdmin: getUserMode(),
        authType: authMode.undefined,
        error: "",
        stateChanged: false,
        stateLimit: "",
        isLoginProcess: false,
        isShowUserDialog: false,
        isShowIncomingDialog: false,
        isShowTaskToCloseDialog: false,
        toCreateEntity: null,
        toCreateEntityId: 0,
        callId: null,
        expanded: !isMobileSize(window.innerWidth, window.innerHeight),
        isMobile: isMobileSize(window.innerWidth, window.innerHeight)
    },
    reducers: {
        stateChangeTimer: (state) => {
            var userStateModel = state.model;
            if (!userStateModel)
                return;

            var userState = userStateModel.state;
            var userStateLimit = "";
            var isBlocked = false;
            if (userState.timeLimit > 0) {
                var dataDiff = new Date(userState.created) - new Date();
                var timeDiff = dataDiff + userState.timeLimit * 60 * 1000;
                if (timeDiff <= 0) {
                    isBlocked = true;
                    userStateModel.isBlocked = true;
                    userStateModel.state.timeLimit = 0;
                    saveAuth(userStateModel);
                }

                var stateData = new Date(timeDiff);
                userStateLimit = formatDate(stateData, "mm:ss", state.ui);
            }

            state.stateLimit = userStateLimit;
            if (!isBlocked) {
                state.model = userStateModel;
                state.stateChanged = true;
            }
        },
        userBlockedChanged: (state, action) => {
            var userBlockedModel = state.model;
            userBlockedModel.isBlocked = action.payload;
            if (!userBlockedModel.isBlocked) {
                userBlockedModel.state.created = new Date();
            }
            saveAuth(userBlockedModel);
            state.model = userBlockedModel;
        },
        stateChanged: (state, action) => {
            state.stateChanged = true;
            if (!action.payload) {
                return;
            }

            var model = state.model;
            model.state = action.payload;
            saveAuth(model);
            state.model = model;
        },
        stateChangeFinish: (state) => {
            state.stateChanged = false;
        },
        themeChanged: (state) => {
            state.model.isDark = !state.model.isDark;
            saveAuth(state.model);
            state.isDark = getUiTheme(state.model);
        },
        modeChanged: (state) => {
            state.model.isAdmin = !state.model.isAdmin;
            saveAuth(state.model);
            state.isAdmin = getUserMode(state.model);
        },
        languageChanged: (state, action) => {
            saveAuth(action.payload);
            state.model = action.payload;
            state.ui = getUiCulture(action.payload);
            state.lang = getUiLang(action.payload);
        },
        mainMenuExpanded: (state) => {
            state.expanded = !state.expanded;
        },
        createEntity: (state, action) => {
            state.toCreateEntity = action.payload.entity;
            state.toCreateEntityId = action.payload.id ? action.payload.id : 0;
        },
        stateChangedError: (state, action) => {
            state.error = action.payload;
            state.isLoginProcess = false;
        },
        clearError: (state) => {
            state.error = null;
        },
        storageChanged: (state, action) => {
            var existUser = action.payload;
            state.isLogged = existUser != null;
            state.model = existUser;
        },
        showUserProfile: (state, action) => {
            state.isShowUserDialog = action.payload;
        },
        showIncomingCallDialog: (state, action) => {
            state.isShowIncomingDialog = action.payload.isShow;
            state.callId = action.payload.callId;
        },
        showTaskToCloseDialog: (state, action) => {
            state.isShowTaskToCloseDialog = action.payload;
        },
        loginProcess: (state) => {
            state.isLoginProcess = true;
        },
        loginUpdate: (state, action) => {
            var userModel = state.model;
            userModel.token = action.payload.token;
            userModel.tokenExpires = action.payload.tokenExpires;
            saveAuth(userModel);
            state.model = userModel;
        },
        loginSuccess: (state, action) => {
            var loginModel = action.payload;
            state.isLogged = true;
            state.model = loginModel;
            state.ui = getUiCulture(loginModel);
            state.lang = getUiLang(loginModel);
            state.error = "";
            state.isLoginProcess = false;
            state.stateChanged = true;
        },
        logout: (state, action) => {
            state.isLogged = false;
            state.model = null;
            state.error = action.payload === true ? window.captions.TimeToLogoutWarning : "";
        }
    }
})

export const {
    stateChangeTimer,
    userBlockedChanged,
    stateChanged,
    stateChangeFinish,
    themeChanged,
    modeChanged,
    languageChanged,
    mainMenuExpanded,
    createEntity,
    stateChangedError,
    clearError,
    storageChanged,
    showUserProfile,
    showIncomingCallDialog,
    showTaskToCloseDialog,
    loginUpdate,
    loginProcess,
    loginSuccess,
    logout,
} = headerSlice.actions

export const changeLanguage = (language) => (dispatch) => {
    const data = getUser();
    data.ui = language;
    fetchPost(window.constants.userChangeLanguage,
        language,
        () => { },
        () => { });

    changeUiLanguage(data, () => dispatch(languageChanged(data)));
}

export const changeStorage = (user) => (dispatch) => {
    changeUiLanguage(user, () => dispatch(storageChanged(user)));
}

export const changeState = (id, location) => (dispatch) => {
    fetchPost(window.constants.userChangeState,
        {
            id,
            location: location && location.latitude > 0
                ? {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    accuracy: location.accuracy
                }
                : null
        },
        data => {
            if (data.error)
                dispatch(stateChangedError(data.error));
            else
                dispatch(stateChanged(data));
        },
        ex => {
            dispatch(stateChangedError(ex.stack));
        });
}

export const executeLogin = (userName, password, location) => (dispatch) => {
    if (!userName || !password) {
        dispatch(stateChangedError(window.captions.MessageInputLogin)); //TODO rename stateChangedError
        return;
    }

    dispatch(loginProcess());
    fetchPost(window.constants.token,
        {
            username: userName,
            password: password,
            location: location && location.latitude > 0
                ? {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    accuracy: location.accuracy
                }
                : null
        },
        model => {
            saveAuth(model);
            usePushNotifications();
            changeUiLanguage(model, () => dispatch(loginSuccess(model)));
        },
        ex => {
            dispatch(stateChangedError(ex));
        }
    )
}

export const executeLogout = (isByTimeout) => (dispatch) => {
    const token = getToken();
    if (token) {
        fetch(window.constants.logout,
            {
                method: "POST",
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': `Bearer ${token}`
                }
            }).then(() => {
                clearAuth();
                dispatch(logout(isByTimeout));
            });
    } else {
        clearAuth();
        dispatch(logout(isByTimeout));
    }
}

export default headerSlice.reducer