import React, { useState, useEffect } from 'react';
import { Checkbox } from "@progress/kendo-react-inputs";
import { TreeView } from "@progress/kendo-react-treeview";

export default function ViewEditTreeView(props) {
    const [state, setState] = useState({
        textField: "name",
        viewField: "viewing",
        editField: "editing",

        dataTree: null
    });

    useEffect(() => mountProperties(), [props.dataTree, props.viewField, props.textField, props.editField]);

    return <div className="container">
        <TreeView
            expandIcons={true}
            data={state.dataTree}
            item={renderItem}
            textField={state.textField}
            onExpandChange={onExpandChange}
        />
    </div>;

    function mountProperties() {
        const textField = props.textField ?? "name";
        const viewField = props.viewField ?? "viewing";
        const editField = props.editField ?? "editing";

        const dataTree = Array.isArray(props.dataTree) && props.dataTree.length ? props.dataTree : [];

        setState({
            textField: textField,
            viewField: viewField,
            editField: editField,

            dataTree: dataTree,
        });
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };

    function onCheckChange(e, item, field, dependentField) {
        var updateFields = [field];
        if (dependentField && e.value) {
            updateFields.push(dependentField);
        }
        setValue(item, updateFields, e.value);
        updateParents(item, updateFields);

        if (props.onCheckChange)
            props.onCheckChange(item);
    }

    function updateParents(item, fields = []) {
        if (item.getParent)
            var parent = item.getParent();
        if (!parent) {
            return;
        }

        updateItemByChildrenValue(parent, fields);
    }

    function updateItemByChildrenValue(parent, fields = []) {
        var result = {};
        var fieldsClone = fields.map(i => i);
        endUpdate:
        for (var itemKey in parent.items) {
            var item = parent.items[itemKey];
            for (var fieldKey in fieldsClone) {
                var field = fieldsClone[fieldKey];
                if (result[field] === null) {
                    fieldsClone.splice(fieldKey, 1);
                    if (fieldsClone.length == 0)
                        break endUpdate;
                    continue;
                }
                result[field] = getNewCheckboxValue(result[field], item[field]);
            }
        }

        var needUpdateField = [];
        fields.forEach(i => {
            if (parent[i] !== result[i]) {
                parent[i] = result[i];
                needUpdateField.push(i);
            }
        });
        if (needUpdateField.length > 0)
            updateParents(parent, needUpdateField);
    }

    function getNewCheckboxValue(oldValue, newValue) {
        if (oldValue) {
            return newValue ? oldValue : null;
        } else if (oldValue === false) {
            return newValue || newValue === null ? null : false;
        } else if (oldValue === null) {
            return null;
        } else {
            return newValue;
        }
    }

    function updateChildrens(item, fields = [], newValue) {
        if (!item.items)
            return;
        item.items.forEach(i => setValue(i, fields, newValue));
    }

    function setValue(item, fields = [], newValue) {
        var fieldsNeedUpdate = [];
        fields.forEach(i => {
            if (item[i] == newValue)
                return;
            item[i] = newValue;
            fieldsNeedUpdate.push(i);
        });
        updateChildrens(item, fieldsNeedUpdate, newValue);
    }

    function renderItem(props) {
        return <>
            <Checkbox key={`CheckboxView_${props.itemHierarchicalIndex}`}
                value={props.item[state.viewField]}
                disabled={props.item[state.editField]}
                onChange={(e) => onCheckChange(e, props.item, state.viewField)} >
                <i className={`dm-i dm-i-eye`} />
            </Checkbox>
            <Checkbox key={`CheckboxEdit_${props.itemHierarchicalIndex}`}
                value={props.item[state.editField]}
                onChange={(e) => onCheckChange(e, props.item, state.editField, state.viewField)} >
                <label className={"k-checkbox-label"} style={{ display: "inline-block" }} >
                    <i className={`dm-i dm-i-pen`} />
                    {props.item[state.textField]}
                </label>
            </Checkbox>
        </>;
    }
}