import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import TextControl from "./controls/textControl.jsx";
import FinancialControl from "./controls/financialControl.jsx";
import NumberControl from "./controls/numberControl.jsx";
import DateControl from "./controls/dateControl.jsx";
import DateTimeControl from "./controls/dateTimeControl.jsx";
import TimeControl from "./controls/timeControl.jsx";
import BoolControl from "./controls/boolControl.jsx";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import { filterFieldTypes, entitySourcesNames, getContainerStyle } from "../../utils/systemConstants.jsx";
import {
    changedValue,
    changedValueFrom,
    changedValueTo,
    changedPeriodValueFrom,
    changedPeriodValueTo,
    changedWaterMarkValue,
    clearField,
    comparerSelected,
    filterEditChildren
} from
    "./searchFormReducer.jsx";

export default {
    renderInput(field, filter, dispatch, entity, isReadonly) {
        const value = filter ? filter.values : "";
        const comparer = filter ? filter.compareType : field.defaultComparer;
        const children = filter && filter.children ? filter.children : [];
        const canContainsPeriodFilter = entity === entitySourcesNames.collateral && field.fieldAlias === "State";
        const periodFilterValue = filter && filter.periodFilter ? filter.periodFilter.values : "";
        switch (field.type) {
            case filterFieldTypes.text:
                return <TextControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    comparer={comparer}
                    comparerSelected={(e) => dispatch(comparerSelected(e))}
                    changedValue={(e) => dispatch(changedValue(e))} />;
            case filterFieldTypes.bool:
                return <BoolControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValue={(e) => dispatch(changedValue(e))} />;
            case filterFieldTypes.number:
            case filterFieldTypes.intNumber:
                return <NumberControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => dispatch(changedValueFrom(e))}
                    changedValueTo={(e) => dispatch(changedValueTo(e))}
                    changedWaterMarkValue={(e) => dispatch(changedWaterMarkValue(e))} />;
            case filterFieldTypes.financial:
                return <FinancialControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => dispatch(changedValueFrom(e))}
                    changedValueTo={(e) => dispatch(changedValueTo(e))} />;
            case filterFieldTypes.dropArray:
                return canContainsPeriodFilter
                    ? <div className="dm-wrapper-content">
                        <div className="dm-label-padding">
                            <DropDownCheckedTree
                                disabled={isReadonly}
                                id={`DropArray${field.id}`}
                                field={field.id}
                                treeData={field.items}
                                selectionChanged={(e) => dispatch(filterEditChildren(e))}
                                selectedIdList={children} />
                        </div>
                        <div className="dm-label-padding">
                            <DateControl
                                disabled={isReadonly}
                                field={field}
                                value={periodFilterValue}
                                changedValueFrom={(e) => dispatch(changedPeriodValueFrom(e))}
                                changedValueTo={(e) => dispatch(changedPeriodValueTo(e))} />
                        </div>
                    </div>
                    : <DropDownCheckedTree
                        id={`DropArray${field.id}`}
                        disabled={isReadonly}
                        field={field.id}
                        treeData={field.items}
                        className="dm-wrapper-content"
                        selectionChanged={(e) => dispatch(filterEditChildren(e))}
                        selectedIdList={children} />;
            case filterFieldTypes.date:
                return <DateControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => dispatch(changedValueFrom(e))}
                    changedValueTo={(e) => dispatch(changedValueTo(e))} />;
            case filterFieldTypes.time:
                return <TimeControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => dispatch(changedValueFrom(e))}
                    changedValueTo={(e) => dispatch(changedValueTo(e))} />;
            case filterFieldTypes.dateTime:
                return <DateTimeControl
                    disabled={isReadonly}
                    field={field}
                    value={value}
                    changedValueFrom={(e) => dispatch(changedValueFrom(e))}
                    changedValueTo={(e) => dispatch(changedValueTo(e))} />;
            default:
                return <div />;
        }
    },

    mapField(field, i, selected, dispatch, entity, isReadonly) {
        const filter =
            selected.items.find((e) => e.id === field.id);
        const hasFilter = filter &&
            (filter.values !== field.defaultValue || (filter.children && filter.children.length > 0));
        const editor = this.renderInput(field, filter, dispatch, entity, isReadonly);
        const clear = hasFilter && !isReadonly
            ? <Button icon="refresh" onClick={() => dispatch(clearField(field.id))} />
            : null;
        const wrapperClass = field.type === filterFieldTypes.dateTime ||
            field.type === filterFieldTypes.date ||
            field.type === filterFieldTypes.time
            ? "dm-wrapper dm-allow-wrap"
            : "dm-wrapper";
        return [
            <div key={i + "name"} className="dm-label" title={field.name}>
                <div>{field.name
                }</div>
            </div>,
            <div key={i + "input"} className="dm-card-editor">
                <div className={wrapperClass}>{editor}{clear}</div>
            </div>
        ];
    },

    renderForm(store, dispatch, entity, isReadonly) {
        const selected = store.selected;
        var containerStyle = getContainerStyle(store.sizeMode) + " dm-filter-group";
        if (store.groups.length === 1) {
            return <div className={containerStyle}>
                {store.groups[0].items.map(
                    (field, i) => this.mapField(field, i, selected, dispatch, entity, isReadonly))}
            </div>;
        }

        const filterGroups =
            store.groups.map((item, g) => <PanelBarItem title={item.name} key={g} expanded={g === 0}>
                <div className={containerStyle}>
                    {item.items.map(
                        (field, i) => this.mapField(field,
                            i,
                            selected,
                            dispatch,
                            entity,
                            isReadonly))}
                </div>
            </PanelBarItem>
            );
        return <PanelBar>
            {filterGroups}
        </PanelBar>;
    }
};