import React from "react";
import { useState, useEffect, useRef } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { Stepper } from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import LoadingMask from "../../components/loadingMask.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import DocumentsControl from "../cards/controls/documentsControl.jsx";
import AccountsControl from "../cards/controls/accountsControl.jsx";
import LinkedPersonsControl from "../cards/controls/linkedPersonsControl.jsx";
import { questionnaireGroupType, entitySourcesNames } from "../../utils/systemConstants.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import { renderButtons, renderDialogs } from "./questionnaireRender.jsx";
import { validateStep, saveStep, phoneVerification } from "./questionnaireHelpers.jsx";

export default function QuestionnaireDialog(props) {
    const gridDocuments = useRef(null);
    const gridLinked = useRef(null);
    const gridAccounts = useRef(null);

    const successPhoneIndex = useRef(null);

    const [validateAsync, setValidateAsync] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [name, setName] = useState(window.captions.Questionnaire);
    const [saveName, setSaveName] = useState(window.captions.Save);
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState([]);
    const [steppers, setSteppers] = useState([]);
    const [actualizationId, setActualizationId] = useState(0);
    const [cardId, setCardId] = useState(0);
    const [index, setIndex] = useState(0);
    const [questId, setQuestId] = useState(null);
    const [source, setSource] = useState(entitySourcesNames.loan);
    const [message, setMessage] = useState(null);
    const [messageError, setMessageError] = useState(false);
    const [isFinal, setIsFinal] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [access, setAccess] = useState({});
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(false);
    const [model, setModel] = useState({
        sources: null,
        phones: null,
        addresses: null,
        emails: null,
        urls: null,
        accounts: null
    });
    const [link, setLink] = useState({
        id: 0,
        cardId: 0,
        source: 0
    });
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [verification, setVerification] = useState({
        isShow: false,
        phoneId: 0,
        code: null,
        altCode: null
    });

    useEffect(() => fetchData(), []);

    if (isFinal && !message)
        props.onClose();

    let title = window.captions.Questionnaire;

    if (pending || error) {
        return <Dialog title={title} onClose={props.onClose} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    if (!questId) {
        return <Dialog title={title} onClose={props.onClose} width={550} height={"auto"}>
            <DropDownTreeView
                treeData={items}
                selectionChanged={changeQuestionnaire}
                style={{ width: "100%" }} />
        </Dialog>;
    }

    title = name;
    const buttons = [];
    const selectedGroup = groups[index];

    const count = groups.length;
    if (count > 1) {
        title = count > 5
            ? `${(index + 1)} / ${count}. ${selectedGroup.name}`
            : <div key="stepper" style={{ width: "100%", padding: "0 0 15px 0" }}>
                <Stepper value={index} items={steppers} />
            </div>;
    }

    const customButton = renderButtons(selectedGroup, isBlocked, access, { add });
    if (customButton)
        buttons.push(customButton);

    const content = renderContent(selectedGroup);
    const addingDialog = renderDialogs(selectedGroup, { cardId, source, adding, editing, link, access, verification }, { add, edit, dialogConfirmVerification });

    if (index > 0) {
        buttons.push(
            <Button key="buttonPrevious" onClick={() => previous()}>{window.captions.Previous}</Button>);
    }

    const isLast = index === count - 1;
    const nextName = isLast ? isBlocked ? window.captions.Close : saveName : window.captions.Next;
    buttons.push(<Button key="buttonNext" themeColor={(isLast ? "primary" : null)} onClick={() => next()}>{nextName}</Button>);

    let mask = "";
    if (validateAsync) {
        mask = <LoadingMask />;
    }

    let modal = "";
    if (message) {
        modal = <ValidationMessage key="validateDialog"
            title={window.captions.Questionnaire}
            close={hideMessage}
            error={messageError}
            text={message} />;
    }

    return [
        <Dialog title={title} onClose={props.onClose} width={1024} height={600} key="rootDialog">
            {content}
            {mask}
            <DialogActionsBar>
                {buttons}
            </DialogActionsBar>
        </Dialog>,
        addingDialog,
        modal
    ];

    function renderContent(selectedGroup) {
        const id = cardId;
        const entity = source;
        if (selectedGroup.type === questionnaireGroupType.fields) {
            return <div className="dm-container dm-full-height">
                <FieldsControl id={id}
                    entity={entity}
                    fields={selectedGroup.fields}
                    sources={model.sources}
                    phones={model.phones}
                    addresses={model.addresses}
                    emails={model.emails}
                    urls={model.urls}
                    accounts={model.accounts}
                    isEditMode={!isBlocked}
                    verificationNewPhone={verificationNewPhone}
                    dialogConfirmVerification={dialogConfirmVerification}
                    setFields={setEntityFields} />
            </div>;
        } else if (selectedGroup.type === questionnaireGroupType.documents) {
            return <DocumentsControl source={entity}
                stepId={selectedGroup.id}
                ref={gridDocuments}
                id={id}
                style={{ height: "100%" }}
                isEditMode={!isBlocked}
                canOpenCard={false} />;
        } else if (selectedGroup.type === questionnaireGroupType.accounts) {
            return <AccountsControl source={entity}
                stepId={selectedGroup.id}
                ref={gridAccounts}
                id={id}
                canDelete={false}
                isEditMode={!isBlocked}
                accountViewEdit={(itemId) => edit(true, 0, 0, itemId)} />;
        } else if (selectedGroup.type === questionnaireGroupType.linkedPersons) {
            return <LinkedPersonsControl source={entity}
                sources={model.sources}
                ref={gridLinked}
                id={id}
                style={{ height: "100%" }}
                isEditMode={!isBlocked}
                isRedirectAllowed={false}
                edit={(linkCardId, linkSource, linkId) => edit(true, linkCardId, linkSource, linkId)} />;
        }
        return null;
    }

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        if (props.id) {
            fetchQuestionnaire(parseInt(props.id));
            return;
        }

        var items = props.questionnaires;
        if (items) {
            setItems(items);
            setPending(null);
            if (items.length === 1) {
                fetchQuestionnaire(items[0].id);
            }
        } else {
            setError("No data");
            setPending(null);
        }
    }

    function fetchQuestionnaire(questId) {
        if (isNaN(questId) || questId <= 0)
            return;

        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getQuestionnaireItem}/${questId}/${props.cardId}`,
            (data) => {
                var steppers = [];
                data.groups.forEach(i => steppers.push({ label: i.name }));

                setIsBlocked(data.isBlocked);
                setCardId(props.cardId);
                setName(data.name);
                setSaveName(data.saveName && data.saveName.length > 0 ? data.saveName : window.captions.Save);
                setSteppers(steppers);
                setGroups(data.groups);
                setModel({
                    sources: data.sources,
                    phones: data.phones,
                    addresses: data.addresses,
                    emails: data.emails,
                    urls: data.urls,
                    accounts: data.accounts
                });
                setAccess(data.access);
                setActualizationId(data.actualizationId);
                setIndex(data.stepIndex);
                setQuestId(questId);
                setError(data.error);
                setPending(null);
                setCanSave(false);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function changeQuestionnaire(e) {
        fetchQuestionnaire(e.value);
    }

    function setEntityFields(fields) {
        const selected = groups[index];
        selected.fields = fields;
        setGroups([...groups]);
        setCanSave(true);
    }

    function previous() {
        setIndex(index - 1);
    }

    function save() {
        saveStep(model, groups, index, isBlocked, canSave, steppers, cardId, actualizationId, questId, false, successPhoneIndex.current, true,
            { setPending, setIsFinal, setIsReturnHome: () => { }, setError, setIndex, setSteppers, setMessage, setMessageError, setCardId, setAccess, setGroups, setActualizationId, setCanSave, setModel, clearIndex },
            props);
    }

    function clearIndex() {
        successPhoneIndex.current = null;
    }

    function next(asyncErrorsList, isAuto = false, isAsync = undefined) {
        var result = validateStep(model,
            groups,
            steppers,
            index,
            isBlocked,
            isAsync == undefined ? validateAsync : isAsync,
            asyncErrorsList,
            questId,
            cardId,
            { setPending, setError, next },
            isAuto);
        setValidateAsync(result.isAsync);
        if (result.isAsync)
            return;

        if (result.errors.length > 0) {
            setSteppers(steppers);
            setMessage(result.errors);
            return;
        }

        save();
    }

    function refreshGrid() {
        const selected = groups[index];
        if (selected.type === questionnaireGroupType.documents) {
            gridDocuments.current.refreshDataSource();
        } else if (selected.type === questionnaireGroupType.linkedPersons) {
            gridLinked.current.refreshDataSource();
        } else if (selected.type === questionnaireGroupType.accounts) {
            gridAccounts.current.refreshDataSource();
        }
    }

    function add(isShow) {
        if (isShow) {
            setAdding(true);
            setEditing(false);
        } else {
            setAdding(false);
            setEditing(false);
            setCanSave(true);
            refreshGrid();
        }
    }

    function edit(isShow, linkCardId, linkSource, linkId) {
        if (isShow) {
            setAdding(false);
            setEditing(true);
            setLink({
                id: linkId,
                cardId: linkCardId,
                source: linkSource
            });
        } else {
            setAdding(false);
            setEditing(false);
            setCanSave(true);
            refreshGrid();
        }
    }

    function hideMessage() {
        setMessage(null);
        setMessageError(false);
    }

    function dialogConfirmVerification(isShow, phoneId, code, altCode, verified = false, stateId = 0) {
        phoneVerification(model.phones, isShow, phoneId, code, altCode, verified, stateId, setVerification, ph => setModel({ ...model, phones: ph }));
    }

    function verificationNewPhone(id) {
        successPhoneIndex.current = model.phones.findIndex(i => i.id === id);
        next(true);
    }
}