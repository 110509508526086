import React from "react";
import FieldsControl from "../../../../cards/controls/fieldsControl.jsx";

export default function UserFieldsTab({ dataUser, userChanged }) {

    return <div className="dm-container dm-no-padding">
        <FieldsControl
            fields={dataUser.fields}
            sources={dataUser.sources}
            isEditMode={true}
            key="userFields"
            setFields={setEntityFields} />
    </div>;


    function setEntityFields(fields) {
        if (userChanged)
            userChanged({
                ...dataUser,
                fields: fields,
                isUpdated: true
            });
    }

}