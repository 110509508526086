import JsBarcode from "jsbarcode";

export default {
    printBarcode(code, text) {
        const container = document.createElement("div");
        const barcode = document.createElement("img");
        container.appendChild(barcode);
        const containerText = document.createElement("div");
        containerText.style.cssText = "width: 170px; text-align: center;";
        const textBarcode = document.createElement("p");
        textBarcode.style.cssText = "margin: 0; margin-left: 11px; font-size: 13px; font-family: monospace;"; 
        if (text && text.includes("\n")) {
            const textArr = text.split("\n");
            for (const str of textArr) {
                textBarcode.appendChild(document.createTextNode(str));
                textBarcode.appendChild(document.createElement("br"));
            }
        } else {
            textBarcode.appendChild(document.createTextNode(text));
        }
        containerText.appendChild(textBarcode);
        container.appendChild(containerText);
        JsBarcode(barcode,
            code,
            {
                width: 2,
                height: 35,
                displayValue: true,
                fontSize: '13em',
                textPosition: 'top'	
            });

        const popupWindow = window.open('', 'Print');
        popupWindow.document.write('<!DOCTYPE html><html><head><title>' +
            window.captions.PrintBarcode +
            '</title></head><body onLoad="window.print()">' +
            container.innerHTML +
            '</body></html>');

        popupWindow.document.close();
        popupWindow.focus();
    }
}