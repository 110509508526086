import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { formatDate } from "@progress/kendo-intl";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import PhoneControl from "../cards/controls/phoneControl.jsx";
import EmailControl from "../cards/controls/emailControl.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import MessageMask from "../../components/messageMask.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import { dateTimeFormat, parseDateTimeFormat, getFormatDateTime, dateTimeWidth } from "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";

export default function SearcDialogUserhForm({ userId, onClose }) {
    const intlService = useInternationalization();

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [sources, setSources] = useState({});
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [linkedUsers, setLinkedUsers] = useState([]);
    const [regions, setRegions] = useState([]);

    const [fields, setFields] = useState({});
    const [roleId, setRoleId] = useState(0);
    const [groupId, setGroupId] = useState(0);

    const [names, setNames] = useState({
        role: "",
        group: ""
    });

    const [photo, setPhoto] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [workAddress, setWorkAddress] = useState("");
    const [city, setCity] = useState("");
    const [comment, setComment] = useState("");
    const [created, setCreated] = useState(new Date());
    const [deleted, setDeleted] = useState(null);

    const [linkedUsersList, setLinkedUsersList] = useState([]);
    const [regionsList, setRegionsList] = useState([]);
    const [phonesList, setPhonesList] = useState([]);

    //TODO implement edit for users
    const [canEdit, setCanEdit] = useState({
        role: false,
        group: false,
        region: false,
        linkedUsers: false,
        regions: false,
        comments: false,
        deleted: false,
        fields: false
    });

    const [isChanged, setIsChanged] = useState({
        role: false,
        group: false,
        region: false,
        comments: false,
        deleted: false,
        fields: false
    });

    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => fetchData(), []);

    let title = window.captions.UserProfile;
    if (pending || error) {
        return <Dialog title={title} onClose={onClose} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const elements = [];

    function push(label, value, alias) {
        if (value)
            renderPair(elements, label, alias, null, value);
    }

    if (photo) {
        elements.push(<div key="photo" className="text-center dm-size-100">
            <img src={photo} alt={name} />
        </div>);
    }

    push(window.captions.Fio, name, "name");

    renderPair(elements,
        window.captions.Role,
        "role",
        () => <DropDownList style={{ width: "100%" }}
            textField="name"
            dataItemKey="id"
            data={roles}
            value={roles.find((i) => i.id === roleId)}
            onChange={changeState} />,
        names.role,
        canEdit.role);

    renderPair(elements,
        window.captions.Group,
        "group",
        () => <DropDownList style={{ width: "100%" }}
            textField="name"
            dataItemKey="id"
            data={groups}
            value={roles.find((i) => i.id === groupId)}
            onChange={changeGroup} />,
        names.group,
        canEdit.group);

    if (linkedUsers.length > 0 && (canEdit.linkedUsers || linkedUsersList.length > 0)) {
        renderPair(elements,
            window.captions.RelativeUsers,
            "linkedUsers",
            () => <DropDownCheckedTree
                id="UserDialogLinkedUsers"
                disabled={!canEdit.linkedUsers}
                treeData={linkedUsers}
                selectedIdList={linkedUsersList}
                selectionChanged={changeLinkedUsers}
                style={{ width: "100%" }} />);
    }

    if (regions.length > 0 && (canEdit.regions || regionsList.length > 0)) {
        renderPair(elements,
            window.captions.UserForRegion,
            "regions",
            () => <DropDownCheckedTree
                id="UserDialogRegions"
                disabled={!canEdit.regions}
                treeData={regions}
                selectedIdList={regionsList}
                selectionChanged={changeRegions}
                style={{ width: "100%" }} />);
    }

    push(window.captions.DateCreated, formatDate(created, dateTimeFormat, getUiCulture()), "created");

    if (email)
        push("E-mail", <EmailControl isEdit={false} value={email} />, "email");

    if (phonesList.length > 0) {
        var phones = phonesList.map((phone, key) => {
            return <PhoneControl key={key} sources={sources} value={phone.name} />;
        });
        push(window.captions.Phones, phones, "phone");
    }

    push(window.captions.WorkAddress, workAddress, "workAddress");
    push(window.captions.City, city, "city");

    if (canEdit.comments) {
        renderPair(elements,
            window.captions.Comment,
            "comment",
            () => <MultilineTextArea changeText={changeComment} text={comment} />);
    } else {
        if (comment) {
            elements.push(<div key="commentData" className="dm-card-editor dm-size-100">
                <i className={`dm-i dm-i-comment-alt`} /> {comment}
            </div>);
        }
    }

    renderPair(elements,
        window.captions.RuleUserFiring,
        "deleted",
        () => <DateTimePicker onChange={changeDeleted}
            value={deleted}
            width={dateTimeWidth}
            formatPlaceholder={getFormatDateTime()} />,
        deleted ? formatDate(deleted, dateTimeFormat, getUiCulture()) : null,
        canEdit.deleted);

    elements.push(<FieldsControl fields={fields}
        sources={sources}
        isEditMode={canEdit.fields}
        key="userFields"
        setFields={setEntityFields} />);

    let saveButton = "";
    if (isEdited) {
        saveButton =
            <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Save
            }</Button>;
    }

    return <Dialog title={title} onClose={onClose} width={550}>
        <div className="dm-container dm-no-padding">
            {elements}
        </div>
        <DialogActionsBar>
            <Button onClick={onClose}>{window.captions.Close}</Button>
            {saveButton}
        </DialogActionsBar>
    </Dialog>;

    function changeRole(e) {
        setRoleId(e.target.value.id);
        setIsEdited(true);
        setIsChanged({ ...isChanged, role: true });
    }

    function changeGroup(e) {
        setGroupId(e.target.value.id);
        setIsEdited(true);
        setIsChanged({ ...isChanged, group: true });
    }

    function changeComment(e) {
        setComment(e.value);
        setIsEdited(true);
        setIsChanged({ ...isChanged, comments: true });
    }

    function setEntityFields(fields) {
        setFields(fields);
    }

    function changeDeleted(e) {
        setDeleted(e.value);
        setIsEdited(true);
        setIsChanged({ ...isChanged, deleted: true });
    }

    function changeLinkedUsers(e) {
        setLinkedUsersList(e.value);
        setIsEdited(true);
    }

    function changeRegions(e) {
        setRegionsList(e.value);
        setIsEdited(true);
    }

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getUserModel}/${userId}`,
            (data) => {
                setSources(data.sources);
                setRoles(data.roles);
                setGroups(data.groups);
                setLinkedUsers(data.linkedUsers ? data.linkedUsers : []);
                setRegions(data.regions ? data.regions : []);

                setFields(data.fields);
                setRoleId(data.roleId);
                setGroupId(data.groupId);

                setNames({
                    role: data.roleName,
                    group: data.groupName
                });

                setPhoto(data.photo);
                setName(data.name);
                setEmail(data.email ? data.email : "");
                setWorkAddress(data.workAddress ? data.workAddress : "");
                setCity(data.city ? data.city : "");
                setComment(data.comments ? data.comments : "");
                setCreated(data.created
                    ? intlService.parseDate(new Date(data.created), parseDateTimeFormat)
                    : null);
                setDeleted(data.deleted
                    ? intlService.parseDate(new Date(data.deleted), parseDateTimeFormat)
                    : null);

                setLinkedUsersList(data.linkedUsersList ? data.linkedUsersList : []);
                setRegionsList(data.regionsList ? data.regionsList : []);
                setPhonesList(data.phonesList ? data.phonesList : []);

                setPending(null);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }
}