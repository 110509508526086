import { fieldTypes, dateTimeFormat, emailValidation } from "../../utils/systemConstants.jsx";
import { formatDate } from "@progress/kendo-intl";
import { getUiCulture } from "../../utils/authHelper.jsx";
import { fetchPost } from "../../utils/requestsHelper.jsx";

export default {
    renderValues(fieldsList, result = null, isCopy = false) {
        if (!result) {
            result = {
                values: {},
                nullValues: [],
                isEmpty() {
                    return Object.keys(this.values).length === 0 && this.nullValues.length === 0;
                }
            };
        }

        if (!fieldsList)
            return result;

        for (let key in fieldsList) {
            const field = fieldsList[key];
            if (!field.isEditable || !field.isUpdated && !isCopy || field.isContact)
                continue;

            if (field.value === null)
                result.nullValues.push(key);
            else
                result.values[key] = field.value;
        }

        return result;
    },

    changeEditFields(isEdit, isEdited, model, set, fields) {
        isEdit = !isEdit;
        if (isEdit || !isEdited) {
            set(isEdit, model);
            return;
        }

        if (!fields)
            fields = model.fields;

        const converted = this.renderValues(fields);
        if (converted.isEmpty()) {
            set(false, model);
            return;
        }

        fetchPost(window.constants.parseText,
            converted,
            (data) => {
                for (let fieldId in data) {
                    const field = fields[fieldId];
                    field.text = data[fieldId];
                }
                set(false, model);
            },
            () => set(false, model));
    },

    partPhone(item) {
        return item.phoneNumber;
    },

    partEmail(item) {
        return item.email;
    },

    partUrl(item) {
        return item.url;
    },

    partAccount(item) {
        return item.eNumber;
    },

    validatePhone(item) {
        return item.phoneNumber == null || item.phoneNumber.length > 0;
    },

    validateEmail(item) {
        if (item.email == null)
            return true;

        if (item.email.length <= 0)
            return false;

        return emailValidation.test(item.email.toLowerCase());
    },

    validateUrl(item) {
        return item.url == null || item.url.length > 0;
    },

    validateAccount(item) {
        return item.valid !== false && (item.eNumber == null || item.eNumber.length > 0);
    },

    validateAddress(item) {
        const config = window.systemConfig.address;
        if ((config.Country.RequiredBool && (item.country == null || item.country.length === 0)) ||
            (config.PostCode.RequiredBool && (item.postCode == null || item.postCode.length === 0)) ||
            (config.Region.RequiredBool && (item.region == null || item.region.length === 0)) ||
            (config.District.RequiredBool && (item.district == null || item.district.length === 0)) ||
            (config.TypeCity.RequiredBool && (item.typeCity == null || item.typeCity.length === 0)) ||
            (config.City.RequiredBool && (item.city == null || item.city.length === 0)) ||
            (config.TypeStreet.RequiredBool && (item.typeStreet == null || item.typeStreet.length === 0)) ||
            (config.Street.RequiredBool && (item.street == null || item.street.length === 0)) ||
            (config.TypeHouse.RequiredBool && (item.typeHouse == null || item.typeHouse.length === 0)) ||
            (config.House.RequiredBool && (item.house == null || item.house.length === 0)) ||
            (config.Corpus.RequiredBool && (item.corpus == null || item.corpus.length === 0)) ||
            (config.Flat.RequiredBool && (item.flat == null || item.flat.length === 0)))
            return false;

        return true;
    },

    validateContactList(errorFields, fieldName, items, types, validate, getPart, requiredTypes,
                        requiredIsOr, isDuplicateWithType, duplicateError, verification = null) {
        let required = [];
        if (requiredTypes)
            required = [...requiredTypes];

        if (items.length === 0) {
            if (required.length > 0)
                errorFields.push(fieldName);
            return;
        }

        let needDublicate = getPart !== null;
        let isAny = false;
        let invalidList = [];
        let duplicateList = [];
        for (let key in items) {
            const item = items[key];
            if (item.isDeleted)
                continue;

            const index = required.indexOf(item.type);
            if (index > -1) {
                required.splice(index, 1);
                isAny = true;
            }

            if (verification && verification.includes(item.type) && item.verified !== true)
                errorFields.push(fieldName + ". " + item.typeText + " - " + window.captions.NotVerified);

            if (!item.isUpdated)
                continue;

            if (!validate(item)) {
                var partItem;
                if (needDublicate)
                    partItem = getPart(item);

                invalidList.push(partItem ? partItem : item.typeText);
            } else if (item.incorrectValue) {
                errorFields.push(fieldName + ". " + item.typeText + " - " + window.captions.WrongPhoneFormat);
            }

            if (needDublicate) {
                var partItem = getPart(item);
                if (partItem.length > 0 && items.find((i) => i !== item && getPart(i) === partItem && (!isDuplicateWithType || i.type === item.type))) {
                    duplicateList.push(partItem);
                }
            }
        }

        if (invalidList.length > 0) {
            errorFields.push(fieldName + ". " + invalidList.join());
        }

        if (duplicateList.length > 0) {
            errorFields.push(duplicateError.replace('- ', '').replace('{0}', duplicateList.join()));
        }

        if (required.length <= 0)
            return;

        if (requiredIsOr) {
            if (!isAny) {
                let errorText = fieldName;
                for (let key in requiredTypes) {
                    const typeId = required[key];
                    const typeModel = types.find((i) => i.id === typeId);
                    errorText = errorText + ", " + (typeModel ? typeModel.name : typeId);
                }
                errorFields.push(errorText);
            }
        } else {
            if (types) {
                let errorText = fieldName;
                for (let key in required) {
                    const typeId = required[key];
                    const typeModel = types.find((i) => i.id === typeId);
                    errorText = errorText + ", " + (typeModel ? typeModel.name : typeId);
                }
                errorFields.push(errorText);
            } else {
                errorFields.push(fieldName);
            }
        }
    },

    validatePassport(value, required) {
        if (value == null || value === "")
            return !required;

        const values = value.split("&PP;");
        let type, serial, number, place, date, endDate;
        [type, serial, number, date, place, endDate] = values;
        type = parseInt(type);
        if (isNaN(type) || type === 0)
            return !required;

        if (serial.length === 0 &&
            number.length === 0 &&
            date.length === 0 &&
            place.length === 0 &&
            endDate.length === 0)
            return !required;

        const restrictions = type === 2
            ? window.systemConfig.passportCardRestriction
            : window.systemConfig.passportBookRestriction;

        if (number.length <= 0 && restrictions.ValidateNumber ||
            restrictions.NumberMax > 0 &&
            !(number.length >= restrictions.NumberMin && number.length <= restrictions.NumberMax))
            return false;

        if (number.length > 0 && restrictions.NumberPattern && !(new RegExp(restrictions.NumberPattern).test(number)))
            return false;

        if (type === 1 &&
            (serial.length <= 0 && restrictions.ValidateSerial ||
            restrictions.SerialMax > 0 &&
            !(serial.length >= restrictions.SerialMin && serial.length <= restrictions.SerialMax)))
            return false;

        if (type === 2 && restrictions.ValidateSerial && serial.length <= 0)
            return false;

        if (serial.length > 0 && restrictions.SerialPattern && !(new RegExp(restrictions.SerialPattern).test(serial)))
            return false;

        if (type === 2 && endDate.length !== 10 && restrictions.ValidateDateEnd)
            return false;

        if (date.length !== 10 && restrictions.ValidateDateGiven)
            return false;

        if (place.length <= 0 && restrictions.ValidateIssuedBy)
            return false;

        const dValues = date.split(".");
        if (dValues.length !== 3 && restrictions.ValidateDateGiven)
            return false;

        if (!restrictions.ValidateDateGiven)
            return true;

        date = new Date(dValues[2], parseInt(dValues[1]) - 1, dValues[0]);
        if (restrictions.DateMin && date < new Date(restrictions.DateMin))
            return false;

        let max;
        if (restrictions.DateMax) {
            max = new Date(restrictions.DateMax);
        } else {
            max = new Date();
        }
        return date <= max;
    },

    validateEdrvp(value, required) {
        if (!required)
            return true;

        if (value == null || value === "")
            return false;

        const values = value.split("&PP;");
        let serial, number;
        [serial, number] = values;

        return serial.length > 0 && number.length > 0;
    },

    validateDate(field, required, errorFields) {
        const value = field.value;
        if (value == null || value === "") {
            if (required)
                errorFields.push(field.name);
            return;
        }

        const date = new Date(value);
        if (field.minDateTime != null && date < new Date(field.minDateTime)) {
            errorFields.push(field.name +
                " - " +
                window.captions.Minimum +
                " " +
                formatDate(new Date(field.minDateTime), dateTimeFormat, getUiCulture()));
        } else if (field.maxDateTime != null && date > new Date(field.maxDateTime)) {
            errorFields.push(field.name +
                " - " +
                window.captions.Maximum +
                " " +
                formatDate(new Date(field.maxDateTime), dateTimeFormat, getUiCulture()));
        }
    },

    validateText(field, required, errorFields) {
        const text = field.value;
        let minLength = 0;
        if (field.textLengthMin)
            minLength = field.textLengthMin;

        if (text != null && text.length < minLength) {
            errorFields.push(field.name + " - " + window.captions.Minimum + " " + minLength);
        } else if (required && (text == null || text.length === 0 || text.length < minLength)) {
            errorFields.push(field.name);
        }
    },

    validateNumber(field, required, errorFields) {
        if (field.value == null || field.value === "") {
            if (required)
                errorFields.push(field.name);
            return;
        }

        const floatValue = parseFloat(field.value);
        if (isNaN(floatValue)) {
            errorFields.push(field.name);
            return;
        }

        if (field.minDouble != null && floatValue < field.minDouble) {
            errorFields.push(field.name + " - " + window.captions.Minimum + " " + field.minDouble);
        } else if (field.maxDouble != null && floatValue > field.maxDouble)
            errorFields.push(field.name + " - " + window.captions.Maximum + " " + field.maxDouble);
    },

    validateRequired(fieldsList, model, contactModel, isAuto = false) {
        const errorFields = [];
        let required = contactModel.required;
        if (!required)
            required = [];

        for (let key in fieldsList) {
            const field = fieldsList[key];
            const requiredField = field.isRequired || required.includes(field.id);
            if (!requiredField && !field.isUpdated && !field.isContact)
                continue;

            if (field.type === fieldTypes.text) {
                this.validateText(field, requiredField, errorFields);
            } else if (field.type === fieldTypes.double || field.type === fieldTypes.integer) {
                this.validateNumber(field, requiredField, errorFields);
            } else if (field.type === fieldTypes.date || field.type === fieldTypes.dateTime) {
                this.validateDate(field, requiredField, errorFields);
            } else if (field.type === fieldTypes.passport) {
                if (!this.validatePassport(field.value, requiredField))
                    errorFields.push(field.name);
            } else if (field.type === fieldTypes.edrvp) {
                if (!this.validateEdrvp(field.value, requiredField))
                    errorFields.push(field.name);
            } else if (field.isContact && model) {
                if (field.type === fieldTypes.phone)
                    this.validateContactList(errorFields,
                        field.name,
                        model.phones,
                        model.sources.phoneTypes,
                        this.validatePhone,
                        this.partPhone,
                        contactModel.phones,
                        contactModel.phonesIsOr,
                        window.systemConfig.checkDuplicatesTypePhone,
                        window.captions.DuplicateCardPhones,
                        isAuto ? null : contactModel.verification);

                if (field.type === fieldTypes.address)
                    this.validateContactList(errorFields,
                        field.name,
                        model.addresses,
                        model.sources.addressTypes,
                        this.validateAddress,
                        null,
                        contactModel.address,
                        contactModel.addressIsOr,
                        window.systemConfig.checkDuplicatesTypeAddress,
                        null);

                if (field.type === fieldTypes.email)
                    this.validateContactList(errorFields,
                        field.name,
                        model.emails,
                        model.sources.emailTypes,
                        this.validateEmail,
                        this.partEmail,
                        contactModel.emails,
                        contactModel.emailsIsOr,
                        window.systemConfig.checkDuplicatesTypeEmail,
                        window.captions.DuplicateCardEmails);

                if (field.type === fieldTypes.url)
                    this.validateContactList(errorFields,
                        field.name,
                        model.urls,
                        model.sources.urlTypes,
                        this.validateUrl,
                        this.partUrl,
                        contactModel.urls,
                        contactModel.urlsIsOr,
                        window.systemConfig.checkDuplicatesTypeUrl,
                        window.captions.DuplicateCardUrls);

                if (field.type === fieldTypes.account)
                    this.validateContactList(errorFields,
                        field.name,
                        model.accounts,
                        model.sources.accountTypes,
                        this.validateAccount,
                        this.partAccount,
                        contactModel.accounts,
                        contactModel.accountsIsOr,
                        window.systemConfig.checkDuplicatesTypeAccount,
                        window.captions.DuplicateCardAccounts);

            } else if (requiredField && (field.value == null || field.value === ""))
                errorFields.push(field.name);
        }

        return errorFields;
    }
}