import React from "react";
import { Input } from "@progress/kendo-react-inputs";

export default function EdrvpControl({ field, value, required, changedValue }) {
    let serial = "";
    let number = "";
    if (value) {
        const values = value.split("&PP;");
        [serial, number] = values;
    }

    return <div className="dm-container">
        <Input
            required={required ?? false}
            maxLength="50"
            className="dm-size-50"
            placeholder={window.captions.EdrvpSerial}
            onChange={e => changedValue({ fieldId: field, property: "serial", value: e.value })}
            defaultValue={serial} />
        <Input
            maxLength="50"
            className="dm-size-50"
            required={required ?? false}
            placeholder={window.captions.Identifier}
            onChange={e => changedValue({ fieldId: field, property: "number", value: e.value })}
            defaultValue={number} />
    </div>;
}