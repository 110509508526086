import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { Avatar } from "@progress/kendo-react-layout";
import { headersFile } from "../../utils/requestsHelper.jsx";
import FileHelper from "../../utils/fileHelper.jsx";

export default {
    findTreeItem(id, items) {
        const item = items.find((i) => i.id === id);
        if (item) {
            item.selected = true;
            return item;
        }

        for (const subItem of items) {
            if (subItem.items && subItem.items.length > 0) {
                const subFounded = this.findTreeItem(id, subItem.items);
                if (subFounded) {
                    subItem.expanded = true;
                    return subFounded;
                }
            }
        }
        return null;
    },

    attachmentsReindex(model) {
        if (!model.attachments)
            return;

        for (let i = 0; i < model.attachments.length; i++) {
            model.attachments[i].index = i;
        }
    },

    attachmentUpload(e, edited, model) {
        if (!e.response)
            return;

        const attachments = model.attachments;
        const files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;
            attachments.push({
                authorId: 0,
                fileName: file.fileName,
                fileSize: file.size,
                fileSizeName: file.size + " Kb",
                fileType: file.fileType,
                id: 0,
                index: attachments.length,
                name: file.name
            });
        }
        edited();
    },

    attachmentName(e, edited, model) {
        const index = e.target.props.item;
        const name = e.target.value;
        const attachments = model.attachments;
        const attachment = attachments[index];
        if (!attachment)
            return;

        if (attachment.name === name)
            return;

        attachment.name = name;
        edited();
    },

    attachmentRemove(attachment, edited, model) {
        const attachments = model.attachments;
        if (!attachment)
            return;

        if (!confirm(window.captions.DeleteItemConfirm))
            return;

        if (attachment.id > 0)
            model.removedAttachments.push(attachment.id);

        attachments.splice(attachment.index, 1);
        this.attachmentsReindex(model);
        edited();
    },

    previewFile(item, previewItem) {
        if (item.contentType === 0 || item.contentType === 4)
            FileHelper.getWiki(item.id);
        else
            previewItem(item);
    },

    renderAttachmentsView(model, previewItem) {
        var attachments = [];
        model.attachments.forEach((item) => {
            if (item.id <= 0)
                return;

            var content;
            if (item.preview) {
                content = <Avatar rounded="large" type="image" title={item.name} size="large">
                    <img alt={item.name} src={item.preview} />
                </Avatar>;
            } else {
                content =
                    <span className="dm-icon-64">
                        <FileIcon extension={item.fileType} {...defaultStyles[item.fileType
                            .substr(1)]} />
                    </span>;
            }
            attachments.push(
                <Button key={`attachment${item.id}`}
                    themeColor="primary"
                    onClick={() => this.previewFile(item, previewItem)}
                    title={item.name}>
                    {content}
                    <div className="dm-wiki-attachment">
                        {item.name}<br />
                        <span>{item.fileSizeName}</span>
                    </div>
                </Button>);
        });
        return attachments;
    },

    renderAttachmentsEdit(model, edited) {
        var attachments = [];
        attachments.push(<div key="attachmentUplodader" className="dm-size-100">
            <Upload
                batch={false}
                multiple={true}
                defaultFiles={[]}
                onStatusChange={(e) => this.attachmentUpload(e, edited, model)}
                saveHeaders={headersFile()}
                removeHeaders={headersFile()}
                saveUrl={window.constants.fileUpload}
                removeUrl={window.constants.fileRemove} />
        </div>);

        model.attachments.forEach((item, g) => {
            attachments.push(
                <div key={`DocumentName${g}`} className="dm-size-33">
                    <div className="dm-wrapper">
                        <span className="dm-icon-16" style={{ margin: "10px" }}>
                            <FileIcon extension={item.fileType} {...defaultStyles[item.fileType.substr(1)
                            ]} />
                        </span>
                        <Input placeholder={window.captions.Caption}
                            className="dm-wrapper-content"
                            item={item.index}
                            onChange={(e) => this.attachmentName(e, edited, model)}
                            value={item.name} />
                        <Button icon="delete"
                            onClick={() => this.attachmentRemove(item, edited, model)} />
                    </div>
                </div>
            );
        });

        return attachments;
    },


}