import React, { forwardRef } from "react";
import { GridBaseComponent, createdSort } from "../../../components/gridApiSource.jsx";
import { GridColumn } from "@progress/kendo-react-grid";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";
import GridDateCell from "../../../components/cells/gridDateCell.jsx";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";
import GridColorCell from "../../../components/cells/gridColorCell.jsx";
import GridEmptyCell from "../../../components/cells/gridEmptyCell.jsx";
import { Button } from "@progress/kendo-react-buttons";

const TasksControl = forwardRef(function TasksControl(props, ref) {
    const actionsCell =
        (p) => {
            return <td {...getLinkCellStyle(p)}>
                <Button icon="menu" fillMode="flat" onClick={() => props.taskEdit(p.dataItem["id"])} />
            </td>;
        };

    const statusCell =
        (p) => {
            var stateName = p.dataItem["stateName"];
            var itemProps = {};

            if (p.dataItem["isNormal"])
                itemProps.className = "badge bg-success";
            else if (p.dataItem["isOverdue"])
                itemProps.className = "badge bg-danger";
            else {
                itemProps.className = "badge bg-light";
                itemProps.style = { color: "#000000" };
            }

            return <td {...p.tdProps}>
                <span {...itemProps}>{stateName}</span>
            </td>;
        };

    return <GridBaseComponent baseUrl={`${window.constants.cardSourceTasks}/${props.source}/${props.id}`}
        sort={createdSort}
        ref={ref}
        className="dm-full-wh">
        <GridColumn field="taskType" title={window.captions.WordType} width="240" />
        <GridColumn field="stateName" title={window.captions.State} width="240" cells={{ data: statusCell }} />
        <GridColumn field="created" title={window.captions.Created} width="120" cells={{ data: GridDateCell }} />
        <GridColumn field="range" title={window.captions.TermOfTask} width="160" cells={{ data: GridDateTimeCell }} />
        <GridColumn field="comment" title={window.captions.Comment} width="240" />
        <GridColumn field="creator" title={window.captions.Author} width="240" />
        <GridColumn field="executor" title={window.captions.TaskExecutor} width="240" />
        <GridColumn title=" " field="id" cells={{ data: GridEmptyCell }} />
        <GridColumn locked={true} field="color" title=" " width="35" cells={{ data: GridColorCell }} />
        <GridColumn locked={true} field="id" title=" " width="35" cells={{ data: actionsCell }} />
    </GridBaseComponent>;
})

export default TasksControl;