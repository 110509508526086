import React from "react";
import { useState, useEffect } from 'react';
import { ListBox, ListBoxToolbar, processListBoxData, processListBoxDragAndDrop } from "@progress/kendo-react-listbox";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function ItemSelector({ items, sourceTypes, textField, itemKeyField, isSelectedField, indexField,
    sourceTypeField, availableItemRender, selectedItemRender, selectedItemsChanged }) {
    const selectedField = "selected";

    const defaultProps = {
        textField: "name",
        itemKeyField: "id",
        isSelectedField: "isSelected",
        indexField: "index",
        sourceTypeField: "sourceType",
    };

    const tools = [
        "moveUp",
        "moveDown",
        "transferTo",
        "transferFrom",
        "transferAllTo",
        "transferAllFrom"
    ];

    const [textFieldSafe, setTextFieldSafe] = useState(defaultProps.textField);
    const [itemKeyFieldSafe, setItemKeyFieldSafe] = useState(defaultProps.itemKeyField);
    const [isSelectedFieldSafe, setIsSelectedFieldSafe] = useState(defaultProps.isSelectedField);
    const [indexFieldSafe, setIndexFieldSafe] = useState(defaultProps.indexField);
    const [sourceTypeFieldSafe, setSourceTypeFieldSafe] = useState(defaultProps.sourceTypeField);

    const [draggedItem, setDraggedItem] = useState({});
    const [itemsFilter, setItemsFilter] = useState("");
    const [itemsSelectedFilter, setItemsSelectedFilter] = useState("");
    const [selectedSourceType, setSelectedSourceType] = useState(null);

    const [selected, setSelected] = useState([]);
    const [unSelected, setUnSelected] = useState([]);

    useEffect(() => mountProperties(), [
        items,
        sourceTypes,
        textField,
        itemKeyField,
        isSelectedField,
        indexField,
        sourceTypeField
    ]);

    let selectedListProps = { onDragStart: handleDragStart, onDrop: handleDrop, draggable: true };
    if (itemsSelectedFilter) {
        selectedListProps = { draggable: false };
        tools.splice(0, 2);
    }

    return <div className="container">
        <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                <h4 style={{ width: "100%" }}>{window.captions.Available}</h4>
                <h4 style={{ width: "100%" }}>{window.captions.Selected}</h4>
            </div>
        </div>
        <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                {sourceTypes && sourceTypes.length
                    ? <DropDownList
                        style={{ width: "100%", margin: "5px" }}
                        dataItemKey="id"
                        data={sourceTypes}
                        textField="name"
                        value={selectedSourceType}
                        onChange={onSourceType} />
                    : null}
                <Input style={{ margin: "5px" }}
                    onChange={(e) => onSelectedSearch(e)}
                    placeholder={window.captions.Search} />
                <Input style={{ margin: "5px" }}
                    onChange={(e) => onItemsSearch(e)}
                    placeholder={window.captions.Search} />
            </div>
        </div>
        <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                <ListBox
                    draggable={false}
                    style={{
                        height: 350,
                        width: "100%",
                        margin: "5px"
                    }}
                    data={unSelected}
                    textField={textFieldSafe}
                    selectedField={selectedField}
                    item={availableItemRender}
                    toolbarPosition={"right"}
                    onItemClick={(e) => handleItemAvailableClick(e)}
                    toolbar={() => {
                        return (
                            <ListBoxToolbar
                                tools={tools}
                                data={unSelected}
                                dataConnected={selected}
                                onToolClick={handleToolBarClick}
                            />
                        );
                    }} />
                <ListBox
                    style={{
                        height: 350,
                        width: "100%",
                        margin: "5px"
                    }}
                    data={selected}
                    textField={textFieldSafe}
                    selectedField={selectedField}
                    item={selectedItemRender}
                    onItemClick={(e) => handleItemSelectedClick(e, "selected", "unSelected")}
                    {...selectedListProps} />
            </div>
        </div>
    </div>;

    function mountProperties() {
        const textFieldMount = textField ?? defaultProps.textField;
        const itemKeyFieldMount = itemKeyField ?? defaultProps.itemKeyField;
        const isSelectedFieldMount = isSelectedField ?? defaultProps.isSelectedField;
        const indexFieldMount = indexField ?? defaultProps.indexField;
        const sourceTypeFieldMount = sourceTypeField ?? defaultProps.sourceTypeField;

        const selectedSourceTypeMount = sourceTypes && sourceTypes.length
            ? sourceTypes.includes(selectedSourceType)
                ? selectedSourceType
                : sourceTypes[0]
            : null;

        setTextFieldSafe(textFieldMount);
        setItemKeyFieldSafe(itemKeyFieldMount);
        setIsSelectedFieldSafe(isSelectedFieldMount);
        setIndexFieldSafe(indexFieldMount);
        setSourceTypeFieldSafe(sourceTypeFieldMount);

        setSelectedSourceType(selectedSourceTypeMount);
        setSelected(items.filter(i => i[isSelectedFieldMount] && (!itemsSelectedFilter || i[textFieldMount].match(new RegExp(itemsSelectedFilter, "i")))).sort((x, y) => x[indexFieldMount] - y[indexFieldMount]));
        setUnSelected(items.filter(i => !i[isSelectedFieldMount] && (!selectedSourceTypeMount || i[sourceTypeFieldMount] === selectedSourceTypeMount.id) && (!itemsFilter || i[textFieldMount].match(new RegExp(itemsFilter, "i")))).sort((a, b) => a[textFieldMount].localeCompare(b[textFieldMount])));
    }

    function handleItemAvailableClick(event) {
        handleItemClick(event, unSelected, setUnSelected, selected, setSelected);
    }

    function handleItemSelectedClick(event) {
        handleItemClick(event, selected, setSelected, unSelected, setUnSelected);
    }

    function handleItemClick(event, data, setData, connectedData, setConnectedData) {
        setData(data.map((item) => {
            if (item[itemKeyFieldSafe] === event.dataItem[itemKeyFieldSafe]) {
                item[selectedField] = !item[selectedField];
            } else if (!event.nativeEvent.ctrlKey) {
                item[selectedField] = false;
            }
            return item;
        }));
        setConnectedData(connectedData.map((item) => {
            item[selectedField] = false;
            return item;
        }));
    };

    function handleToolBarClick(e) {
        let toolName = e.toolName || "";
        let result = processListBoxData(
            unSelected,
            selected,
            toolName,
            selectedField
        );

        let unSelected = [];
        let selected = [];
        if (toolName === "moveUp" || toolName === "moveDown") {
            unSelected = result.listBoxOneData;
            result.listBoxTwoData.forEach((item, index) => {
                item[indexFieldSafe] = index;
            });
            selected = result.listBoxTwoData.map((item, index) => {
                item[indexFieldSafe] = index;
                return item;
            });
            setSelectedItemsChanged(selected, unSelected, selected);
        }
        else {
            unSelected = result.listBoxOneData.filter((item) => {
                item[isSelectedFieldSafe] = false;
                item[indexFieldSafe] = null;
                return (!selectedSourceType || item[sourceTypeFieldSafe] == selectedSourceType.id) && (!itemsFilter || item[textFieldSafe].match(new RegExp(itemsFilter, "i")));
            }).sort((a, b) => a[textFieldSafe].localeCompare(b[textFieldSafe]));

            if (itemsSelectedFilter) {
                let maxIndex = Math.max(...props.items.map(i => i[indexFieldSafe]));
                selected = result.listBoxTwoData.filter((item) => {
                    item[isSelectedFieldSafe] = true;
                    if (!item[indexFieldSafe] && item[indexFieldSafe] !== 0)
                        item[indexFieldSafe] = maxIndex++;

                    return item[textFieldSafe].match(new RegExp(itemsSelectedFilter, "i"));
                });

                let globalSelected = props.items.filter(i => i[isSelectedFieldSafe]).sort((x, y) => x[indexFieldSafe] - y[indexFieldSafe]);
                setSelectedItemsChanged(selected, unSelected, globalSelected);
            }
            else {
                selected = result.listBoxTwoData.map((item, index) => {
                    item[isSelectedFieldSafe] = true;
                    item[indexFieldSafe] = index;
                    return item;
                });

                setSelectedItemsChanged(selected, unSelected, selected);
            }
        }
    };

    function setSelectedItemsChanged(selected, unSelected, selectedGlobal) {
        setUnSelected(unSelected);
        setSelected(selected);

        if (selectedItemsChanged)
            selectedItemsChanged(selectedGlobal);
    }

    function handleDragStart(e) {
        setDraggedItem(e.dataItem);
    };

    function handleDrop(e) {
        let result = processListBoxDragAndDrop(
            unSelected,
            selected,
            draggedItem,
            e.dataItem,
            itemKeyFieldSafe
        );

        result.listBoxTwoData.forEach((item, index) => {
            item[isSelectedFieldSafe] = true;
            item[indexFieldSafe] = index;
        });

        setSelected(result.listBoxTwoData);

        if (selectedItemsChanged)
            selectedItemsChanged(result.listBoxTwoData);
    };

    function onItemsSearch(e) {
        items.forEach(i => {
            if (!i[isSelectedFieldSafe])
                i[selectedField] = false;
        });

        setItemsFilter(e.value);
        setUnSelected(items.filter(i => !i[isSelectedFieldSafe] && (!selectedSourceType || i[sourceTypeFieldSafe] == selectedSourceType.id) && (!e.value || i[textFieldSafe].match(new RegExp(e.value, "i")))).sort((a, b) => a[textFieldSafe].localeCompare(b[textFieldSafe])));
    }

    function onSelectedSearch(e) {
        items.forEach(i => {
            if (i[isSelectedFieldSafe])
                i[selectedField] = false;
        });

        setItemsSelectedFilter(e.value);
        setSelected(items.filter(i => i[isSelectedFieldSafe] && (!e.value || i[textFieldSafe].match(new RegExp(e.value, "i")))));
    }

    function onSourceType(e) {
        setSelectedSourceType(e.value);
        setUnSelected(items.filter(i => !i[isSelectedFieldSafe] && (!e.value || i[sourceTypeFieldSafe] === e.value.id) && (!i[itemsFilter] || i[textFieldSafe].match(new RegExp(i[itemsFilter], "i")))).sort((a, b) => a[textFieldSafe].localeCompare(b[textFieldSafe])));
    }
}