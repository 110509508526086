import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchPost, fetchGet } from "../../utils/requestsHelper.jsx";
import DropDownAlternating from "../../components/controls/dropDownAlternating.jsx";
import MessageMask from "../../components/messageMask.jsx";

export default function DialogLoanCopy({ projectId, stageId, stateId, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [items, setItems] = useState([]);
    const [collapsed, setCollapsed] = useState([]);
    const [error, setError] = useState(null);

    const [options, setOptions] = useState({
        projectId: projectId,
        stageId: stageId,
        stateId: stateId
    });

    useEffect(() => fetchData(), []);

    if (pending || error) {
        return <Dialog title={window.captionsDynamic.DuplicateLoan} onClose={() => onClose(0)} width={400}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    let saveButton = "";
    if (options.projectId > 0 && options.stateId > 0) {
        saveButton = <Button themeColor="primary" onClick={save}>{window.captions.Create}</Button>;
    }

    var labels = [
        window.captions.ProjectsGroup,
        window.captions.Project,
        window.captions.Stage,
        window.captions.State
    ];

    if (collapsed.length > 0)
        labels = labels.slice(collapsed.length);

    return <Dialog title={window.captionsDynamic.DuplicateLoan} onClose={() => onClose(null)} width={400}>
        <DropDownAlternating
            id={`DropAlternatingNewLoan`}
            style={{ width: "100%" }}
            treeData={items}
            treeLabels={labels}
            selectionChanged={changedState}
            selectionCanceled={selectedStateCanceled} />
        <DialogActionsBar>
            <Button onClick={() => onClose(null)}>{window.captions.Cancel}</Button>
            {saveButton}
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getProjectModel}`,
            (data) => {
                setItems(data.items);
                setCollapsed(data.collapsed);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function changedState(e) {
        var stateId = e.value;
        if (options.stateId === stateId)
            return;

        var newProjectId = 0;
        var newStageId = 0;
        if (collapsed.length >= 2) {
            newProjectId = collapsed[1];
            newStageId = collapsed[2];
        } else {
            newProjectId = e.selected[1 - collapsed.length].id;
            newStageId = e.selected[2 - collapsed.length].id;
        }

        setOptions({
            projectId: newProjectId,
            stageId: newStageId,
            stateId: stateId
        })
    }

    function selectedStateCanceled() {
        setOptions({ projectId: 0, stageId: 0, stateId: 0 });
    }

    function save() {
        var saveData = {
            loanId: loanId,
            ProjectId: options.projectId,
            StageId: options.stageId,
            StateId: options.stateId
        };

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(window.constants.getCopyLoan,
            saveData,
            (loanModel) => onClose(loanModel),
            () => onClose(null));
    }
}