import React from "react";
import { ColorPicker } from "@progress/kendo-react-inputs";

export default function ColorPickerControl({ required, value, view, field, changeValue }) {
    let isValid = true;
    if (required)
        isValid = value !== undefined;

    return <ColorPicker
        view={view}
        defaultValue={value}
        value={value}
        valid={isValid}
        onChange={(e) => changeValue({ fieldId: field, value: rgbaToHex(e.value) })} />;

    function rgbaToHex(rgba) {
        rgba = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgba && rgba.length === 4)
            ? "#" +
            (`0${parseInt(rgba[1], 10).toString(16)}`).slice(-2) +
            (`0${parseInt(rgba[2], 10).toString(16)}`).slice(-2) +
            (`0${parseInt(rgba[3], 10).toString(16)}`).slice(-2)
            : "";
    }

}