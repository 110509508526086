import React, { useState } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Hint, Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export default function DialogConfirmVerification({ phoneId, code, altCode, onClose }) {
    const [codeText, setCodeText] = useState("");
    const [isEquals, setIsEquals] = useState(false);

    let buttonConfirm = "";
    if (isEquals) {
        buttonConfirm = <DialogActionsBar>
            <Button themeColor="primary" onClick={confirmVerification}>{window.captions.ButtonConfirm
            }</Button>
        </DialogActionsBar>;
    }

    return <Dialog title={window.captions.ConfirmVerification}
        onClose={() => onClose(false, 0)}
        width={350}>
        <div>
            <Input
                value={codeText}
                style={{ fontSize: "30px", letterSpacing: "5px", textAlign: "center", width: "100%" }}
                ariaDescribedBy={"codeInput"}
                onChange={handleChangeCode} />
            {isEquals
                ? <Hint id={"codeInput"} className="dm-positive">{window.captions.Verified}</Hint>
                : <Error id={"codeInput"}>{window.captions.NotVerified}</Error>
            }
        </div>
        {buttonConfirm}
    </Dialog>;

    function handleChangeCode(e) {
        const value = e.value
            ? e.value.toUpperCase().trim()
            : "";

        setCodeText(value);
        setIsEquals(value === code.toUpperCase() || altCode && value === altCode.toUpperCase());
    }

    function confirmVerification() {
        if (!isEquals)
            return;

        fetchGet(`${window.constants.cardPhoneConfirmVerification}/${phoneId}`,
            (stateId) => onClose(true, stateId),
            () => onClose(false, 0));
    }
}