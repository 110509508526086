import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet } from "../../utils/requestsHelper.jsx";
import { Stepper } from "@progress/kendo-react-layout";
import LoadingMask from "../../components/loadingMask.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import DocumentsControl from "../cards/controls/documentsControl.jsx";
import AccountsControl from "../cards/controls/accountsControl.jsx";
import LinkedPersonsControl from "../cards/controls/linkedPersonsControl.jsx";
import { eventBeforeUnload, questionnaireGroupType, getContainerStyle, entitySourcesNames, eventResize, getLayoutSize, layoutSize } from "../../utils/systemConstants.jsx";
import { renderButtons, renderDialogs } from "./questionnaireRender.jsx";
import { validateStep, saveStep, phoneVerification } from "./questionnaireHelpers.jsx";

export default function QuestionnaireCard() {
    const gridDocuments = useRef(null);
    const gridLinked = useRef(null);
    const gridAccounts = useRef(null);

    const blockReloading = useRef(false);
    const successPhoneIndex = useRef(null);

    const navigate = useNavigate();

    const [cardId, setCardId] = useState(0);
    const [isBlocked, setIsBlocked] = useState(false);
    const [sizeMode, setSizeMode] = useState({ size: layoutSize.one, editor: layoutSize.one, inlineDialog: false });
    const [name, setName] = useState(window.captions.Questionnaire);
    const [saveName, setSaveName] = useState(window.captions.Save);
    const [validateAsync, setValidateAsync] = useState(false);
    const [groups, setGroups] = useState([]);
    const [steppers, setSteppers] = useState([]);
    const [actualizationId, setActualizationId] = useState(0);
    const [index, setIndex] = useState(0);
    const [isReturnHome, setIsReturnHome] = useState(false);
    const [questId, setQuestId] = useState(null);
    const [source, setSource] = useState(entitySourcesNames.loan);
    const [canSave, setCanSave] = useState(false);
    const [access, setAccess] = useState({});
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageError, setMessageError] = useState(false);
    const [isFinal, setIsFinal] = useState(false);

    const [model, setModel] = useState({
        sources: null,
        phones: null,
        addresses: null,
        emails: null,
        urls: null,
        accounts: null
    });
    const [link, setLink] = useState({
        id: 0,
        cardId: 0,
        source: 0
    });
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [verification, setVerification] = useState({
        isShow: false,
        phoneId: 0,
        code: null,
        altCode: null
    });

    const params = useParams();

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.Questionnaire}`;
        window.addEventListener(eventResize, handleWindowSizeChange);
        window.addEventListener(eventBeforeUnload, formClosed);

        return function cleanup() {
            window.removeEventListener(eventResize, handleWindowSizeChange);
            window.removeEventListener(eventBeforeUnload, formClosed);

        };
    }, []);

    useEffect(() => {
        if (blockReloading.current)
            blockReloading.current = false;
        else
            fetchQuestionnaire(params.id, params.entityId);
    }, [params.id, params.entityId]);

    useEffect(() => {
        if (!isFinal || message)
            return;

        const address = isReturnHome ? "/home" : `/card/${source}/${cardId}`;
        setIsFinal(false);
        navigate(address);
    }, [isFinal]);

    useEffect(() => {
        if (cardId > 0 && !params.entityId) {
            blockReloading.current = true;
            navigate(`/questionnaire/${params.id}/${cardId}`);
        }
    }, [cardId]);

    if (pending || error) {
        return <MessageMask text={pending} error={error} />;
    }

    const buttons = [];
    const selectedGroup = groups[index];

    if (!selectedGroup)
        return <LoadingMask />;

    let stepper = "";
    const count = groups.length;
    if (count > 1) {
        stepper = <div key="stepper" style={{ width: "100%", padding: "0 0 15px 0" }}>
            <Stepper value={index} items={steppers} />
        </div>;
    }

    const customButton = renderButtons(selectedGroup, isBlocked, access, { add });
    if (customButton)
        buttons.push(customButton);

    const content = renderContent(selectedGroup);
    const addingDialog = renderDialogs(selectedGroup, { cardId, source, adding, editing, link, access, verification }, { add, edit, dialogConfirmVerification });

    if (index > 0) {
        buttons.push(
            <Button key="buttonPrevious" style={{ marginRight: "10px" }} onClick={() => previous()}>{window
                .captions.Previous}</Button>);
    }

    const isLast = index === count - 1;
    const nextName = isLast ? isBlocked ? window.captions.Close : saveName : window.captions.Next;
    buttons.push(<Button key="buttonNext" themeColor="primary" onClick={() => next()}>{nextName}</Button>);

    let mask = "";
    if (validateAsync) {
        mask = <LoadingMask />;
    } else if (pending) {
        mask = <LoadingMask text={pending} />;
    }

    let modal = "";
    if (message) {
        modal = <ValidationMessage title={name}
            button={isFinal ? window.captions.Finished : window.captions.WordOk}
            close={hideMessage}
            error={messageError}
            text={message} />;
    }

    return <div className="dm-full-height">
        <h1 className="dm-title">{name}</h1>
        <div className="dm-under-title-content">
            {stepper}
            <div className="db-overflow-y" style={{ height: "calc(100% - 160px)" }}>
                {content}
            </div>
            {mask}
            <span style={{ marginTop: "20px" }} className={"k-form-separator"} />
            <div
                style={{ justifyContent: "space-between", alignContent: "center", margin: "10px 15px" }}
                className={"k-form-buttons k-buttons-end"}>
                <span style={{ alignSelf: "center" }}>{`${(index + 1)} / ${count}`}</span>
                <div>
                    {buttons}
                </div>
            </div>
            {addingDialog}
            {modal}
        </div>
    </div>;

    function renderContent(selectedGroup) {
        const id = cardId;
        const entity = source;
        if (selectedGroup.type === questionnaireGroupType.fields) {
            var containerStyle = getContainerStyle(sizeMode) + " dm-full-height";
            return <div className={containerStyle}>
                <FieldsControl id={id}
                    entity={entity}
                    fields={selectedGroup.fields}
                    sources={model.sources}
                    phones={model.phones}
                    addresses={model.addresses}
                    emails={model.emails}
                    urls={model.urls}
                    accounts={model.accounts}
                    isEditMode={!isBlocked}
                    verificationNewPhone={verificationNewPhone}
                    dialogConfirmVerification={dialogConfirmVerification}
                    setFields={setEntityFields} />
            </div>;
        } else if (selectedGroup.type === questionnaireGroupType.documents) {
            return <DocumentsControl source={entity}
                stepId={selectedGroup.id}
                ref={gridDocuments}
                id={id}
                style={{ height: "100%" }}
                isEditMode={!isBlocked}
                canOpenCard={false} />;
        } else if (selectedGroup.type === questionnaireGroupType.accounts) {
            return <AccountsControl source={entity}
                stepId={selectedGroup.id}
                ref={gridAccounts}
                id={id}
                canDelete={false}
                isEditMode={!isBlocked}
                accountViewEdit={(itemId) => edit(true, 0, 0, itemId)} />;
        } else if (selectedGroup.type === questionnaireGroupType.linkedPersons) {
            return <LinkedPersonsControl source={entity}
                sources={model.sources}
                ref={gridLinked}
                id={id}
                style={{ height: "100%" }}
                isEditMode={!isBlocked}
                isRedirectAllowed={false}
                edit={(linkCardId, linkSource, linkId) => edit(true, linkCardId, linkSource, linkId)} />;
        }
        return null;
    }

    function formClosed(e) {
        if (!canSave)
            return null;

        e.preventDefault();
        e.returnValue = "Can close?";
        return e.returnValue;
    }

    function handleWindowSizeChange() {
        setSizeMode(getLayoutSize(window.innerWidth, true));
    }

    function fetchQuestionnaire(questId, entityId) {
        if (isNaN(questId) || questId <= 0) {
            setPending(null);
            setError("Undefined questId");
            return;
        }

        if (!entityId)
            entityId = 0;
        else
            entityId = parseInt(entityId);

        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getQuestionnaireItem}/${questId}/${entityId}`,
            (data) => {
                var steppers = [];
                data.groups.forEach(i => steppers.push({ label: i.name }));
                document.title = `Delta M. Crm. ${data.name}`;

                setIsBlocked(data.isBlocked);
                setName(data.name);
                setSizeMode(getLayoutSize(window.innerWidth, true));
                setSaveName(data.saveName && data.saveName.length > 0 ? data.saveName : window.captions.Save);
                setCardId(entityId);
                setSteppers(steppers);
                setGroups(data.groups);
                setModel({
                    sources: data.sources,
                    phones: data.phones,
                    addresses: data.addresses,
                    emails: data.emails,
                    urls: data.urls,
                    accounts: data.accounts
                });
                setSource(data.sourceText);
                setAccess(data.access);
                setActualizationId(data.actualizationId);
                setIndex(data.stepIndex);
                setIsReturnHome(data.isReturnHome);
                setQuestId(questId);
                setError(data.error);
                setPending(null);
                setCanSave(false);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }

    function setEntityFields(fields) {
        const selected = groups[index];
        selected.fields = fields;
        setGroups([...groups]);
        setCanSave(true);
    }

    function previous() {
        setIndex(index - 1);
    }

    function next(asyncErrorsList, isAuto = false, isAsync = undefined) {
        var result = validateStep(model,
            groups,
            steppers,
            index,
            isBlocked,
            isAsync == undefined ? validateAsync : isAsync,
            asyncErrorsList,
            questId,
            cardId,
            { setPending, setError, next },
            isAuto);
        setValidateAsync(result.isAsync);
        if (result.isAsync)
            return;

        if (result.errors.length > 0) {
            setSteppers(steppers);
            setMessage(result.errors);
            return;
        }

        saveStep(model, groups, index, isBlocked, canSave, steppers, cardId, actualizationId, questId, isReturnHome, successPhoneIndex.current, false,
            { setPending, setIsFinal, setIsReturnHome, setError, setIndex, setSteppers, setMessage, setMessageError, setCardId, setAccess, setGroups, setActualizationId, setCanSave, setModel, clearIndex }, null);
    }

    function clearIndex() {
        successPhoneIndex.current = null;
    }

    function refreshGrid() {
        const selected = groups[index];
        if (selected.type === questionnaireGroupType.documents) {
            gridDocuments.current.refreshDataSource();
        } else if (selected.type === questionnaireGroupType.linkedPersons) {
            gridLinked.current.refreshDataSource();
        } else if (selected.type === questionnaireGroupType.accounts) {
            gridAccounts.current.refreshDataSource();
        }
    }

    function add(isShow) {
        if (isShow) {
            setAdding(true);
            setEditing(false);
        } else {
            setAdding(false);
            setEditing(false);
            setCanSave(true);
            refreshGrid();
        }
    }

    function edit(isShow, linkCardId, linkSource, linkId) {
        if (isShow) {
            setAdding(false);
            setEditing(true);
            setLink({
                id: linkId,
                cardId: linkCardId,
                source: linkSource
            });
        } else {
            setAdding(false);
            setEditing(false);
            setCanSave(true);
            refreshGrid();
        }
    }

    function hideMessage() {
        setMessage(null);
        setMessageError(false);
    }

    function dialogConfirmVerification(isShow, phoneId, code, altCode, verified = false, stateId = 0) {
        phoneVerification(model.phones, isShow, phoneId, code, altCode, verified, stateId, setVerification, ph => setModel({ ...model, phones: ph }));
    }

    function verificationNewPhone(id) {
        successPhoneIndex.current = model.phones.findIndex(i => i.id === id);
        next(null, true);
    }
}