import React from "react";
import { useState, useEffect } from 'react';
import { ListView } from "@progress/kendo-react-listview";
import { EditListViewBooleanCheckItem } from "./editListViewItem.jsx";
import LoadingMask from "../../../../components/loadingMask.jsx";

export default function CheckerListComponent(props) {
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (props.fieldId)
            fetchData();
    }, [props.selected, props.fieldId]);

    if (!items)
        return <LoadingMask />;

    return <ListView data={items} item={getCheckerItem} />;

    function fetchData() {
        setItems(props.items);
        setSelected(props.selected);
    }

    function onChangeCheckers(e) {
        var item = e.dataItem;

        var isChecked = selected.includes(item.id);
        var newValue = [];

        if (!isChecked) {
            newValue.push(item.id);
            selected.map((element) => newValue.push(element));
        } else {
            selected.map((element) => {
                if (element !== item.id)
                    newValue.push(element);
            });
        }

        setSelected(newValue);
        props.onSaveItem(newValue);
    }

    function getCheckerItem(item) {
        var data = {
            dataItem: {
                id: item.dataItem.id,
                name: item.dataItem.name,
                value: selected.includes(item.dataItem.id)
            },
            index: item.index
        }

        return <EditListViewBooleanCheckItem
            key={`BoolListItem_${item.index}`}
            item={data}
            onSaveItem={onChangeCheckers}
            fieldId={props.fieldId} />;
    }
}