import React from "react";
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { getLocation } from "../../utils/locationHelper.jsx";
import Routing from "../../routes/route.jsx";
import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-react-layout";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { IdleTimer } from '../../components/idleTimerComponent.jsx'

import { getUiLanguages, entitySourcesNames } from "../../utils/systemConstants.jsx";
import { renderMenuItems } from "./headerRender.jsx";

import {
    stateChangeTimer,
    stateChanged,
    stateChangeFinish,
    themeChanged,
    modeChanged,
    mainMenuExpanded,
    createEntity,
    clearError,
    showUserProfile,
    showIncomingCallDialog,
    changeLanguage,
    changeState,
    executeLogout,
    showTaskToCloseDialog
} from './headerReducer.jsx';

import { Badge } from "@progress/kendo-react-indicators";
import DialogUser from "../dialogs/dialogUser.jsx";
import { Avatar } from "@progress/kendo-react-layout";
import DialogIncomingCall from "../dialogs/dialogIncomingCall.jsx";
import DialogChoseTaskToClose from "../dialogs/dialogChoseTaskToClose.jsx"
import DialogProjectStage from "../dialogs/dialogProjectStage.jsx";
import DialogCreateClient from "../dialogs/dialogCreateClient.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ErrorMask from "../../components/errorMask.jsx";

export default function Header() {
    const dispatch = useDispatch();

    const stateTimer = useRef(null);
    const idleTimerControl = useRef(null);
    const idleTimer = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    const header = useSelector(state => state.header);
    const expanded = useSelector(state => state.header.expanded);
    const isMobile = useSelector(state => state.header.isMobile);
    const user = useSelector(state => state.header.model);
    const toCreateEntity = useSelector(state => state.header.toCreateEntity);
    const toCreateEntityId = useSelector(state => state.header.toCreateEntityId);
    const unreadMessageCount = useSelector(state => state.chat.unreadMessageCount);

    useEffect(() => {
        if (location.pathname.startsWith("/card/") && expanded === true)
            dispatch(mainMenuExpanded());
    }, [location.pathname]);

    useEffect(() => {
        if (user.state.timeToLogout > 0) {
            dispatch(stateChanged());
            idleTimerControl.current = <IdleTimer
                ref={idleTimer}
                timeout={1000 * 60 * user.state.timeToLogout}
                throttle={500}
                onIdle={onIdle} />;
        } else {
            idleTimerControl.current = null;
            if (user.state.timeLimit > 0) {
                dispatch(stateChanged());
            }
        }
    }, [user.state]);

    useEffect(() => {
        if (!toCreateEntity || ((toCreateEntity === entitySourcesNames.loan || toCreateEntity === entitySourcesNames.client) &&
            toCreateEntityId === 0))
            return;

        const address = toCreateEntity === entitySourcesNames.questionnaire
            ? `/${toCreateEntity}/${toCreateEntityId}`
            : `/card/${toCreateEntity}/${toCreateEntityId}`;

        dispatch(createEntity({ entity: null, id: 0 }));
        navigate(address);
    }, [toCreateEntity, toCreateEntityId]);

    useEffect(() => {
        if (!header.stateChanged || !user.state)
            return;

        dispatch(stateChangeFinish());
        clearTimeout(stateTimer.current);

        if (user.state.timeLimit > 0)
            stateTimer.current = setInterval(stateUpdate, 1000);
    }, [header.stateChanged]);

    if (user.isBlocked) {
        clearTimeout(stateTimer.current);

        return <ErrorMask iconClass="fas fa-lock">
            <div>
                {window.captions.YouAreBlocked}<br />
                {window.captions.ToUnlockTheContactToTheChief}
            </div>
        </ErrorMask>;
    }

    const menuIcon = (item) => {
        if (item.route === "expand")
            return <img src="/img/logo.png" alt="Delta M. Crm" width="25" height="24" />;

        if (item.entity)
            return <span title={item.text} className={`dm-i dm-i-plus dm-i-fw`}></span>;

        return item.count && item.count > 0
            ? <span title={item.text} className={`dm-i dm-i-${item.icon} dm-i-fw`}>
                <Badge themeColor="info">{item.count}</Badge>
            </span>
            : <span title={item.text} className={`dm-i dm-i-${item.icon} dm-i-fw`}></span>;
    };

    const menuTemplate = expanded
        ? (p) => <DrawerItem {...p} className={(p.entity ? "dm-positive" : "")}>
            {menuIcon(p)}
            <span className="dm-item-text-root">{p.text}</span>
        </DrawerItem>
        : (p) => <DrawerItem {...p} className={(p.entity ? "dm-positive" : "")}>
            <Tooltip position="right" anchorElement="target" openDelay={0}>
                {menuIcon(p)}
            </Tooltip>
        </DrawerItem>;

    let userState = "";
    let userStateLimit = "";
    const userStates = user.states.map((item, g) => {
        var classRow = user.state.id === item.id ? "dm-menu-selected" : null;
        var stateKey = `state${g}`;
        if (user.state.id === item.id) {
            userState = item.name;
            return <MenuItem cssClass={classRow} text={item.name} key={stateKey} />;
        }

        return <MenuItem cssClass={classRow} text={item.name} key={stateKey} data={{ command: "state", id: item.id }} />;
    }
    );

    if (user.state.timeLimit > 0) {
        userStateLimit = ` ${header.stateLimit}`;
    }

    let dialog = null;
    if (header.error) {
        dialog = <ValidationMessage close={() => dispatch(clearError())}
            text={header.error}
            error={true} />;
    }

    if (header.isShowUserDialog) {
        dialog = <DialogUser userId={user.id}
            key="dialogUserProfile"
            onClose={() => dispatch(showUserProfile(false))} />;
    }

    if (header.isShowIncomingDialog) {
        dialog = <DialogIncomingCall callId={header.callId}
            onClose={() => dispatch(showIncomingCallDialog({ isShow: false, callId: 0 }))} />;
    }
    if (header.isShowTaskToCloseDialog) {
        dialog = <DialogChoseTaskToClose model={header.isShowTaskToCloseDialog}
            onClose={() => dispatch(showTaskToCloseDialog(false))} />;
    }
    if (toCreateEntity === entitySourcesNames.client && toCreateEntityId === 0) {
        dialog = <DialogCreateClient clientId={0} onClose={(id) => dispatch(createEntity({
            entity: id === 0 ? null : entitySourcesNames.client,
            id: id
        }))} />;
    }
    if (toCreateEntity === entitySourcesNames.loan && toCreateEntityId === 0) {
        dialog = <DialogProjectStage clientId={0} onClose={(id) => dispatch(createEntity({
            entity: id === 0 ? null : entitySourcesNames.loan,
            id: id
        }))} />;
    }

    const menuRenderAvatar = () => userAvatar;
    const menuRenderTheme = () => <React.Fragment><i className={`fas fa-${(header.isDark ? "sun" : "moon")}`} /></React.Fragment>;
    const menuRenderRole = () => <React.Fragment>{user.groupName} <span className="badge bg-secondary"> {user.roleName}</span ></React.Fragment>;
    const menuRenderLanguage = (p) => <React.Fragment><img src={`/img/flag/${p.item.data.icon}.png`} alt={p.item.text} /> {p.item.text}</React.Fragment>;
    const menuRenderIcon = (p) => <React.Fragment><i className={`dm-i dm-i-${p.item.data.icon}`} /> {p.item.text}</React.Fragment>;

    const uiLanguages = getUiLanguages();
    const languages = uiLanguages.map((item, g) => {
        var classRow = header.lang === item.link ? "dm-menu-selected" : null;
        return <MenuItem cssClass={classRow} text={item.name} key={`lang${g}`} data={{ command: "lang", link: item.link, icon: item.icon }} render={menuRenderLanguage} />;
    });

    const changeMode = user.access.isAdmin
        ? <MenuItem data={{ command: "admin", icon: "settings" }} text={(header.isAdmin
            ? window.captions.UserMode
            : window.captions.AdministratorMode)} render={menuRenderIcon} />
        : null;

    const items = renderMenuItems(user, unreadMessageCount);
    const current = location.pathname;
    const selectedItems = items.map(
        (item) => ({
            ...item,
            selected: current && (current.startsWith(item.route) || (item.path && current.startsWith(item.path)))
        }));

    const userAvatar = user.avatar
        ? <Avatar type="image">
            <img alt={user.name} src={user.avatar} />
        </Avatar>
        : <Avatar type="text">
            <span title={user.name}>{user.initials}</span>
        </Avatar>;

    let toolbarButton = null;
    let drawerClass = "dm-desktop";
    if (header.isMobile) {
        drawerClass = "dm-mobile";
        toolbarButton = <Button icon="menu" fillMode="flat" onClick={() => dispatch(mainMenuExpanded())} />;
    }

    return <Drawer expanded={header.expanded}
        items={selectedItems}
        className={drawerClass}
        position="start"
        mode={header.isMobile ? "overlay" : "push"}
        mini={!header.isMobile}
        width={220}
        item={menuTemplate}
        onOverlayClick={() => dispatch(mainMenuExpanded())}
        onSelect={onSelect}>
        <DrawerContent className={`k-drawer-content-${header.expanded ? "expanded" : "simple"}`}>
            <div className="dm-toolbar d-print-none">
                {toolbarButton}
                <div className="dm-toolbar-controls">
                    <Menu onSelect={onStateSelect} openOnClick={true} hoverCloseDelay={500}>
                        <MenuItem render={menuRenderTheme} data={{ command: "theme" }} />
                        <MenuItem text={`${userState}${userStateLimit}`}>
                            {userStates}
                        </MenuItem>
                        <MenuItem render={menuRenderAvatar}>
                            <MenuItem text={user.name} />
                            <MenuItem render={menuRenderRole} />
                            <MenuItem cssClass="dm-menu-divider" />
                            {languages}
                            <MenuItem cssClass="dm-menu-divider" />
                            {changeMode}
                            <MenuItem data={{ command: "profile", icon: "check-double" }} text={window.captions.UserProfile} render={menuRenderIcon} />
                            <MenuItem data={{ command: "exit", icon: "sign-out-alt" }} text={window.captions.WordExit} render={menuRenderIcon} />
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <Routing />
            {dialog}
            {idleTimerControl.current}
        </DrawerContent>
    </Drawer>;

    function stateUpdate() {
        dispatch(stateChangeTimer());
    }

    function onSelect(e) {
        var selected = e.itemTarget.props;
        if (selected.route === "expand") {
            dispatch(mainMenuExpanded());
            return;
        }

        if (isMobile)
            dispatch(mainMenuExpanded());

        if (selected.entity) {
            dispatch(createEntity({ entity: selected.entity, id: selected.id ?? 0 }));
            return;
        }

        navigate(selected.route);
    }

    function onStateSelect(e) {
        var item = e.item.data;
        if (!item)
            return;

        switch (item.command) {
            case "profile":
                dispatch(showUserProfile(true));
                break;
            case "exit":
                dispatch(executeLogout(false));
                break;
            case "state":
                if (window.systemConfig.userLogonsGeolocation) {
                    getLocation(coords => dispatch(changeState(item.id, coords)), () => dispatch(changeState(item.id, null)));
                } else {
                    dispatch(changeState(item.id, null));
                }
                break;
            case "lang":
                dispatch(changeLanguage(item.link));
                break;
            case "theme":
                dispatch(themeChanged());
                break;
            case "admin":
                dispatch(modeChanged());
                break;
        }
    }

    function onIdle() {
        console.log("user is idle");
        console.log("last active", idleTimer.current.getLastActiveTime());
        clearTimeout(stateTimer.current);
        idleTimerControl.current = null;
        dispatch(executeLogout(true));
    }
}