import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { getFormatDate, dateWidth } from "../../../utils/systemConstants.jsx";

export default function PassportControl({ value, required, field, passportTypes, changedValue }) {
    let type = 1;
    let serial = "";
    let number = "";
    let place = "";
    let date = null;
    let endDate = null;
    if (value) {
        var values = value.split("&PP;");
        [type, serial, number, date, place, endDate] = values;

        type = parseInt(type);
        if (isNaN(type) || type === 0)
            type = 1;

        if (date && date.length === 10) {
            const dValues = date.split(".");
            if (dValues.length === 3)
                date = new Date(dValues[2], parseInt(dValues[1]) - 1, dValues[0]);
            else
                date = null;
        } else {
            date = null;
        }
        if (endDate && endDate.length === 10) {
            const dValues = endDate.split(".");
            if (dValues.length === 3)
                endDate = new Date(dValues[2], parseInt(dValues[1]) - 1, dValues[0]);
            else
                endDate = null;
        } else {
            endDate = null;
        }
    }

    var restrictions = type === 2
        ? window.systemConfig.passportCardRestriction
        : window.systemConfig.passportBookRestriction;

    var icon = restrictions.Icon;
    if (icon === 0)
        icon = type;

    const selectedType = passportTypes.find((e) => e.id === type);
    const selectedPassportName = selectedType && type > 0 ? selectedType.name : "";
    var dateTo = null;
    var placeColumns = 50;
    if (type === 2) {
        let requiredDateEnd = false;
        if (required || restrictions.ValidateDateEnd)
            requiredDateEnd = true;

        dateTo = <DatePicker
            width={dateWidth}
            formatPlaceholder={getFormatDate()}
            required={requiredDateEnd}
            placeholder={window.captions.PassportEndDate}
            onChange={e => changedValue({ isPassport: true, fieldId: field, property: "endDate", value: e.value })}
            defaultValue={endDate} />;
        placeColumns = 100;
    }

    var dropIcon = icon === 2
        ? "id-card"
        : "passport";

    var numberProps = {};
    var serialProps = {};
    var dateProps = {};

    if (restrictions.NumberMax > 0) {
        numberProps.maxLength = restrictions.NumberMax;
        numberProps.valid = number != null &&
            number.length >= restrictions.NumberMin &&
            number.length <= restrictions.NumberMax;
    } else if (required || restrictions.ValidateNumber)
        numberProps.valid = number && number.length > 0;

    if (numberProps.valid && restrictions.NumberPattern && number) {
        numberProps.valid = new RegExp(restrictions.NumberPattern).test(number);
    }

    if (restrictions.SerialMax > 0) {
        serialProps.maxLength = restrictions.SerialMax;
        serialProps.valid = serial != null &&
            serial.length >= restrictions.SerialMin &&
            serial.length <= restrictions.SerialMax;
    } else if (required || restrictions.ValidateSerial)
        serialProps.valid = serial != null && serial.length > 0;

    if (serialProps.valid && restrictions.SerialPattern && serial != null) {
        serialProps.valid = new RegExp(restrictions.SerialPattern).test(serial);
    }

    if (restrictions.DateMin)
        dateProps.min = new Date(restrictions.DateMin);

    if (restrictions.DateMax) {
        dateProps.max = new Date(restrictions.DateMax);
    } else {
        dateProps.max = new Date();
    }

    dateProps.valid = true;
    if (required || date || restrictions.ValidateDateGiven)
        dateProps.valid = date && date <= dateProps.max && (!dateProps.min || date >= dateProps.min);

    var serialControls;
    if (type === 2 && window.systemConfig.showPassportIdCardSerial === false || type === 1 && window.systemConfig.showPassportBookSerial === false) {
        serialControls = <Input className="dm-wrapper-content"
            {...numberProps}
            placeholder={window.captions.PassportNumber}
            onChange={(e) => changedValue({ isPassport: true, fieldId: field, property: "number", value: e.value })}
            defaultValue={number} />;

    } else {
        serialControls = <div className="dm-wrapper-content">
            <Input
                className="dm-size-33"
                {...serialProps}
                required={required && type === 1}
                placeholder={window.captions.PassportSerion}
                onChange={(e) => changedValue({ isPassport: true, fieldId: field, property: "serial", value: e.value })}
                defaultValue={serial} />
            <Input
                className="dm-size-66"
                {...numberProps}
                placeholder={window.captions.PassportNumber}
                onChange={(e) => changedValue({ isPassport: true, fieldId: field, property: "number", value: e.value })}
                defaultValue={number} />
        </div>;
    }

    let requiredPlace = false;
    if (required || restrictions.ValidateIssuedBy)
        requiredPlace = true;

    return <div>
        <div className="dm-wrapper">
            <DropDownButton
                field={field}
                property="type"
                onItemClick={(e) => changedValue({
                    isPassport: true, 
                    fieldId: field,
                    property: "type",
                    value: e.item.id, //e.value === undefined ? e.item.id : e.target.value.id,
                    valueName: e.item.name //e.item === undefined ? e.target.value.name : e.item.name
                })}
                title={selectedPassportName}
                iconClass={`fas fa-${dropIcon} fa-fw`}
                items={passportTypes ?? []}
                textField="name" />
            {serialControls}
        </div>
        <DatePicker
            width={dateWidth}
            formatPlaceholder={getFormatDate()}
            {...dateProps}
            placeholder={window.captions.PassportDate}
            onChange={(e) => changedValue({ isPassport: true, fieldId: field, property: "date", value: e.value })}
            defaultValue={date} />
        {dateTo}
        <Input
            className={`dm-size-${placeColumns}`}
            required={requiredPlace}
            placeholder={window.captions.PassportWho}
            onChange={(e) => changedValue({ isPassport: true, fieldId: field, property: "place", value: e.value })}
            defaultValue={place} />
    </div>;
}