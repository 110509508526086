import React, { useState, useRef } from 'react';

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import GridTextCell from "../../../components/cells/gridTextCell.jsx";
import PasswordBaseFieldControl from "./baseFieldPasswordControl.jsx";
import EgripFieldControl from "./baseFieldEgripControl.jsx";
import AddressBaseFieldControl from "./baseFieldAddressControl.jsx";

export default function BaseFieldsControl({ data, columns }) {
    const gridControl = useRef(null);

    const [selectedTab, setSelectedTab] = useState(null);

    const sports = [
        { text: "Basketball", id: 1 },
        { text: "Football", id: 2 },
        { text: "Tennis", id: 3 },
        { text: "Volleyball", id: 4 },
    ];

    const sports1 = ["id", "test"];

    const actionsCell = (p) => <td {...getLinkCellStyle(p)}>
        <Button />
    </td>;

    const labelCell = (p) => <td >
        <Label style={{ margin: 5, padding: 10 }}>{p.dataItem["name"]}</Label>
    </td>;

    const dropDownCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <DropDownList id="dropdown-basic-buttonfielg"
                style={{ margin: 5, width: 500 }}
                title="Dropdown button"
                textField="name" />

        </td>;


    const dropCheckbox =
        (p) => <td {...getLinkCellStyle(p)}>
            <Checkbox onChange={handleChange}
                style={{ margin: 5 }} />

        </td>;

    const handleChange = (event) => {
        value: "1";
    };

    const testCell = (p) => {
        var content;
        if (p.dataItem["id"] === 5607) {
            content = <PasswordBaseFieldControl columns={columns} />
        }
        if (p.dataItem["id"] === 5597) {
            content = <AddressBaseFieldControl columns={columns} />
        }
        if (p.dataItem["id"] === 5600) {
            content = <EgripFieldControl columns={columns} />
        }
        else {
            content = <DropDownList id="dropdownSetDataUpdateDate"
                data={columns}
                style={{ margin: 5, width: 800 }}
                defaultValue="пусто"
                onChange={handleChange} />;

        }

        return <td {...getLinkCellStyle(p)}>
            {content}
        </td>;
    };

    return <div className="dm-full-height" >
        <Grid ref={gridControl}
            className="dm-full-height"
            data={data}>
            <GridColumn width="30" cells={{ data: dropCheckbox }} />
            <GridColumn onSelected={handleSelect} width="270" cells={{ data: labelCell }} />
            <GridColumn onSelected={handleSelectCheckBox} width="30" cells={{ data: dropCheckbox }} />
            <GridColumn width="800" cells={{ data: testCell }} />
            <GridColumn width="200" cells={{ data: GridTextCell }} />
            <GridColumn width="35" cells={{ data: actionsCell }} />
        </Grid>
    </div >;

    function handleSelect(e) {
        setSelectedTab(e.selected);
    }

    function handleSelectCheckBox(e) {
        setSelectedTab(e.selected);
    }
}