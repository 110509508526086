import { exportMonitorState, exportTypes } from "../../utils/systemConstants.jsx";

function iconClassName(item) {
    if (item.type === exportTypes.folder)
        return "k-icon k-font-icon k-i-folder";

    if (item.type === exportTypes.document || item.type === exportTypes.documentClient || item.type === exportTypes.documentGroup)
        return item.documentFormat === 1 ? "k-icon k-font-icon k-i-file-pdf" : "k-icon k-font-icon k-i-file-word";

    return "k-icon k-font-icon k-i-file-excel";
}

function fileTypeClassName(item) {
    if (item.type === exportTypes.document || item.type === exportTypes.documentClient || item.type === exportTypes.documentGroup)
        return item.documentFormat === 1 ? "pdf" : "docx";

    return "xlsx";
}

function getMonitorName(type) {
    switch (type) {
    case exportMonitorState.errorTemplateNotFound:
        return window.captions.ReportErrorTemplateNotFound;
    case exportMonitorState.loadData:
        return window.captions.ReportLoadData;
    case exportMonitorState.prepareVariables:
        return window.captions.ReportPrepareVariables;
    case exportMonitorState.readData:
        return window.captions.ReportReadData;
    case exportMonitorState.receiveData:
        return window.captions.ReportReceavData;
    case exportMonitorState.errorUnknown:
        return window.captions.ReportErrorUnknown;
    case exportMonitorState.loadingDataToFile:
        return window.captions.LoadingDataToFile;
    case exportMonitorState.errorNoData:
        return window.captions.ReportNoData;
    default:
        return "";
    }

}

export {
    iconClassName,
    fileTypeClassName,
    getMonitorName
    };