import React from "react";

export default function GridImageCell({ dataItem, field, tdProps, style, className }) {
    const value = dataItem[field];
    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;

    if (!value || typeof value !== "string")
        return <td {...props} />;

    return <td {...props}>
        <img src={value} alt="Cell image" />
    </td>;
}