import React from "react";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import MultilineTextArea from "../../../../../components/controls/multilineTextArea.jsx";
import { Button } from "@progress/kendo-react-buttons";

export default function PersonalDataTab({ dataUser, userChanged }) {
    const elements = [];

    renderPair(elements, window.captions.Photo, "photo", () => (
        <div id="userPhoto">
            {!dataUser.photo ? (
                <Button
                    key="AddUserPhoto"
                    onClick={addUserPhoto}
                    title={window.captions.GoToRoleSettings}
                />
            ) : (
                <React.Fragment>
                    <Button
                        key="ViewUserPhoto"
                        icon="link"
                        onClick={viewUserPhoto}
                        title={window.captions.GoToRoleSettings}
                    />
                    <Button
                        key="DeleteUserPhoto"
                        icon="delete"
                        onClick={deleteUserPhoto}
                        title={window.captions.GoToRoleSettings}
                    />
                </React.Fragment>
            )}
        </div>
    ));

    renderPair(elements, window.captions.WorkAddress, "workAddress", () => (
        <MultilineTextArea
            id="workAddressUser"
            changeText={workAddressChanged}
            text={dataUser.workAddress}
        />
    ));

    renderPair(elements, window.captions.City, "city", () => (
        <MultilineTextArea
            id="cityUser"
            changeText={cityChanged}
            text={dataUser.city}
        />
    ));

    renderPair(elements, window.captions.PhoneMobile, "phoneMobile", () => (
        <MultilineTextArea
            id="phoneMobileUser"
            changeText={phoneMobileChanged}
            text={dataUser.phoneMobile}
        />
    ));

    renderPair(elements, window.captions.PhoneWork, "phoneWork", () => (
        <MultilineTextArea
            id="phoneWorkUser"
            changeText={phoneWorkChanged}
            text={dataUser.phoneWork}
        />
    ));

    renderPair(elements, window.captions.Phoneinternal, "phoneInternal", () => (
        <MultilineTextArea
            id="phoneInternalUser"
            changeText={phoneInternalChanged}
            text={dataUser.phoneInternal}
        />
    ));

    renderPair(elements, window.captions.Comments, "comments", () => (
        <MultilineTextArea
            id="commentsUser"
            changeText={commentsChanged}
            text={dataUser.comments}
        />
    ));

    return <div className="dm-container dm-no-padding">{elements}</div>;

    function addUserPhoto(e) {
        // if (typeof props.addNewUser === "function")
        //   props.addNewUser(dataGroup);
    };

    function viewUserPhoto(e) {
        // if (typeof props.addNewUser === "function")
        //   props.addNewUser(dataGroup);
    };

    function deleteUserPhoto(e) {
        // if (typeof props.addNewUser === "function")
        //   props.addNewUser(dataGroup);
    };

    function workAddressChanged(e) {
        // commentsChanged
    };

    function cityChanged(e) {
        // commentsChanged
    };

    function phoneMobileChanged(e) {
        // commentsChanged
    };

    function phoneWorkChanged(e) {
        // commentsChanged
    };

    function phoneInternalChanged(e) {
        // commentsChanged
    };

    function commentsChanged(e) {
        if (userChanged)
            userChanged({ ...dataUser, comments: e.value });
    };
}