import { fieldTypes, dateFormatFixed, isFieldDictionary } from "../../utils/systemConstants.jsx";
import { formatDate } from "@progress/kendo-intl";

export default {
    getContactElements(field, phones, addresses, emails, urls, accounts) {
        switch (field.type) {
            case fieldTypes.phone:
                return phones;
            case fieldTypes.address:
                return addresses;
            case fieldTypes.email:
                return emails;
            case fieldTypes.url:
                return urls;
            case fieldTypes.account:
                return accounts;
        }
        return null;
    },

    contactAdded(fields, sources, phones, addresses, emails, urls, accounts, fieldId, typeItem) {
        const field = fields[fieldId];
        if (!field)
            return false;

        const items = this.getContactElements(field, phones, addresses, emails, urls, accounts);
        if (!items)
            return false;

        let id = Math.min(...items.map(user => user.id));
        id = id > 0 ? -1 : id - 1;

        switch (field.type) {
            case fieldTypes.phone:
                phones.push({
                    phoneNumber: "",
                    type: typeItem ? typeItem.id : sources.phoneTypes[0].id,
                    typeText: typeItem ? typeItem.name : sources.phoneTypes[0].name,
                    isUpdated: true,
                    comment: "",
                    state: 0,
                    owner: 0,
                    id: id
                });
                return true;
            case fieldTypes.address:
                addresses.push({
                    country: "",
                    postCode: "",
                    region: "",
                    district: "",
                    typeCity: "",
                    city: "",
                    typeStreet: "",
                    street: "",
                    typeHouse: "",
                    house: "",
                    corpus: "",
                    flat: "",
                    type: typeItem ? typeItem.id : sources.addressTypes[0].id,
                    typeText: typeItem ? typeItem.name : sources.addressTypes[0].name,
                    isUpdated: true,
                    comment: "",
                    state: 0,
                    owner: 0,
                    id: id
                });
                return true;
            case fieldTypes.email:
                emails.push({
                    email: "",
                    type: typeItem ? typeItem.id : sources.emailTypes[0].id,
                    typeText: typeItem ? typeItem.name : sources.emailTypes[0].name,
                    isUpdated: true,
                    comment: "",
                    state: 0,
                    owner: 0,
                    id: id
                });
                return true;
            case fieldTypes.url:
                urls.push({
                    url: "",
                    type: typeItem ? typeItem.id : sources.urlTypes[0].id,
                    typeText: typeItem ? typeItem.name : sources.urlTypes[0].name,
                    isUpdated: true,
                    comment: "",
                    state: 0,
                    owner: 0,
                    id: id
                });
                return true;
            case fieldTypes.account:
                var state = sources.accountState;
                var existItem = accounts.find((e) => e.state === state);
                if (existItem) {
                    var stateOther = sources.accountStates.find((e) => e.id !== state && e.isPositive);
                    state = stateOther ? stateOther.id : 0;
                }

                accounts.push({
                    number: "",
                    type: typeItem ? typeItem.id : sources.accountTypes[0].id,
                    typeText: typeItem ? typeItem.name : sources.accountTypes[0].name,
                    isUpdated: true,
                    state: state,
                    isMain: state === sources.accountState,
                    currency: sources.currency,
                    id: id
                });
                return true;
        }
        return false;
    },

    contactFieldEdited(field, sources, payload) {
        if (!field)
            return null;

        let oldValue = field.value;
        if (!oldValue)
            oldValue = "&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;";

        const value = payload.value;
        const property = payload.property;
        let comment;
        let state;
        let owner;
        switch (field.type) {
            case fieldTypes.phone:
                {
                    let phoneNumber;
                    [phoneNumber, comment, state, owner] = oldValue.split("&PP;");
                    switch (property) {
                        case "phoneNumber":
                            phoneNumber = value;
                            break;
                        case "comment":
                            comment = value;
                            break;
                        case "state":
                            state = value;
                            break;
                        case "owner":
                            owner = value;
                            break;
                    }

                    field.value = phoneNumber + "&PP;" + comment + "&PP;" + state + "&PP;" + owner;
                    field.isUpdated = true;
                    this.contactPhoneValid(field, sources, phoneNumber);
                    return field;
                }
            case fieldTypes.address:
                {
                    let country, postCode, region, district, typeCity, city, typeStreet, street, typeHouse, house, corpus, flat;
                    [country, postCode, region, district, typeCity, city, typeStreet, street, typeHouse, house, flat, state, owner, corpus, comment] = oldValue.split("&PP;");
                    switch (property) {
                        case "country":
                            country = value;
                            break;
                        case "postCode":
                            postCode = value;
                            break;
                        case "region":
                            region = value;
                            break;
                        case "district":
                            district = value;
                            break;
                        case "typeCity":
                            typeCity = value;
                            break;
                        case "city":
                            city = value;
                            break;
                        case "typeStreet":
                            typeStreet = value;
                            break;
                        case "street":
                            street = value;
                            break;
                        case "typeHouse":
                            typeHouse = value;
                            break;
                        case "house":
                            house = value;
                            break;
                        case "corpus":
                            corpus = value;
                            break;
                        case "flat":
                            flat = value;
                            break;
                        case "comment":
                            comment = value;
                            break;
                        case "state":
                            state = value;
                            break;
                        case "owner":
                            owner = value;
                            break;
                        default:
                            field.isUpdated = true;
                            return field;
                    }

                    field.value = country + "&PP;" + postCode + "&PP;" + region + "&PP;" + district + "&PP;" +
                        typeCity + "&PP;" + city + "&PP;" + typeStreet + "&PP;" + street + "&PP;" +
                        typeHouse + "&PP;" + house + "&PP;" + flat + "&PP;" + state + "&PP;" +
                        owner + "&PP;" + corpus + "&PP;" + comment;
                    field.isUpdated = true;
                    return field;
                }
            case fieldTypes.email:
                {
                    let email;
                    [email, comment, state, owner] = oldValue.split("&PP;");
                    switch (property) {
                        case "email":
                            email = value;
                            break;
                        case "comment":
                            comment = value;
                            break;
                        case "state":
                            state = value;
                            break;
                        case "owner":
                            owner = value;
                            break;
                    }

                    field.value = email + "&PP;" + comment + "&PP;" + state + "&PP;" + owner;
                    field.isUpdated = true;
                    return field;
                }
            case fieldTypes.url:
                {
                    let url;
                    [url, comment, state, owner] = oldValue.split("&PP;");
                    switch (property) {
                        case "url":
                            url = value;
                            break;
                        case "comment":
                            comment = value;
                            break;
                        case "state":
                            state = value;
                            break;
                        case "owner":
                            owner = value;
                            break;
                    }

                    field.value = url + "&PP;" + comment + "&PP;" + state + "&PP;" + owner;
                    field.isUpdated = true;
                    return field;
                }
        }

        return null;
    },

    contactFieldClear(field, item) {
        if (!field || !item)
            return null;

        const value = "";
        switch (field.type) {
            case fieldTypes.phone:
                {
                    item["phoneNumber"] = value;
                    item["comment"] = value;
                    item["state"] = 0;
                    item["owner"] = 0;

                    field.value = "&PP;&PP;&PP;";
                    field.isUpdated = true;
                    return field;
                }
            case fieldTypes.address:
                {
                    item["country"] = value;
                    item["postCode"] = value;
                    item["region"] = value;
                    item["district"] = value;
                    item["typeCity"] = value;
                    item["city"] = value;
                    item["typeStreet"] = value;
                    item["street"] = value;
                    item["typeHouse"] = value;
                    item["house"] = value;
                    item["corpus"] = value;
                    item["flat"] = value;
                    item["comment"] = value;
                    item["state"] = 0;
                    item["owner"] = 0;

                    field.value = "&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;&PP;";
                    field.isUpdated = true;
                    return field;
                }
            case fieldTypes.email:
                {
                    item["email"] = value;
                    item["comment"] = value;
                    item["state"] = 0;
                    item["owner"] = 0;

                    field.value = "&PP;&PP;&PP;";
                    field.isUpdated = true;
                    return field;
                }
            case fieldTypes.url:
                {
                    item["url"] = value;
                    item["comment"] = value;
                    item["state"] = 0;
                    item["owner"] = 0;

                    field.value = "&PP;&PP;&PP;";
                    field.isUpdated = true;
                    return field;
                }
            case fieldTypes.account:
                {
                    item["eNumber"] = value;
                    item["state"] = 0;
                    item["owner"] = 0;

                    field.isUpdated = true;
                    return field;
                }
        }

        return null;
    },

    getContactStateDefault(sources, field) {
        switch (field.type) {
            case fieldTypes.phone:
                return sources.phoneState;
            case fieldTypes.address:
                return sources.addressState;
            case fieldTypes.email:
                return sources.emailState;
            case fieldTypes.url:
                return sources.urlState;
            case fieldTypes.account:
                return sources.accountState;
        }
        return 0;
    },

    getContactStateReplace(sources, field, defaultId) {
        if (field.type === fieldTypes.account) {
            return sources.accountStates.find(s => s.id !== defaultId);
        } else {
            return { id: 0, name: "" };
        }
    },

    contactEdited(isUpdated, sources, fields, phones, addresses, emails, urls, accounts, payload) {
        const updateImmediate = payload.property === "phoneNumber" ||
            payload.property === "eNumber" ||
            payload.property === "type" ||
            payload.property === "state" ||
            payload.property === "currency" ||
            payload.property === "owner";
        const field = fields[payload.fieldId];
        if (!field)
            return false;

        if (payload.item === 0) {
            const editField = this.contactFieldEdited(field, sources, payload);
            if (!editField)
                return false;

            fields[payload.fieldId] = editField;
            return !isUpdated || updateImmediate;
        }

        const items = this.getContactElements(field, phones, addresses, emails, urls, accounts);
        if (!items)
            return false;

        const item = items.find((i) => i.id === payload.item);
        if (!item)
            return false;

        if (payload.isClear) {
            this.contactFieldClear(field, item, payload);
            item.isUpdated = true;
            item.isDeleted = true;
            return true;
        }

        const value = payload.value;
        const property = payload.property;
        if (item[property] === value)
            return false;

        if (property === "state") {
            const stateInt = parseInt(value);
            const stateDefault = this.getContactStateDefault(sources, field);
            if (stateInt > 0) {
                if (stateInt === stateDefault) {
                    item.isMain = true;
                    const stateReplace = this.getContactStateReplace(sources, field, stateInt);
                    items.forEach(i => {
                        if (i.state === stateInt && i !== item) {
                            i.state = stateReplace.id;
                            i.stateText = stateReplace.name;
                            i.isMain = false;
                            i.isUpdated = true;
                        }
                    });
                } else {
                    item.isMain = false;
                }
            }
        }

        if (property === "phoneNumber")
            this.contactPhoneValid(item, sources, value);

        item[property] = value;
        if (payload.valueName)
            item[property + "Text"] = payload.valueName;
        item.isUpdated = true;
        return !isUpdated || updateImmediate;
    },

    contactPhoneValid(field, sources, phoneNumber) {
        field.incorrectValue = false;
        if (!field || !sources || !sources.phoneCodes || !phoneNumber)
            return;

        const code = sources.phoneCodes.find(i => phoneNumber.startsWith(i.codeString));
        if (code) {
            const phoneWithoutCode = phoneNumber.slice(code.codeString.length);
            field.incorrectValue = !(phoneWithoutCode.length >= code.digitsCountMin && phoneWithoutCode.length <= code.digitsCountMax) ||
                !this.phoneValidCodeOperator(code, phoneWithoutCode);
        }
    },

    phoneValidCodeOperator(phoneCode, phoneWithoutCode) {
        if (!phoneCode.operatorsCodes || phoneCode.operatorsCodes.length <= 0)
            return true;

        let existCode = false;
        const filteredOperators = phoneCode.operatorsCodes.filter(i => i.codes && i.codes.length > 0)
            .map(i => i.codes.map(c => c.split("-")));
        if (filteredOperators && filteredOperators.length > 0) {
            for (let codes of filteredOperators) {
                if (!codes)
                    continue;
                for (let code of codes) {
                    switch (code.length) {
                        case 1:
                            {
                                existCode = phoneWithoutCode.startsWith(code[0]);
                                if (existCode)
                                    return existCode;
                                break;
                            }
                        case 2:
                            {
                                const diff = new Number(code[1]) - new Number(code[0]);
                                if (diff > 0) {
                                    for (let i = 0; i <= diff; i++) {
                                        const c = new Number(code[0]) + i;
                                        existCode = phoneWithoutCode.startsWith(c.toString());
                                        if (existCode)
                                            return existCode;
                                    }
                                }
                                break;
                            }
                    }
                }
            }
        }
        return existCode;
    },

    passportEdited(fields, payload) {
        const fieldId = payload.fieldId;
        const editField = fields[fieldId];

        const fieldEdited = this.passportFieldEdited(editField, payload);
        if (!fieldEdited)
            return false;

        fields[fieldId] = fieldEdited;
        return true;
    },

    passportFieldEdited(field, payload, isAdminSetting = false) {
        if (!field)
            return null;

        let oldValue = isAdminSetting ? field : field.value;
        if (!oldValue)
            oldValue = "1&PP;&PP;&PP;&PP;&PP;";

        const value = payload.value;
        const property = payload.property;
        let type, serial, number, date, place, endDate;

        [type, serial, number, date, place, endDate] = oldValue.split("&PP;");
        type = parseInt(type);
        if (isNaN(type))
            type = 1;

        switch (property) {
            case "type":
                type = value;
                break;
            case "serial":
                serial = value;
                break;
            case "number":
                number = value;
                break;
            case "date":
                date = value ? formatDate(new Date(value), dateFormatFixed) : "";
                break;
            case "place":
                place = value;
                break;
            case "endDate":
                endDate = value ? formatDate(new Date(value), dateFormatFixed) : "";
                break;
        }

        const newValue = type + "&PP;" + serial + "&PP;" + number + "&PP;" + date + "&PP;" + place + "&PP;" + endDate;

        if (isAdminSetting) {
            field = newValue;
        }
        else {
            field.value = newValue;
            field.isUpdated = field.oValue !== field.value;
        }

        return field;
    },

    edrvpFieldEdited(field, payload) {
        if (!field)
            return null;

        let oldValue = field.value;
        if (!oldValue)
            oldValue = "&PP;";

        const value = payload.value;
        const property = payload.property;
        let serial, number;
        [serial, number] = oldValue.split("&PP;");
        switch (property) {
            case "serial":
                serial = value;
                break;
            case "number":
                number = value;
                break;
        }

        field.value = serial + "&PP;" + number;
        field.isUpdated = field.oValue !== field.value;
        return field;
    },

    fieldEdited(fields, payload) {
        const fieldId = payload.fieldId;
        const editField = fields[fieldId];

        if (editField.type === fieldTypes.edrvp) {
            const fieldEdited = this.edrvpFieldEdited(editField, payload);
            if (!fieldEdited)
                return false;

            fields[fieldId] = fieldEdited;
            return true;
        }

        let value = payload.value;
        if (editField.type === fieldTypes.time && value) {
            value = value.toLocaleTimeString("ru-RU");
        } else if ((editField.type === fieldTypes.date || editField.type === fieldTypes.dateTime) &&
            value &&
            value instanceof Date) {
            value = new Date(value.getTime() - (value.getTimezoneOffset() * 60 * 1000)).toISOString().substr(0, 19);
        } else if (editField.type === fieldTypes.boolean) {
            if (value === 1)
                value = true;
            else if (value === 0)
                value = false;
            else
                value = null;
        } else if (isFieldDictionary(editField.type) && value === 0)
            value = null;

        if (editField.value === value)
            return false;

        editField.value = value;
        editField.isUpdated = editField.oValue !== editField.value;
        return true;
    },

    fieldSystemEdited(state, payload) {
        const fieldId = payload.fieldId;
        const value = payload.value;
        const valueText = payload.valueText;
        const editModel = state.model;
        if (editModel[fieldId] === value)
            return;

        editModel[fieldId] = value;
        editModel[fieldId.substr(0, fieldId.length - 2) + "Name"] = valueText;
        state.isUpdated = true;
    },

    addPhoneIfIncomingNewCard(openParameters, model) {
        const phones = model.phones ? model.phones : [];
        if (openParameters.phone && model && model.fields) {
            const phoneType = model.sources.phoneTypes[0];
            const phoneField = Object.values(model.fields)
                .find(i => i.type === fieldTypes.phone && i.isContact && i.isEditable);
            if (phoneField && phones.find(ph => ph.phoneNumber === openParameters.phone.phoneNumber) == null) {
                let id = model.phones ? Math.min(...model.phones.map(ph => ph.id)) : 0;
                id = id > 0 ? -1 : id - 1;
                phones.push({
                    phoneNumber: openParameters.phone.phoneNumber,
                    type: phoneType.id,
                    typeText: phoneType.name,
                    isUpdated: true,
                    comment: "",
                    state: 0,
                    owner: 0,
                    id: id
                });
            }
        }
        return phones;
    }
}