import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import { fetchGet, fetchPost } from '../../utils/requestsHelper.jsx';
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Chat as ChatKendo } from "@progress/kendo-react-conversational-ui";
import { Button, Toolbar, ToolbarItem, ToolbarSeparator } from "@progress/kendo-react-buttons";
import { Upload } from "@progress/kendo-react-upload";
import { headersFile } from "../../utils/requestsHelper.jsx";
import { Popup } from "@progress/kendo-react-popup";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Badge } from "@progress/kendo-react-indicators";
import { formatDate } from "@progress/kendo-intl";
import DialogChat from "../dialogs/dialogChat.jsx";
import DialogChatPost from "../dialogs/dialogChatPost.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";
import { clearMessage, updateUnreadMessageCount } from "./chatReducer.jsx";
import chatFunctions from "./chatFunctions.jsx";
import { parseDateTimeFormat, chatTemplateTypes } from "../../utils/systemConstants.jsx";
import FileHelper from "../../utils/fileHelper.jsx";
import "../../styles/scss/chatStyle.scss";

export default function Chat({ canChooseChat, cardChat }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const menuMessage = useRef(null);
    const offset = useRef(null);

    const isToolbarVisible = canChooseChat === false ? false : true;

    const [chats, setChats] = useState([]);
    const [messages, setMessages] = useState([]);
    const [chatAttachments, setChatAttachments] = useState([]);
    const [quotedMessage, setQuotedMessage] = useState(null);
    const [search, setSearch] = useState("");
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showPostDialog, setShowPostDialog] = useState(false);
    const [showChatDialog, setShowChatDialog] = useState(false);
    const [isAddPost, setIsAddPost] = useState(true);
    const [isAttachmentsSelected, setIsAttachmentsSelected] = useState(false);
    const [isEditPost, setIsEditPost] = useState(false);
    const [isAddComment, setIsAddComment] = useState(false);
    const [isAddChat, setIsAddChat] = useState(true);
    const [isLoadMessages, setIsLoadMessages] = useState(false);

    const [openMenu, setOpenMenu] = useState(false);
    const [needReload, setNeedReload] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [accessEdit, setAccessEdit] = useState(false);
    const [accessView, setAccessView] = useState(false);
    const [accessAdd, setAccessAdd] = useState(false);

    const user = useSelector(state => state.header.model);
    const newMessage = useSelector(state => state.chat.newMessage);
    const unreadMessageCount = useSelector(state => state.chat.unreadMessageCount);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.MainTabChat}`;
        if (isToolbarVisible) {
            chatLoad(params.id);
            return;
        }

        if (cardChat) {
            loanChatLoad(cardChat, canChooseChat);            
        }

        return function cleanup() {
            console.log(`Chat did unmount!`);
        };
    }, []);

    useEffect(() => {
        if (newMessage != null)
            pushMessage(newMessage);
    }, [newMessage]);

    useEffect(() => {
        if (params.id && !cardChat)
            chatSelected(params.id, search);
    }, [params.id]);

    useEffect(() => {
        if (needReload)
            chatLoad(params.id);
    }, [needReload]);

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded || isLoadMessages)
        return <LoadingMask />;

    if (!accessView)
        return <ErrorMask error={window.captions.ViewAccessDeny} />;

    let dialogs = null;
    if (showPostDialog) {
        dialogs = <DialogChatPost postMessage={selectedMessage}
            isAdd={isAddPost}
            isEdit={isEditPost}
            isComment={isAddComment}
            onSave={(post) => savePost(post)}
            onClose={() => cancelPost()} />;
    } else if (showChatDialog) {
        const chat = isAddChat ? null : selectedChat;
        if (isAddChat || selectedChat)
            dialogs = <DialogChat chat={chat}
                isAdd={isAddChat}
                onSave={(chat) => saveChat(chat)}
                onClose={() => chatDialogMode(false, false)} />;
    }

    const itemRender = (li, itemProps) => {
        if (!itemProps)
            return li;
        const item = itemProps.dataItem;
        if (item.badgeCount === 0)
            return li;

        const itemBadge = <div>
            <i className={`dm-i dm-i-envelope`} />
            <Badge align={{ vertical: "bottom", horizontal: "end" }}>{item.badgeCount}</Badge>
            <span>{item.name}</span>
        </div>;

        return React.cloneElement(li, li.props, itemBadge);
    };

    let chatControl = null;
    if (selectedChat) {
        let messageBoxTemplate = selectedChat.isLiveFeedMode
            ? messageBoxFeedMode
            : messageBox;

        chatControl = <ChatKendo user={user}
            key="chatControl"
            messages={messages}
            messageBox={messageBoxTemplate}
            className={isToolbarVisible ? "dm-chat-toolbar" : ""}
            messageTemplate={(p) => chatFunctions.template(p, search, accessEdit, handleContextMenuOpen, addMeetingUser, addComment, user.id)}
            onMessageSend={(e) => addMessage(e)}
            placeholder={window.captions.EnterMessage} />;
    }

    const toolbar = isToolbarVisible
        ? <Toolbar>
            <ToolbarItem>
                <DropDownList
                    style={{ width: 200 }}
                    dataItemKey="id"
                    data={chats}
                    textField="name"
                    itemRender={itemRender}
                    value={selectedChat}
                    onChange={(e) => selectChatItem(e.target.value.id)} />
            </ToolbarItem>
            <ToolbarSeparator />
            <ToolbarItem>
                <Input defaultValue={search}
                    onChange={(e) => searchChanged(e)}
                    onKeyDown={(e) => onSearch(e)}
                    placeholder={captions.Search} />
            </ToolbarItem>
            <ToolbarItem>
                <Button icon="search" onClick={() => onSearch(null)} />
            </ToolbarItem>
            <ToolbarSeparator />
            {accessAdd
                ? <ToolbarItem>
                    <Button icon="add"
                        onClick={() => chatDialogMode(true, true)}
                        title={window.captions.Add} />
                </ToolbarItem>
                : null}
            {accessEdit
                ? <ToolbarItem>
                    <Button icon="edit"
                        onClick={() => chatDialogMode(true, false)}
                        title={window.captions.Edit} />
                </ToolbarItem>
                : null
            }
            {accessEdit
                ? <ToolbarItem>
                    <Button icon="delete"
                        onClick={() => deleteChat(true)}
                        title={window.captions.Delete} />
                </ToolbarItem>
                : null
            }
        </Toolbar>
        : null;

    const menuMessageItem = menuMessage.current;
    const canDownload = menuMessageItem && menuMessageItem.type === 3;
    const canQuote = menuMessageItem && menuMessageItem.type === 0 && selectedChat && !selectedChat.isLiveFeedMode;
    const canEdit = menuMessageItem && [0, 4, 5].includes(menuMessageItem.type);
    var contentClass = isToolbarVisible ? "dm-under-title-content" : "dm-full-height";
    var chatContent = <div className={contentClass}>
        <Popup
            offset={offset.current}
            animate={false}
            show={openMenu}>
            <div onBlur={onBlurHandler}>
                <Menu vertical style={{ display: "inline-block" }} onSelect={handleOnSelect}>
                    {canDownload
                        ? <MenuItem text={window.captions.Download
                        } data="download" key="download" />
                        : null}
                    {canEdit
                        ? <MenuItem text={window.captions.Edit} data="edit" key="edit" />
                        : null}
                    {canQuote
                        ? <MenuItem text={window.captions.ToQuote} data="quote" key="quote" />
                        : null}
                    <MenuItem text={window.captions.Delete} data="remove" key="remove" />
                </Menu>
            </div>
        </Popup>
        {toolbar}
        {chatControl}
        {dialogs}
        <ConfirmMessage yes={deleteConfirmed}
            no={confirmClose}
            text={confirm} />
    </div>;

    if (!isToolbarVisible)
        return chatContent;

    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captions.MainTabChat}</h1>
        {chatContent}
    </div>;

    function openInputFiles() {
        if (!selectedChat)
            return;

        const input = document.getElementsByName("files")[0];
        input.click();
    }

    function handleContextMenuOpen(e, message) {
        menuMessage.current = message;
        offset.current = { left: e.clientX, top: e.clientY };
        setOpenMenu(openMenu !== true);
    }

    function handleOnSelect(e) {
        const message = menuMessage.current;
        if (e.item.data === "remove") {
            message.isDeleted = true;
            savePost(message);
        } else if (e.item.data === "edit") {
            editPost(message);
        } else if (e.item.data === "quote") {
            quoteMessage(message);
        } else if (e.item.data === "download" && message.attachmentsList.length !== 0) {
            const attachmentId = message.attachmentsList[0].id;
            FileHelper.getFileChat(attachmentId);
            downloadAttachment();
        }
    }

    function onBlurHandler() {
        setOpenMenu(false);
    }

    function selectChatItem(id) {
        navigate(`/chat/${id}`); //history.push
    }

    function onSearch(e) {
        if (e && e.keyCode !== 13)
            return;

        if (selectedChat)
            chatSelected(selectedChat.id, search);
    }

    function messageBoxFeedMode() {
        return <React.Fragment>
            <div key="chatButtons" className="k-chat-extra-button">
                <Button key="chatPost"
                    className="k-flat"
                    title={window.captions.AddPost}
                    onClick={() => addPost()}>
                    <i className={`dm-i dm-i-plus-square`} />
                </Button>
            </div>
            <div className="k-chat-empty-box">{window.captions.IsLiveFeedMode}</div>
        </React.Fragment>;
    }

    function messageBox(itemProps) {
        const quote = quotedMessage === null || quotedMessage === undefined
            ? null
            : <div>
                <div className="k-chat-input-row">
                    <div >
                        <span className="fas fa-quote-right k-chat-quote-icon" />
                    </div>
                    <div className="k-chat-quote-block">
                        <p className="k-chat-quoted-text"> {quotedMessage.text} </p>
                        <p className="k-chat-quoted-info">
                            {`${quotedMessage.author.name}, ${formatDate(new Date(quotedMessage.timestamp),
                                parseDateTimeFormat)}`}
                        </p>
                    </div>
                    <div className="k-chat-quote-remove-button">
                        <Button icon="k-icon k-font-icon k-i-x" onClick={() => removeQuote()} />
                    </div>
                </div>
                <div className="k-drawer-separator k-chat-separator" />
            </div>;


        return <React.Fragment>
            <div key="chatButtons" className="k-chat-extra-button">
                <Button key="chatPost"
                    className="k-flat"
                    title={window.captions.AddPost}
                    onClick={() => addPost()}>
                    <i className={`dm-i dm-i-plus-square`} />
                </Button>
                <Button aria-label="post"
                    key="addAttachments"
                    className="k-flat"
                    title={window.captions.AddAttachments}
                    onClick={() => { openInputFiles() }}>
                    <i className={`dm-i dm-i-upload`} />
                </Button>
            </div>
            <div style={{
                width: "100%"
            }}>
                {quote}
                <div className="k-chat-input-row">
                    <div className={`k-attachments ${isAttachmentsSelected ? "" : "k-attachments-hidden"}`}>
                        <Upload id="inputAttachmentsEditor"
                            batch={false}
                            multiple={true}
                            files={chatAttachments}
                            onAdd={(e) => attachmentsArrayChanged(e)}
                            onRemove={(e) => attachmentsArrayChanged(e)}
                            onProgress={(e) => progressChanged(e)}
                            onStatusChange={(e) => attachmentsStateChanged(e)}
                            saveHeaders={headersFile()}
                            removeHeaders={headersFile()}
                            saveUrl={window.constants.fileUpload}
                            removeUrl={window.constants.fileRemove} />
                    </div>
                    {isAttachmentsSelected
                        ? <div className="k-send-attachments-button">
                            <Button icon="k-icon k-font-icon k-i-redo"
                                onClick={(e) => addAttachments(e)}
                                title={window.captions.Send} />
                        </div>
                        : <React.Fragment>
                            {itemProps.messageInput}
                            {itemProps.sendButton}
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>;
    }

    function deleteConfirmed() {
        if (!selectedChat)
            return;

        saveChat({
            id: selectedChat.id,
            isUpdated: true,
            isDeleted: true
        });
    }

    function confirmClose() {
        deleteChat(false);
    }

    /* ====== reducxer function */

    function deleteChat(isDelete) {
        setConfirm(isDelete ? window.captions.DeleteItemConfirm : false);
    }

    function searchChanged(e) {
        const value = e.target.value;
        const text = value && value.length > 0
            ? value
            : "";
        setSearch(text);
    }

    function cancelPost() {
        setShowPostDialog(false);
        setOpenMenu(false);
    }

    function added() {
        setShowChatDialog(false);
        setNeedReload(true);
        setConfirm(false);
    }

    function addPost() {
        setShowPostDialog(true);
        setIsAddPost(true);
        setIsEditPost(false);
        setIsAddComment(false);
    }

    function empty() {
        setChatAttachments([]);
        setQuotedMessage(null);
        setIsAttachmentsSelected(false);
    }

    function attachmentsStateChanged(e) {
        if (!e.response)
            return;

        const attachments = chatAttachments; //TODO maybe mutex
        const files = e.response.response;
        for (let index in files) {
            const file = files[index];
            const affectedFile = e.affectedFiles[index];
            const attachment = attachments.find(x => x.uid === affectedFile.uid);
            if (!file || !file.fileName || attachment === undefined || attachment == null)
                continue;

            attachment.fileName = file.fileName;
            attachment.name = file.name;
            attachment.created = new Date();
            attachment.status = affectedFile.status;
        }

        attachmentsChanged(attachments);
    }

    function attachmentsArrayChanged(e) {
        attachmentsChanged(e.newState);
    }

    function progressChanged(e) {
        attachmentsChanged(e.newState);
    }

    function attachmentsChanged(items) {
        setChatAttachments(items ? items : []);
        setIsAttachmentsSelected(items.length !== 0);
        setQuotedMessage(null);
    }

    function quoteMessage(item) {
        setQuotedMessage(item)
        setChatAttachments([]);
        setIsAttachmentsSelected(false);
        setOpenMenu(false);
    }

    function removeQuote() {
        setQuotedMessage(null);
    }

    function downloadAttachment() {
        setOpenMenu(false);
    }

    function editPost(item) {
        setShowPostDialog(true);
        setIsAddPost(false);
        setIsEditPost(true);
        setIsAddComment(false);
        setOpenMenu(false);
        setSelectedMessage(item);
    }

    function addComment(item) {
        setShowPostDialog(true);
        setIsAddPost(false);
        setIsEditPost(false);
        setIsAddComment(true);
        setSelectedMessage(item);
    }

    function loaded(items, messages, accessView, accessEdit, accessAdd, chatId) {
        var currentChat = chatId
            ? items.find((i) => i.id === chatId)
            : items[0];

        setError(null);
        setNeedReload(false);
        setIsLoaded(true);
        setChats(items);
        setSelectedChat(currentChat);
        setAccessEdit(accessEdit);
        setAccessView(accessView);
        setAccessAdd(accessAdd);
        setMessages(messages);

        dispatch(updateUnreadMessageCount(0));
    }

    function fetchError(ex) {
        setError(ex);
        setIsLoaded(true);
        setNeedReload(false);
        setConfirm(false);
    }

    function chatLoad(chatId) {
        setNeedReload(false);
        setIsLoaded(false);
        const chatIdRequest = chatId ? parseInt(chatId) : 0;
        fetchGet(`${window.constants.chatElements}/${chatIdRequest}`,
            data => {
                const messages = chatFunctions.convertMessages(data.messages);
                const items = chatFunctions.convertChats(data.items);
                loaded(items,
                    messages,
                    data.accessView,
                    data.accessEdit,
                    data.accessAdd,
                    chatId ? parseInt(chatId) : null);
            },
            ex => fetchError(ex)
        );
    }

    function loanChatLoad(cardChat, cardChatAccess) {
        const items = chatFunctions.convertChats([cardChat]);
        loaded(items,
            [],
            true,
            cardChatAccess,
            false,
            cardChat.id ? parseInt(cardChat.id) : null);
        chatSelected(cardChat.id, search);
    }

    function chatDialogMode(showChatDialog, isAddChat) {
        setShowChatDialog(showChatDialog);
        setIsAddChat(isAddChat);
    }

    function savePost(post) {
        const isNew = post.id === 0;
        const message = {
            id: post.id,
            chatId: isNew ? selectedChat.id : post.chatId,
            authorId: isNew ? 0 : post.author.id,
            authorName: isNew ? "" : post.author.name,
            timestamp: isNew ? Date.now() : post.timestamp,
            isUpdated: isNew ? false : post.isUpdated,
            isDeleted: isNew ? false : post.isDeleted,
            titleImage: post.titleImage,
            titleImageValue: isNew ? "" : post.titleImageValue,
            type: post.type,
            title: post.title,
            body: post.text,
            attachmentsList: post.attachmentsList,
            comments: isNew ? [] : post.comments,
            usersNotify: post.usersNotify,
            meetingModel: post.meetingModel,
            isImportant: post.isImportant
        };

        fetchPost(window.constants.addMessage,
            message,
            () => cancelPost(),
            ex => fetchError(ex)
        );
    }

    function saveChat(chat) {
        fetchPost(window.constants.saveChat,
            chat,
            () => added(),
            ex => fetchError(ex)
        );
    }

    function addMeetingUser(message, userId) {
        const meet = message.meetingModel;
        meet.participants.push(userId);

        fetchPost(window.constants.addTaskParticipant,
            {
                taskId: meet.id,
                participants: meet.participants
            },
            () => setSelectedChat(message),
            ex => fetchError(ex)
        );
    }

    function chatSelected(id, search) {
        setIsLoadMessages(true);
        fetchPost(`${window.constants.chatMessages}/${id}`,
            search,
            data => {
                const items = chatFunctions.convertMessages(data);

                var chatId = parseInt(id);
                var item = chats.find((i) => i.id === chatId);
                var unreadMessCount = unreadMessageCount;
                if (item) {
                    unreadMessCount = unreadMessCount - item.badgeCount;
                    item.badgeCount = 0;
                    setSelectedChat(item);
                }

                setMessages(items);
                setQuotedMessage(null);
                setChatAttachments([]);
                setIsLoadMessages(false);
                setIsAttachmentsSelected(false);
                setOpenMenu(false);

                dispatch(updateUnreadMessageCount(unreadMessCount));
            },
            ex => fetchError(ex)
        );
    }

    function addAttachments(e) {
        if (!selectedChat || selectedChat.isLiveFeedMode) {
            empty();
            return;
        }

        const timestamp = e.timestamp;
        const models = [];

        for (let index in chatAttachments) {
            const attachment = chatAttachments[index];
            const isImage = attachment.fileName.match(/.(jpg|jpeg|png|gif)$/i);
            if (isImage) {
                models.push({
                    id: 0,
                    chatId: selectedChat.id,
                    authorId: 0,
                    authorName: "",
                    timestamp: timestamp,
                    isUpdated: false,
                    isDeleted: false,
                    isImportant: false,
                    titleImage: attachment.fileName,
                    titleImageValue: "",
                    type: 1,
                    title: attachment.name,
                    body: null,
                    attachmentsList: [],
                    quotedMessage: null,

                    comments: []
                });
                continue;
            }

            models.push({
                id: 0,
                chatId: selectedChat.id,
                authorId: 0,
                authorName: "",
                timestamp: timestamp,
                isUpdated: false,
                isDeleted: false,
                isImportant: false,
                titleImage: "",
                titleImageValue: "",
                type: 3,
                title: "",
                body: null,
                attachmentsList: [attachment],
                quotedMessage: null,

                comments: []
            });
        }

        fetchPost(window.constants.addMessageList,
            models,
            () => empty(),
            ex => fetchError(ex)
        );
    }

    function addMessage(e) {
        if (!selectedChat || selectedChat.isLiveFeedMode) {
            empty();
            return;
        }

        const item = e.message;
        const quoted = quotedMessage
            ? {
                quoteId: quotedMessage.id,
                created: quotedMessage.timestamp,
                authorId: quotedMessage.author.id,
                authorName: quotedMessage.author.name,
                text: quotedMessage.text
            }
            : null;

        if (item.text === "" && quotedMessage) {
            empty();
            return;
        }

        const messageModel = {
            id: 0,
            chatId: selectedChat.id,
            authorId: 0,
            authorName: "",
            timestamp: item.timestamp,
            isUpdated: false,
            isDeleted: false,
            isImportant: false,
            titleImage: "",
            titleImageValue: "",
            type: quoted ? chatTemplateTypes.quote : chatTemplateTypes.def,
            title: "",
            body: item.text,
            attachmentsList: [],
            quotedMessage: quoted,

            comments: []
        };

        fetchPost(window.constants.addMessage,
            messageModel,
            () => empty(),
            ex => fetchError(ex)
        );
    }

    function pushMessage(message) {
        clearMessage();

        if (message.IsDeleted) {
            var deletedItems = [...messages];
            const existedMessage = deletedItems.find((m) => m.id === message.Id);
            if (existedMessage) {
                const index = deletedItems.indexOf(existedMessage);
                deletedItems.splice(index, 1);
                setMessages(deletedItems);
            }
            return;
        }

        var existedChat = chats.find((chat) => chat.id === message.ChatId);
        if (!existedChat)
            return;

        if (!selectedChat || selectedChat.id !== existedChat.id) {
            ++existedChat.badgeCount;
            dispatch(updateUnreadMessageCount(unreadMessageCount + 1));
            return;
        }

        var newMessage = chatFunctions.parsePushMessage(message, messages);
        if (newMessage)
            setMessages([...messages, newMessage]);
    }
}