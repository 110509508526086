import React, { useState, useEffect } from 'react';
import TreeViewCheck from "../../../../../components/controls/treeViewCheck.jsx";

export default function TasksNeeded({ dataRule, ruleChanged }) {
    const [taskTreeList, setTaskTreeList] = useState([]);

    const [data, setData] = useState(null);
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => mountProperties(), [dataRule]);

    return <div className="dm-container dm-no-padding">
        <TreeViewCheck
            key="treeViewCheck"
            dataTree={taskTreeList}
            onCheckChange={selectedTasksChanged}
        />
    </div>;

    function mountProperties() {
        if (!dataRule || !taskTree) {
            setTaskTreeList([]);
            return;
        }

        let taskTreeLocal = Array.isArray(taskTree) && taskTree.length ? taskTree : [];
        taskTreeLocal.forEach(i => setParents(i));
        taskTreeLocal.forEach(i => setCheckPrivileges(i, dataRule));

        setTaskTreeList(taskTreeLocal);
    }

    function setCheckPrivileges(data, dataRule) {
        if (!data) {
            return;
        }

        if (data.id && data.id >= 0 && dataRule.tasks.length > 0) {
            data.checked = dataRule.tasks.some(id => id == data.id);
            if (data.checked && data.items) {
                data.items.forEach(i => i.checked = true);
                return;
            }
        }

        if (data.items && data.items.length > 0) {
            data.items.forEach(i => setCheckPrivileges(i, dataRule));
            if (data.items.some((item) => item.checked)) {
                if (data.items.every((item) => item.checked)) {
                    data.checked = true;
                } else {
                    data.checked = null;
                }
            }
            else {
                data.checked = false;
            }
        }
    }

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function selectedTasksChanged(item) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //urlsTypes: selected,
                isUpdated: true
            });
    }
}