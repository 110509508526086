import React from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { GridBaseComponent, createdSort } from "../../../components/gridApiSource.jsx";
import { Button } from "@progress/kendo-react-buttons";
import GridDateCell from "../../../components/cells/gridDateCell.jsx";
import { entitySources } from "../../../utils/systemConstants.jsx";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import FileHelper from "../../../utils/fileHelper.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

export default function DocumentPackageControl(props) {
    const sourceCell = (p) => {
        switch (p.dataItem[p.field]) {
            case entitySources.loan:
                return <td {...getLinkCellStyle(p)} title={window.captionsDynamic.LoanSingleNominative}>
                    <i className={`dm-i dm-i-money-check`} />
                </td>;
            case entitySources.client:
                return <td {...getLinkCellStyle(p)} title={window.captions.Client}>
                    <i className={`dm-i dm-i-user-tie`} />
                </td>;
            default:
                return <td {...getLinkCellStyle(p)} />;
        }
    };

    const stateCell = (p) => {
        if (p.dataItem["isVerification"])
            return <td {...getLinkCellStyle(p)} title={window.captions.Verifier}>
                <span className="k-icon k-font-icon k-i-check-circle dm-positive dm-symbol-size" />
            </td>;
        else if (p.dataItem["isReview"])
            return <td {...getLinkCellStyle(p)} title={window.captions.Review}>
                <span className="k-icon k-font-icon k-i-edit dm-warning dm-symbol-size" />
            </td>;
        else if (p.dataItem["isPack"])
            return <td {...getLinkCellStyle(p)} title={window.captions.Pack}>
                <span className="k-icon k-font-icon k-i-pdf dm-error dm-symbol-size" />
            </td>;
        else if (p.dataItem["isErrors"])
            return <td {...getLinkCellStyle(p)} title={window.captions.Errors}>
                <i className={`dm-i dm-i-exclamation-triangle dm-error`} />
            </td>;
        else
            return <td {...getLinkCellStyle(p)} />;
    };

    const documentCell = (p) => {
        const id = p.dataItem["id"];
        const files = p.dataItem[p.field];
        const spanStyle = { width: "25px", display: "inline-block" };
        if (!files)
            return <td {...getLinkCellStyle(p)} />;
        const items = files.map((item, i) => {
            return <div key={i}>
                <span style={spanStyle}>
                    {item.isPack ? "" : `v${item.versionId}`}
                </span>
                {item.isPack
                    ? <span className="k-icon k-font-icon k-i-pdf dm-error" />
                    : <span className="k-icon k-font-icon k-i-doc dm-active" />}
                <BadgeContainer>
                    <span className="k-icon k-font-icon k-i-file" />
                    <Badge themeColor="dark">{item.pagesCount}</Badge>
                </BadgeContainer>
                <Button fillMode="flat" icon="k-i-preview k-i-eye" onClick={() => {
                    loadDocument(item.isPack ? id : item.id, item.isPack);
                }} />
            </div>;
        });
        return <td {...getLinkCellStyle(p)}>
            {items}
        </td>;
    };

    return <GridBaseComponent baseUrl={`${window.constants.cardSourceDocumentPackages}/${props.source}/${props.id}`} sort={createdSort} className="dm-full-wh">
        <GridColumn title="">
            <GridColumn field="source" title=" " width="35" cells={{ data: sourceCell }} />
            <GridColumn field="id" title=" " width="35" cells={{ data: stateCell }} />
        </GridColumn>
        <GridColumn title={window.captions.Document}>
            <GridColumn field="files" title={window.captions.Document} width="150" sortable={false} cells={{ data: documentCell }} />
            <GridColumn field="packageType" title={window.captions.WordType} width="220" />
            <GridColumn field="packageState" title={window.captions.State} width="220" />
            <GridColumn field="created" title={window.captions.Created} width="130" cells={{ data: GridDateCell }} />
            <GridColumn field="index" title={window.captions.DocumentIndex} width="120" />
            <GridColumn field="envelope" title={window.captions.Envelope} width="120" />
            <GridColumn field="weight" title={window.captions.Weight} width="120" />
        </GridColumn>
        <GridColumn title={window.captions.Addressee}>
            <GridColumn field="object" title={window.captions.Object} width="200" />
            <GridColumn field="addressee" title={window.captions.Addressee} width="200" />
        </GridColumn>
        <GridColumn title={window.captions.Mail}>
            <GridColumn field="batchName" title={window.captions.Group} width="220" />
            <GridColumn field="externalId" title={window.captions.TrackNumber} width="160" />
            <GridColumn field="cost" title={window.captions.DeliveryCost} width="160" />
            <GridColumn field="sendState" title={window.captions.SendState} width="160" />
            <GridColumn field="sended" title={window.captions.SendedDate} width="160" />
            <GridColumn field="delivered" title={window.captions.Delivered} width="220" />
        </GridColumn>
        <GridColumn field="author" title={window.captions.Author} width="220" />
    </GridBaseComponent>;

    function loadDocument(id, isPack) {
        FileHelper.getFileDocumentPackage(id, isPack);
    }

}