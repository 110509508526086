import React from "react";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import { entitySourcesNames } from "../../utils/systemConstants.jsx";
import { ToolbarItem, ToolbarSeparator } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom";
import { changedSystemValue } from "./cardEntityReducer.jsx";

export function renderState(isMobile, card, dispatch) {
    const model = card.model;
    const statusBar = [];

    if (model.questionnaireId > 0) {
        const href = `/questionnaire/${model.questionnaireId}/${model.id}`;
        statusBar.push(<ToolbarItem key="toolBarQuestionnaire">
            <Link to={href} title={window.captions.Questionnaire}>
                <i title={window.captions.Questionnaire} className={`dm-i dm-i-edit`} />
            </Link>
        </ToolbarItem>);
        statusBar.push(<ToolbarSeparator key="toolBarQuestionnaireSep" />);
    }

    if (model.isArchived) {
        statusBar.push(<ToolbarItem key="toolBarArchived">
            <div title={window.captions.InArchive}>
                <i className={`dm-i dm-i-archive`} />
            </div>
        </ToolbarItem>);
        statusBar.push(<ToolbarSeparator key="toolBarArchivedSep" />);
    }

    if (card.entity === entitySourcesNames.loan) {
        statusBar.push(<ToolbarItem key="toolBarClientLink">
            <div title={window.captions.InArchive}>
                <Link to={`/card/client/${model.clientId}`} title={window.captions.ClientCard}>
                    <i className={`dm-i dm-i-user-tie`} />
                </Link>
            </div>
        </ToolbarItem>);
        statusBar.push(<ToolbarSeparator key="toolBarClientLinkSep" />);
    }

    const barCaption = function (name) { return isMobile ? <span /> : <b>{name}</b>; };
    const barEditCaption = function (key, name) {
        if (!isMobile)
            statusBar.push(<ToolbarItem key={`toolBarLabel${key}`}>
                <b>{name}</b>
            </ToolbarItem>);
    };

    if (card.entity === entitySourcesNames.loan && model.showProject) {
        statusBar.push(<ToolbarItem key="toolBarProject">
            {barCaption(window.captions.Project)}
            <label>{model.projectName}</label>
        </ToolbarItem>);
        statusBar.push(<ToolbarSeparator key="toolBarProjectSep" />);
    }

    if (card.isEditMode && model.access.canEditExecutor && model.sources.executors.length > 0) {
        barEditCaption("Executor", window.captions.Executor);
        statusBar.push(<ToolbarItem key="toolBarExecutor">
            <DropDownTreeView field="author"
                selectedId={model.author}
                treeData={model.sources.executors}
                selectionChanged={(e) => dispatch(changedSystemValue(e))} />
        </ToolbarItem>);
    } else if (model.authorName) {
        statusBar.push(<ToolbarItem key="toolBarExecutor">
            {barCaption(window.captions.Executor)}
            <label>{model.authorName}</label>
        </ToolbarItem>);
    }
    if (card.isEditMode && model.access.canEditExecutor && model.sources.externalExecutors.length > 0) {
        barEditCaption("ExternalUser", window.captions.ExternalUser);
        statusBar.push(<ToolbarItem key="toolBarExternalExecutor">
            <DropDownTreeView field="externalUser"
                selectedId={model.externalUser}
                treeData={model.sources.externalExecutors}
                selectionChanged={(e) => dispatch(changedSystemValue(e))} />
        </ToolbarItem>);
    }
    else if (model.externalUserName) {
        statusBar.push(<ToolbarItem key="toolBarExternalExecutor">
            {barCaption(window.captions.ExternalUser)}
            <label>{model.externalUserName}</label>
        </ToolbarItem>);
    }

    if (card.entity === entitySourcesNames.client) {
        if (card.isEditMode && model.access.canEditType) {
            statusBar.push(<ToolbarSeparator key="toolBarClientTypeSep" />);
            barEditCaption("FizJur", window.captions.ClientType);
            statusBar.push(<ToolbarItem key="toolBarClientType">
                <DropDownTreeView field="fizJur"
                    selectedId={model.fizJur}
                    treeData={model.sources.clientCardType}
                    selectionChanged={(e) => dispatch(changedSystemValue(e))} />
            </ToolbarItem>);
        } else {
            statusBar.push(<ToolbarItem key="toolBarCodeType">
                {barCaption(window.captions.ClientType)}
                <label>{model.fizJurName === "" ? window.captions.Unseted : model.fizJurName}</label>
            </ToolbarItem>);
        }
    }

    if (card.entity === entitySourcesNames.collateral) {
        if (card.isEditMode && model.access.canEditType) {
            statusBar.push(<ToolbarSeparator key="toolBarCodeTypeSep" />);
            barEditCaption("CodeType", window.captions.WordType);
            statusBar.push(<ToolbarItem key="toolBarCodeType">
                <DropDownTreeView field="typeCode"
                    selectedId={model.typeCode}
                    treeData={model.sources.collateralTypes}
                    selectionChanged={(e) => dispatch(changedSystemValue(e))} />
            </ToolbarItem>);
        } else {
            statusBar.push(<ToolbarItem key="toolBarCodeType">
                {barCaption(window.captions.WordType)}
                <label>{model.codeText}</label>
            </ToolbarItem>);
        }
    }

    if (card.entity === entitySourcesNames.documents) {
        if (card.isEditMode && model.access.canEditType) {
            statusBar.push(<ToolbarSeparator key="toolBarCodeTypeSep" />);
            barEditCaption("CodeType", window.captions.WordType);
            statusBar.push(<ToolbarItem key="toolBarCodeType">
                <DropDownTreeView field="typeId"
                    selectedId={model.typeId}
                    treeData={model.sources.documentTypes}
                    selectionChanged={(e) => dispatch(changedSystemValue(e))} />
            </ToolbarItem>);
        } else {
            statusBar.push(<ToolbarItem key="toolBarCodeType">
                {barCaption(window.captions.WordType)}
                <label>{model.typeName}</label>
            </ToolbarItem>);
        }
    }

    if (card.isEditMode && model.access.canEditStage) {
        statusBar.push(<ToolbarSeparator key="toolBarExecutorSep" />);
        barEditCaption("Stage", window.captions.StageAndState);
        statusBar.push(<ToolbarItem key="toolBarStage">
            <DropDownTreeView field="stateId"
                selectedId={model.stateId}
                treeData={model.sources.states}
                selectionChanged={(e) => dispatch(changedSystemValue(e))} />
        </ToolbarItem>);
    } else {
        if (model.stageName) {
            statusBar.push(<ToolbarSeparator key="toolBarExecutorSep" />);
            statusBar.push(<ToolbarItem key="toolBarStage">
                {barCaption(window.captions.Stage)}
                <label>{model.stageName}</label>
            </ToolbarItem>);
        }

        if (model.stateName) {
            statusBar.push(<ToolbarSeparator key="toolBarStageSep" />);
            statusBar.push(<ToolbarItem key="toolBarState">
                {barCaption(window.captions.State)}
                <label>{model.stateName}</label>
            </ToolbarItem>);
        }
    }

    return statusBar;
}