import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import InlineDialog from "../../components/inlineDialog.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import { fetchGet, fetchPost, headersFile } from "../../utils/requestsHelper.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";

export default function DialogDocument({ cardEntity, cardId, inline, typesHidden, isChoiceDocument, onClose }) {
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [types, setTypes] = useState([]);
    const [titles, setTitles] = useState([]);
    const [defaultTypeId, setDefaultTypeId] = useState(0);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => fetchData(), []);

    const title = window.captions.AddDocument;
    if (pending || error) {
        const message = <MessageMask inline text={pending} error={error} />;
        return inline
            ? <InlineDialog title={title} onClose={() => onClose(false)}>
                {message}
            </InlineDialog>
            : <Dialog title={title} onClose={() => onClose(false)} width={550}>
                {message}
            </Dialog>;
    }

    const attachmentsPanel =
        attachments.map((item, g) => {
            return [
                <div key={`DocumentType${g}`} className="dm-size-33">
                    <DropDownTreeView
                        field={item.index}
                        treeData={types}
                        selectedId={item.typeId}
                        selectionChanged={changeType}
                        style={{ width: "100%" }} />
                </div>,
                <Input key={`DocumentName${g}`}
                    className="dm-size-66"
                    item={item.index}
                    placeholder={window.captions.Caption}
                    onChange={changeName}
                    value={item.name} />
            ];
        }
        );

    let modal = null;
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    const dialogActions = <DialogActionsBar>
        <Button onClick={() => onClose(false)}>{window.captions.Cancel}</Button>
        <Button themeColor="primary" onClick={save}>{window.captions.Save}</Button>
    </DialogActionsBar>;

    const uploader = <Upload
        batch={false}
        multiple={true}
        defaultFiles={[]}
        onStatusChange={uploadStatusChange}
        onRemove={remove}
        saveHeaders={headersFile()}
        removeHeaders={headersFile()}
        saveUrl={window.constants.fileUpload}
        removeUrl={window.constants.fileRemove} />;

    return inline
        ? [
            <InlineDialog title={title} key="dialogDocuments"
                onClose={() => onClose(false)}
                buttons={dialogActions}>
                {uploader}
                <div className="dm-container dm-container-slim">
                    {attachmentsPanel}
                </div>
            </InlineDialog>,
            modal
        ]
        : [
            <Dialog key="dialogDocuments" title={title} onClose={() => onClose(false)} width={550}>
                {uploader}
                <div className="dm-container dm-no-padding">
                    {attachmentsPanel}
                </div>
                {dialogActions}
            </Dialog>,
            modal
        ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(window.constants.getDocumentModel,
            (data) => {
                var types = data.types;
                if (typesHidden) {
                    types = types.filter(t => typesHidden.indexOf(t.id) === -1);
                    if (types.indexOf(data.defaultTypeId) === -1)
                        data.defaultTypeId = types[0].id;
                }
                setTypes(types);
                setDefaultTypeId(data.defaultTypeId);
                setTitles(data.titles);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setTypes([]);
                setPending(null);
                setError(ex.stack ? ex.stack : ex);
            });
    }

    function uploadStatusChange(e) {
        if (!e.response)
            return;

        var files = e.response.response;
        var length = attachments.length;
        var items = [];
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;

            var attachment = {
                index: length + items.length,
                typeId: defaultTypeId,
                fileName: file.fileName,
                name: file.name,
                size: file.size
            };

            checkTypeTitle(attachment);
            items.push(attachment);
        }
        setAttachments([...attachments, ...items])
    }

    function remove(e) {
        const items = [...attachments];
        const removeFiles = e.affectedFiles;
        for (let key in removeFiles) {
            const file = removeFiles[key];
            const attach = items.find((i) => i.name === file.name.replace(file.extension, "") &&
                i.fileName === file.fileName &&
                i.size === file.size);

            if (!attach)
                continue;

            const index = items.indexOf(attach);
            items.splice(index, 1);
        }
        setAttachments(items);
    }

    function checkTypeTitle(attachment) {
        var typeName = types.find(x => x.id === attachment.typeId).name;
        var newName = "";
        var title = titles.find(x => x.id === attachment.typeId);
        if (title && title.name.includes('{DocumentsDTID}')) {
            newName = typeName;
        }
        if (title && title.name.includes('{DocumentsOT}')) {
            newName += ` ${cardId}`;
        }

        if (attachment.name !== newName && newName.length > 0)
            attachment.name = newName;
    }

    function changeType(e) {
        var index = e.fieldId;
        var typeId = e.value;
        var items = [...attachments];
        var attachment = items[index];
        if (!attachment)
            return;

        if (attachment.typeId === typeId)
            return;

        attachment.typeId = typeId;
        checkTypeTitle(attachment);
        setAttachments(items);
    }

    function changeName(e) {
        var index = e.target.props.item;
        var name = e.target.value;
        var items = [...attachments];
        var attachment = items[index];
        if (!attachment)
            return;

        if (attachment.name === name)
            return;

        attachment.name = name;
        setAttachments(items);
    }

    function save() {
        if (!attachments || attachments.length === 0) {
            alert(window.captions.ChooseAtLeastOneAttachment);
            return;
        }

        for (let key in attachments) {
            const item = attachments[key];
            if (item.name === "") {
                alert(window.captions.MessageBoxEmptyNameDeny);
                return;
            }
        }

        const saveData = [];
        for (let key in attachments) {
            const item = attachments[key];
            saveData.push({
                typeId: item.typeId,
                fileName: item.fileName,
                name: item.name
            });
        }
        if (isChoiceDocument) {
            onClose(saveData);
            return;
        }

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveDocument}/${cardEntity}/${cardId}`,
            saveData,
            (saveError) => {
                if (saveError) {
                    setValidate(saveError);
                    setPending(null);
                } else
                    onClose(true);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }
}