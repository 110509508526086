import React from "react";
import { formatDate } from "@progress/kendo-intl";
import { timeFormat } from "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";

export default function GridDateCell({ dataItem, field, tdProps, style, className }) {
    let value = dataItem[field];
    let cellValue = null;
    if (value != null && value.minutes)
        cellValue = formatDate(new Date(1, 1, 2000, value.hours, value.minutes, value.seconds), timeFormat, getUiCulture());
    else if (value != null && typeof value !== "object" && value !== "")
        cellValue = formatDate(new Date(`2000-01-01T${value}`), timeFormat, getUiCulture());

    const props = { style: style, ...tdProps };
    if (className)
        props.className = className;

    return <td {...props}>
        {cellValue}
    </td>;
}