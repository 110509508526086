import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from "@progress/kendo-intl";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { fetchGet, fetchPost, headersFile } from "../../utils/requestsHelper.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { DateTimePicker, DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import GridFileCell from "../../components/cells/gridFileCell.jsx";
import GridFileTypeCell from "../../components/cells/gridFileTypeCell.jsx";
import GridDateCell from "../../components/cells/gridDateCell.jsx";
import GridContactInfo from "../../components/cells/gridContactInfo.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import DialogAddLinkedClient from "./dialogAddLinkedClient.jsx";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import DialogDocument from "./dialogDocument.jsx";
import MessageMask from "../../components/messageMask.jsx";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import { Upload } from "@progress/kendo-react-upload";
import {
    dateTimeFormat,
    dateFormat,
    entitySourcesNames,
    getFormatDate,
    dateWidth,
    getFormatDateTime,
    dateTimeWidth
} from
    "../../utils/systemConstants.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";
import FileHelper from "../../utils/fileHelper.jsx";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogProcess({ access, processId, cardId, cardEntity, projectId, onClose }) {
    const isAdd = processId === 0;

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);

    const [selectedTab, setSelectedTab] = useState(0);
    const [isEdit, setIsEdit] = useState(isAdd);
    const [isEdited, setIsEdited] = useState(false);
    const [isMainFieldsChanged, setIsMainFieldsChanged] = useState(isAdd);

    const [process, setProcess] = useState(null);
    const [type, setType] = useState(null);
    const [name, setName] = useState("");
    const [state, setState] = useState(0);
    const [stateName, setStateName] = useState("");
    const [executor, setExecutor] = useState(0);
    const [executorName, setExecutorName] = useState("");
    const [sources, setSources] = useState(null);
    const [linkedClients, setLinkedClients] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [actions, setActions] = useState([]);

    const [deletedLinkedClients, setDeletedLinkedClients] = useState([]);
    const [deletedActions, setDeletedActions] = useState([]);
    const [deletedDocuments, setDeletedDocuments] = useState([]);
    const [deletedActionDocuments, setDeletedActionDocuments] = useState([]);

    const [processObjects, setProcessObjects] = useState([]);
    const [object, setObject] = useState(null);
    const [objectIds, setObjectIds] = useState(null);
    const [processAccess, setProcessAccess] = useState({});

    const [showAddLinked, setShowAddLinked] = useState(false);
    const [showAddDocument, setShowAddDocument] = useState(false);

    useEffect(() => fetchData(), []);

    let title = isAdd ? window.captions.MainTabCardAddProcess : window.captions.Process;
    if (pending || error) {
        return <Dialog title={title} onClose={onCloseDialog} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const isNotFinished = !process.isFinished;

    let editViewButton = null;
    if (access.canAddEditProcess && isNotFinished)
        editViewButton =
            <Button iconClass={`dm-i dm-i-${(isEdit ? "eye" : "pen")}`} onClick={changeEdit}>{isEdit
                ? window.captions.View
                : window.captions.EditMode
            }</Button>;

    let saveButton = null;
    if (isEdited) {
        saveButton = <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Save}</Button>;
    }

    let modal = null;
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    var confirmDialog = <ConfirmMessage key="confirmDialog"
        yes={confirmOk}
        no={confirmClose}
        text={confirm} />;

    const processInfo = [];

    renderPair(processInfo,
        window.captions.Caption,
        "name",
        () => <MultilineTextArea changeText={changeName} text={name} />,
        name,
        isEdit);

    renderPair(processInfo,
        window.captions.Object,
        "object",
        () => <DropDownCheckedTree
            treeData={processObjects}
            selectedIdList={object}
            selectionChanged={changeProcessObject}
            style={{ width: "100%" }} />,
        process.object,
        isAdd);

    const selectedType = sources.processTypes
        ? sources.processTypes.find((e) => e.id === type)
        : null;
    renderPair(processInfo,
        window.captions.Process,
        "type",
        () => <DropDownList
            textField="name"
            dataItemKey="id"
            data={sources.processTypes}
            value={selectedType}
            onChange={changeType}
            style={{ width: "100%" }} />,
        process.typeCaption,
        isAdd);

    var stateSource = null;
    if (isAdd) {
        if (selectedType)
            stateSource = selectedType.items;
    } else {
        stateSource = sources.processStates;
    }

    if (stateSource !== null) {
        var selectedState = stateSource.find((i) => i.id === state);
        renderPair(processInfo,
            window.captions.State,
            "state",
            () => <DropDownList
                textField="name"
                dataItemKey="id"
                data={stateSource}
                value={selectedState}
                onChange={changeState}
                style={{ width: "100%" }} />,
            stateName,
            isEdit);
    }

    renderPair(processInfo,
        window.captions.Executor,
        "executor",
        () => <DropDownTreeView
            treeData={sources.executors}
            selectedId={executor}
            selectionChanged={changeExecutor}
            style={{ width: "100%" }} />,
        executorName,
        isEdit && sources.executors.length > 0);

    if (isAdd) {
        return [
            <Dialog title={title} onClose={onCloseDialog} width={450} key="dialogProcess">
                <div className="dm-container dm-no-padding">
                    {processInfo}
                </div>
                <DialogActionsBar>
                    {saveButton}
                </DialogActionsBar>
            </Dialog>,
            modal,
            confirmDialog
        ];
    }

    const actionsSystemFields = (p) => {
        const systemFields = [];
        //Custom control?

        renderPair(systemFields,
            window.captions.Action,
            "action",
            () => <DropDownTreeView
                treeData={sources.processActions}
                selectedId={p.type}
                selectionChanged={(e) => { changeActionType(e, p) }}
                style={{ width: "calc(100% - 42px)" }} />,
            p.typeName,
            isEdit && p.id === 0);

        if (isEdit && access.canDeleteProcessAction) {
            systemFields.push(
                <div key="actionDelete" className="dm-card-editor" style={{
                    position: "absolute",
                    right: "20px",
                    width: "36px"
                }}>
                    <Button icon="delete" onClick={(e) => handleOnDeleteAction(e, p)} />
                </div>);
        }

        renderPair(systemFields,
            window.captions.DateCreated,
            "created",
            null,
            formatDate(new Date(p.created), dateTimeFormat, getUiCulture()),
            false);

        renderPair(systemFields, window.captions.Author, "author", null, p.authorName, false);

        renderPair(systemFields,
            window.captions.ExpectedEndDate,
            "expected",
            () => <DateTimePicker
                onChange={(e) => { changeActionExpected(e, p) }}
                value={p.expectedEndDate ? new Date(p.expectedEndDate) : null}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />,
            p.expectedEndDate
                ? formatDate(new Date(p.expectedEndDate), dateTimeFormat, getUiCulture())
                : null,
            isEdit && access.canEditDateProcess);


        renderPair(systemFields,
            window.captions.ActualEndDate,
            "actual",
            () => <DatePicker
                onChange={(e) => { changeActionActual(e, p) }}
                value={p.actualEndDate ? new Date(p.actualEndDate) : null}
                width={dateWidth}
                formatPlaceholder={getFormatDate()} />,
            p.actualEndDate
                ? formatDate(new Date(p.actualEndDate), dateFormat, getUiCulture())
                : null,
            isEdit && access.canEditDateProcess);

        return systemFields;
    };

    const actionsDocuments = (item) => {
        if (item.documents.length > 0) {
            var attachments = [];
            var styleFileButton = { borderRadius: "3px 0 0 3px", height: "42px" };
            const buttonDelete = (document) => {
                if (isEdit && processAccess.canDeleteDocument) {
                    return <Button style={{
                        margin: "0 5px 0 0",
                        position: "absolute",
                        width: "25px",
                        height: "42px",
                        borderRadius: "0 3px 3px 0"
                    }}
                        themeColor="primary" icon="close" onClick={(e) => handleOnDeleteActionDocument(e, document)} />;
                }
            };
            if (!isEdit) {
                styleFileButton = { margin: "0 5px 0 0", height: "42px" };
            }
            item.documents.forEach((item) => {
                if (item.id > 0)
                    attachments.push(
                        <span key="openDocument">
                            <Button style={styleFileButton} primary key={`attachment${item.id}`} title={item.name
                            } onClick={() => FileHelper.getDocument(item.id)}>
                                <span className="dm-icon">
                                    <FileIcon extension={item.fileType} {...defaultStyles[
                                        item.fileType]} />
                                </span>
                                {item.sizeText}
                            </Button>
                            {buttonDelete(item)}
                        </span>
                    );
            });
            return <div key="attachmentData" className="dm-size-100">{attachments}</div>;
        }
    };
    const actionUpload = (item) => {
        if (isEdit && processAccess.canAddDocument) {
            return <div key="attachmentUpload" className="dm-size-100" style={{ margin: "5px 0 0 0" }}>
                <Upload
                    batch={false}
                    multiple={true}
                    defaultFiles={[]}
                    onStatusChange={(e) => uploadStatusChange(e, item.documents, item.id)}
                    saveHeaders={headersFile()}
                    removeHeaders={headersFile()}
                    saveUrl={window.constants.fileUpload}
                    removeUrl={window.constants.fileRemove} />
            </div>;
        }
    };
    const actionsPanel =
        actions.map((item, g) => <PanelBarItem title={item.typeName} key={g} expanded={g === 0}>
            <div className="dm-filter-group dm-container">
                {actionsSystemFields(item)}
                <FieldsControl id={item.id}
                    entity={entitySourcesNames.processAction}
                    fields={item.fields}
                    sources={sources}
                    isEditMode={isEdit}
                    setFields={setEntityFields} />
                {actionsDocuments(item)}
                {actionUpload(item)}
            </div>
        </PanelBarItem>
        );

    const fieldsPanel = process.fields && Object.keys(process.fields).length > 0
        ? <FieldsControl id={process.id}
            entity={entitySourcesNames.process}
            fields={process.fields}
            sources={sources}
            isEditMode={isEdit}
            setFields={setEntityFields} />
        : null;

    const closeTitle = isEdited ? window.captions.Cancel : window.captions.Close;

    var actionsTab = null;
    var renderButton = (canAdd, handler, name) => {
        return canAdd
            ? <Button className="dm-positive-filled" onClick={handler}>{name}</Button>
            : null;
    };
    var canAddAction = access.canAddEditProcess && isNotFinished && sources.processActions.length > 0;
    var canAddLinked = access.canAddEditProcess && processAccess.canAddLinkedClient && isNotFinished;
    var canAddDocument = access.canAddEditProcess && processAccess.canAddDocument && isNotFinished;
    var hasActionBar = canAddAction || actionsPanel.length > 0;
    if (hasActionBar) {
        actionsTab = <TabStripTab title={window.captions.Action}>
            <div>
                <PanelBar expandMode="single" style={{ width: "100%" }}>
                    {actionsPanel}
                </PanelBar>
            </div>
        </TabStripTab>;
    }

    var actionsButton;
    var tab = selectedTab;
    if (tab === 1 && actionsTab) {
        actionsButton = renderButton(canAddAction, handleNewAction, window.captions.AddAction);
    } else if (tab === 2 && actionsTab || tab === 1 && !actionsTab) {
        actionsButton = renderButton(canAddLinked,
            () => setShowAddLinked(true),
            window.captions.MainTabCardAddLinked);
    } else if (tab === 3 && actionsTab || tab === 2 && !actionsTab) {
        actionsButton = renderButton(canAddDocument,
            () => setShowAddDocument(true),
            window.captions.AddDocument);
    }

    if (showAddLinked) {
        modal = <DialogAddLinkedClient cardId={0}
            key="dialogAddLinkedClient"
            cardEntity={entitySourcesNames.client}
            canAdd={false}
            isChoiceClient={true}
            canSelectType={true}
            onClose={(data) => handleAddLinked(data)} />;
    }

    if (showAddDocument) {
        modal = <DialogDocument key="dialogDocuments" isChoiceDocument={true} onClose={(data) => handleAddDocument(data)} />;
    }

    return [
        <Dialog title={title}
            key="dialogProcess"
            onClose={onCloseDialog} width={950}
            className="k-dialog-no-padding">
            <TabStrip selected={tab} onSelect={handleSelectTab}>
                <TabStripTab title={window.captions.Process}>
                    <div className="dm-container dm-no-padding">
                        {processInfo}
                    </div>
                </TabStripTab>
                {actionsTab}
                <TabStripTab title={window.captions.LinkedPersons}>
                    <Grid filterable={false}
                        sortable={true}
                        pageable={false}
                        data={linkedClients}
                        total={linkedClients ? linkedClients.length : 0}
                        className="dm-full-wh">
                        <GridColumn field="name" title={window.captions.Caption} width="240" />
                        <GridColumn field="id" title={window.captions.MainTabCardContact}
                            cells={{ data: (p) => <GridContactInfo {...p} sources={sources} /> }} />
                        <GridColumn field="clientTypeName" title={window.captions.WordType} width="240" />
                        <GridColumn field="id" title=" " width="35" cells={{ data: removeCell }} />
                    </Grid>
                </TabStripTab>
                <TabStripTab title={window.captions.Attachments}>
                    <Grid filterable={false}
                        sortable={true}
                        pageable={false}
                        data={documents}
                        total={documents ? documents.length : 0}
                        className="dm-full-wh">
                        <GridColumn field="id" title=" " width="70" cells={{ data: GridFileCell }} />
                        <GridColumn field="fileType" title=" " width="70" cells={{ data: GridFileTypeCell }} />
                        <GridColumn field="name" title={window.captions.Caption} />
                        <GridColumn field="type" title={window.captions.WordType} width="240" />
                        <GridColumn field="sizeText" title={window.captions.SizeInKiloBites} width="240" />
                        <GridColumn field="created" title={window.captions.DateCreated} width="120"
                            cells={{ data: GridDateCell }} />
                        {processAccess.canDeleteDocument
                            ? <GridColumn field="id" title=" " width="35" cells={{ data: removeCell }} />
                            : null}
                    </Grid>
                </TabStripTab>
                {fieldsPanel
                    ? <TabStripTab title={window.captions.Fields}>
                        <div className="dm-container dm-no-padding">
                            {fieldsPanel}
                        </div>
                    </TabStripTab>
                    : null}
            </TabStrip>
            <DialogActionsBar>
                <Button onClick={onCloseDialog}>{closeTitle}</Button>
                {editViewButton}
                {saveButton}
                {actionsButton}
            </DialogActionsBar>
        </Dialog>,
        modal,
        confirmDialog
    ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        const fetchProjectId = projectId ? projectId : 0;
        fetchGet(`${window.constants.getProcessModel}/${cardEntity}/${cardId}/${processId}/${fetchProjectId}`,
            (data) => {
                if (!data) {
                    setPending(null);
                    setError(window.captions.WordError);
                    return;
                }

                setProcess(data);
                setName(data.name);
                setProcessObjects(data.processObjects);
                setState(data.state);
                setStateName(data.stateName);
                setExecutor(data.authorId);
                setExecutorName(data.authorName);
                setSources(data.sources);
                setLinkedClients(data.linkedClients);
                setDocuments(data.documents);
                setActions(data.actions);
                setProcessAccess(data.access);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function removeCell(p) {
        if (isEdit)
            return <td {...getLinkCellStyle(p)}>
                <Button icon="delete" onClick={() => handleOnDelete(p.dataItem)} />
            </td>;
        else
            return <td {...getLinkCellStyle(p)} />;
    };

    function setEntityFields(fields, entityId, entity) {
        if (entity === entitySourcesNames.process) {
            setProcess({ ...process, fields });
            setIsEdited(true);
            setIsMainFieldsChanged(true);
        } else if (entity === entitySourcesNames.processAction) {
            const action = actions.find((i) => { return i.id === entityId });
            if (!action)
                return;

            action.fields = fields;
            action.updated = true;
            setActions([...actions]);
            setIsEdited(true);
        }
    }

    function handleSelectTab(e) {
        setSelectedTab(e.selected);
    }

    function changeName(e) {
        setName(e.value);
        setIsEdited(true);
        setIsMainFieldsChanged(true);
    }

    function changeType(e) {
        const type = e.target.value.id;
        setType(type);
        setIsEdited(true);
        setIsMainFieldsChanged(true);
    }

    function changeExecutor(e) {
        setExecutor(e.value);
        setExecutorName(e.valueText);
        setIsEdited(true);
        setIsMainFieldsChanged(true);
    }

    function changeState(e) {
        setState(e.target.value.id);
        setStateName(e.target.value.name);
        setIsEdited(true);
        setIsMainFieldsChanged(true);
    }

    function changeActionExpected(e, action) {
        action.expectedEndDate = new Date(e.value);
        setActions([...actions]);
        setIsEdited(true);
    }

    function changeActionActual(e, action) {
        action.actualEndDate = new Date(e.value);
        setActions([...actions]);
        setIsEdited(true);
    }

    function changeActionType(e, action) {
        const items = [...actions];
        const fetchProjectId = projectId ? projectId : 0;
        fetchGet(`${window.constants.processActionModel}/${cardEntity}/${process.type}/${e.value}/${fetchProjectId}`,
            (data) => {
                const index = items.indexOf(action);
                items.splice(index, 1);
                items.unshift(data);
                setActions(items);
                setIsEdited(true);
                setPending(null);
                setError(null);
            },
            () => { });
    }

    function changeProcessObject(e) {
        const selectList = e.value;
        const objectIds = [];
        for (let key in selectList) {
            const objectId = selectList[key];
            const item = getObjectItem(objectId, processObjects);
            if (!item)
                continue;
            objectIds.push({ id: item.parent, linkId: item.id });
        }
        setObject(selectList);
        setObjectIds(objectIds);
        setIsEdited(true);
        setIsMainFieldsChanged(true);
    }

    function getObjectItem(objectId, items) {
        for (let key in items) {
            const item = items[key];
            if (objectId === item.id)
                return item;

            const i = getObjectItem(objectId, item.items);
            if (i)
                return i;
        }
        return null;
    }

    function handleNewAction() {
        const pActions = sources.processActions;
        const typeId = process.type;
        const actionTypeId = pActions && pActions.length > 0 ? pActions[0].id : 0;
        const fetchProjectId = projectId ? projectId : 0;
        fetchGet(`${window.constants.processActionModel}/${cardEntity}/${typeId}/${actionTypeId}/${fetchProjectId}`,
            (data) => {
                setActions([data, ...actions]);
                setIsEdited(true);
                setIsEdit(true);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            });
    }

    function handleAddLinked(item) {
        if (!item) {
            setShowAddLinked(false);
            return;
        }

        const clientType = sources.clientTypes.find((i) => i.id === item.typeId);
        var newClient = {
            id: item.id,
            name: item.name,
            clientType: item.typeId,
            clientTypeName: clientType ? clientType.name : "",
            isNew: true
        };
        setLinkedClients([...linkedClients, newClient]);
        setShowAddLinked(false);
        setIsEdited(true);
        setIsMainFieldsChanged(true);
    }

    function handleAddDocument(docs) {
        if (!docs) {
            setShowAddDocument(false);
            return;
        }
        const items = [];
        for (let key in docs) {
            const doc = docs[key];
            const newDoc = {
                canDelete: false,
                name: doc.name,
                fileName: doc.fileName,
                fileType: "",
                type: "",
                typeId: doc.typeId,
                id: 0,
                size: 0,
                sizeText: "",
                created: new Date()
            };
            items.push(newDoc);
        }

        setDocuments([...documents, ...items]);
        setShowAddDocument(false);
        setIsEdited(true);
    }

    function handleOnDelete(dataItem) {
        if (dataItem.fileType) {
            const dataId = parseInt(dataItem.id);
            if (!isNaN(dataId)) {
                dataItem.itemType = "document";
                setConfirm(window.captions.DeleteItemConfirm);
                setDeleteItem(dataItem);
            }
        } else {
            dataItem.itemType = "linked";
            setConfirm(window.captions.DeleteItemConfirm);
            setDeleteItem(dataItem);
        }
    }

    function handleOnDeleteAction(e, action) {
        var actionId = parseInt(action.id);
        if (!isNaN(actionId)) {
            action.itemType = "action";
            setConfirm(window.captions.DeleteItemConfirm);
            setDeleteItem(action);
        }
    }

    function handleOnDeleteActionDocument(e, document) {
        var documentId = parseInt(document.id);
        if (!isNaN(documentId)) {
            document.itemType = "actionDocument";
            setConfirm(window.captions.DeleteItemConfirm);
            setDeleteItem(document);
        }
    }

    function onCloseDialog() {
        if (!isEdited)
            onClose(false);
        else {
            setConfirm(window.captions.CancelChangesConfirm);
        }
    }

    function isValid(errorFields) {
        if (!name || name.trim() === "")
            errorFields.push(window.captions.Caption);

        if (!type && isAdd)
            errorFields.push(window.captions.WordType);

        if (!executor)
            errorFields.push(window.captions.Executor);

        if (!state)
            errorFields.push(window.captions.State);

        if ((!objectIds || objectIds.length === 0) && isAdd)
            errorFields.push(window.captions.Object);
    }

    function validateActionSets(item) {
        if (!item.fieldsSets || item.fieldsSets.length === 0) {
            return [];
        }
        var setErrors = false;
        var errorFields = [];

        for (let key in item.fieldsSets) {
            const setFields = [];
            if (item.requiredAttachmentDocument) {
                if (item.documents.length < 1) {
                    setErrors = true;
                    setFields.push(<span className="dm-error">{window.captions.ChoseOrAddDocument}</span>);
                } else {
                    setFields.push(<span className="dm-positive">{window.captions.ChoseOrAddDocument}</span>);
                }
            }

            const fieldSet = item.fieldsSets[key];
            if (fieldSet.setRequiredFields.length > 0) {
                for (let fieldKey in item.fields) {
                    const field = item.fields[fieldKey];
                    const requiredField = fieldSet.setRequiredFields.includes(field.id);
                    if (!requiredField)
                        continue;

                    const coma = setFields.length === 0 ? "" : ", ";
                    if (field.value == null || field.value === "") {
                        setErrors = true;
                        setFields.push(<span className="dm-error" key={field.id}>{coma}{field.name}</span>);
                    } else
                        setFields.push(<span className="dm-positive" key={field.id}>{coma}{field.name}</span>);
                }
            }

            if (!setErrors)
                return [];

            errorFields.push(<div key={fieldSet.id}>{fieldSet.name}: {setFields}</div>);
        }
        return errorFields;
    }

    function save() {
        const errorFields = CardSaveFunctions.validateRequired(process.fields, null, {});
        isValid(errorFields);
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        var model = CardSaveFunctions.renderValues(process.fields);
        model.id = process.id;
        model.name = name;
        model.type = isAdd ? type : process.type;
        model.authorId = executor;
        model.state = state;
        model.linkIds = objectIds;
        model.isMainFieldsChanged = isMainFieldsChanged;
        if (model.id > 0) {
            model.actions = [];
            for (let key in actions) {
                const item = actions[key];
                if (item.id !== 0 && !item.updated)
                    continue;

                const itemErrorFields = validateActionSets(item);
                if (itemErrorFields.length > 0) {
                    for (let errorKey in itemErrorFields) {
                        errorFields.push(itemErrorFields[errorKey]);
                    }
                    continue;
                }

                const action = CardSaveFunctions.renderValues(item.fields);
                action.id = item.id;
                action.authorId = item.authorId;
                action.type = item.type;
                action.processState = item.processState;
                action.created = item.created ? new Date(item.created) : null;
                action.expectedEndDate = new Date(item.expectedEndDate);
                action.actualEndDate = item.actualEndDate ? new Date(item.actualEndDate) : null;
                action.documents = item.documents;
                model.actions.push(action);
            }

            if (errorFields.length > 0) {
                setPending(null);
                setValidate(errorFields);
                return;
            }

            model.documents = [];
            for (let key in documents) {
                const doc = documents[key];
                if (doc.id === 0) {
                    model.documents.push({
                        name: doc.name,
                        fileName: doc.fileName,
                        typeId: doc.typeId
                    });
                }
            }
            model.linkedClients = [];
            for (let key in linkedClients) {
                const linked = linkedClients[key];
                if (linked.isNew) {
                    model.linkedClients.push({
                        id: linked.id,
                        typeId: linked.clientType
                    });
                }
            }

            model.deletedLinkedClients = deletedLinkedClients;
            model.deletedActions = deletedActions;
            model.deletedDocuments = deletedDocuments;
            model.deletedActionDocuments = deletedActionDocuments;
            model.isFinished = process.isFinished;
        }

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveProcess}/${cardEntity}/${cardId}`,
            model,
            () => onClose(true),
            () => onClose(false));
    }

    function changeEdit() {
        CardSaveFunctions.changeEditFields(isEdit,
            isEdited,
            process,
            (edit, m) => {
                setIsEdit(edit);
                setProcess(m);
            });
    }

    function confirmOk() {
        if (!deleteItem) {
            confirmClose();
            onClose(false);
            return;
        }

        var type = deleteItem.itemType;
        const deleteItemId = parseInt(deleteItem.id);
        if (type === "action") {
            const acts = [...actions];
            const index = acts.indexOf(deleteItem);
            acts.splice(index, 1);
            if (deleteItemId > 0)
                setDeletedActions([...deletedActions, deleteItemId]);


            setActions(acts);
            setIsEdited(true);
            setConfirm(false);
            setDeleteItem(null);
        } else if (type === "document") {
            const docs = [...documents];
            const index = docs.indexOf(deleteItem);
            docs.splice(index, 1);
            if (deleteItemId > 0)
                setDeletedDocuments([...deletedDocuments, deleteItemId]);

            setDocuments(docs);
            setIsEdited(true);
            setIsMainFieldsChanged(true);
            setConfirm(false);
            setDeleteItem(null);
        } else if (type === "linked") {
            const linkId = parseInt(deleteItem.linkCardId);
            const clients = [...linkedClients];
            const index = clients.indexOf(deleteItem);
            clients.splice(index, 1);
            if (linkId > 0)
                setDeletedLinkedClients([...deletedLinkedClients, linkId]);

            setLinkedClients(clients);
            setIsEdited(true);
            setIsMainFieldsChanged(true);
            setConfirm(false);
            setDeleteItem(null);
        } else if (type === "actionDocument") {
            const actions = [...actions];
            const deleteId = [];
            for (let key in actions) {
                const action = actions[key];
                if (action.documents.find((i) => i.id === deleteItemId)) {
                    action.documents.map((i) => deleteId.push(i));
                    const index = deleteId.indexOf(deleteItem);
                    action.documents.splice(index, 1);
                }
            }
            if (deleteItemId > 0)
                setDeletedActions([...deletedActionDocuments, deleteItemId]);

            setActions(actions);
            setIsEdited(true);
            setConfirm(false);
            setDeleteItem(null);
        } else {
            confirmClose();
        }
    }

    function confirmClose() {
        setConfirm(false);
        setDeleteItem(null);
    }

    function uploadStatusChange(e, attachments, actionId) {
        if (!e.response)
            return;

        var files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;
            attachments.push({
                index: attachments.length,
                fileName: file.fileName,
                name: file.name,
                size: file.size
            });
        }
        const actions = state.actions;
        const action = actions.find((i) => i.id === actionId);
        if (!action)
            return;
        action.documents = attachments;
        action.updated = true;
        setActions(actions);
        setIsEdited(true);
    }
}