import React, { useState, useEffect, useRef } from 'react';
import { CalendarCell, MultiViewCalendar } from '@progress/kendo-react-dateinputs';
import { Dialog } from "@progress/kendo-react-dialogs";
import { formatDate } from "@progress/kendo-intl";
import ErrorMask from "../../components/errorMask.jsx";
import { fetchGet } from "../../utils/requestsHelper.jsx";

export default function DialogCollateralCalendar({ id, isDialog, onClose }) {
    const calendarRef = useRef(null);

    const [items, setItems] = useState([]);
    const [resources, setResources] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(id, calendarRef["focusedDate"]), [id]);

    if (error) {
        return <ErrorMask error={error} />;
    }

    const customCell = (p) => {
        const style = {
            background: "#ffffff",
            color: "#000000",
            borderRadius: "2px"
        };
        const item = itemFinder(p.value, items, resources);
        if (item) {
            style.background = item.color;
            style.color = "#ffffff";
        }

        return <CalendarCell {...p} style={style} />;
    }

    if (isDialog) {
        return <Dialog title={window.captions.MainTabCommonCalendar} onClose={() => onClose()} width={1250}>
            <MultiViewCalendar
                ref={calendarRef}
                views="3"
                cell={customCell} />
        </Dialog>;
    }

    return <div>
        <h5 style={{ marginLeft: 10 }}>{window.captions.MainTabCommonCalendar}</h5>
        <MultiViewCalendar
            ref={calendarRef}
            views="3"
            cell={customCell} />
    </div>;

    function fetchData(id, date) {
        const count = 2;
        const datef = formatDate(new Date(date), "yyyy-MM-dd");
        fetchGet(`${window.constants.cardSourceCollateralScheduler}/${id}/${datef}/${count}`,
            (data) => {
                const items = [];
                for (let key in data.items) {
                    const item = data.items[key];
                    const itemCell = {
                        stateId: item.stateId,
                        start: new Date(item.start),
                        end: new Date(item.end)
                    }
                    items.push(itemCell);
                }
                setItems(items);
                setResources(data.resources);
                setError(null);
            },
            (ex) => setError(ex));
    }

    function itemFinder(date, items, resources) {
        const dt = new Date(date);
        const item = items.find(i => dt >= new Date(i.start.getFullYear(), i.start.getMonth(), i.start.getDate()) &&
            dt <= new Date(i.end.getFullYear(), i.end.getMonth(), i.end.getDate()));
        if (item) {
            const res = resources.find(r => r.numId === item.stateId);
            if (res)
                return res;
            else
                return null;
        } else
            return null;
    }
}
