import React from "react";
import { formatDate } from "@progress/kendo-intl";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { getFormatTime, timeWidth } from "../../../utils/systemConstants.jsx";

export default function TimeControl({ value, field, disabled, changedValueFrom, changedValueTo }) {
    let valueArray = value;
    if (!Array.isArray(valueArray))
        valueArray = [null, null];

    let valueFrom = valueArray[0];
    if (valueFrom !== null) {
        valueFrom = convertTime(valueFrom);
    }

    let valueTo = valueArray[1];
    if (valueTo !== null) {
        valueTo = convertTime(valueTo);
    }

    const fieldId = field.id;
    return [
        <div key={`TimeFrom${fieldId}`} className="dm-filter-range">
            <div>{window.captions.WordFrom}</div>
            <TimePicker id={`TimeFromValue${fieldId}`}
                disabled={disabled}
                width={timeWidth}
                formatPlaceholder={getFormatTime()}
                value={valueFrom}
                onChange={(e) => changedValueFrom({ field: fieldId, value: getTime(e.value) })} />
        </div>,
        <div key={`TimeTo${fieldId}`} className="dm-filter-range">
            <div>{window.captions.WordTo}</div>
            <TimePicker id={`TimeToValue${fieldId}`}
                disabled={disabled}
                width={timeWidth}
                formatPlaceholder={getFormatTime()}
                value={valueTo}
                onChange={(e) => changedValueTo({ field: fieldId, value: getTime(e.value) })} />
        </div>
    ];

    function getTime(dateTime) {
        return dateTime ? formatDate(dateTime, "HH:mm:ss") : null;
    }

    function convertTime(time) {
        let result = new Date(`2000-01-01T${time}`);
        if (isNaN(result))
            result = new Date(time);

        if (isNaN(result))
            return null;
        return result;
    }
}