import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import GridContactInfo from "../../components/cells/gridContactInfo.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import DialogAddLinkedClient from "./dialogAddLinkedClient.jsx";
import MessageMask from "../../components/messageMask.jsx";
import { entitySourcesNames } from "../../utils/systemConstants.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogCollateral({ collateralId, cardEntity, cardId, projectId, onClose }) {
    const isAdd = collateralId === 0;

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteId, setDeleteId] = useState(0);

    const [selectedTab, setSelectedTab] = useState(0);
    const [isEdit, setIsEdit] = useState(isAdd);
    const [isEdited, setIsEdited] = useState(false);

    const [collateral, setCollateral] = useState(null);
    const [type, seTtype] = useState(null);
    const [state, setState] = useState(null);
    const [author, setAuthor] = useState({ id: 0, name: "" });
    const [sources, setSources] = useState(null);
    const [owners, setOwners] = useState([]);

    const [deletedOwners, setDeletedOwners] = useState([]);
    const [showAddOwner, setShowAddOwner] = useState(false);

    const access = useSelector(state => state.header.model.access);

    useEffect(() => fetchData(0), []);

    let title = isAdd ? window.captionsDynamic.AddCollateral : window.captionsDynamic.Collateral;
    if (pending || error) {
        return <Dialog title={title} onClose={onCloseDialog} width={550}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const canAddOwner = access.addingOwners;
    const deleteOwners = access.deleteOwners;

    if (showAddOwner) {
        return <DialogAddLinkedClient cardId={0}
            cardEntity={entitySourcesNames.client}
            canAdd={true}
            isChoiceClient={true}
            canSelectType={false}
            onClose={(data) => handleAddOwner(data)} />;
    }

    let editViewButton = null;
    if (true)
        editViewButton =
            <Button iconClass={`dm-i dm-i-${(isEdit ? "eye" : "pen")}`} onClick={changeEdit}>{isEdit
                ? window.captions.View
                : window.captions.EditMode
            }</Button>;

    let saveButton = null;
    if (isEdited || isAdd) {
        saveButton = <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Save}</Button>;
    }

    let modal = null;
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    const collateralInfo = [];
    var selectedType = sources.collateralTypes.find((i) => i.id === type);
    var selectedState = sources.states.find((i) => i.id === state);
    renderPair(collateralInfo,
        window.captionsDynamic.CollateralType,
        "types",
        () => <DropDownList style={{ width: "100%" }}
            data={sources.collateralTypes}
            textField="name"
            dataItemKey="id"
            value={selectedType}
            onChange={changeType} />,
        selectedType ? selectedType.name : null,
        isEdit);

    renderPair(collateralInfo,
        window.captions.State,
        "state",
        () => <DropDownList style={{ width: "100%" }}
            data={sources.states}
            textField="name"
            dataItemKey="id"
            value={selectedState}
            onChange={changeState} />,
        selectedState ? selectedState.name : null,
        isEdit);

    renderPair(collateralInfo,
        window.captions.Executor,
        "executors",
        () => <DropDownTreeView style={{ width: "100%" }}
            selectedId={author.id}
            treeData={sources.executors}
            selectionChanged={changeAuthor} />,
        author.name,
        isEdit && sources.executors.length > 0);

    const fieldsPanel = collateral.fields && Object.keys(collateral.fields).length > 0
        ? <FieldsControl id={collateral.id}
            entity={entitySourcesNames.collateral}
            fields={collateral.fields}
            sources={sources}
            isEditMode={isEdit}
            setFields={setEntityFields} />
        : null;

    const customFieldsPanel = collateral.customFields && Object.keys(collateral.customFields).length > 0
        ? <FieldsControl id={collateral.id}
            entity={entitySourcesNames.collateralAddons}
            fields={collateral.customFields}
            sources={sources}
            isEditMode={isEdit}
            setFields={setEntityFields} />
        : null;

    const closeTitle = isEdited ? window.captions.Cancel : window.captions.Close;

    var renderButton = (canAdd, handler, name) => {
        return canAdd
            ? <Button className="dm-positive-filled" onClick={handler}>{name}</Button>
            : null;
    };

    var tab = selectedTab;
    var actionsButton;
    if (tab === 1) {
        actionsButton = renderButton(canAddOwner, () => setShowAddOwner(true), window.captions.Add);
    }

    return [
        <Dialog title={title}
            key="dialogCollateral"
            onClose={onCloseDialog} width={950}
            className="k-dialog-no-padding">
            <TabStrip selected={tab} onSelect={handleSelectTab}>
                <TabStripTab title={window.captionsDynamic.Collaterals}>
                    <div className="dm-container dm-no-padding">
                        {collateralInfo}
                    </div>
                </TabStripTab>
                <TabStripTab title={window.captionsDynamic.CollateralOwner}>
                    <Grid filterable={false}
                        sortable={true}
                        pageable={false}
                        data={owners}
                        total={owners ? owners.length : 0}
                        className="dm-full-wh">
                        <GridColumn field="name" title={window.captions.Caption} width="240" />
                        <GridColumn field="id" title={window.captions.MainTabCardContact}
                            cells={{ data: (p) => <GridContactInfo {...p} sources={sources} /> }} />
                        {deleteOwners ? <GridColumn field="id" title=" " width="35" cells={{ data: removeCell }} /> : null}
                    </Grid>
                </TabStripTab>
                {fieldsPanel
                    ? <TabStripTab title={window.captionsDynamic.DescrCollateralDog}>
                        <div className="dm-container dm-no-padding">
                            {fieldsPanel}
                        </div>
                    </TabStripTab>
                    : null}
                {customFieldsPanel
                    ? <TabStripTab title={window.captionsDynamic.CollateralDescr}>
                        <div className="dm-container dm-no-padding">
                            {customFieldsPanel}
                        </div>
                    </TabStripTab>
                    : null}
            </TabStrip>
            <DialogActionsBar>
                <Button onClick={onCloseDialog}>{closeTitle}</Button>
                {editViewButton}
                {saveButton}
                {actionsButton}
            </DialogActionsBar>
        </Dialog>,
        modal,
        <ConfirmMessage key="confirmDialog"
            yes={confirmOk}
            no={confirmClose}
            text={confirm} />
    ];

    function fetchData(overrideType) {
        const fetchProjectId = projectId ? projectId : 0;
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getCollateralModel}/${cardEntity}/${collateralId}/${fetchProjectId}/${overrideType}`,
            (data) => {
                if (!data) {
                    setPending(null);
                    setError(window.captions.WordError);
                    return;
                }

                setCollateral(data);
                setAuthor({ id: data.authorId, name: data.authorName });
                seTtype(data.type);
                setState(data.state);
                setSources(data.sources);
                setOwners(data.owners);

                setIsEdited(overrideType > 0);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function removeCell(p) {
        if (isEdit)
            return <td {...getLinkCellStyle(p)}>
                <Button icon="delete" onClick={() => handleOnDelete(p.dataItem)} />
            </td>;
        else
            return <td {...getLinkCellStyle(p)} />;
    };

    function setEntityFields(fields, id, entity) {
        if (entity === entitySourcesNames.collateral) {
            setCollateral({ ...collateral, fields });
            setIsEdited(true);
        } else if (entity === entitySourcesNames.collateralAddons) {
            setCollateral({ ...collateral, customFields: fields });
            setIsEdited(true);
        }
    }

    function handleSelectTab(e) {
        setSelectedTab(e.selected);
    }

    function changeType(e) {
        fetchData(e.target.value.id);
    }

    function changeAuthor(e) {
        setAuthorId(e.value);
        setAuthorName(e.valueText);
        setIsEdited(true);
    }

    function changeState(e) {
        setState(e.target.value.id);
        setIsEdited(true);
    }

    function changeEdit() {
        CardSaveFunctions.changeEditFields(isEdit,
            isEdited,
            collateral,
            (edit, m) => {
                setIsEdit(edit);
                setCollateral(m);
            });

        CardSaveFunctions.changeEditFields(isEdit,
            isEdited,
            collateral,
            (edit, m) => {
                setIsEdit(edit);
                setCollateral(m);
            },
            collateral.customFields);
    }

    function handleAddOwner(item) {
        if (!item) {
            setShowAddOwner(false);
            return;
        }

        var newClient = {
            id: item.id,
            name: item.name,
            isNew: true
        };

        setShowAddOwner(false);
        var exist = owners.find((c) => c.id === item.id);
        if (exist) {
            setValidate(window.captions.AddLinkedClientsError);
            return;
        }

        setOwners({ ...owners, newClient });
        setIsEdited(true);
    }

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem.linkCardId);
        if (!isNaN(id)) {
            setConfirm(window.captions.DeleteItemConfirm);
            setDeleteItem(dataItem);
            setDeleteId(id);
        }
    }

    function onCloseDialog() {
        if (!isEdited)
            onClose(false);
        else {
            setConfirm(window.captions.CancelChangesConfirm);
            setDeleteId(0);
        }
    }

    function isValid(errorFields) {
        if (!type && isAdd)
            errorFields.push(window.captions.WordType);

        if (!author.id)
            errorFields.push(window.captions.Executor);
    }

    function save() {
        var errorFields = CardSaveFunctions.validateRequired(collateral.fields, null, {});
        isValid(errorFields);
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        errorFields = CardSaveFunctions.validateRequired(collateral.customFields, null, {});
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        var model = CardSaveFunctions.renderValues(collateral.fields);
        CardSaveFunctions.renderValues(collateral.customFields, model);
        model.id = collateral.id;
        model.type = type;
        model.state = state;
        model.isArchived = collateral.isArchived;
        model.authorId = author.id;
        model.owners = [];
        for (let key in owners) {
            const client = owners[key];
            if (client.isNew) {
                model.owners.push({
                    id: client.id
                });
            }
        }

        model.deletedOwners = deletedOwners;
        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.saveCollateral}/${cardEntity}/${cardId}`,
            model,
            () => onClose(true),
            () => onClose(false));
    }

    function confirmOk() {
        var dataItem = deleteItem;
        var id = deleteId;
        confirmClose();
        if (id === 0) {
            onClose(false);
            return;
        }

        const clients = [...owners];
        const index = clients.indexOf(dataItem);
        clients.splice(index, 1);

        setOwners(clients);
        setDeletedOwners([...deletedOwners, id])
        setIsEdited(true);
    }

    function confirmClose() {
        setConfirm(false);
        setDeleteItem(null);
        setDeleteId(0);
    }

}