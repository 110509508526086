export default {
    convertGroup(data) {
        //const items = [];
        //for (let key in data) {
        //    const item = data[key];
        //    const chat = {
        //        id: item.id,
        //        name: item.name,
        //        authorId: item.authorId,
        //        isLiveFeedMode: item.isLiveFeedMode,
        //        isUpdated: item.isUpdated,
        //        isDeleted: item.isDeleted,
        //        allowedUsers: item.allowedUsers,
        //        badgeCount: 0
        //    };
        //    items.push(chat);
        //}

        return data;
    },

    convertRule(data) {
        //const items = [];
        //for (let key in data) {
        //    const item = data[key];
        //    const chat = {
        //        id: item.id,
        //        name: item.name,
        //        authorId: item.authorId,
        //        isLiveFeedMode: item.isLiveFeedMode,
        //        isUpdated: item.isUpdated,
        //        isDeleted: item.isDeleted,
        //        allowedUsers: item.allowedUsers,
        //        badgeCount: 0
        //    };
        //    items.push(chat);
        //}
        return data;
    },

    convertUser(data) {
        //const items = [];
        //for (let key in data) {
        //    const item = data[key];
        //    const message = {
        //        id: item.id,
        //        chatId: item.chatId,
        //        author: { id: item.authorId, name: item.authorName },
        //        timestamp: new Date(item.created),
        //        isUpdated: item.isUpdated,
        //        isDeleted: item.isDeleted,
        //        isImportant: item.isImportant,
        //        titleImage: item.titleImage,
        //        titleImageValue: item.titleImageValue,
        //        type: item.type,
        //        title: item.title,
        //        text: item.body,
        //        quote: item.quoteModel,
        //        attachmentsList: item.attachments.map((x) => {
        //            return {
        //                id: x.id,
        //                name: x.name,
        //                authorId: x.authorId,
        //                created: x.created,
        //                fileName: x.fileName,
        //                type: x.type,
        //                size: x.size,
        //                sizeName: getFileSizeName(x.size)
        //            };
        //        }),
        //        comments: item.comments,
        //        meetingModel: item.meetingModel
        //    };
        //    items.push(message);
        //}

        return data;
    },

    getNewGroup(groups, roles) {
        var roleId = roles[0]
            .id;
        if (roles && roles.length > 1) {
            roleId = roles.find(r => !r.isSup)
                ?.id ?? 0;
        }

        // langItem: null,
        return {
            id: 0,
            name: this.getNewName(groups, window.captions.NewGroup),
            color: '#FFFFFF',
            rule: roleId,
            parentId: null,
            taskGroupField: 0,
            taskGroupRanges: '',
            canBeExternalUser: false,
            participationInPlanning: false,
            bindedWindowsGroupSid: null,
            Comments: '',
            users: [],
            deletedUsers: [],
            langItem: {}
        };
    },

    getNewUser(users, parentGroup) {
        return {
            id: 0,
            name: this.getNewName(users, window.captions.NewUser),
            login: this.getNewName(users, "user", 'login'),
            groupId: parentGroup.id,
            rule: parentGroup.rule,
            created: new Date(Date.now()),
            deleted: null,
            password: '',
            isGroupRule: true,
            ipPhoneCode: '',
            ipPhonePassword: '',
            email: '',
            emailPassword: '',
            isDenyAccess: false,
            isReadonly: false,
            isBlocked: false,
            comments: '',
            photo: '',
            city: '',
            workAddress: '',
            phoneMobile: '',
            phoneWork: '',
            phoneInternal: '',
            softPhoneTypeId: 0,
            cardViewStage: 0,
            isGuest: false,
            linkedUsers: [],
            regions: [],
            documents: [],
            removedDocuments: [],
            isBusinessAdministrator: false,
            isCreatedByApi: false,
            deputy: null,
            fields: [],
            sources: null,
            transferResponsible: null,
            values: [],
            nullValues: []
        };
    },

    getNewRule(rules, baseOn) {
        // langItem: null,
        if (baseOn == null) {
            return {
                id: 0,
                name: this.getNewName(rules, window.captions.NewElement),
                langItem: {},

                windowsGroupSelectorType: null,
                windowsUserFields: [],
                windowsUserFieldsCondition: null,
                countSearch: 0,
                fieldsAccess: [],
                priveliges: [],
                accessGlobal: null,
                clientAccessGlobal: null,
                accessByClientsTypes: [],
                accessProject: [],
                accessStages: [],
                accessStrategy: [],
                accessDictionaries: [],
                linkedRoles: [],
                filterForm: [],
                filterResult: [],
                calculatedUserFields: [],
                stagesForAction: [],
                userStates: [],
                userAvailableStates: [],
                historyActionsHiddenView: [],
                historyActionsHiddenAdd: [],
                licenseNotificationDays: 0,
                bindedWindowsGroupSid: null,
                phonesTypes: [],
                addressesTypes: [],
                emailsTypes: [],
                urlsTypes: [],
                phoneStates: [],
                addressStates: [],
                emailStates: [],
                urlStates: [],
                tasks: [],
                documentsTypes: [],
                fullHistoryFilters: [],
                maskConfig: null,
                linkedGroups: [],
            };
        }

        return {
            ...baseOn,
            id: 0,
            name: this.getNewName(rules, baseOn.name),
            langItem: {},
            linkedGroups: [],
            linkedRoles: [],
        };
    },

    getNewName(items, newName, nameField = 'name') {
        if (!items)
            return newName;

        let index = 1;
        let name = newName;
        while (items.some(x => x[nameField] == name)) {
            index++;
            name = `${newName} ${index}`;
        }

        return name;
    },

    getDuplicateNameInfo(list) {
        var duplecateNames = !list || list.length == 0 ? '' : list.join(', ')
        return `${window.captions.MessageBoxDuplicateNameDeny}\r\n${duplecateNames}`;
    }
}