import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import MultilineTextArea from "../../components/controls/multilineTextArea.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import MessageMask from "../../components/messageMask.jsx";
import { getFormatDateTime, dateTimeWidth } from "../../utils/systemConstants.jsx";

export default function DialogTaskMenuAction({ actionType, data, users, onClose }) {
    const [sources, setSources] = useState(null);
    const [fields, setFields] = useState([]);
    const [fieldsToChange, setFieldsToChange] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [value, setValue] = useState(null);
    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (actionType === "changeField")
            fetchData();
        else
            setPending(null);
        setError(null);
    }, []);

    let width = 550;
    if (pending || error) {
        return <Dialog title={window.captions.SetFieldValue}
            onClose={() => onClose(false)} width={width}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    let title = "";
    let dialogContent = "";
    switch (actionType) {
        case "changeExecutor":
            title = window.captions.ChangeExecutor;
            dialogContent = <DropDownTreeView
                treeData={users}
                selectedId={value}
                selectionChanged={changeValue}
                style={{ width: "100%" }} />;
            break;
        case "changeState":
            title = window.captions.RuleChangeState;
            var states = [
                { id: 1, name: window.captions.NotActual },
                { id: 2, name: window.captions.Implemented }
            ];
            var selectedState = states.find((i) => i.id === value);
            dialogContent = <DropDownList style={{ width: "100%" }}
                textField="name"
                dataItemKey="id"
                data={states}
                value={selectedState}
                onChange={changeValue} />;
            break;
        case "changeComment":
            title = window.captions.ChangeComment;
            dialogContent = <MultilineTextArea changeText={changeValue} text={value} />;
            break;
        case "changeRanges":
            title = window.captions.ChangeRanges;
            var dates = data.map((i) => new Date(i.Started));
            var min = new Date(Math.max.apply(null, dates));
            var max = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
            if (data.length === 1 && data[0].RangeLimitInterval !== 0) {
                max = min;
                const item = data[0];
                switch (item.RangeLimitType) {
                    case 0:
                        max.setSeconds(max.getSeconds() + item.RangeLimitInterval);
                        break;
                    case 1:
                        max.setMinutes(max.getMinutes() + item.RangeLimitInterval);
                        break;
                    case 2:
                        max.setHours(max.getHours() + item.RangeLimitInterval);
                        break;
                    case 3:
                        max.setDate(max.getDate() + item.RangeLimitInterval);
                        break;
                    case 4:
                        max.setMonth(max.getMonth() + item.RangeLimitInterval);
                        break;
                    case 5:
                        max.setFullYear(max.getFullYear() + item.RangeLimitInterval);
                        break;
                }
            }
            dialogContent =
                <DateTimePicker
                    onChange={changeValue}
                    min={min}
                    max={max} value={value}
                    width={dateTimeWidth}
                    formatPlaceholder={getFormatDateTime()} />;
            break;
        case "changeStarted":
            title = window.captions.ChangeStarted;
            var startDates = data.map((i) => new Date(i.Range));
            var startMax = startDates && startDates.length > 0
                ? new Date(Math.min.apply(null, startDates))
                : new Date(new Date().setFullYear(new Date().getFullYear() + 10));
            dialogContent = <DateTimePicker
                onChange={changeValue}
                min={new Date()}
                max={startMax} value={value}
                width={dateTimeWidth}
                formatPlaceholder={getFormatDateTime()} />;
            break;
        case "changeField":
            title = window.captions.SetFieldValue;
            var field = "";
            if (selectedField) {
                field = <FieldsControl id={0}
                    entity="task"
                    fields={fieldsToChange}
                    sources={sources}
                    isEditMode={true}
                    setFields={setEntityFields} />;
            }
            dialogContent = <div>
                <DropDownList style={{ width: "100%" }}
                    textField="name"
                    dataItemKey="id"
                    data={Object.values(fields)}
                    value={selectedField}
                    onChange={changeSelectedField} />
                <div className="dm-container dm-no-padding">
                    {field}
                </div>
            </div>;
            break;
    }

    let selectButton = "";
    if (value) {
        selectButton = <Button themeColor="primary"
            onClick={() => save(value)}>
            {window.captions.Save}
        </Button>;
    }

    return <Dialog title={title} onClose={() => onClose(false)} width={width}>
        {dialogContent}
        <DialogActionsBar>
            <Button onClick={() => onClose(false)}>{window.captions.Cancel}</Button>
            {selectButton}
        </DialogActionsBar>
    </Dialog>;

    function fetchData() {
        fetchGet(window.constants.tasksFields,
            (response) => {
                setSources(response.sources);
                setFields(response.fields);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function changeValue(e) {
        setValue(actionType === "changeState" ? e.target.value.id : e.value);
    }

    function changeSelectedField(e) {
        const fieldsToChange = [];
        var field = e.value;
        field.value = null;
        fieldsToChange[field.modelName] = field;
        setFieldsToChange(fieldsToChange);
        setSelectedField(e.target.value);
    }

    function setEntityFields(fields) {
        const field = fields[selectedField.modelName];
        setValue(field.value);
    }

    function save() {
        if (!value) {
            onClose(false);
            return;
        }

        const saveModel = {
            selectedTasks: data.map((i) => i.Id),
            actionType: actionType,
            fieldId: selectedField ? selectedField.id : 0,
            value: value
        };

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(window.constants.taskSaveChanges,
            saveModel,
            () => onClose(true),
            () => onClose(false));
    }
}