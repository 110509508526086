import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { useInternationalization } from "@progress/kendo-react-intl";
import { formatDate } from "@progress/kendo-intl";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Link } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Skeleton } from "@progress/kendo-react-indicators";
import InlineDialog from "../../components/inlineDialog.jsx";
import MessageMask from "../../components/messageMask.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import DropDownCheckedTree from "../../components/controls/dropDownCheckedTree.jsx";
import { fetchGet, fetchPost, headersFile } from "../../utils/requestsHelper.jsx";
import { maxAllowedDate, dateTimeFormat, parseDateTimeFormat, getFormatDateTime, dateTimeWidth } from
    "../../utils/systemConstants.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import FileHelper from "../../utils/fileHelper.jsx";
import { getUiCulture } from "../../utils/authHelper.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import ConfirmMessage from "../../components/confirmMessage.jsx";
import { entitySourcesNames } from "../../utils/systemConstants.jsx";

export default function DialogTask({ inline, taskId, projectId, isMeeting, cardEntity, cardId, onClose }) {
    const intlService = useInternationalization();

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [users, setUsers] = useState([]);
    const [types, setTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [sources, setSources] = useState({});
    const [confirm, setConfirm] = useState(false);

    const [fields, setFields] = useState({});
    const [executorId, setExecutorId] = useState(0);
    const [typeId, setTypeId] = useState(0);
    const [stateId, setStateId] = useState(0);

    const [names, setNames] = useState({
        executor: "",
        author: "",
        type: "",
        state: ""
    });

    const [canAddVenue, setCanAddVenue] = useState(false);

    const [canEdit, setCanEdit] = useState({
        attachments: false,
        participants: false,
        executor: false,
        state: false,
        started: false,
        range: false,
        comments: false,
        fields: false
    });

    const [isChanged, setIsChanged] = useState({
        executor: false,
        state: false,
        started: false,
        range: false,
        fields: false,
        comments: false
    });

    const [created, setCreated] = useState(null);
    const [started, setStarted] = useState(new Date());
    const [range, setRange] = useState(null);
    const [finished, setFinished] = useState(null);
    const [rangeMax, setRangeMax] = useState(maxAllowedDate);
    const [comment, setComment] = useState("");
    const [venue, setVenue] = useState("");

    const [linkType, setLinkType] = useState("");
    const [linkId, setLinkId] = useState(0);

    const [participantList, setParticipantList] = useState([]);
    const [attachments, setAttachments] = useState([]);

    const [isRangeRequired, setIsRangeRequired] = useState(false);
    const [isRangeFixed, setIsRangeFixed] = useState(false);

    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => fetchData(taskId, projectId), [taskId]);

    let title = taskId === 0 ? window.captions.NewTask : window.captions.Task;
    if (pending || error) {
        var message = <MessageMask inline text={pending} error={error} />;
        if (inline && pending === window.captions.LoadingData)
            message = getSkeleton();

        return inline
            ? <InlineDialog title={title} onClose={() => onCloseDialog()}>
                {message}
            </InlineDialog>
            : <Dialog title={title} onClose={() => onCloseDialog()} width={550}>
                {message}
            </Dialog>;
    }

    const elements = [];

    var isNew = taskId === 0;
    renderPair(elements,
        window.captions.WordType,
        "types",
        () => <DropDownTreeView
            id="TaskDialogType"
            treeData={types}
            selectedId={typeId}
            selectionChanged={changeType}
            style={{ width: "100%" }} />,
        names.type,
        isNew);

    if (!isNew) {
        renderPair(elements, window.captions.Author, "author", null, names.author, false);
    }

    renderPair(elements,
        window.captions.TaskExecutor,
        "executor",
        () => <DropDownTreeView
            id="TaskDialogUser"
            treeData={users}
            selectedId={executorId}
            selectionChanged={changeUser}
            style={{ width: "100%" }} />,
        names.executor,
        canEdit.executor && users.length > 0);

    if (participants.length > 0 && (canEdit.participants || participantList.length > 0)) {
        renderPair(elements,
            window.captions.Participants,
            "participant",
            () => <DropDownCheckedTree
                id="TaskDialogParticipant"
                disabled={!canEdit.participants}
                treeData={participants}
                selectedIdList={participantList}
                selectionChanged={changeParticipants}
                style={{ width: "100%" }} />);
    }

    if (!isNew) {
        var selectedState = states.find((i) => i.id === stateId);
        renderPair(elements,
            window.captions.State,
            "state",
            () => <DropDownList style={{ width: "100%" }}
                textField="name"
                dataItemKey="id"
                data={states}
                value={selectedState}
                onChange={changeState} />,
            names.state,
            canEdit.state);

        renderPair(elements,
            window.captions.DateCreated,
            "created",
            null,
            formatDate(created, dateTimeFormat, getUiCulture()),
            false);
    }

    renderPair(elements,
        window.captions.StartDate,
        "started",
        () => <DateTimePicker
            onChange={changeStart}
            min={started ? started : new Date()}
            value={started}
            width={dateTimeWidth}
            formatPlaceholder={getFormatDateTime()} />,
        started ? formatDate(started, dateTimeFormat, getUiCulture()) : null,
        canEdit.started);

    renderPair(elements,
        window.captions.RangeDate,
        "range",
        () => <DateTimePicker
            onChange={changeRange}
            disabled={isRangeFixed}
            min={started}
            max={rangeMax} value={range}
            width={dateTimeWidth}
            formatPlaceholder={getFormatDateTime()} />,
        range ? formatDate(range, dateTimeFormat, getUiCulture()) : null,
        canEdit.range);

    if (finished) {
        renderPair(elements,
            window.captions.Finished,
            "finished",
            null,
            formatDate(finished, dateTimeFormat, getUiCulture()),
            false);
    }

    renderPair(elements,
        window.captions.Venue,
        "venue",
        () => <TextArea onChange={changeVenue}
            value={venue}
            rows={2}
            style={{ width: "100%" }} />,
        venue,
        canAddVenue);

    renderPair(elements,
        window.captions.Comment,
        "comment",
        () => <TextArea onChange={changeComment}
            value={comment}
            rows={4}
            style={{ width: "100%" }} />,
        comment
            ? <div className="dm-text-wrap">
                <i className={`dm-i dm-i-comment-alt`} /> {comment}
            </div>
            : null,
        canEdit.comments);

    if (fields && Object.keys(fields).length > 0) {
        const canEditFields = canEdit.fields;
        elements.push(<FieldsControl id={taskId}
            key="taskFields"
            fields={fields}
            sources={sources}
            isEditMode={canEditFields}
            setFields={setEntityFields} />);
    }

    if (!isNew) {
        if (attachments && attachments.length > 0) {
            var attachmentsPanel = [];
            attachments.forEach((item) => {
                attachmentsPanel.push(
                    <Button themeColor="primary" key={`attachment${item.id}`} title={item.name} onClick={() =>
                        FileHelper
                            .getDocument(item.id)}>
                        <span className="dm-icon">
                            <FileIcon extension={item.fileType} {...defaultStyles[
                                item.fileType]} />
                        </span>
                        {item.sizeText}
                    </Button>);
            });

            elements.push(<div key="attachmentData" className="dm-size-100">{attachmentsPanel}</div>);
        }
    } else if (canEdit.attachments) {
        elements.push(<div key="attachmentData" className="dm-size-100">
            <Upload
                batch={false}
                multiple={true}
                defaultFiles={[]}
                onStatusChange={uploadStatusChange}
                saveHeaders={headersFile()}
                removeHeaders={headersFile()}
                saveUrl={window.constants.fileUpload}
                removeUrl={window.constants.fileRemove} />
        </div>);
    }

    var closeTitle = isEdited ? window.captions.Cancel : window.captions.Close;
    let linkButton = null;
    let saveButton = null;
    if (linkType && linkId !== cardId) {
        const href = `/card/${linkType}/${linkId}`;
        linkButton =
            <Link to={href}
                className="k-button k-button-md k-button-rectangle k-button-solid k-rounded-md k-button-solid-primary dm-white"
                onClick={() => onCloseDialog()}>
                {window.captions.OpenCard}
            </Link>;
    }

    if (isEdited) {
        saveButton =
            <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Save
            }</Button>;
    }

    let modal = null;
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    if (confirm) {
        modal = <ConfirmMessage key="confirmDialog"
            yes={confirmOk}
            no={() => setConfirm(false)}
            text={confirm} />;
    }

    var dialogActions = <DialogActionsBar>
        <Button onClick={onCloseDialog}>{closeTitle}</Button>
        {saveButton}
        {linkButton}
    </DialogActionsBar>;

    return inline
        ? [
            <InlineDialog title={title}
                key="dialogTask"
                buttons={dialogActions}
                onClose={onCloseDialog}>
                <div className="dm-container dm-container-slim">
                    {elements}
                </div>
            </InlineDialog>,
            modal
        ]
        : [
            <Dialog title={title} key="dialogTask" onClose={onCloseDialog} width={550}>
                <div className="dm-container dm-no-padding">
                    {elements}
                </div>
                {dialogActions}
            </Dialog>,
            modal
        ];

    function fetchData(taskId, projectId) {
        setPending(window.captions.LoadingData);
        setError(null);

        const uri = isMeeting
            ? window.constants.getTaskMeetingModel
            : `${window.constants.getTaskModel}/${cardEntity}/${taskId}/${projectId}`;
        fetchGet(uri,
            (data) => {
                setUsers(isMeeting ? data.users.filter((i) => i.id > 0) : data.users);
                setTypes(data.types);
                setStates(data.states);
                setSources(data.sources);
                setParticipants(data.participants ? data.participants : []);

                setFields(data.fields);
                setExecutorId(data.executorId);
                setTypeId(data.typeId);
                setStateId(data.stateId);

                setNames({
                    executor: data.executorName,
                    author: data.authorName,
                    type: data.typeName,
                    state: data.stateName
                });
                setCreated(data.created ? intlService.parseDate(new Date(data.created), parseDateTimeFormat) : null);
                setStarted(data.started ? intlService.parseDate(new Date(data.started), parseDateTimeFormat) : new Date());
                setRange(data.range ? intlService.parseDate(new Date(data.range), parseDateTimeFormat) : null);
                setFinished(data.finished ? intlService.parseDate(new Date(data.finished), parseDateTimeFormat) : null);
                setComment(data.comment ? data.comment : "");
                setVenue(data.venue ? data.venue : "");

                setLinkType(data.linkType);
                setLinkId(data.linkId);

                setCanEdit({
                    attachments: data.canAddAttachments,
                    participants: taskId === 0,
                    executor: data.canEditExecutor || taskId === 0,
                    state: data.canEditState || taskId === 0,
                    started: data.canEditStarted || taskId === 0,
                    range: data.canEditRanges || taskId === 0,
                    comments: data.canEditComments || taskId === 0,
                    fields: data.canEditFields || taskId === 0
                });

                setParticipantList(data.participantList ? data.participantList : []);
                setAttachments(data.attachments ? data.attachments : []);
                setPending(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function changeParticipants(e) {
        setParticipantList(e.value);
        setIsEdited(true);
    }

    function uploadStatusChange(e) {
        if (!e.response)
            return;

        var items = [];
        var length = attachments.length;
        var files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;
            items.push({
                index: length + items.length,
                fileName: file.fileName,
                name: file.name,
                size: file.size
            });
        }

        setAttachments([...attachments, ...items]);
        setIsEdited(true);
    }

    function changeUser(e) {
        setExecutorId(e.value);
        setIsEdited(true);
        setIsChanged({ ...isChanged, executor: true });
    }

    function changeState(e) {
        setStateId(e.target.value.id);
        setIsEdited(true);
        setIsChanged({ ...isChanged, state: true });
    }

    function changeType(e) {
        var taskTypeId = e.value;
        if (typeId === taskTypeId)
            return;

        if (taskTypeId === 0) {
            setTypeId(taskTypeId);
            setParticipants([]);
            setFields({});
            setRange(null);
            setCanAddVenue(false);
            setRangeMax(maxAllowedDate);
            setIsRangeRequired(false);
            setIsRangeFixed(false);
            setIsEdited(true);
            return;
        }

        fetchGet(`${window.constants.getTaskType}/${taskTypeId}`,
            (data) => {
                var rangeDate = data.rangeDate
                    ? intlService.parseDate(new Date(data.rangeDate), parseDateTimeFormat)
                    : null;
                var rangeMaxDate = data.rangeMaxDate
                    ? intlService.parseDate(new Date(data.rangeMaxDate), parseDateTimeFormat)
                    : maxAllowedDate;

                setTypeId(taskTypeId);
                setParticipants(data.users ? data.users : []);
                setFields(data.fields);
                setRange(rangeDate);
                setRangeMax(rangeMaxDate);
                setIsRangeRequired(data.isRangeRequired);
                setIsRangeFixed(data.isRangeFixed);
                setIsEdited(true);
                setCanAddVenue(data.canAddVenue);
                setError(null);
            },
            (ex) => {
                setError(ex);
                setPending(null);
            });
    }

    function changeStart(e) {
        setStarted(e.value);
        setIsEdited(true);
        setIsChanged({ ...isChanged, started: true });
    }

    function changeRange(e) {
        setRange(e.value);
        setIsEdited(true);
        setIsChanged({ ...isChanged, range: true });
    }

    function changeComment(e) {
        setComment(e.value);
        setIsEdited(true);
        setIsChanged({ ...isChanged, comments: true });
    }

    function changeVenue(e) {
        setVenue(e.value);
        setIsEdited(true);
    }

    function setEntityFields(fields) {
        setFields(fields);
        setIsEdited(true);
        setIsChanged({ ...isChanged, fields: true });
    }

    function isValid(errorFields) {
        if (isRangeRequired && !range)
            errorFields.push(window.captions.SpecifyExecutionDeadline);

        if (!executorId)
            errorFields.push(window.captions.Executor);

        if (!typeId)
            errorFields.push(window.captions.WordType);

        if (!started)
            errorFields.push(window.captions.BeginDate);
    }

    function save() {
        const errorFields = CardSaveFunctions.validateRequired(fields, null, {});
        isValid(errorFields);
        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        var saveData = CardSaveFunctions.renderValues(fields);
        saveData.typeId = typeId;
        saveData.executorId = executorId;
        saveData.participantList = participantList;
        saveData.started = started;
        saveData.stateId = stateId;
        saveData.range = range;
        saveData.comment = comment;
        saveData.venue = venue;
        saveData.attachments = attachments;

        saveData.id = taskId;
        saveData.IsChangedExecutor = isChanged.executor;
        saveData.IsChangedState = isChanged.state;
        saveData.IsChangedStarted = isChanged.started;
        saveData.IsChangedRange = isChanged.range;
        saveData.IsChangedFields = isChanged.fields;
        saveData.IsChangedComments = isChanged.comments;

        if (isMeeting) {
            onClose(saveData);
        } else {
            setPending(window.captions.SavingData);
            setError(null);
            fetchPost(`${window.constants.saveTask}/${cardEntity}/${cardId}`,
                saveData,
                (saveId) => {
                    setPending(null);
                    if (saveId === -2) {
                        setValidate(window.captions.UniqueTaskRangeDateNullWarning);
                    } else if (saveId < 0 && cardEntity !== entitySourcesNames.users) {
                        setValidate(window.captions.SaveDataError);
                    } else if (saveId === 0) {
                        setValidate(window.captions.UniqueTaskWarningDate);
                    } else {
                        onClose(true);
                    }
                },
                () => onClose(false));
        }
    }

    function onCloseDialog() {
        if (!isEdited)
            onClose(false);
        else {
            setConfirm(window.captions.CancelChangesConfirm);
        }
    }

    function confirmOk() {
        setConfirm(false);
        onClose(false);
    }

    function getSkeleton() {
        return <div style={{ padding: "0 16px" }}>
            <Skeleton shape={"text"} style={{ width: "25px", margin: "10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "100%", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "80px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "100%", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "110px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "225px", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "110px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "225px", height: "40px" }} />
            <Skeleton shape={"text"} style={{ width: "110px", margin: "20px 0 10px 0" }} />
            <Skeleton shape={"rectangle"} style={{ width: "100%", height: "60px" }} />
        </div>;
    }
}