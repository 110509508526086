import React from "react";
import { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Header from "./header/header.jsx";
import MessageWorker from "./header/messageWorker.jsx";
import LoginForm from "../components/forms/loginForm.jsx";
import { requestSession, storageChange, getUser } from "../utils/authHelper.jsx";
import { eventStorage } from "../utils/systemConstants.jsx";
import { changeStorage } from './header/headerReducer.jsx';

import "../styles/scss/app.scss";

import "../fontawesome/less/fontawesome.less";
import "../fontawesome/less/solid.less";

import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

require("../localization/loadLocale.jsx");

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        function storageChanged(event) {
            if (storageChange(event, getUser())) {
                dispatch(changeStorage(getUser()));
            }
        }

        window.addEventListener(eventStorage, storageChanged);

        requestSession();

        return function cleanup() {
            window.removeEventListener(eventStorage, storageChanged);
        };
    }, []);

    const isDark = useSelector(state => state.header.isDark);
    const isLogged = useSelector(state => state.header.isLogged);
    const ui = useSelector(state => state.header.ui);

    if (isDark) {
        require("../styles/dark/kendoOverride.css");
        require("../styles/dark/override.css");
    }

    const content = isLogged
        ? <>
            <MessageWorker />
            <Header />
        </>
        : <LoginForm />;

    return <BrowserRouter>
        <LocalizationProvider language={ui}>
            <IntlProvider locale={ui}>
                {content}
            </IntlProvider>
        </LocalizationProvider>
    </BrowserRouter>;
}

export default App;