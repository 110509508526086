import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import { Toolbar, ToolbarItem, Button } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import collateralSchemaFunctions from "./collateralSchemaFunctions.jsx";
import DropDownTreeView from "../../components/controls/dropDownTreeView.jsx";
import DialogSchemaBlock from "../dialogs/dialogSchemaBlock.jsx";
import { getFormatDateTime, dateTimeWidth } from "../../utils/systemConstants.jsx";
import {
    schemaLoad,
    schemaSelectedChanged,
    viewBlockInfo,
    dialogCanceled,
    selectedDateChanged
} from "./collateralSchemaReducer.jsx";
import "../../styles/css/collateralSchema.css";

export default function CollateralSchema() {
    const dispatch = useDispatch();
    const params = useParams();

    const store = useSelector(state => state.collateralSchema);

    useEffect(() => dispatch(schemaLoad(params.id)), []);

    if (store.error)
        return <ErrorMask error={store.error} />;

    if (!store.isLoaded || store.isLoadingBlocks)
        return <LoadingMask />;

    const items = [];
    const toolbarButtons = [];
    let dialogBlockDialog = "";
    let schemaWidth = 0;
    const selectedDate = store.date ? new Date(store.date) : new Date();

    if (store.selectedBlock)
        toolbarButtons.push(<ToolbarItem key="ViewMode">
            <Button icon="eye"
                onClick={() => dispatch(viewBlockInfo(store.selectedBlock))}>
                {window.captions.View}
            </Button>
        </ToolbarItem>);

    if (store.selectedBlock && store.selectedBlock.tid === 2)
        toolbarButtons.push(<ToolbarItem key="LinkButton">
            <Button icon="link">
                <Link to={`/card/collateral/${store.selectedBlock.cid}`} title={window.captionsDynamic.Collaterals}>
                    {window.captionsDynamic.Collaterals}
                </Link>
            </Button>
        </ToolbarItem>);

    if (store.selectedBlock && store.selectedBlock.tid === 0) {
        const newSchema = store.selectedSchema.items.find(x => x.id === store.selectedBlock.sid);
        if (newSchema)
            toolbarButtons.push(<ToolbarItem key="LinkChanged">
                <Button icon="link"
                    onClick={() => dispatch(schemaSelectedChanged({ value: newSchema.id }))} />
            </ToolbarItem>);
    }


    if (store.showBlockDialog)
        dialogBlockDialog = <DialogSchemaBlock block={store.blockDataModel} onClose={() => dispatch(dialogCanceled())} />;

    for (let item of store.blocks) {
        let left = item.positionLeft - store.minLeft;

        const block = <div key={item.id} className="sch-block-container" style={{
            left: left,
            top: item.positionTop - store.minTop,
            zIndex: item.tid === 1 ? -2 : item.id
        }}>
            {collateralSchemaFunctions.drawBlock(store, dispatch, item)}
        </div>;

        left += item.width;
        if (left > schemaWidth)
            schemaWidth = left;

        items.push(block);
    }

    return <div className="dm-full-height">
        <h1 className="dm-title">{window.captionsDynamic.ResourceSchemas}</h1>
        <div className="dm-under-title-content">
            <Toolbar className="sch-toolbar">
                <ToolbarItem>
                    <DropDownTreeView
                        isHierarchical={true}
                        selectedId={store.selectedSchema
                            ? store.selectedSchema.id
                            : 0}
                        treeData={store.collateralSchemas}
                        selectionChanged={(e) => dispatch(schemaSelectedChanged(e, selectedDate))} />
                </ToolbarItem>
                <ToolbarItem>
                    <DateTimePicker property="date"
                        value={store.date}
                        width={dateTimeWidth}
                        formatPlaceholder={getFormatDateTime()}
                        onChange={(e) => dispatch(selectedDateChanged(e, store))}
                        defaultValue={selectedDate} />
                </ToolbarItem>
                {toolbarButtons}
            </Toolbar>
            <div className="sch-diagram-container">
                <div key="diagram" className="sch-diagram">
                    {items}
                </div>
            </div>
            {dialogBlockDialog}
        </div>
    </div>;

}