import React from "react";
import { useState, useEffect } from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getUiLang } from "../../utils/authHelper.jsx";
import { getUiSources } from "../../utils/systemConstants.jsx";

export default function LangItemEditor({ langItem, defaultName, namedChanged }) {
    const [id, setId] = useState(0);
    const [source, setSource] = useState([]);
    const [selectLang, setSelectLang] = useState([]);
    const [selectName, setSelectName] = useState("");

    useEffect(() => mountProperties(), [langItem.id]);

    const items = getLanguages();
    let selectNameModel = langItem.items ? langItem.items.find(i => i.id === selectLang.id) : null; //No prop
    if (!selectNameModel) {
        selectNameModel = {
            id: selectLang.id,
            name: selectName
        };
    }

    let selectLangModel = items.find(i => i.id === selectLang.id);

    return <>
        <Input key="nameEditor"
            maxLength={255}
            className="dm-size-66"
            value={selectNameModel.name}
            onChange={changeName} />
        <DropDownList textField="name"
            dataItemKey="id"
            className="dm-size-33"
            data={items}
            value={selectLangModel}
            onChange={changeSelectLang} />
    </>;

    function getLanguages() {
        const items = [
            {
                id: 1,
                name: window.captions.LanguageUkrainian,
                icon: "ua"
            }, {
                id: 0,
                name: window.captions.LanguageRussian,
                icon: "ru"
            }, {
                id: 2,
                name: window.captions.LanguageEnglish,
                icon: "us"
            }, {
                id: 3,
                name: window.captions.LanguageBelarusian,
                icon: "by"
            }, {
                id: 5,
                name: window.captions.LanguagePoland,
                icon: "pl"
            }, {
                id: 6,
                name: window.captions.LanguageGeorgia,
                icon: "ge"
            }, {
                id: 7,
                name: window.captions.LanguageVietnamese,
                icon: "vn"
            }, {
                id: 8,
                name: window.captions.LanguageEspanol,
                icon: "es"
            }, {
                id: 10,
                name: window.captions.LanguageArabic,
                icon: "ar"
            }
        ];

        return items;
    }

    function mountProperties() {
        const id = langItem.id;
        let selected = [];
        const userUi = getUiLang();
        let language = getUiSources().find(i => i.name === userUi);
        if (langItem.items && langItem.items.length > 0) {
            const findElement = langItem.items.find(i => i.id === language.id);
            if (findElement)
                selected = findElement;
            else {
                const findDefaultElement = langItem.items.find(i => i.name);
                selected = findDefaultElement;
                language = getUiSources().find(i => i.id === findDefaultElement.id);
            }
        } else {
            langItem.items = [];
            langItem.items.push({ id: language.id, name: defaultName });
        }

        setId(id);
        setSource(langItem.items);
        setSelectLang(language);
    }

    function changeSelectLang(event) {
        setSelectLang(event.value);
        setSelectName("");
    }

    function changeName(event) {
        var item = event.target.value;
        var newLang = {};
        if (!langItem) {
            langItem = {
                id: 0,
                items: []
            };
        }
        let findLang = langItem.items.find(i => i.id === selectLang.id);
        if (findLang) {
            findLang.name = item;
            let filteredLang = langItem.items.filter(i => i.id !== findLang.id);
            langItem.items = filteredLang;
            langItem.items.push(findLang);
        } else {
            newLang.id = selectLang.id;
            newLang.name = item;
            langItem.items.push(newLang);
        }

        setSelectName(item);

        if (namedChanged)
            namedChanged({ selectLang: langItem });
    }
}