import React, { useState, useEffect } from 'react';
import FullAccessTreeView from "../../../../../components/controls/fullAccessTreeView.jsx";

export default function Dictionary({ dataRule, dictionariesList, ruleChanged }) {
    const [items, setItems] = useState([]);

    useEffect(() => mountProperties(), [dataRule, dictionariesList]);

    return <div className="dm-container dm-no-padding">
        <FullAccessTreeView
            key="strategyAccessTreeView"
            dataTree={items}
            onCheckChange={onCheckChange}
        />
    </div>;

    function mountProperties() {
        if (!dataRule || !dictionariesList) {
            setItems([]);
            return;
        }

        let itemsLocal = Array.isArray(dictionariesList) && dictionariesList.length ? dictionariesList : [];
        itemsLocal.forEach(d => {
            let itemAccess = dataRule.accessDictionaries.find(access => access.id == d.id);
            setAccess(d, itemAccess);
        });

        setItems(itemsLocal);
    }

    function setAccess(item, access) {
        if (!access) {
            item.viewing = false;
            item.editing = false;
            item.deleting = false;
            item.adding = false;
            return;
        }

        item.viewing = access.view;
        item.editing = access.edit;
        item.deleting = access.delete;
        item.adding = access.add;
    }

    function onCheckChange(selectedItems) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //stagesForAction: selected,
                isUpdated: true
            });
    }
}