import React, { useState } from "react";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TreeView } from "@progress/kendo-react-treeview";
import ExportQueues from "../exportQueue/exportQueues.jsx";
import LoadingMask from "../../components/loadingMask.jsx";
import ErrorMask from "../../components/errorMask.jsx";
import DialogExport from "../dialogs/dialogExport.jsx";
import { fetchGet, fetchPost } from '../../utils/requestsHelper.jsx';
import { iconClassName } from "./statisticHelpers.jsx";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getContainerEditorStyle } from "../../utils/systemConstants.jsx";

export default function Statistic() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [config, setConfig] = useState(null);

    const [reports, setReports] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [exportStarted, setExportStarted] = useState(false);
    const [selected, setSelected] = useState(null);

    const access = useSelector(state => state.header.model.access.statistic);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.Statistic}`;
        fetchData();
    }, []);

    if (!access)
        return <ErrorMask error={window.captions.AccessDenied} />;

    if (error)
        return <ErrorMask error={error} />;

    if (!isLoaded)
        return <LoadingMask />;

    let dialog = null;
    if (selected) {
        dialog = <DialogExport exportItem={selected}
            key="dialogExport"
            onClose={(isRendered) => exportDialogClosed(isRendered)} />;
    }

    const ui = [];

    if (reports && reports.length > 0) {
        ui.push(<div className="dm-size-small-100 dm-size-big-50" key="reportsBlock">
            <h4>
                <span className="dm-icon">
                    <FileIcon extension={"xlsx"} {...defaultStyles["xlsx"
                    ]} />
                </span> {window.captions.MainTabStatisticReports}
            </h4>
            <TreeView
                data={reports}
                textField="name"
                item={i => [<span className={iconClassName(i.item)} key="0" />, i.item.name]}
                animate={false}
                expandIcons={true}
                onItemClick={onItemClick}
                onExpandChange={onExpandChange} />
        </div>);
    }

    if (documents && documents.length > 0) {
        ui.push(<div className="dm-size-small-100 dm-size-big-50" key="documentsBlock">
            <h4>
                <span className="dm-icon">
                    <FileIcon extension={"docx"} {...defaultStyles["docx"
                    ]} />
                </span> {window.captions.Documents
                }
            </h4>
            <TreeView
                data={documents}
                textField="name"
                item={i => [
                    <span className={iconClassName(i.item)} key="0" />, i.item.name,
                    i.item.hasVariables
                ]}
                animate={false}
                expandIcons={true}
                onItemClick={onItemClick}
                onExpandChange={onExpandChange} />
        </div>);
    }

    const containerStyle = getContainerEditorStyle(window.innerWidth > 1100);
    return [
        <div className="dm-full-height" key="statisticView">
            <h1 className="dm-title">{window.captions.Statistic}</h1>
            <div className="dm-under-title-content">
                <ExportQueues isStarted={exportStarted} />
                <div className={containerStyle}>
                    {ui}
                </div>
            </div>
        </div>,
        dialog
    ];

    function fetchData() {
        fetchGet(window.constants.statisticAllElements,
            data => {
                setReports(data.reports);
                setDocuments(data.documents);
                setConfig(config);
                setError(null);
                setIsLoaded(true);
                setExportStarted(false);
                setSelected(null);
            },
            ex => {
                setError(ex);
                setIsLoaded(true);
            }
        );
    }

    function exportDialogClosed(isStarted) {
        setExportStarted(isStarted);
        if (selected)
            selected.selected = false;

        setSelected(null);
    }

    function onItemClick(event) {
        const item = event.item;
        if (item.items && item.items.length > 0) {
            item.expanded = !item.expanded;
            return;
        }

        if (selected)
            selected.selected = false;

        item.selected = true;
        setExportStarted(false);
        setSelected(item);
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

}