import React from "react";

export default function ErrorMask({ error, iconClass, inline, style, children }) {
    const errors = [];
    if (children) {
        errors.push(<span key="error" className="badge bg-danger">{children}</span>);
    } else if (error) {
        var stringError = typeof error === 'string' || error instanceof String
            ? error
            : JSON.stringify(error);
        if (stringError.indexOf("\r\n") > 0) {
            errors.push(<span key="errorMessage" className="badge bg-warning">Request error</span>);
            errors.push(<pre key="errorDetails" className="dm-error-detail">{stringError}</pre>);
        }
        else if (stringError.length > 80) {
            errors.push(<span key="errorMessage" className="badge bg-warning">Request error</span>);
            errors.push(<div key="errorDetails" className="dm-error-detail">{stringError}</div>);
        } else {
            errors.push(<span key="error" className="badge bg-danger">{stringError}</span>);
        }
    } else {
        errors.push(<span key="error" className="badge bg-danger">Error</span>);
    }

    const icon = iconClass
        ? `${iconClass} dm-error`
        : "dm-i dm-i-exclamation-triangle dm-error";

    const className = inline ? "dm-error-inline" : "dm-error-block";
    return <div className={className} style={style}>
        <i className={icon} style={{ fontSize: "60px", lineHeight: "60px", display: "block", marginBottom: "5px" }} />{errors}
    </div>;
}