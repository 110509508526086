import React, { useState, useEffect } from 'react';
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { numericWidth } from "../../../../../utils/systemConstants.jsx";
import { TreeView, } from "@progress/kendo-react-treeview";

export default function RuleGeneralData({ dataRule, privilegesCard, privilegesGeneral, ruleChanged }) {
    const [privilegesCardList, setPrivilegesCardList] = useState(null);
    const [privilegesGeneralList, setPrivilegesGeneralList] = useState(null);

    useEffect(() => mountProperties(), [dataRule, privilegesCard, privilegesGeneral]);

    const elements = [];

    renderPair(elements,
        window.captions.LicenseNotification,
        "licenseNotification",
        () => <div id="licenseNotification">
            <NumericTextBox onChange={licenseNotificationChanged}
                defaultValue={0}
                min={0}
                format="n0"
                width={numericWidth}
                value={dataRule.licenseNotificationDays}
            />
        </div>
    );

    renderPair(elements,
        window.captions.NumberPagedLinesSearchResult,
        "numberPagedLinesSearchResult",
        () => <div id="numberPagedLinesSearchResult">
            <NumericTextBox onChange={numberPagedLinesSearchResultChanged}
                defaultValue={0}
                min={0}
                format="n0"
                width={numericWidth}
                value={dataRule.countSearch}
            />
        </div>
    );

    return <div className="dm-container dm-no-padding">
        {elements}
        <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                <TreeView
                    key="privilegesCardTree"
                    checkboxes={true}
                    expandIcons={true}
                    data={privilegesCardList}
                    checkIndeterminateField="checkIndeterminate"
                    textField="name"
                    onExpandChange={onExpandChange}
                    onCheckChange={(e) => onCheckChangeTreeView(e, () => setPrivilegesCardList([...privilegesCardList]))}
                />
                <TreeView
                    key="privilegesGeneralTree"
                    checkboxes={true}
                    expandIcons={true}
                    data={privilegesGeneralList}
                    checkIndeterminateField="checkIndeterminate"
                    textField="name"
                    onExpandChange={onExpandChange}
                    onCheckChange={(e) => onCheckChangeTreeView(e, () => setPrivilegesGeneralList([...privilegesGeneralList]))}
                />
            </div>
        </div>
    </div>;

    function mountProperties() {
        if (!dataRule || (!privilegesCard && !privilegesGeneral)) {
            setPrivilegesCardList([]);
            setPrivilegesGeneralList([]);
            return;
        }

        let privilegesCardLocal = Array.isArray(privilegesCard) && privilegesCard.length ? privilegesCard : [];
        privilegesCardLocal.forEach(i => setParents(i));
        privilegesCardLocal.forEach(i => setCheckPrivileges(i, dataRule));
        let privilegesGeneralLocal = Array.isArray(privilegesGeneral) && privilegesGeneral.length ? privilegesGeneral : [];
        privilegesGeneralLocal.forEach(i => setParents(i));
        privilegesGeneralLocal.forEach(i => setCheckPrivileges(i, dataRule));

        setPrivilegesCardList(privilegesCardLocal);
        setPrivilegesGeneralList(privilegesGeneralLocal);
    }

    function setCheckPrivileges(data, dataRule) {
        if (!data) {
            return;
        }

        if (data.privilege && data.privilege >= 0 && dataRule.priveliges.length > data.privilege) {
            data.checked = dataRule.priveliges[data.privilege];
            if (data.checked) {
                data.items.forEach(i => i.checked = true);
                return;
            }
        }

        if (data.items && data.items.length > 0) {
            data.items.forEach(i => setCheckPrivileges(i, dataRule));
            if (data.items.some((item) => item.checked)) {
                if (data.items.every((item) => item.checked)) {
                    data.checked = true;
                    data.checkIndeterminate = false;
                } else {
                    data.checked = false;
                    data.checkIndeterminate = true;
                }
            }
            else {
                data.checked = false;
                data.checkIndeterminate = false;
            }
        }
    }

    function licenseNotificationChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...privilegesGeneralLocal,
                licenseNotificationDays: e.value,
                isUpdated: true
            });
    }

    function numberPagedLinesSearchResultChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                countSearch: e.value,
                isUpdated: true
            });
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };

    function onCheckChangeTreeView(event, changed) {
        event.item.checked = !event.item.checked;
        checkRecursive(event.item);
        checkParents(event.item);

        changed();
    }

    function checkRecursive(item) {
        if (item.items && item.items.length > 0)
            item.items.forEach(i => i.checked = item.checked);
    }

    function checkParents(item) {
        if (item.getParent)
            var parent = item.getParent();
        if (!parent) {
            return;
        }

        if (parent.items.some((item) => item.checked)) {
            if (parent.items.every((item) => item.checked)) {
                parent.checked = true;
                parent.checkIndeterminate = false;
            } else {
                parent.checked = false;
                parent.checkIndeterminate = true;
            }
        }
        else {
            parent.checked = false;
            parent.checkIndeterminate = false;
        }
    }

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }
}