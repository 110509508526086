import React from "react";
import { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { EditListViewTextBoxItem } from "./editListViewItem.jsx";

export default function ValueArraySettings(props) {
    const [list, setList] = useState([]);

    useEffect(() => fetchData(), [props.fieldId]);

    return <div>
        <Button onClick={addValue}>{window.captions.Add}</Button>
        <ListView data={list}
            item={textBoxItem}
            style={{ width: "100%" }} />
    </div>;

    function fetchData() {
        if (props.list) {
            setList(props.list);
        } else {
            setList([]);
        }
    }

    function deleteItem(item) {
        var newValue = list.filter((e) => e !== item.dataItem);
        setList(newValue);
        props.onSaveValueArray(newValue);
    };

    function addValue() {
        var newValue = [];
        list.map(item => newValue.push(item));
        newValue.push("");
        setList(newValue);
        props.onSaveValueArray(newValue);
    }

    function saveItem(event) {
        var newValue = [];
        list.map((item, index) => {
            if (index === event.index)
                item = event.dataItem;

            newValue.push(item);
        });
        setList(newValue);
        props.onSaveValueArray(newValue);
    }

    function textBoxItem(item) {
        return <EditListViewTextBoxItem key={`ListEditTextBoxItem_${item.index}`}
            item={item}
            onSaveItem={saveItem}
            onDeleteItem={deleteItem} />;
    }
}