import React from "react";
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux";
import App from "./containers/app.jsx";
import rootReducer from "./reducers/rootReducer.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={rootReducer}>
        <App />
    </Provider>
)