import React, { useState, useEffect } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { fieldTypes } from "../../utils/systemConstants.jsx";
import CardSaveFunctions from "../cards/cardSaveFunctions.jsx";
import FieldsControl from "../cards/controls/fieldsControl.jsx";
import MessageMask from "../../components/messageMask.jsx";
import InlineDialog from "../../components/inlineDialog.jsx";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ValidationMessage from "../../components/validationMessage.jsx";

export default function DialogPaymentAdder({ inline, projectId, loanIds, stageId, onClose }) {
    const [fields, setFields] = useState([]);
    const [sources, setSources] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);

    useEffect(() => fetchData(), []);

    const title = window.captions.NewPayment;
    if (pending || error) {
        const message = <MessageMask inline text={pending} error={error} />;
        return inline
            ? <InlineDialog title={title} onClose={() => onClose(false)}>
                {message}
            </InlineDialog>
            : <Dialog title={title} onClose={() => onClose(false)} width={400}>
                {message}
            </Dialog>;
    }
    const fieldsPanel = <FieldsControl id={0}
        entity={"payment"}
        fields={fields}
        sources={sources}
        isEditMode={true}
        setFields={setEntityFields} />;

    let dialogActions = null;
    if (canSave)
        dialogActions = <DialogActionsBar>
            <Button themeColor="primary" onClick={savePayment}>{window.captions.Save}</Button>
        </DialogActionsBar>;

    let modal = null;
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }

    return inline
        ? [
            <InlineDialog title={title}
                key="dialogPaymentAdder"
                buttons={dialogActions}
                onClose={() => onClose(false)}>
                <div className="dm-container dm-container-slim">
                    {fieldsPanel}
                </div>
            </InlineDialog>,
            modal
        ]
        : [
            <Dialog title={title} key="dialogPaymentAdder" onClose={() => onClose(false)}
                width={600} height={700}>
                <div className="dm-container dm-no-padding">
                    {fieldsPanel}
                </div>
                {dialogActions}
            </Dialog>,
            modal
        ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(`${window.constants.getPaymentModel}/${projectId}`,
            (data) => {
                setFields(data.fields);
                setSources(data.sources);
                setPending(null);
                setError(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function setEntityFields(fields) {
        setFields(fields);
        setCanSave(true);
    }

    function savePayment() {
        const errorFields = CardSaveFunctions.validateRequired(fields, null, {});

        for (let key in fields) {
            const fieldModel = fields[key];
            switch (fieldModel.type) {
                case fieldTypes.integer:
                case fieldTypes.double:
                    if (!isNaN(fieldModel.value) && fieldModel.value < 0) {
                        errorFields.push(fieldModel.name + " - " + window.captions.DenyNegativePaymentValues);
                    }
                    break;
                case fieldTypes.date:
                    if (fieldModel.fieldAlias === "Created" && (!fieldModel.value || fieldModel.value > new Date())) {
                        errorFields.push(fieldModel.name + " - " + window.captions.WrongDateCreated);
                    }
                    break;
            }
        }

        if (errorFields.length > 0) {
            setPending(null);
            setValidate(errorFields);
            return;
        }

        const payment = CardSaveFunctions.renderValues(fields);
        payment.loanIds = loanIds;
        payment.stageId = stageId;

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.savePayment}`,
            payment,
            () => onClose(true),
            () => onClose(false));
    }
}