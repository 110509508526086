import React, { useState, useEffect } from 'react';

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { TreeView } from "@progress/kendo-react-treeview";

export default function ProjectDialog({ onClose, dataProjects, getProgects, getStartPrStat }) {
    const [listIdProject, setListIdProject] = useState([]);

    useEffect(() => getR(), []);

    return <Dialog title={"Проекти"} onClose={onClose}>
        <TreeView
            key="progectTree"
            checkboxes={true}
            expandIcons={true}
            data={dataProjects}
            checkIndeterminateField="checkIndeterminate"
            textField="text"
            onExpandChange={onExpandChange}
            onCheckChange={(e) => onCheckChangeTreeView(e, "stages")} />

        <DialogActionsBar>
            <Button icon="save"
                className="dm-button"
                onClick={() => saveProj()}

                style={{ margin: "16px 0 16px 0" }}>
                {window.captions.Save}
            </Button>
            <Button
                className="dm-button"
                onClick={() => cancelSaveProj()}

                style={{ margin: "16px 0 16px 16px" }}>
                {window.captions.Cancel}
            </Button>
        </DialogActionsBar>
    </Dialog>;

    function getR() { getProgects() }

    function addProjId(e) {
        if (e.item.items === undefined) {
            setListIdProject([
                ...listIdProject,
                e.item.id
            ]);
        }

        if (e.item.items !== undefined) {
            const arry = [];
            for (let key in e.item.items) {
                const entity = e.item.items[key];
                arry.push(entity.id);
            }

            setListIdProject(arry);
        }
    }

    function deleteProjId(e) {
        if (e.item.items === undefined) {
            if (listIdProject !== undefined && listIdProject.length > 0) {
                const idx = listIdProject.findIndex((el) => el === e.item.id);

                const before = listIdProject.slice(0, idx);
                const after = listIdProject.slice(idx + 1);
                const newArr = [...before, ...after];
                setListIdProject(newArr);
            }
        }

        if (e.item.items !== undefined) {
            let arry = listIdProject;
            if (listIdProject !== undefined && listIdProject.length > 0) {
                for (let key in e.item.items) {
                    const entity = e.item.items[key];
                    if (entity.cheked === true)
                        continue;

                    if (arry.includes(entity.id))
                        arry = arry.filter(x => x !== entity.id);

                }
                setListIdProject(arry);
            }
        }
    }

    function onCheckChangeTreeView(event) {
        event.item.checked = !event.item.checked;

        if (event.item.items !== undefined && event.item.items.length > 0) {
            checkRecursive(event.item);
        }

        if (event.item.items === undefined) {

            if (event.item.checked && !listIdProject.includes(event.item.id))
                addProjId(event);

            const a = !event.item.checked;
            const b = listIdProject.includes(event.item.id);

            if (!event.item.checked && listIdProject.includes(event.item.id))
                deleteProjId(event);
        }
        if (event.item.items !== undefined && event.item.items.length > 0) {
            const chek = event.item.items.filter(x => x.checked === true);
            const unChek = event.item.items.filter(x => x.checked === false);

            if (chek !== undefined && chek.length > 0)
                addProjId(event);

            if (unChek !== undefined && unChek.length > 0)
                deleteProjId(event);

        }

    }

    function getParent(items, parentId) {
        let parent = items.filter(i => i.id === parentId);

        if (parent.length === 0) {
            for (let key in items) {
                const child = items[key];
                parent = getParent(child.items, parentId);
                if (parent.length > 0 || parent.id > 0)
                    return parent;
            }
        }

        return parent;
    }

    function checkRecursive(parent) {
        for (let key in parent.items) {
            const child = parent.items[key];
            child.checked = parent.checked;

            //if (child.items.length > 0)
            //    checkRecursive(child);
        }
        return parent;
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function saveProj() {
        getStartPrStat(listIdProject);
        onClose();
    }

    function cancelSaveProj() {
        const res = listIdProject;
        onClose();
    }
}