import React from "react";
import { useState, useRef } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, Input } from "@progress/kendo-react-inputs";

export default function NumberControl({ value, field, disabled, onKeyPress, changedValueFrom, changedValueTo, changedWaterMarkValue }) {
    let buttonInterval = useRef(null);
    const [isNumberInterval, setIsNumberInterval] = useState(Array.isArray(value));

    let valueArray = value;
    let valueFrom = 0;
    let valueTo = 0;
    if (isNumberInterval) {
        if (!Array.isArray(valueArray))
            valueArray = [null, null];

        valueFrom = valueArray[0];
        if (valueFrom !== null) {
            valueFrom = parseInt(valueFrom);
            if (isNaN(valueFrom))
                valueFrom = 0;
        }

        valueTo = valueArray[1];
        if (valueTo !== null) {
            valueTo = parseInt(valueTo);
            if (isNaN(valueTo))
                valueTo = 0;
        }
    } else {
        if (!valueArray)
            valueArray = "";
    }

    const fieldId = field.id;
    return [
        <Button key={`ButtonInterval${fieldId}`}
            disabled={disabled}
            selected={isNumberInterval}
            togglable={true}
            title={!isNumberInterval
                ? window.captions.WordInterval
                : ""}
            className="dm-filter-range-button"
            onClick={handleIsInterval}
            ref={buttonInterval}>
            {"[...]"}
        </Button>,
        isNumberInterval
            ? [
                <div key={`NumberFrom${fieldId}`} className="dm-filter-range">
                    <div>{window.captions.WordFrom}</div>
                    <NumericTextBox id={`NumberFrom${fieldId}`}
                        disabled={disabled}
                        width="104px"
                        value={valueFrom}
                        onKeyPress={onKeyPress}
                        onChange={(e) => changedValueFrom({ field: fieldId, value: e.value })} />
                </div>,
                <div key={`NumberFrom${fieldId}`} key={`NumberTo${fieldId}`} className="dm-filter-range">
                    <div>{window.captions.WordTo}</div>
                    <NumericTextBox id={`NumberTo${fieldId}`}
                        disabled={disabled}
                        width="104px"
                        value={valueTo}
                        onKeyPress={onKeyPress}
                        onChange={(e) => changedValueTo({ field: fieldId, value: e.value })} />
                </div>
            ]
            : <Input key={`NumberWaterMark${fieldId}`}
                disabled={disabled}
                className="dm-wrapper-content"
                id={`NumberWaterMark${fieldId}`}
                value={valueArray}
                onChange={e => changedWaterMarkValue({ field: fieldId, value: e.value })} />
    ];

    function handleIsInterval() {
        setIsNumberInterval(!isNumberInterval);
    }
}