import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet, fetchDelete } from "../../../utils/requestsHelper.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import GridEmptyCell from "../../../components/cells/gridEmptyCell.jsx";
import ConfirmMessage from "../../../components/confirmMessage.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";
import GridDateTimeCell from "../../../components/cells/gridDateTimeCell.jsx";

const AccountDetailRow = (props) => {
    const itemLimits = props.dataItem.accountLimits;
    const itemTransactions = props.dataItem.transactions;
    var hasLimits = itemLimits && itemLimits.length > 0;
    var hasTransactions = itemTransactions && itemTransactions.length > 0;

    var gridLimits;
    if (hasLimits) {
        gridLimits = <Grid className="dm-full-wh"
            data={itemLimits}>
            <GridColumn field="typeName" title={window.captions.AccountLimitsType} width="240" />
            <GridColumn field="maxSum" title={window.captions.FormulaSum} width="100" />
            <GridColumn field="actualEndDate" title={window.captions.StartDateAccountLimit}
                width="160" cells={{ data: GridDateTimeCell }} />
            <GridColumn field="expectedEndDate" title={window.captions.EndDateAccountLimit}
                width="160" cells={{ data: GridDateTimeCell }} />
            <GridColumn field="authorName" title={window.captions.Author} width="240" />
        </Grid>;
    }

    var gridTransactions;
    if (hasTransactions) {
        gridTransactions = <Grid className="dm-full-wh"
            data={itemTransactions}>
            <GridColumn field="typeName" title={window.captions.WordType} width="240" />
            <GridColumn field="amount" title={window.captions.FormulaSum} width="100" />
            <GridColumn field="created" title={window.captions.Created} width="160"
                cells={{ data: GridDateTimeCell }} />
            <GridColumn field="currencyName" title={window.captions.Currency} width="240" />
            <GridColumn field="authorName" title={window.captions.Author} width="240" />
        </Grid>;
    }

    if (hasLimits && hasTransactions) {
        return <div>{gridLimits}<br />{gridTransactions}</div>
    } else if (hasLimits) {
        return gridLimits
    } else if (hasTransactions) {
        return gridTransactions
    } else {
        return <div>{window.captions.Empty}</div>;
    }

}

const AccountsControl = forwardRef(function AccountsControl(props, ref) {
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [data, setData] = useState(null);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [total, setTotal] = useState(0);
    const [deleteId, setDeleteId] = useState(0);

    useEffect(() => refreshDataSource(), [props.id, props.source]);

    useImperativeHandle(ref, () => {
        return {
            refreshDataSource
        };
    })

    if (error) {
        return <ErrorMask error={error} />;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    const actionsCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="menu" onClick={() => props.accountViewEdit(p.dataItem.id)} />
        </td>;

    const removeCell =
        (p) => <td {...getLinkCellStyle(p)}>
            <Button icon="delete" onClick={() => handleOnDelete(p.dataItem)} />
        </td>;

    const isSelfCell = (p) => {
        if (p.rowType === "groupHeader")
            return null;

        return p.dataItem[p.field]
            ? <td {...getLinkCellStyle(p)} title={window.captions.IsMain}>
                <i className={`dm-i dm-i-check-double dm-warning-im`} />
            </td>
            : <td {...getLinkCellStyle(p)} />;
    };

    let removeColumn = null;
    if (props.canDelete && props.isEditMode) {
        removeColumn = <GridColumn locked={true} field="id" title=" " width="35" cells={{ data: removeCell }} />;
    }

    return <div className="dm-full-wh">
        <Grid className="dm-full-wh"
            pageable={true}
            total={total}
            data={data}
            skip={dataState.skip}
            pageSize={dataState.take}
            filter={dataState.filter}
            sort={dataState.sort}
            onDataStateChange={handleDataStateChange}
            detail={AccountDetailRow}
            expandField="expanded"
            onExpandChange={expandChange}>
            <GridColumn field="typeName" title={window.captions.AccountType} width="160" />
            <GridColumn field="currencyName" title={window.captions.Currency} width="160" />
            <GridColumn field="stateName" title={window.captions.AccountState} width="160" />
            <GridColumn field="eNumber" title={window.captions.AccountNumber} width="240" />
            <GridColumn field="authorName" title={window.captions.Executor} width="240" />
            <GridColumn title=" " field="id" cells={{ data: GridEmptyCell }} />
            <GridColumn field="isMain" title=" " width="35" cells={{ data: isSelfCell }} locked={true} />
            {removeColumn}
            <GridColumn locked={true} field="id" title=" " width="35" cells={{ data: actionsCell }} />
        </Grid>
        {loadingPanel}
        <ConfirmMessage yes={deleteItem}
            no={confirmClose}
            text={confirm} />
    </div>;


    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(props.id, props.source, changeEvent.dataState);
    }

    function fetchData(id, source, dataState) {
        const hasGroups = dataState.group && dataState.group.length;
        setPending(true);
        fetchGet(`${window.constants.cardSourceAccounts}/${source}/${id}?${toDataSourceRequestString(dataState)}`,
            ({ data, total }) => {
                setData(hasGroups ? translateDataSourceResultGroups(data) : data);
                setError(null);
                setTotal(total);
                setDataState(dataState);
                setPending(false);
            },
            data => setError(data));
    }

    function expandChange(event) {
        let newData = data.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setData(newData);
    }

    function handleOnDelete(dataItem) {
        var id = parseInt(dataItem.id);
        if (!isNaN(id)) {
            setDeleteId(id);
            setConfirm(window.captions.DeleteItemConfirm);
        }
    }

    function refreshDataSource() {
        fetchData(props.id, props.source, dataState);
    }

    function deleteItem() {
        var id = deleteId;
        confirmClose();
        fetchDelete(`${window.constants.cardSourceAccounts}/${props.source}/${props.id}`,
            id,
            () => refreshDataSource(),
            () => { });
    }

    function confirmClose() {
        setDeleteId(0);
        setConfirm(false);
    }
})

export default AccountsControl;