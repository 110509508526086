import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import DrawerMenu from "../../../components/drawerMenu.jsx";
import { entitySourcesNames } from "../../../utils/systemConstants.jsx";
import { StackLayout } from "@progress/kendo-react-layout";
import { TreeView } from "@progress/kendo-react-treeview";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import SettingFunction from "./structureSettingsFunctions.jsx";
import FieldItemComponent from "./fieldSettings.jsx";
import StageSettingsComponent from "./stagesSettings.jsx"
import { getUiLang } from "../../../utils/authHelper.jsx";
import { fetchGet, fetchPost } from '../../../utils/requestsHelper.jsx';

export default function AdminStructureSettings() {
    const navigate = useNavigate();
    const params = useParams();

    const [isLoaded, setIsLoaded] = useState(false);
    const [needReload, setNeedReload] = useState(false);
    const [error, setError] = useState(null);
    const [storage, setStorage] = useState({
        unusedSources: null,
        fields: null,
        stages: null,
        allSimpleDict: null,
        allExtDict: null,
        autocompleteList: null,
        clientTypes: null,
        hasAccess: false
    });

    const [newItem, setNewItem] = useState(null);
    const [updatedItem, setUpdatedItem] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const [select, setSelect] = useState(null);
    const [entitySource, setEntitySource] = useState(null);

    const isMobile = useSelector(state => state.header.isMobile);
    const access = useSelector(state => state.header.model.access);
    const ui = useSelector(state => state.header.ui);
    const lang = useSelector(state => state.header.lang);
    const currentUi = { id: lang, name: ui };

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.MainTabAdministrationFields}`;
    }, []);

    useEffect(() => {
        structureSettingsLoad(params.entity);
        onClose(true);
    }, [params.entity]);

    useEffect(() => {
        if (!needReload)
            return;

        setNeedReload(false);
        structureSettingsLoad(params.entity);
    }, [needReload]);

    if (!access.adminStructureSettings)
        return <ErrorMask error={window.captions.AccessDenied} />;

    if (!isLoaded)
        return <LoadingMask />;

    if (!storage.hasAccess)
        return <ErrorMask error={window.captions.AccessDenied} />;

    let content;
    if (updatedItem)
        updateItem();

    const leftMenu = <DrawerMenu items={SettingFunction.renderMenuItems(params.entity)}
        align="left"
        onSelect={onSelect} />;

    let list;
    if (params.entity === entitySourcesNames.specials) {
        if (!storage.stages) {
            content = <LoadingMask />;
        } else {
            list = renderTreeView(storage.stages, true);
            if (newItem) {
                content = <StageSettingsComponent item={newItem}
                    codes={getCodes()}
                    names={getNames()}
                    currentUi={currentUi}
                    addState={(stage) => addStateEx(stage)}
                    onSave={(stage) => onSave(stage)}
                    onClose={(stage) => onClose(stage)} />;
            }
            if (currentItem) {
                content = <StageSettingsComponent item={currentItem}
                    codes={getCodes()}
                    names={getNames()}
                    currentUi={currentUi}
                    isNeedReload={needReload}
                    onReload={reloadContent}
                    addState={(stage) => addStateEx(stage)}
                    onSave={(stage) => onSave(stage)}
                    onClose={(stage) => onClose(stage)} />;
            }
        }
    } else {
        if (!storage.fields) {
            content = <LoadingMask />;
        } else {
            list = renderTreeView(storage.fields);
            if (newItem) {
                var sources = newItem.sources;
                //this.props.blockDict What is it?
                content = <FieldItemComponent
                    className="dm-container"
                    item={newItem.field}
                    codes={getCodes()}
                    names={getNames()}
                    currentUi={currentUi}
                    clientTypes={storage.clientTypes}
                    autocompleteList={storage.autocompleteList}
                    allSimpleDict={storage.allSimpleDict}
                    allExtDict={storage.allExtDict}
                    sources={sources}
                    unusedSources={storage.unusedSources}
                    isMobile={isMobile}
                    onSave={(field) => onSave(field)}
                    onClose={(field) => onClose(field)} />;
            }

            if (currentItem) {
                content = <FieldItemComponent
                    className="dm-container"
                    blockDict={currentItem.blockDictionary}
                    item={currentItem.field}
                    codes={getCodes()}
                    names={getNames()}
                    currentUi={currentUi}
                    clientTypes={storage.clientTypes}
                    autocompleteList={storage.autocompleteList}
                    allSimpleDict={storage.allSimpleDict}
                    allExtDict={storage.allExtDict}
                    sources={currentItem.sources}
                    unusedSources={storage.unusedSources}
                    isMobile={isMobile}
                    onSave={(field) => onSave(field)}
                    onClose={(field) => onClose(field)} />;
            }
        }
    }

    return <div className="dm-full-height" key="structureView">
        {leftMenu}
        <div className="dm-full-height">
            <h1>{getTitle()}</h1>
            <div className="dm-under-title-content">
                <StackLayout style={{ maxHeight: "calc(100vh - 48px)" }}>
                    {list}
                    {content}
                </StackLayout>
            </div>
        </div>
    </div>;

    function getTitle() {
        const entity = params.entity;

        if (entity === entitySourcesNames.client)
            return window.captions.ConfigFieldsClient;
        if (entity === entitySourcesNames.loan)
            return window.captionsDynamic.ConfigFieldsLoan;
        if (entity === entitySourcesNames.payments)
            return window.captions.ConfigFieldsPayments;
        if (entity === entitySourcesNames.paymentPlan)
            return window.captions.ConfigPaymentPlanFields;
        if (entity === entitySourcesNames.users)
            return window.captions.ConfigFieldsUsers;
        if (entity === entitySourcesNames.project)
            return window.captions.ConfigFieldsProjects;
        if (entity === entitySourcesNames.projectGroup)
            return window.captions.ConfigFieldsProjectGroups;
        if (entity === entitySourcesNames.phone)
            return window.captions.PhoneFields;
        if (entity === entitySourcesNames.address)
            return window.captions.AddressFields;
        if (entity === entitySourcesNames.email)
            return window.captions.EmailFields;
        if (entity === entitySourcesNames.url)
            return window.captions.UrlFields;
        if (entity === entitySourcesNames.promiseScheduler)
            return window.captions.PromiseSchedulerFields;
        if (entity === entitySourcesNames.promisePayment)
            return window.captions.PromisePaymentFields;
        if (entity === entitySourcesNames.specials)
            return window.captions.MainTabAdministrationStages;

        return window.captions.MainTabAdministrationFields;
    }

    function handleSourceChange(event) {
        setEntitySource(event.target.value);
        if (entitySource.id !== event.target.value.id)
            structureSettingsLoad(event.target.value.id);
    };

    function onItemClick(event) {
        setSelect(event.itemHierarchicalIndex);
        setCurrentItem(event.item);
    };

    function onSelect(e) {
        var selected = e;
        if (selected.id === 0) {
            addItem();
            return;
        }

        if (selected.route === "expand") {
            expandChanged();
            return;
        }

        if (isMobile)
            expandChanged();

        navigate(selected.route);
    }

    function addItem() {
        if (params.entity === entitySourcesNames.specials) {
            var stageNames = [];
            storage.stages.map(item => {
                stageNames.push({ id: stageNames.length, name: item.name });
            });

            let stageRequest = {
                names: stageNames
            }
            addStage(stageRequest);
        }
        else {
            var names = [];
            storage.fields.map(item => {
                names.push({ id: names.length, name: item.field.name });
            });

            let request = {
                names: names,
                unusedSources: storage.unusedSources
            }
            addEntityField(params.entity, request);
        }

        setCurrentItem(newItem);
    }

    function addStateEx(stage) {
        if (!stage)
            return;

        addState(stage);
        setCurrentItem(updatedItem);
    }

    function onClose(needReload) {
        setSelect(null);
        setCurrentItem(null);

        setNeedReload(needReload);
        setNewItem(null);
        setUpdatedItem(null);
    }

    function onSave(model) {
        setSelect(null);
        setCurrentItem(null);

        if (params.entity === entitySourcesNames.specials)
            saveStage(model);
        else
            saveEntityField(model);
    }

    function updateItem() {
        if (newItem)
            setNewItem(updatedItem);
        else
            setCurrentItem(updatedItem);

        setUpdatedItem(null);
    }

    function getNames() {
        let names = [];
        if (params.entity === entitySourcesNames.specials) {
            storage.stages.map(item => {
                if (item.langItem.items) {
                    var langValue = item.langItem.items.find(item => item.id === currentUi.id);
                    if (langValue) {
                        names.push({
                            id: item.id,
                            name: langValue.name
                        });

                    } else {
                        names.push({
                            id: item.id,
                            name: item.name
                        });
                    }
                } else {
                    names.push({
                        id: item.id,
                        name: item.name
                    });
                }
            });
        } else {
            storage.fields.map(item => {
                if (item.field.langItem.items) {
                    if (!currentUi) {
                        names.push({
                            id: item.field.id,
                            name: item.field.name
                        });
                    } else {
                        var langValue = item.field.langItem.items.find(item => item.id === currentUi.id);
                        if (langValue) {
                            names.push({
                                id: item.field.id,
                                name: langValue.name
                            });

                        } else {
                            names.push({
                                id: item.field.id,
                                name: item.field.name
                            });
                        }
                    }
                } else {
                    names.push({
                        id: item.field.id,
                        name: item.field.name
                    });
                }
            });
        }

        return names;
    }

    function getCodes() {
        let codes = [];

        if (params.entity === entitySourcesNames.specials) {
            storage.stages.map(item => {
                if (item.code)
                    codes.push({
                        id: item.id,
                        value: item.code
                    });
            });
        } else {
            storage.fields.map(item => {
                if (item.field.code)
                    codes.push({
                        id: item.field.id,
                        value: item.field.code
                    });
            });
        }

        return codes;
    }

    function reloadContent() {
        if (needReload === true) {
            setNeedReload(false);
            structureSettingsLoad(params.entity);
        }
    }

    function fieldItem(props, isStage) {
        return isStage
            ? <span>{props.item.name}</span>
            : <span>{props.item.field.localizeName}</span>;
    }

    function renderTreeView(items, isStage = false) {
        let propName = isStage ? "name" : "field.name";
        return <TreeView
            className="dm-container"
            data={items}
            onItemClick={onItemClick}
            textField={propName}
            item={props => fieldItem(props, isStage)}
            style={{ width: "100%" }} />;
    }

    function showError(ex) {
        setError(ex);
        setIsLoaded(true);
    }

    function structureSettingsLoad(entity) {
        setIsLoaded(false);
        fetchGet(`${window.constants.getStructureSettings}/${entity}`,
            model => {
                if (model) {
                    setStorage({
                        unusedSources: model.unusedSources,
                        fields: model.fields,
                        stages: model.stages,
                        allSimpleDict: model.allSimpleDict,
                        allExtDict: model.allExtDict,
                        autocompleteList: model.autocompleteList,
                        clientTypes: model.clientTypes,
                        hasAccess: model.hasAccess
                    });
                    setError(null);
                    setIsLoaded(true);
                } else {
                    showError(window.captions.LoadDataError);
                }

            },
            ex => showError(ex)
        );
    }

    function addEntityField(entity, requestModel) {
        fetchPost(`${window.constants.addEntityField}/${entity}`,
            requestModel,
            model => setNewItem(model),
            ex => showError(ex)
        );
    }

    function saveEntityField(field) {
        fetchPost(`${window.constants.saveEntityField}`,
            field,
            model => {
                setNeedReload(model.isNeedReload);
                setCurrentItem(model.data);
                setNewItem(undefined);
            },
            ex => showError(ex)
        );
    }

    function addStage(requestModel) {
        fetchPost(window.constants.addStage,
            requestModel,
            model => setNewItem(model),
            ex => showError(ex)
        );
    }

    function addState(requestModel) {
        fetchPost(window.constants.addState,
            requestModel,
            model => setUpdatedItem(model),
            ex => showError(ex)
        );
    }

    function saveStage(stage) {
        fetchPost(`${window.constants.saveStage}`,
            stage,
            model => {
                setNeedReload(model.isNeedReload);
                setCurrentItem(model.data);
                setNewItem(undefined);
            },
            ex => showError(ex)
        );
    }
}