import React from "react";
import { useState, useEffect } from 'react';
import { StackLayout } from "@progress/kendo-react-layout";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { fieldTypes, numericWidth } from "../../../../../utils/systemConstants.jsx";

export default function NumericLimitation(props) {
    const [restriction, setRestriction] = useState({});
    const [valueMin, setValueMin] = useState(0);
    const [valueMax, setValueMax] = useState(0);
    const [type, setType] = useState(0);

    useEffect(() => fetchData(), [props.fieldId]);

    const format = type === fieldTypes.double ? "n2" : "#";

    let content = [];
    renderPair(content,
        window.captions.WordFrom,
        "RestrictionMin",
        () => <NumericTextBox placeholder={window.captions.WordFrom}
            className="dm-m-right"
            min={0}
            width={numericWidth}
            format={format}
            value={valueMin}
            onChange={saveMinValue} />,
        true);

    renderPair(content,
        window.captions.WordTo,
        "RestrictionMax",
        () => <NumericTextBox placeholder={window.captions.WordTo}
            className="dm-m-right"
            min={0}
            width={numericWidth}
            format={format}
            value={valueMax}
            onChange={saveMaxValue} />,
        true);

    return (
        <StackLayout orientation="vertical" gap={5}>
            {content}
        </StackLayout>
    );

    function fetchData() {
        if (!props)
            return;

        const model = props.restriction;
        setRestriction(model);
        setValueMin(model.minValue ? model.minValue : 0);
        setValueMax(model.maxValue ? model.maxValue : 0);
        setType(props.type);
    }

    function saveMinValue(item) {
        if (parseInt(item)) {
            setValueMin(item);
        } else {
            saveRestriction(item.value, false);

        }
    }

    function saveMaxValue(item) {
        if (parseInt(item)) {
            setValueMax(item);
        } else {
            saveRestriction(item.value, true);
        }
    }

    function saveRestriction(value, isMax) {
        if (!value)
            value = 0;

        if (isMax)
            setValueMax(value);
        else
            setValueMin(value);

        var newValue = {
            minType: restriction.minType,
            minValue: !isMax ? value : valueMin ? valueMin : 0,
            maxType: restriction.maxType,
            maxValue: isMax ? value : valueMax ? valueMax : 0
        };

        if (isMax)
            newValue.maxValue = value;
        else
            newValue.minValue = value;

        if (newValue.minValue !== null)
            newValue.minType = 1;

        if (newValue.maxValue !== null)
            newValue.maxType = 1;

        if (isMax && newValue.maxValue <= newValue.minValue) {
            newValue.minValue = newValue.maxValue <= 0 ? 0 : newValue.maxValue - 1;
            saveMinValue(newValue.minValue);
        }

        if (!isMax && newValue.maxValue <= newValue.minValue) {
            newValue.maxValue = newValue.minValue <= 0 ? 0 : newValue.minValue + 1;
            saveMaxValue(newValue.maxValue);
        }

        var oldValueJson = JSON.stringify(restriction);
        var newValueJson = JSON.stringify(newValue);

        if (oldValueJson === newValueJson)
            return;

        props.onSaveLimit(newValue);
    }
}