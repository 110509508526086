import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function GridFileTypeCell(props) {
    const value = props.dataItem[props.field];
    return <td title={value} {...getLinkCellStyle(props)}>
        <span className="dm-icon">
            <FileIcon extension={value} {...defaultStyles[value]} />
        </span>
    </td>;
}