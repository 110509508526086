import React, { useState, useEffect, useRef } from 'react';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import gridFunctions from "../../components/gridFunctions.jsx";
import { fetchGet, fetchPost } from "../../utils/requestsHelper.jsx";
import { GridBaseComponent } from "../../components/gridApiSource.jsx";
import { entitySourcesNames } from "../../utils/systemConstants.jsx";
import { renderPair } from "../cards/cardRenderFunctions.jsx";
import MessageMask from "../../components/messageMask.jsx";
import ValidationMessage from "../../components/validationMessage.jsx";
import { getLinkCellStyle } from "../../utils/pluginHelpers.jsx";

export default function DialogAddLinkedClient({ cardEntity, cardId, canAdd, canSelectType, isChoiceClient, onClose }) {
    const gridRef = useRef(null);

    const [pending, setPending] = useState(window.captions.LoadingData);
    const [error, setError] = useState(null);
    const [validate, setValidate] = useState(false);
    const [canAddNew, setCanAddNew] = useState(true);
    const [types, setTypes] = useState([]);
    const [columns, setColumns] = useState([]);
    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [search, setSearch] = useState("");
    const [typeId, setTypeId] = useState(0);

    useEffect(() => fetchData(), []);

    const title = cardEntity === entitySourcesNames.collateral
        ? window.captions.AddOwneer
        : window.captions.MainTabCardAddLinked;
    if (pending || error) {
        return <Dialog title={title} onClose={() => onClose()} width={850}>
            <MessageMask inline text={pending} error={error} />
        </Dialog>;
    }

    const actionsCell = (p) => <td {...getLinkCellStyle(p)}>
        <Button icon="check" fillMode="flat" onClick={() => selectClient(p.dataItem)} />
    </td>;

    const elements = [];
    let action = "";
    if (id > 0) {
        let selectedType = { id: 0, name: "" };
        if (typeId > 0) {
            action =
                <Button themeColor="primary" iconClass="dm-i dm-i-save" onClick={save}>{window.captions.Add
                }</Button>;
            selectedType = types.find((i) => i.id === typeId);
        }

        renderPair(elements,
            window.captions.Client,
            "client",
            null,
            <span className="text-success">{name}</span>);

        if (canSelectType) {
            renderPair(elements,
                window.captions.WordType,
                "types",
                () => <DropDownList style={{ width: "100%" }}
                    data={types}
                    textField="name"
                    dataItemKey="id"
                    value={selectedType}
                    onChange={selectType} />);
        }
    }

    let createNew = "";
    if (canAddNew && canAdd)
        createNew = <Button iconClass="dm-i dm-i-plus" onClick={create}>{window.captions.Create}</Button>;

    let modal = "";
    if (validate) {
        modal = <ValidationMessage key="validateDialog" close={() => setValidate(false)} text={validate} error={true} />;
    }
    const fieldColumns = gridFunctions.renderGridColumns(columns);
    return [
        <Dialog title={title} key="dialogLinked" onClose={() => { onClose(); }} width={850}>
            <div className="dm-container dm-no-padding">
                <div className="dm-wrapper">
                    <div className="dm-wrapper-content">
                        <Input onChange={searchChanged}
                            onKeyDown={(e) => onSearchKey(e)}
                            placeholder={window.captions.Fio}
                            value={search}
                            style={{ width: "100%" }} />
                    </div>
                    <div>
                        <Button icon="find" title={window.captions.Search} onClick={() => filter()} />
                    </div>
                </div>

                <GridBaseComponent baseUrl={`${window.constants.getLinkedSearch}/${cardEntity}/${cardId}`}
                    style={{ width: "100%", height: "400px", margin: "0 0 15px 0" }}
                    ref={gridRef}>
                    <GridColumn field="id" title=" " width="35" cells={{ data: actionsCell }} />
                    {fieldColumns}
                </GridBaseComponent>
                {elements}
            </div>
            <DialogActionsBar>
                <Button onClick={() => { onClose(); }}>{window.captions.Cancel}</Button>
                {createNew}
                {action}
            </DialogActionsBar>
        </Dialog>,
        modal
    ];

    function fetchData() {
        setPending(window.captions.LoadingData);
        setError(null);
        fetchGet(window.constants.getLinkedModel,
            (data) => {
                setTypes(data.types);
                setTypeId(data.defaultTypeId);
                setCanAddNew(data.canAddNew);
                setColumns(data.columns);
                setPending(null);
            },
            (ex) => {
                setError(ex.stack ? ex.stack : ex);
                setPending(null);
            });
    }

    function selectType(e) {
        setTypeId(e.target.value.id);
    }

    function selectClient(dataItem) {
        setId(parseInt(dataItem["CID"]));
        setName(dataItem["FIO"]);
    }

    function create() {
        if (typeId <= 0 && canSelectType)
            return;

        const data = {
            id: 0,
            name: "",
            typeId: typeId
        };
        onClose(data);
    }

    function save() {
        if (isChoiceClient) {
            const data = {
                id: id,
                name: name,
                typeId: typeId
            };
            onClose(data);
            return;
        }


        var saveData = {
            id: id,
            typeId: typeId
        };

        setPending(window.captions.SavingData);
        setError(null);
        fetchPost(`${window.constants.addLinkedModel}/${cardEntity}/${cardId}`,
            saveData,
            (success) => {
                if (success)
                    onClose();
                else {
                    setPending(null);
                    setValidate(window.captions.AddLinkedClientsError);
                }
            },
            () => { onClose(); });
    }

    function onSearchKey(e) {
        if (e.keyCode !== 13)
            return;

        filter();
    }

    function filter() {
        gridRef.current.filterByField("fio", "contains", search);
    }

    function searchChanged(e) {
        const text = e.target.value && e.target.value.length > 0
            ? e.target.value
            : "";
        setSearch(text);
    }
}