import React from "react";
import { DropDownButton, Button } from "@progress/kendo-react-buttons";
import { fetchGet } from "../../../utils/requestsHelper.jsx";

export default function ContactContextMenuControl({ item, entityItem, dialogConfirmVerification, verificationNewPhone }) {
    if (!entityItem || !item || !item.phoneNumber || item.verified || !dialogConfirmVerification)
        return null;

    if (item.id <= 0 && !verificationNewPhone)
        return null;

    if (item.awaitVerification) {
        item.awaitVerification = null;
        verification();
    }

    if (!item.verificationCode || item.verificationCode.length === 0 || !item.altVerificationCode || item.altVerificationCode.length === 0)
        return <Button className="dm-m-left" title={window.captions.Verification} icon="check" onClick={() => verification()} />;

    const menuItems = [
        { id: "send", name: window.captions.Verification },
        { id: "confirm", name: window.captions.ConfirmVerification }
    ];
    return <DropDownButton
        className="dm-m-left"
        onItemClick={handleOnSelect}
        icon="more-vertical"
        items={menuItems}
        textField="name" />;

    function handleOnSelect(e) {
        switch (e.item.id) {
            case "send":
                verification();
                break;
            case "confirm":
                dialogConfirmVerification(true, item.id, item.verificationCode, item.altVerificationCode);
                break;
            default:
                break;
        }
    }

    function verification() {
        if (!entityItem || !item)
            return;

        const phoneId = item.id;
        if (phoneId <= 0) {
            verificationNewPhone(phoneId);
            return;
        }

        fetchGet(
            `${window.constants.cardPhoneVerification}/${entityItem.id}/${entityItem.entity}/${phoneId}`,
            (code) => {
                if (code) {
                    if (code.constructor === Boolean) {
                        alert(window.captions.CannotSendError);
                        return;
                    }

                    if (code.constructor === Array) {
                        item.verificationCode = code[0];
                        item.altVerificationCode = code[code.length - 1];
                    }

                    if (code.constructor === String)
                        item.altVerificationCode = item.verificationCode = code;

                    dialogConfirmVerification(true, item.id, item.verificationCode, item.altVerificationCode);
                } else
                    alert(window.captions.CannotSendError);
            },
            () => { alert(window.captions.CannotSendError) });
    }
}