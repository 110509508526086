import React, { useState, useEffect } from 'react';
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { GridBaseComponent } from "../../../components/gridApiSource.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { fetchGet, fetchDelete } from "../../../utils/requestsHelper.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { paymentPeriods, promiseStateName } from "../../../utils/systemConstants.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import GridTextCell from "../../../components/cells/gridTextCell.jsx";
import GridPhoneCell from "../../../components/cells/gridPhoneCell.jsx";
import GridDateCell from "../../../components/cells/gridDateCell.jsx";
import MultilineTextArea from "../../../components/controls/multilineTextArea.jsx";
import { getLinkCellStyle } from "../../../utils/pluginHelpers.jsx";

const PromiseDetailRow = (props) => {
    const gridDateCell = (p) => <GridDateCell {...p} />;
    const gridTextCell = (p) => <GridTextCell {...p} />;

    const gridStateCell = (p) => {
        const stateName = promiseStateName(p.dataItem[p.field]);
        return <td>
            <div className="dm-text-cell" title={stateName ? stateName : null}>{stateName ? stateName : null
            }</div>
        </td>;
    };

    return <GridBaseComponent baseUrl={`${window.constants.cardSourcePromiseScheduler}/${props.dataItem.Id}`}
        className="dm-full-wh">
        <GridColumn field="Index" title="#" width="100" cells={{ data: gridTextCell }} />
        <GridColumn field="PromiseDateTime" title={window.captions.WordDate}
            cells={{ data: gridDateCell }} width="130" />
        <GridColumn field="PromiseAmount" title={window.captions.PromiseAmount}
            cells={{ data: gridTextCell }} width="220" />
        <GridColumn field="PromiseState" title={window.captions.State}
            cells={{ data: gridStateCell }} width="220" />
        <GridColumn field="PromisePaymentPercent" title={window.captions.PromisePaymentPercent}
            cells={{ data: gridTextCell }} width="220" />
        <GridColumn field="Comment" title={window.captions.Comment}
            cells={{ data: gridTextCell }} width="220" />
    </GridBaseComponent>;
}

export default function PromiseSchedulerControl(props) {
    const [dataState, setDataState] = useState({ skip: 0, take: 20, sort: [] });
    const [data, setData] = useState(null);
    const [showDialogCancel, setShowDialogCancel] = useState(false);
    const [canceledItem, setCanceledItem] = useState(null);
    const [text, setText] = useState("");
    const [total, setTotal] = useState(0);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => fetchData(props.id, dataState), [props.id]);

    if (error) {
        return <ErrorMask error={error} />;
    }

    let loadingPanel = "";
    if (pending) {
        loadingPanel = <GridLoadingPanel />;
    }

    let dialogContent = "";
    if (showDialogCancel) {
        dialogContent =
            <Dialog title={window.captions.PromiseScheduler} onClose={() => { handleDialogOnClose(false); }} width={400}>
                <FloatingLabel label={window.captions.EnterReasonCancelPromise} style={{ width: "100%" }}>
                    <MultilineTextArea id="chatTitleEditor" changeText={changeText} text={text} />
                </FloatingLabel>
                <DialogActionsBar>
                    <Button onClick={() => { handleDialogOnClose(false); }}>
                        {window.captions.Cancel}
                    </Button>
                    <Button themeColor="primary" onClick={() => { handleDialogOnClose(true); }}>
                        {window.captions.WordSelect}
                    </Button>
                </DialogActionsBar>
            </Dialog>;
    }

    return <div className="dm-full-wh">
        <Grid className="dm-full-wh"
            pageable={true}
            total={total}
            data={data}
            {...dataState}
            onDataStateChange={handleDataStateChange}
            detail={PromiseDetailRow}
            expandField="expanded"
            onExpandChange={expandChange}>
            <GridColumn field="Index" title="#" width="100" cells={{ data: gridTextCell }} />
            <GridColumn field="IsImport" title=" " width="55" cells={{ data: importCell }} />
            <GridColumn field="FirstTimePromise" title={window.captions.PromissPaymentDate}
                cells={{ data: gridDateCell }} width="130" />
            <GridColumn field="Created" title={window.captions.AddedDate}
                cells={{ data: gridDateCell }} width="130" />
            <GridColumn field="TotalAmountPromise" title={window.captions.PromiseAmount}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="PromiseState" title={window.captions.State}
                cells={{ data: gridStateCell }} width="220" />
            <GridColumn field="PromisePaymentPercent" title={window.captions.PromisePaymentPercent}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="PeriodAmount" title={window.captions.NumbersPaymentPeriod}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="PaymentPeriod" title={window.captions.Period}
                cells={{ data: gridPaymentPeriodCell }} width="220" />
            <GridColumn field="PhoneNumber" title={window.captions.PhoneNumber}
                cells={{ data: gridPhoneCell }} width="220" />
            <GridColumn field="Author" title={window.captions.Author}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="PromiseSourceConst" title={window.captions
                .MainTabAdministrationDictPromiseSource}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="Comment" title={window.captions.Comment}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="DelayReason" title={window.captions.DelayReason}
                cells={{ data: gridTextCell }} width="220" />
            <GridColumn field="Index" title=" " width="35" cells={{ data: cancelCell }} />
        </Grid>
        {loadingPanel}
        {dialogContent}
    </div>;

    function handleDataStateChange(changeEvent) {
        setDataState(changeEvent.dataState);
        fetchData(props.id, changeEvent.dataState);
    }

    function fetchData(id, dataState) {
        setPending(true);
        fetchGet(`${window.constants.cardSourcePromisePayment}/${id}?${toDataSourceRequestString(dataState)}`,
            ({ data, total }) => {
                setData(data);
                setError(null);
                setTotal(total);
                setDataState(dataState);
                setPending(false);
            },
            ex => setError(ex));
    }

    function gridDateCell(p) { return <GridDateCell {...p} /> }

    function gridTextCell(p) { return <GridTextCell {...p} /> }

    function gridPhoneCell(p) { return <GridPhoneCell {...p} /> }

    function gridStateCell(p) {
        const stateName = promiseStateName(p.dataItem[p.field]);
        return <td>
            <div className="dm-text-cell" title={stateName ? stateName : null}>{stateName ? stateName : null
            }</div>
        </td>;
    }

    function gridPaymentPeriodCell(p) {
        let period = "";
        switch (p.dataItem[p.field]) {
            case paymentPeriods.week:
                period = window.captions.Week;
                break;
            case paymentPeriods.month:
                period = window.captions.Month;
                break;
            case paymentPeriods.unknown:
                period = window.captions.Unknown;
                break;
            case paymentPeriods.day:
                period = window.captions.Day;
                break;
            default:
                return <td>
                    <div className="dm-text-cell" title={period}>{period}</div>
                </td>;
        }
        period =
            p.dataItem["CountSourcePeriod"] > 1 ? `${period} (${p.dataItem["CountSourcePeriod"]})` : period;
        return <td>
            <div className="dm-text-cell" title={period ? period : null}>{period ? period : null}</div>
        </td>;
    }

    function importCell(p) {
        return p.dataItem[p.field]
            ? <td {...getLinkCellStyle(p)} title={window.captions.Import}>
                <i className={`dm-i dm-i-upload`} />
            </td>
            : <td {...getLinkCellStyle(p)} />;
    }

    function cancelCell(p) {
        if (props.canCancel && p.dataItem.PromiseState === 2)
            return <td {...getLinkCellStyle(p)} title={window.captions.Cancel}>
                <Button icon="undo" fillMode="flat" onClick={() => handleOnCancel(p.dataItem)} />
            </td>;
        else
            return <td {...getLinkCellStyle(p)} />;
    };

    function expandChange(event) {
        let newData = data.map((item) => {
            if (item.Id === event.dataItem.Id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setData(newData);
    }

    function changeText(e) {
        setText(e.value);
    }

    function handleOnCancel(dataItem) {
        setShowDialogCancel(true);
        setCanceledItem(dataItem);
    }

    function handleDialogOnClose(isCancel) {
        if (!isCancel) {
            setShowDialogCancel(false);
            setCanceledItem(null);
            return;
        }

        const promise = {
            id: canceledItem.Id,
            aid: canceledItem.Aid,
            pid: canceledItem.Pid,
            author: canceledItem.Author,
            loanId: canceledItem.LoanId,
            isDiscount: canceledItem.IsDiscount,
            firstTimePromise: new Date(canceledItem.FirstTimePromise),
            created: new Date(canceledItem.Created),
            totalAmountPromise: canceledItem.TotalAmountPromise,
            periodAmount: canceledItem.PeriodAmount,
            countSourcePeriod: canceledItem.CountSourcePeriod,
            phoneId: canceledItem.PhoneId,
            phoneNumber: canceledItem.PhoneNumber,
            paymentScheduler: [],
            comment: text,
            promiseState: 3,
            paymentPeriod: canceledItem.PaymentPeriod,
            discountAmount: canceledItem.DiscountAmount,
            promiseSource: canceledItem.PromiseSource,
            isImport: canceledItem.IsImport,
            delayReason: canceledItem.DelayReason,
            isFromAction: canceledItem.IsFromAction,
            historyId: canceledItem.HistoryId
        };

        promise.paymentScheduler = canceledItem.PaymentScheduler.map((item) => {
            return {
                promiseState: 3,
                id: item.Id,
                promisePaymentId: item.PromisePaymentId,
                promiseAmount: item.PromiseAmount,
                promiseDateTime: new Date(item.PromiseDateTime),
                comment: item.Comment,
                amountAccepted: item.AmountAccepted
            };
        });

        fetchDelete(`${window.constants.cardSourcePromisePayment}`,
            promise,
            () => {
                setShowDialogCancel(false);
                setCanceledItem(null);
            },
            () => { });
        fetchData(props.id, dataState);
    }
}